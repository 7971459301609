import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Employee(props: SvgIconProps) {
  return (
    <SvgIcon width="15" height="19" viewBox="0 0 15 19" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.496.5a4.498 4.498 0 0 1 2.299 8.363 7.506 7.506 0 0 1 5.197 7.132v2a.5.5 0 1 1-1 0v-2a6.508 6.508 0 0 0-4.657-6.234l-.312 1.253.98 5.397a.5.5 0 0 1-.2.49l-2 1.5a.5.5 0 0 1-.599 0l-1.999-1.5a.5.5 0 0 1-.2-.49l.98-5.397-.312-1.253a6.508 6.508 0 0 0-4.674 6.234v2a.5.5 0 1 1-.999 0v-2a7.506 7.506 0 0 1 5.197-7.132A4.498 4.498 0 0 1 7.496.5zm.582 10.998H6.914l-.87 4.783 1.452 1.09 1.451-1.09-.87-4.783zm-.582-9.995a3.498 3.498 0 1 0 0 6.996 3.498 3.498 0 0 0 0-6.996z"
        fill="#9FACBF"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default Employee;
