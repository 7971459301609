import { styled } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';

export const CustomIconButton = styled(IconButton)(({ theme }) => ({
  height: '60px',
  minWidth: '60px',
  borderLeft: 'solid 1px #1b2027',
  borderRadius: 0,
  '& svg': {
    width: '21px',
  },
}));

export const UserContainerButton = styled(IconButton)(({ theme }) => ({
  padding: '0 15px',
  height: '60px',
  minWidth: '60px !important',
  borderRadius: 0,
  borderLeft: 'solid 1px #1b2027',
  '& > span:first-child': {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'nowrap',
    alignItems: 'center',
    justifyContent: 'center',
    color: '#EEEEEE',
    '& > div': {
      marginLeft: '10px',
      height: '34px',
      width: '34px',
      overflow: 'hidden',
      borderRadius: '5px',
      background: '#808080',
    },
  },
}));

export const ImageContainer = styled('div')({
  marginLeft: '10px',
  height: '34px',
  width: '34px',
  overflow: 'hidden',
  borderRadius: '5px',
});

export const ProfileImage = styled('img')({
  width: '100%',
  height: '100%',
  objectFit: 'contain',
});
