import { Box, IconButton } from '@material-ui/core';
import {
  StickyNoteIconTwoTone,
  ShadedStarIconTwoTone,
  UnshadedStarIconTwoTone,
} from '@room-match/shared-ui-components';
import { ellipseText } from '@room-match/shared-utils';
import React from 'react';
import { StyledTooltip, Typography } from 'shared/elements';
import { IAuditionAttributes } from 'shared/interfaces/IAudition';
import { IComment } from 'shared/interfaces/utils/IComment';
import { useStyles } from './Rating.styles';

type Props = {
  audition: IAuditionAttributes;
  comment: IComment | null;
};
const Rating: React.FC<Props> = ({ comment }) => {
  const classes = useStyles();
  return (
    <div className={classes.request__rating}>
      <Box display="flex" gridGap={6} alignItems="flex-start">
        <IconButton size="small">
          <StickyNoteIconTwoTone style={{ fontSize: 14 }} />
        </IconButton>
        <div>
          <StyledTooltip title={comment?.comment || 'N/A'}>
            <div>
              <Typography className={classes.request__rating__title}>
                {ellipseText(comment?.comment || 'N/A', 100)}
              </Typography>
            </div>
          </StyledTooltip>
          {/* Stars */}
          <div className={classes.request__rating__starsContainer}>
            {!comment?.rating ? (
              <>
                {Array.from({ length: 5 }).map((_, i) => (
                  <UnshadedStarIconTwoTone key={i} fontSize="small" style={{ fontSize: 15 }} />
                ))}
              </>
            ) : (
              <>
                {Array.from({ length: comment.rating }).map((_, i) => (
                  <>
                    <ShadedStarIconTwoTone key={i} fontSize="small" style={{ fontSize: 15 }} />
                  </>
                ))}
                {Array.from({ length: 5 - comment.rating }).map((_, i) => (
                  <UnshadedStarIconTwoTone key={i} fontSize="small" style={{ fontSize: 15 }} />
                ))}
              </>
            )}
          </div>
        </div>
      </Box>
    </div>
  );
};

export default Rating;
