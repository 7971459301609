import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const InstagramOutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.022 0c1.538.004 1.884.018 2.693.056.956.042 1.61.197 2.184.419a4.43 4.43 0 0 1 1.593 1.037c.46.45.812.992 1.04 1.596.222.57.377 1.228.419 2.184.008.185.015.345.02.501l.006.187c.011.374.016.768.018 1.458v.371l.001.206v1.752a75.869 75.869 0 0 1-.022 2.163l-.006.187c-.006.186-.014.373-.024.595-.042.956-.197 1.61-.419 2.183a4.604 4.604 0 0 1-2.633 2.634c-.57.221-1.228.376-2.184.418l-.501.021-.187.007c-.467.015-.967.022-2.035.024L9.27 18H8.012c-.997-.003-1.5-.009-1.942-.022l-.187-.006a70.272 70.272 0 0 1-.594-.025c-.957-.042-1.61-.197-2.184-.418a4.43 4.43 0 0 1-1.593-1.037 4.439 4.439 0 0 1-1.04-1.597c-.222-.57-.377-1.227-.42-2.183a71.2 71.2 0 0 1-.024-.595l-.006-.187c-.013-.443-.02-.945-.021-1.942V7.981c.003-1.538.017-1.883.055-2.693.042-.956.197-1.61.419-2.183a4.432 4.432 0 0 1 1.037-1.593c.45-.46.992-.812 1.597-1.04.57-.222 1.227-.377 2.183-.42l.501-.02.187-.007C6.447.01 6.947.003 8.015.001zm-.01 1.636H8.007c-1.508.003-1.837.016-2.634.052-.876.039-1.354.186-1.67.31-.39.143-.742.372-1.034.67a2.736 2.736 0 0 0-.675 1.035c-.123.316-.271.79-.31 1.67-.039.87-.05 1.184-.052 3.099v1.074c.002 1.914.013 2.225.052 3.099.039.875.187 1.354.31 1.67.144.39.373.742.671 1.034a2.77 2.77 0 0 0 1.034.671c.317.123.791.271 1.67.31.833.037 1.156.05 2.858.052h1.556c1.702-.002 2.023-.015 2.858-.052.876-.039 1.354-.187 1.67-.31a2.986 2.986 0 0 0 1.71-1.709c.122-.316.27-.79.309-1.67.037-.835.05-1.153.052-2.854V8.23c-.002-1.702-.015-2.023-.052-2.858-.039-.876-.187-1.354-.31-1.67a2.798 2.798 0 0 0-.671-1.034 2.768 2.768 0 0 0-1.034-.672c-.316-.123-.791-.27-1.67-.31-.795-.035-1.123-.048-2.633-.051zm-1.01 2.742a4.625 4.625 0 0 1 0 9.248 4.625 4.625 0 0 1 0-9.248zm0 1.624a3 3 0 1 0 0 6 3 3 0 0 0 0-6zm4.807-2.886a1.08 1.08 0 1 1 0 2.159 1.08 1.08 0 0 1 0-2.16z"
        fill="#FFF"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default InstagramOutlinedIcon;
