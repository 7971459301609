import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const CalendarIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="hw0jkjolja">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M13.938 1.25H12.5V.5a.5.5 0 0 0-1 0v.75h-7V.5a.5.5 0 0 0-1 0v.75H2.062A2.065 2.065 0 0 0 0 3.313v10.624C0 15.075.925 16 2.063 16h11.874A2.065 2.065 0 0 0 16 13.937V3.313a2.065 2.065 0 0 0-2.063-2.063zm-11.876 1H3.5v.5a.5.5 0 0 0 1 0v-.5h7v.5a.5.5 0 0 0 1 0v-.5h1.438c.585 0 1.062.477 1.062 1.063V4.5H1V3.312c0-.585.477-1.062 1.063-1.062zM13.938 15H2.062A1.064 1.064 0 0 1 1 13.937V5.5h14v8.438c0 .585-.477 1.062-1.063 1.062z"
        fill="url(#hw0jkjolja)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default CalendarIconTwoTone;
