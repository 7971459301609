import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const InformationIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 0c5.527 0 10 4.473 10 10s-4.473 10-10 10S0 15.527 0 10 4.473 0 10 0zm0 1.563A8.433 8.433 0 0 0 1.562 10 8.433 8.433 0 0 0 10 18.438 8.433 8.433 0 0 0 18.438 10 8.433 8.433 0 0 0 10 1.562zm0 12.246a.977.977 0 1 1 0 1.953.977.977 0 0 1 0-1.953zm0-8.79a3.129 3.129 0 0 1 3.125 3.126 3.13 3.13 0 0 1-2.344 3.026v1.27a.781.781 0 1 1-1.562 0v-1.953c0-.431.35-.781.781-.781.862 0 1.563-.7 1.563-1.562 0-.862-.701-1.563-1.563-1.563s-1.563.701-1.563 1.563a.781.781 0 1 1-1.562 0A3.129 3.129 0 0 1 10 5.02z"
        fill="#DE7C30"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default InformationIconTwoTone;
