import React, { useState, useCallback, useEffect, useMemo } from 'react';
import { DialogContent, Box, Typography, Button } from '@material-ui/core';
import { useStyles, DefaultDialog } from './NotificationModal.styles';
import { CloseIcon, ErrorIcon, SuccessIcon, WarningCircleFilledIcon } from 'components/Icons';
import clsx from 'clsx';

type NotificationType = 'success' | 'error' | 'warning';
type Props = {
  type: NotificationType;
  open?: boolean;
};

type NotificationModalProps = {
  onClose?: () => void;
};

export const useNotificationModal = ({ type = 'success', open: openProps }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<NotificationType>(type);
  const [notification, setNotification] = useState<{ title: string; message: string; ctaText?: string }>({
    title: '',
    message: '',
    ctaText: '',
  });

  const handleSetMessage = (title: string, message: string, ctaText?: string) => {
    setNotification({ title, message, ctaText });
  };

  const handleOpen = () => setOpen(true);
  const handleToggle = () => setOpen((curr) => !curr);
  const handleChangeModalType = (type: NotificationType) => setModalType(type);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (openProps) {
      setOpen(openProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setModalType(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderIcon = useMemo(() => {
    switch (modalType) {
      case 'success':
        return <SuccessIcon />;
      case 'error':
        return <ErrorIcon />;
      case 'warning':
        return <WarningCircleFilledIcon />;
    }
  }, [modalType]);

  return useMemo(() => {
    return {
      handleSetMessage: (title: string, message: string, ctaText?: string) => handleSetMessage(title, message, ctaText),
      handleOpen: () => handleOpen(),
      handleClose: () => handleClose(),
      handleToggle: () => handleToggle(),
      handleChangeModalType: (modalType: NotificationType) => handleChangeModalType(modalType),
      isOpen: open,
      NotificationModal: ({ onClose }: NotificationModalProps) => (
        <DefaultDialog
          style={{ zIndex: 9999 }}
          open={open}
          onClose={() => {
            if (onClose) {
              onClose();
            }
            handleClose();
          }}
          maxWidth="sm"
          fullWidth
          className={clsx(classes.dialog, {
            [classes.errorDialog]: modalType === 'error',
            [classes.warningDialog]: modalType === 'warning',
          })}
          aria-labelledby="simple-dialog-title"
        >
          <DialogContent>
            <Box className={classes.closeIcon}>
              <Box onClick={handleClose}>
                <CloseIcon style={{ width: '14px' }} />
              </Box>
            </Box>
            <Box className={classes.notifBody}>
              {renderIcon}
              <Typography variant="h5">{notification.title}</Typography>
              <Typography variant="body2">{notification.message}</Typography>
              <Button
                fullWidth
                className={clsx(classes.btnCls, {
                  [classes.primaryBtn]: modalType === 'success',
                  [classes.errorBtn]: modalType === 'error',
                  [classes.warningBtn]: modalType === 'warning',
                })}
                onClick={() => {
                  if (onClose) {
                    onClose();
                  }
                  handleClose();
                }}
              >
                {notification.ctaText ? notification.ctaText : 'Call to Action'}
              </Button>
            </Box>
          </DialogContent>
        </DefaultDialog>
      ),
    };
  }, [classes, modalType, open, renderIcon, notification, handleClose]);
};
