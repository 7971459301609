import { createTheme } from '@material-ui/core';

export const lightTheme = createTheme({
  palette: {
    type: 'light',
    primary: {
      main: '#3e8bff',
    },
    secondary: {
      main: '#3e8bff',
    },
    action: {
      hover: '#2d343e',
    },
    customGrey: {
      light: '#fff',
      main: '#fff',
      dark: '#fff',
      contrastText: '#000',
    },
    customBlack: {
      dark: '#fff',
      main: '#fff',
      light: '#fff',
      contrastText: '#000',
    },
    contrastThreshold: 3,
    tonalOffset: 0.2,
  },
});
