import { IconButton } from '@material-ui/core';
import { Input, InputProps, PaperPlaneIconTwoTone } from '@room-match/shared-ui-components';

import React from 'react';

type Props = InputProps & {
  placeHolder?: string;
  handleSubmit: () => void;
};
const MessageInput: React.FC<Props> = ({ handleSubmit, placeHolder = 'Write message to agency...', ...props }) => {
  return (
    <Input
      name="general_company_email"
      variant="outlined"
      type="email"
      margin={'normal'}
      placeholder={placeHolder}
      fullWidth
      InputLabelProps={{ shrink: true }}
      inputProps={{ tabIndex: 1 }}
      onKeyUp={(e) => {
        if (e.key === 'Enter') {
          handleSubmit();
        }
      }}
      InputProps={{
        endAdornment: (
          <IconButton size="small" onClick={() => handleSubmit()}>
            <PaperPlaneIconTwoTone style={{ fontSize: 14 }} />
          </IconButton>
        ),
      }}
      style={{ marginTop: 0, height: '20' }}
      size="small"
      {...props}
    />
  );
};

export default MessageInput;
