import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const ResetToLeftIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 23 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.908 0C8.062 0 4.012 3.467 3.102 8.05l-1.9-2.377L0 6.635l3.077 3.846a.77.77 0 0 0 .945.207l4.615-2.307-.689-1.376L4.557 8.7c.63-4.05 4.128-7.162 8.351-7.162 4.666 0 8.462 3.797 8.462 8.462 0 4.665-3.796 8.462-8.462 8.462V20c5.514 0 10-4.486 10-10s-4.486-10-10-10z"
        fill="#D7A300"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default ResetToLeftIcon;
