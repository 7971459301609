import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    profileDialog: {
      '&.paper': {
        minWidth: '85%',
        minHeight: '90%',
      },
    },
    profileDialog_Container: {
      minWidth: '85%',
      marginTop: theme.spacing(8),
      marginBottom: theme.spacing(2),
      minHeight: '90%',
    },
    contentContainer: {
      marginTop: 12,
    },
    dialogContaier: {
      flex: '1 1 auto',
      padding: '40px !important',
    },
  }),
);
