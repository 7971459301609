import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const ColoredImageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="40" height="41" viewBox="0 0 40 41" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="zgpd6qtmqa">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M34.889 0A5.117 5.117 0 0 1 40 5.111V34.89A5.117 5.117 0 0 1 34.889 40H5.11A5.117 5.117 0 0 1 0 34.889V5.11A5.117 5.117 0 0 1 5.111 0zm-5.234 16.165a.755.755 0 0 0-.578.223l-11.542 11.56c-.43.43-1.118.462-1.586.073l-4.857-4.033a.761.761 0 0 0-.982.006L2.356 30.59v4.298a2.758 2.758 0 0 0 2.755 2.755H34.89a2.758 2.758 0 0 0 2.755-2.755v-9.287l-7.436-9.156a.756.756 0 0 0-.553-.28zM34.89 2.356H5.11a2.758 2.758 0 0 0-2.755 2.755v22.387l6.228-5.299a3.113 3.113 0 0 1 4.013-.024l4.03 3.347L27.41 14.723a3.127 3.127 0 0 1 2.367-.91c.881.045 1.705.463 2.261 1.148l5.607 6.903V5.111a2.758 2.758 0 0 0-2.755-2.755zM12.592 4.921a5.687 5.687 0 0 1 5.68 5.681 5.687 5.687 0 0 1-5.68 5.68 5.687 5.687 0 0 1-5.681-5.68 5.687 5.687 0 0 1 5.68-5.68zm0 2.356a3.328 3.328 0 0 0-3.325 3.325 3.328 3.328 0 0 0 3.325 3.325 3.328 3.328 0 0 0 3.324-3.325 3.328 3.328 0 0 0-3.324-3.325z"
        transform="translate(0 .5)"
        fill={`${props.fill || 'url(#zgpd6qtmqa)'}`}
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default ColoredImageIcon;
