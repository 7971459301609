import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const VideoIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="40" height="40" viewBox="0 0 40 40" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="u771hwde5a">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="m38.477 1.731.046.161 1.42 5.384a.834.834 0 0 1-.515.994l-.108.033L9.888 14.99h29.247c.425 0 .776.319.827.73l.007.104v20.008a4.173 4.173 0 0 1-3.96 4.163L35.8 40H5.789a4.173 4.173 0 0 1-4.163-3.96l-.005-.208V17.183L.077 11.078a2.47 2.47 0 0 1 .195-1.742L.37 9.16A2.48 2.48 0 0 1 1.75 8.084l.195-.053L35.534.061a2.52 2.52 0 0 1 2.943 1.67zm-.176 21.596H3.288v12.505a2.504 2.504 0 0 0 2.337 2.495l.165.006H35.8a2.504 2.504 0 0 0 2.496-2.337l.006-.164-.001-12.505zm-30.527-6.67-4.485.001V17.101l-.001 4.558h1.985l2.501-5.002zm8.336 0H9.64l-2.501 5.002h6.47l2.501-5.002zm8.336 0h-6.471l-2.501 5.002h6.471l2.501-5.002zm8.337 0h-6.472l-2.501 5.002h6.472l2.501-5.002zm5.519.001-3.654-.001-2.501 5.002h6.154v-5.001zm-34.4-7.38-1.579.376a.842.842 0 0 0-.532.378.84.84 0 0 0-.118.53l.02.11 1.07 4.227 5.506-1.252-4.367-4.369zm8.085-1.918-6.18 1.466 4.384 4.384 6.233-1.416-4.437-4.434zm8.094-1.92-6.187 1.468 4.452 4.449 6.23-1.416-4.495-4.501zm8.079-1.916-6.174 1.464 4.511 4.517 6.225-1.415-4.562-4.566zm7.934-1.86-.09.005-.092.017-5.847 1.386 4.578 4.582 3.467-.788-1.202-4.56a.843.843 0 0 0-.814-.642z"
        fill={`${props.fill || 'url(#u771hwde5a)'}`}
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default VideoIcon;
