import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IClientPermissionAttributes } from 'shared/interfaces/IClientPermission';
import { RootState } from '../store';

type IProjectPermissionKey =
  | 'share_session'
  | 'see_director_producer_comment'
  | 'see_director_producer_select_rating'
  | 'select_callback'
  | 'download_media'
  | 'add_comments_ratings';

export interface IClientPermissionState {
  projectPermissions: { [key in IProjectPermissionKey]: boolean } | null;
  generalPermissions: {
    clientView: 'roles' | 'sessions';
  } | null;
  auditionPermission: {
    auto_share: boolean;
    roles: Array<{ id: string; auto_share: boolean; session_ids: Array<string> }>;
    sessions: Array<{ id: string; auto_share: boolean; role_ids: Array<string> }>;
  } | null;
}

export const initialState: IClientPermissionState = {
  projectPermissions: {
    share_session: false,
    add_comments_ratings: false,
    see_director_producer_comment: false,
    see_director_producer_select_rating: false,
    download_media: false,
    select_callback: false,
  },
  generalPermissions: {
    clientView: 'sessions',
  },
  auditionPermission: {
    auto_share: false,
    roles: [],
    sessions: [],
  },
};
export const clientPermissionSlicer = createSlice({
  name: 'clientPermission',
  initialState,
  reducers: {
    initializeProjectPermission: (
      state: IClientPermissionState,
      action: PayloadAction<IClientPermissionAttributes['project_permissions']>,
    ) => {
      const permissions = action.payload?.permissions;
      if (permissions) {
        if (state.projectPermissions) {
          if (permissions.includes('Ability to share sessions')) {
            state.projectPermissions = { ...state.projectPermissions, share_session: true };
          }
          if (permissions.includes(`Ability to see Director & Producer's comment`)) {
            state.projectPermissions = { ...state.projectPermissions, see_director_producer_comment: true };
          }
          if (permissions.includes(`Ability to see Director & Producer's select/ratings`)) {
            state.projectPermissions = { ...state.projectPermissions, see_director_producer_select_rating: true };
          }
          if (permissions.includes('Ability to select talent for Callbacks')) {
            state.projectPermissions = { ...state.projectPermissions, select_callback: true };
          }
          if (permissions.includes('Ability to download media')) {
            state.projectPermissions = { ...state.projectPermissions, download_media: true };
          }
          if (permissions.includes('Ability to add comments and ratings')) {
            state.projectPermissions = { ...state.projectPermissions, add_comments_ratings: true };
          }
        }
      }
    },
    initializeGeneralPermission: (
      state: IClientPermissionState,
      action: PayloadAction<IClientPermissionAttributes['general_permisions']>,
    ) => {
      const default_client_view = action.payload?.default_client_view;
      if (state.generalPermissions) {
        switch (default_client_view) {
          case 'role':
            state.generalPermissions.clientView = 'roles';
            break;
          default:
            state.generalPermissions.clientView = 'sessions';
            break;
        }
      }
    },
    initializeAuditionPermission: (
      state: IClientPermissionState,
      action: PayloadAction<IClientPermissionAttributes['auditions_permissions']>,
    ) => {
      const auditionsPermissions = action.payload;
      if (auditionsPermissions) {
        state.auditionPermission = auditionsPermissions;
      }
    },
  },
});

export const selectClientPermission = (state: RootState) => state.clientPermission;
export const { initializeProjectPermission, initializeGeneralPermission, initializeAuditionPermission } =
  clientPermissionSlicer.actions;

export default clientPermissionSlicer.reducer;
