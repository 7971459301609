import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const DollarSignIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="16" viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.743 10.966c.01-1.266-.491-2.267-1.49-2.974-.77-.544-1.706-.828-2.61-1.101C2.758 6.32 2.21 6.019 2.21 5.117c0-1.004 1.305-1.361 2.422-1.361.813 0 1.75.253 2.33.628l1.153-1.785c-.76-.492-1.8-.828-2.826-.933V0H3.164v1.783C1.274 2.2.084 3.434.084 5.117c0 1.179.49 2.114 1.454 2.773.732.504 1.626.774 2.489 1.036 1.849.559 2.6.899 2.591 2.027v.008c0 .947-1.254 1.283-2.329 1.283-1.015 0-2.119-.445-2.745-1.106L0 12.598c.792.839 1.944 1.431 3.164 1.663V16H5.29v-1.701c2.1-.3 3.45-1.565 3.453-3.333z"
        fill="#8DC54E"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default DollarSignIconTwoTone;
