import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function SelectedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M.833 4.667A.834.834 0 0 1 0 3.833V3C0 1.622 1.122.5 2.5.5h.833a.834.834 0 0 1 0 1.667H2.5A.835.835 0 0 0 1.667 3v.833c0 .46-.374.834-.834.834zm15.001 0a.834.834 0 0 1-.833-.834V3a.835.835 0 0 0-.834-.833h-.833a.834.834 0 0 1 0-1.667h.833c1.379 0 2.5 1.122 2.5 2.5v.833c0 .46-.373.834-.833.834zM10 2.167H6.667a.834.834 0 0 1 0-1.667H10a.834.834 0 0 1 0 1.667zm-1.666 15H6.667a.834.834 0 0 1 0-1.666h1.667a.834.834 0 0 1 0 1.666zm-5 0H2.5a2.503 2.503 0 0 1-2.5-2.5v-.833a.834.834 0 0 1 1.667 0v.833c0 .46.374.834.833.834h.833a.834.834 0 0 1 0 1.666zm-2.5-5.833A.834.834 0 0 1 0 10.5V7.167a.834.834 0 0 1 1.667 0V10.5c0 .46-.374.834-.834.834zM10.751 8c.146 0 .282.057.384.16l7.861 7.86a.543.543 0 0 1-.383.928v.001h-4.207l-3.264 3.385a.543.543 0 0 1-.934-.378V8.545c0-.3.243-.544.543-.544zm5.082-1.666c.46 0 .833.373.833.833v1.667a.834.834 0 0 1-1.666 0V7.167c0-.46.373-.833.833-.833z"
          fill="#677482"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default SelectedIcon;
