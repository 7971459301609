import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function CheckedCalendarIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15 .5c.394 0 .714.32.714.714v1.429h2.143c1.184 0 2.143.96 2.143 2.143v13.571c0 1.184-.96 2.143-2.143 2.143H2.143A2.143 2.143 0 0 1 0 18.357V4.786c0-1.184.96-2.143 2.143-2.143h2.143V1.214a.714.714 0 0 1 1.428 0v1.429h8.572V1.214c0-.394.32-.714.714-.714zm3.571 8.571H1.43v9.286c0 .395.32.714.714.714h15.714c.395 0 .714-.32.714-.714V9.071zm-4.79 1.638a.714.714 0 0 1 1.01 1.01l-5.715 5.715a.714.714 0 0 1-1.01 0L5.21 14.576a.714.714 0 0 1 1.01-1.01l2.352 2.353zM4.286 4.071H2.143a.714.714 0 0 0-.714.715v2.857H18.57V4.786a.714.714 0 0 0-.714-.715h-2.143V5.5a.714.714 0 1 1-1.428 0V4.071H5.714V5.5a.714.714 0 1 1-1.428 0V4.071z"
        fill={props.htmlColor || '#677482'}
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default CheckedCalendarIcon;
