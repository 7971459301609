import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Camera(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 20" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="6trxfn1pxa">
            <stop stop-color="#3E8BFF" offset="0%" />
            <stop stop-color="#3E8BFF" offset="100%" />
          </linearGradient>
        </defs>
        <path
          d="M13.915 0a2.804 2.804 0 0 1 2.665 1.923l.385 1.154h3.303a2.812 2.812 0 0 1 2.809 2.806v11.308A2.812 2.812 0 0 1 20.27 20H2.809A2.812 2.812 0 0 1 0 17.194V5.886a2.812 2.812 0 0 1 2.81-2.81h3.302l.386-1.153A2.805 2.805 0 0 1 9.162 0zm0 1.538H9.162a1.27 1.27 0 0 0-1.205.869l-.56 1.682a.77.77 0 0 1-.73.526H2.809c-.7.001-1.268.568-1.27 1.268v11.308c0 .701.569 1.27 1.27 1.27h17.459c.701 0 1.27-.569 1.27-1.27V5.886c0-.701-.569-1.27-1.27-1.27H16.41a.77.77 0 0 1-.73-.527l-.56-1.681a1.268 1.268 0 0 0-1.205-.87zm-2.377 3.847a5.775 5.775 0 0 1 5.77 5.769 5.77 5.77 0 1 1-5.77-5.77zm0 1.538a4.23 4.23 0 1 0 0 8.462 4.23 4.23 0 0 0 0-8.462zm7.693-.77a.77.77 0 1 1 0 1.54.77.77 0 0 1 0-1.54z"
          fill="url(#6trxfn1pxa)"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default Camera;
