import { FilmIconTwoTone, LoadingSpinnerIconTwoTone } from '@room-match/shared-ui-components';
import clsx from 'clsx';
import { ClientSingleExpandingContentWithTab } from 'components/ClientViewExpandingContent';
import { EmptyList } from 'components/EmptyList';
import React, { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { Typography } from 'shared/elements';
import { IAuditionScheduleGroupAttributes } from 'shared/interfaces/IAuditionScheduleGroup';
import { IClientPermissionState } from 'shared/redux/slicers/clientPermission.slicer';
import { auditionScheduleGroupService } from 'shared/services/auditionScheduleGroupService';
import { clientAuditionSchedule } from 'shared/services/clientAuditionScheduleService';
import { generateGridForExpanded } from 'shared/utils/generateGridForExpanded';
import { getIsViewedAudition } from 'shared/utils/getIsViewedAudition';
import AuditionSingle from './AuditionSingle/AuditionSingle';
import { useStyles } from './ClientAuditionList.styles';
import { TalentProfile } from 'components';
import { IAuditionScheduleAttributes } from 'shared/interfaces/IAuditionSchedule';

type Props = {
  isEditMode?: boolean;
  isExpandedShow?: boolean;
  toggleIsExpandedShown: (isShown: boolean) => void;
  isExpandedSidebar?: boolean;
  name: string;
  projectId: string;
  sessionId: string;
  roleId: string;
  clientPermissionsState?: IClientPermissionState;
};
// (i + 1) % 5 !== 0 ? { grid: 'item', show: true } : { grid: 'expanded', show: false }

const { getClientAuditionSchedules } = clientAuditionSchedule();
const { setAuditionScheduleGroupView } = auditionScheduleGroupService();
const ClientAuditionList: React.FC<Props> = ({
  isEditMode,
  isExpandedShow,
  toggleIsExpandedShown,
  name,
  projectId,
  sessionId,
  roleId,
  isExpandedSidebar,
  clientPermissionsState,
}) => {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState(-1);
  const [cardWidth, setCardWidth] = useState<number>(4);

  const [talent, setTalent] = useState<any>({});
  const [auditionScheduleId, setAuditionScheduleId] = useState<string>('');
  const [isProfileOpened, setisProfileOpened] = useState<boolean>(false);

  const queryClient = useQueryClient();

  const { data: clientAuditionSchedulesData, isLoading: isLoadingClientAuditionSchedule } = getClientAuditionSchedules({
    project_id: projectId,
    casting_session_id: sessionId,
    casting_role_id: roleId,
  });

  const { mutate: setAuditionScheduleGroupViewMutate } = setAuditionScheduleGroupView();

  const clientAuditionSchedules = useMemo(() => {
    return clientAuditionSchedulesData ? clientAuditionSchedulesData.data.client_view_audition_schedules : [];
  }, [clientAuditionSchedulesData]);

  const [data, setData] = useState<ReturnType<typeof generateGridForExpanded<IAuditionScheduleAttributes>>>(
    generateGridForExpanded<IAuditionScheduleAttributes>(clientAuditionSchedules, cardWidth),
  );

  useEffect(() => {
    const generatedData = generateGridForExpanded<IAuditionScheduleAttributes>(clientAuditionSchedules, cardWidth);
    setData(generatedData);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardWidth, clientAuditionSchedules]);

  useEffect(() => {
    if (isExpandedSidebar) {
      setCardWidth(4);
    } else {
      setCardWidth(5);
    }
  }, [isExpandedSidebar]);

  const handleSelectItem = (index: number) => {
    if (selectedItem === index) {
      // Toggle the selected item.
      setSelectedItem(-1);
      let mappedData = data;
      mappedData = mappedData.map((value) => ({ ...value, show: false }));
      const dataToUpdate = mappedData.find((_, i) => i === index);
      if (dataToUpdate) {
        if (dataToUpdate.audition_schedule_groups.length > 0) {
          const getFirstAuditionScheduleGroup = dataToUpdate.audition_schedule_groups[0];

          if (!getFirstAuditionScheduleGroup.viewed) {
            const mappedAuditionedScheduleGroup = dataToUpdate.audition_schedule_groups.map(
              (group: IAuditionScheduleGroupAttributes) =>
                group.id === getFirstAuditionScheduleGroup.id ? { ...group, viewed: true } : group,
            );
            mappedData = mappedData.map((value) =>
              value.id === dataToUpdate.id
                ? { ...value, audition_schedule_groups: mappedAuditionedScheduleGroup }
                : value,
            );
          }
        }
      }

      setData(mappedData);
      toggleIsExpandedShown(false);
    } else {
      const hasShowIndex = data.findIndex((v) => v.grid === 'expanded' && v.show);
      let mappedData = data;

      // If there's an expanded div that show then hide it.
      if (hasShowIndex > 0) {
        mappedData = mappedData.map((value, i) => (i === hasShowIndex ? { ...value, show: false } : value));
      }
      // Itarate the grid and get the index of the nearest selected item.
      let indexToShow = index;
      for (let i = index; i < data.length; i++) {
        if (data[i].grid === 'expanded') {
          indexToShow = i;
          break;
        }
      }

      // Update the grid and set the item to show.
      mappedData = mappedData.map((value, i) => (i === indexToShow ? { ...value, show: true } : value));

      const dataToUpdate = mappedData.find((_, i) => i === index);
      if (dataToUpdate) {
        if (dataToUpdate.audition_schedule_groups.length > 0) {
          const getFirstAuditionScheduleGroup = dataToUpdate.audition_schedule_groups[0];

          if (!getFirstAuditionScheduleGroup.viewed) {
            const mappedAuditionedScheduleGroup = dataToUpdate.audition_schedule_groups.map(
              (group: IAuditionScheduleGroupAttributes) =>
                group.id === getFirstAuditionScheduleGroup.id ? { ...group, viewed: true } : group,
            );
            mappedData = mappedData.map((value) =>
              value.id === dataToUpdate.id
                ? { ...value, audition_schedule_groups: mappedAuditionedScheduleGroup }
                : value,
            );

            setAuditionScheduleGroupViewMutate(getFirstAuditionScheduleGroup.id, {
              onSettled: () => {
                queryClient.invalidateQueries(['auditionScheduleGroup'], {
                  refetchInactive: true,
                  refetchActive: false,
                });
                queryClient.invalidateQueries(['clientAuditionSchedule'], {
                  refetchInactive: true,
                  refetchActive: false,
                });
              },
            });
          }
        }
      }

      setSelectedItem(index);
      setData(mappedData);
      toggleIsExpandedShown(true);
    }
  };

  const handleCloseExpandingContent = () => {
    setSelectedItem(-1);
    let mappedData = data;
    mappedData = mappedData.map((value) => ({ ...value, show: false }));
    setData(mappedData);
    toggleIsExpandedShown(false);
  };

  const handleViewedAuditionScheduleGroup = (auditionScheduleGroupId: string) => {
    const getSelectedAudition = data.find((_, i) => i === selectedItem);
    if (getSelectedAudition) {
      const getGroupInSelectedAudition =
        getSelectedAudition.audition_schedule_groups as IAuditionScheduleGroupAttributes[];
      if (getGroupInSelectedAudition.length > 0) {
        const getAuditionScheduleGroup = getGroupInSelectedAudition.find(
          (value) => value.id === auditionScheduleGroupId,
        );
        if (getAuditionScheduleGroup) {
          if (!getAuditionScheduleGroup.viewed) {
            setAuditionScheduleGroupViewMutate(auditionScheduleGroupId, {
              onSettled: () => {
                queryClient.invalidateQueries(['auditionScheduleGroup'], {
                  refetchInactive: true,
                  refetchActive: false,
                });
                queryClient.invalidateQueries(['clientAuditionSchedule'], {
                  refetchInactive: true,
                  refetchActive: false,
                });
              },
            });
          }
        }
      }
    }
  };

  const getSelectedAuditionSchedule = useMemo(() => {
    return data.find((_, index) => index === selectedItem);
  }, [data, selectedItem]);

  const handleOpenTalentProfile = (talent: any, auditionScheduleId: string) => {
    setTalent(talent);
    setAuditionScheduleId(auditionScheduleId);
    setisProfileOpened(true);
  };

  return (
    <div className={classes.auditionList}>
      {/* Header */}
      <div className={classes.auditionList__header}>
        <div className={classes.auditionList__headerContent}>
          <FilmIconTwoTone fontSize="small" />
          <Typography fontSize={14}>{name}</Typography>
        </div>
      </div>
      {/* List */}
      <div className={clsx(classes.auditionList__listContainer, classes.auditionList__clientRoleListContianer)}>
        {!isLoadingClientAuditionSchedule ? (
          <React.Fragment>
            {data.length > 0 ? (
              <React.Fragment>
                {data.map((value, i) => (
                  <React.Fragment>
                    {value.grid === 'item' ? (
                      <div
                        key={value.id}
                        className={classes.auditionList__listItem}
                        style={{ width: `calc((100% / ${cardWidth}) - 16px)` }}
                      >
                        <AuditionSingle
                          index={i}
                          handleSelectItem={handleSelectItem}
                          isSelected={selectedItem === i}
                          isEditMode={isEditMode}
                          hasShownExpanded={isExpandedShow}
                          auditionSchedule={value}
                          groupId={value.id}
                          sessionId={sessionId}
                          isViewed={getIsViewedAudition(value.audition_schedule_groups)}
                          auditionScheduleGroup={value.audition_schedule_groups[0]}
                          clientPermissionsState={clientPermissionsState}
                          handleOpenTalentProfile={handleOpenTalentProfile}
                        />
                      </div>
                    ) : (
                      <>
                        {value.show && getSelectedAuditionSchedule && (
                          <div className={classes.auditionList__listItem} style={{ width: `100%` }}>
                            <ClientSingleExpandingContentWithTab
                              isEditMode={isEditMode}
                              audition={getSelectedAuditionSchedule.audition}
                              mediums={getSelectedAuditionSchedule.medium_attachments || []}
                              auditionScheduleGroups={getSelectedAuditionSchedule.audition_schedule_groups}
                              handleViewedAuditionScheduleGroup={handleViewedAuditionScheduleGroup}
                              auditionSchedule={getSelectedAuditionSchedule}
                              clientPermissionsState={clientPermissionsState}
                              handleOpenTalentProfile={handleOpenTalentProfile}
                              sessionId={sessionId}
                              handleCloseExpandingContent={handleCloseExpandingContent}
                            />
                          </div>
                        )}
                      </>
                    )}
                  </React.Fragment>
                ))}
              </React.Fragment>
            ) : (
              <EmptyList text="No Auditions" />
            )}
          </React.Fragment>
        ) : (
          <div className={classes.auditionList__loadingContainer}>
            <LoadingSpinnerIconTwoTone style={{ fontSize: '5em' }} />
          </div>
        )}
      </div>
      {isProfileOpened && (
        <TalentProfile
          data={talent}
          auditionScheduleId={auditionScheduleId}
          open={isProfileOpened}
          onClose={() => {
            setTalent({});
            setisProfileOpened(false);
          }}
        />
      )}
    </div>
  );
};

export default ClientAuditionList;
