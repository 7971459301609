import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    resumeContainer: {
      maxHeight: 600,
      overflow: 'auto',
    },
    resumeList: {},
    resumeList__item: {
      '&:not(:first-child)': {
        marginTop: theme.spacing(4),
      },
    },
    resumeList__titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(0, 2),
    },
    resumeList__tableContainer: {
      width: '100%',
    },
    resumeList__tableData: {
      color: theme.palette.customGrey.dark,
      paddingTop: 12,
      paddingBottom: 12,
    },
    resumeList__mediaContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
    },
  }),
);
