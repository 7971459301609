import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    inputContainer: {
      marginTop: 25,
      display: 'flex',
      '& ul': {
        backgroundColor: theme.palette.type === 'dark' ? '#0d1117 !important' : 'white',
      },
      '& li:hover': {
        color: theme.palette.type === 'dark' ? '#0d1117 !important' : '#0d1117 !important',
      },
      '& li.highlight': {
        color: theme.palette.type === 'dark' ? '#0d1117 !important' : '#0d1117 !important',
      },
      '& input': {
        color: theme.palette.type === 'dark' ? '#f1f1f1 !important' : '#0d1117 !important',
      },
      '& input:disabled': {
        opacity: '0.5',
      },
    },
    contactInput_class: {
      height: 'inherit !important',
      padding: '10px 16px 10px 50px',
      fontSize: '14px !important',
      background: theme.palette.type === 'dark' ? '#0e1319 !important' : '#FFFFFF !important',
      caretColor: theme.palette.type === 'dark' ? '#2d343e !important' : '#d5dbe3 !important',
      borderRadius: '4px !important',
      border: theme.palette.type === 'dark' ? 'solid 1px #2d343e !important' : 'solid 1px #d5dbe3 !important',
      width: '116px !important',
      marginRight: '10px !important',
    },
    contactContainer_class: {
      width: 'unset',
      '& .flag-dropdown.open, & .selected-flag, & .flag-dropdown': {
        background: theme.palette.type === 'dark' ? '#0e1319 !important' : '#FFFFFF !important',
      },
    },
    contactButton_class: {
      border: theme.palette.type === 'dark' ? 'solid 1px #2d343e !important' : 'solid 1px #d5dbe3 !important',
      borderRight: 'none !important',
      borderTopLeftRadius: '4px !important',
      borderBottomLeftRadius: '4px !important',
      height: 'inherit !important',
    },
  }),
);
