import React from 'react';
import { useMemo, useState } from 'react';
import { StyledTabSF, StyledTabsSF } from 'shared/elements/StyledTabSocialFeed';
import { ITab } from '@room-match/shared-ui-components';
import { Typography } from 'shared/elements';
import { useStyles } from './SocialFeedContent.styles';
import InstagramFeeds from './SocialFeeds/InstagramFeed/InstagramFeedContent';
import TikTokFeeds from './SocialFeeds/TiktokFeedContent';
import YoutubeFeeds from './SocialFeeds/YoutubeFeedContent';
import FacebookFeeds from './SocialFeeds/FacebookFeedContent';
import TwitterFeeds from './SocialFeeds/TwitterFeedContent';

const tabs: ITab[] = [
  {
    name: 'instagram',
    header: 'INSTAGRAM',
    component: <InstagramFeeds />,
  },
  {
    name: 'tiktok',
    header: 'TIKTOK',
    component: <TikTokFeeds />,
  },
  {
    name: 'youtube',
    header: 'YOUTUBE',
    component: <YoutubeFeeds />,
  },
  {
    name: 'facebook',
    header: 'FACEBOOK',
    component: <FacebookFeeds />,
  },
  {
    name: 'twitter',
    header: 'TWITTER',
    component: <TwitterFeeds />,
  },
];

const SocialFeedContent = () => {
  const classes = useStyles();
  const [tab, setTab] = useState<string>('instagram');

  const getActiveTab = useMemo(() => {
    if (!tab) {
      return tabs[0].component;
    }
    return tabs.find((_tab) => _tab.name === tab)?.component;
  }, [tab]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (tab !== newValue) {
      setTab(newValue);
    }
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  return (
    <div className={classes.socialFeedContainer}>
      <StyledTabsSF value={!tab ? 'instagram' : tab} onChange={handleTabChange} aria-label="simple tabs example">
        {tabs.map((tab, index) => (
          <StyledTabSF key={index} label={tab.header} value={tab.name} disabled={tab.disabled} {...a11yProps(index)} />
        ))}
      </StyledTabsSF>
      {/* <div className={classes.socialFeedTabContainer}>{getActiveTab}</div> */}
      <div>{getActiveTab}</div>
    </div>
  );
};

export default SocialFeedContent;
