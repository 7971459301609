import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      maxHeight: '95%',
      top: 20,
    },
    container: {
      display: 'flex',
      gap: 24,
    },
    imageContainer: {
      width: 280,
      height: 320,
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: '50% 50%',
      borderRadius: 4,
    },
    detailsContainer: {
      flex: 1,
    },
    details__header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    details__agencyContainer: {
      marginTop: theme.spacing(2),
    },
    details__description: {
      display: 'flex',
      gap: 24,
      alignItems: 'center',
      marginTop: theme.spacing(1),
    },
    description__division: {
      width: 'calc(100% / 3)',
    },
    description__rep: {
      width: 'calc(100% / 3)',
    },
    description__logo: {
      width: 'calc(100% / 3)',
    },
    description__logoImage: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },
    details__shareProfileContainer: {
      marginTop: theme.spacing(2),
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    shareProfile__linkWrapper: {
      backgroundColor: '#0e1319',
      border: '1px solid #2d343e',
      padding: theme.spacing(2),
      borderRadius: 4,
    },
  }),
);
