import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      backgroundImage: 'linear-gradient(to bottom, #0D0F14, #0D0F14), linear-gradient(253deg, #d51bff, #00d4ff)',
    },
    dialogContent: {
      padding: '32px 64px',
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(4),
    },
    buttonError: {
      border: `1px solid ${theme.palette.error.main} !important`,
    },
    buttonContainer: {
      marginTop: theme.spacing(6),
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);
