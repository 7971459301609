import { gql } from 'graphql-request';

export const SEARCH_PROJECTS = gql`
  query ($search: String) {
    projects(search: $search) {
      collection {
        id
        name
        status
        project_type
        union
        sag_aftra_contract_type
        contact_email
        contact_number
        conflicts
        usage
        rate
        additional_details
        production_company
        slug
        with_self_tapes_roles
        with_auditioned
        with_sessions
        additional_provisions
        production_company
        ad_agency
        producer
        director
        ad_agency
        logo
        aggregates {
          decisions {
            booked
            called_back
            on_avail
            released
            selects
            th
          }
          self_tapes {
            approved
            for_review
            requested
          }
          submissions {
            auditioned
            new
            selected
            viewed
          }
        }
        worksheet_aggregates {
          all
          auditioned
          call_back
          on_avail
          released
          booked
          stn_twelve
          th
          no_response
          late
          no_show
        }
        casting_roles {
          id
          name
          # description
          age_range_from
          age_range_to
          ethnic_appearance
          rate_summary
          wardrobe
        }
        casting_sessions {
          id
          name
          # description
        }
        medium_attachments {
          primary
          medium_id
          mediumable_id
          mediumable_type
          attachment
          modified_attachment
          sort
          medium {
            file_type
            file_name
            file_size
            file_width
            file_height
            visible
          }
        }
        staff_attachments {
          position
          staff {
            position_name
            archived_at
            user {
              first_name
              last_name
            }
          }
        }
        contact_attachments {
          id
          contactable_type
          contactable_id
          visible_to_agents
          visible_to_notice_board
          project_permissions
          auditions_permissions
          contact {
            company_name
            cell_contact_country_code
            cell_contact_number
            office_contact_country_code
            office_contact_number
            address1
            address2
            city_suburb
            state_code
            country_code
            zip_code
            note
            position
            user {
              id
              first_name
              last_name
            }
          }
        }
      }
    }
  }
`;

export const GET_SINGLE_PROJECT = gql`
  query ($id: ID!) {
    project(id: $id) {
      id
      name
      status
      project_type
      union
      sag_aftra_contract_type
      contact_email
      contact_number
      conflicts
      usage
      rate
      additional_details
      production_company
      slug
      with_self_tapes_roles
      with_auditioned
      with_sessions
      additional_provisions
      production_company
      ad_agency
      producer
      director
      ad_agency
      logo
      aggregates {
        decisions {
          booked
          called_back
          on_avail
          released
          selects
          th
        }
        self_tapes {
          approved
          for_review
          requested
        }
        submissions {
          auditioned
          new
          selected
          viewed
        }
      }
      worksheet_aggregates {
        all
        auditioned
        call_back
        on_avail
        released
        booked
        stn_twelve
        th
        no_response
        late
        no_show
      }
      casting_roles {
        id
        name
        # description
        age_range_from
        age_range_to
        ethnic_appearance
        rate_summary
        wardrobe
      }
      casting_sessions {
        id
        name
        # description
      }
      medium_attachments {
        primary
        medium_id
        mediumable_id
        mediumable_type
        attachment
        modified_attachment
        sort
        medium {
          file_type
          file_name
          file_size
          file_width
          file_height
          visible
        }
      }
      staff_attachments {
        position
        staff {
          position_name
          archived_at
          user {
            first_name
            last_name
          }
        }
      }
      contact_attachments {
        id
        contactable_type
        contactable_id
        visible_to_agents
        visible_to_notice_board
        project_permissions
        auditions_permissions
        contact {
          company_name
          cell_contact_country_code
          cell_contact_number
          office_contact_country_code
          office_contact_number
          address1
          address2
          city_suburb
          state_code
          country_code
          zip_code
          note
          position
          user {
            id
            first_name
            last_name
          }
        }
      }
    }
  }
`;

export const SEARCH_CASTING_ROLES_BY_PROJECT = gql`
  query ($projectId: ID!, $search: String!) {
    project_casting_roles(projectId: $projectId, search: $search) {
      id
      name
      age_range_from
      age_range_to
      ethnic_appearance
      rate_summary
      wardrobe
      casting_sessions {
        id
        name
        session_type
        session_date
      }
    }
  }
`;

export const GET_CASTING_SESSIONS_BY_PROJECT = gql`
  query ($projectId: ID!, $archived: Boolean) {
    project_casting_sessions(projectId: $projectId, archived: $archived) {
      id
      slug
      name
      description
      session_type
      status
      session_date
      archived
      aggregates {
        scheduled
        to_send
        messages
        declined
        reschedule
        no_response
        confirmed
        checked_in
        auditioned
        late
        overtime
        no_show
        media_uploaded
      }
      casting_roles {
        id
        name
        audition_type
        age_range_from
        age_range_to
        talent_description
        ethnic_appearance
        rate_summary
        wardrobe
        aggregates {
          submissions {
            new
            viewed
            selected
            auditioned
          }
          self_tapes {
            requested
            confirmed
            declined
            no_response
            new
            need_edit
            hidden
            for_cd_review
            approved
          }
          decisions {
            called_back
            selects
            on_avail
            released
            booked
            th
          }
        }
      }
      schedules {
        id
        time_in
      }
    }
  }
`;
