import { useMutation, useQuery } from 'react-query';
import { studioSessionDao } from 'shared/dao/studioSessionDao';
import { ICommonResponsePayload } from 'shared/interfaces/ICommon';
import {
  ISessionAttachQuestionPayload,
  ISessionAttachQuestionResponsePayload,
  ISessionCreatePayload,
  ISessionCreateResponsePayload,
  ISessionImportResponsePayload,
  ISessionMediumAttachmentRequestPayload,
  ISessionMediumAttachmentResponsePayload,
  ISessionQuestionsResponsePayload,
  ISessionResponsePayload,
  ISessionSortAuditionScheduleGroupPayload,
} from 'shared/interfaces/ISession';

import { IAxiosErrorResponse, IOnProgress } from 'shared/interfaces/utils/IAxios';

const {
  getStudioSessions: getStudioSessionsDao,
  getOneStudioSession: getOneStudioSessionDao,
  createStudioSession: createStudioSessionDao,
  updateStudioSession: updateStudioSessionDao,
  importStudioSession: importStudioSessionDao,
  getSessionMediumAttachments: getSessionMediumAttachmentsDao,
  attachStudioSessionQuestion: attachStudioSessionQuestionDao,
  getStudioSessionQuestions: getStudioSessionQuestionsDao,
  studioSessionSortAuditionScheduleGroup: studioSessionSortAuditionScheduleGroupDao,
  deleteSession: deleteSessionDao,
  archiveSession: archiveSessionDao,
} = studioSessionDao();
export const studioSessionService = () => {
  const getStudioSessions = (projectId: string) => {
    return useQuery<ISessionResponsePayload, IAxiosErrorResponse>(
      ['studioSession', projectId],
      () => getStudioSessionsDao(projectId),
      {
        enabled: !!projectId,
      },
    );
  };

  const getOneStudioSession = (projectId: string, sessionId: string) => {
    return useQuery<ISessionResponsePayload, IAxiosErrorResponse>(
      ['studioSession', projectId, sessionId],
      () => getOneStudioSessionDao(projectId, sessionId),
      {
        enabled: !!projectId && !!sessionId,
      },
    );
  };

  const createStudioSession = () => {
    return useMutation<
      ISessionCreateResponsePayload,
      IAxiosErrorResponse,
      { projectId: string; payload: ISessionCreatePayload }
    >(({ projectId, payload }) => createStudioSessionDao(projectId, payload));
  };

  const updateStudioSession = () => {
    return useMutation<
      ISessionCreateResponsePayload,
      IAxiosErrorResponse,
      { projectId: string; sessionId: string; payload: ISessionCreatePayload }
    >(({ projectId, sessionId, payload }) => updateStudioSessionDao(projectId, sessionId, payload));
  };

  const importStudioSession = () => {
    return useMutation<
      ISessionImportResponsePayload,
      IAxiosErrorResponse,
      { formData: FormData; onProgress?: IOnProgress }
    >(({ formData, onProgress }) => importStudioSessionDao(formData, onProgress));
  };

  const getSessionMediumAttachments = (payload: ISessionMediumAttachmentRequestPayload) => {
    return useQuery<
      ISessionMediumAttachmentResponsePayload,
      IAxiosErrorResponse,
      ISessionMediumAttachmentRequestPayload
    >(['studioSession/mediumAttachments', payload], () => getSessionMediumAttachmentsDao(payload));
  };

  const attachStudioSessionQuestion = () => {
    return useMutation<
      ISessionAttachQuestionResponsePayload,
      IAxiosErrorResponse,
      { projectId: string; sessionId: string; payload: ISessionAttachQuestionPayload }
    >(({ projectId, sessionId, payload }) => attachStudioSessionQuestionDao(projectId, sessionId, payload));
  };

  const getStudioSessionQuestions = (projectId: string, sessionId: string) => {
    return useQuery<ISessionQuestionsResponsePayload, IAxiosErrorResponse>(
      ['studioSessionQuestions', projectId, sessionId],
      () => getStudioSessionQuestionsDao(projectId, sessionId),
      {
        enabled: !!projectId && !!sessionId,
      },
    );
  };

  const studioSessionSortAuditionScheduleGroup = () => {
    return useMutation<
      ICommonResponsePayload,
      IAxiosErrorResponse,
      { sessionId: string; payload: ISessionSortAuditionScheduleGroupPayload }
    >(({ sessionId, payload }) => studioSessionSortAuditionScheduleGroupDao(sessionId, payload));
  };

  const deleteSession = () => {
    return useMutation<ICommonResponsePayload, IAxiosErrorResponse, { projectId: string; sessionId: string }>(
      ({ projectId, sessionId }) => deleteSessionDao(projectId, sessionId),
    );
  };
  const archiveSession = () => {
    return useMutation<ICommonResponsePayload, IAxiosErrorResponse, { projectId: string; sessionId: string }>(
      ({ projectId, sessionId }) => archiveSessionDao(projectId, sessionId),
    );
  };

  return {
    getStudioSessions,
    getOneStudioSession,
    createStudioSession,
    updateStudioSession,
    importStudioSession,
    getSessionMediumAttachments,
    attachStudioSessionQuestion,
    getStudioSessionQuestions,
    studioSessionSortAuditionScheduleGroup,
    deleteSession,
    archiveSession,
  };
};
