import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function ClapperBoardIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="dpvzjohjwa">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="m23.073 1 .041.135.851 3.23a.5.5 0 0 1-.296.592l-.077.025L5.93 8.994h17.551a.5.5 0 0 1 .492.41l.008.09V21.5a2.504 2.504 0 0 1-2.336 2.496L21.48 24H3.474a2.504 2.504 0 0 1-2.496-2.337L.973 21.5.972 10.308.046 6.647A1.482 1.482 0 0 1 .15 5.629l.072-.133c.187-.303.467-.525.8-.636l.145-.041L21.32.037A1.513 1.513 0 0 1 23.073 1zm-.093 12.996H1.973v7.503c0 .779.597 1.42 1.356 1.494l.145.007H21.48c.78 0 1.421-.597 1.494-1.357l.007-.144-.001-7.503zM4.664 9.994h-2.69l-.001 3.001h1.191l1.5-3.001zm5.002 0H5.784l-1.501 3.001h3.883l1.5-3.001zm5.001 0h-3.882l-1.501 3.001h3.883l1.5-3.001zm5.002 0h-3.882l-1.501 3.001h3.883l1.5-3.001zm3.312 0h-2.192l-1.501 3.001h3.692l.001-3zM2.341 5.568l-.947.225a.505.505 0 0 0-.391.532l.013.08.642 2.535 3.303-.751-2.62-2.621zm4.852-1.151-3.708.88 2.629 2.63 3.741-.85-2.662-2.66zm4.855-1.152-3.711.881 2.671 2.669 3.738-.85-2.698-2.7zm4.848-1.15-3.704.879 2.706 2.71 3.735-.849-2.737-2.74zm4.76-1.116-.054.004-.055.01-3.508.831 2.747 2.749 2.08-.472-.721-2.736a.506.506 0 0 0-.489-.386z"
        fill="url(#dpvzjohjwa)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default ClapperBoardIcon;
