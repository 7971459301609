import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Notifications(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m17.608 14.755-1.221-1.757a1.428 1.428 0 0 1-.258-.814V7.142a7.142 7.142 0 0 0-14.283 0v5.042c0 .29-.09.575-.257.814L.368 14.755a1.428 1.428 0 0 0 1.057 2.385h4.064a3.57 3.57 0 0 0 6.998 0h4.064a1.428 1.428 0 0 0 1.057-2.385zm-8.62 3.813a2.142 2.142 0 0 1-2.014-1.428h4.028a2.142 2.142 0 0 1-2.014 1.428zm-7.563-2.857a.578.578 0 0 0 .086-.1l1.25-1.8a2.857 2.857 0 0 0 .514-1.627V7.142a5.713 5.713 0 0 1 11.426 0v5.042c.002.582.181 1.15.514 1.628l1.25 1.8c.025.036.054.07.086.1H1.425z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default Notifications;
