import { Layout } from 'components/Layout';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route, useHistory } from 'react-router';
import { ROUTES } from 'shared/constants/ROUTES';
import { getUserProfile, selectUserState } from 'shared/redux/slicers/user.slicer';
import { tokenService } from 'shared/services/tokenService';

type Props = {
  component: React.FC;
  exact?: boolean;
  path: string;
};
const { getAuthToken, isAuthenticated } = tokenService();
const PrivateRoute: React.FC<Props> = ({ component: Component, exact, path }) => {
  // isAuthenticated() ? <Component /> : <Redirect to={ROUTES.LOGIN} />

  const authToken = getAuthToken();
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector(selectUserState);
  const history = useHistory();
  useEffect(() => {
    if (authToken && !isLoggedIn) {
      dispatch(getUserProfile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, isLoggedIn]);

  useEffect(() => {
    if (user) {
      if (user.attributes.user_types.includes('contact')) {
        history.push(ROUTES.CLIENT.PROJECTS.PROJECT_LIST);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <Layout>
      <Route
        component={() => {
          if (isAuthenticated()) {
            return <Component />;
          } else {
            return <Redirect to={ROUTES.LOGIN} />;
          }
        }}
        exact={exact}
        path={path}
      />
    </Layout>
  );
};

export default PrivateRoute;
