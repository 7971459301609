import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const TiktokOutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="20" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.478 20a6.451 6.451 0 0 1-4.58-1.876 6.307 6.307 0 0 1-1.889-4.94 6.369 6.369 0 0 1 1.603-3.833 6.444 6.444 0 0 1 4.866-2.176c.324 0 .651.024.973.071l.5.075v4.437l-.77-.254a2.202 2.202 0 0 0-2.265.545c-.416.422-.64.98-.63 1.572a2.185 2.185 0 0 0 1.074 1.85 2.203 2.203 0 0 0 3.33-1.883l.004-4.602V0h4.24l.006.58a4.163 4.163 0 0 0 1.662 3.293c.728.55 1.598.84 2.513.84.022 0 .022 0 .217.014l.545.039v4.179H17.092a8.168 8.168 0 0 1-3.236-.658 8.429 8.429 0 0 1-.91-.451l.013 5.774a6.341 6.341 0 0 1-1.9 4.514 6.444 6.444 0 0 1-3.609 1.804 6.62 6.62 0 0 1-.972.072zm0-11.654a5.287 5.287 0 0 0-3.993 1.786 5.194 5.194 0 0 0-1.307 3.124 5.152 5.152 0 0 0 1.543 4.034 5.29 5.29 0 0 0 3.757 1.538c.268 0 .537-.02.8-.059a5.279 5.279 0 0 0 2.957-1.478 5.175 5.175 0 0 0 1.552-3.682l-.018-8.062.947.73c.488.377 1.026.69 1.596.931.78.33 1.567.512 2.393.556V5.87a5.296 5.296 0 0 1-2.81-1.06 5.334 5.334 0 0 1-2.09-3.639h-1.94v7.814l-.003 4.602c0 1.454-.93 2.739-2.314 3.196a3.382 3.382 0 0 1-2.79-.307 3.348 3.348 0 0 1-1.645-2.84 3.341 3.341 0 0 1 .967-2.41 3.353 3.353 0 0 1 2.698-.995V8.355c-.1-.006-.2-.009-.3-.009z"
        fill="#FFF"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default TiktokOutlinedIcon;
