import { Button, InputAdornment } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import DatePickerComp from './DatePickerComp/DatePickerComp';
import { format } from 'date-fns';
import { Input, InputProps } from 'shared/elements';
import { CalendarIconTwoTone } from 'components/Icons';
import { useStyles } from './DatePicker.styles';
import clsx from 'clsx';

type Props = {
  startIcon?: React.ReactNode;
  onChooseDate?: (value: Date[] | Date, valueText?: string) => void;
  type?: 'range' | 'date' | 'preset-range';
  selectMultiple?: boolean;
  label?: string;
  componentType?: 'button' | 'input';
  inputProps?: InputProps;
  value?: Date | Date[];
  classes?: { button: string };
  buttonProps?: { error: boolean };
};
const DatePicker: React.FC<Props> = ({
  startIcon,
  onChooseDate,
  type,
  selectMultiple,
  label,
  componentType,
  inputProps,
  value,
  classes,
  buttonProps,
}) => {
  const [isDatePickerOpen, setIsDatePickerOpen] = useState<boolean>(false);
  const [inputText, setInputText] = useState<string | null>(null);
  const rootClasses = useStyles();

  // Open Date Picker Modal
  const handleOpenDatePicker = () => {
    setIsDatePickerOpen(true);
  };

  const handleCloseDatePicker = () => {
    setIsDatePickerOpen(false);
  };

  useEffect(() => {
    if (value) {
      if (Array.isArray(value)) {
        const [startDate, endDate] = value;
        const formattedStartDate = format(startDate as Date, 'MMM dd yyy');
        const formattedEndDate = format(endDate as Date, 'MMM dd yyy');
        const newDateRange = `${formattedStartDate} - ${formattedEndDate}`;
        setInputText(newDateRange);
      } else {
        const formattedDate = format(new Date(value), 'MMM dd yyy');
        setInputText(formattedDate);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  const handleSetInputText = (date: Date | Date[]) => {
    if (value) {
      if (Array.isArray(date)) {
        const [startDate, endDate] = date;
        const formattedStartDate = format(startDate as Date, 'MMM dd yyy');
        const formattedEndDate = format(endDate as Date, 'MMM dd yyy');
        const newDateRange = `${formattedStartDate} - ${formattedEndDate}`;
        setInputText(newDateRange);
      } else {
        const formattedDate = format(date, 'MMM dd yyy');
        setInputText(formattedDate);
      }
    }
  };

  const renderComponentType = () => {
    switch (componentType) {
      case 'button':
        return (
          <Button
            variant="contained"
            startIcon={startIcon}
            onClick={() => handleOpenDatePicker()}
            classes={{
              root: clsx(classes?.button, {
                [rootClasses.buttonError]: buttonProps?.error,
              }),
            }}
          >
            {label}
          </Button>
        );
      case 'input':
        return (
          <div>
            <Input
              type="text"
              name="session_date"
              variant="outlined"
              fullWidth
              onClick={() => handleOpenDatePicker()}
              value={inputText}
              InputProps={{
                readOnly: true,
                endAdornment: (
                  <InputAdornment position="end">
                    <CalendarIconTwoTone style={{ width: '16px' }} />
                  </InputAdornment>
                ),
              }}
              {...inputProps}

              // errorMessage={getErrorMessage(form.touched.session_date, form.errors.session_date)}
            />
          </div>
        );

      default:
        break;
    }
  };

  return (
    <div>
      <div>{renderComponentType()}</div>

      <DatePickerComp
        open={isDatePickerOpen}
        onClose={handleCloseDatePicker}
        onChooseDate={onChooseDate}
        type={type}
        selectMultiple={selectMultiple}
        handleSetInputText={handleSetInputText}
        defaultValue={value}
      />
    </div>
  );
};

export default DatePicker;

DatePicker.defaultProps = {
  type: 'date',
  componentType: 'button',
};
