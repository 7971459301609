export const PROJECT_TYPES = [
  { key: 'Audio Books', value: 'Audio Books' },
  { key: 'Brand Ambassador', value: 'Brand Ambassador' },
  { key: 'Cabaret', value: 'Cabaret' },
  { key: 'Comedy Show', value: 'Comedy Show' },
  { key: 'Commercial', value: 'Commercial' },
  { key: 'Daytime Drama', value: 'Daytime Drama' },
  { key: `Director's Reel`, value: `Director's Reel` },
  { key: 'Documentary', value: 'Documentary' },
  { key: 'Educational / Training', value: 'Educational / Training' },
  { key: 'Feature Film', value: 'Feature Film' },
  { key: 'Game Show', value: 'Game Show' },
  { key: 'Industrial', value: 'Industrial' },
  { key: 'Infomercial', value: 'Infomercial' },
  { key: 'Internet Commercial', value: 'Internet Commercial' },
  { key: 'Live Event', value: 'Live Event' },
  { key: 'Mini Series', value: 'Mini Series' },
  { key: 'Mobile App', value: 'Mobile App' },
  { key: 'Music', value: 'Music' },
  { key: 'Music Video', value: 'Music Video' },
  { key: 'Other', value: 'Other' },
  { key: 'PSA', value: 'PSA' },
  { key: 'Pilot', value: 'Pilot' },
  { key: 'Podcast', value: 'Podcast' },
  { key: 'Print', value: 'Print' },
  { key: 'Promo', value: 'Promo' },
  { key: 'Promotional Event', value: 'Promotional Event' },
  { key: 'Radio', value: 'Radio' },
  { key: 'Re-enactment', value: 'Re-enactment' },
  { key: 'Reality TV', value: 'Reality TV' },
  { key: 'Runway Show', value: 'Runway Show' },
  { key: 'Short Film', value: 'Short Film' },
  { key: 'Short Video', value: 'Short Video' },
  { key: 'Sketch', value: 'Sketch' },
  { key: 'Stock Photography', value: 'Stock Photography' },
  { key: 'Streaming Series', value: 'Streaming Series' },
  { key: 'Student Film', value: 'Student Film' },
  { key: 'Student Project', value: 'Student Project' },
  { key: 'Talk Show', value: 'Talk Show' },
  { key: 'Television Film', value: 'Television Film' },
  { key: 'Television Series', value: 'Television Series' },
  { key: 'Theatre', value: 'Theatre' },
  { key: 'Variety Show', value: 'Variety Show' },
  { key: 'Video Game', value: 'Video Game' },
  { key: 'Virtual Reality', value: 'Virtual Reality' },
  { key: 'Voice-Over', value: 'Voice-Over' },
  { key: 'Web Series', value: 'Web Series' },
];
