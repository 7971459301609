import { createTheme, CssBaseline } from '@material-ui/core';
import { ThemeProvider } from '@material-ui/styles';
import React from 'react';
import { useAppSelector } from 'shared/redux/store';
import { darkTheme } from './dark';
import { lightTheme } from './light';

type Props = {
  children: React.ReactNode;
};

const getThemeByName = (theme: 'dark' | 'light') => {
  return theme === 'dark' ? darkTheme : lightTheme;
};

const SwitchThemeProvider: React.FC<Props> = ({ children }) => {
  const { mode } = useAppSelector(({ theme }) => theme);
  const theme = getThemeByName(mode);

  return (
    <ThemeProvider theme={createTheme(theme)}>
      <CssBaseline />
      {children}
    </ThemeProvider>
  );
};

export default SwitchThemeProvider;
