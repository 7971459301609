import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function MicrophoneIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="17" height="24" viewBox="0 0 17 24" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="o7akn6p7ga">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M15.703 10.547c.388 0 .703.315.703.703 0 4.286-3.304 7.815-7.5 8.173v3.17h3.797a.703.703 0 0 1 0 1.407h-9a.703.703 0 0 1 0-1.406H7.5v-3.171C3.305 19.065 0 15.536 0 11.25a.703.703 0 0 1 1.406 0 6.805 6.805 0 0 0 6.797 6.797A6.805 6.805 0 0 0 15 11.25c0-.388.315-.703.703-.703zM8.484 0c2.714 0 4.922 2.229 4.922 4.969v6.562a4.927 4.927 0 0 1-4.922 4.922h-.562A4.927 4.927 0 0 1 3 11.531V4.96C3.005 2.224 5.211 0 7.922 0zm0 1.406h-.562c-1.798 0-3.284 1.375-3.49 3.14h1.146a.703.703 0 0 1 0 1.407H4.406v1.594h1.922a.703.703 0 0 1 0 1.406H4.406v1.594h1.172a.703.703 0 0 1 0 1.406H4.432a3.52 3.52 0 0 0 3.49 3.094h.562a3.52 3.52 0 0 0 3.49-3.094h-1.146a.703.703 0 0 1 0-1.406H12V8.953h-1.922a.703.703 0 0 1 0-1.406H12V5.953h-1.172a.703.703 0 0 1 0-1.406h1.147c-.207-1.766-1.693-3.14-3.49-3.14z"
        fill="url(#o7akn6p7ga)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default MicrophoneIcon;
