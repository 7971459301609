import { styled } from '@material-ui/core';
import { COLORS } from 'utils/COLORS';

export const LoadingBarContainer = styled('div')(({}) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  width: '100%',
  background: '#0b0f14',
  borderStyle: 'solid',
  borderWidth: '1px',
  borderImageSource: 'linear-gradient(253deg, #d51bff, #00d4ff)',
  borderImageSlice: '1',
  backgroundImage: 'linear-gradient(to bottom, #0e1319, #0e1319), linear-gradient(253deg, #d51bff, #00d4ff)',
  backgroundOrigin: 'border-box',
  backgroundClip: 'content-box, border-box',
  borderRadius: '4px !important',
}));
