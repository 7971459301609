import React, { useState } from 'react';
import { DialogContent, Box, Typography, Button, Grid } from '@material-ui/core';
import { CloseIcon, SuccessIcon, ErrorIcon, WarningCircleFilledIcon } from '@room-match/shared-ui-components';
import { useStyles, DefaultDialog } from './ConfirmationModal.styles';
import clsx from 'clsx';

type Props = {
  confirmation: {
    title: string;
    message: string;
  };
  open: boolean;
  onClose: () => void;
  onConfirm?: () => void;
  type?: 'success' | 'error' | 'warning';
};
const ConfirmationModal: React.FC<Props> = ({ confirmation, open, onClose, onConfirm, type }) => {
  const classes = useStyles();

  const renderIcon = (type: 'success' | 'error' | 'warning' | undefined) => {
    switch (type) {
      case 'success':
        return <SuccessIcon />;
      case 'error':
        return <ErrorIcon />;
      case 'warning':
        return <WarningCircleFilledIcon />;
      default:
        return <SuccessIcon />;
    }
  };

  return (
    <DefaultDialog
      open={open}
      onClose={() => onClose()}
      maxWidth="sm"
      fullWidth
      className={clsx(classes.dialog, {
        [classes.errorDialog]: type === 'error',
        [classes.warningDialog]: type === 'warning',
      })}
      aria-labelledby="simple-dialog-title"
    >
      <DialogContent>
        <Box className={classes.closeIcon}>
          <Box onClick={() => onClose()}>
            <CloseIcon style={{ width: '14px' }} />
          </Box>
        </Box>
        <Box className={classes.notifBody}>
          {renderIcon(type)}
          <Typography variant="h5">{confirmation?.title}</Typography>
          <Typography variant="body2">{confirmation?.message}</Typography>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Button
                fullWidth
                className={clsx(classes.baseBtn, {
                  [classes.primaryBtn]: type === 'success',
                  [classes.errorBtn]: type === 'error',
                  [classes.warningBtn]: type === 'warning',
                })}
                onClick={() => (onConfirm ? onConfirm() : onClose())}
              >
                Confirm
              </Button>
            </Grid>
            <Grid item xs={6}>
              <Button fullWidth className={classes.defaultBtn} onClick={() => onClose()}>
                Cancel
              </Button>
            </Grid>
          </Grid>
        </Box>
      </DialogContent>
    </DefaultDialog>
  );
};

export default ConfirmationModal;

ConfirmationModal.defaultProps = {
  type: 'success',
};
