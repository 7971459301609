import React, { useState, useMemo, useEffect } from 'react';
import { CustomVideoPlayer } from '@room-match/shared-ui-components';
import { IMediumAttributes } from 'shared/interfaces/IMedium';
import { PlayIcon } from 'components/Icons';
import Video from '../../TalentGalleries/VideoGallery/Video';
import { useStyles } from './VideoGallery.styles';
import { useGetThumbnail } from 'shared/hooks/useGetThumbnail';
import { EmptyList } from 'components/EmptyList';

type Props = {
  videos?: IMediumAttributes[];
};

const VideoGallery: React.FC<Props> = ({ videos = [] }) => {
  const classes = useStyles();
  const [selectedVideoIndex, setSelectedVideoIndex] = useState<number>(0);
  const [selectedVideoTime, setSelectedVideoTime] = useState<string>('00:00');

  const { thumbnail, handleChangeVideoUrl } = useGetThumbnail();

  const handleSetSelectedVideoTime = (time: string) => {
    setSelectedVideoTime(time);
  };

  const handleSetSelectedVideoUrl = (url: string) => {
    handleChangeVideoUrl(url);
  };

  const selectedVideo = useMemo(() => {
    return videos.length > 0 ? videos[selectedVideoIndex] : null;
  }, [selectedVideoIndex, videos]);

  useEffect(() => {
    if (selectedVideo) {
      handleChangeVideoUrl(selectedVideo.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideo]);

  return (
    <div className={classes.videoGalleryContainer}>
      {videos.length > 0 ? (
        <React.Fragment>
          {/* Video */}
          {selectedVideo && (
            <div className={classes.videoContainer}>
              <CustomVideoPlayer
                url={selectedVideo.attachment || selectedVideo.attachment_url}
                thumbnail={thumbnail}
                playIcon={<PlayIcon style={{ fontSize: 48 }} />}
              />
            </div>
          )}

          {/* Video List */}
          {videos.length > 1 && (
            <div className={classes.videoList}>
              {videos.map((video, i) => (
                <div
                  key={i}
                  onClick={() => {
                    setSelectedVideoIndex(i);
                    handleSetSelectedVideoUrl(video.id);
                  }}
                >
                  <Video
                    video={video}
                    index={i}
                    selectedIndex={selectedVideoIndex}
                    videoURL={video.id || video.id}
                    handleSetSelectedVideoTime={handleSetSelectedVideoTime}
                  />
                </div>
              ))}
            </div>
          )}
        </React.Fragment>
      ) : (
        <EmptyList text="No videos data" />
      )}
    </div>
  );
};

export default VideoGallery;
