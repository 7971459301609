import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const PaperPlaneIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m6.722 12.252 2.892.967-1.929 2.568a.537.537 0 0 1-.963-.309v-3.226zM16.787.059a.544.544 0 0 1 .556.038.518.518 0 0 1 .218.502l-1.956 12.87a.524.524 0 0 1-.25.368.545.545 0 0 1-.45.048l-7.454-2.492 7.33-8.64-9.472 7.924L.86 9.19a.524.524 0 0 1-.36-.455.52.52 0 0 1 .286-.502z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default PaperPlaneIcon;
