import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const PlayIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="xi5o3szufa">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <g fill="none" fill-rule="evenodd">
        <circle fill="#FFF" cx="8" cy="8" r="5.125" />
        <path
          d="M8 0a8 8 0 1 0 0 16A8 8 0 0 0 8 0zM5.814 11.48V4.52L11.841 8l-6.027 3.48z"
          fill="url(#xi5o3szufa)"
          fill-rule="nonzero"
        />
      </g>
    </SvgIcon>
  );
};

export default PlayIconTwoTone;
