import React, { useEffect, useState } from 'react';
import clsx from 'clsx';
import { DialogContent, Box, Typography, IconButton, TextareaAutosize, Button } from '@material-ui/core';
import {
  CloseIcon,
  FlagGreenIconTwoTone,
  FlagRedIconTwoTone,
  FlagOrangeIconTwoTone,
} from '@room-match/shared-ui-components';
import { StyledDialog } from 'shared/elements/StyledDialog';
import { useTextAreaStyle } from 'shared/theme/styles/textAreaStyle';
import { useStyles } from './FlagModal.styles';
import { AuditionFlag } from 'shared/enums/AuditionFlag';

type Props = {
  data?: {
    name: string;
  };
  open: boolean;
  onClose: () => void;
  name?: string;
  onSubmit: (flag: string, note: string) => void;
  currentFlag?: AuditionFlag;
  currentNote?: string;
};
const FlagModal: React.FC<Props> = ({ data, open, onClose, onSubmit, currentFlag, currentNote }) => {
  const classes = useStyles();
  const textarea_classes = useTextAreaStyle();
  const [selectedFlag, setSelectedFlag] = useState<AuditionFlag | null>(null);
  const [note, setNote] = useState<string>('');

  useEffect(() => {
    if (currentFlag) {
      setSelectedFlag(currentFlag);
    }
    return () => {
      setSelectedFlag(null);
    };
  }, [currentFlag]);

  useEffect(() => {
    if (currentNote) {
      setNote(currentNote);
    }
  }, [currentNote]);

  const handleClickFlag = (flag: AuditionFlag) => {
    setSelectedFlag(flag);
  };

  const handleSubmit = () => {
    if (selectedFlag && note) {
      onSubmit(selectedFlag, note);
      onClose();
    }
  };

  const isActiveFlag = (tape: AuditionFlag) => selectedFlag === tape;

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      className={classes.dialog}
      aria-labelledby="simple-dialog-title"
    >
      <DialogContent>
        <Box className={classes.closeIcon}>
          <Box onClick={onClose}>
            <CloseIcon style={{ width: '14px' }} />
          </Box>
        </Box>
        <Box className={classes.flagBody}>
          <Box className={classes.headerContainer}>
            <Box className={classes.headerDp} />
            <Box className={classes.headerText}>
              <Typography variant="h5">Flag</Typography>
              <Typography variant="body2">{data?.name || 'N/A'}</Typography>
            </Box>
          </Box>
          <Box className={classes.request__action}>
            <IconButton
              classes={{
                root: clsx(classes.request__actionIconButton, {
                  [classes.request__actionButton__active]: isActiveFlag(AuditionFlag.RED_FLAG),
                }),
              }}
              onClick={() => handleClickFlag(AuditionFlag.RED_FLAG)}
            >
              <FlagRedIconTwoTone style={{ fontSize: 22 }} />
            </IconButton>
            <IconButton
              classes={{
                root: clsx(classes.request__actionIconButton, {
                  [classes.request__actionButton__active]: isActiveFlag(AuditionFlag.ORANGE_FLAG),
                }),
              }}
              onClick={() => handleClickFlag(AuditionFlag.ORANGE_FLAG)}
            >
              <FlagOrangeIconTwoTone style={{ fontSize: 22 }} />
            </IconButton>
            <IconButton
              classes={{
                root: clsx(classes.request__actionIconButton, {
                  [classes.request__actionButton__active]: isActiveFlag(AuditionFlag.GREEN_FLAG),
                }),
              }}
              onClick={() => handleClickFlag(AuditionFlag.GREEN_FLAG)}
            >
              <FlagGreenIconTwoTone style={{ fontSize: 22 }} viewBox="0 0 17 17" />
            </IconButton>
          </Box>
          <Box>
            <Typography style={{ color: '#c2ccd8', fontSize: '13px' }}>Flag Note</Typography>
            <TextareaAutosize
              minRows={7}
              placeholder="Type here..."
              className={textarea_classes.textAreaCls}
              style={{ marginTop: '5px', padding: '15px' }}
              value={note}
              onChange={(e) => setNote(e.target.value as string)}
            />
          </Box>
          <Button className={classes.btnCls} fullWidth style={{ background: '#3e8bff' }} onClick={() => handleSubmit()}>
            Flag Now!
          </Button>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default FlagModal;
