import { useMemo, useState } from 'react';
import { abstractApiService } from 'shared/services/abstractApiService';
import { yupEmailValidation } from 'shared/utils/yupEmailValidation';

const { validateEmail } = abstractApiService();
export const useEmailValidation = () => {
  const [emailValue, setEmailValue] = useState<string>('');

  const { data, isLoading } = validateEmail(emailValue, {
    enabled: !!emailValue && yupEmailValidation(emailValue),
  });

  const isValidEmail = useMemo(() => {
    if (emailValue) {
      return data ? data.deliverability === 'DELIVERABLE' : false;
    }
    return true;
  }, [data, emailValue]);

  const handleEmailChange = (email: string) => {
    setEmailValue(email);
  };

  return {
    isValidEmail,
    handleEmailChange,
    emailValue,
    isEmailValidationLoading: isLoading,
  };
};
