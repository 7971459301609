import {
  closestCenter,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import { arrayMove } from '@dnd-kit/sortable';
import { LoadingSpinnerIconTwoTone, useNotificationModal } from '@room-match/shared-ui-components';
import { errorResponseToArray } from '@room-match/shared-utils';
import { ClientGroupExpandingContent, ClientSingleExpandingContent, EmptyList } from 'components';
import React, { useEffect, useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { IAuditionScheduleGroupAttributes } from 'shared/interfaces/IAuditionScheduleGroup';
import { IClientPermissionState } from 'shared/redux/slicers/clientPermission.slicer';
import { auditionScheduleGroupService } from 'shared/services/auditionScheduleGroupService';
import { studioSessionService } from 'shared/services/studioSessionService';
import { generateGridForExpanded } from 'shared/utils/generateGridForExpanded';
import { swapArrayElement } from 'shared/utils/swapArrayElement';
import AuditionGroup from './AuditionGroup/AuditionGroup';
import AuditionSingle from './AuditionSingle/AuditionSingle';
import { useStyles } from './ClientAuditionList.styles';

type Props = {
  isEditMode?: boolean;
  isExpandedShow?: boolean;
  toggleIsExpandedShown: (isShown: boolean) => void;
  auditionScheduleGroups: IAuditionScheduleGroupAttributes[];
  isLoadingScheduleGroup?: boolean;
  projectId: string;
  isExpandedSidebar?: boolean;
  sessionId: string;
  clientPermissionsState?: IClientPermissionState;
  sliderValue?: number;
};

const { setAuditionScheduleGroupView } = auditionScheduleGroupService();
const { studioSessionSortAuditionScheduleGroup } = studioSessionService();
const ClientSessionAuditionList: React.FC<Props> = ({
  isEditMode,
  isExpandedShow,
  toggleIsExpandedShown,
  auditionScheduleGroups,
  isExpandedSidebar,
  sessionId,
  clientPermissionsState,
  isLoadingScheduleGroup,
  sliderValue = 0,
}) => {
  const classes = useStyles();
  const [selectedItem, setSelectedItem] = useState(-1);
  const [cardWidth, setCardWidth] = useState<number>(4);
  const {
    handleOpen,
    handleSetMessage,
    isOpen: isNotificationModalOpen,
    NotificationModal,
    handleChangeModalType,
  } = useNotificationModal({ type: 'error' });

  const mappedAuditionScheduleGroups = useMemo(() => {
    if (!clientPermissionsState) {
      return auditionScheduleGroups;
    }
    return auditionScheduleGroups
      .map((group) => {
        const { auditionPermission } = clientPermissionsState;
        if (auditionPermission) {
          const getPermissionBySession = auditionPermission.sessions.find((sn) => sn.id === sessionId);
          if (getPermissionBySession) {
            const filteredAuditionScheduleByPermission = group.audition_schedules.filter((sched) =>
              getPermissionBySession.role_ids.includes(sched.audition.casting_role.id),
            );
            return { ...group, audition_schedules: filteredAuditionScheduleByPermission };
          }
        }

        return group;
      })
      .filter((group) => group.audition_schedules.length > 0);
  }, [auditionScheduleGroups, clientPermissionsState, sessionId]);

  const [data, setData] = useState<ReturnType<typeof generateGridForExpanded<IAuditionScheduleGroupAttributes>>>(
    generateGridForExpanded<IAuditionScheduleGroupAttributes>(mappedAuditionScheduleGroups, cardWidth),
  );
  const [activeId, setActiveId] = useState<string | null>(null);

  const sensors = useSensors(useSensor(MouseSensor), useSensor(TouchSensor));

  const { mutate: setAuditionScheduleGroupViewMutate } = setAuditionScheduleGroupView();
  const { mutate: sortAuditionScheduleGroupMutate } = studioSessionSortAuditionScheduleGroup();
  const queryClient = useQueryClient();

  useEffect(() => {
    const sortedAuditionScheduleGroups = mappedAuditionScheduleGroups.sort(
      (a, b) => Number(a.group_number) - Number(b.group_number),
    );

    const computedValue = cardWidth - Math.ceil(sliderValue * 5);

    const generatedData = generateGridForExpanded<IAuditionScheduleGroupAttributes>(
      sortedAuditionScheduleGroups,
      computedValue > 0 ? computedValue : 1,
    );
    setData(generatedData);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cardWidth, auditionScheduleGroups, sliderValue]);

  useEffect(() => {
    if (isExpandedSidebar) {
      setCardWidth(4);
    } else {
      setCardWidth(5);
    }
  }, [isExpandedSidebar]);

  const handleSelectItem = (index: number) => {
    if (selectedItem === index) {
      // Toggle the selected item.
      setSelectedItem(-1);
      let mappedData = data;
      mappedData = mappedData.map((value) => ({ ...value, show: false }));
      const dataToUpdate = mappedData.find((_, i) => i === index);
      if (dataToUpdate) {
        mappedData = mappedData.map((value) => (value.id === dataToUpdate.id ? { ...value, viewed: true } : value));
      }
      setData(mappedData);
      toggleIsExpandedShown(false);
    } else {
      const hasShowIndex = data.findIndex((v) => v.grid === 'expanded' && v.show);
      let mappedData = data;

      // If there's an expanded div that show then hide it.
      if (hasShowIndex > 0) {
        mappedData = mappedData.map((value, i) => (i === hasShowIndex ? { ...value, show: false } : value));
      }
      // Itarate the grid and get the index of the nearest selected item.
      let indexToShow = index;
      for (let i = index; i < data.length; i++) {
        if (data[i].grid === 'expanded') {
          indexToShow = i;
          break;
        }
      }

      // Update the grid and set the item to show.
      mappedData = mappedData.map((value, i) => (i === indexToShow ? { ...value, show: true } : value));

      const dataToUpdate = mappedData.find((_, i) => i === index);

      if (dataToUpdate) {
        mappedData = mappedData.map((value) => (value.id === dataToUpdate.id ? { ...value, viewed: true } : value));
        if (!dataToUpdate.viewed) {
          setAuditionScheduleGroupViewMutate(dataToUpdate.id, {
            onSettled: () => {
              queryClient.invalidateQueries(['auditionScheduleGroup', sessionId], {
                refetchInactive: true,
                refetchActive: false,
              });
            },
          });
        }
      }

      setSelectedItem(index);
      setData(mappedData);
      toggleIsExpandedShown(true);
    }
  };

  const handleCloseExpandingContent = () => {
    setSelectedItem(-1);
    let mappedData = data;
    mappedData = mappedData.map((value) => ({ ...value, show: false }));
    setData(mappedData);
    toggleIsExpandedShown(false);
  };

  const getSelectedAuditionSchedule = useMemo(() => {
    return data.find((_, index) => index === selectedItem);
  }, [data, selectedItem]);

  const onDragStart = (event: DragStartEvent) => {
    setActiveId(event.active.id);
  };

  const onDragOver = (event: DragEndEvent) => {
    const { active, over } = event;
    if (active && over) {
      if (active.id !== over.id) {
        setData((items) => {
          const filteredItems = items.filter((item) => item.grid !== 'expanded');
          const oldIndex = filteredItems.findIndex((x) => x.id === active.id);
          const newIndex = filteredItems.findIndex((x) => x.id === over.id);
          const groupNumbers = filteredItems.map((i) => i.group_number).sort((a, b) => Number(a) - Number(b));

          const movedItems = arrayMove(filteredItems, oldIndex, newIndex);
          const sortedItems = movedItems.map((item, index) => {
            return {
              ...item,
              group_number: groupNumbers[index],
            };
          });
          const generatedData = generateGridForExpanded<IAuditionScheduleGroupAttributes>(sortedItems, cardWidth);
          return generatedData;
        });
      }
    }
  };

  const onDragEnd = () => {
    const filteredItems = data.filter((item) => item.grid !== 'expanded');
    const updatedItems = filteredItems.map((item) => {
      return {
        id: item.id,
        group_number: Number(item.group_number),
      };
    });

    setTimeout(() => {
      sortAuditionScheduleGroupMutate(
        {
          sessionId,
          payload: {
            sorted_audition_schedule_groups: updatedItems,
          },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['auditionScheduleGroup', sessionId], {
              refetchInactive: true,
              refetchActive: false,
            });
          },
          onError: (errors) => {
            if (errors) {
              queryClient.invalidateQueries(['auditionScheduleGroup', sessionId], {
                refetchInactive: true,
              });
              if (errors.response && errors.response.data.errors) {
                const errorMessage = errorResponseToArray(errors.response.data.errors);
                handleChangeModalType('error');
                handleSetMessage('Error!', errorMessage, 'Close');
                handleOpen();
                queryClient.invalidateQueries(['auditionScheduleGroup', sessionId], {
                  refetchInactive: true,
                });
              } else {
                handleChangeModalType('error');
                handleSetMessage('Error!', 'Something went wrong', 'Close');
                handleOpen();
                alert('Errors');
              }
            }
          },
        },
      );
    }, 500);
  };

  const handleEditGroupNumber = (auditionScheduleGroupId: string, newGroupNumber: string, oldGroupNumber: string) => {
    const filteredItems = data.filter((item) => item.grid !== 'expanded');
    const oldIndex = filteredItems.findIndex((x) => x.group_number === oldGroupNumber);
    const newIndex = filteredItems.findIndex((x) => x.group_number === newGroupNumber);

    const groupNumbers = filteredItems.map((i) => i.group_number).sort((a, b) => Number(a) - Number(b));

    const movedItems = swapArrayElement(filteredItems, oldIndex, newIndex);

    const sortedItems = movedItems.map((item, index) => {
      return {
        ...item,
        group_number: groupNumbers[index],
      };
    });
    const generatedData = generateGridForExpanded<IAuditionScheduleGroupAttributes>(sortedItems, cardWidth);
    setData(generatedData);

    const updatedItems = sortedItems.map((item) => {
      return {
        id: item.id,
        group_number: Number(item.group_number),
      };
    });

    sortAuditionScheduleGroupMutate(
      {
        sessionId,
        payload: {
          sorted_audition_schedule_groups: updatedItems,
        },
      },
      {
        onSettled: () => {
          queryClient.invalidateQueries(['auditionScheduleGroup', sessionId], {
            refetchInactive: true,
            refetchActive: false,
          });
        },
      },
    );
  };

  const activeData = useMemo(() => {
    return data.find((data) => data.id === activeId);
  }, [data, activeId]);

  return (
    <div className={classes.auditionList}>
      {/* List */}
      <div className={classes.auditionList__listContainer}>
        {!isLoadingScheduleGroup ? (
          <>
            {auditionScheduleGroups.length > 0 ? (
              <DndContext
                sensors={sensors}
                collisionDetection={closestCenter}
                onDragStart={onDragStart}
                onDragOver={onDragOver}
                onDragEnd={onDragEnd}
                onDragCancel={onDragEnd}
              >
                <>
                  {data.map((value, i) => (
                    <>
                      {value.grid === 'item' ? (
                        <div
                          className={classes.auditionList__listItem}
                          style={{ width: `calc((100% / ${cardWidth - sliderValue * 5}) - 16px )` }}
                          key={value.id}
                        >
                          {value.audition_schedules.length > 1 ? (
                            <AuditionGroup
                              index={i}
                              handleSelectItem={handleSelectItem}
                              isSelected={selectedItem === i}
                              isEditMode={isEditMode}
                              hasShownExpanded={isExpandedShow}
                              auditionSchedules={value.audition_schedules}
                              auditionScheduleGroup={value}
                              isViewed={value.viewed}
                              handleEditGroupNumber={handleEditGroupNumber}
                            />
                          ) : (
                            <React.Fragment>
                              <AuditionSingle
                                index={i}
                                handleSelectItem={handleSelectItem}
                                isSelected={selectedItem === i}
                                isEditMode={isEditMode}
                                hasShownExpanded={isExpandedShow}
                                auditionSchedule={value.audition_schedules[0]}
                                groupId={value.id}
                                sessionId={sessionId}
                                isViewed={value.viewed}
                                auditionScheduleGroup={value}
                                handleEditGroupNumber={handleEditGroupNumber}
                                clientPermissionsState={clientPermissionsState}
                              />
                            </React.Fragment>
                          )}
                        </div>
                      ) : (
                        <>
                          {value.show && getSelectedAuditionSchedule && (
                            <div className={classes.auditionList__listItem} style={{ width: `100%` }} key={value.id}>
                              {getSelectedAuditionSchedule.audition_schedules.length > 1 ? (
                                <ClientGroupExpandingContent
                                  isEditMode={isEditMode}
                                  onCreateNote={() => null}
                                  auditionSchedules={getSelectedAuditionSchedule.audition_schedules}
                                  mediums={getSelectedAuditionSchedule.medium_attachments}
                                  auditionGroup={getSelectedAuditionSchedule}
                                  sessionId={sessionId}
                                  clientPermissionsState={clientPermissionsState}
                                  handleCloseExpandingContent={() => handleCloseExpandingContent()}
                                />
                              ) : (
                                <ClientSingleExpandingContent
                                  isEditMode={isEditMode}
                                  audition={getSelectedAuditionSchedule.audition_schedules[0].audition}
                                  auditionSchedule={getSelectedAuditionSchedule.audition_schedules[0]}
                                  groupId={getSelectedAuditionSchedule.id}
                                  sessionId={sessionId}
                                  clientPermissionsState={clientPermissionsState}
                                  handleCloseExpandingContent={() => handleCloseExpandingContent()}
                                />
                              )}
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ))}
                </>

                <DragOverlay>
                  {activeId ? (
                    <>
                      {activeData && (
                        <>
                          {activeData.audition_schedules.length > 1 ? (
                            <div className={classes.auditionList__listItem} style={{ width: '100%' }}>
                              <AuditionGroup
                                index={data.findIndex((data) => data.id === activeId)}
                                handleSelectItem={handleSelectItem}
                                isSelected={false}
                                isEditMode={isEditMode}
                                hasShownExpanded={false}
                                auditionSchedules={activeData.audition_schedules}
                                auditionScheduleGroup={activeData}
                                isViewed={activeData.viewed}
                                handleEditGroupNumber={handleEditGroupNumber}
                              />
                            </div>
                          ) : (
                            <div className={classes.auditionList__listItem} style={{ width: '100%' }}>
                              <AuditionSingle
                                index={data.findIndex((data) => data.id === activeId)}
                                handleSelectItem={handleSelectItem}
                                isSelected={false}
                                isEditMode={isEditMode}
                                hasShownExpanded={false}
                                auditionSchedule={activeData.audition_schedules[0]}
                                groupId={activeData.id}
                                sessionId={sessionId}
                                isViewed={activeData.viewed}
                                auditionScheduleGroup={activeData}
                                handleEditGroupNumber={handleEditGroupNumber}
                              />
                            </div>
                          )}
                        </>
                      )}
                    </>
                  ) : null}
                </DragOverlay>
              </DndContext>
            ) : (
              <EmptyList text="No Auditions" />
            )}
          </>
        ) : (
          <div className={classes.auditionList__loadingContainer}>
            <LoadingSpinnerIconTwoTone style={{ fontSize: '5em' }} />
          </div>
        )}
      </div>
      {isNotificationModalOpen && <NotificationModal />}
    </div>
  );
};

export default ClientSessionAuditionList;
