import { Grid, IconButton, Popover } from '@material-ui/core';
import { FlagIcon, Input, PaperPlaneIconTwoTone } from '@room-match/shared-ui-components';
import { AuditionFlagIcon } from 'components/AuditionFlagIcon';
import React, { useState } from 'react';
import { Typography } from 'shared/elements';
import { AuditionFlag } from 'shared/enums/AuditionFlag';
import { IAuditionFlag } from 'shared/interfaces/IAuditionFlag';
import { useStyles } from './DetailComment.styles';
import { useParams } from 'react-router-dom';
import { ellipseText } from '@room-match/shared-utils';

type Props = {
  auditionFlag?: IAuditionFlag | null;
  onCreateAuditionFlag?: (flag: string, comment: string) => void;
};
const DetailComment: React.FC<Props> = ({ auditionFlag, onCreateAuditionFlag }) => {
  const classes = useStyles();

  const [anchorElFlag, setAnchorElFlag] = React.useState<HTMLElement | null>(null);
  const [selectedFlag, setSelectedFlag] = useState<AuditionFlag | null>(null);
  const [noteMessage, setNoteMessage] = useState<string>('');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElFlag(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElFlag(null);
  };

  const handleSelectFlag = (flag: AuditionFlag) => {
    setSelectedFlag(flag);
    handleClose();
  };

  const handleNoteSend = () => {
    if (noteMessage && selectedFlag) {
      if (onCreateAuditionFlag) {
        onCreateAuditionFlag(selectedFlag, noteMessage);
        setSelectedFlag(null);
        setNoteMessage('');
      }
    }
  };

  const openFlag = Boolean(anchorElFlag);
  const id = openFlag ? 'simple-popover' : undefined;
  return (
    <div className={classes.request__comment}>
      <div className={classes.request__comment_flagContent}>
        {auditionFlag?.attributes?.flag_type && auditionFlag?.attributes?.comment && (
          <>
            <AuditionFlagIcon flag={auditionFlag?.attributes?.flag_type || null} fontSize={16} />
            <Typography fontSize={14}>{ellipseText(auditionFlag?.attributes?.comment || 'N/A', 25)}</Typography>
          </>
        )}
      </div>
      <Input
        name="general_company_email"
        variant="outlined"
        type="text"
        placeholder="Write note here…"
        margin={'none'}
        fullWidth
        value={noteMessage}
        onChange={(e) => setNoteMessage(e.target.value as string)}
        onKeyUp={(e) => {
          if (e.key === 'Enter') {
            handleNoteSend();
          }
        }}
        InputLabelProps={{ shrink: true }}
        InputProps={{
          tabIndex: 1,
          startAdornment: (
            <IconButton
              aria-describedby={id}
              onClick={handleClick}
              style={{ padding: 5, marginRight: 8, marginLeft: '-5px' }}
            >
              {!selectedFlag ? (
                <FlagIcon style={{ fontSize: 14 }} />
              ) : (
                <>
                  {selectedFlag === AuditionFlag.RED_FLAG ? (
                    <FlagIcon className={classes.flagRed} />
                  ) : selectedFlag === AuditionFlag.ORANGE_FLAG ? (
                    <FlagIcon className={classes.flagYellow} />
                  ) : (
                    <FlagIcon className={classes.flagGreen} />
                  )}
                </>
              )}
            </IconButton>
          ),
          endAdornment: (
            <IconButton size="small" disabled={!selectedFlag} onClick={() => handleNoteSend()}>
              <PaperPlaneIconTwoTone style={{ fontSize: 14 }} />
            </IconButton>
          ),
        }}
      />
      <Popover
        id={id}
        open={openFlag}
        anchorEl={anchorElFlag}
        onClose={handleClose}
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        classes={{
          paper: classes.paperSmall,
        }}
      >
        <Grid container spacing={0} className={classes.popoverContainer}>
          <IconButton onClick={() => handleSelectFlag(AuditionFlag.RED_FLAG)}>
            <FlagIcon className={classes.flagRed} />
          </IconButton>
          <IconButton onClick={() => handleSelectFlag(AuditionFlag.ORANGE_FLAG)}>
            <FlagIcon className={classes.flagYellow} />
          </IconButton>
          <IconButton onClick={() => handleSelectFlag(AuditionFlag.GREEN_FLAG)}>
            <FlagIcon className={classes.flagGreen} />
          </IconButton>
        </Grid>
      </Popover>
    </div>
  );
};

export default DetailComment;
