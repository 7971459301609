import { IconButton, Paper, TextareaAutosize, TextareaAutosizeProps, TextField, useTheme } from '@material-ui/core';
import {
  PlusIconTwoTone,
  ShadedStarIconTwoTone,
  ShadedStartBlueIconTwoTone,
  UnshadedStarIconTwoTone,
} from '@room-match/shared-ui-components';
import clsx from 'clsx';
import { FormikProps, useFormik } from 'formik';
import React, { useEffect, useState } from 'react';
import { useStyles } from './NoteTextArea.styles';
import { useNoteTextAreaForm } from './NoteTextAreaForm';
import { Fragment } from 'react';

type Props = TextareaAutosizeProps & {
  isYellowStar?: boolean;
  minRows?: number;
  classes?: {
    paper: string;
  };
  onSubmitForm?: (rating: number, note: string) => void;
};
const NoteTextArea: React.FC<Props> = ({ isYellowStar, classes: Classes, onSubmitForm, minRows = 5, ...props }) => {
  const classes = useStyles();
  const theme = useTheme();

  const handleSubmit = (values: { stars: number; note: string }) => {
    if (onSubmitForm) {
      onSubmitForm(values.stars, values.note);
    }
  };

  const { form } = useNoteTextAreaForm({
    onSubmit: (values) => handleSubmit(values),
  });

  useEffect(() => {
    form.resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isYellowStar]);

  const handleHoverStars = (value: number) => {
    if (value === form.values.stars) {
      form.setFieldValue('stars', 0);
    } else {
      form.setFieldValue('stars', value);
    }
  };

  return (
    <Paper
      className={clsx(classes.root, Classes?.paper, {
        [classes.rootError]: (form.errors.note && form.touched.note) || (form.touched.stars && form.errors.stars),
      })}
    >
      <div className={classes.inputContainer}>
        <TextareaAutosize
          minRows={minRows}
          maxRows={5}
          className={classes.textArea}
          placeholder="Type note here…"
          value={form.values.note}
          name="note"
          onKeyDown={(e) => {
            if (e.key.toLowerCase() === 'enter' && !e.shiftKey) {
              e.preventDefault();
              return form.handleSubmit();
            }
          }}
          onChange={form.handleChange}
          onBlur={form.handleBlur}
          {...props}
        />
      </div>
      <div className={classes.actionContainer}>
        <div className={classes.starList}>
          {isYellowStar ? (
            <Fragment>
              {Array.from({ length: 5 }).map((_, i) => (
                <div onClick={() => handleHoverStars(i + 1)} style={{ cursor: 'pointer' }}>
                  {form.values.stars > i ? (
                    <ShadedStarIconTwoTone key={i} classes={{ root: classes.starIcon }} />
                  ) : (
                    <UnshadedStarIconTwoTone key={i} classes={{ root: classes.starIcon }} />
                  )}
                </div>
              ))}
            </Fragment>
          ) : (
            <Fragment>
              {Array.from({ length: 5 }).map((_, i) => (
                <div onClick={() => handleHoverStars(i + 1)} style={{ cursor: 'pointer' }}>
                  {form.values.stars > i ? (
                    <ShadedStartBlueIconTwoTone key={i} classes={{ root: classes.starIcon }} />
                  ) : (
                    <UnshadedStarIconTwoTone key={i} classes={{ root: classes.starIcon }} />
                  )}
                </div>
              ))}
            </Fragment>
          )}
        </div>
        <div className={classes.checkMarkContainer}>
          <IconButton size="small" onClick={() => form.handleSubmit()}>
            <PlusIconTwoTone fontSize="small" htmlColor={theme.palette.primary.main} />
          </IconButton>
        </div>
      </div>
    </Paper>
  );
};

export default NoteTextArea;
