import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function SelectArrowDownIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="11" height="6" viewBox="0 0 11 6" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M5.263 6a.735.735 0 0 1-.521-.216L.216 1.258A.737.737 0 1 1 1.258.216L5.263 4.22 9.267.216a.737.737 0 0 1 1.043 1.042L5.784 5.784A.735.735 0 0 1 5.263 6z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default SelectArrowDownIcon;
