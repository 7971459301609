import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    boxSelectFlex: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      '& > *:first-child': {
        marginRight: '10px',
      },
    },
  }),
);
