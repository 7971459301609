import { Box, Grid, Card, CardContent, IconButton } from '@material-ui/core';
import { ArrowUpIconTwoTone, Typography } from '@room-match/shared-ui-components';

import clsx from 'clsx';
import { EmptyList } from 'components/EmptyList';
import {
  ArrowDownIcon,
  FacebookOutlinedIcon,
  InstagramOutlinedIcon,
  PlayIcon,
  TiktokOutlinedIcon,
  YoutubeOutlinedIcon,
} from 'components/Icons';
import React, { useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { IMedium, IMediumAttributes } from 'shared/interfaces/IMedium';

import { IProfileAttributes, IProfileSkill } from 'shared/interfaces/IProfile';
import { onOpenResumeGallery } from 'shared/redux/slicers/talentProfile.slicer';
import { getActiveSkills } from 'shared/utils/getActiveSkills';
import { useStyles } from './TalentProfileCard.styles';
type Props = {
  profile: IProfileAttributes;
  handleSetResumeMedia: (instance: IMediumAttributes[]) => void;
};
const TalentProfileCard: React.FC<Props> = ({ profile, handleSetResumeMedia }) => {
  const classes = useStyles();
  const [isFullHeight, setIsFullHeight] = useState<boolean>(false);
  const dispatch = useDispatch();

  const skills = useMemo(() => {
    return profile.skills ? profile.skills : [];
  }, [profile.skills]);

  const handleOpenSkills = (medias: IMedium[]) => {
    const newMedia = medias.map((media) => ({
      ...media.attributes,
    }));

    handleSetResumeMedia(newMedia);
    dispatch(onOpenResumeGallery());
  };

  const activeSkills = useMemo(() => {
    return getActiveSkills(skills);
  }, [skills]);

  return (
    <Box className={classes.cardsContainer}>
      <Grid container spacing={2} alignContent="stretch">
        {/* Social */}
        <Grid item lg={6} md={6}>
          <Card
            className={clsx(classes.card, {
              [classes.cardFullHeight]: isFullHeight,
            })}
          >
            <CardContent className={classes.cardContent}>
              <Box className={classes.flexBetween}>
                <Typography variant="subHeading">
                  Social Reach : <span className={classes.textPrimary}>0k</span>
                </Typography>
                <Box className={classes.socialIconContainer}>
                  <IconButton size="small" onClick={() => setIsFullHeight((curr) => !curr)}>
                    {!isFullHeight ? (
                      <ArrowDownIcon className={classes.socialIcon} />
                    ) : (
                      <ArrowUpIconTwoTone className={classes.socialIcon} />
                    )}
                  </IconButton>
                </Box>
              </Box>

              {isFullHeight && (
                <div className={classes.socialList}>
                  <div
                    className={clsx(classes.socialItem, {
                      [classes.socialItem__hidden]: true,
                    })}
                  >
                    <div className={classes.socialLabel}>
                      <InstagramOutlinedIcon className={classes.socialIcon} />
                      <Typography fontSize={14}>Instagram</Typography>
                    </div>
                    <Typography fontSize={14}>0k</Typography>
                  </div>

                  <div
                    className={clsx(classes.socialItem, {
                      [classes.socialItem__hidden]: true,
                    })}
                  >
                    <div className={classes.socialLabel}>
                      <YoutubeOutlinedIcon className={classes.socialIcon} />
                      <Typography fontSize={14}>Youtube</Typography>
                    </div>
                    <Typography fontSize={14}>0k</Typography>
                  </div>

                  <div
                    className={clsx(classes.socialItem, {
                      [classes.socialItem__hidden]: true,
                    })}
                  >
                    <div className={classes.socialLabel}>
                      <InstagramOutlinedIcon className={classes.socialIcon} />
                      <Typography fontSize={14}>Twitter</Typography>
                    </div>
                    <Typography fontSize={14}>0k</Typography>
                  </div>
                  <div
                    className={clsx(classes.socialItem, {
                      [classes.socialItem__hidden]: true,
                    })}
                  >
                    <div className={classes.socialLabel}>
                      <TiktokOutlinedIcon className={classes.socialIcon} />
                      <Typography fontSize={14}>Tiktok</Typography>
                    </div>
                    <Typography fontSize={14}>0k</Typography>
                  </div>

                  <div
                    className={clsx(classes.socialItem, {
                      [classes.socialItem__hidden]: true,
                    })}
                  >
                    <div className={classes.socialLabel}>
                      <FacebookOutlinedIcon className={classes.socialIcon} />
                      <Typography fontSize={14}>Facebook</Typography>
                    </div>
                    <Typography fontSize={14}>0k</Typography>
                  </div>
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        {/* Agency */}
        <Grid item lg={6} md={6}>
          <Card
            className={clsx(classes.card, {
              [classes.cardFullHeight]: isFullHeight,
            })}
          >
            <CardContent>
              <Box className={classes.flexBetween}>
                <Typography variant="subHeading">{profile.agency?.data?.attributes.name || '—'}</Typography>
                <Box className={classes.flexBetween} gridGap={16}>
                  <IconButton size="small" onClick={() => setIsFullHeight((curr) => !curr)}>
                    {!isFullHeight ? (
                      <ArrowDownIcon className={classes.socialIcon} />
                    ) : (
                      <ArrowUpIconTwoTone className={classes.socialIcon} />
                    )}
                  </IconButton>
                </Box>
              </Box>
              {isFullHeight && (
                <div className={classes.agencyExpanding}>
                  <Grid container spacing={1}>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Typography fontSize={14} color="primary">
                        Commercial Division
                      </Typography>
                    </Grid>
                    <Grid item xl={6}>
                      <Typography fontSize={14} color="primary">
                        {profile.agency?.data?.attributes.contact_first_name &&
                        profile.agency?.data?.attributes.contact_last_name
                          ? `${profile.agency?.data?.attributes.contact_first_name} ${profile.agency?.data?.attributes.contact_last_name} `
                          : '—'}
                      </Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Typography fontSize={14}>{profile.agency?.data?.attributes.contact_number || '—'}</Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Typography fontSize={14}>{profile.agency?.data?.attributes.contact_number || '—'}</Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Typography fontSize={14}>
                        {profile.agency?.data?.attributes.country && profile.agency?.data?.attributes.state
                          ? `${profile.agency?.data?.attributes.country}, ${profile.agency?.data?.attributes.state} `
                          : '—'}
                      </Typography>
                    </Grid>
                    <Grid item xl={6} lg={6} md={6} sm={6} xs={6}>
                      <Typography fontSize={14}>{profile.agency?.data?.attributes.email || '—'}</Typography>
                    </Grid>
                  </Grid>
                </div>
              )}
            </CardContent>
          </Card>
        </Grid>
        {/* Resume Highlights */}
        <Grid item lg={6} md={6}>
          <Card className={classes.card} style={{ height: '100%' }}>
            <CardContent>
              <Typography color="primary" fontWeight={500}>
                Resume Highlights
              </Typography>
              {Array.from({ length: 0 }).length > 0 ? (
                <Box className={classes.resumeContainer}>
                  <Box className={classes.resumeItemContainer}>
                    <IconButton size="small">
                      <PlayIcon style={{ fontSize: 16 }} />
                    </IconButton>
                    <Box>
                      <Typography fontSize={12} color="primary">
                        2010 - The Wannabes
                      </Typography>
                      <Typography fontSize={12} style={{ lineHeight: 1 }}>
                        Andrew, Ian Brown
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              ) : (
                <EmptyList text="No Resume Highlights Data" />
              )}
            </CardContent>
          </Card>
        </Grid>
        {/* Skills Highlights */}
        <Grid item lg={6} md={6}>
          <Card className={classes.card}>
            <CardContent>
              <Typography color="primary" fontWeight={500}>
                Skills Highlights
              </Typography>
              {activeSkills.length > 0 ? (
                <Box className={classes.skillsContainer}>
                  <React.Fragment>
                    {activeSkills.map((skillGroup, index) => (
                      <React.Fragment key={index}>
                        {skillGroup.groups.map((group, index) => (
                          <React.Fragment key={index}>
                            {group.skills
                              .filter((skill) => skill.is_selected && skill.proficiency.value === 'E')
                              .map((skill, index) => (
                                <Box className={classes.skillsItemContainer} key={index}>
                                  <Box>
                                    <Typography fontSize={12}>{skill.value}</Typography>
                                    <Typography fontSize={12} color="mainGrey">
                                      {skillGroup.category}
                                    </Typography>
                                  </Box>
                                  <IconButton size="small" onClick={() => handleOpenSkills(skill.media)}>
                                    <PlayIcon style={{ fontSize: 16 }} />
                                  </IconButton>
                                </Box>
                              ))}
                          </React.Fragment>
                        ))}
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                </Box>
              ) : (
                <EmptyList text="No Skill Highlights Data" />
              )}
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TalentProfileCard;
