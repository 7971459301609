import { AuditionFlag } from 'shared/enums/AuditionFlag';
import { IAuditionFlag } from 'shared/interfaces/IAuditionFlag';

export const getCastingFavoriteData = (auditionFlags: IAuditionFlag[]) => {
  return auditionFlags.filter((data) => data.attributes.flag_type === 'casting_favourite')[0];
};

export const getClientFavoriteData = (auditionFlags: IAuditionFlag[]) => {
  return auditionFlags.filter((flag) => {
    return flag.attributes.flag_type === 'client_favourite' || flag.attributes.flag_type === 'client_does_not_like';
  })[0];
};

export const getCurrentFlagData = (auditionFlags: IAuditionFlag[]) => {
  return auditionFlags.filter((flag) => {
    return (
      flag.attributes.flag_type === 'green_flag' ||
      flag.attributes.flag_type === 'red_flag' ||
      flag.attributes.flag_type === 'orange_flag'
    );
  })[0];
};

export const getInternalNotesData = (auditionFlags: IAuditionFlag[]) => {
  return auditionFlags.filter((flag) => flag.attributes.flag_type === 'internal_note');
};
export const getPublicNotesData = (auditionFlags: IAuditionFlag[]) => {
  return auditionFlags.filter((flag) => flag.attributes.flag_type === 'public_note');
};

export const getCurrentFlagTypeData = (flagType: string) => {
  switch (flagType) {
    case 'green_flag':
      return AuditionFlag.GREEN_FLAG;
    case 'red_flag':
      return AuditionFlag.RED_FLAG;
    case 'orange_flag':
      return AuditionFlag.ORANGE_FLAG;

    default:
      return undefined;
  }
};
