import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const MagnifyingGlassIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.667 15v3.333H5V20H0v-5h1.667zM8.333 1.667A6.666 6.666 0 0 1 13.6 12.42l4.733 4.735-1.18 1.177-4.732-4.733A6.666 6.666 0 0 1 1.667 8.333a6.666 6.666 0 0 1 6.666-6.666zm0 1.666c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm0 1.667v1.667c-.92 0-1.666.747-1.666 1.666H5A3.336 3.336 0 0 1 8.333 5zM20 0v5h-1.667V1.667H15V0h5z"
        fill="#D7A300"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default MagnifyingGlassIconTwoTone;
