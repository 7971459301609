import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function HeadshotIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="23" height="30" viewBox="0 0 23 30" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="qm3zrfuxra">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="m.879 24.92.68 3.516h2.836a.677.677 0 0 1 .678.68l-4.194.678L.2 25.6a.677.677 0 0 1 .679-.68zm21.094 0 .679 4.195-4.195.68a.677.677 0 0 1-.679-.68l3.516-.68V25.6a.677.677 0 0 1 .679-.68zM11.426 2.014l.68 3.538c1.9.12 3.533.75 4.747 1.849 1.499 1.355 2.283 3.377 2.283 5.84l-.006.378-.019.383 2.851.317a.677.677 0 0 1 .68.569l.01.11-3.752.68c-.332 1.771-1.002 3.54-1.927 5.017-1.23 1.961-2.752 3.219-4.387 3.626l-.234.053-.247 2.92a.677.677 0 0 1-.57.679l-.11.009-.679-3.566c-1.815-.263-3.518-1.569-4.867-3.721-.77-1.227-1.362-2.656-1.73-4.12l-.105-.441-3.152-.457a.677.677 0 0 1-.683-.569l-.009-.11 3.564-.68a12.625 12.625 0 0 1-.048-1.078c0-2.462.784-4.484 2.282-5.84 1.06-.957 2.437-1.56 4.034-1.778l.345-.04.37-2.877a.677.677 0 0 1 .679-.69zm-.68 13.663H5.339l.066.298c.763 3.274 2.772 6.369 5.343 7.08v-7.378zm6.767-.012h-5.408v7.373l.23-.055c1.149-.315 2.218-1.213 3.098-2.44.969-1.351 1.71-3.1 2.08-4.878zm-5.408-8.75v7.404h5.619l.03-.362c.016-.241.024-.48.024-.717 0-2.05-.575-3.443-1.392-4.389-1.226-1.42-3.009-1.84-4.281-1.937zm-1.358 0c-1.273.096-3.055.516-4.281 1.936-.817.946-1.393 2.338-1.393 4.39 0 .218.008.439.05 1.078h5.624zM21.973.2l.679 4.195a.677.677 0 0 1-.68.678l-.679-3.515h-2.836a.677.677 0 0 1-.679-.68L21.973.2zM4.395.2a.677.677 0 0 1 .678.679l-3.515.68v2.836a.677.677 0 0 1-.68.678L.2.88z"
        fill="url(#qm3zrfuxra)"
        fillRule="nonzero"
        stroke="#151E2A"
        strokeWidth=".4"
      />
    </SvgIcon>
  );
}

export default HeadshotIcon;
