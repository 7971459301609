import { Box, styled } from '@material-ui/core';

export const ProgressCont = styled(Box)(({ theme }) => ({
  alignSelf: 'center',
  minWidth: 50,
  overflow: 'hidden',
}));

export const Completed = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.type === 'dark' ? '#151a21' : '#edf1f6',
  transition: '300ms',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
}));
