import React from 'react';
import { Theme, Tooltip, TooltipProps } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const GradientTooltip = withStyles((theme: Theme) => ({
  tooltip: {
    backgroundColor: theme.palette.customBlack.main,
    border: '1px solid transparent',
    borderImageSource: 'linear-gradient(253deg, #d51bff, #558afe)',
    borderImageSlice: '1',
    backgroundImage: 'linear-gradient(to bottom, #0e1319, #0e1319), linear-gradient(253deg, #d51bff, #558afe)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    borderRadius: '8px !important',
    padding: 0,
    fontSize: 12,
  },
}))(Tooltip);

export const StyledTooltip = (props: TooltipProps) => {
  return <GradientTooltip {...props} title={<div style={{ padding: 8 }}>{props.title}</div>} />;
};
