import React, { useMemo } from 'react';
import { useHistory } from 'react-router-dom';
import clsx from 'clsx';
import { useQueryClient } from 'react-query';
import { useDispatch } from 'react-redux';
import { AppBar, Toolbar, Typography } from '@material-ui/core';
import { Theme, createStyles, makeStyles } from '@material-ui/core';
import MenuIcon from '@material-ui/icons/Menu';
import { userLogout } from 'shared/redux/slicers/user.slicer';
import { SignOutIcon, SettingsIcon, NotificationsIcon } from 'components/Icons';
import { CustomIconButton, ImageContainer, ProfileImage, UserContainerButton } from './Header.style';
import { ROUTES } from 'shared/constants/ROUTES';
import { useAppSelector } from 'shared/redux/store';
import { getFullName } from 'shared/utils/getFullName';
import { NO_PROFILE_IMAGE_PLACEHOLDER_URL } from 'shared/constants/ASSETS_URL';
import { navigateToRoute } from 'shared/utils/navigateToRoute';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    appBar: {
      backgroundColor: '#080c12',
      boxShadow: 'none',
      borderBottom: '1px solid rgba(255, 255, 255, 0.12)',
      zIndex: theme.zIndex.drawer + 1,
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.leavingScreen,
      }),
      [theme.breakpoints.up('sm')]: {
        marginLeft: 100,
        width: 'calc(100% - 100px)',
      },
    },
    appBarShift: {
      transition: theme.transitions.create(['width', 'margin'], {
        easing: theme.transitions.easing.sharp,
        duration: theme.transitions.duration.enteringScreen,
      }),
      [theme.breakpoints.up('sm')]: {
        marginLeft: 100,
        width: 'calc(100% - 100px)',
      },
    },
    menuButton: {
      marginRight: theme.spacing(2),
      marginLeft: theme.spacing(2),
      [theme.breakpoints.up('sm')]: {
        display: 'none',
      },
    },
    search: {
      position: 'relative',
      marginLeft: 0,
      width: '100%',
      [theme.breakpoints.up('sm')]: {
        marginLeft: theme.spacing(1),
        width: 'auto',
      },
    },
    searchIcon: {
      height: '100%',
      position: 'absolute',
      pointerEvents: 'none',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    inputRoot: {
      color: 'inherit',
    },
    sectionDesktop: {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
    },
    sectionMobile: {
      display: 'flex',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
  }),
);

type Props = {
  isDrawerCollapse?: boolean;
  handleDrawerToggle: () => void;
};

const Header: React.FC<Props> = ({ isDrawerCollapse, handleDrawerToggle }) => {
  const classes = useStyles();
  const history = useHistory();
  const { user: userState, app: appState } = useAppSelector((state) => state);
  const { user } = userState;
  const { headerTitle } = appState;
  const dispatch = useDispatch();
  const queryClient = useQueryClient();

  const handleLogOut = async () => {
    dispatch(userLogout());
    queryClient.clear();
    window.location.href = '/login';
  };

  const userFullName = useMemo(() => {
    return user ? getFullName(user.attributes.first_name, user.attributes.last_name) : '';
  }, [user]);

  return (
    <AppBar
      position="fixed"
      className={clsx(classes.appBar, {
        [classes.appBarShift]: !isDrawerCollapse,
      })}
    >
      <Toolbar style={{ minHeight: '60px', padding: '0px 13px' }}>
        <CustomIconButton
          aria-label="open drawer"
          onClick={handleDrawerToggle}
          edge="start"
          className={clsx(classes.menuButton, {})}
        >
          <MenuIcon />
        </CustomIconButton>
        <Typography variant="body1">{headerTitle}</Typography>
        <div style={{ flexGrow: 1 }} />
        <div className={classes.sectionDesktop} style={{ minHeight: '60px' }}>
          <UserContainerButton onClick={() => history.push(ROUTES.CASTING.PROFILE)}>
            <Typography variant="body2">{userFullName}</Typography>
            <ImageContainer>
              <ProfileImage src={`${user?.attributes.picture || NO_PROFILE_IMAGE_PLACEHOLDER_URL}`} />
            </ImageContainer>
          </UserContainerButton>
          <CustomIconButton>
            <NotificationsIcon />
          </CustomIconButton>
          <CustomIconButton onClick={() => history.push(navigateToRoute.toSetting('company_information'))}>
            <SettingsIcon />
          </CustomIconButton>
          <CustomIconButton
            edge="end"
            aria-label="account of current user"
            aria-controls="primary-search-account-menu"
            aria-haspopup="true"
            onClick={handleLogOut}
          >
            <SignOutIcon />
          </CustomIconButton>
        </div>
        <div className={classes.sectionMobile} style={{ minHeight: '60px' }}>
          <CustomIconButton
            aria-label="show more"
            aria-controls="primary-search-account-menu-mobile"
            aria-haspopup="true"
            onClick={handleLogOut}
          >
            <SignOutIcon />
          </CustomIconButton>
        </div>
      </Toolbar>
    </AppBar>
  );
};

export default Header;
