import { createStyles, makeStyles, styled } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    qrModal__dialogContent: {
      padding: '20px 30px',
    },
    qrModal__container: {},
    qrModal__header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    qrModal__content: {
      padding: '10px 0',
      marginTop: theme.spacing(2),
    },
    qrModal__qrContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 18,
    },
    qrModal__imageContainer: {
      width: '100%',
      height: 200,
      overflow: 'hidden',
      borderRadius: 4,

      backgroundColor: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    qrModal__rightContent: {},
    qrModal__linkContainer: {
      gap: 8,
      border: '1px solid #2d343e',
      padding: theme.spacing(1.5),
      borderRadius: 8,
    },
    qrModal__link: {
      display: 'flex',
      alignItems: 'center',
    },
    qrModal__centerTextContainer: {
      marginTop: theme.spacing(1.5),
    },
    qrModal__detailContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      marginTop: theme.spacing(1),
    },
    qrModal__detailTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },
  }),
);

export const StyledATag = styled('a')(({ theme }) => ({
  color: theme.palette.primary.main,
  fontSize: 16,
  textDecoration: 'none',
  display: 'inline-block',
}));
