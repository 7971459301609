// eslint-disable-next-line no-unused-vars, @typescript-eslint/no-unused-vars
import { createStyles, makeStyles, styled } from '@material-ui/core/styles';
import Switch from '@material-ui/core/Switch';

export const useStyles = makeStyles(() => {
  return createStyles({
    root: {
      padding: 0,
    },
    switchContent: {
      display: 'flex',
      color: '#FFFFFF',
      cursor: 'pointer',
      textDecoration: 'none',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      margin: '0px 10px',
      '& label': {
        marginRight: '0px',
        marginLeft: '0px',
      },
    },
    disabled: {
      '& .MuiSwitch-thumb': {
        background: '#323d4e!important',
      },
    },
    switchComp: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      height: '16px',
      width: '30px',
      background: '#FFFFFF',
      borderRadius: '4px',
      '& > *': {
        height: '9px',
        width: '2px',
        borderRadius: '4px',
        background: '#bbbbbb',
      },
      '& > *:not(:last-child)': {
        marginRight: '2px !important',
      },
    },
  });
});

export const CustomSwitch = styled(Switch)(() => ({
  width: '70px',
  height: '36px',
  padding: 7,
  '& .MuiSwitch-switchBase': {
    margin: 1,
    padding: 0,
    top: '9px',
    transform: 'translateX(29px)',
    '&.Mui-checked': {
      color: '#fff',
      transform: 'translateX(9px)',
      '& + .MuiSwitch-track': {
        opacity: 1,
        backgroundColor: '#505d6d',
      },
    },
  },

  '& .MuiSwitch-thumb': {
    background: '#3e8bff',
    width: '30px',
    height: '16px',
    borderRadius: '5px',
    '&:before': {
      content: "''",
      position: 'absolute',
      width: '100%',
      height: '100%',
      left: 0,
      top: 0,
    },
  },
  '& .MuiSwitch-track': {
    opacity: 1,
    backgroundColor: '#3e8bff',
    borderRadius: '5px',
  },
}));
