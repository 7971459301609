import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    expandedDiv: {
      backgroundColor: '#0b0f14',
      padding: theme.spacing(2),
      border: `1px solid ${theme.palette.primary.main}`,
      borderRadius: 8,
    },
    closeButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    expandedDiv__leftContainer: {
      minHeight: 500,
    },
    expandedDiv__videoPlayerContainer: {
      height: 550,
      width: '100%',
    },
    expandedDiv__mediaContainer: {
      minHeight: 550,
      position: 'relative',
    },
    expandedDiv__closeSelectedMediaButton: {
      position: 'absolute',
      right: 0,
      top: 0,
      zIndex: 9888,
    },
    expandedDiv__sceneActionContainer: {
      marginTop: theme.spacing(2),
      display: 'grid',
      gridTemplateColumns: 'repeat(5,1fr)',
      gap: 16,
      [theme.breakpoints.down('lg')]: {
        gap: 8,
      },
    },
    expandedDiv__actionContainer: {
      marginTop: theme.spacing(3),
      display: 'flex',
      justifyContent: 'space-between',
      gap: 16,
    },
    rightContainer: {},
    expandedDiv__talentContainer: {
      display: 'flex',
      gap: 16,
      alignItems: 'center',
    },
    expandedDiv__imageContainer: {
      width: 150,
      height: 80,
      overflow: 'hidden',
      borderRadius: 4,
      position: 'relative',
    },
    expandedDiv__image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    expandedDiv__detailContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
    },
    expandedDiv__iconsContainer: {
      display: 'flex',
      gap: 12,
    },
    expandedDiv__iconItem: {
      display: 'flex',
      gap: 6,
      alignItems: 'center',
    },
    expandedDiv__iconItem__hidden: {
      opacity: 0.4,
    },
    expandedDiv__icon: {
      fontSize: 16,
    },
    noteTextAreaContainer: {
      margin: theme.spacing(2, 0),
    },
    expandedDiv__commentSectionContainer: {
      marginTop: theme.spacing(4),
    },
    expandedDiv__commentSection__content: {},
    buttonIcon: {
      fontSize: '16px !important',
    },
    actionButton: {
      backgroundColor: '#1b232d',
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.customBlack.dark,
      },
    },
    commentContainer: {
      maxHeight: 600,
      overflow: 'auto',
      paddingRight: 12,
      display: 'flex',
      flexDirection: 'column',
      gap: 32,
    },
    singleImageContainer: {
      width: '100%',
      minHeight: 550,
    },
    singleImage: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
  }),
);
