import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function EmailIconTwoTone(props: SvgIconProps) {
  return (
    <SvgIcon width="20" height="15" viewBox="0 0 20 15" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.875 0H3.125A3.129 3.129 0 0 0 0 3.125v8.75A3.129 3.129 0 0 0 3.125 15h13.75A3.129 3.129 0 0 0 20 11.875v-8.75A3.129 3.129 0 0 0 16.875 0zm1.875 11.875a1.877 1.877 0 0 1-1.875 1.875H3.125a1.877 1.877 0 0 1-1.875-1.875v-8.75c0-1.034.841-1.875 1.875-1.875h13.75c1.034 0 1.875.841 1.875 1.875v8.75zm-1.433-8.308L13.384 7.5l3.933 3.933a.625.625 0 1 1-.884.884L12.5 8.384l-2.058 2.058a.623.623 0 0 1-.884 0L7.5 8.384l-3.933 3.933a.623.623 0 0 1-.884 0 .625.625 0 0 1 0-.884L6.616 7.5 2.683 3.567a.625.625 0 1 1 .884-.884L10 9.116l6.433-6.433a.625.625 0 1 1 .884.884z"
        fill="#3E8BFF"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default EmailIconTwoTone;
