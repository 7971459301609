import { Box, Button, Grid } from '@material-ui/core';
import {
  CustomVideoPlayer,
  DeclineIconTwoTone,
  LikeIconTwoTone,
  MagnifyingGlassIconTwoTone,
  PlayIconTwoTone,
  ShadedStarIconTwoTone,
  ShadedStartBlueIconTwoTone,
  StickyNoteBlueIconTwoTone,
  StickyNoteIconTwoTone,
  UploadIconTwoTone,
} from '@room-match/shared-ui-components';
import clsx from 'clsx';
import { PlayIcon } from 'components/Icons';
import { ImageCarousel } from 'components/ImageCarousel';
import React, { useEffect, useMemo, useState } from 'react';
import { StyledTab, StyledTabs, Typography } from 'shared/elements';
import { CommentTab } from './CommentTab';

import { useStyles } from './AuditionExpandingContentWithTab.styles';
import { IAuditionAttributes } from 'shared/interfaces/IAudition';
import { getFullName } from 'shared/utils/getFullName';
import { IAuditionFlag } from 'shared/interfaces/IAuditionFlag';
import { IComment } from 'shared/interfaces/utils/IComment';
import { NoteTextArea } from 'components/NoteTextArea';
import { NO_IMAGE_PLACEHOLDER_URL } from 'shared/constants/ASSETS_URL';
import { MediumTabAction } from 'shared/enums/MediumTabAction';
import { IMediumAttributes } from 'shared/interfaces/IMedium';
import {
  getMediaImages,
  getMediaImagesPerGroup,
  getMediaScenes,
  getMediaSlates,
  getPrimaryImage,
  getScenesPerGroup,
  getSlatesPerGroup,
} from 'shared/utils/auditionMediaAttachments';
import { IAuditionScheduleGroupAttributes } from 'shared/interfaces/IAuditionScheduleGroup';
import { sumArrayOfNumbers } from '@room-match/shared-utils';
import { getMediaId } from 'shared/utils/auditionMediaAttachments';
import { uploadService } from 'shared/services/uploadService';
import { useGetThumbnail } from 'shared/hooks/useGetThumbnail';

type Props = {
  isShowActionButtons?: boolean;
  audition: IAuditionAttributes;
  internalNotes: IAuditionFlag[];
  publicNotes: IAuditionFlag[];
  onSubmitComment?: (type: 'Internal Note' | 'Public Note', rating: number, note: string) => void;
  mediums: IMediumAttributes[];
  groupId?: string;
  auditionScheduleGroups?: IAuditionScheduleGroupAttributes[];
};

const { getSingleMedia } = uploadService();
const AuditionExpandingContentWithTab: React.FC<Props> = ({
  isShowActionButtons = true,
  audition,
  internalNotes,
  publicNotes,
  onSubmitComment,
  mediums,

  auditionScheduleGroups,
}) => {
  const classes = useStyles();
  const [tab, setTab] = useState<string>('internal_notes');

  const [scenes, setScenes] = useState<IMediumAttributes[]>([]);
  const [images, setImages] = useState<IMediumAttributes[]>([]);
  const [slates, setSlates] = useState<IMediumAttributes[]>([]);
  const [selectedAttachments, setSelectedAttachments] = useState<{
    type: string;
    attachments: IMediumAttributes[];
  } | null>(null);

  const [selectedGroupId, setSelectedGroupId] = useState<string>('');

  const { thumbnail, handleChangeVideoUrl, isErrorThumbnail } = useGetThumbnail();

  const tabs = useMemo(() => {
    return [
      {
        name: 'internal_notes',
        header: (
          <Box sx={{ display: 'flex', alignItems: 'center', gridGap: 6 }}>
            <StickyNoteIconTwoTone style={{ fontSize: 16 }} />
            <Typography>Internal Notes</Typography>
          </Box>
        ),
      },
      {
        name: 'public_notes',
        header: (
          <Box sx={{ display: 'flex', alignItems: 'center', gridGap: 6 }}>
            <StickyNoteBlueIconTwoTone style={{ fontSize: 16 }} />
            <Typography>Public Notes</Typography>
          </Box>
        ),
      },
    ];
  }, []);

  useEffect(() => {
    if (auditionScheduleGroups && auditionScheduleGroups.length > 0) {
      const getFirstAuditionScheduleGroup = auditionScheduleGroups[0];
      setSelectedGroupId(getFirstAuditionScheduleGroup.id);
    }
  }, [auditionScheduleGroups]);

  useEffect(() => {
    const getImagesMediums = getMediaImagesPerGroup(mediums, selectedGroupId);
    const getSceneMediums = getScenesPerGroup(mediums, selectedGroupId);
    const getSlateMediums = getSlatesPerGroup(mediums, selectedGroupId);

    setScenes(getSceneMediums);
    setImages(getImagesMediums);
    setSlates(getSlateMediums);

    const getFirstSceneMediums = getSceneMediums[0];
    if (getFirstSceneMediums && getFirstSceneMediums.tag_list.length > 0) {
      setSelectedAttachments({ type: getFirstSceneMediums.tag_list[0], attachments: [getFirstSceneMediums] });
      handleChangeVideoUrl(getFirstSceneMediums.attachment);
      return;
    } else {
      setSelectedAttachments({ type: MediumTabAction.IMAGES, attachments: getImagesMediums });
      handleChangeVideoUrl('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedGroupId]);

  const handleMediumTabActionChange = (mediumTabAction: string, attachments: IMediumAttributes[]) => {
    if (selectedAttachments?.type !== mediumTabAction) {
      setSelectedAttachments({ type: mediumTabAction, attachments });
      if (attachments.length > 0) {
        handleChangeVideoUrl(attachments[0].attachment);
      }
    }
  };

  const handleChangeGroupTab = (groupId: string) => {
    setSelectedGroupId(groupId);
    const getImagesMediums = getMediaImagesPerGroup(mediums, selectedGroupId);
    const getSceneMediums = getScenesPerGroup(mediums, selectedGroupId);
    const getSlateMediums = getSlatesPerGroup(mediums, selectedGroupId);
    setScenes(getSceneMediums);
    setImages(getImagesMediums);
    setSlates(getSlateMediums);
  };

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (tab !== newValue) {
      setTab(newValue);
    }
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

  const handleSubmitComment = (rating: number, note: string) => {
    if (onSubmitComment) {
      if (tab === 'internal_notes') {
        onSubmitComment('Internal Note', rating, note);
      } else {
        onSubmitComment('Public Note', rating, note);
      }
    }
  };

  const internalNotesComments = useMemo(() => {
    return internalNotes.map((internalNote) => {
      return {
        comment: internalNote.attributes.comment,
        rating: internalNote.attributes.rating,
        user: internalNote.attributes.user.data.attributes,
        created_at: internalNote.attributes.created_at,
      };
    });
  }, [internalNotes]) as IComment[];

  const publicNotesComments = useMemo(() => {
    return publicNotes.map((publicNote) => {
      return {
        comment: publicNote.attributes.comment,
        rating: publicNote.attributes.rating,
        user: publicNote.attributes.user.data.attributes,
        created_at: publicNote.attributes.created_at,
      };
    });
  }, [publicNotes]) as IComment[];

  return (
    <div className={classes.expandedDiv}>
      {/* Content */}
      <Grid container spacing={4}>
        {/* Left Player */}
        <Grid item lg={7}>
          <div className={classes.expandedDiv__groupButtonsContainer}>
            {auditionScheduleGroups &&
              auditionScheduleGroups.length > 0 &&
              auditionScheduleGroups
                .sort((a, b) => Number(a.group_number) - Number(b.group_number))
                .map((group) => (
                  <div>
                    <Button
                      variant={selectedGroupId === group.id ? 'contained' : 'outlined'}
                      color="primary"
                      style={{ color: '#fff', minWidth: '0' }}
                      onClick={() => handleChangeGroupTab(group.id)}
                    >
                      {group.group_name}
                    </Button>
                  </div>
                ))}
          </div>
          <div className={classes.expandedDiv__leftContainer}>
            <div className={classes.expandedDiv__mediaContainer}>
              {selectedAttachments && (
                <>
                  {selectedAttachments.type.toLocaleLowerCase().startsWith('image') ? (
                    <>
                      {selectedAttachments.attachments.length > 0 && (
                        <ImageCarousel images={selectedAttachments.attachments} />
                      )}
                    </>
                  ) : (
                    <div className={classes.expandedDiv__videoPlayerContainer}>
                      {selectedAttachments.attachments && selectedAttachments.attachments.length > 0 && (
                        <CustomVideoPlayer
                          url={selectedAttachments.attachments[0].attachment || ''}
                          thumbnail={
                            !isErrorThumbnail
                              ? thumbnail
                              : getPrimaryImage(audition, getMediaImagesPerGroup(mediums, selectedGroupId))
                          }
                          playIcon={
                            <PlayIcon style={{ fontSize: 48, position: 'absolute', right: '2%', bottom: '3%' }} />
                          }
                          key={Math.random().toString()}
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={classes.expandedDiv__sceneActionContainer}>
              {scenes.map((scene, index) => (
                <Button
                  key={scene.id}
                  variant={selectedAttachments?.type !== scene.tag_list[0] ? 'outlined' : 'contained'}
                  color="primary"
                  startIcon={<PlayIconTwoTone classes={{ root: classes.buttonIcon }} />}
                  onClick={() => handleMediumTabActionChange(scene.tag_list[0], [scene])}
                >
                  {`Video ${index + 1}`}
                </Button> //  === 'Scene 1' ? 'Slate' : scene.tag_list[0]
              ))}

              {slates.map((slate) => (
                <Button
                  key={slate.id}
                  variant={selectedAttachments?.type !== slate.tag_list[0] ? 'outlined' : 'contained'}
                  color="primary"
                  startIcon={<PlayIconTwoTone classes={{ root: classes.buttonIcon }} />}
                  onClick={() => handleMediumTabActionChange(slate.tag_list[0], [slate])}
                >
                  {slate.tag_list[0]}
                </Button>
              ))}
              {images.length > 0 && (
                <Button
                  variant={selectedAttachments?.type !== MediumTabAction.IMAGES ? 'outlined' : 'contained'}
                  color="primary"
                  style={{ color: '#fff' }}
                  startIcon={<UploadIconTwoTone classes={{ root: classes.buttonIcon }} />}
                  onClick={() => handleMediumTabActionChange(MediumTabAction.IMAGES, images)}
                >
                  Images
                </Button>
              )}
            </div>
            {/* Scene Action */}
            {/* Actions */}
            {isShowActionButtons && (
              <div className={classes.expandedDiv__actionContainer}>
                <Button
                  variant="contained"
                  className={classes.actionButton}
                  startIcon={<MagnifyingGlassIconTwoTone classes={{ root: classes.buttonIcon }} />}
                  fullWidth
                >
                  Mark for Edit
                </Button>
                <Button
                  variant="contained"
                  className={classes.actionButton}
                  startIcon={<DeclineIconTwoTone classes={{ root: classes.buttonIcon }} />}
                  fullWidth
                >
                  Reject
                </Button>
                <Button
                  variant="contained"
                  className={classes.actionButton}
                  startIcon={<LikeIconTwoTone classes={{ root: classes.buttonIcon }} />}
                  fullWidth
                >
                  Approve
                </Button>
              </div>
            )}
          </div>
        </Grid>
        {/* Comments Section */}
        <Grid item lg={5}>
          <div className={classes.rightContainer}>
            {/* Talent Profie */}
            <div className={classes.expandedDiv__talentContainer}>
              <div className={classes.expandedDiv__imageContainer}>
                <img
                  src={getPrimaryImage(audition, getMediaImagesPerGroup(mediums, selectedGroupId))}
                  className={classes.expandedDiv__image}
                />
              </div>
              {/* Talent Profile Detail  */}
              <div className={classes.expandedDiv__detailContainer}>
                <Typography fontWeight={500}>
                  {getFullName(audition.profile?.talent?.first_name, audition.profile?.talent?.last_name)}
                </Typography>
                {/* Talent Profile Icons */}
                <div className={classes.expandedDiv__iconsContainer}>
                  <div
                    className={clsx(classes.expandedDiv__iconItem, {
                      [classes.expandedDiv__iconItem__hidden]: tab !== 'internal_notes',
                    })}
                  >
                    <StickyNoteIconTwoTone classes={{ root: classes.expandedDiv__icon }} />
                    <Typography fontSize={16}>{internalNotesComments.length}</Typography>
                  </div>
                  <div
                    className={clsx(classes.expandedDiv__iconItem, {
                      [classes.expandedDiv__iconItem__hidden]: tab !== 'internal_notes',
                    })}
                  >
                    <ShadedStarIconTwoTone classes={{ root: classes.expandedDiv__icon }} />
                    <Typography fontSize={16}>
                      {sumArrayOfNumbers(internalNotesComments.map((v) => v.rating || 0)) || 0}
                    </Typography>
                  </div>
                  <div
                    className={clsx(classes.expandedDiv__iconItem, {
                      [classes.expandedDiv__iconItem__hidden]: tab !== 'public_notes',
                    })}
                  >
                    <StickyNoteBlueIconTwoTone classes={{ root: classes.expandedDiv__icon }} />
                    <Typography fontSize={16}>{publicNotesComments.length}</Typography>
                  </div>
                  <div
                    className={clsx(classes.expandedDiv__iconItem, {
                      [classes.expandedDiv__iconItem__hidden]: tab !== 'public_notes',
                    })}
                  >
                    <ShadedStartBlueIconTwoTone classes={{ root: classes.expandedDiv__icon }} />
                    <Typography fontSize={16}>
                      {sumArrayOfNumbers(publicNotesComments.map((v) => v.rating || 0)) || 0}
                    </Typography>
                  </div>
                </div>
              </div>
            </div>

            {/* Comment Section */}
            <div className={classes.expandedDiv__commentSectionContainer}>
              <StyledTabs value={tab} onChange={handleTabChange} aria-label="simple tabs example" variant="fullWidth">
                {tabs.map((tab, index) => (
                  <StyledTab key={index} label={tab.header} value={tab.name} {...a11yProps(index)} />
                ))}
              </StyledTabs>
              <div className={classes.noteTextAreaContainer}>
                <NoteTextArea isYellowStar={tab === 'internal_notes'} onSubmitForm={handleSubmitComment} />
              </div>
              <div className={classes.expandedDiv__commentSection__content}>
                <TabPanel value={tab} index="internal_notes">
                  <CommentTab comments={internalNotesComments} isYellowStar />
                </TabPanel>
                <TabPanel value={tab} index="public_notes">
                  <CommentTab comments={publicNotesComments} />
                </TabPanel>
              </div>
            </div>
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AuditionExpandingContentWithTab;
