export const readableProjectUnion = (union: string) => {
  switch (union.toLocaleLowerCase()) {
    case 'actra':
      return 'ACTRA';
    case 'aea':
      return 'AEA';
    case 'caea':
      return 'CAEA';
    case 'equity':
      return 'Equity';
    case 'meaa':
      return 'MEAA';
    case 'no_union_affiliation':
      return 'No Union Affiliation';
    case 'non_union':
      return 'Non Union';
    case 'other':
      return 'Other';
    case 'sag_aftra':
      return 'SAG/AFTRA';
    case 'ubcp_actra':
      return 'UBCP / ACTRA';
    case 'uda':
      return 'UDA';
    default:
      return undefined;
  }
};
