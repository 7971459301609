import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function PointerIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 17 21" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.05 7.15c-.287 0-.56.064-.804.177a1.929 1.929 0 0 0-2.696-1.312 1.93 1.93 0 0 0-2.615-1.276V2.426A1.928 1.928 0 0 0 7.01.5a1.928 1.928 0 0 0-1.926 1.926v9.164l-.993-1.474-.015-.02a2.28 2.28 0 0 0-3.043-.477c-.518.34-.87.864-.988 1.472a2.272 2.272 0 0 0 .353 1.728l3.544 5.586.012.018A4.786 4.786 0 0 0 7.899 20.5h3.977c2.811 0 5.1-2.288 5.1-5.1V9.078a1.928 1.928 0 0 0-1.927-1.926zm.753 8.25a3.932 3.932 0 0 1-3.927 3.928H7.899c-1.188 0-2.3-.583-2.975-1.56l-3.543-5.586-.012-.018a1.11 1.11 0 0 1-.175-.847 1.11 1.11 0 0 1 .482-.72 1.113 1.113 0 0 1 1.471.217l3.108 4.613V2.426a.755.755 0 0 1 1.508 0v6.951h1.172V6.511a.755.755 0 0 1 1.508 0v2.866h1.172v-1.62a.755.755 0 0 1 1.508 0v1.62h1.172v-.3a.755.755 0 0 1 1.508 0V15.4z"
          fill="#677482"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default PointerIcon;
