import { createStyles, makeStyles, Theme } from '@material-ui/core';

interface StyleProps {
  color?: 'primary' | 'mainGrey' | 'lightGrey' | 'darkGrey';
  fontSize?: number;
  variant?: 'heading' | 'subHeading' | 'text';
}
export const useStyles = makeStyles<Theme, StyleProps>((theme) =>
  createStyles({
    baseStyle: {
      color: ({ color }) => {
        if (color === 'primary') {
          return theme.palette.primary.main;
        } else if (color === 'lightGrey') {
          return theme.palette.customGrey.light;
        } else if (color === 'mainGrey') {
          return theme.palette.customGrey.main;
        } else if (color === 'darkGrey') {
          return theme.palette.customGrey.dark;
        }
        return '#fff';
      },
      fontSize: ({ variant }) => {
        if (variant === 'heading') {
          return 24;
        } else if (variant === 'subHeading') {
          return 20;
        }
        return 16;
      },
      fontWeight: ({ variant }) => {
        if (variant === 'heading') {
          return 700;
        } else if (variant === 'subHeading') {
          return 500;
        }
        return 400;
      },
      wordBreak: 'break-all',
    },
  }),
);
