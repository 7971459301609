import {
  Box,
  Button,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

import { DownloadIcon, PlayIcon } from 'components/Icons';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from 'shared/elements';
import { onOpenResumeGallery } from 'shared/redux/slicers/talentProfile.slicer';
import { useStyles } from './ResumeContent.styles';
import { IProfileResumeCategory } from 'shared/interfaces/IProfile';
import { IMedium, IMediumAttributes } from 'shared/interfaces/IMedium';
import { EmptyList } from 'components/EmptyList';

type Props = {
  resumeData?: IProfileResumeCategory[];
  handleSetResumeMedia?: (instance: IMediumAttributes[]) => void;
};

const ResumeContent: React.FC<Props> = ({ resumeData, handleSetResumeMedia }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const resume = useMemo(() => {
    return resumeData ? resumeData.filter((item) => item.credits && item.credits.length > 0) : [];
  }, [resumeData]);

  const handleResumeGalleryOpen = (medias: IMedium[]) => {
    const reStructuredMedia = medias.map((media: IMedium) => ({
      ...media.attributes,
    }));
    if (handleSetResumeMedia) {
      handleSetResumeMedia(reStructuredMedia);
    }
    dispatch(onOpenResumeGallery());
  };

  return (
    <Box className={classes.resumeContainer}>
      {/* Resume List */}
      <Box className={classes.resumeList}>
        {/* Resume Item */}
        {resume.length > 0 ? (
          <React.Fragment>
            <Button
              startIcon={<DownloadIcon style={{ fontSize: 18 }} />}
              variant="contained"
              color="secondary"
              disableElevation
              style={{ backgroundColor: '#2c3643', position: 'absolute', right: '20px', top: '20px' }}
            >
              Download Resume
            </Button>
            {resume.map((item) => (
              <Box className={classes.resumeList__item} key={item.category_id}>
                <Box className={classes.resumeList__titleContainer}>
                  <Typography color="primary">{item.category}</Typography>
                </Box>
                <TableContainer component={Box} className={classes.resumeList__tableContainer}>
                  <Table>
                    <TableHead>
                      <TableRow>
                        <TableCell>Year</TableCell>
                        <TableCell>Title</TableCell>
                        <TableCell>Role</TableCell>
                        <TableCell>Director</TableCell>
                        <TableCell>Media</TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {item.credits &&
                        item.credits.map((row, index) => (
                          <TableRow key={index}>
                            <TableCell className={classes.resumeList__tableData} width="10%">
                              {row.year}
                            </TableCell>
                            <TableCell className={classes.resumeList__tableData} scope="row" width="35%">
                              {row.title}
                            </TableCell>
                            <TableCell className={classes.resumeList__tableData} width="20%">
                              {row.role}
                            </TableCell>
                            <TableCell className={classes.resumeList__tableData} width="20%">
                              {row.director}
                            </TableCell>
                            <TableCell className={classes.resumeList__tableData} width="15%">
                              {/* {row.attached_media.length > 0 ? ( */}
                              <Box className={classes.resumeList__mediaContainer}>
                                <IconButton size="small" onClick={() => handleResumeGalleryOpen(row.attached_media)}>
                                  <PlayIcon style={{ fontSize: 16 }} />
                                </IconButton>
                                <Typography fontSize={14}>View Media</Typography>
                              </Box>
                              {/* ) : (
                          <Box style={{ marginLeft: 3 }}>
                            <Typography>—</Typography>
                          </Box>
                        )} */}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </Box>
            ))}
          </React.Fragment>
        ) : (
          <div style={{ marginTop: 12 }}>
            <EmptyList text="No Resume Data" />
          </div>
        )}
      </Box>
    </Box>
  );
};

export default ResumeContent;
