import { Menu, MenuProps } from '@material-ui/core';

import React from 'react';
import { useStyles } from './StyledMenu.styles';

const StyledMenu: React.FC<MenuProps> = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <Menu {...props} classes={{ ...props.classes, paper: classes.paper }}>
      {children}
    </Menu>
  );
};

export default StyledMenu;
