import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    boxContainer: {
      '& .MuiOutlinedInput-root': {
        height: '60px',
        borderRadius: '6px',
      },
      '& .MuiInputBase-formControl': {
        height: '60px !important',
        fontSize: '14px !important',
        overflow: 'hidden',
      },
      '& input': {
        fontSize: '14px',
      },
    },
    extraContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: '5px',
      alignItems: 'center',
      '& a, & span': {
        fontSize: '14px',
      },
      '& a': {
        color: '#3e8bff',
      },
    },
    link: {
      color: '#3e8bff',
      textDecoration: 'none',
    },
    buttonContainer: {
      display: 'flex',
      flexDirection: 'column',
      width: '100%',
      alignItems: 'center',
      justifyContent: 'center',
      '& button, & a': {
        fontSize: '14px',
        width: '100%',
        maxWidth: '400px',
        textTransform: 'initial',
      },
    },
    buttonCls: {
      marginTop: '20px',
      height: '45px',
      borderRadius: '6px',
      textTransform: 'unset',
    },
    loginFooter: {
      marginTop: '15px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      '& *': {
        fontSize: '14px',
      },
      '& span': {
        color: '#6e767f',
      },
    },
    customContactInput: {
      '& .MuiBox-root': {
        margin: '15px 0px 7px 0px !important',
      },
    },
  }),
);
