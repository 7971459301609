import { createStyles, makeStyles } from '@material-ui/core';

export const useWorksheetSectionStyle = makeStyles((theme) =>
  createStyles({
    root: {
      background: '#1d232c',
      borderRadius: 8,
      padding: '25px 30px',
    },
    header: {
      width: '100%',
      display: 'flex',
      alignItems: 'center',
      paddingBottom: '25px',
      marginBottom: '25px',
      borderBottom: '2px solid #2d343e',
      position: 'relative',
      justifyContent: 'space-between',

      '&::before': {
        content: '""',
        background: '#3e8bff',
        height: '2px',
        position: 'absolute',
        width: '50px',
        bottom: '-2px',
      },
    },
    collapseButton: {
      background: 'none',
      '& .MuiSvgIcon-root': {
        width: 12,
        height: 14,
      },
    },
    content: {
      maxWidth: 800,
      width: '100%',
      gap: '0.75em',
      display: 'flex',
      flexDirection: 'column',
      transition: 'all 0.3s',
      overflow: 'hidden',
      maxHeight: 0,

      '& .MuiFormLabel-root': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        letterSpacing: '1px',
        alignItems: 'center',

        '& .MuiBox-root': {
          padding: '2px 4px 1px 5px',
          border: '1px solid',
          borderRadius: '100%',
          fontSize: '12px',
        },
      },

      '& .MuiSelect-iconOutlined': {
        right: '0',
        top: '22px',
      },

      '& .MuiSelect-iconOpen': {
        top: '2px',
        right: '13px',
      },
    },

    selectLabelFix: {
      marginBottom: 4,
    },

    twoColumn: {
      display: 'flex',
      gap: '2em',
      width: '100%',
      alignItems: 'flex-end',
      transition: 'all 0.3s',
      overflow: 'hidden',
      maxHeight: 0,

      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',
        gap: '1em',

        '& > div': {
          width: '100%!important',
        },
      },

      '& > div': {
        maxWidth: 700,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',
        gap: '0.75em',

        '& .MuiFormLabel-root': {
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '13px',
          letterSpacing: '1px',
          alignItems: 'center',

          '& .MuiBox-root': {
            padding: '2px 4px 1px 5px',
            border: '1px solid',
            borderRadius: '100%',
            fontSize: '12px',
          },
        },

        '& .MuiSelect-iconOutlined': {
          right: '0',
          top: '22px',
        },

        '& .MuiSelect-iconOpen': {
          top: '2px',
          right: '13px',
        },
      },
    },

    gapLessColumn: {
      display: 'flex',
      width: '100%',
      transition: 'all 0.3s',
      maxHeight: 0,

      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',

        '& > div': {
          width: '100%!important',
        },
      },

      '& > div': {
        maxWidth: 700,
        width: '100%',
        display: 'flex',
        flexDirection: 'column',

        '& .MuiFormLabel-root': {
          display: 'flex',
          justifyContent: 'space-between',
          fontSize: '13px',
          letterSpacing: '1px',
          alignItems: 'center',

          '& .MuiBox-root': {
            padding: '2px 4px 1px 5px',
            border: '1px solid',
            borderRadius: '100%',
            fontSize: '12px',
          },
        },
      },
    },

    content__isNotCollapsed: {
      maxHeight: '1000px',
      transition: 'all 0.3s',
    },
  }),
);
