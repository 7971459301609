import { Card, CardContent, Grid, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { PlayIcon } from 'components/Icons';
import React, { useMemo, Fragment } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from 'shared/elements';
import { useStyles } from './TalentSkillList.styles';
import { IProfileSkillGroup } from 'shared/interfaces/IProfile';
import { IMediumAttributes, IMedium } from 'shared/interfaces/IMedium';
import { onOpenResumeGallery } from 'shared/redux/slicers/talentProfile.slicer';
import { EmptyList } from 'components/EmptyList';
import { getActiveSkills } from 'shared/utils/getActiveSkills';

type Props = {
  skills: IProfileSkillGroup[];
  handleSetResumeMedia: (instance: IMediumAttributes[]) => void;
};

const TalentSkillList: React.FC<Props> = ({ skills, handleSetResumeMedia }) => {
  const dispatch = useDispatch();

  const activeSkills = useMemo(() => {
    return getActiveSkills(skills);
  }, [skills]);

  const handleOpenSkills = (medias: IMedium[]) => {
    const newMedia = medias.map((media) => ({
      ...media.attributes,
    }));

    handleSetResumeMedia(newMedia);
    dispatch(onOpenResumeGallery());
  };

  const classes = useStyles();
  return (
    <div className={classes.skillsListContainer}>
      <Card className={classes.card}>
        <CardContent>
          {/* Header */}
          <div className={classes.skillListHeader}>
            <Typography variant="subHeading" color="primary">
              Skills
            </Typography>
            {/* Legends */}
            <div className={classes.skillListLegend}>
              <div className={classes.skillListLegend__item}>
                <div className={classes.legendCircle} style={{ backgroundColor: '#b4c5df' }}></div>
                <Typography fontSize={12}>Basic</Typography>
              </div>
              <div className={classes.skillListLegend__item}>
                <div className={classes.legendCircle}></div>
                <Typography fontSize={12}>Intermerdiate</Typography>
              </div>
              <div className={classes.skillListLegend__item}>
                <div
                  className={classes.legendCircle}
                  style={{ backgroundImage: 'linear-gradient(to bottom, #209378, #0b6353)' }}
                ></div>
                <Typography fontSize={12}>Expert / Professional</Typography>
              </div>
            </div>
          </div>
          {/* Skill List Items */}
          <div className={classes.skillsListItemsContainer}>
            {/* Skill Item */}
            {activeSkills.length > 0 ? (
              <React.Fragment>
                {activeSkills.map((skillGroup) => (
                  <div className={classes.skillsList__item} key={skillGroup.category}>
                    {skillGroup.groups.map((group) => (
                      <Fragment key={group.name}>
                        <Typography>{`${skillGroup.category} / ${group.name}`}</Typography>
                        <Grid container spacing={2} style={{ marginTop: 0 }}>
                          {group.skills.map((skill) => (
                            <Grid item lg={2} key={skill.key}>
                              <div
                                className={clsx(classes.skillList__playerItem, {
                                  [classes.skillList__playerItem__inter_expert]: skill.proficiency.value === 'E',
                                  [classes.skillList__playerItem__inter_intermediate]: skill.proficiency.value === 'I',
                                  [classes.skillList__playerItem__inter_novice]: skill.proficiency.value === 'N',
                                })}
                              >
                                <Typography fontSize={12}>{skill.key}</Typography>
                                {skill.media.length > 0 && (
                                  <IconButton size="small" onClick={() => handleOpenSkills(skill.media)}>
                                    <PlayIcon fill="red" fontSize="small" height={18} width={18} viewBox="0 0 18 18" />
                                  </IconButton>
                                )}
                              </div>
                            </Grid>
                          ))}
                        </Grid>
                      </Fragment>
                    ))}
                  </div>
                ))}
              </React.Fragment>
            ) : (
              <EmptyList text="No Skills Data" />
            )}
          </div>
        </CardContent>
      </Card>
    </div>
  );
};

export default TalentSkillList;
