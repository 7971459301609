import { ROUTES } from 'shared/constants/ROUTES';

export const navigateToRoute = {
  toSetting: (tab: string) => `${ROUTES.CASTING.SETTINGS}`.replace(':tab', tab),
  toSession: (projectId: string, sessionId: string) =>
    `${ROUTES.CASTING.PROJECTS.SESSION.UPDATE_SESSION}`
      .replace(':projectId', projectId)
      .replace(':sessionId', sessionId),
  toProjectTab: (projectId: string, tab: string) =>
    `${ROUTES.CASTING.PROJECTS.PROJECT_TAB_DETAIL}`.replace(':projectId', projectId).replace(':tab', tab),
  toProjectChildTab: (projectId: string, tab: string, childTab: string) =>
    `${ROUTES.CASTING.PROJECTS.PROJECT_CHILDTAB_DETAIL}`
      .replace(':projectId', projectId)
      .replace(':tab', tab)
      .replace(':childTab', childTab),
  toProjectChildTabDetailTab: (projectId: string, tab: string, projectDetailId: string, childTab: string) =>
    `${ROUTES.CASTING.PROJECTS.PROJECT_DETAIL_CHILDTAB_DETAIL}`
      .replace(':projectId', projectId)
      .replace(':tab', tab)
      .replace(':projectDetailId', projectDetailId)
      .replace(':childTab', childTab),
  toProjectChildTabPdfPreview: (projectId: string, tab: string, childTab: string) =>
    `${ROUTES.PROJECT_DETAIL_CHILDTAB_PDF_PREVIEW}`
      .replace(':projectId', projectId)
      .replace(':tab', tab)
      .replace(':childTab', childTab),
  toProjectSessionChildTabPdfPreview: (projectId: string, tab: string, sessionId: string, childTab: string) =>
    `${ROUTES.PROJECT_SESSION_DETAIL_CHILDTAB_PDF_PREVIEW}`
      .replace(':projectId', projectId)
      .replace(':tab', tab)
      .replace(':sessionId', sessionId)
      .replace(':childTab', childTab),
  toEditProject: (projectId: string) => `${ROUTES.CASTING.PROJECTS.EDIT_PROJECT}`.replace(':projectId', projectId),
  toCreateProject: (type: string) => `${ROUTES.CASTING.PROJECTS.CREATE_PROJECT}`.replace(':type', type),
  toCreateRole: (projectId: string) => `${ROUTES.CASTING.PROJECTS.ROLE.CREATE_ROLE}`.replace(':projectId', projectId),
  toCreateStRole: (projectId: string) =>
    `${ROUTES.CASTING.PROJECTS.ROLE.CREATE_ST_ROLE}`.replace(':projectId', projectId),
  toEditRole: (projectId: string, roleId: string) =>
    `${ROUTES.CASTING.PROJECTS.ROLE.EDIT_ROLE}`.replace(':projectId', projectId).replace(':roleId', roleId),
  toProfileLink: (token: string) => ROUTES.TALENT_PROFILE_LINK.replace(':token', token),
};

export const navigateToClientRoute = {
  toForgotPassword: (companyId: string) => `${ROUTES.CLIENT_FORGOT_PASSWORD}`.replace(':companyId', companyId),
  toLogin: (companyId: string) => `${ROUTES.LOGIN_CLIENT}`.replace(':companyId', companyId),
  toClientViewPreview: (projectId: string) =>
    `${ROUTES.CLIENT.PROJECTS.CLIENT_VIEW_PREVIEW}`.replace(':projectId', projectId),
  toClientView: (projectId: string) => `${ROUTES.CLIENT.PROJECTS.CLIENT_VIEW}`.replace(':projectId', projectId),
};
