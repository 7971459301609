import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function ViewedIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 16" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.69 3.146c2.26 1.645 4.05 3.8 4.05 3.832.472.57.472 1.398 0 1.969-2.732 3.301-6.87 6.481-11.193 6.481-4.322 0-8.461-3.18-11.193-6.481a1.546 1.546 0 0 1 0-1.97c.687-.83 2.126-2.431 4.05-3.83 4.842-3.526 9.435-3.533 14.287 0zm-7.143-1.228c-4.788 0-8.94 4.567-10.097 5.966a.123.123 0 0 0 0 .156c1.158 1.4 5.309 5.966 10.097 5.966 4.788 0 8.94-4.566 10.097-5.966.068-.081-.004-.156 0-.156-1.158-1.4-5.309-5.966-10.097-5.966zm0 1.067a4.983 4.983 0 0 1 4.977 4.977 4.983 4.983 0 0 1-4.977 4.978 4.983 4.983 0 0 1-4.978-4.978 4.983 4.983 0 0 1 4.978-4.977zm0 1.422a3.56 3.56 0 0 0-3.555 3.555 3.56 3.56 0 0 0 3.555 3.556 3.56 3.56 0 0 0 3.555-3.556 3.56 3.56 0 0 0-3.555-3.555z"
          fill={props.htmlColor || '#677482'}
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default ViewedIcon;
