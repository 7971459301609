import React, { useContext, useEffect, useState } from 'react';
import {
  CarouselProvider,
  Slider,
  Slide,
  ButtonBack,
  ButtonNext,
  CarouselContext,
  Image,
  WithStore,
} from 'pure-react-carousel';
import 'pure-react-carousel/dist/react-carousel.es.css';
import './ImageCarousel.css';
import { useStyles } from './ImageCarousel.styles';
import { Box } from '@material-ui/core';
import { NextIcon, PrevIcon } from 'components/Icons';
import clsx from 'clsx';
import { getImageWidth } from '@room-match/shared-utils';
import DecoratedComponent from './DecoratedComponent';
import { NO_IMAGE_PLACEHOLDER_URL } from 'shared/constants/ASSETS_URL';
import { IMediumAttributes } from 'shared/interfaces/IMedium';

const images = [
  {
    src: NO_IMAGE_PLACEHOLDER_URL,
    image: NO_IMAGE_PLACEHOLDER_URL,
    width: 1920,
    height: 2401,
  },
  {
    src: NO_IMAGE_PLACEHOLDER_URL,
    image: NO_IMAGE_PLACEHOLDER_URL,
    width: 1980,
    height: 1280,
  },
  {
    src: NO_IMAGE_PLACEHOLDER_URL,
    image: NO_IMAGE_PLACEHOLDER_URL,
    width: 1920,
    height: 2401,
  },
  {
    src: NO_IMAGE_PLACEHOLDER_URL,
    image: NO_IMAGE_PLACEHOLDER_URL,
    width: 1980,
    height: 1280,
  },
  {
    src: NO_IMAGE_PLACEHOLDER_URL,
    image: NO_IMAGE_PLACEHOLDER_URL,
    width: 1920,
    height: 2401,
  },
  {
    src: NO_IMAGE_PLACEHOLDER_URL,
    image: NO_IMAGE_PLACEHOLDER_URL,
    width: 1980,
    height: 1280,
  },
  {
    src: NO_IMAGE_PLACEHOLDER_URL,
    image: NO_IMAGE_PLACEHOLDER_URL,
    width: 1920,
    height: 2401,
  },
  {
    src: NO_IMAGE_PLACEHOLDER_URL,
    image: NO_IMAGE_PLACEHOLDER_URL,
    width: 1980,
    height: 1280,
  },
  {
    src: NO_IMAGE_PLACEHOLDER_URL,
    image: NO_IMAGE_PLACEHOLDER_URL,
    width: 1920,
    height: 2401,
  },
  {
    src: NO_IMAGE_PLACEHOLDER_URL,
    image: NO_IMAGE_PLACEHOLDER_URL,
    width: 1980,
    height: 1280,
  },
];
const BOX_HEIGHT = 100;

type Props = {
  images?: IMediumAttributes[];
  fit?: string;
};
const ImageCarousel: React.FC<Props> = ({ images = [], fit = 'cover' }) => {
  const classes = useStyles();

  const imageData = images.map((img) => {
    return {
      width: img.file_width,
      height: img.file_height,
      src: img.attachment || img.attachment_url,
    };
  });

  return (
    <Box className={classes.container}>
      <CarouselProvider
        naturalSlideWidth={100}
        naturalSlideHeight={125}
        totalSlides={images.length}
        visibleSlides={1}
        currentSlide={0}
        isIntrinsicHeight={true}
        infinite={true}
      >
        <Slider>
          {images.map((image, i) => (
            <>
              {image.file_width > image.file_height ? (
                <Slide index={i} classNameHidden={classes.imageHidden}>
                  <Image
                    hasMasterSpinner={true}
                    style={{ borderRadius: '8', objectFit: fit }}
                    src={image.attachment || image.attachment_url}
                  />
                </Slide>
              ) : (
                <Slide index={i} classNameHidden={classes.imageHidden}>
                  <Image
                    hasMasterSpinner={true}
                    style={{ borderRadius: '8', objectFit: fit }}
                    src={image.attachment || image.attachment_url}
                  />
                </Slide>
              )}
            </>
          ))}
        </Slider>
        <ButtonBack className={clsx(classes.button, classes.buttonBack)}>
          <PrevIcon />
        </ButtonBack>
        <ButtonNext className={clsx(classes.button, classes.buttonNext)}>
          <NextIcon />
        </ButtonNext>
        <DecoratedComponent images={imageData} />
      </CarouselProvider>
      <Box className={classes.blurRight} />
      <Box className={classes.blurLeft} />
    </Box>
  );
};

export default ImageCarousel;
