import { IconButton } from '@material-ui/core';
import { GradientLogIconTwoTone } from '@room-match/shared-ui-components';
import React from 'react';
import { useStyles } from './NoteIcon.styles';

type Props = {
  handleOpenJobLog: () => void;
};
const NoteIcon: React.FC<Props> = ({ handleOpenJobLog }) => {
  const classes = useStyles();
  return (
    <IconButton
      size="small"
      disableRipple
      disableFocusRipple
      edge="end"
      classes={{ root: classes.iconNote }}
      onClick={() => handleOpenJobLog()}
    >
      <GradientLogIconTwoTone style={{ fontSize: 18 }} />
    </IconButton>
  );
};

export default NoteIcon;
