import { Box, Button, IconButton, useTheme } from '@material-ui/core';
import {
  StickyNoteBlueIconTwoTone,
  ShadedStartBlueIconTwoTone,
  FilledStarIcon,
  DotsIcon,
  StickyNoteIconTwoTone,
  PlayIconTwoTone,
  ImageIcon,
} from '@room-match/shared-ui-components';
import { ellipseText } from '@room-match/shared-utils';
import { CallBackButton } from 'components/CallBackButton';
import { NoteTextArea } from 'components/NoteTextArea';
import React, { useMemo, useState } from 'react';
import { useQueryClient } from 'react-query';
import { StyledTab, StyledTabs, StyledTooltip, Typography } from 'shared/elements';
import { IAuditionAttributes } from 'shared/interfaces/IAudition';
import {
  IAuditionScheduleAttributes,
  IAuditionScheduleUpdatePhaseAndStatusPayload,
} from 'shared/interfaces/IAuditionSchedule';
import { IMediumAttributes } from 'shared/interfaces/IMedium';
import { IComment } from 'shared/interfaces/utils/IComment';
import { ISelectMediaButtonProps } from 'shared/interfaces/utils/ISelectMediaButtonProps';
import { auditionScheduleFlagService } from 'shared/services/auditionScheduleFlagService';
import { getInternalNotesData, getPublicNotesData } from 'shared/utils/auditionFlagParser';
import {
  getMediaImagesPerGroup,
  getPrimaryImage,
  getSlatesPerGroup,
  getViewMediaImagePerGroup,
} from 'shared/utils/auditionMediaAttachments';
import { getFullName } from 'shared/utils/getFullName';
import { CommentTab } from '../CommentTab';
import { auditionScheduleService } from 'shared/services/auditionScheduleService';
import { AuditionStatus } from 'shared/enums/AuditionStatus';
import { AuditionPhase } from 'shared/enums/AuditionPhase';

import { useStyles } from './GroupCommentItem.styles';
import { a11yProps } from 'shared/utils/a11yProps';

function TabPanel(props: any) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`wrapped-tabpanel-${index}`}
      aria-labelledby={`wrapped-tab-${index}`}
      {...other}
    >
      {value === index && <>{children}</>}
    </div>
  );
}

type Props = {
  isEditMode?: boolean;
  audition: IAuditionAttributes;
  handleSelectedMediumTab: (instance: IMediumAttributes[], auditionSchedule: IAuditionScheduleAttributes) => void;
  handleSelectedMediaButton: (mediaButton: ISelectMediaButtonProps) => void;
  selectedMediaButton: ISelectMediaButtonProps | null;
  groupId: string;
  sessionId: string;
  auditionSchedule: IAuditionScheduleAttributes;
  isDisabledComment?: boolean;
  isDisabledCallback?: boolean;
};

const { updateAuditionScheduleStatusAndPhase } = auditionScheduleService();
const { getAuditionScheduleFlags, createAuditionScheduleFlag } = auditionScheduleFlagService();
const GroupCommentItem: React.FC<Props> = ({
  isEditMode,
  audition,
  handleSelectedMediaButton,
  selectedMediaButton,
  groupId,
  auditionSchedule,
  sessionId,
  isDisabledComment,
  isDisabledCallback,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const [selectedTab, setSelectedTab] = useState<string>('public_notes');
  const { mutate: updateAuditionSchedulePhaseAndStatusMutate } = updateAuditionScheduleStatusAndPhase();

  const { data: auditionFlagData, isLoading: isAuditionFlagLoading } = getAuditionScheduleFlags(auditionSchedule.id, {
    enabled: !!auditionSchedule.id,
  });
  const { mutate: mutateCreateAuditionFlag } = createAuditionScheduleFlag();
  const queryClient = useQueryClient();

  const handleTabChange = (_: React.ChangeEvent<{}>, newValue: string) => {
    if (selectedTab !== newValue) {
      setSelectedTab(newValue);
    }
  };

  const tabs = useMemo(() => {
    return [
      {
        name: 'internal_notes',
        header: (
          <Box sx={{ display: 'flex', alignItems: 'center', gridGap: 6 }}>
            <StickyNoteIconTwoTone style={{ fontSize: 16 }} />
            <Typography>Internal Notes</Typography>
          </Box>
        ),
      },
      {
        name: 'public_notes',
        header: (
          <Box sx={{ display: 'flex', alignItems: 'center', gridGap: 6 }}>
            <StickyNoteBlueIconTwoTone style={{ fontSize: 16 }} />
            <Typography>Public Notes</Typography>
          </Box>
        ),
      },
    ];
  }, []);

  const onCreateNote = (rating: number, note: string, flagType: 'Public Note' | 'Internal Note' = 'Public Note') => {
    mutateCreateAuditionFlag(
      {
        auditionScheduleId: auditionSchedule.id,
        payload: { flag_type: flagType, comment: note, rating: rating },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['audition_schedule_flag', auditionSchedule.id], {
            refetchInactive: true,
          });
          queryClient.invalidateQueries(['audition'], {
            refetchInactive: true,
          });
        },
      },
    );
  };

  const onUpdateAudition = (auditionScheduleId: string, status: AuditionStatus, phase: AuditionPhase) => {
    const payload: IAuditionScheduleUpdatePhaseAndStatusPayload = {
      audition_schedules: [
        {
          id: auditionScheduleId,
          status,
          phase,
        },
      ],
    };
    updateAuditionSchedulePhaseAndStatusMutate(
      { payload },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['auditionScheduleGroup', sessionId], {
            refetchInactive: true,
          });
        },
      },
    );
  };

  const publicNotes = useMemo(() => {
    return auditionFlagData ? getPublicNotesData(auditionFlagData.data) : [];
  }, [auditionFlagData]);

  const internalNotes = useMemo(() => {
    return auditionFlagData ? getInternalNotesData(auditionFlagData.data) : [];
  }, [auditionFlagData]);

  const publicNotesComments = useMemo(() => {
    return publicNotes.map((publicNote) => {
      return {
        comment: publicNote.attributes.comment,
        rating: publicNote.attributes.rating,
        user: publicNote.attributes.user.data.attributes,
        created_at: publicNote.attributes.created_at,
      };
    });
  }, [publicNotes]) as IComment[];

  const internalNotesComments = useMemo(() => {
    return internalNotes.map((internalNote) => {
      return {
        comment: internalNote.attributes.comment,
        rating: internalNote.attributes.rating,
        user: internalNote.attributes.user.data.attributes,
        created_at: internalNote.attributes.created_at,
      };
    });
  }, [internalNotes]) as IComment[];

  const isMediaButtonSelected = (type: string, id: string) => {
    if (selectedMediaButton) {
      return selectedMediaButton.type === type && selectedMediaButton.id === id;
    }
    return false;
  };

  const getAuditionSlates = useMemo(() => {
    return getSlatesPerGroup(auditionSchedule.medium_attachments, groupId);
  }, [auditionSchedule.medium_attachments, groupId]);

  return (
    <div className={classes.itemContainer}>
      <div className={classes.talentContainer}>
        <div className={classes.imageContainer}>
          <img
            src={getPrimaryImage(
              auditionSchedule.audition,
              getMediaImagesPerGroup(auditionSchedule.medium_attachments, groupId),
            )}
            className={classes.image}
          />
        </div>
        {/* Talent Profile Detail  */}
        <div className={classes.detailContainer}>
          <div className={classes.detail__description}>
            <StyledTooltip
              title={getFullName(audition.profile.talent?.first_name, audition.profile.talent?.last_name) || '—'}
            >
              <div>
                <Typography fontWeight={500}>
                  {ellipseText(
                    getFullName(audition.profile.talent?.first_name, audition.profile.talent?.last_name) || '—',
                    22,
                  )}
                </Typography>
              </div>
            </StyledTooltip>

            <Typography fontWeight={500} color="primary" fontSize={14}>
              {audition.casting_role.name}
            </Typography>
            {/* Talent Profile Icons */}
            <div className={classes.iconContainer}>
              <div className={classes.iconItem}>
                <StickyNoteBlueIconTwoTone classes={{ root: classes.iconItem__icon }} />
                <Typography fontSize={16}>{publicNotes.length}</Typography>
              </div>
              <div className={classes.iconItem}>
                <ShadedStartBlueIconTwoTone classes={{ root: classes.iconItem__icon }} />
                <Typography fontSize={16}>
                  {publicNotesComments.length > 0 ? publicNotesComments[0].rating : 0}
                </Typography>
              </div>
              {!isDisabledCallback && (
                <CallBackButton
                  disabled={auditionSchedule.status === AuditionStatus.CALLBACK}
                  onClick={() => onUpdateAudition(auditionSchedule.id, AuditionStatus.CALLBACK, AuditionPhase.DECISION)}
                />
              )}

              <IconButton size="small">
                <FilledStarIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
          <div className={classes.detail__actions}>
            <div className={classes.detail__actionContainer}>
              {getAuditionSlates.length > 0 && (
                <Button
                  variant={isMediaButtonSelected('video', audition.id) ? 'outlined' : 'contained'}
                  color="secondary"
                  classes={{ root: classes.actionBtn }}
                  onClick={() =>
                    handleSelectedMediaButton({
                      type: 'video',
                      attachments: getAuditionSlates,
                      isGroup: false,
                      label: 'Slate',
                      id: audition.id,
                    })
                  }
                  startIcon={<PlayIconTwoTone />}
                >
                  Slate
                </Button>
              )}

              <Button
                startIcon={<ImageIcon htmlColor={theme.palette.primary.main} />}
                variant={isMediaButtonSelected('image', audition.id) ? 'outlined' : 'contained'}
                classes={{ root: classes.actionBtn }}
                color="secondary"
                onClick={() =>
                  handleSelectedMediaButton({
                    type: 'image',
                    attachments: getViewMediaImagePerGroup(auditionSchedule.medium_attachments, groupId),
                    isGroup: false,
                    label: 'Image',
                    id: audition.id,
                  })
                }
              >
                Images
              </Button>
            </div>
            <div style={{ alignSelf: 'center' }}>
              <IconButton size="small">
                <DotsIcon fontSize="small" />
              </IconButton>
            </div>
          </div>
        </div>
      </div>

      {!isEditMode && (
        <div className={classes.noteTextAreaContainer}>
          <div className={classes.noteLabel}>
            <StickyNoteBlueIconTwoTone style={{ fontSize: 14 }} />
            <Typography fontSize={14}>Notes</Typography>
          </div>
          {!isDisabledComment && (
            <NoteTextArea
              classes={{ paper: classes.notePaper }}
              onSubmitForm={(rating, note) => onCreateNote(rating, note)}
            />
          )}
        </div>
      )}

      {/* Comment Section */}
      {isEditMode ? (
        <div className={classes.commentSectionContainer}>
          <StyledTabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="simple tabs example"
            variant="fullWidth"
          >
            {tabs.map((tab, index) => (
              <StyledTab
                key={index}
                label={tab.header}
                value={tab.name}
                {...a11yProps(index)}
                selected={selectedTab === tab.name}
              />
            ))}
          </StyledTabs>
          <div className={classes.noteTextAreaContainer}>
            <NoteTextArea
              isYellowStar
              onSubmitForm={(rating, note) =>
                onCreateNote(rating, note, selectedTab === 'internal_notes' ? 'Internal Note' : 'Public Note')
              }
            />
          </div>
          <div className={classes.commentSection__content}>
            <TabPanel value={selectedTab} index="internal_notes">
              <CommentTab comments={internalNotesComments} isYellowStar />
            </TabPanel>
            <TabPanel value={selectedTab} index="public_notes">
              <CommentTab comments={publicNotesComments} />
            </TabPanel>
          </div>
        </div>
      ) : (
        <div className={classes.commentSection__content}>
          <CommentTab comments={publicNotesComments} />
        </div>
      )}
    </div>
  );
};

export default GroupCommentItem;
