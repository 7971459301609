import React, { Fragment } from 'react';
import { Redirect, Route, Switch } from 'react-router';
import { Login } from 'pages/Login';
import { LoginClient } from 'pages/Client/LoginClient';
import { Signup } from 'pages/Signup';
import { ResetPassword } from 'pages/ResetPassword';
import { ForgotPassword } from 'pages/ForgotPassword';
import { ROUTES } from 'shared/constants/ROUTES';
import { NotFound } from 'pages/NotFound';

import PrivateRoute from './PrivateRoute';
import PublicRoute from './PublicRoute';
import PDFRoute from './PDFRoute';
import ClientPublicRoute from './ClientPublicRoute';
import ClientRoute from './ClientRoute';
import { ClientResetPassword } from 'pages/Client/ClientResetPassword';
import TalentProfileLink from 'pages/TalentProfileLink';

const AsyncProfile = React.lazy(() => import('pages/Profile'));
const AsyncSettings = React.lazy(() => import('pages/Settings'));
const AsyncDashboard = React.lazy(() => import('pages/Dashboard'));
const AsyncMyProjects = React.lazy(() => import('pages/MyProjects'));
const AsyncCreateProject = React.lazy(() => import('pages/MyProjects/CreateProject'));
const AsyncCreateSession = React.lazy(() => import('pages/MyProjects/CreateSession/CreateSession'));
const AsyncImportSession = React.lazy(() => import('pages/MyProjects/CreateSession/ImportSession'));
const AsyncMessages = React.lazy(() => import('pages/Messages'));
const AsyncCompanies = React.lazy(() => import('pages/Companies'));
const AsyncTalents = React.lazy(() => import('pages/Talents'));
const AsyncContacts = React.lazy(() => import('pages/Contacts'));
const AsyncReports = React.lazy(() => import('pages/Reports'));
const AsyncSchedulesSesssions = React.lazy(() => import('pages/SchedulesSessions'));

const AsyncClientMyProject = React.lazy(() => import('pages/Client/MyProjects/MyProjects'));
const AsyncClientClientView = React.lazy(() => import('pages/Client/ClientView'));
const AsyncClientClientViewPreview = React.lazy(() => import('pages/Client/ClientViewPreview'));

// Project Pages
const AsyncProject = React.lazy(() => import('pages/MyProjects/Project'));
const AsyncAddNewRole = React.lazy(() => import('pages/MyProjects/Project/Roles/AddNewRole'));
const AsyncAddNewSTRole = React.lazy(() => import('pages/MyProjects/Project/SelfTapes/AddNewSTRole'));
// const AsyncSTN12PDF = React.lazy(() => import('pages/PDFs/STN12/STN12PDF'));
// const AsyncTHPDF = React.lazy(() => import('pages/PDFs/TH/THPDF'));
const PDFs = React.lazy(() => import('pages/PDFs/PDFs'));

const Routes = () => {
  return (
    <Fragment>
      <Switch>
        <Route exact path={ROUTES.ROOT} component={() => <Redirect to={ROUTES.LOGIN} />} />
        <Route exact path={ROUTES.TALENT_PROFILE_LINK} component={TalentProfileLink} />
        <PublicRoute exact path={ROUTES.LOGIN} component={Login} />
        <PublicRoute exact path={ROUTES.SIGNUP} component={Signup} />
        <PublicRoute exact path={ROUTES.RESET_PASSWORD} component={ResetPassword} />
        <PublicRoute exact path={ROUTES.FORGOT_PASSWORD} component={ForgotPassword} />
        <PrivateRoute exact path={ROUTES.CASTING.PROFILE} component={AsyncProfile} />
        <PrivateRoute exact path={ROUTES.CASTING.SETTINGS} component={AsyncSettings} />
        <PrivateRoute exact path={ROUTES.CASTING.DASHBOARD} component={AsyncDashboard} />
        <PrivateRoute exact path={ROUTES.CASTING.PROJECTS.PROJECT_LIST} component={AsyncMyProjects} />
        <PrivateRoute exact path={ROUTES.CASTING.PROJECTS.CREATE_PROJECT} component={AsyncCreateProject} />
        <PrivateRoute
          exact
          path={ROUTES.CASTING.PROJECTS.SESSION.CREATE_SESSION_MANUAL}
          component={AsyncCreateSession}
        />
        <PrivateRoute exact path={ROUTES.CASTING.PROJECTS.SESSION.UPDATE_SESSION} component={AsyncCreateSession} />
        <PrivateRoute
          exact
          path={ROUTES.CASTING.PROJECTS.SESSION.CREATE_SESSION_IMPORT}
          component={AsyncImportSession}
        />
        <PrivateRoute exact path={ROUTES.CASTING.PROJECTS.EDIT_PROJECT} component={AsyncCreateProject} />
        <PrivateRoute exact path={ROUTES.CASTING.PROJECTS.PROJECT_TAB_DETAIL} component={AsyncProject} />

        <PrivateRoute exact path={ROUTES.CASTING.PROJECTS.ROLE.CREATE_ROLE} component={AsyncAddNewRole} />
        <PrivateRoute exact path={ROUTES.CASTING.PROJECTS.ROLE.CREATE_ST_ROLE} component={AsyncAddNewSTRole} />
        <PrivateRoute exact path={ROUTES.CASTING.PROJECTS.ROLE.EDIT_ROLE} component={AsyncAddNewRole} />
        <PrivateRoute exact path={ROUTES.CASTING.PROJECTS.PROJECT_CHILDTAB_DETAIL} component={AsyncProject} />
        <PrivateRoute exact path={ROUTES.CASTING.PROJECTS.PROJECT_DETAIL_CHILDTAB_DETAIL} component={AsyncProject} />
        <PrivateRoute exact path={ROUTES.CASTING.MESSAGES} component={AsyncMessages} />
        <PrivateRoute exact path={ROUTES.CASTING.COMPANIES} component={AsyncCompanies} />
        <PrivateRoute exact path={ROUTES.CASTING.SCHEDULES} component={AsyncSchedulesSesssions} />
        <PrivateRoute exact path={ROUTES.CASTING.TALENTS} component={AsyncTalents} />
        <PrivateRoute exact path={`${ROUTES.CASTING.CONTACTS}/:tab`} component={AsyncContacts} />
        <PrivateRoute exact path={`${ROUTES.CASTING.REPORTS}`} component={AsyncReports} />

        <ClientPublicRoute exact path={ROUTES.LOGIN_CLIENT} component={LoginClient} />
        <ClientPublicRoute exact path={ROUTES.CLIENT_FORGOT_PASSWORD} component={LoginClient} />
        <ClientPublicRoute exact path={ROUTES.CLIENT_RESET_PASSWORD} component={ClientResetPassword} />
        <ClientRoute exact path={ROUTES.CLIENT.PROJECTS.PROJECT_LIST} component={AsyncClientMyProject} />
        <ClientRoute exact path={ROUTES.CLIENT.PROJECTS.CLIENT_VIEW} component={AsyncClientClientView} />
        <ClientRoute exact path={ROUTES.CLIENT.PROJECTS.CLIENT_VIEW_PREVIEW} component={AsyncClientClientViewPreview} />
        <ClientRoute exact path={ROUTES.CLIENT.PROFILE} component={AsyncProfile} />

        <PDFRoute exact path={ROUTES.PROJECT_DETAIL_CHILDTAB_PDF_PREVIEW} component={PDFs} />
        <PDFRoute exact path={ROUTES.PROJECT_SESSION_DETAIL_CHILDTAB_PDF_PREVIEW} component={PDFs} />

        <Route path="*" exact component={NotFound} />
      </Switch>
    </Fragment>
  );
};

export default Routes;
