import { Button, Grid, IconButton } from '@material-ui/core';
import { CloseIcon, CustomVideoPlayer, PlayIconTwoTone, UploadIconTwoTone } from '@room-match/shared-ui-components';
import { PlayIcon } from 'components/Icons';
import React, { useEffect, useMemo, useState } from 'react';
import { useStyles } from './SingleExpandingContentWithTab.styles';
import AuditionSingleComment from './AuditionSingleComment/AuditionSingleComment';
import { IAuditionAttributes } from 'shared/interfaces/IAudition';
import { IAuditionFlag } from 'shared/interfaces/IAuditionFlag';
import { IComment } from 'shared/interfaces/utils/IComment';
import { IMediumAttributes } from 'shared/interfaces/IMedium';
import { MediumTabAction } from 'shared/enums/MediumTabAction';
import { ImageCarousel } from 'components/ImageCarousel';
import {
  getMediaImages,
  getMediaImagesPerGroup,
  getMediaScenes,
  getMediaSlates,
  getPrimaryImage,
  getScenesPerGroup,
  getSlatesPerGroup,
} from 'shared/utils/auditionMediaAttachments';
import { IAuditionScheduleGroupAttributes } from 'shared/interfaces/IAuditionScheduleGroup';
import { IAuditionScheduleAttributes } from 'shared/interfaces/IAuditionSchedule';
import { IClientPermissionState } from 'shared/redux/slicers/clientPermission.slicer';
import { useGetThumbnail } from 'shared/hooks/useGetThumbnail';
import { getInternalNotesData, getPublicNotesData } from 'shared/utils/auditionFlagParser';
import { useQueryClient } from 'react-query';
import { auditionScheduleFlagService } from 'shared/services/auditionScheduleFlagService';

type Props = {
  isEditMode?: boolean;
  audition: IAuditionAttributes;
  mediums?: IMediumAttributes[];
  auditionScheduleGroups: IAuditionScheduleGroupAttributes[];
  auditionSchedule: IAuditionScheduleAttributes;
  handleViewedAuditionScheduleGroup: (auditionScheduleGroupId: string) => void;
  handleOpenTalentProfile?: (instance: any, auditionScheduleId: string) => void;
  clientPermissionsState?: IClientPermissionState;
  sessionId?: string;
  handleCloseExpandingContent: () => void;
};

const { getAuditionScheduleFlags, createAuditionScheduleFlag } = auditionScheduleFlagService();
const SingleExpandingContentWithTab: React.FC<Props> = ({
  isEditMode,
  audition,
  mediums,
  auditionScheduleGroups,
  auditionSchedule,
  handleViewedAuditionScheduleGroup,
  handleOpenTalentProfile,
  clientPermissionsState,
  sessionId,
  handleCloseExpandingContent,
}) => {
  const classes = useStyles();

  const [scenes, setScenes] = useState<IMediumAttributes[]>([]);
  const [images, setImages] = useState<IMediumAttributes[]>([]);
  const [slates, setSlates] = useState<IMediumAttributes[]>([]);
  const [selectedAttachments, setSelectedAttachments] = useState<{
    type: string;
    attachments: IMediumAttributes[];
  } | null>(null);

  const [selectedGroupId, setSelectedGroupId] = useState<string>('');
  const { thumbnail, handleChangeVideoUrl, isErrorThumbnail } = useGetThumbnail();

  const queryClient = useQueryClient();
  const { data: auditionFlagData, isLoading: isAuditionFlagLoading } = getAuditionScheduleFlags(auditionSchedule.id, {
    enabled: !!auditionSchedule.id,
  });
  const { mutate: mutateCreateAuditionFlag } = createAuditionScheduleFlag();

  useEffect(() => {
    if (auditionScheduleGroups && auditionScheduleGroups.length > 0) {
      setSelectedGroupId(auditionScheduleGroups[0].id);
    }
  }, [auditionScheduleGroups]);

  // useEffect(() => {
  //   if (auditionScheduleGroups && auditionScheduleGroups.length > 0) {
  //     const getFirstAuditionScheduleGroup = auditionScheduleGroups[0];
  //     setSelectedGroupId(getFirstAuditionScheduleGroup.id);
  //   }
  // }, [auditionScheduleGroups]);

  useEffect(() => {
    const getImagesMediums = getMediaImagesPerGroup(auditionSchedule.medium_attachments, selectedGroupId);
    const getSceneMediums = getScenesPerGroup(auditionSchedule.medium_attachments, selectedGroupId);

    const getSlateMediums = getSlatesPerGroup(auditionSchedule.medium_attachments, selectedGroupId);
    setScenes(getSceneMediums);
    setImages(getImagesMediums);
    setSlates(getSlateMediums);

    if (getSceneMediums.length > 0) {
      setSelectedAttachments({ type: getSceneMediums[0].tag_list[0], attachments: getSceneMediums });
      handleChangeVideoUrl(getSceneMediums[0].attachment);
      return;
    } else {
      setSelectedAttachments({ type: MediumTabAction.IMAGES, attachments: getImagesMediums });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [auditionSchedule, selectedGroupId]);

  const handleChangeGroupTab = (groupId: string) => {
    setSelectedGroupId(groupId);
    const getImagesMediums = getMediaImagesPerGroup(auditionSchedule.medium_attachments, groupId);
    const getSceneMediums = getScenesPerGroup(auditionSchedule.medium_attachments, groupId);
    const getSlateMediums = getSlatesPerGroup(auditionSchedule.medium_attachments, groupId);
    setScenes(getSceneMediums);
    setImages(getImagesMediums);
    setSlates(getSlateMediums);
    handleViewedAuditionScheduleGroup(groupId);
  };

  const handleMediumTabActionChange = (mediumTabAction: string, attachments: IMediumAttributes[]) => {
    if (selectedAttachments?.type !== mediumTabAction) {
      setSelectedAttachments({ type: mediumTabAction, attachments });
      handleChangeVideoUrl(attachments[0].attachment);
    }
  };

  const onCreateNote = (rating: number, note: string, flagType: 'Public Note' | 'Internal Note' = 'Public Note') => {
    mutateCreateAuditionFlag(
      {
        auditionScheduleId: auditionSchedule.id,
        payload: { flag_type: flagType, comment: note, rating: rating },
      },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['audition_schedule_flag', auditionSchedule.id], {
            refetchInactive: true,
          });
          queryClient.invalidateQueries(['audition'], {
            refetchInactive: true,
          });
        },
      },
    );
  };

  const publicNotes = useMemo(() => {
    return auditionFlagData ? getPublicNotesData(auditionFlagData.data) : [];
  }, [auditionFlagData]);

  const internalNotes = useMemo(() => {
    return auditionFlagData ? getInternalNotesData(auditionFlagData.data) : [];
  }, [auditionFlagData]);

  const publicNotesComments = useMemo(() => {
    return publicNotes.map((publicNote) => {
      return {
        comment: publicNote.attributes.comment,
        rating: publicNote.attributes.rating,
        user: publicNote.attributes.user.data.attributes,
        created_at: publicNote.attributes.created_at,
      };
    });
  }, [publicNotes]) as IComment[];

  const internalNotesComments = useMemo(() => {
    return internalNotes.map((internalNote) => {
      return {
        comment: internalNote.attributes.comment,
        rating: internalNote.attributes.rating,
        user: internalNote.attributes.user.data.attributes,
        created_at: internalNote.attributes.created_at,
      };
    });
  }, [internalNotes]) as IComment[];

  return (
    <div className={classes.expandedDiv}>
      <div className={classes.closeButtonContainer}>
        <IconButton size="small" onClick={() => handleCloseExpandingContent()}>
          <CloseIcon fontSize="small" style={{ fontSize: 14 }} />
        </IconButton>
      </div>
      {/* Content */}
      <Grid container spacing={4}>
        {/* Left Player */}
        <Grid item lg={7}>
          <div className={classes.expandedDiv__groupButtonsContainer}>
            {auditionScheduleGroups &&
              auditionScheduleGroups
                .sort((a, b) => Number(a.group_number) - Number(b.group_number))
                .map((group) => (
                  <div>
                    <Button
                      variant={selectedGroupId === group.id ? 'contained' : 'outlined'}
                      color="primary"
                      style={{ color: '#fff', minWidth: '0' }}
                      onClick={() => handleChangeGroupTab(group.id)}
                    >
                      {group.group_name}
                    </Button>
                  </div>
                ))}
          </div>
          <div className={classes.expandedDiv__leftContainer}>
            <div className={classes.expandedDiv__mediaContainer}>
              {selectedAttachments && (
                <>
                  {selectedAttachments.type.toLocaleLowerCase().startsWith('image') ? (
                    <>
                      {selectedAttachments.attachments.length > 0 && (
                        <ImageCarousel images={selectedAttachments.attachments} />
                      )}
                    </>
                  ) : (
                    <div className={classes.expandedDiv__videoPlayerContainer}>
                      {selectedAttachments.attachments && selectedAttachments.attachments.length > 0 && (
                        <CustomVideoPlayer
                          url={selectedAttachments.attachments[0].attachment || ''}
                          thumbnail={
                            !isErrorThumbnail
                              ? thumbnail
                              : getPrimaryImage(
                                  audition,
                                  getMediaImagesPerGroup(auditionSchedule.medium_attachments, selectedGroupId),
                                )
                          }
                          playIcon={
                            <PlayIcon style={{ fontSize: 48, position: 'absolute', right: '2%', bottom: '3%' }} />
                          }
                          key={Math.random().toString()}
                        />
                      )}
                    </div>
                  )}
                </>
              )}
            </div>
            <div className={classes.expandedDiv__sceneActionContainer}>
              {scenes.map((scene, index) => (
                <Button
                  variant={selectedAttachments?.type !== scene.tag_list[0] ? 'outlined' : 'contained'}
                  color="primary"
                  startIcon={<PlayIconTwoTone classes={{ root: classes.buttonIcon }} />}
                  onClick={() => handleMediumTabActionChange(scene.tag_list[0], [scene])}
                >
                  {`Video ${index + 1}`}
                </Button>
              ))}

              {slates
                .sort((a, b) => a.tag_list[0].localeCompare(b.tag_list[0]))
                .map((slate) => (
                  <Button
                    variant={selectedAttachments?.type !== slate.tag_list[0] ? 'outlined' : 'contained'}
                    color="primary"
                    startIcon={<PlayIconTwoTone classes={{ root: classes.buttonIcon }} />}
                    onClick={() => handleMediumTabActionChange(slate.tag_list[0], [slate])}
                  >
                    {slate.tag_list[0]}
                  </Button>
                ))}
              <Button
                variant={selectedAttachments?.type !== MediumTabAction.IMAGES ? 'outlined' : 'contained'}
                color="primary"
                style={{ color: '#fff' }}
                startIcon={<UploadIconTwoTone classes={{ root: classes.buttonIcon }} />}
                onClick={() => handleMediumTabActionChange(MediumTabAction.IMAGES, images)}
              >
                Images
              </Button>
            </div>

            {/* Scene Action */}
            {/* Actions */}
          </div>
        </Grid>
        {/* Comments Section */}
        <Grid item lg={5}>
          <AuditionSingleComment
            isEditMode={isEditMode}
            publicNotesComments={publicNotesComments}
            internalNotesComments={internalNotesComments}
            onCreateNote={onCreateNote}
            sessionId={sessionId || ''}
            audition={audition}
            selectedGroupId={selectedGroupId}
            auditionSchedule={auditionSchedule}
            mediums={mediums || []}
            handleOpenTalentProfile={handleOpenTalentProfile}
            isDisabledComment={
              clientPermissionsState ? !clientPermissionsState?.projectPermissions?.add_comments_ratings : false
            }
            isDisabledCallback={
              clientPermissionsState ? !clientPermissionsState?.projectPermissions?.select_callback : false
            }
          />
        </Grid>
      </Grid>
    </div>
  );
};

export default SingleExpandingContentWithTab;
