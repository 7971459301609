import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function PendingLightBulbIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill="#DDB252" fill-rule="evenodd">
        <circle opacity=".2" cx="9" cy="9" r="9" />
        <circle cx="9" cy="9" r="6.3" />
      </g>
    </SvgIcon>
  );
}

export default PendingLightBulbIcon;
