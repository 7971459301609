import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      display: 'flex',
      gap: '1em',
      width: '100%',

      [theme.breakpoints.down(1660)]: {
        flexWrap: 'wrap',

        '& > div': {
          width: '100%!important',
        },
      },

      '& .MuiFormLabel-root': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        letterSpacing: '1px',
        alignItems: 'center',

        '& .MuiBox-root': {
          padding: '2px 4px 1px 5px',
          border: '1px solid',
          borderRadius: '100%',
          fontSize: '12px',
        },
      },

      '& .MuiSelect-iconOutlined': {
        right: '0',
        top: '22px',
      },

      '& .MuiSelect-iconOpen': {
        top: '2px',
        right: '13px',
      },
    },
    phone__input: {
      '& .MuiBox-root': {
        marginTop: 10,
      },
    },
    marginTopSm: {
      marginTop: '10px',
    },
    customChipContainer: {
      '& > *:not(:last-child)': {
        marginBottom: '10px',
      },
    },
    customChip: {
      width: '100%',
      borderRadius: '5px',
      background: '#2e3540',
      border: 'none',
      justifyContent: 'space-between',

      '& .MuiChip-deleteIcon': {
        height: '15px',
        width: '20px',
      },
    },
    contactBypass: {
      '& .MuiFormControl-root': {
        marginTop: '-2px !important',
      },
      '& .MuiInputBase-formControl': {
        marginTop: '2px !important',
      },
      '& label': {
        fontSize: '17px !important',
        letterSpacing: '1px !important',
        marginBottom: '0px !important',
      },
    },
    inputLabelCls: {
      fontSize: '13px',
      letterSpacing: '1px',
    },
  }),
);
