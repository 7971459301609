import { FormikProps } from 'formik';
import React from 'react';
import { ShowToType } from 'shared/enums/ShowToType';
import { IProject, IProjectCreatePayload } from 'shared/interfaces/IProject';
import { IProjectSide } from 'shared/interfaces/IProjectSide';
import { IShowToAttributes } from 'shared/interfaces/utils/IShowToAttributes';
import { AdditionalDetails } from './AdditionalDetails';
import { CastingContactInformation } from './CastingContactInformation';
import { ConflictsAndUsage } from './ConflictsAndUsage';
import { CreativeTeam } from './CreativeTeam';
import { GeneralProjectInformation } from './GeneralProjectInformation';
import { Container } from './ProjectForm.styles';

type Props = {
  form: FormikProps<IProjectCreatePayload>;

  project: IProject;
  director: any;
  associate: any;
  assistant: any;
  assignedStaff: any;
  handleSetStaff: (instance: any, str: string) => void;
  handleIsValidEmailAddress: (instance: boolean) => void;
  producer: any;
  assignedContacts: any;
  selProducerShowToList: IShowToAttributes[];
  selDirectorShowToList: IShowToAttributes[];
  handleSetContact: (instance: any, str: string) => void;
  handleSetSelectedProducerShowToList: (instance: any) => void;
  handleSetSelectedDirectorShowToList: (instance: any) => void;
  handleRemoveContactProducer: (instance: string) => void;
  handleRemoveContactDirector: (instance: string) => void;
  checkUncheckProducerVisibleToAgents: (instance: any, instance2: string, instance3: ShowToType) => void;
  checkUncheckDirectorVisibleToAgents: (instance: any, instance2: string, instance3: ShowToType) => void;
  files: any;
  sides: Array<IProjectSide>;
  handleSetSideFiles: (instance: any) => void;
  handleSetProjectSides: (instance: Array<IProjectSide>) => void;
  handleCollapseProjectAccordion: (accordionItem: string) => void;
  projectAccordionShow: string[];
};
const ProjectForm: React.FC<Props> = ({
  form,
  project,
  producer,
  director,
  associate,
  assistant,
  assignedStaff,
  handleSetStaff,
  handleIsValidEmailAddress,
  assignedContacts,
  handleSetContact,
  selProducerShowToList,
  selDirectorShowToList,
  handleSetSelectedProducerShowToList,
  handleSetSelectedDirectorShowToList,
  handleRemoveContactProducer,
  handleRemoveContactDirector,
  checkUncheckProducerVisibleToAgents,
  checkUncheckDirectorVisibleToAgents,
  files,
  sides,
  handleSetSideFiles,
  handleSetProjectSides,
  projectAccordionShow,
  handleCollapseProjectAccordion,
}) => {
  return (
    <Container>
      <GeneralProjectInformation
        form={form}
        toggleCollapse={handleCollapseProjectAccordion}
        isCollapse={projectAccordionShow.includes('GPI')}
      />
      <ConflictsAndUsage
        form={form}
        isCollapse={projectAccordionShow.includes('CAU')}
        toggleCollapse={handleCollapseProjectAccordion}
      />

      <CastingContactInformation
        form={form}
        project={project}
        director={director}
        associate={associate}
        assistant={assistant}
        assignedStaff={assignedStaff}
        handleSetStaff={handleSetStaff}
        handleIsValidEmailAddress={handleIsValidEmailAddress}
        isCollapse={projectAccordionShow.includes('CCI')}
        toggleCollapse={handleCollapseProjectAccordion}
      />

      <CreativeTeam
        form={form}
        project={project}
        director={director}
        producer={producer}
        assignedContacts={assignedContacts}
        selProducerShowToList={selProducerShowToList}
        selDirectorShowToList={selDirectorShowToList}
        handleSetContact={handleSetContact}
        handleSetSelectedProducerShowToList={handleSetSelectedProducerShowToList}
        handleSetSelectedDirectorShowToList={handleSetSelectedDirectorShowToList}
        handleRemoveContactProducer={handleRemoveContactProducer}
        handleRemoveContactDirector={handleRemoveContactDirector}
        checkUncheckProducerVisibleToAgents={checkUncheckProducerVisibleToAgents}
        checkUncheckDirectorVisibleToAgents={checkUncheckDirectorVisibleToAgents}
        isCollapse={projectAccordionShow.includes('CT')}
        toggleCollapse={handleCollapseProjectAccordion}
      />

      <AdditionalDetails
        form={form}
        files={files}
        sides={sides}
        handleSetSideFiles={handleSetSideFiles}
        handleSetProjectSides={handleSetProjectSides}
        isCollapse={projectAccordionShow.includes('CT')}
        toggleCollapse={handleCollapseProjectAccordion}
      />
    </Container>
  );
};

export default ProjectForm;
