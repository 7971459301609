import React, { Fragment, useCallback, useState, useEffect, useRef } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';

import { useStyles } from './FileUpload.styles';
import { DocumentUploadIconTwoTone, SuccessIcon, TrashErrorIconTwoTone, UploadIconTwoTone } from 'components';

type Props = {
  onFileSelected: (
    name: string,
    type: string,
    image: string,
    file?: File,
    imageAttr?: { width: number; height: number },
  ) => void;
  onFileRemoved?: () => void;
  heading?: string;
  sizeText?: string;
  bodyText?: string;
  acceptedFiles?: string;
  icon?: string;
  headingTop?: string;
  height?: number;
  autoUpload?: boolean;
};
const FileUpload: React.FC<Props> = ({
  onFileSelected,
  onFileRemoved = () => {},
  heading,
  sizeText,
  bodyText,
  acceptedFiles,
  icon,
  headingTop,
  height,
  autoUpload = false,
}) => {
  const classes = useStyles();
  const [hasFile, setHasFile] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const inputFile = useRef<HTMLInputElement>(null);
  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        if (file.type.startsWith('image')) {
          const image = new Image();
          reader.onload = () => {
            image.onload = function () {
              onFileSelected(file.name, file.type, reader.result as string, file, {
                width: image.width,
                height: image.height,
              });
            };
            setHasFile(true);
            setFileName(file.name);
            image.src = reader.result as string;
          };
        } else {
          reader.onload = () => {
            onFileSelected(file.name, file.type, reader.result as string, file);
            setHasFile(true);
            setFileName(file.name);
          };
        }

        reader.readAsDataURL(file);
      });
    },
    [onFileSelected],
  );

  const handleRemoveFile = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    setHasFile(false);
    setFileName('');
    onFileRemoved();
  };

  useEffect(() => {
    if (autoUpload) {
      if (inputFile.current) {
        inputFile.current.click();
      }
    }
    return () => {};
  }, [autoUpload, inputFile]);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });
  return (
    <Box className={classes.container} style={{ height: height ? height : 'auto' }}>
      <input {...getInputProps()} ref={inputFile} id="contained-input-file" accept={acceptedFiles} />
      <Box {...getRootProps()} style={{ width: '100%' }}>
        <Box className={classes.uploaderContainer}>
          {!hasFile && (
            <Fragment>
              <Box className={`${classes.uploaderHeader} ${headingTop ? classes.headingTop : ''}`}>
                {icon === 'image' ? (
                  <UploadIconTwoTone />
                ) : (
                  <DocumentUploadIconTwoTone width="16" height="21" viewBox="0 0 16 21" />
                )}
                {headingTop ? (
                  <Typography variant="h6" style={{ marginLeft: 7 }}>
                    {headingTop}
                  </Typography>
                ) : (
                  ''
                )}
              </Box>
              {heading ? <Typography variant="h6">{heading}</Typography> : ''}
              {sizeText ? <Typography variant="body1">{sizeText}</Typography> : ''}
              {bodyText ? <Typography variant="body1">{bodyText}</Typography> : ''}
            </Fragment>
          )}
          {hasFile && (
            <Box className={classes.uploadProcessed}>
              <SuccessIcon />
              <div className={classes.fileContainer}>
                <Typography variant="body1">File Processed Successfully</Typography>
                <Typography variant="body2">{fileName}</Typography>
              </div>
              <TrashErrorIconTwoTone onClick={(e) => handleRemoveFile(e)} />
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default FileUpload;
