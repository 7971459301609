import React, { useMemo } from 'react';
import { format } from 'date-fns';
import {
  DialogContent,
  Box,
  Typography,
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  TableBody,
} from '@material-ui/core';
import {
  CloseIcon,
  CommentIcon,
  OnAvailIcon,
  ResetToLeftIcon,
  CheckedCircleIcon,
  ClockCircleIcon,
} from '@room-match/shared-ui-components';
import { auditionService } from 'shared/services/auditionService';
import { StyledDialog } from 'shared/elements/StyledDialog';
import { useStyles } from './JobLogModal.styles';
import { IAuditionActivities, IAuditionAttributes } from 'shared/interfaces/IAudition';

type Props = {
  open: boolean;
  onClose: () => void;
  audition?: IAuditionAttributes | null;
};
const { getAuditionAtivities } = auditionService();
const JobLogModal: React.FC<Props> = ({ open, onClose, audition }) => {
  const classes = useStyles();
  const { data: activitiesData } = getAuditionAtivities(audition ? audition.id : '');

  const activities = useMemo(() => {
    return activitiesData ? activitiesData.data : [];
  }, [activitiesData]);

  const renderMessages = (row: IAuditionActivities) => {
    switch (row.attributes.parameters.event) {
      case 'Role Change':
        return (
          <Box>
            <Typography variant="body2" className={classes.flexRow}>
              From&nbsp;
              <Typography variant="body2" className={classes.primaryText}>
                {/* {row.from}&nbsp; */}
              </Typography>
              To&nbsp;
              <Typography variant="body2" className={classes.primaryText}>
                {/* {row.to} */}
              </Typography>
            </Typography>
          </Box>
        );
      case 'Reschedule Request':
        return (
          <Box className={classes.flexRow}>
            <CommentIcon className={classes.svgCls} />
            <Typography variant="body2" className={classes.orangeText}>
              {row.attributes.parameters.message}
            </Typography>
          </Box>
        );
      default:
        return (
          <Box>
            <Typography variant="body2">{row.attributes.parameters.message}</Typography>
          </Box>
        );
    }
  };

  const renderEvent = (event: string) => {
    switch (event) {
      case 'On Avail':
        return (
          <Box className={classes.eventCls}>
            <OnAvailIcon className={`${classes.svgCls} ${classes.onAvailCls}`} />
            <Typography variant="body2">{event}</Typography>
          </Box>
        );
      case 'Callback':
        return (
          <Box className={classes.eventCls}>
            <ResetToLeftIcon className={classes.svgCls} />
            <Typography variant="body2">{event}</Typography>
          </Box>
        );
      case 'Auditioned':
        return (
          <Box className={classes.eventCls}>
            <CheckedCircleIcon className={`${classes.svgCls} ${classes.greenSvg}`} />
            <Typography variant="body2">{event}</Typography>
          </Box>
        );
      case 'Role Change':
        return (
          <Box className={classes.eventCls}>
            <CheckedCircleIcon className={`${classes.svgCls} ${classes.greenSvg}`} />
            <Typography variant="body2">{event}</Typography>
          </Box>
        );
      case 'Reschedule Request':
        return (
          <Box className={classes.eventCls}>
            <ClockCircleIcon className={`${classes.svgCls} ${classes.greenSvg}`} />
            <Typography variant="body2">{event}</Typography>
          </Box>
        );
      default:
        return (
          <Box className={classes.eventCls}>
            <Typography variant="body2">{event}</Typography>
          </Box>
        );
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      maxWidth="lg"
      fullWidth
      className={classes.dialog}
      aria-labelledby="simple-dialog-title"
    >
      <DialogContent>
        <Box className={classes.closeIcon}>
          <Box onClick={onClose}>
            <CloseIcon style={{ width: '14px' }} />
          </Box>
        </Box>
        <Box className={classes.logBody}>
          <Box className={classes.headerContainer}>
            <Box className={classes.headerDp} />
            <Box>
              <Typography variant="h5">Job Log</Typography>
              <Typography variant="body2" style={{ display: 'flex', marginTop: 3 }}>
                {`${audition?.profile.talent?.first_name} ${audition?.profile.talent?.last_name} (${
                  audition?.profile.agency?.name || ''
                }) -`}
                &nbsp;
                <Typography variant="body2" className={classes.primaryText}>
                  {`${audition?.casting_role.name}`}
                </Typography>
              </Typography>
            </Box>
          </Box>
          <TableContainer component={Box}>
            <Table style={{ minWidth: 900, marginTop: '10px' }}>
              <TableHead>
                <TableRow>
                  <TableCell>
                    <Typography variant="body2">Date</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">Actioned By</Typography>
                  </TableCell>
                  <TableCell>
                    <Typography variant="body2">Detail / Messages</Typography>
                  </TableCell>
                  <TableCell align="right">
                    <Typography variant="body2">Event</Typography>
                  </TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {activities.map((row, index: number) => (
                  <TableRow key={index}>
                    <TableCell className={classes.darkText}>
                      <Typography variant="body2">{format(new Date(row.attributes.created_at), 'PP - pp')}</Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2" style={{ display: 'flex' }} className={classes.primaryText}>
                        {`${row.attributes.owner.data.attributes.first_name} ${row.attributes.owner.data.attributes.last_name}`}
                        &nbsp;
                        <Typography variant="body2" className={classes.darkText}>
                          {`${
                            row.attributes.owner.data.attributes.position_name ||
                            row.attributes.owner.data.attributes.agency_name ||
                            ''
                          }`}
                        </Typography>
                      </Typography>
                    </TableCell>
                    <TableCell>
                      <Typography variant="body2">{renderMessages(row)}</Typography>
                    </TableCell>
                    <TableCell align="right">
                      <Typography variant="body2">{renderEvent(row.attributes.parameters.event || '')}</Typography>
                    </TableCell>
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        </Box>
      </DialogContent>
    </StyledDialog>
  );
};

export default JobLogModal;
