import { styled, Box, Button, Typography } from '@material-ui/core';

export const MainCont = styled('div')(({ theme }) => ({
  minHeight: '90vh',
  minWidth: '70vw',
  position: 'relative',
  padding: 30,
  display: 'flex',
  flexDirection: 'column',
}));

export const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: 10,
  alignItems: 'center',
}));

export const Title = styled(Box)(({ theme }) => ({
  fontSize: 18,
}));

export const SwitchButton = styled(Button)(({ theme }) => ({
  marginLeft: 'auto',
  fontSize: 14,
  height: 30,
  borderRadius: 5,
  border: '1px solid #3e8bff',
  marginRight: 30,
}));

export const Body = styled(Box)(({ theme }) => ({
  flex: 1,
  overflow: 'hidden',
}));

export const Footer = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: 10,
}));

export const LeftFooter = styled(Box)(({ theme }) => ({
  flex: 1,
}));

export const RightFooter = styled(Box)(({ theme }) => ({
  flex: 1,
  margin: '0 50px',
}));

export const SaveButton = styled(Button)(({ theme }) => ({
  backgroundColor: '#3e8bff',
  height: 44,
  width: '100%',
  marginTop: 10,
}));

export const Scale = styled(Box)(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  fontSize: 14,
}));

export const CursorHint = styled(Typography)(({ theme }) => ({
  fontSize: 15,
}));

export const Quality = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginTop: 10,
  // flexDirection: 'column',
  justifyContent: 'space-around',
}));

export const ZoomCropBox = styled(Box)(({ theme }) => ({
  // alignItems: 'center',
  // justifyContent: 'center',
  // border: '1px #daa13e dashed',
  // borderRadius: 8,
  flexDirection: 'column',

  padding: 10,
  // margin: 10,
  display: 'flex',
  flex: 1,
}));

export const TagLabel = styled(Box)(({ theme }) => ({
  display: 'flex',
  marginBottom: 5,
}));

export const TagCont = styled(Box)(({ theme }) => ({
  display: 'flex',
  //   justifyContent: 'space-between',
}));

//MULTIPLE IMAGES STYLES

export const MultipleEditorCont = styled('div')(({ theme }) => ({
  height: '90vh',
  width: '70vw',
  position: 'relative',
  padding: 20,
  display: 'flex',
  flexDirection: 'column',
}));

export const ImagesList = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  // backgroundColor: 'pink',
  width: '15%',
  border: 'solid #2d343e 1px',
  borderRadius: 8,
  overflow: 'auto',
  flexWrap: 'wrap',
  alignContent: 'flex-start',
}));

export const EditorMain = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  // backgroundColor: 'yellow',
  flex: 1,
  height: 1,
}));

export const EditorPreview = styled(Box)(({ theme }) => ({
  flex: 1,
  overflow: 'hidden',
  // backgroundColor: 'orange',
}));

export const ImageQualityCont = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'row',
  backgroundColor: 'teal',
  flex: 1,
}));

export const Controls = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  // backgroundColor: 'purple',
  flex: 1,
  marginRight: 10,
}));

export const TagMainCont = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  marginLeft: 10,
  flex: 1,
  overflow: 'hidden',
  justifyContent: 'center',
}));

export const Buttons = styled(Button)(({ theme }) => ({
  flex: 1,
  margin: 10,
  height: 40,
  border: '1px solid #3e8bff',
}));
