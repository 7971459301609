import { DialogContent, IconButton } from '@material-ui/core';
import { ITab } from '@room-match/shared-ui-components';
import { CloseIcon } from 'components/Icons';
import React, { useEffect, useMemo, useState } from 'react';
import { StyledDialog, StyledTab, StyledTabs, Typography } from 'shared/elements';
import ImageGallery from './ImageGallery/ImageGallery';
import { useStyles } from './ResumeMediaGallery.styles';
import VideoGallery from './VideoGallery/VideoGallery';
import { IMediumAttributes } from 'shared/interfaces/IMedium';
import AudioContent from '../TalentProfile/TalentProfileDetails/TabContent/AudioContent';
import { a11yProps } from 'shared/utils/a11yProps';

type Props = {
  media: IMediumAttributes[];
  open: boolean;
  onClose: () => void;
};
const ResumeMediaGallery: React.FC<Props> = ({ open, onClose, media }) => {
  const classes = useStyles();
  const [tab, setTab] = useState<string>('images');

  const filtered_images = useMemo(() => {
    return media.filter((item) => item.file_type === 'image');
  }, [media]);

  const filtered_audios = useMemo(() => {
    return media.filter((item) => item.file_type === 'audio');
  }, [media]);

  const filtered_videos = useMemo(() => {
    return media.filter((item) => item.file_type === 'video');
  }, [media]);

  useEffect(() => {
    return () => {
      if (!open) {
        setTab('images');
      }
    };
  }, [open]);

  const tabs: ITab[] = useMemo(() => {
    return [
      {
        name: 'images',
        header: `Images (${filtered_images.length})`,
        component: <ImageGallery />,
      },
      {
        name: 'videos',
        header: `Videos (${filtered_videos.length})`,
        component: <VideoGallery />,
      },
      {
        name: 'audios',
        header: `Audios (${filtered_audios.length})`,
        component: <h1>Image Gallery</h1>,
      },
    ];
  }, [filtered_images, filtered_audios, filtered_videos]);

  const getActiveTab = useMemo(() => {
    const currentTab = tabs.find((_tab) => _tab.name === tab);
    if (currentTab?.name === 'images') {
      currentTab.component = <ImageGallery images={filtered_images} />;
      return currentTab?.component;
    }
    if (currentTab?.name === 'audios') {
      currentTab.component = <AudioContent audios={filtered_audios} />;
      return currentTab?.component;
    }
    if (currentTab?.name === 'videos') {
      currentTab.component = <VideoGallery videos={filtered_videos} />;
      return currentTab?.component;
    }
    return tabs.find((_tab) => _tab.name === tab)?.component;
  }, [tab, filtered_images, filtered_audios, filtered_videos, tabs]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (tab !== newValue) {
      setTab(newValue);
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="lg" fullWidth style={{ maxHeight: '95%', top: 20 }}>
      <DialogContent>
        {/* Resume Description */}
        <div className={classes.resumeDescription}>
          <div>
            {/* <Typography>2010 - The Wannabes</Typography>
            <Typography fontSize={13} color="mainGrey">
              Andrew, Ian Brown
            </Typography> */}
          </div>
          <IconButton onClick={onClose}>
            <CloseIcon style={{ fontSize: 13 }} />
          </IconButton>
        </div>
        <div className={classes.tabContainer}>
          <StyledTabs value={!tab ? 'resume' : tab} onChange={handleTabChange} aria-label="simple tabs example">
            {tabs.map((tab, index) => (
              <StyledTab
                key={index}
                label={tab.header}
                value={tab.name}
                disabled={tab.disabled}
                {...a11yProps(index)}
              />
            ))}
          </StyledTabs>
          <div className={classes.activeTabContainer}>{getActiveTab}</div>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

export default ResumeMediaGallery;
