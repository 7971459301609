import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function LinkedInIconTwoTone(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M24.667 28A3.333 3.333 0 0 0 28 24.667V7.333A3.333 3.333 0 0 0 24.667 4H7.333A3.333 3.333 0 0 0 4 7.333v17.334A3.333 3.333 0 0 0 7.333 28h17.334zM11.333 12.667H8V24h3.333V12.667zm-1.695-1.334h.019c1.033 0 1.676-.742 1.676-1.667C11.315 8.72 10.69 8 9.676 8 8.663 8 8 8.72 8 9.666c0 .926.643 1.667 1.638 1.667zM20.667 24H24v-6.484c0-3.35-1.79-4.85-4.175-4.85-1.925 0-2.678 1-3.158 1.745v-1.744h-3.334V24h3.334v-6l-.002-.191c-.005-.343-.01-.82.069-1.014.263-.652.804-1.326 1.805-1.326 1.311 0 2.128 1 2.128 2.465V24z"
        fill="#0288D1"
      />
    </SvgIcon>
  );
}

export default LinkedInIconTwoTone;
