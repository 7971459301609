import { Dialog, Theme } from '@material-ui/core';
import { withStyles, makeStyles, createStyles } from '@material-ui/styles';

export const DefaultDialog = withStyles((theme: Theme) => ({
  paper: {
    backgroundColor: theme.palette.customBlack.main,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderImageSource: 'linear-gradient(253deg, #d51bff, #00d4ff)',
    borderImageSlice: '1',
    backgroundImage: 'linear-gradient(to bottom, #0e1319, #0e1319), linear-gradient(253deg, #d51bff, #00d4ff)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    borderRadius: '8px !important',
  },
}))(Dialog);

export const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      '&.paper': {
        minWidth: '85%',
        minHeight: '90%',
      },
      '& .MuiDialog-paperWidthSm': {
        maxWidth: '585px !important',
      },
    },
    errorDialog: {
      '& .MuiDialog-paper': {
        border: 'solid 1px #e92b2b',
      },
    },
    warningDialog: {
      '& .MuiDialog-paper': {
        border: 'solid 1px #e39604',
      },
    },
    closeIcon: {
      position: 'absolute',
      top: '20px',
      right: '20px',
      cursor: 'pointer',
    },
    notifBody: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '40px 30px',
      '& svg': {
        width: '70px',
        height: '70px',
      },
      '& > *:not(:last-child)': {
        marginBottom: '40px',
      },
    },
    baseBtn: {
      height: '50px',
    },
    primaryBtn: {
      background: '#3e8bff',
    },
    warningBtn: {
      background: '#e39604',
    },
    errorBtn: {
      background: '#e92b2b',
    },
    defaultBtn: {
      height: '50px',
      background: '#21262d',
    },
  }),
);
