import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      backgroundColor: theme.palette.type === 'dark' ? '#151a21' : '#edf1f6',
      minHeight: '100vh',
      overflow: 'hidden',
      '& > iframe': {
        width: '100%',
        height: '100%',
        border: 'none',
      },
    },
  }),
);
