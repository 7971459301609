import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    tab: {
      marginTop: theme.spacing(3),
    },
    tab__contentWrapper: {
      backgroundColor: '#0f151c',
      border: `1px solid #2d343e`,
      borderRadius: 8,
    },
    tab__contentWrapper__showExpandedDiv: {
      borderColor: theme.palette.primary.main,
    },
    tab__card: {
      [theme.breakpoints.down('lg')]: {
        width: '100%',
        overflow: 'auto',
        '&::-webkit-scrollbar': {
          height: 8,
        },
      },
    },
    tab__cardContent: {
      overflow: 'hidden',
      [theme.breakpoints.down('lg')]: {
        // width: 1980,
      },
    },
    tab__contentContainer: {
      borderBottom: `1px solid #2d343e`,
    },
    tab__left: {
      display: 'flex',
    },
    tab__detail: {
      padding: '14px 16px',
      backgroundColor: '#0b0f14',
      flex: 1,
      display: 'flex',
      gap: 8,
      [theme.breakpoints.down('lg')]: {
        padding: 12,
      },
    },
    tab__information: {
      display: 'flex',
      justifyContent: 'space-between',
      // alignItems: 'center',
      gap: 24,
      flex: 1,
      [theme.breakpoints.down('lg')]: {
        gap: 16,
      },
    },

    tab__right: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      gap: 16,
      padding: '16px 24px',
      [theme.breakpoints.down('lg')]: {
        padding: 12,
        paddingRight: 12,
        gap: 10,
      },
    },
    tab__history: {
      display: 'flex',
      flexDirection: 'column',
      flex: 1,
    },
    tab__history__unFW: {
      maxWidth: 400,
      [theme.breakpoints.down('lg')]: {
        maxWidth: 300,
      },
    },
    tab__expandedDivContainer: {
      padding: theme.spacing(2, 3),
      backgroundColor: '#0b0f14',
      overflow: 'hidden',
      borderRadius: 8,
    },
  }),
);
