import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function DocumentFile(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 23 30" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M13.98 0a3.21 3.21 0 0 1 2.214.882l5.65 5.345a3.236 3.236 0 0 1 1.008 2.34v18.21A3.226 3.226 0 0 1 19.629 30H3.223A3.226 3.226 0 0 1 0 26.777V3.223A3.226 3.226 0 0 1 3.223 0zm-.27 1.758H3.224c-.808 0-1.465.657-1.465 1.465v23.554c0 .808.657 1.465 1.465 1.465h16.406c.808 0 1.465-.657 1.465-1.465V8.79h-5.332c-1.131 0-2.051-.92-2.051-2.05V1.757zM9.067 21.094a.879.879 0 0 1 0 1.758H4.805a.879.879 0 0 1 0-1.758zm8.63-4.688a.879.879 0 0 1 0 1.758H4.804a.879.879 0 0 1 0-1.758zm0-4.687a.879.879 0 0 1 0 1.758H4.804a.879.879 0 0 1 0-1.758zm-2.227-9.104v4.123c0 .162.131.293.293.293h4.374L15.47 2.615z"
          fill="#6E767F"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default DocumentFile;
