import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function DocumentIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 16 21" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.143.5H.714A.714.714 0 0 0 0 1.214V16.93A3.576 3.576 0 0 0 3.571 20.5H15c.394 0 .714-.32.714-.714V4.07A3.576 3.576 0 0 0 12.143.5zm2.143 18.571H3.57A2.143 2.143 0 0 1 1.43 16.93v-15h10.714c1.183 0 2.143.959 2.143 2.142v15zM12.143 6.214c0 .395-.32.715-.714.715H4.286a.714.714 0 0 1 0-1.429h7.143c.394 0 .714.32.714.714zm0 4.286c0 .394-.32.714-.714.714H4.286a.714.714 0 1 1 0-1.428h7.143c.394 0 .714.32.714.714zm0 4.286c0 .394-.32.714-.714.714H4.286a.714.714 0 1 1 0-1.429h7.143c.394 0 .714.32.714.715z"
          fill={props.htmlColor || '#677482'}
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default DocumentIcon;
