import { Button, Grid, IconButton, Popover } from '@material-ui/core';
import {
  DangerExclamationIconTwoTone,
  HeartIcon,
  HeartIconTwoTone,
  StarGradientIconTwoTone,
  StyledPopover,
  UnshadedStarIconTwoTone,
} from '@room-match/shared-ui-components';
import React from 'react';
import { Typography } from 'shared/elements';
import { useStyles } from './DetailFavorite.styles';

type Props = {
  isCastingFavorite?: boolean;
  onCreateCastingFavorite?: () => void;
  clientRate?: string | null;
  onCreateClientFavorite?: (type: string) => void;
};
const DetailFavorite: React.FC<Props> = ({
  isCastingFavorite,
  onCreateCastingFavorite,
  onCreateClientFavorite,
  clientRate,
}) => {
  const classes = useStyles();

  const [anchorClientSelection, setAnchorClientSelection] = React.useState<HTMLElement | null>(null);
  const openClientSelection = Boolean(anchorClientSelection);
  const clientSelectionId = openClientSelection ? 'simple-popover' : undefined;
  const handleClientSelectionOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorClientSelection(event.currentTarget);
  };
  const handleClientSelectionClose = () => {
    setAnchorClientSelection(null);
  };

  const handleCastingFavorite = () => {
    if (onCreateCastingFavorite) {
      onCreateCastingFavorite();
    }
  };

  const handleClientFavorite = (type: string) => {
    if (onCreateClientFavorite) {
      onCreateClientFavorite(type);
    }
    handleClientSelectionClose();
  };

  return (
    <div className={classes.request__favorite}>
      <div className={classes.request__favorite__item}>
        <IconButton size="small" onClick={() => handleCastingFavorite()}>
          {!isCastingFavorite ? <HeartIcon fontSize="small" /> : <HeartIconTwoTone fontSize="small" />}
        </IconButton>
        <Typography fontSize={14}>Casting Favourite</Typography>
      </div>
      <div className={classes.request__favorite__item}>
        <IconButton size="small" onClick={(e) => handleClientSelectionOpen(e)}>
          {!clientRate ? (
            <UnshadedStarIconTwoTone fontSize="small" />
          ) : (
            <>
              {clientRate === 'client_does_not_like' ? (
                <DangerExclamationIconTwoTone fontSize="small" />
              ) : (
                <StarGradientIconTwoTone fontSize="small" />
              )}
            </>
          )}
        </IconButton>
        <Popover
          id={clientSelectionId}
          open={openClientSelection}
          anchorEl={anchorClientSelection}
          onClose={handleClientSelectionClose}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          classes={{
            paper: classes.paperSmall,
          }}
        >
          <Grid container spacing={0} className={classes.clientSelectionPopover}>
            <Grid item>
              <Button
                startIcon={<StarGradientIconTwoTone fontSize="small" />}
                onClick={() => handleClientFavorite('Client Favourite')}
              >
                <Typography fontSize={14}>Client Favourite</Typography>
              </Button>
            </Grid>
            <Grid item>
              <Button
                startIcon={<DangerExclamationIconTwoTone fontSize="small" />}
                onClick={() => handleClientFavorite('Client Does Not Like')}
              >
                <Typography fontSize={14}>Client Doesn't Like</Typography>
              </Button>
            </Grid>
          </Grid>
        </Popover>
        <Typography fontSize={14}>
          {!clientRate
            ? 'Client Favourite'
            : clientRate === 'client_does_not_like'
            ? "Client Doesn't Like"
            : 'Client Favourite'}
        </Typography>
      </div>
    </div>
  );
};

export default DetailFavorite;
