import { Typography, Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';

import { useStyles } from './NotFound.styles';
import { CastingAppLogo } from '@room-match/shared-ui-components';

const NotFound = () => {
  const classes = useStyles();
  const history = useHistory();

  return (
    <div className={classes.bodyContainer}>
      <div className={classes.bgSvg}>
        <img src="/casting-logo-half.svg" alt="File Logo" style={{ transform: 'scaleX(-1)' }} />
        <Typography>#404</Typography>
        <img src="/casting-logo-half.svg" alt="File Logo" />
      </div>
      <div className={classes.errorContainer}>
        <CastingAppLogo style={{ height: '135px', width: '135px' }} />
        <Typography style={{ fontSize: 60, lineHeight: 1 }}>Error #404</Typography>
        <Typography variant="body2">This Page Does Not Exist</Typography>
        <div style={{ padding: '0px 20px', width: '100%' }}>
          <Button style={{ background: '#3e8bff', height: '60px' }} fullWidth onClick={() => history.goBack()}>
            Go Back
          </Button>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
