import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const DoubleUpArrowIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <clipPath id="jvee4mfbma">
          <path d="M1920 0v1349H0V0h1920z" />
        </clipPath>
        <clipPath id="evmom4bzqb">
          <path d="m7.412 6.588 6.417 6.416a.583.583 0 1 1-.825.825L7 7.825.996 13.829a.583.583 0 1 1-.825-.825l6.417-6.416a.583.583 0 0 1 .824 0zm0-6.417 6.417 6.417a.583.583 0 1 1-.825.824L7 1.408.996 7.412a.583.583 0 1 1-.825-.824L6.588.17a.583.583 0 0 1 .824 0z" />
        </clipPath>
        <linearGradient id="43ukb5xt6c" x1="7" y1="0" x2="7" y2="14" gradientUnits="userSpaceOnUse">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <g clip-path="url(#jvee4mfbma)" transform="translate(-218 -179)">
        <g clip-path="url(#evmom4bzqb)" transform="translate(218 179)">
          <path fill="url(#43ukb5xt6c)" d="M0 0h14v14H0V0z" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default DoubleUpArrowIconTwoTone;
