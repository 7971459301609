import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const FlagIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="16" viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m11.4 4.8 2.04-2.72A.8.8 0 0 0 12.8.8H3.2a.8.8 0 1 0-1.6 0v13.6H.8a.8.8 0 1 0 0 1.6H4a.8.8 0 1 0 0-1.6h-.8V8.8h9.6a.8.8 0 0 0 .64-1.28L11.4 4.8z"
        fill="#677482"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default FlagIcon;
