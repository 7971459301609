import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';

import {
  IAuditionFlagCreatePayload,
  IAuditionFlagMutateResponsePayload,
  IAuditionFlagResponsePayload,
} from 'shared/interfaces/IAuditionFlag';

const { GET, POST, PATCH } = useAxios();
export const auditionScheduleFlagDao = () => {
  const getAuditionScheduleFlags = async (auditionScheduleId: string) => {
    const response = await GET<IAuditionFlagResponsePayload>({
      url: `${ENDPOINTS.AUDITION_SCHEDULES}/${auditionScheduleId}/audition_flags`,
    });
    return response.data;
  };

  const createAuditionScheduleFlag = async (auditionScheduleId: string, payload: IAuditionFlagCreatePayload) => {
    const response = await POST<IAuditionFlagMutateResponsePayload>({
      url: `${ENDPOINTS.AUDITION_SCHEDULES}/${auditionScheduleId}/audition_flags`,
      data: payload,
    });
    return response.data;
  };

  const updateAuditionScheduleFlag = async (
    auditionScheduleId: string,
    payload: IAuditionFlagCreatePayload,
    flagId: string,
  ) => {
    const response = await PATCH<IAuditionFlagMutateResponsePayload>({
      url: `${ENDPOINTS.AUDITION_SCHEDULES}/${auditionScheduleId}/audition_flags/${flagId}`,
      data: payload,
    });
    return response.data;
  };

  const deleteAuditionScheduleFlag = async (auditionScheduleId: string, flagId: string) => {
    const response = await PATCH<IAuditionFlagMutateResponsePayload>({
      url: `${ENDPOINTS.AUDITION_SCHEDULES}/${auditionScheduleId}/audition_flags/${flagId}`,
    });
    return response.data;
  };

  return {
    getAuditionScheduleFlags,
    createAuditionScheduleFlag,
    updateAuditionScheduleFlag,
    deleteAuditionScheduleFlag,
  };
};
