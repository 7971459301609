import { styled, Theme } from '@material-ui/core';
import { Typography } from 'shared/elements';
import { COLORS } from 'utils/COLORS';

export const TypographyContainer = styled(Typography)<Theme, { $visible?: boolean }>(({ $visible = false }) => ({
  display: $visible ? 'block' : 'none',
}));

export const FlexContainer = styled('div')<Theme, { $gap?: number; $type?: string }>(
  ({ $gap = 0, $type = 'success' }) => ({
    color: $type === 'success' ? '#43ba01' : $type === 'fail' ? COLORS.DANGER : '#fff',
    display: 'flex',
    alignItems: 'center',
    gap: $gap,
  }),
);
