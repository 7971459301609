import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const EditIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <svg>
        <path
          d="M9.086 3.07a.77.77 0 0 1 0 1.54H1.539V18.46H15.39v-7.547a.77.77 0 0 1 1.54 0v7.547A1.54 1.54 0 0 1 15.39 20H1.54A1.54 1.54 0 0 1 0 18.461V4.61l.001-.02.005-.12a1.54 1.54 0 0 1 1.533-1.4h7.547zM17.313 0A2.671 2.671 0 0 1 20 2.686a2.671 2.671 0 0 1-.78 1.904l-7.516 7.516-.01.01-.032.027.03-.028-.057.05-.03.024a.768.768 0 0 1-.195.1l-.033.01-.033.01-.023.005-3.464.743-.079.013-.078.004h-.005l-.086-.006-.04-.004a.982.982 0 0 1-.158-.045.738.738 0 0 1-.13-.066l-.052-.037a.818.818 0 0 1-.17-.178l-.02-.032a.74.74 0 0 1-.072-.155l-.009-.028a.672.672 0 0 1-.014-.057l-.003-.012-.007-.042v-.002l-.006-.053v-.107l.003-.026a.624.624 0 0 1 .009-.063l.004-.017.743-3.464a.77.77 0 0 1 .13-.292l.015-.019a.727.727 0 0 1 .057-.066l.006-.006L15.402.788A2.671 2.671 0 0 1 17.313 0zm-2.72 3.774L9.142 9.225l-.445 2.077 2.077-.445 5.451-5.451-1.632-1.632zm2-1.989-.095.085-.817.815 1.633 1.633.803-.803a1.142 1.142 0 0 0 .344-.83c0-.312-.118-.602-.331-.815-.397-.397-1.097-.425-1.536-.085z"
          fill={props.htmlColor || '#646C75'}
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
};

export default EditIcon;
