import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    text: {
      color: theme.palette.primary.main,
      fontSize: 16,
    },
    link: {
      textDecoration: 'none',
    },
  }),
);
