import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    imageGalleryContainer: {},
    largeImageContainer: {
      width: '100%',
      height: '550px',
      position: 'relative',
      display: 'inline-block',
      overflow: 'hidden',
    },
    largeImage: {
      display: 'block',
      position: 'absolute',
      left: '50%',
      top: '50%',
      height: '100%',
      minWidth: '100%',
      minHeight: '100%',
      transform: 'translate(-50%,-50%)',
      objectFit: 'cover',
    },
    imageListContainer: {
      display: 'flex',
      width: '100%',
      gap: 8,
      overflow: 'auto',
      paddingBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
      '&::-webkit-scrollbar': {
        width: 6,
        height: 6,
      },
    },
    imageItemContainer: {
      cursor: 'pointer',
    },
    imageItem: {
      width: '100%',

      objectFit: 'cover',
    },
  }),
);
