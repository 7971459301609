import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Plus(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="ss055ly1ta">
            <stop stopColor="#3E8BFF" offset="0%" />
            <stop stopColor="#3E8BFF" offset="100%" />
          </linearGradient>
        </defs>
        <g transform="translate(-203 -1273)" fill="url(#ss055ly1ta)" fillRule="evenodd">
          <path
            d="M212 1273.467a8.533 8.533 0 1 0 0 17.066 8.533 8.533 0 0 0 0-17.066zm0 1.066a7.467 7.467 0 1 1 0 14.934 7.467 7.467 0 0 1 0-14.934z"
            fillRule="nonzero"
          />
          <path d="M212 1278.267c.206 0 .373.167.373.373v2.987h2.987a.373.373 0 0 1 0 .746h-2.987v2.987a.373.373 0 0 1-.746 0l-.001-2.987h-2.986a.373.373 0 1 1 0-.746h2.986v-2.987c0-.206.168-.373.374-.373z" />
        </g>
      </svg>
    </SvgIcon>
  );
}

export default Plus;
