import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Time(props: SvgIconProps) {
  return (
    <SvgIcon width="16" height="20" viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.615 0a.77.77 0 0 1 0 1.538h-.769v3.079C13.842 7.522 11.238 9.093 9.441 10c1.797.906 4.401 2.478 4.406 5.384l-.001 3.077h.77a.77.77 0 0 1 0 1.539h-1.539l-.014-.001H2.321L2.308 20l-.014-.001L.769 20a.77.77 0 0 1 0-1.538l.769-.001v-3.078c.005-2.744 2.216-4.29 4.357-5.383C3.754 8.906 1.543 7.36 1.538 4.617V1.538H.769A.77.77 0 0 1 .77 0h13.846zM7.668 10.85l-.214.1-.43.206c-1.936.94-3.804 2.077-3.94 4.01l-.007.218-.001 3.077h9.231v-3.078l-.007-.212c-.142-1.976-2.195-3.14-4.123-4.08-.164-.08-.334-.16-.509-.242zm4.639-9.312H3.076v3.076c.004 2.289 2.377 3.5 4.592 4.537.175-.082.345-.162.509-.242 1.997-.973 4.128-2.187 4.13-4.295V1.538z"
        fill="#D7A300"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default Time;
