import { Box, Button, Grid, Typography, InputAdornment } from '@material-ui/core';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { FrontLayout } from 'components';
import { Link, useHistory } from 'react-router-dom';
import { Input, Backdrop } from '@room-match/shared-ui-components';
import { IForgotPasswordRequestPayload } from 'shared/interfaces/IUser';
import { selectUserState, userSendEmail } from 'shared/redux/slicers/user.slicer';
import * as yup from 'yup';
import { FormikProps, useFormik } from 'formik';
import { getErrorMessage } from 'shared/utils/getErrorMessage';
import { ROUTES } from 'shared/constants/ROUTES';
import { BoxContainer, CustomButton, ExtraContainer, LoginFooter } from './ForgotPassword.styles';
import { EmailIcon } from 'components/Icons';

const ForgotPassword = () => {
  const dispatch = useDispatch();
  const history = useHistory();

  const { isLoading, isLoggedIn } = useSelector(selectUserState);

  useEffect(() => {
    if (isLoggedIn) {
      history.push(ROUTES.CASTING.PROJECTS.PROJECT_LIST);
    }
  }, [isLoggedIn, history]);

  const initialValues: IForgotPasswordRequestPayload = {
    email: '',
  };

  const forgotPasswordValidationSchema: yup.SchemaOf<IForgotPasswordRequestPayload> = yup.object({
    email: yup.string().email('Wrong email format').required('Email is required'),
  });

  const handleforgotPasswordSubmit = async (values: IForgotPasswordRequestPayload) => {
    dispatch(userSendEmail(values, history));
  };

  const form: FormikProps<IForgotPasswordRequestPayload> = useFormik({
    initialValues,
    validationSchema: forgotPasswordValidationSchema,
    onSubmit: (values) => handleforgotPasswordSubmit(values),
  });

  return (
    <FrontLayout containerWidth={400}>
      <BoxContainer>
        <div>
          <br />
          <Typography variant="subtitle2" style={{ textAlign: 'center' }}>
            Please enter the email address used for your account below and you will be sent a reset password link.
          </Typography>
          <br />
          <Input
            name="email"
            variant="outlined"
            placeholder="Enter Email..."
            onChange={(e) => {
              if (form.errors.email && !form.touched.email) {
                form.setFieldTouched('email');
                form.validateField('email');
              }
              return form.handleChange(e);
            }}
            errorMessage={getErrorMessage(form.touched.email, form.errors.email)}
            fullWidth
            margin={'normal'}
            autoFocus
            inputProps={{ tabIndex: 1 }}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">
                  <EmailIcon style={{ margin: '2px 5px 0 0', width: 20 }} />
                </InputAdornment>
              ),
            }}
            value={form.values.email}
          />
        </div>
        <BoxContainer>
          <CustomButton
            color="primary"
            variant="contained"
            disableElevation
            fullWidth
            tabIndex={10}
            onClick={() => form.handleSubmit()}
            style={{ marginTop: '12px' }}
          >
            Send reset password link
          </CustomButton>
          <Link to={'/login'}>
            <CustomButton variant="outlined" disableElevation fullWidth tabIndex={11} style={{ marginTop: '20px' }}>
              Cancel
            </CustomButton>
          </Link>
        </BoxContainer>
        <Backdrop isLoading={isLoading} />
      </BoxContainer>
    </FrontLayout>
  );
};

export default ForgotPassword;
