import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import {
  IClientAuditionRequestPayload,
  IClientAuditionResponsePayload,
} from 'shared/interfaces/IClientAuditionSchedule';
import { GET_CLIENT_AUDITION_SCHEDULE } from 'shared/queries/clientAuditionSchedule.queries';

const { POST } = useAxios();
export const clientAuditionScheduleDao = () => {
  const getClientAuditionSchedules = async (payload: IClientAuditionRequestPayload) => {
    const response = await POST<IClientAuditionResponsePayload>({
      url: ENDPOINTS.GRAPHQL,
      data: {
        query: GET_CLIENT_AUDITION_SCHEDULE,
        variables: {
          ...payload,
        },
      },
    });

    return response.data;
  };

  return {
    getClientAuditionSchedules,
  };
};
