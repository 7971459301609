import { styled } from '@material-ui/core';

export const Container = styled('div')(({}) => ({
  flex: '1 1 auto',
  padding: '40px !important',
}));

export const ContentContainer = styled('div')(({}) => ({
  marginTop: 12,
}));
