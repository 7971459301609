export const PROJECT_UNIONS = [
  { key: 'ACTRA', value: 'ACTRA' },
  { key: 'AEA', value: 'AEA' },
  { key: 'CAEA', value: 'CAEA' },
  { key: 'Equity', value: 'Equity' },
  { key: 'MEAA', value: 'MEAA' },
  { key: 'No Union Affiliation', value: 'No Union Affiliation' },
  { key: 'Non Union', value: 'Non Union' },
  { key: 'Other', value: 'Other' },
  { key: 'SAG/AFTRA', value: 'SAG/AFTRA' },
  { key: 'UBCP / ACTRA', value: 'UBCP / ACTRA' },
  { key: 'UDA', value: 'UDA' },
];
