import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Password(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M8.999 1.885a6.454 6.454 0 0 1 9.116 0 6.454 6.454 0 0 1 0 9.116 6.445 6.445 0 0 1-6.545 1.57l-1.456 1.439a.586.586 0 0 1-.412.169H8.631v1.091a.586.586 0 0 1-.654.582l-.89-.103-.116 1.234a.586.586 0 0 1-.529.528l-1.233.116.103.89a.586.586 0 0 1-.168.482l-.828.83A.586.586 0 0 1 3.9 20H.586A.586.586 0 0 1 0 19.414v-3.315c0-.156.062-.305.172-.414l7.257-7.258a6.445 6.445 0 0 1 1.57-6.542zm8.287.829a5.28 5.28 0 0 0-7.459 0 5.275 5.275 0 0 0-1.176 5.652.586.586 0 0 1-.131.628l-7.348 7.348v2.486h2.486l.457-.457-.14-1.203a.586.586 0 0 1 .527-.651l1.347-.127.128-1.348a.586.586 0 0 1 .65-.527l.832.097v-1.02c0-.323.262-.585.586-.585h1.417l1.546-1.529a.586.586 0 0 1 .626-.129 5.275 5.275 0 0 0 5.652-1.176 5.28 5.28 0 0 0 0-7.46zm-3.315.829a1.76 1.76 0 0 1 2.486 0 1.76 1.76 0 0 1 0 2.486 1.76 1.76 0 0 1-2.486 0 1.76 1.76 0 0 1 0-2.486zm1.658.828a.586.586 0 1 0-.83.828.586.586 0 0 0 .83-.828z"
        fill="#3E8BFF"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default Password;
