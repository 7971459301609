import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const ChatIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="k1lpp1394a">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M8 0C3.579 0 0 3.578 0 8c0 1.403.366 2.774 1.06 3.982L.03 15.183a.625.625 0 0 0 .787.787l3.2-1.03A7.982 7.982 0 0 0 8 16c4.421 0 8-3.578 8-8 0-4.421-3.578-8-8-8zm0 14.75a6.73 6.73 0 0 1-3.573-1.023.625.625 0 0 0-.523-.065l-2.31.744.744-2.31a.625.625 0 0 0-.065-.523A6.731 6.731 0 0 1 1.25 8 6.758 6.758 0 0 1 8 1.25 6.758 6.758 0 0 1 14.75 8 6.758 6.758 0 0 1 8 14.75zM8.781 8A.781.781 0 1 1 7.22 8 .781.781 0 0 1 8.78 8zm3.125 0a.781.781 0 1 1-1.562 0 .781.781 0 0 1 1.562 0zm-6.25 0a.781.781 0 1 1-1.562 0 .781.781 0 0 1 1.562 0z"
        fill="url(#k1lpp1394a)"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default ChatIconTwoTone;
