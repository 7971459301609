import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const DownloadIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 22 20" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="pni99l82ya">
            <stop stop-color="#3E8BFF" offset="0%" />
            <stop stop-color="#3E8BFF" offset="100%" />
          </linearGradient>
        </defs>
        <path
          d="M21.818 17.273v-3.637a.91.91 0 1 0-1.818 0v3.637a.91.91 0 0 1-.91.909H2.728a.91.91 0 0 1-.909-.91v-3.636a.91.91 0 0 0-1.818 0v3.637A2.727 2.727 0 0 0 2.727 20h16.364a2.727 2.727 0 0 0 2.727-2.727zm-5.8-4.746-4.545 3.637a.91.91 0 0 1-1.128 0L5.8 12.527a.91.91 0 0 1 1.127-1.418L10 13.564V.909a.91.91 0 0 1 1.818 0v12.655l3.073-2.455a.91.91 0 1 1 1.127 1.418z"
          fill="url(#pni99l82ya)"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
};

export default DownloadIcon;
