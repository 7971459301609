import { Grid, ImageList, ImageListItem, Typography as Typography2 } from '@material-ui/core';
import { MiniAudioPlayer } from 'components/MiniAudioPlayer';
import React, { useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Typography } from 'shared/elements';
import { IMedium } from 'shared/interfaces/IMedium';
import { IProfile, IProfileMediumAttachment } from 'shared/interfaces/IProfile';
import VideoItem from './VideoItem';
import { onOpenImageGallery, onOpenVideoGallery } from 'shared/redux/slicers/talentProfile.slicer';
import { useStyles } from './TalentProfileMedia.styles';
import { EmptyList } from 'components/EmptyList';
import { NO_PROFILE_IMAGE_PLACEHOLDER_URL } from 'shared/constants/ASSETS_URL';
import { Image } from 'components/Image';
type Props = {
  profile: IProfile;
  handleVideoViewType?: (instance: string) => void;
  handleVideoDisplay?: (instance: IMedium) => void;
};
const TalentProfileMedia: React.FC<Props> = ({ profile, handleVideoViewType, handleVideoDisplay }) => {
  const classes = useStyles();
  const dispatch = useDispatch();

  const handleOpenImageGallery = () => {
    dispatch(onOpenImageGallery());
  };

  const handleOpenVideoGallery = (view: string) => {
    if (handleVideoViewType) {
      handleVideoViewType(view);
    }
    dispatch(onOpenVideoGallery());
  };

  const audios = useMemo(() => {
    return profile.attributes.audios.data
      ? profile.attributes.audios.data.map((audio) => ({
          ...audio.attributes,
          file_name: audio.attributes.medium.data.attributes.file_name,
        }))
      : [];
  }, [profile]);

  return (
    <div className={classes.talentProfileMediaContainer}>
      {/* Primary Image */}
      <div className={classes.primaryImage}>
        <Image
          src={profile.attributes.primary_image_url || NO_PROFILE_IMAGE_PLACEHOLDER_URL}
          className={classes.primaryImage__img}
        />
      </div>
      {/* Image List */}
      <div className={classes.imageListContainer}>
        {profile.attributes.images.data.length > 0 ? (
          <ImageList rowHeight={127} cols={3} gap={12} className={classes.imageList}>
            {profile.attributes.images.data.map((image: IProfileMediumAttachment, index) => (
              <ImageListItem
                key={index}
                classes={{ item: classes.imageListItem }}
                onClick={() => handleOpenImageGallery()}
              >
                <img src={image.attributes.modified_attachment} style={{ borderRadius: 4, objectFit: 'contain' }} />
              </ImageListItem>
            ))}
          </ImageList>
        ) : (
          <EmptyList text="No Images Data" />
        )}
      </div>
      {/* Video List */}
      <div className={classes.videoListContainer}>
        <Typography2
          variant="body1"
          onClick={() => handleOpenVideoGallery('group')}
        >{`Video Files (${profile.attributes.videos.data.length})`}</Typography2>
        <Grid container spacing={2} style={{ marginTop: 12 }}>
          {profile.attributes.videos.data.length > 0 ? (
            <React.Fragment>
              {profile.attributes.videos.data.map((video, i) => (
                <VideoItem
                  key={i}
                  video={video}
                  onClick={() => {
                    handleOpenVideoGallery('single');
                    if (handleVideoDisplay) {
                      handleVideoDisplay(video);
                    }
                  }}
                />
              ))}
            </React.Fragment>
          ) : (
            <EmptyList text="No Video Data" />
          )}
        </Grid>
      </div>

      {/* Audio List */}
      <div className={classes.audioListContainer}>
        <Typography fontWeight={500}>{`Audio Files (${audios.length})`}</Typography>
        {/* Audio List Grid */}
        <Grid container style={{ marginTop: 12 }} spacing={2}>
          {/* Audio List Item */}
          {audios.length > 0 ? (
            <React.Fragment>
              {audios.map((audio, i) => (
                <Grid item lg={12} md={12} key={i}>
                  <MiniAudioPlayer audio={audio} />
                </Grid>
              ))}
            </React.Fragment>
          ) : (
            <EmptyList text="No Audio Data" />
          )}
        </Grid>
      </div>
    </div>
  );
};

export default TalentProfileMedia;
