import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const TapIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 64 50" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="trujnbpd1a">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="m49.324 10.63.176.167 9.897 10.871a14.433 14.433 0 0 1 3.764 9.73c0 3.727-1.401 7.24-3.956 9.935l-.279.285-4.136 4.14a14.396 14.396 0 0 1-9.841 4.237L44.55 50H19.737a3.952 3.952 0 0 1-3.948-3.947c0-2.824 2.05-5.263 4.809-5.77l.23-.038 10.622-1.669L13.534 20.66a5.03 5.03 0 0 1 0-7.107 5.04 5.04 0 0 1 6.95-.184l.177.168 4.663 4.663c.229-.776.65-1.51 1.263-2.124 1.768-1.765 4.702-1.934 6.618-.46a4.99 4.99 0 0 1 1.277-2.171c1.77-1.771 4.702-1.932 6.623-.458.221-.8.648-1.55 1.271-2.174 1.873-1.873 5.028-1.917 6.948-.183zm-5.071 2.028a2.409 2.409 0 0 0-.174 3.23l.116.13.158.158a1.316 1.316 0 0 1-1.721 1.983l-.108-.09h-.006l-.006-.006-.002-.002-.01-.006-.005-.004-.003-.004-2.747-2.757c-.905-.906-2.482-.9-3.384 0a2.415 2.415 0 0 0-.167 3.242l.117.131 2.779 2.777a1.317 1.317 0 0 1-1.754 1.956l-.107-.096-2.747-2.745-.045-.044-2.587-2.59c-.905-.905-2.479-.9-3.384 0a2.41 2.41 0 0 0-.176 3.227l.115.13 5.424 5.425a1.317 1.317 0 0 1-1.754 1.956l-.107-.096-5.379-5.376-7.789-7.79a2.397 2.397 0 0 0-3.39-.003 2.408 2.408 0 0 0-.137 3.272l.122.131L35.14 38.542a1.317 1.317 0 0 1-.596 2.202l-.13.027-13.198 2.074c-1.6.231-2.795 1.607-2.795 3.208 0 .68.52 1.241 1.182 1.309l.134.006H44.55c3.043 0 5.916-1.142 8.125-3.224l.251-.244 4.137-4.14a11.76 11.76 0 0 0 3.463-8.36c0-2.827-1.003-5.538-2.835-7.682l-.244-.276-9.852-10.829c-.821-.816-2.437-.855-3.342.045zM17.105 0c7.734 0 14.527 5.226 16.513 12.708a1.32 1.32 0 0 1-.931 1.61 1.306 1.306 0 0 1-1.61-.934c-1.682-6.331-7.43-10.752-13.972-10.752-7.979 0-14.473 6.492-14.473 14.473 0 6.545 4.423 12.29 10.752 13.971a1.315 1.315 0 1 1-.673 2.543C5.226 31.632 0 24.839 0 17.105 0 7.674 7.674 0 17.105 0zm0 5.263c4.634 0 8.863 2.742 10.782 6.984a1.317 1.317 0 0 1-2.4 1.082c-1.492-3.3-4.782-5.434-8.382-5.434-5.079 0-9.21 4.131-9.21 9.21 0 3.6 2.134 6.89 5.434 8.382.663.3.955 1.079.658 1.742a1.32 1.32 0 0 1-1.742.658c-4.24-1.919-6.982-6.15-6.982-10.782 0-6.529 5.313-11.842 11.842-11.842z"
        fill="url(#trujnbpd1a)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default TapIcon;
