import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      maxHeight: '95%',
      top: 20,
    },

    sizeCardContainer: {
      display: 'flex',
      gap: 24,
    },
    imageContainer: {
      // width: 100,
      width: 280,
      height: 450,
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      objectPosition: '50% 50%',
      borderRadius: 4,
    },
    details: {
      flex: 1,
    },
    details__header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    details__agencyContainer: {
      marginTop: theme.spacing(2),
    },
    details__description: {
      display: 'flex',
      gap: 24,
      alignItems: 'center',
      marginTop: theme.spacing(1),
    },
    description__division: {
      width: 'calc(100% / 3)',
    },
    description__rep: {
      width: 'calc(100% / 3)',
    },
    description__logo: {
      width: 'calc(100% / 3)',
    },
    description__logoImage: {
      height: '100%',
      width: '100%',
      objectFit: 'cover',
    },

    details__statistics: {
      marginTop: theme.spacing(1),
    },
    statistics__item: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(1.25, 2),

      '&:nth-child(even)': {
        backgroundColor: '#10151c',
        borderTop: '1px solid #202936',
        borderBottom: '1px solid #202936',
      },
      '&:last-child': {
        borderBottom: '1px solid #202936',
      },
    },
    generateContainer: {
      marginTop: theme.spacing(2),
    },
  }),
);
