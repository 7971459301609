import React from 'react';
import { ReportIcon } from '@room-match/shared-ui-components';
import {
  DashboardIcon,
  ProjectsIcon,
  MessagesIcon,
  CompanyIcon,
  UsersListIcon,
  ContactsIcon,
  CalendarLeftIcon,
} from 'components/Icons';

import { ROUTES } from './ROUTES';

interface ISidebarItems {
  name: string;
  icon: React.ReactNode;
  link?: string;
  disabled?: boolean;
}
export const SIDEBAR_ITEMS: ISidebarItems[] = [
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    link: ROUTES.CASTING.DASHBOARD,
    disabled: true,
  },
  {
    name: 'Projects',
    icon: <ProjectsIcon />,
    link: ROUTES.CASTING.PROJECTS.PROJECT_LIST,
  },
  {
    name: 'Schedules',
    icon: <CalendarLeftIcon />,
    link: ROUTES.CASTING.SCHEDULES,
  },
  {
    name: 'Messages',
    icon: <MessagesIcon />,
    link: ROUTES.CASTING.MESSAGES,
    disabled: true,
  },
  {
    name: 'Companies',
    icon: <CompanyIcon />,
    link: ROUTES.CASTING.COMPANIES,
    disabled: true,
  },
  {
    name: 'Talent',
    icon: <UsersListIcon />,
    link: ROUTES.CASTING.TALENTS,
    disabled: true,
  },
  {
    name: 'Contacts',
    icon: <ContactsIcon />,
    link: `${ROUTES.CASTING.CONTACTS}/all`,
  },
  {
    name: 'Reports',
    icon: <ReportIcon />,
    link: `${ROUTES.CASTING.REPORTS}`,
  },
];

export const CLIENT_SIDEBAR_ITEMS: ISidebarItems[] = [
  {
    name: 'Projects',
    icon: <ProjectsIcon />,
    link: ROUTES.CLIENT.PROJECTS.PROJECT_LIST,
  },
];
