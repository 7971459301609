import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const ShadedStartBlueIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="ow9qljxhha">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M16.652 6.046a.886.886 0 0 0-.764-.61l-4.818-.438L9.164.538A.888.888 0 0 0 7.532.54L5.626 5l-4.82.437a.887.887 0 0 0-.503 1.553l3.643 3.194-1.074 4.732a.887.887 0 0 0 1.32.958l4.156-2.484 4.155 2.484a.888.888 0 0 0 1.32-.959l-1.073-4.73 3.642-3.195a.888.888 0 0 0 .26-.943z"
        transform="translate(.696)"
        fill="url(#ow9qljxhha)"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default ShadedStartBlueIconTwoTone;
