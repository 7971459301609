import axios from 'axios';
import { useEffect, useMemo, useState } from 'react';
import { uploadService } from 'shared/services/uploadService';
import { getMediaId } from 'shared/utils/auditionMediaAttachments';
import { getThumbnailUrl } from 'shared/utils/getThumbnailUrl';

const { getSingleMedia } = uploadService();
export const useGetThumbnail = () => {
  const [videoUrl, setVideoUrl] = useState<string>('');

  const [isErrorThumbnail, setIsErrorThumbnail] = useState<boolean>(false);
  const { data, isLoading } = getSingleMedia(getMediaId(videoUrl));

  const thumbnail = useMemo(() => {
    return data ? getThumbnailUrl(data) : '';
  }, [data]);

  const handleChangeVideoUrl = (url: string) => {
    setVideoUrl(url);
  };

  useEffect(() => {
    (async () => {
      try {
        if (!!thumbnail) {
          await axios.get(thumbnail, {
            responseType: 'blob',
            headers: {
              accept: 'application/json',
            },
          });
        }
        setIsErrorThumbnail(false);
      } catch (err) {
        setIsErrorThumbnail(true);
      }
    })();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [thumbnail]);

  return {
    thumbnail,
    videoUrl,
    isLoading,
    isErrorThumbnail,
    handleChangeVideoUrl,
  };
};
