import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    auditionList: {},
    auditionList__header: {
      paddingBottom: theme.spacing(2),
      borderBottom: '1px solid #2d343e',
    },
    auditionList__headerContent: {
      padding: theme.spacing(0, 2),
      display: 'flex',
      alignItems: 'center',
      gap: 12,
    },
    auditionList__listContainer: {
      display: 'flex',
      gap: 16,
      flexWrap: 'wrap',
      paddingBottom: theme.spacing(1),
      minHeight: 300,
    },
    auditionList__clientRoleListContianer: {
      marginTop: 16,
      minHeight: 'auto',
    },
    auditionList__listItem: {},
    auditionList__loadingContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
    },
  }),
);
