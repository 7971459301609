import { useMutation, useQuery } from 'react-query';
import { companyDao } from 'shared/dao/companyDao';
import {
  ICompanyAddressCreatePayload,
  ICompanyLogoUpdatePayload,
  ICompanyMetalDetailsResponsePayload,
  ICompanyResponsePayload,
  ICompanyUpdatePayload,
} from 'shared/interfaces/ICompany';
import { ICommonResponsePayload } from 'shared/interfaces/ICommon';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';

const {
  getCompany: getCompanyDao,
  updateCompany: updateCompanyDao,
  updateCompanyLogo: updateCompanyLogoDao,
  updateCompanyIcon: updateCompanyIconDao,
  createCompanyAddress: createCompanyAddressDao,
  updateCompanyAddress: updateCompanyAddressDao,
  deleteCompanyAddress: deleteCompanyAddressDao,
  detachCompanyLogo: detachCompanyLogoDao,
  detachCompanyIcon: detachCompanyIconDao,
  getCompanyMetaDetails: getCompanyMetaDetailsDao,
} = companyDao();
export const companyService = () => {
  const getCompany = () => {
    return useQuery<ICompanyResponsePayload, IAxiosErrorResponse>(['company'], () => getCompanyDao());
  };

  const updateCompany = () => {
    return useMutation<ICompanyResponsePayload, IAxiosErrorResponse, ICompanyUpdatePayload>((payload) =>
      updateCompanyDao(payload),
    );
  };

  const updateCompanyLogo = () => {
    return useMutation<ICompanyLogoUpdatePayload, IAxiosErrorResponse, FormData>((formData) =>
      updateCompanyLogoDao(formData),
    );
  };
  const updateCompanyIcon = () => {
    return useMutation<ICompanyLogoUpdatePayload, IAxiosErrorResponse, FormData>((formData) =>
      updateCompanyIconDao(formData),
    );
  };

  const createCompanyAddress = () => {
    return useMutation<ICompanyResponsePayload, IAxiosErrorResponse, { payload: ICompanyAddressCreatePayload }>(
      ({ payload }) => createCompanyAddressDao(payload),
    );
  };

  const updateCompanyAddress = () => {
    return useMutation<
      ICompanyResponsePayload,
      IAxiosErrorResponse,
      { payload: ICompanyAddressCreatePayload; addressId: string }
    >(({ payload, addressId }) => updateCompanyAddressDao(payload, addressId));
  };

  const deleteCompanyAddress = () => {
    return useMutation<ICompanyResponsePayload, IAxiosErrorResponse, { addressId: string }>(({ addressId }) =>
      deleteCompanyAddressDao(addressId),
    );
  };

  const detachCompanyLogo = () => {
    return useMutation<ICommonResponsePayload, IAxiosErrorResponse, { strType: string }>(({ strType }) =>
      detachCompanyLogoDao(strType),
    );
  };

  const detachCompanyIcon = () => {
    return useMutation<ICommonResponsePayload, IAxiosErrorResponse, { strType: string }>(({ strType }) =>
      detachCompanyIconDao(strType),
    );
  };

  const getCompanyMetaDetails = (companyId: string) => {
    return useQuery<ICompanyMetalDetailsResponsePayload, IAxiosErrorResponse>(['companyMetaDetails', companyId], () =>
      getCompanyMetaDetailsDao(companyId),
    );
  };

  return {
    getCompany,
    updateCompany,
    updateCompanyLogo,
    updateCompanyIcon,
    createCompanyAddress,
    updateCompanyAddress,
    deleteCompanyAddress,
    detachCompanyLogo,
    detachCompanyIcon,
    getCompanyMetaDetails,
  };
};
