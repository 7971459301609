import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    compositeCardContainer: {
      maxHeight: '100%',
      overflow: 'auto',
    },
    compositeCardContainer__titleContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      padding: theme.spacing(0),
    },
    compositeCardContainer__mediaContainer: {},
    primaryImage: {
      display: 'block',
      alignItems: 'center',
      borderRadius: 4,
      overflow: 'hidden',
      padding: '0px',
    },
    primaryImage__img: {
      width: '100%',
      height: 'auto',
      objectFit: 'contain',
      borderRadius: 4,
      marginTop: 16,
    },
  }),
);
