import { Grid } from '@material-ui/core';
import { Backdrop } from '@room-match/shared-ui-components';
import { ResumeMediaGallery } from 'components/ResumeMediaGallery';
import { SizeCard } from 'components/SizeCard';
import ImageGallery from 'components/TalentGalleries/ImageGallery/ImageGallery';
import VideoGallery from 'components/TalentGalleries/VideoGallery/VideoGallery';
import TalentDetails from 'components/TalentProfile/TalentDetails/TalentDetails';
import TalentProfileCard from 'components/TalentProfile/TalentProfileCard/TalentProfileCard';
import TalentProfileDetails from 'components/TalentProfile/TalentProfileDetails/TalentProfileDetails';
import TalentProfileMedia from 'components/TalentProfile/TalentProfileMedia/TalentProfileMedia';
import TalentSkillList from 'components/TalentProfile/TalentSkillList/TalentSkillList';
import { NotFound } from 'pages/NotFound';
import { useEffect, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useParams } from 'react-router-dom';
import { IMedium, IMediumAttributes } from 'shared/interfaces/IMedium';
import {
  onCloseImageGallery,
  onCloseResumeGallery,
  onCloseSizeCard,
  onCloseVideoGallery,
} from 'shared/redux/slicers/talentProfile.slicer';
import { useAppSelector } from 'shared/redux/store';
import { profileLinkService } from 'shared/services/profileLinkService';
import { Container, ContentContainer } from './TalentProfileLink.styles';

const { getProfileLink } = profileLinkService();

const TalentProfileLink = () => {
  const [videoViewType, setVideoViewType] = useState<string>('group');
  const [selectedVideos, setSelectedVideos] = useState<IMedium[]>([]);
  const [resumeMedia, setResumeMedia] = useState<IMediumAttributes[]>([]);
  const { isResumeGalleryOpen, isSizeCardOpen, isImageGalleryOpen, isVideoGalleryOpen } = useAppSelector(
    ({ talentProfile }) => talentProfile,
  );
  const { token } = useParams() as { token: string };
  const dispatch = useDispatch();

  const { data: profileLinkData, isLoading: isLoadingProfileLink, isError: isErrorProfileLink } = getProfileLink(token);

  const profile = useMemo(() => {
    return profileLinkData ? profileLinkData.data : null;
  }, [profileLinkData]);

  const images = useMemo(() => {
    return profile?.attributes.images.data
      ? profile?.attributes.images.data.map((image) => ({
          ...image.attributes,
          file_height: image?.attributes?.medium?.data.attributes.file_height,
          file_width: image?.attributes?.medium?.data.attributes.file_width,
        }))
      : [];
  }, [profile]);

  const videos = useMemo(() => {
    return profile?.attributes.videos.data
      ? profile?.attributes.videos.data.map((video) => ({
          ...video,
        }))
      : [];
  }, [profile]);

  const handleResumeGalleryClose = () => {
    dispatch(onCloseResumeGallery());
  };

  const handleCloseSizeCard = () => {
    dispatch(onCloseSizeCard());
  };

  const handleCloseImageGallery = () => {
    dispatch(onCloseImageGallery());
  };

  const handleCloseVideoGallery = () => {
    dispatch(onCloseVideoGallery());
  };

  const handleVideoViewType = (view: string) => {
    setVideoViewType(view);
    setSelectedVideos(videos);
  };

  const handleVideoDisplay = (video: IMedium) => {
    setSelectedVideos([video]);
  };

  const handleSetResumeMedia = (medias: IMediumAttributes[]) => {
    setResumeMedia(medias);
  };

  useEffect(() => {
    if (videos.length > 0) {
      setSelectedVideos(videos);
    }
  }, [videos]);

  return (
    <Container>
      {/* Header */}
      {!isLoadingProfileLink && (
        <ContentContainer>
          {!isErrorProfileLink && profile ? (
            <Grid container spacing={4}>
              {/* Talent Profile Media */}
              <Grid item lg={2} md={3}>
                {profile && (
                  <TalentProfileMedia
                    profile={profile}
                    handleVideoViewType={handleVideoViewType}
                    handleVideoDisplay={handleVideoDisplay}
                  />
                )}
              </Grid>
              {/* Talent Profile Details */}
              <Grid item lg={10} md={9}>
                {/* Talent Details */}
                <TalentDetails profile={profile.attributes} />

                {/* Cards */}
                <TalentProfileCard profile={profile.attributes} handleSetResumeMedia={handleSetResumeMedia} />
                {/* Profile Details */}
                <TalentProfileDetails profile={profile} handleSetResumeMedia={handleSetResumeMedia} />
                {/* Skills List */}
                <TalentSkillList
                  skills={profile?.attributes.skills || []}
                  handleSetResumeMedia={handleSetResumeMedia}
                />
              </Grid>
              <ResumeMediaGallery open={isResumeGalleryOpen} onClose={handleResumeGalleryClose} media={resumeMedia} />
              <SizeCard profile={profile.attributes} open={isSizeCardOpen} onClose={handleCloseSizeCard} />
              <ImageGallery open={isImageGalleryOpen} onClose={handleCloseImageGallery} data={images} fit="contain" />
              <VideoGallery
                open={isVideoGalleryOpen}
                onClose={handleCloseVideoGallery}
                data={selectedVideos}
                view={videoViewType}
              />
            </Grid>
          ) : (
            <NotFound />
          )}
        </ContentContainer>
      )}

      <Backdrop isLoading={isLoadingProfileLink} />
    </Container>
  );
};

export default TalentProfileLink;
