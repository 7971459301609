import { Box, Paper, Typography } from '@material-ui/core';
import React from 'react';

import { useStyles } from './FrontLayout.style';

type Props = {
  heading?: string;
  subHeading?: string;
  bannerImage?: string;
  companyLogo?: string;
  isClient?: boolean;
  children: React.ReactNode;
  containerWidth?: number;
  clientName?: string;
};
const FrontLayout: React.FC<Props> = ({
  children,
  bannerImage = '/casting-background.png',
  companyLogo = '/casting-logo.svg',
  isClient,
  containerWidth,
  clientName,
}) => {
  const classes = useStyles({ bannerImage });
  return (
    <div className={classes.bodyContainer}>
      <div className={classes.backgroundContainer}>
        <div className={classes.bgImageOverlay} />
      </div>
      <Paper className={classes.mainContainer} style={{ width: containerWidth || 640 }}>
        <Box className={classes.header} style={{ marginTop: isClient ? 'auto' : 'unset' }}>
          <div className={classes.logoContainer}>
            {companyLogo && <img className={classes.logoCls} src={companyLogo} alt="File Logo" />}
          </div>
        </Box>
        {isClient && (
          <div className={classes.clientDetails}>
            {clientName && <Typography style={{ fontSize: '25px' }}>Welcome back, {clientName || 'N/A'}!</Typography>}
            <Typography variant="h6">You have some new auditions to review, please login below.</Typography>
          </div>
        )}
        <Box className={classes.childrenContainer}>{children}</Box>
        {isClient && (
          <div className={classes.poweredBy}>
            <Typography variant="body2">Powered by</Typography>
            <img src="/powered-by.svg" alt="Powered by" />
          </div>
        )}
      </Paper>
    </div>
  );
};

export default FrontLayout;
