import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    dotButtonContainer: {
      display: 'flex',
      alignItems: 'center',
    },
    menu: {
      backgroundColor: '#0b0f14',
      border: '1px solid #222c39',
      borderRadius: 8,
    },
    menu__item: {
      margin: '4px 0',
      transition: 'none !important',
      '&:hover': {
        backgroundColor: 'transparent !important',
      },
    },
    menuItem__container: {
      display: 'flex',
      alignItems: 'center',
      gap: 12,
    },
  }),
);
