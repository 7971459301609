import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const ResumeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.885 0H1.793A1.55 1.55 0 0 0 .245 1.548c0 11.509-.013 10.814.027 10.932.026.079.07.147.123.2.032.033 3.166 3.17 3.212 3.205.203.157-.11.108 7.278.108a1.55 1.55 0 0 0 1.548-1.548V1.548A1.55 1.55 0 0 0 10.885 0zm-7.48 14.231-1.399-1.398h1.399v1.398zm7.996.214a.516.516 0 0 1-.516.516H4.437v-2.644a.516.516 0 0 0-.516-.516H1.277V1.548c0-.285.231-.516.516-.516h9.092c.285 0 .516.231.516.516v12.897zm-1.644-4.03a.516.516 0 0 1-.516.515H3.405a.516.516 0 0 1 0-1.031H9.24c.285 0 .516.23.516.515zm0 2.643a.516.516 0 0 1-.516.516H5.92a.516.516 0 0 1 0-1.031h3.32c.286 0 .517.23.517.515zM6.317 5.42c.958 0 1.737-.779 1.737-1.736s-.779-1.736-1.736-1.736c-.958 0-1.737.779-1.737 1.736s.78 1.736 1.737 1.736zm0-2.44a.705.705 0 1 1 0 1.41.705.705 0 0 1 0-1.41zM3.706 7.945V7.24c0-.446.264-.852.671-1.034l.818-.365c.137-.061.041-.045 2.025-.045.145 0 .14.013 1.029.41.407.182.67.588.67 1.034v.707a.516.516 0 0 1-1.031 0V7.24a.1.1 0 0 0-.06-.092l-.718-.32H5.514l-.717.32a.1.1 0 0 0-.06.092v.707a.516.516 0 0 1-1.032 0z"
        fill={props.htmlColor || '#fff'}
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default ResumeIcon;
