import React from 'react';
import { Button } from '@material-ui/core';
import { DownloadIcon } from 'components/Icons';
import { useStyles } from './CompositeCardContent.styles';

const CompositeCardContent = () => {
  const classes = useStyles();
  const onDownload = () => {
    const link = document.createElement('a');
    link.download = 'file.pdf';
    link.href = './sample_composite_card.pdf';
    link.click();
  };

  return (
    <div className={classes.compositeCardContainer}>
      <div className={classes.compositeCardContainer__titleContainer}>
        <Button
          startIcon={<DownloadIcon style={{ fontSize: 18, marginTop: 3 }} />}
          variant="contained"
          color="secondary"
          disableElevation
          style={{ backgroundColor: '#2c3643' }}
          fullWidth
          onClick={onDownload}
        >
          Download Composite Card
        </Button>
      </div>
      <div className={classes.compositeCardContainer__mediaContainer}>
        <div className={classes.primaryImage}>
          <img src="/sample_composite_card.png" className={classes.primaryImage__img} />
        </div>
      </div>
    </div>
  );
};

export default CompositeCardContent;
