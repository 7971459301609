import { IconButton } from '@material-ui/core';
import { PaperPlaneIconTwoTone, ChatIconTwoTone, Input } from '@room-match/shared-ui-components';
import clsx from 'clsx';
import React from 'react';
import { Typography } from 'shared/elements';
import { IAuditionAttributes } from 'shared/interfaces/IAudition';
import MessageInput from '../MessageInput/MessageInput';
import { useStyles } from './TalentHistory.styles';

type Props = {
  isGradientIcon?: boolean;
  audition: IAuditionAttributes;
};
const TalentHistory: React.FC<Props> = ({ isGradientIcon, audition }) => {
  const classes = useStyles();
  return (
    <div className={classes.history__detail}>
      <IconButton style={{ padding: 0 }}>
        {isGradientIcon ? (
          <ChatIconTwoTone fontSize="small" style={{ fontSize: 15 }} />
        ) : (
          <ChatIconTwoTone fontSize="small" style={{ fontSize: 15 }} />
        )}
      </IconButton>
      <div>
        <Typography fontSize={14} style={{ lineHeight: 0.8 }}>
          {audition.submission_note || 'N/A'}
        </Typography>
        {/* <Typography fontSize={14} color="mainGrey" style={{ marginTop: 6 }}>
          Smith Agency - 3:45 23/1/22
        </Typography> */}
      </div>
    </div>
  );
};

export default TalentHistory;
