import React from 'react';
import { Typography as MUITypography, TypographyTypeMap } from '@material-ui/core';
import { OverridableComponent } from '@material-ui/core/OverridableComponent';
import clsx from 'clsx';
import { useStyles } from './Typography.styles';

type Props = {
  children?: React.ReactNode;
  color?: 'primary' | 'mainGrey' | 'lightGrey' | 'darkGrey';
  fontSize?: number;
  fontWeight?: 400 | 500 | 700;
  variant?: 'heading' | 'subHeading' | 'text';
  className?: string;
  style?: React.CSSProperties;
  onMouseEnter?: (e: any) => void;
  onMouseLeave?: (e: any) => void;
};
const Typography: React.FC<Props> = ({
  children,
  color,
  fontSize,
  fontWeight,
  variant,
  className,
  style,
  onMouseEnter,
  onMouseLeave,
}) => {
  const classes = useStyles({ color, fontSize, variant });
  return (
    <MUITypography
      className={clsx(classes.baseStyle, className)}
      style={{ fontSize, fontWeight, ...style }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {children}
    </MUITypography>
  );
};

export default Typography;
