import { computeProgressEvent, IProgressEvent } from '@room-match/shared-utils';
import axios from 'axios';
import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { ICommonResponsePayload } from 'shared/interfaces/ICommon';
import { IS3Response, IUploadCreateResponsePayload } from 'shared/interfaces/IUpload';
import { IOnProgress } from 'shared/interfaces/utils/IAxios';
import { createFileChecksum } from 'shared/utils/s3Utils';

const { GET, POST, PATCH, DELETE } = useAxios();
export const uploadDao = () => {
  const getSingleMedia = async (mediaId: string) => {
    const response = await GET<string>({
      url: `${ENDPOINTS.MEDIA}/${mediaId}?redirect=false`,
    });

    return response.data;
  };

  const getSingleMediaAsBlob = async (mediaId: string): Promise<Blob> => {
    // const response = await GET<Blob>({
    //   url: `${ENDPOINTS.MEDIA}/${mediaId}`,
    //   responseType: 'blob',
    //   headers: undefined,
    // });
    const response = await axios.get(`${ENDPOINTS.MEDIA}/${mediaId}`, {
      responseType: 'blob',
    });
    return response.data;
  };

  const uploadMedia = async (formData: FormData, onProgress?: IOnProgress) => {
    const file: File = formData.get('attachment') as File;

    const checksum = await createFileChecksum(file);

    const presignedBody = {
      byte_size: formData.get('file_size'),
      checksum,
      filename: formData.get('file_name'),
      content_type: file.type,
    };

    const s3Response: IS3Response = await POST({
      url: ENDPOINTS.PRESIGNED_URL,
      data: presignedBody,
    });

    const config = {
      onUploadProgress: (progressEvent: IProgressEvent) => {
        if (onProgress) {
          onProgress(computeProgressEvent(progressEvent));
        }
      },
      headers: { ...s3Response.data.direct_upload.headers },
    };

    await axios.put(s3Response.data.direct_upload.url, file, config);

    formData.set('attachment', s3Response.data.blob_signed_id);

    const response = await POST<IUploadCreateResponsePayload>({
      url: ENDPOINTS.MEDIA,
      data: formData,
    });

    return response.data;
  };

  const updateMedia = async (formData: FormData, mediumId: string, onProgress?: IOnProgress) => {
    const response = await PATCH<IUploadCreateResponsePayload>({
      url: `${ENDPOINTS.MEDIA}/${mediumId}`,
      data: formData,
      onUploadProgress: (progressEvent: IProgressEvent) => {
        if (onProgress) {
          onProgress(computeProgressEvent(progressEvent));
        }
      },
    });

    return response.data;
  };

  const deleteMedia = async (mediaIds: string[]) => {
    const response = await DELETE<ICommonResponsePayload>({
      url: ENDPOINTS.MEDIA,
      data: {
        medium_ids: mediaIds,
      },
    });

    return response.data;
  };

  return {
    getSingleMedia,
    getSingleMediaAsBlob,
    uploadMedia,
    updateMedia,
    deleteMedia,
  };
};
