import { QueryKey, useMutation, useQuery, UseQueryOptions } from 'react-query';
import { queryClient } from 'shared/config/queryClient';
import { roleDao } from 'shared/dao/roleDao';
import { AuditionType } from 'shared/enums/AuditionType';
import {
  IRoleMutateResponsePayload,
  IRoleCreatePayload,
  IRoleResponsePayload,
  IRoleSideCreatePayload,
  IRoleSideCreateResponsePayload,
  ISingleRoleResponsePayload,
  IRoleUpdateRequestPayload,
} from 'shared/interfaces/IRole';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';

const {
  getRoles: getRolesDao,
  getSingleRole: getSingleRoleDao,
  createRole: createRoleDao,
  updateRole: updateRoleDao,
  createRoleSide: createRoleSideDao,
} = roleDao();
export const roleService = () => {
  const getRoles = (
    projectId: string,
    auditionType?: AuditionType,
    options?: Omit<
      UseQueryOptions<IRoleResponsePayload, IAxiosErrorResponse, IRoleResponsePayload, QueryKey>,
      'queryKey' | 'queryFn'
    >,
  ) => {
    return useQuery<IRoleResponsePayload, IAxiosErrorResponse>(
      ['role', projectId, auditionType],
      () => getRolesDao(projectId, auditionType),
      options,
    );
  };
  const getSingleRole = (projectId: string, roleId: string) => {
    return useQuery<ISingleRoleResponsePayload, IAxiosErrorResponse>(
      ['role', projectId, roleId],
      () => getSingleRoleDao(projectId, roleId),
      {
        enabled: !!roleId,
      },
    );
  };
  const createRole = () => {
    return useMutation<
      IRoleMutateResponsePayload,
      IAxiosErrorResponse,
      { projectId: string; payload: IRoleCreatePayload }
    >(({ projectId, payload }) => createRoleDao(projectId, payload));
  };

  const updateRole = () => {
    return useMutation<
      IRoleMutateResponsePayload,
      IAxiosErrorResponse,
      { projectId: string; roleId: string; payload: IRoleUpdateRequestPayload; updateType?: string }
    >(({ projectId, roleId, payload }) => updateRoleDao(projectId, roleId, payload), {
      onMutate: async ({ projectId, roleId, payload, updateType }) => {
        await queryClient.cancelQueries(['role', projectId], { exact: true });

        const previousRole = queryClient.getQueryData<IRoleResponsePayload>(['role', projectId], {
          exact: false,
          active: true,
        });
        if (updateType === 'toggle_audition_type') {
          // *! HAS ERRORS WHEN UPDATE ROLE NEED TO BE FIXED
          if (payload.audition_type) {
            if (previousRole) {
              const mappedData = previousRole.data.map((data) => {
                return data.attributes.id === roleId
                  ? {
                      ...data,
                      attributes: {
                        ...data.attributes,
                        audition_type: payload.audition_type || data.attributes.audition_type,
                      },
                    }
                  : data;
              });

              const newRole = {
                ...previousRole,
                data: mappedData,
              };

              queryClient.setQueriesData<IRoleResponsePayload>(['role', projectId], newRole);
            }
          }
        }

        return { previousRole };
      },
    });
  };

  const createRoleSide = () => {
    return useMutation<
      IRoleSideCreateResponsePayload,
      IAxiosErrorResponse,
      { projectId: string; roleId: string; payload: IRoleSideCreatePayload }
    >(({ projectId, roleId, payload }) => createRoleSideDao(projectId, roleId, payload));
  };

  return {
    getRoles,
    getSingleRole,
    createRole,
    updateRole,
    createRoleSide,
  };
};
