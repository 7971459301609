import { Box, DialogContent, IconButton, useTheme } from '@material-ui/core';
import { CloseIcon } from 'components/Icons';
import { ImageCarousel } from 'components/ImageCarousel';
import React, { useMemo } from 'react';
import { StyledDialog } from 'shared/elements';
import { useStyles } from './ImageGallery.styles';
import { IMediumAttributes } from 'shared/interfaces/IMedium';

// const images = [
//   {
//     src: '/sample_person2.jpg',
//     image: '/sample_person2.jpg',
//     width: 1920,
//     height: 2401,
//   },
//   {
//     src: '/landscape.jpg',
//     image: '/landscape.jpg',
//     width: 1980,
//     height: 1280,
//   },
// ];

// const items = Array.from({ length: 20 }).map((_, i) => {
//   return images[i % 2 === 0 ? 0 : 1];
// });

const BOX_HEIGHT = 100;
type Props = {
  open: boolean;
  data: IMediumAttributes[];
  fit?: string;
  onClose: () => void;
};
const ImageGallery: React.FC<Props> = ({ open, onClose, data, fit }) => {
  const classes = useStyles();
  const theme = useTheme();
  const captionStyle = {
    fontSize: '2em',
    fontWeight: 'bold',
  };
  const slideNumberStyle = {
    fontSize: '20px',
    fontWeight: 'bold',
  };
  const images = useMemo(() => {
    return data.length > 0 ? data : [];
  }, [data]);

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="lg" fullWidth style={{ maxHeight: '95%', top: 20 }}>
      <DialogContent>
        <Box className={classes.header}>
          {/* Title */}
          <Box></Box>
          {/* Artist */}
          <Box className={classes.header__artist}>
            {/* <Typography>Leonardo DiCaprio</Typography>
            <Typography color="mainGrey" fontSize={13}>
              Images 1 of 12
            </Typography> */}
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </Box>
        {/* Image */}
        <Box className={classes.imageContainer}>
          <ImageCarousel images={images} fit={fit} />
          {/* <Carousel
            data={images}
            time={5000}
            width="1200px"
            height="500px"
            captionStyle={captionStyle}
            radius="10px"
            captionPosition="bottom"
            automatic={false}
            pauseIconColor="white"
            pauseIconSize="40px"
            slideBackgroundColor={theme.palette.customBlack.main}
            slideImageFit="contain"
            thumbnails={true}
            thumbnailWidth="100px"
            style={{
              textAlign: 'center',
              maxWidth: '1200px',
              maxHeight: '300px',
              margin: '0 auto',
              paddingBottom: 40,
              '& img': {
                width: 'initial',
                borderRadius: '20px !important',
              },
            }}
          /> */}
        </Box>
        {/* Image List */}
        {/* <Box className={classes.imageList}>
          {items.map((item, i) => (
            <Box
              className={classes.imageList__item}
              style={{
                maxWidth: getImageWidth({ width: item.width, height: item.height }, BOX_HEIGHT),
                height: BOX_HEIGHT,
              }}
              key={i}
            >
              <Box style={{ width: getImageWidth({ width: item.width, height: item.height }, BOX_HEIGHT) / 2 }}>
                <img
                  src={item.src}
                  className={clsx(classes.imageList__img, {
                    [classes.imageList__img__selected]: i === 2,
                  })}
                  style={{ height: BOX_HEIGHT, width: '100%' }}
                />
              </Box>
            </Box>
          ))}
        </Box> */}
      </DialogContent>
    </StyledDialog>
  );
};

export default ImageGallery;
