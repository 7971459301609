import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    itemContainer: {},
    talentContainer: {
      display: 'flex',
      gap: 16,
      alignItems: 'center',
    },
    imageContainer: {
      width: 150,
      height: 80,
      overflow: 'hidden',
      borderRadius: 4,
      position: 'relative',
    },
    image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    detailContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      gap: 4,
      flex: 1,
    },
    detail__description: {
      display: 'flex',
      flexDirection: 'column',
      // alignItems: 'center',
      gap: 4,
    },
    iconContainer: {
      display: 'flex',
      gap: 12,
    },
    iconItem: {
      display: 'flex',
      gap: 6,
      alignItems: 'center',
    },
    iconItem__icon: {
      fontSize: 16,
    },
    detail__actions: {
      display: 'flex',
      gap: 12,
    },
    detail__actionContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 6,
    },
    actionBtn: {
      backgroundColor: '#212835',
      color: '#fff',
      '&.MuiButton-outlinedSecondary': {
        border: `1px solid ${theme.palette.primary.main} `,
      },
    },
    commentSectionContainer: {
      marginTop: theme.spacing(2),
    },
    noteTextAreaContainer: {
      marginTop: theme.spacing(2),
    },
    notePaper: {
      marginTop: theme.spacing(2),
    },
    noteLabel: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    commentSection__content: {
      padding: theme.spacing(1, 0),
    },
  }),
);
