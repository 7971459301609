import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function ClockOvertime(props: SvgIconProps) {
  return (
    <SvgIcon width="18" height="20" viewBox="0 0 18 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 0a.714.714 0 1 1 0 1.428h-.715v1.464a8.568 8.568 0 1 1-7.247 3.006L1.43 5.29l-.207.214A.717.717 0 1 1 .21 4.491l1.428-1.428a.717.717 0 0 1 1.014 1.014l-.214.207.607.607a8.497 8.497 0 0 1 4.812-2V1.429h-.714a.714.714 0 0 1 0-1.428zM8.57 4.284a7.14 7.14 0 1 0 0 14.28 7.14 7.14 0 0 0 0-14.28zm0 2.856c.395 0 .714.32.714.714v2.856l2.614 2.32a.714.714 0 1 1-.943 1.072L8.1 11.574a.714.714 0 0 1-.243-.535V7.854c0-.394.32-.714.714-.714z"
        fill="#E53737"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default ClockOvertime;
