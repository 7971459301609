import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function DocumentAdd(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 13 17" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="8qj0w5feua">
            <stop stop-color="#3E8BFF" offset="0%" />
            <stop stop-color="#3E8BFF" offset="100%" />
          </linearGradient>
        </defs>
        <path
          d="M7.024 0c.426.001.834.17 1.136.47l4.17 4.17c.294.306.462.711.47 1.136V14.4a1.6 1.6 0 0 1-1.6 1.6H1.6A1.6 1.6 0 0 1 0 14.4V1.6A1.6 1.6 0 0 1 1.6 0zm-.09 1.067H1.6a.533.533 0 0 0-.533.533v12.8c0 .295.238.533.533.533h9.6a.533.533 0 0 0 .533-.533V5.867h-3.2a1.6 1.6 0 0 1-1.6-1.6v-3.2zM6.4 6.933c.295 0 .533.24.533.534v1.6h1.6a.533.533 0 1 1 0 1.066h-1.6v1.6a.533.533 0 1 1-1.066 0v-1.6h-1.6a.533.533 0 1 1 0-1.066h1.6v-1.6c0-.295.238-.534.533-.534zM8 1.821v2.446c0 .294.239.533.533.533h2.446L8 1.82z"
          transform="translate(0 .5)"
          fill="url(#8qj0w5feua)"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default DocumentAdd;
