import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import {
  IAuditionScheduleRequestPayload,
  IAuditionScheduleResponsePayload,
  IAuditionScheduleUpdatePhaseAndStatusPayload,
} from 'shared/interfaces/IAuditionSchedule';
import { ICommonUpdateResponsePayload } from 'shared/interfaces/ICommon';
import { GET_AUDITION_SCHEDULES } from 'shared/queries/auditionSchedule.queries';

const { POST, PATCH } = useAxios();
export const auditionScheduleDao = () => {
  const getAuditionSchedules = async (payload: IAuditionScheduleRequestPayload) => {
    const response = await POST<IAuditionScheduleResponsePayload>({
      url: ENDPOINTS.GRAPHQL,
      data: {
        query: GET_AUDITION_SCHEDULES,
        variables: { ...payload },
      },
    });

    return response.data;
  };

  const updateAuditionScheduleStatusAndPhase = async (payload: IAuditionScheduleUpdatePhaseAndStatusPayload) => {
    const response = await PATCH<ICommonUpdateResponsePayload>({
      url: ENDPOINTS.AUDITION_SCHEDULES,
      data: payload,
    });
    return response.data;
  };

  return {
    getAuditionSchedules,
    updateAuditionScheduleStatusAndPhase,
  };
};
