import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    content: {
      maxWidth: 795,
      width: '100%',
      gap: '0.75em',
      display: 'flex',
      flexDirection: 'column',

      '& .MuiFormLabel-root': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        letterSpacing: '1px',
        alignItems: 'center',

        '& .MuiBox-root': {
          padding: '2px 4px 1px 5px',
          border: '1px solid',
          borderRadius: '100%',
          fontSize: '12px',
        },
      },

      [theme.breakpoints.down('md')]: {
        '& > div': {
          width: '100%!important',
        },
      },
    },
    icon__primary: {
      '& path': {
        fill: '#3e8bff',
      },
    },

    checkbox: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: '1em',
    },
    inputIcon: {
      '& svg': {
        width: '18px',
      },
      '& svg path': {
        fill: '#3e8bff',
      },
      '& .MuiSelect-iconOpen': {
        transform: 'unset',
      },
    },
    marginTopSm: {
      marginTop: '10px',
    },
    customIcon: {
      top: '13px !important',
      right: '13px !important',
    },
    customChip: {
      width: '100%',
      borderRadius: '5px',
      background: '#2e3540',
      border: 'none',
      justifyContent: 'space-between',

      '& .MuiChip-deleteIcon': {
        height: '15px',
        width: '20px',
      },
      '& svg path': {
        fill: '#d23636',
      },
    },
    checkboxes: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'wrap',
      justifyContent: 'flex-start',
      alignItems: 'center',
      '& > *:first-child': {
        marginRight: '15px',
      },
      '& .MuiTypography-body1': {
        fontSize: '14px',
      },
    },
    bypassCheckBoxCls: {
      '& .MuiFormControlLabel-root': {
        marginTop: 'unset !important',
      },
    },
    ciContainer: {
      '& .MuiFormControl-root': {
        marginBottom: '0px',
      },
    },
  }),
);
