import { Box, IconButton } from '@material-ui/core';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  StickyNoteBlueIconTwoTone,
  ShadedStartBlueIconTwoTone,
  FilledStarIcon,
  ITab,
  StickyNoteIconTwoTone,
  DotsIcon,
} from '@room-match/shared-ui-components';
import { CallBackButton } from 'components/CallBackButton';
import { NoteTextArea } from 'components/NoteTextArea';
import React, { useMemo, useState } from 'react';
import { NO_IMAGE_PLACEHOLDER_URL } from 'shared/constants/ASSETS_URL';
import { StyledTab, StyledTabs, Typography } from 'shared/elements';
import { IAuditionAttributes } from 'shared/interfaces/IAudition';
import { IComment } from 'shared/interfaces/utils/IComment';
import { getFullName } from 'shared/utils/getFullName';
import { CommentTab } from './CommentTab';
import {
  IAuditionScheduleAttributes,
  IAuditionScheduleUpdatePhaseAndStatusPayload,
} from 'shared/interfaces/IAuditionSchedule';
import { auditionScheduleService } from 'shared/services/auditionScheduleService';
import { AuditionStatus } from 'shared/enums/AuditionStatus';
import { AuditionPhase } from 'shared/enums/AuditionPhase';

import { useStyles } from './AuditionSingleComment.styles';

import { getMediaImagesPerGroup, getPrimaryImage } from 'shared/utils/auditionMediaAttachments';
import { a11yProps } from 'shared/utils/a11yProps';
type Props = {
  isEditMode?: boolean;
  publicNotesComments: IComment[];
  internalNotesComments: IComment[];
  onCreateNote: (rating: number, note: string, flagType?: 'Public Note' | 'Internal Note') => void;
  auditionSchedule: IAuditionScheduleAttributes;
  audition: IAuditionAttributes;

  groupId: string;
  sessionId: string;
  isDisabledComment?: boolean;
  isDisabledCallback?: boolean;
};
const { updateAuditionScheduleStatusAndPhase } = auditionScheduleService();
const AuditionSingleComment: React.FC<Props> = ({
  isEditMode,
  publicNotesComments,
  internalNotesComments,
  onCreateNote,
  auditionSchedule,
  audition,
  groupId,
  sessionId,
  isDisabledCallback,
  isDisabledComment,
}) => {
  const { projectId } = useParams() as { projectId: string };
  const queryClient = useQueryClient();
  const classes = useStyles();

  const [selectedTab, setSelectedTab] = useState<string>('public_notes');

  const { mutate: updateAuditionSchedulePhaseAndStatusMutate } = updateAuditionScheduleStatusAndPhase();

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (selectedTab !== newValue) {
      setSelectedTab(newValue);
    }
  };

  const onUpdateAudition = (auditionScheduleId: string, status: AuditionStatus, phase: AuditionPhase) => {
    const payload: IAuditionScheduleUpdatePhaseAndStatusPayload = {
      audition_schedules: [
        {
          id: auditionScheduleId,
          status,
          phase,
        },
      ],
    };
    updateAuditionSchedulePhaseAndStatusMutate(
      { payload },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['auditionScheduleGroup', sessionId], {
            refetchInactive: true,
          });
        },
      },
    );
  };

  const tabs = useMemo(() => {
    return [
      {
        name: 'internal_notes',
        header: (
          <Box sx={{ display: 'flex', alignItems: 'center', gridGap: 6 }}>
            <StickyNoteIconTwoTone style={{ fontSize: 16 }} />
            <Typography>Internal Notes</Typography>
          </Box>
        ),
      },
      {
        name: 'public_notes',
        header: (
          <Box sx={{ display: 'flex', alignItems: 'center', gridGap: 6 }}>
            <StickyNoteBlueIconTwoTone style={{ fontSize: 16 }} />
            <Typography>Public Notes</Typography>
          </Box>
        ),
      },
    ];
  }, []);

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

  return (
    <div className={classes.rightContainer}>
      {/* Talent Profie */}
      <div className={classes.expandedDiv__talentContainer}>
        <div className={classes.expandedDiv__imageContainer}>
          <img
            src={getPrimaryImage(
              auditionSchedule.audition,
              getMediaImagesPerGroup(auditionSchedule.medium_attachments, groupId),
            )}
            className={classes.expandedDiv__image}
          />
        </div>
        {/* Talent Profile Detail  */}
        <div className={classes.expandedDiv__detailContainer}>
          <div className={classes.expandedDiv__details}>
            <Typography fontWeight={500}>
              {getFullName(audition.profile?.talent?.first_name, audition.profile?.talent?.last_name)}
            </Typography>
            {/* Talent Profile Icons */}
            <div className={classes.expandedDiv__iconsContainer}>
              <div className={classes.expandedDiv__iconItem}>
                <StickyNoteBlueIconTwoTone classes={{ root: classes.expandedDiv__icon }} />
                <Typography fontSize={16}>{publicNotesComments.length > 0 ? publicNotesComments.length : 0}</Typography>
              </div>
              <div className={classes.expandedDiv__iconItem}>
                <ShadedStartBlueIconTwoTone classes={{ root: classes.expandedDiv__icon }} />
                <Typography fontSize={16}>
                  {publicNotesComments.length > 0 ? publicNotesComments[0].rating : 0}
                </Typography>
              </div>
              {!isDisabledCallback && (
                <CallBackButton
                  disabled={auditionSchedule.status === AuditionStatus.CALLBACK}
                  onClick={() => onUpdateAudition(auditionSchedule.id, AuditionStatus.CALLBACK, AuditionPhase.DECISION)}
                />
              )}

              <IconButton size="small">
                <FilledStarIcon fontSize="small" />
              </IconButton>
            </div>
          </div>

          <div style={{ alignSelf: 'center' }}>
            <IconButton size="small">
              <DotsIcon fontSize="small" />
            </IconButton>
          </div>
        </div>
      </div>
      {!isEditMode && (
        <div className={classes.noteTextAreaContainer}>
          <div className={classes.noteLabel}>
            <StickyNoteBlueIconTwoTone style={{ fontSize: 14 }} />
            <Typography fontSize={14}>Notes</Typography>
          </div>
          {!isDisabledComment && (
            <NoteTextArea
              classes={{ paper: classes.notePaper }}
              onSubmitForm={(rating, note) => onCreateNote(rating, note)}
            />
          )}
        </div>
      )}

      {/* Comment Section */}
      {isEditMode ? (
        <div className={classes.expandedDiv__commentSectionContainer}>
          <StyledTabs
            value={selectedTab}
            onChange={handleTabChange}
            aria-label="simple tabs example"
            variant="fullWidth"
          >
            {tabs.map((tab, index) => (
              <StyledTab
                key={index}
                label={tab.header}
                value={tab.name}
                {...a11yProps(index)}
                selected={selectedTab === tab.name}
              />
            ))}
          </StyledTabs>
          <div className={classes.noteTextAreaContainer}>
            <NoteTextArea
              isYellowStar={selectedTab === 'internal_notes'}
              onSubmitForm={(rating, note) =>
                onCreateNote(rating, note, selectedTab === 'internal_notes' ? 'Internal Note' : 'Public Note')
              }
            />
          </div>
          <div className={classes.expandedDiv__commentSection__content}>
            <TabPanel value={selectedTab} index="internal_notes">
              <CommentTab comments={internalNotesComments} isYellowStar />
            </TabPanel>
            <TabPanel value={selectedTab} index="public_notes">
              <CommentTab comments={publicNotesComments} />
            </TabPanel>
          </div>
        </div>
      ) : (
        <CommentTab comments={publicNotesComments} />
      )}
    </div>
  );
};

export default AuditionSingleComment;
