import { LoadingSpinnerIconTwoTone } from 'components/Icons';
import React from 'react';
import { LoadingBarContainer } from './LoadingBar.styles';

type Props = {
  classes?: {
    root?: string;
    icon?: string;
  };
};
const LoadingBar: React.FC<Props> = ({ classes }) => {
  return (
    <LoadingBarContainer className={classes?.root}>
      <LoadingSpinnerIconTwoTone style={{ fontSize: '3em' }} className={classes?.icon} />
    </LoadingBarContainer>
  );
};

export default LoadingBar;
