import { Box, FormControl, InputLabel, TextFieldProps } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
// import MuiPhoneInput from 'material-ui-phone-number';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';

import { Input } from '@room-match/shared-ui-components';
import { useStyles } from './ContactInput.styles';

function formatPhoneNumber(value: any) {
  if (!value) return value;

  const phoneNumber = value.replace(/[^\d]/g, '');

  const phoneNumberLength = phoneNumber.length;

  if (phoneNumberLength < 4) return phoneNumber;

  if (phoneNumberLength < 7) {
    return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3)}`;
  }

  return `(${phoneNumber.slice(0, 3)}) ${phoneNumber.slice(3, 6)}-${phoneNumber.slice(6, 10)}`;
}

type Props = {
  errorMessage?: string;
  handleCodeChange?: any;
  country?: string;
  value?: any;
  dropdownStyle: any;
  showLabel: boolean;
  labelTxt?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>, value?: string) => void;
} & Omit<TextFieldProps, 'onChange'>;
const ContactInput: React.FC<Props> = ({
  dropdownStyle = {},
  showLabel = false,
  errorMessage,
  handleCodeChange,
  country,
  value,
  labelTxt,
  onChange,
  ...props
}) => {
  const classes = useStyles();
  const dStyle = {
    width: '130px',
    ...dropdownStyle,
  };

  const [inputValue, setInputValue] = useState();

  const handleInput = (e: any) => {
    const formattedPhoneNumber = formatPhoneNumber(e.target.value);
    setInputValue(formattedPhoneNumber);
    if (onChange) {
      onChange(e, e.target.value);
    }
  };

  useEffect(() => {
    setInputValue(formatPhoneNumber(value));
  }, [value]);

  return (
    <FormControl fullWidth style={{ marginTop: 0 }}>
      {showLabel && (
        <InputLabel id="lblContactInput" shrink>
          {labelTxt ? `${labelTxt}` : 'Phone Number'}
        </InputLabel>
      )}
      <Box className={classes.inputContainer}>
        <PhoneInput
          specialLabel={''}
          country={country || 'us'}
          inputClass={classes.contactInput_class}
          containerClass={classes.contactContainer_class}
          containerStyle={dStyle}
          buttonClass={classes.contactButton_class}
          inputProps={{
            readOnly: true,
          }}
          onChange={(val, data) => {
            handleCodeChange(data);
          }}
          disabled={props.disabled}
        />
        <Input
          fullWidth
          InputProps={{ disableUnderline: true }}
          InputLabelProps={{ shrink: true }}
          style={{ flex: '1', marginTop: 0, marginBottom: 0 }}
          errorMessage={errorMessage}
          inputProps={{ maxLength: 14 }}
          {...props}
          // value={value}
          onChange={(e) => handleInput(e)}
          value={inputValue}
        />
      </Box>
    </FormControl>
  );
};

export default ContactInput;
