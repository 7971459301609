import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    requests__description: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-evenly',
      gap: 0,
      width: 200,
    },
    nameWrap: {
      textOverflow: 'ellipsis',
      maxWidth: 170,
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    requests__description__item: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      [theme.breakpoints.down('lg')]: {
        '& p': {
          fontSize: '13px !important',
        },
      },
    },
    requests__description__icon: {
      fontSize: '17px !important',
    },
    detail__description__textContainer: {
      display: 'flex',
      gap: 8,
    },
    popover: {
      pointerEvents: 'none',
    },
    paper: {
      padding: theme.spacing(1),
      border: '1px solid #3e8bff',
      borderRadius: '8px',
      backgroundImage: 'linear-gradient(180deg, #1d2834, #161e27)',
    },
    paperSmall: {
      backgroundImage: 'linear-gradient(180deg, #1d2834, #161e27)',
      border: '1px solid #222c39',
      borderRadius: '8px',
    },

    popoverContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 15px',
      gap: 10,

      '& > button': {
        padding: 5,
      },
    },

    popoverHeading: {
      marginBottom: 5,
    },
    ghosted: {
      '& path': {
        fill: '#6e767f !important',
      },
    },
  }),
);
