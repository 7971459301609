import { DialogContent, Grid } from '@material-ui/core';
import React, { useState, useMemo, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { StyledDialog } from 'shared/elements/StyledDialog';
import { useStyles } from './TalentProfile.styles';
import TalentProfileHeader from './TalentProfileHeader/TalentProfileHeader';
import TalentProfileMedia from './TalentProfileMedia/TalentProfileMedia';
import TalentDetails from './TalentDetails/TalentDetails';
import TalentSkillList from './TalentSkillList/TalentSkillList';
import TalentProfileDetails from './TalentProfileDetails/TalentProfileDetails';
import TalentProfileCard from './TalentProfileCard/TalentProfileCard';
import { ResumeMediaGallery } from 'components/ResumeMediaGallery';
import {
  onCloseImageGallery,
  onCloseResumeGallery,
  onCloseShareProfile,
  onCloseSizeCard,
  onCloseVideoGallery,
  onOpenShareProfile,
} from 'shared/redux/slicers/talentProfile.slicer';
import { useAppSelector } from 'shared/redux/store';
import { SizeCard } from 'components/SizeCard';
import ImageGallery from 'components/TalentGalleries/ImageGallery/ImageGallery';
import VideoGallery from 'components/TalentGalleries/VideoGallery/VideoGallery';
import { profileService } from 'shared/services/profileService';
import { auditionScheduleFlagService } from 'shared/services/auditionScheduleFlagService';
import { IMediumAttributes, IMedium } from 'shared/interfaces/IMedium';
import { navigateToRoute } from 'shared/utils/navigateToRoute';
import { LoadingBar } from '@room-match/shared-ui-components';
import { ShareProfileModal } from 'components/ShareProfileModal';

type Props = {
  data: any;
  open: boolean;
  auditionScheduleId: string;
  onClose: () => void;
};

const { getProfile, createProfileLink } = profileService();
const { getAuditionScheduleFlags } = auditionScheduleFlagService();
const TalentProfile: React.FC<Props> = ({ data, open, auditionScheduleId, onClose }) => {
  const classes = useStyles();
  const [videoViewType, setVideoViewType] = useState<string>('group');
  const [selectedVideos, setSelectedVideos] = useState<IMedium[]>([]);
  const [resumeMedia, setResumeMedia] = useState<IMediumAttributes[]>([]);
  const [profileLink, setProfileLink] = useState<string>('');

  const { isResumeGalleryOpen, isSizeCardOpen, isImageGalleryOpen, isVideoGalleryOpen, isShareProfileOpen } =
    useAppSelector(({ talentProfile }) => talentProfile);
  const dispatch = useDispatch();

  const { data: profileData, isLoading: isLoadingProfile } = getProfile(data.id);
  const { data: auditionFlagData } = getAuditionScheduleFlags(auditionScheduleId, {
    enabled: !!auditionScheduleId,
  });
  const { mutate: mutateCreateProfileLink, isLoading: isLoadingCreateProfileLink } = createProfileLink();

  const profile = useMemo(() => {
    return profileData ? profileData.data : null;
  }, [profileData]);

  const flags = useMemo(() => {
    return auditionFlagData && auditionFlagData.data.length > 0 ? auditionFlagData.data : [];
  }, [auditionFlagData]);

  const images = useMemo(() => {
    return profileData?.data.attributes.images.data
      ? profileData?.data.attributes.images.data.map((image) => ({
          ...image.attributes,
          file_height: image?.attributes?.medium?.data.attributes.file_height,
          file_width: image?.attributes?.medium?.data.attributes.file_width,
        }))
      : [];
  }, [profileData]);

  const videos = useMemo(() => {
    return profileData?.data.attributes.videos.data
      ? profileData?.data.attributes.videos.data.map((video) => ({
          ...video,
        }))
      : [];
  }, [profileData]);

  const handleResumeGalleryClose = () => {
    dispatch(onCloseResumeGallery());
  };

  const handleCloseSizeCard = () => {
    dispatch(onCloseSizeCard());
  };

  const handleCloseImageGallery = () => {
    dispatch(onCloseImageGallery());
  };

  const handleCloseVideoGallery = () => {
    dispatch(onCloseVideoGallery());
  };

  const handleCloseShareProfile = () => {
    dispatch(onCloseShareProfile());
  };

  const handleVideoViewType = (view: string) => {
    setVideoViewType(view);
    setSelectedVideos(videos);
  };

  const handleVideoDisplay = (video: IMedium) => {
    setSelectedVideos([video]);
  };

  const handleSetResumeMedia = (medias: IMediumAttributes[]) => {
    setResumeMedia(medias);
  };

  useEffect(() => {
    if (videos.length > 0) {
      setSelectedVideos(videos);
    }
  }, [videos]);

  const handleCreateProfileLink = () => {
    if (profile) {
      mutateCreateProfileLink(profile.id, {
        onSuccess: (response) => {
          const token = response.data.attributes.token;
          setProfileLink(`${window.location.origin.toString()}${navigateToRoute.toProfileLink(token)}/`);
          dispatch(onOpenShareProfile());
          // window.open(navigateToRoute.toProfileLink(token), '_blank');
        },
      });
    }
  };

  return (
    <StyledDialog
      open={open}
      onClose={onClose}
      // maxWidth="xl"
      // fullWidth
      // className={classes.profileDialog}
      classes={{ paper: classes.profileDialog_Container }}
      aria-labelledby="simple-dialog-title"
    >
      <DialogContent className={classes.dialogContaier}>
        {/* Header */}
        <TalentProfileHeader
          flags={flags}
          onClose={onClose}
          handleCreateProfileLink={handleCreateProfileLink}
          isLoadingCreateProfileLink={isLoadingCreateProfileLink}
        />
        {!isLoadingProfile ? (
          <React.Fragment>
            {/* Content */}
            <div className={classes.contentContainer}>
              <Grid container spacing={4}>
                {/* Talent Profile Media */}
                <Grid item lg={2} md={3}>
                  {profile && (
                    <TalentProfileMedia
                      profile={profile}
                      handleVideoViewType={handleVideoViewType}
                      handleVideoDisplay={handleVideoDisplay}
                    />
                  )}
                </Grid>
                {/* Talent Profile Details */}
                <Grid item lg={10} md={9}>
                  {/* Talent Details */}
                  {profile && <TalentDetails profile={profile.attributes} />}

                  {/* Cards */}
                  {profile && (
                    <TalentProfileCard profile={profile.attributes} handleSetResumeMedia={handleSetResumeMedia} />
                  )}
                  {/* Profile Details */}
                  {profile && <TalentProfileDetails profile={profile} handleSetResumeMedia={handleSetResumeMedia} />}
                  {/* Skills List */}
                  <TalentSkillList
                    skills={profileData?.data.attributes.skills || []}
                    handleSetResumeMedia={handleSetResumeMedia}
                  />
                </Grid>
              </Grid>
            </div>
          </React.Fragment>
        ) : (
          <div style={{ marginTop: 24 }}>
            <LoadingBar />
          </div>
        )}
      </DialogContent>
      {/* Resume Media Gallery */}
      <ResumeMediaGallery open={isResumeGalleryOpen} onClose={handleResumeGalleryClose} media={resumeMedia} />
      {profile && <SizeCard profile={profile.attributes} open={isSizeCardOpen} onClose={handleCloseSizeCard} />}

      <ImageGallery open={isImageGalleryOpen} onClose={handleCloseImageGallery} data={images} fit="contain" />
      <VideoGallery
        open={isVideoGalleryOpen}
        onClose={handleCloseVideoGallery}
        data={selectedVideos}
        view={videoViewType}
      />
      {profile && (
        <ShareProfileModal
          open={isShareProfileOpen}
          onClose={handleCloseShareProfile}
          profile={profile.attributes}
          profileLink={profileLink}
        />
      )}
    </StyledDialog>
  );
};

export default TalentProfile;
