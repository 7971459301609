import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      '&.paper': {
        minWidth: '85%',
        minHeight: '90%',
      },
      '& .MuiDialogContent-root': {
        padding: '30px 30px 10px 30px',
        minHeight: 'calc(100vh - 400px) !important',
      },
      '& .MuiDialog-paperWidthLg': {
        maxWidth: '1270px !important',
      },
    },
    heading: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      borderBottom: `1px solid ${theme.palette.primary.main}`,
      paddingBottom: 24,
    },
    heading__title: {
      display: 'flex',
      alignItems: 'center',
      gap: 12,
    },
    content: {
      padding: theme.spacing(2, 0),
    },
    titleContainer: {
      backgroundColor: '#1D232C',
      padding: theme.spacing(2),
      borderRadius: 4,
    },
    listContainer: {
      padding: theme.spacing(2, 0),
    },
    dialog__action: {
      padding: theme.spacing(2, 0),
    },
  }),
);
