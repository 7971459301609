import { IconButton, Menu, MenuItem } from '@material-ui/core';
import {
  ArchiveIcon,
  DotsIcon,
  DotsIconTwoTone,
  EditIconTwoTone,
  RemoveIconTwoTone,
  UserNewIconTwoTone,
  PlusIconTwoTone,
} from '@room-match/shared-ui-components';
import { RightArrowIcon } from 'components/Icons';
import React, { useState } from 'react';
import { useStyles } from './DotIcon.styles';
import { Typography } from 'shared/elements';
import { AddTalentToModal, MoveTalentToModal } from 'components';

const IconStyle: React.CSSProperties = {
  fontSize: 16,
};

const DotIcon = () => {
  const [isOpenAddTalentToModal, setIsOpenAddTalentToModal] = useState<boolean>(false);
  const [isOpenMoveTalentToModal, setIsOpenMoveTalentToModal] = useState<boolean>(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = useState<any>(null);

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleOpenAddTalentToModal = () => setIsOpenAddTalentToModal(true);
  const handleCloseAddTalentToModal = () => setIsOpenAddTalentToModal(false);
  const handleOpenMoveTalentToModal = () => setIsOpenMoveTalentToModal(true);
  const handleCloseMoveTalentToModal = () => setIsOpenMoveTalentToModal(false);

  return (
    <div className={classes.dotButtonContainer}>
      <IconButton size="small" onClick={handleClick}>
        <DotsIcon fontSize="small" />
      </IconButton>
      <Menu
        id="simple-menu"
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
        anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
        transformOrigin={{ vertical: 'top', horizontal: 'right' }}
        getContentAnchorEl={null}
        classes={{ paper: classes.menu }}
      >
        <MenuItem onClick={handleOpenAddTalentToModal} className={classes.menu__item}>
          <div className={classes.menuItem__container}>
            <PlusIconTwoTone style={IconStyle} />
            <Typography fontSize={13}>Add Talent To</Typography>
          </div>
        </MenuItem>
        <MenuItem onClick={handleOpenMoveTalentToModal} className={classes.menu__item}>
          <div className={classes.menuItem__container}>
            <RightArrowIcon style={IconStyle} />
            <Typography fontSize={13}>Move Talent To</Typography>
          </div>
        </MenuItem>
      </Menu>
      <AddTalentToModal open={isOpenAddTalentToModal} onClose={handleCloseAddTalentToModal} />
      <MoveTalentToModal open={isOpenMoveTalentToModal} onClose={handleCloseMoveTalentToModal} />
    </div>
  );
};

export default DotIcon;
