import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const DangerExclamationIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="17" viewBox="0 0 18 17" xmlns="http://www.w3.org/2000/svg" {...props}>
      <g fill-rule="nonzero" fill="none">
        <path
          d="M7.67 1.268.207 14.196a1.536 1.536 0 0 0 1.33 2.304h14.928a1.536 1.536 0 0 0 1.33-2.304L10.33 1.268a1.536 1.536 0 0 0-2.66 0z"
          fill="#EE404C"
        />
        <g transform="translate(8.035 5.255)" fill="#FFF7ED">
          <rect width="1.931" height="5.885" rx=".965" />
          <circle cx=".965" cy="8.21" r="1" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default DangerExclamationIconTwoTone;
