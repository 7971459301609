import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const QuestionMarkCircleFilledIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="1tk75ptjha">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M.955 16.75a36.129 36.129 0 0 0 0 16.5 21.195 21.195 0 0 0 15.794 15.795 36.127 36.127 0 0 0 16.502 0 21.195 21.195 0 0 0 15.794-15.794 36.125 36.125 0 0 0 0-16.502A21.195 21.195 0 0 0 33.251.955a36.13 36.13 0 0 0-16.502 0A21.195 21.195 0 0 0 .955 16.749zm26.172 17.158a1.994 1.994 0 1 1-3.989 0 1.994 1.994 0 0 1 3.99 0zm-5.85-14.492a3.723 3.723 0 1 1 7.446 0v.323a4.046 4.046 0 0 1-1.185 2.86l-3.666 3.666a1.596 1.596 0 0 0 2.256 2.257l3.666-3.666a7.236 7.236 0 0 0 2.12-5.117v-.323a6.914 6.914 0 1 0-13.828 0v1.33a1.596 1.596 0 0 0 3.191 0v-1.33z"
        fill="url(#1tk75ptjha)"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
};

export default QuestionMarkCircleFilledIcon;
