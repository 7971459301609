import { Button, DialogContent, Grid, IconButton } from '@material-ui/core';
import { StyledDialog, Typography } from '@room-match/shared-ui-components';
import { CloseIcon } from 'components/Icons';
import React from 'react';
import { NO_PROFILE_IMAGE_PLACEHOLDER_URL } from 'shared/constants/ASSETS_URL';

import { IProfileAttributes } from 'shared/interfaces/IProfile';
import { useStyles } from './SizeCard.styles';

type Props = {
  profile: IProfileAttributes;
  open: boolean;
  onClose: () => void;
};
const SizeCard: React.FC<Props> = ({ profile, open, onClose }) => {
  const classes = useStyles();
  return (
    <StyledDialog open={open} onClose={onClose} className={classes.dialog} maxWidth="md" fullWidth>
      <DialogContent>
        {/* Container */}
        <div className={classes.sizeCardContainer}>
          {/* Image */}
          <div className={classes.imageContainer}>
            <img src={profile.primary_image_url || NO_PROFILE_IMAGE_PLACEHOLDER_URL} className={classes.image} />
          </div>
          {/* Details */}
          <div className={classes.details}>
            {/* Header */}
            <div className={classes.details__header}>
              {/* Text */}
              <div>
                <Typography variant="heading" fontWeight={500} style={{ lineHeight: 1 }}>
                  {`${profile.talent.data.attributes.first_name} ${profile.talent.data.attributes.last_name}`}
                </Typography>
                <Typography color="mainGrey">SAG/AFTRA - Los Angeles</Typography>
              </div>
              {/* Close Button */}
              <IconButton onClick={onClose} size="small">
                <CloseIcon style={{ fontSize: 12 }} />
              </IconButton>
            </div>
            {/* Agency */}
            <div className={classes.details__agencyContainer}>
              <Typography>{profile.agency?.data?.attributes.name || '—'}</Typography>
            </div>
            {/* Description */}
            <div className={classes.details__description}>
              {/* Division */}
              <div className={classes.description__division}>
                <Typography fontSize={14} color="primary">
                  Commercial Divison
                </Typography>
                <Typography fontSize={12}>{profile.agency?.data?.attributes.contact_number || '—'}</Typography>
                <Typography fontSize={12}>
                  {profile.agency?.data?.attributes.country && profile.agency?.data?.attributes.state
                    ? `${profile.agency?.data?.attributes.country}, ${profile.agency?.data?.attributes.state} `
                    : '—'}
                </Typography>
              </div>
              {/* Rep */}
              <div className={classes.description__rep}>
                <Typography fontSize={14} color="primary">
                  Rep:
                  {profile.agency?.data?.attributes.contact_first_name &&
                  profile.agency?.data?.attributes.contact_last_name
                    ? `${profile.agency?.data?.attributes.contact_first_name} ${profile.agency?.data?.attributes.contact_last_name} `
                    : '—'}
                </Typography>
                <Typography fontSize={12}>{profile.agency?.data?.attributes.contact_number || '—'}</Typography>
                <Typography fontSize={12}>{profile.agency?.data?.attributes.website || '—'}</Typography>
              </div>
              {/* Logo */}
              {profile.agency?.data?.attributes.logo ? (
                <div className={classes.description__logo}>
                  <img src={profile.agency?.data?.attributes.logo} className={classes.description__logoImage} />
                </div>
              ) : (
                <Typography>{profile.agency?.data?.attributes.name || '—'}</Typography>
              )}
            </div>
            {/* Statistics */}
            <div className={classes.details__statistics}>
              <Grid container spacing={4}>
                {/* Left */}
                <Grid item lg={6}>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Height</Typography>
                    <Typography fontSize={13}>{profile.statistics?.height || '—'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Weight</Typography>
                    <Typography fontSize={13}>{profile.statistics?.weight || '—'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Hair Color</Typography>
                    <Typography fontSize={13}>{profile.statistics?.hair_color || '—'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Eye Color</Typography>
                    <Typography fontSize={13}>{profile.statistics?.eye_color || '—'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Coat Size</Typography>
                    <Typography fontSize={13}>{profile.statistics?.coat_size || '—'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Pants</Typography>
                    <Typography fontSize={13}>{profile.statistics?.pants_size || '—'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Chest</Typography>
                    <Typography fontSize={13}>{profile.statistics?.chest_size || '—'}</Typography>
                  </div>
                </Grid>

                {/* Right */}
                <Grid item lg={6}>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Waist</Typography>
                    <Typography fontSize={13}>{profile.statistics?.waist || '—'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Inseam Leg</Typography>
                    <Typography fontSize={13}>{profile.statistics?.inside_leg || '—'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Outseam Leg</Typography>
                    <Typography fontSize={13}>{profile.statistics?.outside_leg || '—'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Neck Size</Typography>
                    <Typography fontSize={13}>{profile.statistics?.neck_size || '—'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Sleeve Size</Typography>
                    <Typography fontSize={13}>{profile.statistics?.sleeves || '—'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Hat Size</Typography>
                    <Typography fontSize={13}>{profile.statistics?.hat || '—'}</Typography>
                  </div>
                  <div className={classes.statistics__item}>
                    <Typography fontSize={13}>Glove Size</Typography>
                    <Typography fontSize={13}>{profile.statistics?.gloves || '—'}</Typography>
                  </div>
                </Grid>
              </Grid>
            </div>
            {/* Generate */}
            <div className={classes.generateContainer}>
              <Button variant="contained" color="primary" fullWidth size="large">
                Download Sizes as PDF
              </Button>
            </div>
          </div>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

export default SizeCard;
