import { IProfileSkillGroup } from 'shared/interfaces/IProfile';

export const getActiveSkills = (data: IProfileSkillGroup[]): IProfileSkillGroup[] => {
  if (data.length <= 0) return [];

  const getActiveSkillsCategories = data.filter((skill) => {
    return skill.groups.filter((group) => group.skills.filter((s) => s.is_selected).length > 0).length > 0;
  });
  const getActiveSkillsGroups = getActiveSkillsCategories.map((c) => {
    const filtered = c.groups.filter((g) => g.skills.filter((s) => s.is_selected).length > 0);
    return {
      ...c,
      groups: filtered,
    };
  });
  const getActiveSkillSets = getActiveSkillsGroups.map((c) => {
    return {
      ...c,
      groups: c.groups.map((g) => {
        return {
          ...g,
          skills: g.skills.filter((s) => s.is_selected),
        };
      }),
    };
  });

  return getActiveSkillSets;
};
