import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Phone(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="bhjab8dowa">
            <stop stop-color="#3E8BFF" offset="0%" />
            <stop stop-color="#3E8BFF" offset="100%" />
          </linearGradient>
        </defs>
        <path
          d="M29.035 21.594c-.053-.044-6.042-4.33-7.667-4.049-.781.138-1.228.67-2.123 1.737a30.54 30.54 0 0 1-.759.876 12.458 12.458 0 0 1-1.651-.672 13.7 13.7 0 0 1-6.321-6.321 12.458 12.458 0 0 1-.672-1.651c.294-.269.706-.616.882-.764 1.061-.89 1.593-1.337 1.731-2.119.283-1.619-4.005-7.613-4.049-7.667A2.289 2.289 0 0 0 6.7 0C4.962 0 0 6.436 0 7.521c0 .063.091 6.467 7.988 14.5C16.012 29.909 22.416 30 22.479 30 23.564 30 30 25.038 30 23.3a2.287 2.287 0 0 0-.965-1.706zm-6.666 6.4c-.874-.072-6.248-.781-12.967-7.382C2.767 13.857 2.076 8.468 2.007 7.633a27.054 27.054 0 0 1 4.706-5.561c.04.04.093.1.161.178a35.391 35.391 0 0 1 3.574 6.063c-.32.323-.66.627-1.016.911-.552.42-1.059.897-1.512 1.422l-.243.34.072.411c.212.917.536 1.804.965 2.641a15.71 15.71 0 0 0 7.248 7.247c.837.43 1.724.754 2.641.966l.411.072.34-.243a10.117 10.117 0 0 0 1.428-1.518c.313-.374.732-.873.89-1.014a35.163 35.163 0 0 1 6.078 3.578c.083.07.141.124.18.159a27.031 27.031 0 0 1-5.561 4.707v.002z"
          fill="url(#bhjab8dowa)"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default Phone;
