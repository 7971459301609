import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    img: {
      opacity: 0,
      transition: 'all 10s ease',
    },

    loaded: {
      opacity: 1,
      transition: 'all 10s ease',
    },
  }),
);
