import { format } from 'date-fns';
import { useEffect, useLayoutEffect, useState } from 'react';

export const useClockTimer = () => {
  const [currentTime, setCurrentTime] = useState<string>('');

  const getTime = () => {
    const date = new Date();
    setCurrentTime(format(date, 'hh:mm a'));
  };

  useLayoutEffect(() => {
    getTime();
  }, []);

  useEffect(() => {
    const interval = setInterval(() => getTime(), 1000);
    return () => clearInterval(interval);
  }, []);

  return {
    currentTime,
  };
};
