import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    headerContainer: {},
    headerWrapper: {
      display: 'flex',
      gap: '1rem',
    },
    header__middle: {
      backgroundColor: theme.palette.customBlack.dark,
      padding: theme.spacing(0.25, 2),
      borderRadius: 4,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    header__center: {
      display: 'flex',
      alignItems: 'center',
      gap: 20,
    },
    cb: {
      backgroundColor: theme.palette.customBlack.main,
      padding: 2,
      borderRadius: 6,
      cursor: 'pointer',
    },
    paginationRoot: {
      gap: '20px !important',
    },
    shareProfileContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
    },
    rightIconsContainer: {},
    paperSmall: {
      backgroundImage: 'linear-gradient(180deg, #1d2834, #161e27)',
      border: '1px solid #222c39',
      // borderRadius: '8px',
    },
    popoverContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px',
      gap: 10,

      '& > button': {
        padding: 5,
      },
    },
    flagRed: {
      fontSize: 16,
      '& path': {
        fill: '#e53737',
      },
    },

    flagGreen: {
      fontSize: 16,
      '& path': {
        fill: '#43ba01',
      },
    },

    flagYellow: {
      fontSize: 16,
      '& path': {
        fill: '#e58f37',
      },
    },
  }),
);
