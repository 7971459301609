import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      color: '#c2ccd8',
      '& .MuiFormLabel-root': {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '13px',
        letterSpacing: '1px',
        alignItems: 'center',

        '& .MuiBox-root': {
          padding: '2px 4px 1px 5px',
          border: '1px solid',
          borderRadius: '100%',
          fontSize: '12px',
        },
      },

      '& .MuiInputBase-root': {
        '&input::placeholder': {
          color: '#6e767f',
          letterSpacing: '1px',
        },
      },
    },

    textAreaCls: {
      background: theme.palette.type === 'dark' ? '#0e1319' : '#FFFFFF',
      border: theme.palette.type === 'dark' ? 'solid 1px #2d343e' : 'solid 1px #d5dbe3',
      color: theme.palette.type === 'dark' ? '#FFFFFF' : 'default',
      width: '100%',
      fontSize: '14px',
      padding: '18.5px 14px',
      borderRadius: '4px',
      resize: 'none',
      outline: 'none',
      fontFamily: "'Roboto', sans-serif",
      '&:hover': {
        border: theme.palette.type === 'dark' ? 'solid 1px #FFFFFF' : 'solid 1px #0e1319',
      },
      '&:focus': {
        border: theme.palette.type === 'dark' ? 'solid 1px #3e8bff' : 'solid 1px #0e1319',
      },

      '& textarea::placeholder': {
        color: '#6e767f',
        letterSpacing: '1px',
        fontWeight: 700,
      },

      '& textarea::-webkit-input-placeholder': {
        color: '#6e767f',
        letterSpacing: '1px',
        fontWeight: 700,
      },

      '& textarea::-ms-input-placeholder': {
        color: '#6e767f',
        letterSpacing: '1px',
        fontWeight: 700,
      },
    },

    withLabel: {
      marginTop: '10px',
    },
  }),
);
