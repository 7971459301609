import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const CloseIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M1.334.229 8 6.895 14.666.229a.781.781 0 0 1 1.105 1.105L9.105 8l6.666 6.666a.781.781 0 1 1-1.105 1.105L8 9.105l-6.666 6.666a.78.78 0 0 1-1.275-.253.781.781 0 0 1 .17-.852L6.895 8 .229 1.334A.781.781 0 0 1 1.334.229z"
        fill={props.htmlColor || '#fff'}
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default CloseIcon;
