import { Button, DialogContent, IconButton } from '@material-ui/core';
import {
  ClapperBoardIcon,
  CloseIcon,
  DatePicker,
  DocumentIcon,
  FullBodyIcon,
  HeadshotIcon,
  MicrophoneIcon,
  StyledDialog,
  Switch,
  TextInput,
  TimePicker,
  Typography,
  VideoIcon,
} from '@room-match/shared-ui-components';
import clsx from 'clsx';
import { format } from 'date-fns';
import React from 'react';
import { COLORS } from 'shared/constants/COLORS';
import { TextareaAutosize } from 'shared/elements';
import { IRoleRequestSelfTapesRequirement } from 'shared/interfaces/IRole';
import { useSelfTapesRequirementForm } from './SelfTapesRequirementForm';
import { useStyles } from './SelfTapesRequirementsModal.styles';

type Props = {
  open: boolean;
  onClose: () => void;
  handleSubmitSelfTapesRequirement: (values: IRoleRequestSelfTapesRequirement) => void;
  selfTapesRequirement: IRoleRequestSelfTapesRequirement | null;
};
const SelfTapesRequirementsModal: React.FC<Props> = ({
  open,
  onClose,
  handleSubmitSelfTapesRequirement,
  selfTapesRequirement,
}) => {
  const classes = useStyles();

  const handleSubmit = (values: IRoleRequestSelfTapesRequirement) => {
    handleSubmitSelfTapesRequirement(values);
  };

  const { form } = useSelfTapesRequirementForm({ onSubmit: handleSubmit, selfTapesRequirement });

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent classes={{ root: classes.dialogContent }}>
        <div className={classes.closeButtonContainer}>
          <IconButton size="small" onClick={() => onClose()}>
            <CloseIcon style={{ fontSize: 12 }} />
          </IconButton>
        </div>
        <div className={classes.deadline}>
          <Typography color="primary" fontSize={14}>
            Deadline:
          </Typography>
          <div className={classes.deadline__buttonContainer}>
            <DatePicker
              componentType="button"
              onChooseDate={(value) => {
                const date = value as Date;

                form.setFieldValue('date', new Date(date.toDateString()));
              }}
              label={!form.values.date ? 'Select Date' : format(new Date(form.values.date), 'dd MMM yyyy')}
              classes={{ button: classes.datePickerButton }}
              value={form.values.date || new Date()}
              buttonProps={{ error: !!(form.touched.date && form.errors.date) }}
            />
            <TimePicker
              componentType="button"
              label={!form.values.time ? 'Select Time' : form.values.time}
              classes={{ button: classes.datePickerButton }}
              value={form.values.time || undefined}
              isShowTimezone
              timezoneValue={form.values.timezone}
              onChooseTime={(value, timezone) => {
                form.setFieldValue('timezone', timezone);
                return form.setFieldValue('time', value);
              }}
              buttonProps={{ error: !!(form.touched.time && form.errors.time) }}
            />
          </div>
        </div>

        <div className={classes.requirements}>
          <Typography color="primary" fontSize={14}>
            Requirements:
          </Typography>
          <div className={classes.requirements__list}>
            <div className={classes.requirements__item}>
              <div className={classes.requirements__item__leftContainer}>
                <HeadshotIcon />
                <Typography>Audition Selfie</Typography>
              </div>
              <Switch
                customVariant="customSwitch"
                disabled={false}
                toggle={(e) => {
                  return form.setFieldValue('audition_selfie', !e.target.checked);
                }}
                checked={form.values.audition_selfie}
              />
            </div>
            <div className={classes.requirements__item}>
              <div className={classes.requirements__item__leftContainer}>
                <FullBodyIcon />
                <Typography>Full Body</Typography>
              </div>
              <Switch
                customVariant="customSwitch"
                disabled={false}
                toggle={(e) => {
                  return form.setFieldValue('full_body', !e.target.checked);
                }}
                checked={form.values.full_body}
              />
            </div>
            <div className={classes.requirements__item}>
              <div className={classes.requirements__item__leftContainer}>
                <ClapperBoardIcon />
                <Typography>Slate</Typography>
              </div>
              <Switch
                customVariant="customSwitch"
                disabled={false}
                toggle={(e) => {
                  return form.setFieldValue('slate', !e.target.checked);
                }}
                checked={form.values.slate}
              />
            </div>

            {/* Other Images */}
            <div className={classes.requirements__itemExpandable}>
              <div
                className={clsx(classes.requirements__item__labelContainer, {
                  [classes.requirements__itemExpandable__expanded]: form.values.other_images.length > 0,
                })}
              >
                <div className={classes.requirements__item__leftContainer}>
                  <HeadshotIcon />
                  <Typography>Other Images</Typography>
                </div>
                <div className={classes.requirements__item__rightContainer}>
                  {form.values.other_images.length > 0 && (
                    <div className={classes.requirements__item__valueContainer}>
                      <Button
                        size="small"
                        classes={{ root: classes.requirements__item__actionButton }}
                        disabled={form.values.other_images.length <= 0}
                        onClick={() => {
                          form.setFieldValue('other_images', form.values.other_images.slice(0, -1));
                        }}
                      >
                        -
                      </Button>
                      <div className={classes.requirements__item__value}>
                        <Typography>{form.values.other_images.length}</Typography>
                      </div>
                      <Button
                        classes={{ root: classes.requirements__item__actionButton }}
                        onClick={() => {
                          form.setFieldValue('other_images', [...form.values.other_images, '']);
                        }}
                        disabled={form.values.other_images.length >= 5}
                      >
                        +
                      </Button>
                    </div>
                  )}

                  <Switch
                    customVariant="customSwitch"
                    disabled={false}
                    toggle={(e) => {
                      const value = !e.target.checked;

                      if (value) {
                        form.setFieldValue('other_images', ['']);
                      } else {
                        form.setFieldValue('other_images', []);
                      }
                    }}
                    checked={form.values.other_images.length > 0}
                  />
                </div>
              </div>
              {form.values.other_images.length > 0 && (
                <div className={classes.requirements__item__expandContainer}>
                  {form.values.other_images.map((video, index) => (
                    <TextInput
                      variant="outlined"
                      placeholder={`Other Images ${index + 1}`}
                      fullWidth
                      margin={'dense'}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ tabIndex: 1 }}
                      classes={{ root: classes.requirements__item__input }}
                      value={form.values.other_images[index]}
                      name={`other_images[${index}]`}
                      onChange={form.handleChange}
                    />
                  ))}
                </div>
              )}
            </div>
            {/* Video */}
            <div className={classes.requirements__itemExpandable}>
              <div
                className={clsx(classes.requirements__item__labelContainer, {
                  [classes.requirements__itemExpandable__expanded]: form.values.video.length > 0,
                })}
              >
                <div className={classes.requirements__item__leftContainer}>
                  <VideoIcon />
                  <Typography>Video</Typography>
                </div>
                <div className={classes.requirements__item__rightContainer}>
                  {form.values.video.length > 0 && (
                    <div className={classes.requirements__item__valueContainer}>
                      <Button
                        size="small"
                        classes={{ root: classes.requirements__item__actionButton }}
                        disabled={form.values.video.length <= 0}
                        onClick={() => {
                          form.setFieldValue('video', form.values.video.slice(0, -1));
                        }}
                      >
                        -
                      </Button>
                      <div className={classes.requirements__item__value}>
                        <Typography>{form.values.video.length}</Typography>
                      </div>
                      <Button
                        classes={{ root: classes.requirements__item__actionButton }}
                        onClick={() => {
                          form.setFieldValue('video', [...form.values.video, '']);
                        }}
                        disabled={form.values.video.length >= 5}
                      >
                        +
                      </Button>
                    </div>
                  )}

                  <Switch
                    customVariant="customSwitch"
                    disabled={false}
                    toggle={(e) => {
                      const value = !e.target.checked;

                      if (value) {
                        form.setFieldValue('video', ['']);
                      } else {
                        form.setFieldValue('video', []);
                      }
                    }}
                    checked={form.values.video.length > 0}
                  />
                </div>
              </div>
              {form.values.video.length > 0 && (
                <div className={classes.requirements__item__expandContainer}>
                  {form.values.video.map((video, index) => (
                    <TextInput
                      variant="outlined"
                      placeholder={`Video ${index + 1}`}
                      fullWidth
                      margin={'dense'}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ tabIndex: 1 }}
                      classes={{ root: classes.requirements__item__input }}
                      value={form.values.video[index]}
                      name={`video[${index}]`}
                      onChange={form.handleChange}
                    />
                  ))}
                </div>
              )}
            </div>
            {/* Audio */}
            <div className={classes.requirements__itemExpandable}>
              <div
                className={clsx(classes.requirements__item__labelContainer, {
                  [classes.requirements__itemExpandable__expanded]: form.values.audio.length > 0,
                })}
              >
                <div className={classes.requirements__item__leftContainer}>
                  <MicrophoneIcon />
                  <Typography>Audio</Typography>
                </div>
                <div className={classes.requirements__item__rightContainer}>
                  {form.values.audio.length > 0 && (
                    <div className={classes.requirements__item__valueContainer}>
                      <Button
                        size="small"
                        classes={{ root: classes.requirements__item__actionButton }}
                        disabled={form.values.audio.length <= 0}
                        onClick={() => {
                          form.setFieldValue('audio', form.values.audio.slice(0, -1));
                        }}
                      >
                        -
                      </Button>
                      <div className={classes.requirements__item__value}>
                        <Typography>{form.values.audio.length}</Typography>
                      </div>
                      <Button
                        classes={{ root: classes.requirements__item__actionButton }}
                        onClick={() => {
                          form.setFieldValue('audio', [...form.values.audio, '']);
                        }}
                        disabled={form.values.audio.length >= 5}
                      >
                        +
                      </Button>
                    </div>
                  )}

                  <Switch
                    customVariant="customSwitch"
                    disabled={false}
                    toggle={(e) => {
                      const value = !e.target.checked;

                      if (value) {
                        form.setFieldValue('audio', ['']);
                      } else {
                        form.setFieldValue('audio', []);
                      }
                    }}
                    checked={form.values.audio.length > 0}
                  />
                </div>
              </div>
              {form.values.audio.length > 0 && (
                <div className={classes.requirements__item__expandContainer}>
                  {form.values.audio.map((audio, index) => (
                    <TextInput
                      variant="outlined"
                      placeholder={`Audio ${index + 1}`}
                      fullWidth
                      margin={'dense'}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ tabIndex: 1 }}
                      classes={{ root: classes.requirements__item__input }}
                      value={form.values.audio[index]}
                      name={`audio[${index}]`}
                      onChange={form.handleChange}
                    />
                  ))}
                </div>
              )}
            </div>
            {/* Files */}
            <div className={classes.requirements__itemExpandable}>
              <div
                className={clsx(classes.requirements__item__labelContainer, {
                  [classes.requirements__itemExpandable__expanded]: form.values.files.length > 0,
                })}
              >
                <div className={classes.requirements__item__leftContainer}>
                  <DocumentIcon htmlColor={COLORS.PRIMARY} />
                  <Typography>File</Typography>
                </div>
                <div className={classes.requirements__item__rightContainer}>
                  {form.values.files.length > 0 && (
                    <div className={classes.requirements__item__valueContainer}>
                      <Button
                        size="small"
                        classes={{ root: classes.requirements__item__actionButton }}
                        disabled={form.values.files.length <= 0}
                        onClick={() => {
                          form.setFieldValue('files', form.values.files.slice(0, -1));
                        }}
                      >
                        -
                      </Button>
                      <div className={classes.requirements__item__value}>
                        <Typography>{form.values.files.length}</Typography>
                      </div>
                      <Button
                        classes={{ root: classes.requirements__item__actionButton }}
                        onClick={() => {
                          form.setFieldValue('files', [...form.values.files, '']);
                        }}
                        disabled={form.values.files.length >= 5}
                      >
                        +
                      </Button>
                    </div>
                  )}

                  <Switch
                    customVariant="customSwitch"
                    disabled={false}
                    toggle={(e) => {
                      const value = !e.target.checked;

                      if (value) {
                        form.setFieldValue('files', ['']);
                      } else {
                        form.setFieldValue('files', []);
                      }
                    }}
                    checked={form.values.files.length > 0}
                  />
                </div>
              </div>
              {form.values.files.length > 0 && (
                <div className={classes.requirements__item__expandContainer}>
                  {form.values.files.map((_, index) => (
                    <TextInput
                      variant="outlined"
                      placeholder={`File ${index + 1}`}
                      fullWidth
                      margin={'dense'}
                      InputLabelProps={{ shrink: true }}
                      inputProps={{ tabIndex: 1 }}
                      classes={{ root: classes.requirements__item__input }}
                      value={form.values.files[index]}
                      name={`files[${index}]`}
                      onChange={form.handleChange}
                    />
                  ))}
                </div>
              )}
            </div>
          </div>
          <div className={classes.requirements__container}>
            <TextareaAutosize
              label="Message for Selections"
              minRows={5}
              name="selections"
              value={form.values.selections}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
            />
          </div>
          <div className={classes.requirements__container}>
            <Button variant="contained" color="primary" fullWidth size="large" onClick={() => form.handleSubmit()}>
              Send Request
            </Button>
          </div>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

export default SelfTapesRequirementsModal;
