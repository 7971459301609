import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Sun(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="7a268scwya">
            <stop stopColor="#3E8BFF" offset="0%" />
            <stop stopColor="#3E8BFF" offset="100%" />
          </linearGradient>
        </defs>
        <path
          d="M8 13.27a.56.56 0 0 1 .55.55v1.63A.56.56 0 0 1 8 16a.56.56 0 0 1-.55-.55v-1.63a.56.56 0 0 1 .55-.55zm4.417-1.608.07.063 1.164 1.164a.537.537 0 0 1 0 .762.536.536 0 0 1-.687.063l-.075-.063-1.164-1.164a.537.537 0 0 1 0-.762.545.545 0 0 1 .692-.063zm-8.163.063a.536.536 0 0 1 .063.687l-.063.075L3.09 13.65a.537.537 0 0 1-.762 0 .536.536 0 0 1-.063-.687l.063-.075 1.164-1.164c.212-.212.571-.212.762 0zM8 3.979c1.1 0 2.116.465 2.836 1.185A3.947 3.947 0 0 1 12.021 8c0 1.1-.465 2.116-1.185 2.836A3.947 3.947 0 0 1 8 12.021c-1.1 0-2.116-.465-2.836-1.185A4.035 4.035 0 0 1 3.979 8c0-1.1.465-2.116 1.185-2.836A3.947 3.947 0 0 1 8 3.979zm0 1.1c-.804 0-1.545.318-2.074.847-.53.529-.847 1.27-.847 2.074 0 .804.318 1.545.847 2.074.529.53 1.27.847 2.074.847.804 0 1.545-.318 2.074-.847.53-.529.847-1.27.847-2.074 0-.804-.318-1.545-.847-2.074A2.927 2.927 0 0 0 8 5.079zm7.45 2.37A.56.56 0 0 1 16 8a.56.56 0 0 1-.55.55h-1.63a.56.56 0 0 1-.55-.55.56.56 0 0 1 .55-.55zm-13.27 0A.56.56 0 0 1 2.73 8a.56.56 0 0 1-.55.55H.55A.546.546 0 0 1 0 8a.56.56 0 0 1 .55-.55zm11.492-5.1a.536.536 0 0 1 .063.687l-.063.075-1.164 1.164c-.212.212-.571.212-.762 0a.536.536 0 0 1-.063-.687l.063-.075L12.91 2.35a.537.537 0 0 1 .762 0zM3.015 2.286l.075.063 1.164 1.164a.537.537 0 0 1 0 .762.536.536 0 0 1-.687.063l-.075-.063-1.164-1.164a.537.537 0 0 1 0-.762.536.536 0 0 1 .687-.063zM8 0a.56.56 0 0 1 .55.55v1.63a.56.56 0 0 1-.55.55.56.56 0 0 1-.55-.55V.55A.56.56 0 0 1 8 0z"
          fill="url(#7a268scwya)"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default Sun;
