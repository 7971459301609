import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      display: 'flex',
      alignItems: 'center',
    },
    button: {
      background: '#2e3540',
      padding: '5px 30px',
      height: 40,
    },
    image__container: {
      display: 'flex',
      alignItems: 'center',
      gap: '1.5em',
      marginBottom: 20,
    },
    image__button: {
      background: '#171c22',
      borderRadius: 5,
      border: '1px solid #3e8bff',
      height: 64,
      width: 64,
    },
    twoColumn: {
      display: 'flex',
      gap: '1em',
      '& > div': {
        flex: '50%',
      },

      [theme.breakpoints.down('md')]: {
        flexWrap: 'wrap',

        '& > div': {
          flex: '100%!important',
        },
      },
    },
  }),
);
