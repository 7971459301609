import { ISelectValue } from '@room-match/shared-ui-components';

export const CONTACT_INFO_TO: ISelectValue[] = [
  {
    key: 'Agents, managers and talent',
    value: 'Agents, managers and talent',
  },
  {
    key: 'Agents and managers',
    value: 'Agents and managers',
  },
  {
    key: 'Agents only',
    value: 'Agents only',
  },
];
