import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    request__rating: {
      maxWidth: 300,
      [theme.breakpoints.down('lg')]: {
        maxWidth: 200,
      },
    },
    request__rating__title: {
      fontSize: 14,
      [theme.breakpoints.down('lg')]: {
        fontSize: 13,
      },
    },
    request__rating__starsContainer: {
      marginTop: 4,
      display: 'flex',
      gap: 6,
    },
  }),
);
