import { Typography } from '@material-ui/core';
import React from 'react';
import { Link as RRDMLink } from 'react-router-dom';
import { useStyles } from './Link.styles';

type Props = {
  to: string;
  children: React.ReactNode;
};
const Link: React.FC<Props> = ({ children, ...props }) => {
  const classes = useStyles();
  return (
    <RRDMLink className={classes.link} {...props}>
      <Typography variant="subtitle1" className={classes.text}>
        {children}
      </Typography>
    </RRDMLink>
  );
};

export default Link;
