import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function RemoveIconTwoTone(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 19 20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M12.432 0c.746 0 1.352.606 1.352 1.351l-.001 1.757h4.325a.81.81 0 0 1 .805.71l.006.1a.81.81 0 0 1-.81.812l-1.15-.001-1.232 14.045a1.37 1.37 0 0 1-1.221 1.22l-.128.006H4.541a1.365 1.365 0 0 1-1.348-1.216L1.96 4.729H.81a.81.81 0 0 1-.804-.708L0 3.919a.81.81 0 0 1 .81-.81l4.325-.001V1.351c0-.701.537-1.28 1.222-1.345L6.487 0zm2.899 4.729H3.587l1.198 13.65h9.349l1.197-13.65zm-3.169-3.107H6.757l-.001 1.486h5.406V1.622z"
          fill="#D33535"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default RemoveIconTwoTone;
