import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const HeartFeedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="22" height="21" viewBox="0 0 22 21" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="c7qyj1etva">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M20.697 1.957A6.045 6.045 0 0 0 16.2 0c-1.297 0-2.486.41-3.532 1.22a7.227 7.227 0 0 0-1.428 1.49 7.223 7.223 0 0 0-1.428-1.49C8.766.41 7.577 0 6.28 0a6.045 6.045 0 0 0-4.497 1.957C.633 3.205 0 4.91 0 6.756c0 1.901.709 3.641 2.23 5.477 1.36 1.642 3.316 3.308 5.58 5.238.774.66 1.65 1.407 2.561 2.202a1.318 1.318 0 0 0 1.738 0c.91-.795 1.787-1.543 2.56-2.202 2.265-1.93 4.22-3.596 5.582-5.238 1.52-1.836 2.229-3.576 2.229-5.477 0-1.847-.633-3.551-1.783-4.799z"
        transform="translate(0 .5)"
        fill="url(#c7qyj1etva)"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default HeartFeedIcon;
