import { createStyles, makeStyles } from '@material-ui/core';

const ITEM_PADDING = '12px';
export const useStyles = makeStyles((theme) =>
  createStyles({
    requests__left: {
      display: 'flex',
      // gap: theme.spacing(1),
    },
    requests__action: {
      borderRight: '1px solid #2d343e',

      display: 'flex',
      // width: '86px',
      // alignItems: 'center',
    },
    requests__action__new: {
      display: 'flex',
      alignItems: 'center',
      // padding: '14px 24px',
      justifyContent: 'center',
      flex: 1,
      // [theme.breakpoints.down('lg')]: {
      //   padding: '14px 16px',
      // },
    },
    requests__action__review: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // padding: '14px 24px',
      flex: 1,
      // [theme.breakpoints.down('lg')]: {
      //   padding: '14px 16px',
      // },
    },
    requests__action__approved: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    requests__action__approved__dragIcon: {
      padding: '0 8px',
    },
    requests__action__approved__likeIcon: {
      backgroundColor: '#0b0f14',
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      justifyContent: 'center',
      height: '100%',
      //   minHeight: '100%',
      //   minWidth: '50px',
      borderLeft: '1px solid #2d343e',
      padding: '0 14px',
      minWidth: 56,
      // alignContent: 'center',
      // justifyContent: 'center',
      // justifySelf: 'center',
    },
    requests__action__rejected: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '14px 16px',
      justifyContent: 'center',
      [theme.breakpoints.down('lg')]: {
        padding: '14px 16px',
      },
    },
    request__videoPlayerContainer: {
      width: 180,
      height: 120,
      overflow: 'hidden',
      borderRadius: 4,
      position: 'relative',
    },
    request__videoPlayerThumbnail: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    request__videoPlayerButton: {
      position: 'absolute',
      right: 5,
      bottom: 5,

      zIndex: 999,
    },
    requests__detail: {
      padding: ITEM_PADDING,
      backgroundColor: '#0b0f14',
      flex: 1,
      display: 'flex',
      gap: 16,
    },
    requests__detail_left: {
      //   padding: '15.5px',
      padding: ITEM_PADDING,
      //   backgroundColor: '#0b0f14',
      // padding: '24px 14px 14px 14px',
      flex: 1,
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    requests__information: {
      display: 'flex',
      // justifyContent: 'space-between',
      // alignItems: 'center',
      gap: 24,
      flex: 1,
    },
    requests__description: {
      display: 'flex',
      gap: 15,

      '& > div': {
        flexDirection: 'column',

        '&:first-child': {
          gap: 5,
        },
      },
    },
    detail__description__textContainer: {
      display: 'flex',
      gap: 8,
    },
    requests__description__item: {
      display: 'flex',
      //   justifyContent: 'space-between',
      justifyContent: 'center',
      gap: 8,
    },
    requests__leftend__doticon: {
      display: 'flex',
      alignItems: 'center',
      //   justifyContent: 'center',
      //   gap: 2,
      padding: '0px',
    },
    requests__description__twoRow: {
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
      marginBottom: '2px',
    },
    requests__description__item__qa: {
      display: 'flex',
      gap: 7,
    },
    paperSmall: {
      backgroundImage: 'linear-gradient(180deg, #1d2834, #161e27)',
      border: '1px solid #222c39',
      borderRadius: '8px',
    },
    clientSelectionPopover: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 15px',
      gap: 10,

      '& > div': {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
      },

      '& button': {
        padding: 0,
        background: 'none',
        '&:hover': {
          background: 'none',
        },
      },
    },
  }),
);
