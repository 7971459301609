import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function QuestionCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 60 60" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M30 0c16.542 0 30 13.458 30 30S46.543 60 30.002 60v-1.116L30 60C13.458 60 0 46.542 0 30S13.458 0 30 0zm0 2.742C14.97 2.742 2.742 14.97 2.742 30S14.97 57.258 30 57.258h.002c15.029 0 27.256-12.228 27.256-27.259C57.258 14.97 45.03 2.742 30 2.742zm0 37.524a2.258 2.258 0 1 1 0 4.516 2.258 2.258 0 0 1 0-4.516zm0-26.246c4.546 0 8.245 3.7 8.245 8.246 0 4.08-2.978 7.476-6.874 8.131v4.547a1.371 1.371 0 0 1-2.742 0V29.14A1.37 1.37 0 0 1 30 27.77a5.509 5.509 0 0 0 5.503-5.503A5.51 5.51 0 0 0 30 16.762a5.51 5.51 0 0 0-5.503 5.504 1.37 1.37 0 1 1-2.743 0c0-4.547 3.7-8.246 8.246-8.246z"
          fill={props.htmlColor || '#6E767F'}
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default QuestionCircleIcon;
