import { unstable_createMuiStrictModeTheme as createTheme } from '@material-ui/core';

export const darkTheme = createTheme({
  palette: {
    type: 'dark',
    background: {
      default: '#0d1117',
    },
    primary: {
      main: '#3e8bff',
    },
    secondary: {
      main: '#10181f',
    },
    action: {
      hover: '#3e8bff',
    },
    customGrey: {
      light: '#c3ccd7',
      main: '#6e767f',
      dark: '#98a1ac',
      contrastText: '#fff',
    },
    customBlack: {
      dark: '#192029',
      main: '#0B1017',
      light: '#232C39',
      contrastText: '#fff',
    },
  },
  overrides: {
    MuiButton: {
      root: {
        textTransform: 'none',
        fontSize: 13,
        backgroundColor: '#0e1319',
      },
      outlinedPrimary: {
        color: '#fff',
      },
      text: {
        backgroundColor: 'transparent',
      },
    },
    MuiDialogContent: {
      // root: {
      //   overflow: 'hidden !important',
      // },
    },
  },
});
