import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function TwitterIconTwoTone(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M28 8.286c-.882.39-1.83.651-2.831.775a4.92 4.92 0 0 0 2.167-2.706 9.886 9.886 0 0 1-3.129 1.184A4.918 4.918 0 0 0 20.617 6c-2.72 0-4.924 2.185-4.924 4.88 0 .381.044.753.128 1.113A14.033 14.033 0 0 1 5.672 6.891a4.87 4.87 0 0 0 1.523 6.521 5.014 5.014 0 0 1-2.232-.61v.058c0 2.367 1.698 4.339 3.948 4.787a4.896 4.896 0 0 1-1.294.176c-.318 0-.628-.036-.928-.09a4.933 4.933 0 0 0 4.599 3.39 9.95 9.95 0 0 1-7.29 2.02c2.18 1.381 4.77 2.19 7.55 2.19 9.056 0 14.01-7.437 14.01-13.889 0-.211-.006-.422-.016-.63A9.688 9.688 0 0 0 28 8.286z"
        fill="#03A9F4"
      />
    </SvgIcon>
  );
}

export default TwitterIconTwoTone;
