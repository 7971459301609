import { Autocomplete } from '@material-ui/lab';
import moment from 'moment-timezone';
import React from 'react';
import { Input } from 'shared/elements';
import { InputProps } from 'shared/elements/Input/Input';

type Props = {
  value: string;
  onChange: (value: string) => void;
  fullWidth?: boolean;
  InputProps?: InputProps;
};
const TimezoneSelect: React.FC<Props> = ({ value, onChange, fullWidth, InputProps }) => {
  const mappedTimezones = moment.tz.names().map((tz) => {
    return {
      key: tz,
      value: tz,
    };
  });

  return (
    <Autocomplete
      options={mappedTimezones}
      clearOnBlur={false}
      getOptionLabel={(option) => option.key}
      fullWidth={fullWidth}
      getOptionSelected={(options, values) => options.value === values.value}
      noOptionsText="No Timezoneq"
      value={{
        value: value || '',
        key: mappedTimezones.find((v) => v.value === value)?.key || '',
      }}
      onChange={(e, record, reason) => {
        if (record) {
          if (reason === 'clear') {
            return onChange('');
          } else if (reason === 'select-option') {
            return onChange(record.value);
          }
        }
      }}
      renderInput={(params) => (
        <Input
          {...params}
          type="text"
          variant="outlined"
          autoComplete="none"
          value={value}
          inputProps={{
            ...params.inputProps,
            autocomplete: 'none',
            form: {
              autocomplete: 'none',
            },
          }}
          {...InputProps}
        />
      )}
    />
  );
};

export default TimezoneSelect;
