import { Slider, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const HEIGHT = 6;
export const StyledSlider = withStyles((theme: Theme) => ({
  root: {
    // marginRight: -28,
    height: HEIGHT,
  },
  thumb: {
    height: 24,
    width: 24,
    backgroundColor: theme.palette.primary.main,
    borderRadius: 8,
    padding: 0,
    marginTop: -10,
    marginLeft: -12,
    marginRight: -24,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: HEIGHT,
    borderRadius: 8,
  },
  rail: {
    height: HEIGHT,
    borderRadius: 8,
    backgroundColor: theme.palette.customBlack.main,
  },
}))(Slider);
