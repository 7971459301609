import { makeStyles, createStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      '&.paper': {
        minWidth: '85%',
        minHeight: '90%',
      },
      '& .MuiDialog-paperWidthSm': {
        maxWidth: '585px !important',
      },
    },
    closeIcon: {
      position: 'absolute',
      top: '20px',
      right: '20px',
      cursor: 'pointer',
    },
    btnCls: {
      height: '50px',
      background: '#3e8bff',
    },
    flagBody: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: '30px 30px 40px 30px',
      '& > *': {
        width: '100%',
      },
      '& > *:not(:last-child)': {
        marginBottom: '15px',
      },
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'nowrap',
    },
    headerDp: {
      background: '#808080',
      height: '60px',
      width: '45px',
      borderRadius: '5px',
      marginRight: '20px',
    },
    headerText: {
      '& > *:last-child': {
        marginTop: '3px',
        color: '#6e767f',
      },
    },
    request__action: {
      display: 'flex',
      gap: 10,
      margin: '10px 0px 20px 0px !important',
    },
    request__actionIconButton: {
      fontSize: 18,
      minWidth: 'auto',
      minHeight: 'auto',
      padding: '16px 16.9px',
      lineHeight: 1,
      border: '2px solid rgba(255, 255, 255, 0.23)',
      borderRadius: 8,
    },
    request__actionButton__active: {
      borderColor: '#3e8bff',
    },
  }),
);
