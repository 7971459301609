import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    instaFeedContainer: {
      maxHeight: '100%',
      overflow: 'auto',
      padding: '0px 10px 0px 10px',
    },
    instaFeedImage: {
      opacity: 1,
      display: 'block',
      width: '100%',
      height: 'auto',
      transition: '0.5s ease',
      backfaceVisibility: 'hidden',
    },
    instaFeedMiddle: {
      display: 'flex',
      alignItems: 'center',
      gap: theme.spacing(1),
      transition: '0.5s ease',
      opacity: 0,
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
    },
    instaFeedListContainer: {
      marginTop: '-15px',
    },
    instaFeedListItem: {
      width: '100%',
      height: 'auto',
      overflow: 'hidden',
      borderRadius: 4,
      position: 'relative',
      cursor: 'pointer',
      '&:hover $instaFeedImage': {
        opacity: 0.2,
      },
      '&:hover $instaFeedMiddle': {
        opacity: 1,
      },
    },
    instaFeedText: {
      fontColor: '#fff',
      fontSize: 14,
      fontFamily: 'Inter',
      fontStretch: 'normal',
      fontStyle: 'normal',
    },
  }),
);
