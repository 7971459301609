import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const SuccessIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 70 51" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="100%" y1="24.111%" x2="0%" y2="75.889%" id="xypidf3eha">
          <stop stop-color="#D51BFF" offset="0%" />
          <stop stop-color="#00D4FF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M68.373 1.626a5.552 5.552 0 0 0-7.851 0L25.185 36.964 9.48 21.26a5.552 5.552 0 1 0-7.852 7.853l19.63 19.63a5.536 5.536 0 0 0 3.927 1.628 5.533 5.533 0 0 0 3.927-1.627L68.373 9.479a5.552 5.552 0 0 0 0-7.853z"
        fill="url(#xypidf3eha)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default SuccessIcon;
