import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    profileDialog: {
      '&.paper': {
        minWidth: '85%',
        minHeight: '90%',
      },

      '& .MuiDialog-paperWidthSm': {
        width: '100%',
        maxWidth: '606px !important',
      },

      '& .MuiDialogContent-root': {
        padding: '60px 40px 30px',

        [theme.breakpoints.down(730)]: {
          padding: '60px 30px 30px',
        },
      },
    },

    container: {
      display: 'flex',
      flexDirection: 'column',
      gap: '20px',

      '& .MuiButton-root': {
        fontSize: '14px',
        color: '#fff',
        width: '100%',
        padding: '15px 30px',
        height: '50px',
      },

      //   '& .MuiFormLabel-root': {
      //     display: 'flex',
      //     justifyContent: 'space-between',
      //     fontSize: '13px',
      //     letterSpacing: '1px',
      //     alignItems: 'center',

      //     '& .MuiBox-root': {
      //       padding: '2px 4px 1px 5px',
      //       border: '1px solid',
      //       borderRadius: '100%',
      //       fontSize: '12px',
      //     },
      //   },

      '& .MuiInputBase-root': {
        // height: '50px !important',
        overflow: 'hidden',
        fontSize: '14px !important',
      },

      '& .MuiSelect-iconOutlined': {
        right: '0',
        top: '22px',
      },

      '& .MuiSelect-iconOpen': {
        top: '2px',
        right: '13px',
      },
    },

    closeIcon: {
      position: 'absolute',
      top: '20px',
      right: '20px',
      cursor: 'pointer',
    },

    header: {
      textAlign: 'center',
    },

    form: {
      display: 'flex',
      flexDirection: 'column',
      gap: 10,

      '& .MuiFormControl-marginNormal': {
        marginTop: 12,
      },
    },

    textGrid: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: '10px',
      fontWeight: 500,
    },

    twoColumn: {
      display: 'flex',
      gap: 15,

      '& > div': {
        flex: '49%',
      },
    },

    editButton: {
      background: '#0e1319',
      border: '1px solid #2d343e',

      '& .MuiButton-startIcon': {
        marginTop: '-2px',
      },
      '&:hover': {
        background: '#151a21',
        borderColor: '#3e8bff',

        '& svg path': {
          transition: 'background-color 250ms',
        },
      },
    },

    saveButton: {
      marginTop: 20,
      background: '#3e8bff',

      '&:hover': {
        backgroundColor: 'rgb(43, 97, 178)',
      },
    },
    anchorState: {
      fontWeight: 500,
      '&:hover': {
        cursor: 'pointer',
        textDecoration: 'underline',
      },
    },
  }),
);
