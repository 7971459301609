import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      zIndex: 9999,
      position: 'relative',
    },
    header__artist: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
    },
    imageContainer: {
      // minHeight: 550,
      marginTop: theme.spacing(2),
      // paddingBottom: 100,
      // maxWidth: 550,
      minHeight: 550,
      // backgroundColor: 'red',
    },
    imageList: {
      display: 'flex',
      width: '100%',
      gap: 8,
      overflow: 'auto',
      paddingBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
      '&::-webkit-scrollbar': {
        width: 6,
        height: 6,
      },
    },
    imageList__item: {},
    imageList__img: {
      width: '100%',
      objectFit: 'cover',
      borderRadius: 4,
      border: '2px solid transparent',
    },
    imageList__img__selected: {
      border: `2px solid ${theme.palette.primary.main}`,
    },
  }),
);
