import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function DoubleArrowRightIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="10" height="14" viewBox="0 0 10 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m5.47.334 4.25 5.985c.275.389.275 1.017 0 1.406l-4.25 5.986c-.133.184-.31.288-.497.289-.287.002-.548-.24-.658-.615-.11-.374-.049-.805.155-1.09l3.755-5.278L4.47 1.74c-.24-.396-.224-.986.038-1.354.261-.369.68-.391.961-.052zM1.213.294 5.46 6.292c.274.39.274 1.02 0 1.41L1.214 13.7c-.132.19-.313.299-.502.3-.288.002-.548-.241-.658-.616-.11-.375-.049-.808.155-1.094l3.75-5.288L.21 1.714C-.068 1.322-.068.686.21.294c.278-.392.728-.392 1.005 0z"
        fill="#FFF"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default DoubleArrowRightIcon;
