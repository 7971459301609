import { Box, Button, DialogContent, Grid, IconButton, Link, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CloseIcon, CopyIconTwoTone } from '@room-match/shared-ui-components';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { exportComponentAsJPEG } from 'react-component-export-image';
import { QRCode } from 'react-qrcode-logo';

import useCopyClipboard from 'react-use-clipboard';
import { CONFIG } from 'shared/config';
import { StyledDialog, Typography } from 'shared/elements';
import { IProjectAttributes } from 'shared/interfaces/IProject';

import { StyledATag, useStyles } from './SelfTapesQRModal.styles';

type Props = {
  open: boolean;
  onClose: () => void;
  project: IProjectAttributes;
};
const SelfTapesQRModal: React.FC<Props> = ({ open, onClose, project }) => {
  const classes = useStyles();
  const qrRef = useRef<HTMLDivElement | null>(null);
  const URL = `${CONFIG.TALENT_APP_URL}/go/self-tapes/${project.id}`;
  const [isCopiedLinkQR, setIsCopiedLinkQR] = useCopyClipboard(URL, { successDuration: 1000 });

  const exportQr = () => {
    if (qrRef.current) {
      exportComponentAsJPEG(qrRef, {
        fileName: `${project.name}- Self-tapes QR`,
      });
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent classes={{ root: classes.qrModal__dialogContent }}>
        <Box className={classes.qrModal__container}>
          {/* Header  */}
          <Box className={classes.qrModal__header}>
            <Typography fontSize={25}>Self Tape Link / QR Code & Instructions</Typography>
            <IconButton size="small" onClick={() => onClose()}>
              <CloseIcon style={{ fontSize: 14 }} />
            </IconButton>
          </Box>
          {/* Content */}
          <Box className={classes.qrModal__content}>
            {/* Qr */}
            <Grid container spacing={2}>
              <Grid item xl={3} lg={3}>
                <Box className={classes.qrModal__qrContainer}>
                  <div className={classes.qrModal__imageContainer} ref={qrRef}>
                    <QRCode value={URL} logoImage="/casting-logo.png" removeQrCodeBehindLogo ecLevel="H" size={170} />
                  </div>
                  <Button variant="contained" color="primary" fullWidth onClick={() => exportQr()}>
                    Download QR Code
                  </Button>
                </Box>
              </Grid>
              {/* Right Content */}
              <Grid item xl={9} lg={9}>
                <Box className={classes.qrModal__rightContent}>
                  {/* Link  */}
                  <Box className={clsx(classes.qrModal__linkContainer, classes.qrModal__link)}>
                    <IconButton size="small" onClick={() => setIsCopiedLinkQR()}>
                      <CopyIconTwoTone style={{ fontSize: 14 }} />
                    </IconButton>
                    <StyledATag href={URL} target="_blank">
                      {URL}
                    </StyledATag>
                  </Box>
                  {/* Text Detail */}
                  <Box className={classes.qrModal__centerTextContainer}>
                    <Typography fontSize={14}>
                      If you are sharing this link with talent to complete their self tape here is some suggested text
                      below:
                    </Typography>
                  </Box>
                  <Box className={clsx(classes.qrModal__linkContainer, classes.qrModal__detailContainer)}>
                    <IconButton size="small" onClick={() => setIsCopiedLinkQR()}>
                      <CopyIconTwoTone style={{ fontSize: 14 }} />
                    </IconButton>
                    <Box className={classes.qrModal__detailTextContainer}>
                      <Typography fontSize={14}>
                        Please click the link below before you take any action for instructions on submitting your self
                        tape and answering relevant questions.
                      </Typography>
                      <StyledATag href={URL} target="_blank">
                        {URL}
                      </StyledATag>
                      <Typography fontSize={14}>
                        and follow the steps to complete your self tape process for this project, including answering
                        questions and checking your profile and submitting media....
                      </Typography>
                    </Box>
                  </Box>
                </Box>
              </Grid>
            </Grid>
          </Box>
        </Box>
      </DialogContent>
      <Snackbar
        open={isCopiedLinkQR}
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="success">Copied to Clipboard</Alert>
      </Snackbar>
    </StyledDialog>
  );
};

export default SelfTapesQRModal;
