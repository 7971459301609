import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      fontSize: 11,
      minWidth: 'auto',
      padding: '1px 3px',
      borderRadius: 8,
      backgroundColor: '#293440',
    },
  }),
);
