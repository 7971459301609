import { SelfTapesTab } from 'shared/enums/SelfTapesTab';

export const REQUEST_TAB_LIST = [
  SelfTapesTab.NEW,
  SelfTapesTab.HIDDEN,
  SelfTapesTab.CD_APPROVAL,
  SelfTapesTab.APPROVED,
];

export const ACTION_TAB_LIST = [
  SelfTapesTab.REQUESTED,
  SelfTapesTab.CONFIRMED,
  SelfTapesTab.DECLINED,
  SelfTapesTab.NO_RESPONSe,
];
