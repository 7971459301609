import React from 'react';
import { Grid } from '@material-ui/core';
import { CommentFeedIcon, HeartFeedIcon } from 'components/Icons';
// import { HeartIcon } from '@room-match/shared-ui-components';
import { Typography } from 'shared/elements';
import { useStyles } from './InstagramFeedContent.styles';

const InstagramFeeds = () => {
  const classes = useStyles();
  return (
    <div className={classes.instaFeedContainer}>
      {/* Post Feed List */}
      <div className={classes.instaFeedListContainer}>
        <Grid container spacing={2} style={{ marginTop: 12, marginBottom: 2 }}>
          {Array.from({ length: 8 }).map((_, i) => (
            <Grid item lg={3} key={i}>
              <div className={classes.instaFeedListItem}>
                <img src="/leonardo-dicarpio.jpg" className={classes.instaFeedImage} />
                <div className={classes.instaFeedMiddle}>
                  <HeartFeedIcon style={{ fontSize: 18 }} />
                  <Typography className={classes.instaFeedText}>2,483</Typography>
                  <CommentFeedIcon style={{ fontSize: 18 }} />
                  <Typography className={classes.instaFeedText}>30</Typography>
                </div>
              </div>
            </Grid>
          ))}
        </Grid>
      </div>
    </div>
  );
};

export default InstagramFeeds;
