import { FormikProps, useFormik } from 'formik';
import { ISignInRequestPayload } from 'shared/interfaces/IUser';
import { object, SchemaOf, string } from 'yup';

type Props = {
  onSubmit: (values: ISignInRequestPayload) => void;
};
export const useLoginClientForm = ({ onSubmit }: Props) => {
  const validationSchema: SchemaOf<ISignInRequestPayload> = object().shape({
    user: object({
      email: string().email('Email must be a valid email').required('Email is required'),
      password: string().required('Password is required'),
    }),
  });
  const initialValues: ISignInRequestPayload = {
    user: {
      email: '',
      password: '',
    },
  };

  const form: FormikProps<ISignInRequestPayload> = useFormik({
    validationSchema,
    initialValues,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  return {
    form,
  };
};
