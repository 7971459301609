import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const YoutubeIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="19" height="14" viewBox="0 0 19 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M15.665 0a2.706 2.706 0 0 1 2.703 2.659v8.638A2.706 2.706 0 0 1 15.71 14H2.703A2.706 2.706 0 0 1 0 11.341V2.703A2.706 2.706 0 0 1 2.659 0h13.006zm0 1.11H2.703c-.87 0-1.579.7-1.593 1.567v8.62c0 .87.7 1.579 1.567 1.593h12.988c.87 0 1.579-.7 1.593-1.567v-8.62c0-.878-.714-1.593-1.593-1.593zm-7.81 2.727.012.007 4.297 2.685a.555.555 0 0 1 .01.935l-.01.007-4.297 2.685a.555.555 0 0 1-.849-.458V4.315c0-.198.105-.38.275-.48l.01-.006.013-.006a.555.555 0 0 1 .54.014zm.273 1.479v3.368L10.822 7 8.128 5.316z"
        fill="#FFF"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default YoutubeIcon;
