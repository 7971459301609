import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const FilledStarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.152 6.046a.886.886 0 0 0-.764-.61l-4.818-.438L9.664.538A.888.888 0 0 0 8.032.54L6.126 5l-4.82.437a.887.887 0 0 0-.503 1.553l3.643 3.194-1.074 4.732a.887.887 0 0 0 1.32.958l4.156-2.484 4.155 2.484a.888.888 0 0 0 1.32-.959l-1.073-4.73 3.642-3.195a.888.888 0 0 0 .26-.943z"
        fill={props.htmlColor || '#646C75'}
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default FilledStarIcon;
