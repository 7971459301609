import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const PriceTagIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M14.333 0h-4.228c-.802 0-1.556.312-2.122.879L.569 8.293A1.955 1.955 0 0 0 0 9.668c0 .51.207 1.01.569 1.372l4.392 4.392c.36.36.86.568 1.374.568.51 0 1.01-.207 1.372-.569l7.414-7.414A2.98 2.98 0 0 0 16 5.895V1.667C16 .747 15.253 0 14.333 0zm-2 5.333c-.919 0-1.666-.747-1.666-1.666 0-.92.747-1.667 1.666-1.667.92 0 1.667.747 1.667 1.667 0 .919-.747 1.666-1.667 1.666z"
        fill="#677482"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default PriceTagIcon;
