import { createStyles, makeStyles, Theme } from '@material-ui/core';
interface Props {
  bannerImage?: string;
}
export const useStyles = makeStyles<Theme, Props>((theme) =>
  createStyles({
    bodyContainer: {
      backgroundSize: 'cover',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '15px',
      minHeight: '100vh',
      '& .MuiOutlinedInput-input:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0 100px #0d1117 inset',
      },
    },
    backgroundContainer: (props) => ({
      top: 0,
      width: '100%',
      height: 'calc(100vh - 60%)',
      display: 'flex',
      zIndex: -1,
      overflow: 'hidden',
      position: 'fixed',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      backgroundImage: `url(${props.bannerImage})`,
      backgroundSize: 'auto 100%',
      backgroundRepeat: 'repeat',
      backgroundPosition: 'center',
    }),
    bgImage: {
      height: 'calc(100vh - 60%)',
      width: 'auto',
    },
    bgImageOverlay: {
      width: '100%',
      height: 'calc(100vh - 69%)',
      position: 'absolute',
      backgroundImage: 'url(/background-overlay.svg)',
      backgroundRepeat: 'repeat',
      backgroundSize: 'auto calc(100vh - 60%)',
    },
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      width: '420px',
      padding: '15px 30px',
      background: 'none !important',
      boxShadow: 'none !important',
      height: 'calc(100vh - 30px)',
      [theme.breakpoints.down(500)]: {
        width: '100%',
      },
      '& fieldset': {
        border: 'solid 1px #2d343e',
      },
    },
    header: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    logoContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      height: '210px',
      maxWidth: '420px',
      paddingBottom: '25px',
    },
    logoCls: {
      height: '100%',
      width: '100%',
      objectFit: 'contain',
    },
    clientDetails: {
      width: '100%',
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      marginBottom: '20px',
      '& > *': {
        textAlign: 'center',
      },
    },
    childrenContainer: {
      maxWidth: '420px',
      width: '100%',
      marginLeft: 'auto',
      marginRight: 'auto',
    },
    poweredBy: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'center',
      marginTop: 'auto',
      paddingTop: '20px',
      marginBottom: '-15px',
      '& > *:first-child': {
        marginRight: '10px',
      },
    },
  }),
);
