import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const Printer = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="qfu1opp1ga">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M12.914 0C13.513 0 14 .487 14 1.086V4.2l-.001.01v.02a.19.19 0 0 1-.003.019h.918c.599 0 1.086.487 1.086 1.086v5.828c0 .599-.487 1.086-1.086 1.086H12.25v2.664c0 .599-.487 1.086-1.086 1.086H4.836a1.087 1.087 0 0 1-1.086-1.086V12.25H1.086A1.087 1.087 0 0 1 0 11.164V5.336C0 4.737.487 4.25 1.086 4.25h.918a.37.37 0 0 0-.002-.02v-.02L2 4.2 2 4.063V1.086C2 .487 2.487 0 3.086 0zm-1.75 9.75H4.836a.086.086 0 0 0-.086.086v5.078c0 .048.038.086.086.086h6.328a.086.086 0 0 0 .086-.086V9.836a.086.086 0 0 0-.086-.086zM9.5 13a.5.5 0 0 1 0 1h-3a.5.5 0 0 1 0-1h3zm0-2.25a.5.5 0 0 1 0 1h-3a.5.5 0 0 1 0-1h3zm5.414-5.5H1.086A.086.086 0 0 0 1 5.336v5.828c0 .048.038.086.086.086H3.75V9.836c0-.599.487-1.086 1.086-1.086h6.328c.599 0 1.086.487 1.086 1.086v1.414h2.664a.086.086 0 0 0 .086-.086V5.336a.086.086 0 0 0-.086-.086zM13.5 6a.5.5 0 0 1 0 1h-.75a.5.5 0 0 1 0-1h.75zm-.586-5H3.086A.086.086 0 0 0 3 1.086v3.078c0 .048.038.086.086.086h9.828A.086.086 0 0 0 13 4.164V1.086A.086.086 0 0 0 12.914 1z"
        fill="url(#qfu1opp1ga)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default Printer;
