import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function CommentsFeed(props: SvgIconProps) {
  return (
    <SvgIcon width="22" height="21" viewBox="0 0 22 21" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="jf1rj8e4la">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M9.587.009A10 10 0 0 1 19.98 9.383v6.455A4.167 4.167 0 0 1 15.818 20h-5.813A10 10 0 0 1 9.587.009zm.421 1.676a8.8 8.8 0 0 0-1.082.068A8.36 8.36 0 0 0 1.72 9.047a8.256 8.256 0 0 0 2.061 6.505 8.36 8.36 0 0 0 6.224 2.783h5.813a2.497 2.497 0 0 0 2.498-2.497V9.462A8.36 8.36 0 0 0 15.319 3.6a8.26 8.26 0 0 0-5.31-1.915zm3.313 10.823a.832.832 0 1 1 0 1.665H6.66a.832.832 0 1 1 0-1.665zm0-3.33a.832.832 0 1 1 0 1.665H6.66a.832.832 0 1 1 0-1.665zm-3.33-3.33a.832.832 0 1 1 0 1.665H6.66a.832.832 0 1 1 0-1.665z"
        transform="translate(0 .5)"
        fill="url(#jf1rj8e4la)"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
}

export default CommentsFeed;
