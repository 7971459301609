import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function UserCircleIconTwoTone(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 30 30" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="5n8z3yxtya">
            <stop stop-color="#3E8BFF" offset="0%" />
            <stop stop-color="#3E8BFF" offset="100%" />
          </linearGradient>
        </defs>
        <path
          d="M25.607 4.393A14.902 14.902 0 0 0 15 0C10.993 0 7.226 1.56 4.393 4.393A14.902 14.902 0 0 0 0 15c0 4.007 1.56 7.774 4.393 10.607A14.902 14.902 0 0 0 15 30c4.007 0 7.774-1.56 10.607-4.393A14.902 14.902 0 0 0 30 15c0-4.007-1.56-7.774-4.393-10.607zM6.51 25.154c.495-4.262 4.16-7.552 8.49-7.552 2.283 0 4.43.89 6.044 2.504a8.541 8.541 0 0 1 2.446 5.048A13.182 13.182 0 0 1 15 28.242c-3.228 0-6.19-1.161-8.49-3.088zM15 15.791a4.561 4.561 0 0 1-4.556-4.555A4.561 4.561 0 0 1 15 6.68a4.561 4.561 0 0 1 4.556 4.556A4.561 4.561 0 0 1 15 15.79zm10.003 7.877a10.307 10.307 0 0 0-2.716-4.805 10.274 10.274 0 0 0-3.752-2.399 6.312 6.312 0 0 0 2.779-5.228A6.321 6.321 0 0 0 15 4.922a6.321 6.321 0 0 0-6.314 6.314 6.312 6.312 0 0 0 2.781 5.23 10.28 10.28 0 0 0-3.49 2.141 10.273 10.273 0 0 0-2.981 5.06A13.186 13.186 0 0 1 1.758 15C1.758 7.698 7.698 1.758 15 1.758c7.302 0 13.242 5.94 13.242 13.242 0 3.312-1.222 6.344-3.239 8.668z"
          fill="url(#5n8z3yxtya)"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default UserCircleIconTwoTone;
