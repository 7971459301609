import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    item: {
      backgroundColor: '#141b24',
      border: '1px solid #2d343e',
      borderRadius: 8,
      transition: 'all 50ms',
      overflow: 'hidden',
    },
    item__selected: {
      borderColor: theme.palette.primary.main,
    },
    item__notActive: {
      opacity: 0.3,
    },
    item__thumbnailContainer: {
      width: '100%',
      height: 190,
      position: 'relative',
      cursor: 'pointer',
    },
    item__thumbnail: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    item__idContainer: {
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRight: '1px solid #2d343e',
      width: 40,
      overflow: 'hidden',
    },
    item__inputIdContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRight: '1px solid #2d343e',
      width: 40,
      overflow: 'hidden',
      padding: theme.spacing(0, 0.5),
      '& .MuiFormControl-root': {
        margin: 0,
      },
      '& .MuiInputBase-root': {
        backgroundColor: 'transparent !important',
      },
      '& .MuiOutlinedInput-input': {
        padding: '7px 5px',
      },
    },
    item__isViewed: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      backgroundColor: 'rgba(62,139,255, 0.7)',
      padding: 4,
    },
    item__viewedText: {
      textAlign: 'center',
      textTransform: 'uppercase',
      fontWeight: 500,
    },
    item__details: {
      display: 'flex',
    },
    item__dragContainer: {
      padding: '2px',
      display: 'flex',
      alignItems: 'center',
      borderRight: '1px solid #2d343e',
      backgroundColor: '#121921',
    },
    item__detailsCountContainer: {
      display: 'flex',
      alignItems: 'center',
      padding: theme.spacing(2),
      borderRight: '1px solid #2d343e',
    },
    item__groupNameContainer: {
      cursor: 'pointer',
    },
    item__groupName: {
      position: 'relative',
      display: 'inline-block',
      wordWrap: 'break-word',
      overflow: 'hidden',
      lineHeight: 1.2,
      maxHeight: '1.2em',
      minHeight: '1.2em',
    },

    item__contentContainer: {},
    item__detailDescriptionContainer: {
      flex: 1,
      padding: theme.spacing(2),
      backgroundColor: '#0e1319',
    },
    item__bottomContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 18,
    },
    item__bottomFlex: {
      display: 'flex',
      alignItems: 'center',
      gap: 6,
    },

    individual: {
      padding: theme.spacing(3, 2),
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    individual__thumbnailContainer: {
      width: 100,
      height: 54,
    },
    individual__thumbnail: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: 4,
    },
    individual__detailsContainer: {
      display: 'flex',
      flex: 1,
    },

    individual__details: {
      flex: 1,
    },
    individual__actions: {
      display: 'flex',
      alignItems: 'center',
    },
    popover: {
      padding: '8px 6px',
    },
    popover__talentList: {
      padding: '0px 10px',
      display: 'flex',
      flexDirection: 'column',
      gap: 12,
    },
    popover__talentItem: {
      display: 'flex',
      alignItems: 'center',
      gap: 12,
    },
    popover__talentImg: {
      width: 96,
      height: 54,
      objectFit: 'cover',
      borderRadius: 4,
    },
    popover__talentDetailsContainer: {},
    popover__closeWindow: {
      cursor: 'pointer',
      marginTop: 12,
      borderTop: '1px solid #2f3b49',
      padding: '12px 0',
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);
