import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function InputSearch(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m17.79 16.73-4.473-4.473a7.506 7.506 0 1 0-1.06 1.06l4.473 4.473a.75.75 0 0 0 1.06-1.06zM7.518 13.513a5.995 5.995 0 1 1 5.995-5.995 6.002 6.002 0 0 1-5.995 5.995z"
          fill="#3E8BFF"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default InputSearch;
