// function titleCase(str) {
//   return str.
//   . split(' ')
//   . map((word) => word[0]. toUpperCase() + word. slice(1). toLowerCase())
//   . join(' ');
//   }
/**
 * Convert Snake Case to Title Case
 * @param text
 * @returns string
 */
export const snakeCaseToTitleCase = (text: string) => {
  return text
    .split('_')
    .map((word) => word[0].toUpperCase() + word.slice(1).toLocaleLowerCase())
    .join(' ');
};
