import { FormControl, FormHelperText, InputLabel, Select as MUISelect, MenuItem, SelectProps } from '@material-ui/core';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { useStyles } from './Select.styles';

export interface ISelectValue {
  key: string;
  value: string | number | readonly string[] | undefined;
}

type Props = {
  fullWidth?: boolean;
  label?: string;
  placeHolder?: string;
  errorMessage?: string;
  data: Array<ISelectValue>;
  hasMargin?: boolean;
  rootFormStyle?: React.CSSProperties;
} & SelectProps;
const Select: React.FC<Props> = ({
  fullWidth,
  label,
  errorMessage,
  data,
  hasMargin = true,
  placeHolder = 'Select One',
  rootFormStyle,
  ...props
}) => {
  const classes = useStyles();
  return (
    <Fragment>
      <FormControl
        style={rootFormStyle}
        margin={hasMargin ? 'normal' : undefined}
        fullWidth={fullWidth}
        error={!!errorMessage}
      >
        {!!label && <InputLabel shrink>{label}</InputLabel>}

        <MUISelect
          disableUnderline
          error={!!errorMessage}
          className={clsx(classes.select, props.className)}
          displayEmpty
          // renderValue={!!props.value ? undefined : () => placeHolder}
          renderValue={
            Array.isArray(props.value)
              ? props.value.length > 0
                ? undefined
                : () => placeHolder
              : props.value
              ? undefined
              : () => placeHolder
          }
          {...props}
        >
          {data.map((item) => (
            <MenuItem value={item.value} key={item.key}>
              {item.key}
            </MenuItem>
          ))}
        </MUISelect>
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </Fragment>
  );
};

export default Select;

Select.defaultProps = {
  fullWidth: false,
};
