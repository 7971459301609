import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const DotsIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="5" height="18" viewBox="0 0 5 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M0 9a2.08 2.08 0 0 0 2.077 2.077A2.08 2.08 0 0 0 4.154 9a2.08 2.08 0 0 0-2.077-2.077A2.08 2.08 0 0 0 0 9zm0 6.923A2.08 2.08 0 0 0 2.077 18a2.08 2.08 0 0 0 2.077-2.077 2.08 2.08 0 0 0-2.077-2.077A2.08 2.08 0 0 0 0 15.923zM0 2.077a2.08 2.08 0 0 0 2.077 2.077 2.08 2.08 0 0 0 2.077-2.077A2.08 2.08 0 0 0 2.077 0 2.08 2.08 0 0 0 0 2.077z"
        fill="#6E767F"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default DotsIcon;
