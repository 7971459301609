import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function TimesCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M10 .5c5.514 0 10 4.486 10 10s-4.486 10-10 10-10-4.486-10-10S4.486.5 10 .5zm0 1.25c-4.825 0-8.75 3.925-8.75 8.75s3.925 8.75 8.75 8.75 8.75-3.925 8.75-8.75S14.825 1.75 10 1.75zm3.537 5.213a.625.625 0 0 1 0 .885L10.884 10.5l2.652 2.652a.625.625 0 1 1-.884.883L10 11.384l-2.65 2.651a.626.626 0 1 1-.885-.884l2.65-2.652-2.65-2.65a.625.625 0 1 1 .883-.885L10 9.615l2.653-2.652a.625.625 0 0 1 .885 0z"
          fill="#677482"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default TimesCircleIcon;
