import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const ErrorIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 71 70" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10.256 10.239c13.652-13.652 35.872-13.652 49.524 0 13.652 13.654 13.652 35.87 0 49.521C52.953 66.588 43.985 70 35.018 70s-17.935-3.412-24.762-10.24C3.643 53.147 0 44.354 0 35.001s3.643-18.146 10.256-24.762zm45.689 3.835c-11.54-11.536-30.315-11.539-41.854 0-5.587 5.59-8.666 13.023-8.666 20.927s3.079 15.336 8.666 20.924c11.54 11.539 30.315 11.539 41.854 0 11.536-11.536 11.536-30.312 0-41.85zm-13.074 9.25a2.713 2.713 0 1 1 3.835 3.838l-7.68 7.668 7.68 7.668a2.712 2.712 0 1 1-3.835 3.838l-7.685-7.673-7.684 7.673a2.714 2.714 0 0 1-1.918.792 2.712 2.712 0 0 1-1.918-4.63l7.68-7.668-7.68-7.668a2.712 2.712 0 1 1 3.836-3.838l7.684 7.673z"
        fill="#E92B2B"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default ErrorIcon;
