import { Button, DialogContent, IconButton, Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { CloseIcon, StyledDialog, Typography } from '@room-match/shared-ui-components';
import { Image } from 'components/Image';
import React from 'react';
import useCopyClipboard from 'react-use-clipboard';
import { NO_PROFILE_IMAGE_PLACEHOLDER_URL } from 'shared/constants/ASSETS_URL';
import { IProfileAttributes } from 'shared/interfaces/IProfile';
import { useStyles } from './ShareProfileModal.styles';

type Props = {
  profile: IProfileAttributes;
  open: boolean;
  onClose: () => void;
  profileLink: string;
};
const ShareProfileModal: React.FC<Props> = ({ open, onClose, profile, profileLink }) => {
  const [isCopiedProfileURL, handleCopyProfileURL] = useCopyClipboard(profileLink, { successDuration: 1000 });
  const classes = useStyles();
  return (
    <StyledDialog open={open} onClose={onClose} className={classes.dialog} maxWidth="md" fullWidth>
      <DialogContent>
        <div className={classes.container}>
          <div className={classes.imageContainer}>
            <Image
              src={profile.primary_image_url || NO_PROFILE_IMAGE_PLACEHOLDER_URL}
              className={classes.image}
              alt={profile.talent.data.attributes.first_name}
            />
          </div>
          <div className={classes.detailsContainer}>
            <div className={classes.details__header}>
              <div>
                <Typography variant="heading" fontWeight={500} style={{ lineHeight: 1 }}>
                  {`${profile.talent.data.attributes.first_name} ${profile.talent.data.attributes.last_name}`}
                </Typography>
                <Typography color="mainGrey">SAG/AFTRA - Los Angeles</Typography>
              </div>
              <IconButton onClick={onClose} size="small">
                <CloseIcon style={{ fontSize: 12 }} />
              </IconButton>
            </div>

            <div className={classes.details__agencyContainer}>
              <Typography>{profile.agency?.data?.attributes.name || '—'}</Typography>
            </div>

            <div className={classes.details__description}>
              {/* Division */}
              <div className={classes.description__division}>
                <Typography fontSize={14} color="primary">
                  Commercial Divison
                </Typography>
                <Typography fontSize={12}>{profile.agency?.data?.attributes.contact_number || '—'}</Typography>
                <Typography fontSize={12}>
                  {profile.agency?.data?.attributes.country && profile.agency?.data?.attributes.state
                    ? `${profile.agency?.data?.attributes.country}, ${profile.agency?.data?.attributes.state} `
                    : '—'}
                </Typography>
              </div>
              {/* Rep */}
              <div className={classes.description__rep}>
                <Typography fontSize={14} color="primary">
                  Rep:
                  {profile.agency?.data?.attributes.contact_first_name &&
                  profile.agency?.data?.attributes.contact_last_name
                    ? `${profile.agency?.data?.attributes.contact_first_name} ${profile.agency?.data?.attributes.contact_last_name} `
                    : '—'}
                </Typography>
                <Typography fontSize={12}>{profile.agency?.data?.attributes.contact_number || '—'}</Typography>
                <Typography fontSize={12}>{profile.agency?.data?.attributes.website || '—'}</Typography>
              </div>
              {/* Logo */}
              {profile.agency?.data?.attributes.logo ? (
                <div className={classes.description__logo}>
                  <img src={profile.agency?.data?.attributes.logo} className={classes.description__logoImage} />
                </div>
              ) : (
                <Typography>{profile.agency?.data?.attributes.name || '—'}</Typography>
              )}
            </div>
            <div className={classes.details__shareProfileContainer}>
              <Typography color="primary" fontSize={25}>
                Share Profile URL
              </Typography>
              <div className={classes.shareProfile__linkWrapper}>
                <Typography fontSize={14}>{profileLink}</Typography>
              </div>
              <Button fullWidth color="primary" variant="contained" onClick={() => handleCopyProfileURL()}>
                Copy Link
              </Button>
            </div>
          </div>
        </div>
        <Snackbar
          open={isCopiedProfileURL}
          autoHideDuration={6000}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        >
          <Alert severity="success">Copied to Clipboard</Alert>
        </Snackbar>
      </DialogContent>
    </StyledDialog>
  );
};

export default ShareProfileModal;
