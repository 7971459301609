export const ROUTES = {
  ROOT: '/',
  LOGIN: '/login',

  SIGNUP: '/signup',
  FORGOT_PASSWORD: '/forgot_password',
  RESET_PASSWORD: '/reset_password',
  COMPLETE_PROFILE: '/complete_profile',
  // PROJECT_DETAIL_TAB_PDF_PREVIEW: '/project/:projectId/:tab/pdf-preview',
  PROJECT_DETAIL_CHILDTAB_PDF_PREVIEW: '/project/:projectId/:tab/:childTab/pdf-preview',
  PROJECT_SESSION_DETAIL_CHILDTAB_PDF_PREVIEW: '/project/:projectId/:tab/:sessionId/:childTab/pdf-preview',
  TALENT_PROFILE_LINK: '/profile_link/:token',
  CASTING: {
    PROFILE: '/casting/profile',
    SETTINGS: '/casting/settings/:tab',
    PROJECTS: {
      PROJECT_LIST: '/casting/my_projects',
      PROJECT_TAB_DETAIL: '/casting/my_projects/:projectId/:tab',
      PROJECT_CHILDTAB_DETAIL: '/casting/my_projects/:projectId/:tab/:childTab',
      PROJECT_DETAIL_CHILDTAB_DETAIL: '/casting/my_projects/:projectId/:tab/:projectDetailId/:childTab',
      CREATE_PROJECT: '/casting/my_projects/create/:type',
      EDIT_PROJECT: '/casting/my_projects/:projectId/edit',
      SESSION: {
        CREATE_SESSION_MANUAL: '/casting/my_projects/session/create/manual',
        UPDATE_SESSION: '/casting/my_projects/session/update/:projectId/:sessionId',
        CREATE_SESSION_IMPORT: '/casting/my_projects/session/create/import',
      },
      ROLE: {
        CREATE_ROLE: '/casting/my_projects/:projectId/role/create',
        CREATE_ST_ROLE: '/casting/my_projects/:projectId/role/create/st-role',
        EDIT_ROLE: '/casting/my_projects/:projectId/role/:roleId/edit',
      },
    },
    DASHBOARD: '/casting/dashboard',
    MESSAGES: '/casting/messages',
    SCHEDULES: '/casting/schedules',
    TALENTS: '/casting/talents',
    COMPANIES: '/casting/companies',
    CONTACTS: '/casting/contacts',
    REPORTS: '/casting/reports',
  },
  LOGIN_CLIENT: '/client/login/:companyId',
  CLIENT_FORGOT_PASSWORD: '/client/forgot-password/:companyId',
  CLIENT_RESET_PASSWORD: '/client/reset-password/:companyId',
  CLIENT: {
    PROJECTS: {
      PROJECT_LIST: '/client/my_projects',
      CLIENT_VIEW_PREVIEW: '/client/my_projects/:projectId/client-view-preview',
      CLIENT_VIEW: '/client/my_projects/:projectId/client-view',
    },
    PROFILE: '/client/profile',
  },
};
