import { Button, Grid, IconButton, Popover } from '@material-ui/core';
import {
  CheckBox,
  DangerExclamationIconTwoTone,
  DollarSignIconTwoTone,
  DotsIcon,
  HeartIcon,
  HeartIconTwoTone,
  PlayIconTwoTone,
  PriceTagIcon,
  ShadedStartBlueIconTwoTone,
  StarGradientIconTwoTone,
  Typography,
  UnshadedStarIconTwoTone,
} from '@room-match/shared-ui-components';
import { AuditionFlagIcon } from 'components/AuditionFlagIcon';
import React from 'react';
import { IAuditionAttributes } from 'shared/interfaces/IAudition';

import { useStyles } from './WorksheetTalentDetail.styles';

type Props = {
  audition: IAuditionAttributes;
  currentFlag: string | null;
  isCastingFavorite?: boolean;
  clientRate?: string | null;
  onSelectAudition: (isChecked: boolean) => void;
  handleOpenFlagModal: () => void;
  onCreateCastingFavorite: () => void;
  onCreateClientFavorite: (type: string) => void;
  onToggleExpandingContent?: () => void;
  primaryImage: string;
  isSelectedAudition?: boolean;
};
const WorksheetTalentDetail: React.FC<Props> = ({
  audition,
  onSelectAudition,
  handleOpenFlagModal,
  currentFlag,
  isCastingFavorite,
  clientRate,
  onCreateCastingFavorite,
  onCreateClientFavorite,
  onToggleExpandingContent,
  primaryImage,
  isSelectedAudition,
}) => {
  const [anchorClientSelection, setAnchorClientSelection] = React.useState<HTMLElement | null>(null);
  const openClientSelection = Boolean(anchorClientSelection);
  const clientSelectionId = openClientSelection ? 'simple-popover' : undefined;
  const handleClientSelectionOpen = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorClientSelection(event.currentTarget);
  };
  const handleClientSelectionClose = () => {
    setAnchorClientSelection(null);
  };

  const handleCastingFavorite = (type: string) => {
    onCreateClientFavorite(type);
    handleClientSelectionClose();
  };

  const handleToggleExpandingContent = () => {
    if (onToggleExpandingContent) {
      onToggleExpandingContent();
    }
  };

  const classes = useStyles();
  return (
    <div className={classes.requests__left}>
      {/* Action */}
      <div className={classes.requests__action}>
        <div className={classes.requests__action__approved}>
          <div className={classes.requests__action__approved__dragIcon}>
            <CheckBox
              size="small"
              checked={isSelectedAudition}
              onChange={(e) => {
                onSelectAudition(e.target.checked);
              }}
            ></CheckBox>
          </div>
          <div className={classes.requests__action__approved__likeIcon}>
            {/* QUEUE ID */}
            <Typography fontSize={17}>{audition.sequence_tag || 'N/A'}</Typography>
          </div>
        </div>
      </div>
      {/* Details */}
      <div className={classes.requests__detail}>
        {/* Video Player */}
        <div className={classes.request__videoPlayerContainer}>
          <img src={primaryImage} className={classes.request__videoPlayerThumbnail} />
          <IconButton
            className={classes.request__videoPlayerButton}
            size="small"
            onClick={() => handleToggleExpandingContent()}
          >
            <PlayIconTwoTone style={{ fontSize: 30 }} />
          </IconButton>
        </div>
        {/* Information */}
        <div className={classes.requests__information}>
          {/* Description */}
          <div className={classes.requests__description}>
            <div className={classes.requests__description__item}>
              <div className={classes.detail__description__textContainer}>
                {/* Henry Fox (SAG/AFTRA) */}
                <Typography fontSize={15}>
                  {audition.profile?.talent?.first_name && audition.profile?.talent?.last_name
                    ? `${audition.profile?.talent?.first_name} ${audition.profile?.talent?.last_name}`
                    : 'N/A'}
                </Typography>
                <IconButton size="small">
                  <DollarSignIconTwoTone fontSize="small" style={{ fontSize: 16 }} />
                </IconButton>
              </div>
              <div className={classes.requests__description__twoRow}>
                <Typography fontSize={14} color="mainGrey">
                  {audition.profile.agency?.name || 'N/A'}
                </Typography>
              </div>
              <div className={classes.requests__description__item__qa}>
                <IconButton size="small" style={{ padding: 0 }}>
                  <ShadedStartBlueIconTwoTone style={{ fontSize: 18 }} />
                </IconButton>
                <Typography fontSize={14}>{audition.public_rating}</Typography>
                <IconButton size="small" style={{ padding: 0 }} onClick={() => handleOpenFlagModal()}>
                  <AuditionFlagIcon flag={currentFlag || null} />
                </IconButton>
                <IconButton size="small" style={{ padding: 0 }} onClick={() => onCreateCastingFavorite()}>
                  {!isCastingFavorite ? (
                    <HeartIcon style={{ fontSize: 18 }} />
                  ) : (
                    <HeartIconTwoTone style={{ fontSize: 18 }} />
                  )}
                </IconButton>
                <IconButton size="small" style={{ padding: 0 }}>
                  <PriceTagIcon style={{ fontSize: 18 }} />
                </IconButton>{' '}
                <IconButton size="small" style={{ padding: 0 }} onClick={(e) => handleClientSelectionOpen(e)}>
                  {!clientRate ? (
                    <UnshadedStarIconTwoTone style={{ fontSize: 18 }} />
                  ) : (
                    <>
                      {clientRate === 'client_does_not_like' ? (
                        <DangerExclamationIconTwoTone style={{ fontSize: 18 }} />
                      ) : (
                        <StarGradientIconTwoTone style={{ fontSize: 18 }} />
                      )}
                    </>
                  )}
                </IconButton>
                <Popover
                  id={clientSelectionId}
                  open={openClientSelection}
                  anchorEl={anchorClientSelection}
                  onClose={handleClientSelectionClose}
                  anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  transformOrigin={{
                    vertical: 'top',
                    horizontal: 'left',
                  }}
                  classes={{
                    paper: classes.paperSmall,
                  }}
                >
                  <Grid container spacing={0} className={classes.clientSelectionPopover}>
                    <Grid item>
                      <Button
                        startIcon={<StarGradientIconTwoTone fontSize="small" />}
                        onClick={() => handleCastingFavorite('Client Favourite')}
                      >
                        <Typography fontSize={14}>Client Favourite</Typography>
                      </Button>
                    </Grid>
                    <Grid item>
                      <Button
                        startIcon={<DangerExclamationIconTwoTone fontSize="small" />}
                        onClick={() => handleCastingFavorite('Client Does Not Like')}
                      >
                        <Typography fontSize={14}>Client Doesn't Like</Typography>
                      </Button>
                    </Grid>
                  </Grid>
                </Popover>
              </div>
            </div>
          </div>
        </div>
        <div className={classes.requests__leftend__doticon}>
          <IconButton size="small">
            <DotsIcon fontSize="small" />
          </IconButton>
        </div>
      </div>
    </div>
  );
};

export default WorksheetTalentDetail;
