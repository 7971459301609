import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    item: {
      backgroundColor: '#141b24',
      border: '1px solid #2d343e',
      borderRadius: 8,
      transition: 'all 50ms',
      overflow: 'hidden',
    },
    item__selected: {
      borderColor: theme.palette.primary.main,
    },
    item__notActive: {
      opacity: 0.3,
    },
    item__thumbnailContainer: {
      width: '100%',
      height: 190,
      position: 'relative',
      cursor: 'pointer',
    },
    item__thumbnail: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderTopLeftRadius: 8,
      borderTopRightRadius: 8,
    },
    item__isViewed: {
      position: 'absolute',
      left: 0,
      bottom: 0,
      width: '100%',
      backgroundColor: 'rgba(62,139,255, 0.7)',
      padding: 4,
    },
    item__viewedText: {
      textAlign: 'center',
      textTransform: 'uppercase',
      fontWeight: 500,
    },
    item__description: {
      display: 'flex',
      borderBottom: '1px solid #2d343e',
    },
    item__dragContainer: {
      padding: '2px',
      display: 'flex',
      alignItems: 'center',
      borderRight: '1px solid #2d343e',
      backgroundColor: '#121921',
    },
    item__idContainer: {
      padding: '8px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRight: '1px solid #2d343e',
      width: 40,
      overflow: 'hidden',
    },
    item__inputIdContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      borderRight: '1px solid #2d343e',
      width: 40,
      overflow: 'hidden',
      padding: theme.spacing(0, 0.5),
      '& .MuiFormControl-root': {
        margin: 0,
      },
      '& .MuiInputBase-root': {
        backgroundColor: 'transparent !important',
      },
      '& .MuiOutlinedInput-input': {
        padding: '7px 5px',
      },
    },
    item__contentContainer: {
      flex: 1,
      display: 'flex',
      justifyContent: 'space-between',
      padding: theme.spacing(1.5),
      paddingRight: theme.spacing(2),
    },
    item__content: {},
    item__nameContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 6,
    },
    item__contentBottomContainer: {
      marginTop: theme.spacing(0.8),
      display: 'flex',
      alignItems: 'center',
      gap: 12,
    },
    item__noteContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 6,
    },
    item__starList: {
      display: 'flex',
      alignItems: 'center',
      gap: 6,
    },
    item__actionsContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: 6,
    },
    actions__cbButton: {
      fontSize: 11,
      minWidth: 'auto',
      padding: '1px 3px',
      borderRadius: 8,
      backgroundColor: '#293440',
    },
    item__commentsContainer: {
      padding: theme.spacing(2),
    },
    item__commentsInfoContainer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    item__commentsRating: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
    },
    item__commentsPerson: {
      display: 'flex',
      gap: 4,
    },
    item__commentsStars: {
      display: 'flex',
      alignItems: 'center',
      gap: 6,
    },
    items__commentsActions: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
    },
    item__commentContainer: {
      marginTop: theme.spacing(1),
    },
    item__comment: {
      fontSize: 14,
      lineHeight: 1.3,
    },
  }),
);
