import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const QRCodeIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="17" height="16" viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="jwlh2ss30a">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M1.778 10.667v1.777H0v-1.777h1.778zM0 16h1.778v-1.778H0V16zm1.778-3.556v1.778h1.778v-1.778H1.778zm3.555-5.333H3.556V8.89H1.778v1.778h3.555V7.11zM0 7.111V8.89h1.778V7.11H0zm5.333 7.111H3.556V16H8v-1.778h-.889v-1.778H5.333v1.778zM8.89.89H7.11v1.778H8.89V.889zM7.11 7.11H8.89V4.444H7.11v2.667zM.89 5.333A.889.889 0 0 1 0 4.444V.89C0 .398.398 0 .889 0h3.555c.491 0 .89.398.89.889v3.555c0 .491-.399.89-.89.89H.89zm.889-1.777h1.778V1.778H1.778v1.778zM16 .889V8h-1.778V5.333h-2.666a.889.889 0 0 1-.89-.889V.89c0-.491.399-.889.89-.889h3.555c.491 0 .889.398.889.889zm-1.778.889h-1.778v1.778h1.778V1.778zM16 11.556v3.555c0 .491-.398.889-.889.889h-3.555a.889.889 0 0 1-.89-.889v-2.667H7.112V8.89H8.89v1.778h.889V7.11h1.778v3.556h3.555c.491 0 .889.398.889.889zm-1.778.888h-1.778v1.778h1.778v-1.778z"
        transform="translate(.5)"
        fill="url(#jwlh2ss30a)"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default QRCodeIconTwoTone;
