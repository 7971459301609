import { Box, Grid, Button, IconButton, Tooltip, Popover } from '@material-ui/core';
import { Pagination } from '@material-ui/lab';
import clsx from 'clsx';
import {
  HeartIcon,
  HeartIconTwoTone,
  LoadingSpinnerIconTwoTone,
  StarGradientIconTwoTone,
  UnshadedStarIconTwoTone,
} from '@room-match/shared-ui-components';
import { ShareIcon, PriceTagIcon, FlagIcon, CloseIcon } from 'components/Icons';
import StarGradientIcon from 'components/Icons/StarGradientIcon';
import React, { useState } from 'react';
import { Typography } from 'shared/elements/Typography';
import { StyledPaginationItem } from '../StyledElements/StyledPaginationItem';
import { useStyles } from './TalentProfileHeader.styles';
import { IAuditionFlag } from 'shared/interfaces/IAuditionFlag';
import { useDispatch } from 'react-redux';
import { onOpenShareProfile } from 'shared/redux/slicers/talentProfile.slicer';

type Props = {
  flags: IAuditionFlag[];
  onClose: () => void;
  handleCreateProfileLink: () => void;
  isLoadingCreateProfileLink: boolean;
};

const TalentProfileHeader: React.FC<Props> = ({
  onClose,
  flags,
  handleCreateProfileLink,
  isLoadingCreateProfileLink,
}) => {
  const classes = useStyles();
  const [anchorElFlag, setAnchorElFlag] = useState<HTMLElement | null>(null);
  const flags_array = flags.filter(
    (flag) =>
      flag.attributes.flag_type === 'red_flag' ||
      flag.attributes.flag_type === 'green_flag' ||
      flag.attributes.flag_type === 'orange_flag',
  );

  const casting_favourite = flags.filter((flag) => flag.attributes.flag_type === 'casting_favourite');
  const client_favourite = flags.filter((flag) => flag.attributes.flag_type === 'client_favourite');

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorElFlag(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorElFlag(null);
  };

  const openFlag = Boolean(anchorElFlag);

  return (
    <Box className={classes.headerContainer}>
      <Grid container spacing={4} alignItems="stretch">
        {/* Left */}
        <Grid item lg={2} md={3}>
          <Box className={classes.headerContainer} style={{ height: '100%' }}>
            <Button
              variant="contained"
              color="secondary"
              disableElevation
              fullWidth
              size="large"
              style={{ height: '100%' }}
            >
              Previous Talent
            </Button>
          </Box>
        </Grid>
        {/* Center */}
        <Grid item lg={8} md={6}>
          <Box className={classes.header__middle} style={{ height: '100%' }}>
            {/* Left */}
            <Box className={classes.shareProfileContainer}>
              <Tooltip title="Share Profile" arrow>
                {!isLoadingCreateProfileLink ? (
                  <IconButton onClick={() => handleCreateProfileLink()} disabled={isLoadingCreateProfileLink}>
                    <ShareIcon width={12} height={12} style={{ fontSize: 16 }} />
                  </IconButton>
                ) : (
                  <LoadingSpinnerIconTwoTone />
                )}
              </Tooltip>
              {/* <Typography fontSize={15} color="darkGrey">
                Share Profile
              </Typography> */}
            </Box>
            <Box className={classes.header__center}>
              {/* Center */}
              <Pagination
                count={5}
                page={2}
                hideNextButton
                hidePrevButton
                color="primary"
                shape="rounded"
                renderItem={(item) => <StyledPaginationItem {...item} />}
                classes={{ ul: classes.paginationRoot }}
              />
              <Box className={classes.cb}>CB</Box>
              <IconButton size="small">
                {client_favourite.length > 0 ? (
                  <StarGradientIconTwoTone fontSize="small" />
                ) : (
                  <UnshadedStarIconTwoTone fontSize="small" />
                )}
              </IconButton>
            </Box>

            {/* Right */}
            <Box className={classes.rightIconsContainer}>
              <IconButton>
                <PriceTagIcon width={12} height={12} style={{ fontSize: 16 }} />
              </IconButton>
              <IconButton aria-describedby="flag-popover" onClick={handleClick}>
                <FlagIcon
                  width={12}
                  height={12}
                  style={{ fontSize: 16 }}
                  className={clsx({
                    [classes.flagRed]: flags_array.length > 0 && flags_array[0].attributes.flag_type === 'red_flag',
                    [classes.flagGreen]: flags_array.length > 0 && flags_array[0].attributes.flag_type === 'green_flag',
                    [classes.flagYellow]:
                      flags_array.length > 0 && flags_array[0].attributes.flag_type === 'orange_flag',
                  })}
                />
              </IconButton>
              <IconButton>
                {casting_favourite.length > 0 ? <HeartIconTwoTone fontSize="small" /> : <HeartIcon fontSize="small" />}
              </IconButton>
            </Box>
            <Popover
              id="flag-popover"
              open={openFlag}
              anchorEl={anchorElFlag}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'left',
              }}
              classes={{
                paper: classes.paperSmall,
              }}
            >
              <Grid container spacing={0} className={classes.popoverContainer}>
                {flags_array.map((flag) => (
                  <IconButton key={flag.id} style={{ borderRadius: '4px' }}>
                    <FlagIcon
                      className={clsx({
                        [classes.flagRed]: flag.attributes.flag_type === 'red_flag',
                        [classes.flagGreen]: flag.attributes.flag_type === 'green_flag',
                        [classes.flagYellow]: flag.attributes.flag_type === 'orange_flag',
                      })}
                    />
                    <Typography style={{ marginLeft: '10px' }}>{flag.attributes.comment}</Typography>
                  </IconButton>
                ))}
              </Grid>
            </Popover>
          </Box>
        </Grid>
        {/* Right */}
        <Grid item lg={2} md={3}>
          <Box display="flex" alignItems="center" gridGap={8} style={{ height: '100%' }}>
            <Button
              variant="contained"
              color="primary"
              disableElevation
              size="large"
              fullWidth
              style={{ height: '100%' }}
            >
              Next Talent
            </Button>
            <IconButton onClick={onClose}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </Box>
        </Grid>
      </Grid>
    </Box>
  );
};

export default TalentProfileHeader;
