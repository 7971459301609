import React, { useEffect, useMemo, useState } from 'react';
import { InputAdornment, Box, Button, FormControlLabel } from '@material-ui/core';
import { Input, InputPassword, CheckBox, Backdrop } from '@room-match/shared-ui-components';
import { Link, useHistory, useLocation, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FrontLayout } from 'components';
import { EmailIcon, PasswordIcon } from 'components/Icons';
import { ISignInRequestPayload } from 'shared/interfaces/IUser';
import { clientLogin, selectUserState } from 'shared/redux/slicers/user.slicer';
import { getErrorMessage } from 'shared/utils/getErrorMessage';
import { ROUTES } from 'shared/constants/ROUTES';

import { useStyles } from './LoginClient.styles';
import { companyService } from 'shared/services/companyService';
import { navigateToClientRoute } from 'shared/utils/navigateToRoute';
import { ls } from 'shared/utils/ls';
import { useLoginClientForm } from './LoginClientForm';

const { getCompanyMetaDetails } = companyService();
const { setLS, getLS } = ls();
const LoginClient = () => {
  const classes = useStyles();
  const history = useHistory();
  const urlParams = new URLSearchParams(window.location.search);
  const clientName = urlParams.get('name');
  const projectId = urlParams.get('project_id');
  const { companyId } = useParams() as { companyId: string | undefined };
  const dispatch = useDispatch();
  const { isLoading: isLoadingUser, isLoggedIn, user } = useSelector(selectUserState);
  const [isRememberMe, setIsRememberMe] = useState<boolean>(false);
  const { data: companyMetaDetailsData, isLoading: isCompanyDetailsMetaLoading } = getCompanyMetaDetails(
    companyId || '',
  );

  const isLoading = isCompanyDetailsMetaLoading;

  useEffect(() => {
    if (companyId) {
      const getLSCompanyId = getLS('companyId') as string | undefined;
      if (!getLSCompanyId) {
        setLS('companyId', companyId);
      }
    }
  }, [companyId]);

  useEffect(() => {
    if (clientName) {
      const getClientName = getLS('clientName') as string | undefined;
      if (!getClientName) {
        setLS('clientName', clientName);
      }
    }
  }, [clientName]);

  useEffect(() => {
    if (isLoggedIn) {
      if (user) {
        if (projectId) {
          history.push(navigateToClientRoute.toClientViewPreview(projectId));
        } else {
          history.push(ROUTES.CLIENT.PROJECTS.PROJECT_LIST);
        }
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoggedIn, user]);

  const asnycLogin = (values: ISignInRequestPayload, isRememberMe?: boolean): Promise<any> =>
    Promise.resolve(dispatch(clientLogin(values, isRememberMe)));

  const handleLoginSubmit = async (values: ISignInRequestPayload) => {
    const response = await asnycLogin(values, isRememberMe);
    if (response) {
      if (projectId) {
        history.push(navigateToClientRoute.toClientViewPreview(projectId));
      } else {
        history.push(ROUTES.CLIENT.PROJECTS.PROJECT_LIST);
      }
    }
  };

  const { form } = useLoginClientForm({ onSubmit: handleLoginSubmit });

  // const handleCheckBoxChange = (prop: keyof LoginState) => (event: React.ChangeEvent<HTMLInputElement>) => {
  //   setLoginState({ ...loginState, [prop]: event.target.checked });
  // };

  const handleInputKeyPress = (event: React.KeyboardEvent<HTMLElement>) => {
    if (event.key === 'Enter') {
      form.handleSubmit();
    }
  };

  const companyMetaDetails = useMemo(() => {
    return companyMetaDetailsData ? companyMetaDetailsData.data : null;
  }, [companyMetaDetailsData]);

  return (
    <>
      {!isLoading && (
        <FrontLayout
          bannerImage={companyMetaDetails?.attributes.icon ? companyMetaDetails.attributes.icon : ''}
          companyLogo={companyMetaDetails?.attributes.logo ? companyMetaDetails.attributes.logo : ''}
          isClient={true}
          clientName={clientName || ''}
        >
          <Box className={classes.boxContainer}>
            <Input
              name="user.email"
              variant="outlined"
              placeholder="Enter Email..."
              errorMessage={getErrorMessage(form.touched.user?.email, form.errors.user?.email)}
              fullWidth
              margin={'normal'}
              autoFocus
              inputProps={{ tabIndex: 1 }}
              onChange={form.handleChange}
              onKeyPress={handleInputKeyPress}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <EmailIcon style={{ marginTop: '8px' }} />
                  </InputAdornment>
                ),
              }}
              value={form.values.user.email}
            />
            <InputPassword
              fullWidth
              errorMessage={getErrorMessage(form.touched.user?.password, form.errors.user?.password)}
              name="user.password"
              variant="outlined"
              placeholder="Enter Password..."
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">
                    <PasswordIcon style={{ marginTop: '5px' }} />
                  </InputAdornment>
                ),
              }}
              InputLabelProps={{ shrink: true }}
              inputProps={{ tabIndex: 2 }}
              onChange={form.handleChange}
              value={form.values.user.password}
              onKeyPress={handleInputKeyPress}
              style={{ marginTop: '15px' }}
              onKeyUp={(e) => {
                if (e.key.toLocaleLowerCase() === 'enter') {
                  form.handleSubmit();
                }
              }}
              onCopy={(e) => {
                e.preventDefault();
                return false;
              }}
              onPaste={(e) => {
                e.preventDefault();
                return false;
              }}
            />
          </Box>
          <Box className={classes.extraContainer}>
            <Box>
              <FormControlLabel
                control={
                  <CheckBox
                    name="remmeberMe"
                    value={isRememberMe}
                    onChange={(e) => setIsRememberMe(e.target.checked)}
                    // onChange={handleCheckBoxChange('rememberMe')}
                    // value={loginState.rememberMe}
                  />
                }
                label="Remember Me"
              />
            </Box>
            <Box>
              <Link to={navigateToClientRoute.toForgotPassword(companyId || '')} className={classes.link}>
                Forgot Password?
              </Link>
            </Box>
          </Box>
          <Box>
            <Button
              color="primary"
              variant="contained"
              disableElevation
              fullWidth
              style={{ marginTop: '5px' }}
              className={classes.buttonCls}
              onClick={() => form.handleSubmit()}
              disabled={isLoadingUser}
            >
              {isLoadingUser ? 'Logging In..' : 'Log In'}
            </Button>
          </Box>
        </FrontLayout>
      )}
      <Backdrop isLoading={isLoading || isLoadingUser} />
    </>
  );
};

export default LoginClient;
