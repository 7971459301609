import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    socialFeedContainer: {
      // backgroundColor: theme.palette.customBlack.dark,
      backgroundColor: 'transparent',
      // padding: theme.spacing(4, 3),
      padding: '0px',
      maxHeight: '100%',
      overflow: 'auto',
    },
    socialFeedTitleContainer: {
      marginTop: theme.spacing(2),
    },
    socialFeedTabContainer: {
      minHeight: 'auto',
      // backgroundColor: theme.palette.customBlack.dark,
      // backgroundColor: 'transparent',
      padding: theme.spacing(4, 3),
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
    },
  }),
);
