import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const ShadedStarIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="13" viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.482 4.647 9.48 4.035 7.685.214c-.134-.285-.602-.285-.736 0L5.157 4.035l-4.002.611a.407.407 0 0 0-.23.687l2.908 2.98-.687 4.214a.407.407 0 0 0 .598.421l3.575-1.976 3.575 1.976a.407.407 0 0 0 .598-.421l-.687-4.213 2.907-2.98a.407.407 0 0 0-.23-.687z"
        fill="#FFC107"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default ShadedStarIconTwoTone;
