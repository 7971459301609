import { Box, IconButton, Typography } from '@material-ui/core';
import React, { useEffect, useRef, useState } from 'react';
import ReactPlayer from 'react-player';
import { useStyles } from './VideoPlayer.styles';

import PlayArrowOutlinedIcon from '@material-ui/icons/PlayArrowOutlined';
import PauseIcon from '@material-ui/icons/Pause';
import FullscreenIcon from '@material-ui/icons/Fullscreen';
import FullscreenExitIcon from '@material-ui/icons/FullscreenExit';
import clsx from 'clsx';
import { CustomVideoPlayerSlider } from './CustomVideoPlayerSlider';
import { secondsToTime } from '@room-match/shared-utils';
import { LoadingSpinnerIconTwoTone } from 'components/Icons';

type Props = {
  url: string;
  startTime?: number;
  endTime?: number;
  setIsFullScreen: React.Dispatch<React.SetStateAction<boolean>>;
  isFullScreen: boolean;
  thumbnail?: string | boolean;
  playIcon?: React.ReactElement<any, string | React.JSXElementConstructor<any>>;
  classes?: {
    videoPlayer: string;
    icon: string;
  };
  key?: string;
};
const VideoPlayer: React.FC<Props> = ({
  url,
  startTime,
  endTime,
  setIsFullScreen,
  isFullScreen,
  thumbnail,
  playIcon,
  classes: customClasses,
  key,
}) => {
  const classes = useStyles();

  const playerRef = useRef<any>(null);
  const [isVideoPlaying, setIsVideoPlaying] = useState<boolean>(false);
  const [isVideoReady, setIsVideoReady] = useState<boolean>(false);
  const [, setIsShowVideoAction] = useState<boolean>(false);
  const [videoMeta, setVideoMeta] = useState({ start: 0, end: 0 });
  const [videoProgress, setVideoProgress] = useState(0);
  const [videoDuration, setVideoDuration] = useState<string>('');
  const [getCurrentVideoTime, setGetCurrentVideoTime] = useState<number>(0);

  const isVideoEdited = !!(startTime && endTime);

  const toggleVideoPlaying = () => {
    setIsVideoPlaying((current) => !current);
  };

  const openFullScreen = () => {
    setIsFullScreen(true);
    document.documentElement.requestFullscreen();
  };
  const closeFullScreen = () => {
    setIsFullScreen(false);
    document.exitFullscreen();
  };

  const onEscape = () => {
    if (!document.fullscreenElement) setIsFullScreen(false);
  };

  useEffect(() => {
    window.addEventListener('fullscreenchange', onEscape);

    return () => window.removeEventListener('fullscreenchange', onEscape);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // Video Functions

  const onPause = () => {
    if (playerRef.current) {
      const getPlayer = playerRef.current;
      const getCurrentTime = Math.floor(getPlayer.getCurrentTime());
      if (videoMeta.end <= getCurrentTime) {
        getPlayer.seekTo(videoMeta.start);
        setVideoProgress(0);
        setGetCurrentVideoTime(0);
        setIsVideoPlaying(false);
      }
    }
  };

  const onReady = () => {
    setIsShowVideoAction(true);
    setIsVideoReady(true);
    let getVideoDuration = 0;
    if (playerRef.current) {
      const getPlayer = playerRef.current;

      if (startTime && endTime) {
        getVideoDuration = endTime - startTime;

        setVideoMeta({ start: startTime, end: endTime });
      } else {
        getVideoDuration = Math.floor(getPlayer.getDuration());

        setVideoMeta({ start: 0, end: getVideoDuration });
      }
      setVideoDuration(secondsToTime(getVideoDuration));
    }
  };

  const onProgress = ({
    playedSeconds,
  }: {
    playedSeconds: number;
    played: number;
    loadedSeconds: number;
    loaded: number;
  }) => {
    if (playerRef.current) {
      const getPlayer = playerRef.current;
      if (isVideoPlaying) {
        const getCurrentTime = Math.round(playedSeconds);

        let currentDurationTime = getCurrentTime;
        if (isVideoEdited) {
          currentDurationTime = getCurrentTime - videoMeta.start;
        }

        setGetCurrentVideoTime(currentDurationTime);
        if (videoMeta.end <= getCurrentTime) {
          getPlayer.seekTo(videoMeta.start);
          setVideoProgress(0);
          setGetCurrentVideoTime(0);
          setIsVideoPlaying(false);
          return;
        }
        setVideoProgress(() => {
          const videoDuration = videoMeta.end - videoMeta.start;
          let currentValue = (playedSeconds / videoDuration) * 100;
          if (isVideoEdited) {
            currentValue = ((playedSeconds - videoMeta.start) / videoDuration) * 100;
          }

          return currentValue;
        });
      }
    }
  };

  const onEnded = () => {
    if (playerRef.current) {
      const getPlayer = playerRef.current;
      getPlayer.seekTo(videoMeta.start);

      setIsVideoPlaying(false);
    }
  };

  const handleSliderChange = (value: number) => {
    if (playerRef.current) {
      const getPlayer = playerRef.current;
      const videoDuration = videoMeta.end - videoMeta.start;
      let convertedSliderValue = videoMeta.start;
      if (value > 0) {
        convertedSliderValue = (value * videoDuration) / 100 + videoMeta.start;
        if (isVideoEdited) {
          convertedSliderValue = ((value + videoMeta.start) * videoDuration) / 100 + videoMeta.start;
        }
      }

      setVideoProgress(value);
      setGetCurrentVideoTime(convertedSliderValue - videoMeta.start);
      getPlayer.seekTo(convertedSliderValue);
    }
  };

  return (
    <Box
      className={clsx(classes.videoWrapper)}
      onClick={(e) => {
        e.stopPropagation();
        toggleVideoPlaying();
      }}
      onMouseEnter={() => {
        if (isVideoReady) {
          setIsShowVideoAction(true);
        }
      }}
      onMouseLeave={() => {
        if (isVideoReady) {
          setIsShowVideoAction(false);
        }
      }}
    >
      {thumbnail ? (
        <ReactPlayer
          key={key}
          ref={playerRef}
          className="react-player"
          url={`${url}#t=${videoMeta.start},${videoMeta.end}`}
          width="100%"
          height="100%"
          playing={isVideoPlaying}
          onReady={onReady}
          onPause={onPause}
          onEnded={onEnded}
          onProgress={onProgress}
          light={thumbnail}
          playIcon={playIcon}
        />
      ) : (
        <ReactPlayer
          key={key}
          ref={playerRef}
          className="react-player"
          url={`${url}#t=${videoMeta.start},${videoMeta.end}`}
          width="100%"
          height="100%"
          playing={isVideoPlaying}
          onReady={onReady}
          onPause={onPause}
          onEnded={onEnded}
          onProgress={onProgress}
          playIcon={playIcon}
        />
      )}

      {/* {isShowVideoAction && (
        <Box className={classes.playAction}>
          <IconButton onClick={() => toggleVideoPlaying()} style={{ backgroundColor: '#fff' }}>
            {isVideoPlaying ? <StopIcon htmlColor="#fff" /> : <PlayArrowOutlinedIcon htmlColor="#fff" />}
          </IconButton>
        </Box>
      )} */}
      {isVideoReady && (
        <Box className={classes.progressAction}>
          {/* <h1>Hello</h1> */}
          <Box paddingX={2}>
            <CustomVideoPlayerSlider
              className={classes.videoSlider}
              value={videoProgress}
              aria-labelledby="continuous-slider"
              onClick={(e) => e.stopPropagation()}
              onChange={(_, value) => handleSliderChange(value as number)}
              valueLabelDisplay="auto"
              valueLabelFormat={(value) => {
                const videoDuration = videoMeta.end - videoMeta.start;
                let convertedSliderValue = videoMeta.start;
                if (value > 0) {
                  convertedSliderValue = (value * videoDuration) / 100 + videoMeta.start;
                  if (isVideoEdited) {
                    convertedSliderValue = ((value + videoMeta.start) * videoDuration) / 100 + videoMeta.start;
                  }
                }
                return secondsToTime(Math.floor(convertedSliderValue - videoMeta.start));
              }}
            />
          </Box>

          <Box paddingX={0.1} className={classes.bottomActions}>
            <Box>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  toggleVideoPlaying();
                }}
              >
                {!isVideoPlaying ? <PlayArrowOutlinedIcon htmlColor="#fff" /> : <PauseIcon htmlColor="#fff" />}
              </IconButton>
              <Typography variant="caption" style={{ color: '#fff' }}>
                {`${secondsToTime(getCurrentVideoTime)}/${videoDuration}`}
              </Typography>
            </Box>

            <Box className={classes.controlsContainer}>
              <IconButton
                onClick={(e) => {
                  e.stopPropagation();
                  if (!isFullScreen) {
                    openFullScreen();
                  } else {
                    closeFullScreen();
                  }
                }}
              >
                {!isFullScreen ? <FullscreenIcon htmlColor="#fff" /> : <FullscreenExitIcon htmlColor="#fff" />}
              </IconButton>
            </Box>
          </Box>
        </Box>
      )}

      {/* <VideoPlayer src={`${item.attributes.modified_attachment_url || item.attributes.attachment_url}`} /> */}
    </Box>
  );
};

export default VideoPlayer;
