import { CONFIG } from 'shared/config';

export const ENDPOINTS = {
  USERS: `${CONFIG.AUTH_API_URL}/users`,
  REFRESH_TOKEN: `${CONFIG.AUTH_API_URL}/refresh_token`,
  RESET_PASSWORD: `${CONFIG.AUTH_API_URL}/v1/reset_password`,
  CHANGE_PASSWORD: `${CONFIG.AUTH_API_URL}/change_password`,
  PROVIDER_AUTHENTICATION: `${CONFIG.AUTH_API_URL}/provider_authentication`,
  ME: `${CONFIG.CASTING_API_URL}/me`,
  PROFILE: `${CONFIG.CASTING_API_URL}/profiles`,
  MEDIA: `${CONFIG.UPLOAD_API_URL}/media`,
  MEDIA_URLS: `${CONFIG.UPLOAD_API_URL}/media_urls`,
  COMPANY: `${CONFIG.CASTING_API_URL}/company`,
  COMPANY_MATCHES: `${CONFIG.CASTING_API_URL}/match_casting_companies`,
  STAFF: `${CONFIG.CASTING_API_URL}/staffs`,
  CONTACT: `${CONFIG.CASTING_API_URL}/contacts`,
  QUESTION: `${CONFIG.CASTING_API_URL}/questions`,
  QUESTION_SORT: `${CONFIG.CASTING_API_URL}/sort_questions`,
  CASTING_LOCATION: `${CONFIG.CASTING_API_URL}/casting_locations`,
  SESSION: `${CONFIG.CASTING_API_URL}/sessions`,
  SCHEDULES_SESSIONS: `${CONFIG.CASTING_API_URL}/schedules_sessions`,
  AUDITIONS: `${CONFIG.CASTING_API_URL}/auditions`,
  AUDITION_SCHEDULES: `${CONFIG.CASTING_API_URL}/audition_schedules`,
  AUDITION_SCHEDULE_GROUPS: `${CONFIG.CASTING_API_URL}/audition_schedule_groups`,
  PERMISSIONS: `${CONFIG.CASTING_API_URL}/permissions`,
  PROJECT: `${CONFIG.CASTING_API_URL}/projects`,
  TH_REASONS: `${CONFIG.CASTING_API_URL}/th_reasons`,
  PROFILE_LINK: `${CONFIG.CASTING_API_URL}/profile_links`,
  GRAPHQL: CONFIG.GRAPHQL_API_URL,
  UPLOAD: `${CONFIG.UPLOAD_API_URL}/media`,
  PRESIGNED_URL: `${CONFIG.UPLOAD_API_URL}/presigned_url`,
};
