import { Tab, Tabs, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const StyledTabsSF = withStyles(() => ({
  root: {
    // backgroundColor: '#121820',
    backgroundColor: 'transparent',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
    '& .MuiTab-root': {
      minWidth: '100px',
    },
  },
  indicator: {
    // backgroundColor: theme.palette.primary.main,
    backgroundColor: 'transparent',
  },
}))(Tabs);

export const StyledTabSF = withStyles((theme: Theme) => ({
  root: {
    padding: '0px',
    color: '#6e767f',
    textTransform: 'none',
    // width: 20,
  },
  selected: {
    color: theme.palette.primary.main,
  },
}))(Tab);
