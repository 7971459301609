import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';
export const useMainListeItemStyle = makeStyles((theme: Theme) => {
  return createStyles({
    root: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      padding: '15px 16px !important',
      '& *': {
        margin: '0 !important',
      },
      '& svg': {
        marginBottom: '5px !important',
      },
      '& > div:first-child': {
        display: 'flex',
        justifyContent: 'center',
      },
      '& > div:last-child *': {
        fontSize: '12px',
      },
      '& > div > span': {
        visibility: 'hidden',
      },
      '&$selected': {
        backgroundColor: 'transparent',
        borderRight: 'solid 1px #3f8aff',
        '& > div > span': {
          color: '#FFFFFF',
          visibility: 'visible',
        },
        '& > div > svg path': {
          fill: '#3f8aff',
        },
      },
      '&$selected:hover': {
        backgroundColor: '#151a21',
        '& > div > span': {
          visibility: 'visible',
        },
      },
      '&:hover': {
        backgroundColor: '#151a21',
        '& > div > span': {
          visibility: 'visible',
        },
      },
      '& span': {
        fontSize: '12px',
        color: '#838d9c',
      },
      '& .MuiListItemIcon-root': {
        minWidth: '46px',
      },
    },
    selected: {},
  });
});
