import { CONFIG } from 'shared/config';
import { useAxios } from 'shared/hooks/useAxios';
import { IAbstractApiResponsePayload } from 'shared/interfaces/utils/IAbstractApi';

const { GET } = useAxios();
export const abstractApiDao = () => {
  const validateEmail = async (email: string) => {
    const response = await GET<IAbstractApiResponsePayload>({
      url: `${CONFIG.ABSTRACT_API_URL}/`,
      params: {
        api_key: CONFIG.ABSTRACT_API_KEY,
        email,
      },
    });
    return response.data;
  };

  return {
    validateEmail,
  };
};
