import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function CheckboxCheck(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 10 8">
      <svg width="10" height="8" viewBox="0 0 10 8" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="iyymrwdnza">
            <stop stopColor="#3E8BFF" offset="0%" />
            <stop stopColor="#3E8BFF" offset="100%" />
          </linearGradient>
        </defs>
        <path
          d="M9.768.258a.738.738 0 0 0-1.122 0L3.598 5.871 1.354 3.376a.738.738 0 0 0-1.122 0 .953.953 0 0 0 0 1.248l2.805 3.118a.752.752 0 0 0 .56.258.752.752 0 0 0 .562-.258l5.609-6.237a.953.953 0 0 0 0-1.247z"
          fill="url(#iyymrwdnza)"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default CheckboxCheck;
