import { useSortable } from '@dnd-kit/sortable';
import { Button, IconButton } from '@material-ui/core';
import clsx from 'clsx';
import { useQueryClient } from 'react-query';
import { useParams } from 'react-router-dom';
import {
  DragIcon,
  FilledStarIcon,
  ResumeIcon,
  ShadedStartBlueIconTwoTone,
  StickyNoteBlueIconTwoTone,
  StyledTooltip,
  TextInput,
  UnshadedStarIconTwoTone,
} from '@room-match/shared-ui-components';
import { ellipseText } from '@room-match/shared-utils';

import React, { useEffect, useMemo, useRef, useState } from 'react';
import { Typography } from 'shared/elements';
import {
  IAuditionScheduleAttributes,
  IAuditionScheduleUpdatePhaseAndStatusPayload,
} from 'shared/interfaces/IAuditionSchedule';
import { auditionScheduleService } from 'shared/services/auditionScheduleService';

import { getPublicNotesData } from 'shared/utils/auditionFlagParser';
import { getMediaImagesPerGroup, getPrimaryImage } from 'shared/utils/auditionMediaAttachments';
import { getFullName } from 'shared/utils/getFullName';
import { useStyles } from './AuditionSingle.styles';
import { AuditionStatus } from 'shared/enums/AuditionStatus';
import { AuditionPhase } from 'shared/enums/AuditionPhase';
import { CSS } from '@dnd-kit/utilities';
import { IAuditionScheduleGroupAttributes } from 'shared/interfaces/IAuditionScheduleGroup';
import { auditionScheduleFlagService } from 'shared/services/auditionScheduleFlagService';
import { IClientPermissionState } from 'shared/redux/slicers/clientPermission.slicer';

type Props = {
  index: number;
  isViewed?: boolean;
  isSelected?: boolean;
  handleSelectItem: (index: number) => void;
  isEditMode?: boolean;
  hasShownExpanded?: boolean;
  auditionSchedule: IAuditionScheduleAttributes;
  groupId: string;
  sessionId?: string;
  auditionScheduleGroup: IAuditionScheduleGroupAttributes;
  handleOpenTalentProfile?: (data: any, auditionScheduleId: string) => void;
  handleEditGroupNumber?: (auditionScheduleGroupId: string, newGroupNumber: string, oldGroupNumber: string) => void;
  clientPermissionsState?: IClientPermissionState;
};

const { updateAuditionScheduleStatusAndPhase } = auditionScheduleService();
const { getAuditionScheduleFlags } = auditionScheduleFlagService();
const AuditionSingle: React.FC<Props> = ({
  index,
  isViewed,
  handleSelectItem,
  isSelected,
  isEditMode,
  hasShownExpanded,
  auditionSchedule,
  groupId,
  sessionId,
  auditionScheduleGroup,
  handleOpenTalentProfile,
  handleEditGroupNumber,
  clientPermissionsState,
}) => {
  const { projectId } = useParams() as { projectId: string };
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [selectedCommentIndex, setSelectedCommentIndex] = useState<number>(0);
  const [groupNumber, setGroupNumber] = useState<string>(auditionScheduleGroup.group_number);
  const numInputRef = useRef<null | HTMLInputElement>(null);

  useEffect(() => {
    setGroupNumber(auditionScheduleGroup.group_number);
  }, [auditionScheduleGroup.group_number]);

  const sortable = useSortable({ id: auditionScheduleGroup.id });
  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = sortable;

  const { mutate: updateAuditionSchedulePhaseAndStatusMutate } = updateAuditionScheduleStatusAndPhase();

  const onUpdateAudition = (auditionScheduleId: string, status: AuditionStatus, phase: AuditionPhase) => {
    const payload: IAuditionScheduleUpdatePhaseAndStatusPayload = {
      audition_schedules: [
        {
          id: auditionScheduleId,
          status,
          phase,
        },
      ],
    };
    updateAuditionSchedulePhaseAndStatusMutate(
      { payload },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['auditionScheduleGroup', sessionId], {
            refetchInactive: true,
          });
        },
      },
    );
  };
  const { data: auditionFlagData, isLoading: isAuditionFlagLoading } = getAuditionScheduleFlags(auditionSchedule?.id, {
    enabled: !!auditionSchedule?.id,
  });

  const getPublicNotes = useMemo(() => {
    return auditionFlagData ? getPublicNotesData(auditionFlagData.data) : [];
  }, [auditionFlagData]);

  const getComment = useMemo(() => {
    return getPublicNotes.filter((_, i) => i === selectedCommentIndex)[0];
  }, [getPublicNotes, selectedCommentIndex]);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;
    if (key === 'Escape') {
      setGroupNumber(auditionScheduleGroup.group_number);
    } else if (key === 'Enter') {
      if (auditionScheduleGroup.group_number !== groupNumber) {
        // TODO UPDATE GROUP NUMBER

        if (numInputRef.current) {
          numInputRef.current.blur();
        }
        if (handleEditGroupNumber) {
          handleEditGroupNumber(auditionScheduleGroup.id, groupNumber, auditionScheduleGroup.group_number);
          setGroupNumber(auditionScheduleGroup.group_number);
        }
      }
    }
  };

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.3 : 1,
  };

  return (
    <div
      className={clsx(classes.item, {
        [classes.item__selected]: isSelected,
        [classes.item__notActive]: hasShownExpanded && !isSelected,
      })}
      ref={setNodeRef}
      style={style}
    >
      {/* Thumbnail */}
      <div className={classes.item__thumbnailContainer} onClick={() => handleSelectItem(index)}>
        {auditionSchedule && (
          <img
            src={getPrimaryImage(
              auditionSchedule.audition,
              getMediaImagesPerGroup(auditionSchedule.medium_attachments, auditionScheduleGroup.id),
            )}
            className={classes.item__thumbnail}
          />
        )}
        {isViewed && (
          <div className={classes.item__isViewed}>
            <Typography className={classes.item__viewedText}>Viewed</Typography>
          </div>
        )}
      </div>

      {/* Description */}
      <div className={classes.item__description}>
        {/* Drag */}
        {isEditMode && (
          <div
            className={classes.item__dragContainer}
            {...attributes}
            {...listeners}
            onClick={(e) => e.stopPropagation()}
          >
            <IconButton size="small">
              <DragIcon fontSize="small" />
            </IconButton>
          </div>
        )}

        {/* Id */}
        {isEditMode ? (
          <div className={classes.item__inputIdContainer}>
            <TextInput
              margin="none"
              value={groupNumber}
              inputRef={numInputRef}
              onKeyUp={handleKeyUp}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  setGroupNumber(e.target.value);
                }
              }}
            />
          </div>
        ) : (
          <div className={classes.item__idContainer}>
            <Typography>{groupNumber || '—'}</Typography>
          </div>
        )}
        {/* Content */}
        <div className={classes.item__contentContainer}>
          {/* Content */}
          <div className={classes.item__content}>
            <div className={classes.item__nameContainer}>
              <StyledTooltip
                title={
                  auditionSchedule
                    ? getFullName(
                        auditionSchedule.audition?.profile?.talent?.first_name,
                        auditionSchedule.audition?.profile?.talent?.last_name,
                      )
                    : ''
                }
              >
                <div>
                  <Typography>
                    {auditionSchedule
                      ? ellipseText(
                          getFullName(
                            auditionSchedule.audition?.profile?.talent?.first_name,
                            auditionSchedule.audition?.profile?.talent?.last_name,
                          ),
                          15,
                        )
                      : ''}
                  </Typography>
                </div>
              </StyledTooltip>

              <ResumeIcon
                style={{ fontSize: 16 }}
                onClick={() =>
                  handleOpenTalentProfile
                    ? handleOpenTalentProfile(auditionSchedule.audition?.profile, auditionSchedule?.id)
                    : null
                }
              />
            </div>
            <div className={classes.item__contentBottomContainer}>
              <div className={classes.item__noteContainer}>
                <StickyNoteBlueIconTwoTone style={{ fontSize: 14 }} />
                <Typography fontSize={14} style={{ lineHeight: 1 }}>
                  {getPublicNotes.length}
                </Typography>
              </div>
              <div className={classes.item__starList}>
                {getComment ? (
                  <>
                    {Array.from({ length: getComment?.attributes?.rating || 0 }).map((_, i) => (
                      <ShadedStartBlueIconTwoTone key={i} style={{ fontSize: 14 }} />
                    ))}

                    {Array.from({ length: 5 - (getComment?.attributes?.rating || 5) }).map((_, i) => (
                      <UnshadedStarIconTwoTone key={i} style={{ fontSize: 14 }} />
                    ))}
                  </>
                ) : (
                  <>
                    {Array.from({ length: 5 }).map((_, i) => (
                      <UnshadedStarIconTwoTone key={i} style={{ fontSize: 14 }} />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>

          {/* Actions */}
          <div className={classes.item__actionsContainer}>
            {!clientPermissionsState ? (
              <Button
                disabled={(auditionSchedule || {}).status === AuditionStatus.CALLBACK}
                classes={{ root: classes.actions__cbButton }}
                onClick={() => onUpdateAudition(auditionSchedule.id, AuditionStatus.CALLBACK, AuditionPhase.DECISION)}
              >
                CB
              </Button>
            ) : (
              <React.Fragment>
                {clientPermissionsState?.projectPermissions?.select_callback && (
                  <Button
                    disabled={(auditionSchedule || {}).status === AuditionStatus.CALLBACK}
                    classes={{ root: classes.actions__cbButton }}
                    onClick={() =>
                      onUpdateAudition(auditionSchedule.id, AuditionStatus.CALLBACK, AuditionPhase.DECISION)
                    }
                  >
                    CB
                  </Button>
                )}
              </React.Fragment>
            )}

            <IconButton size="small" style={{ justifyItems: 'flex-end' }}>
              <FilledStarIcon style={{ fontSize: 16 }} />
            </IconButton>
          </div>
        </div>

        {/*  */}
      </div>

      {/* Comments */}
      {/* 
      <div className={classes.item__commentsContainer}>
        <div className={classes.item__commentsInfoContainer}>
          <div className={classes.item__commentsRating}>
            <div className={classes.item__commentsPerson}>
              <Typography fontSize={14}>{getComment?.attributes?.user.data.attributes.first_name || 'N/A'}</Typography>
              <Typography fontSize={14} color="mainGrey">
                ({getComment?.attributes?.created_at ? getTimeAgo(new Date(getComment?.attributes?.created_at)) : 'N/A'}
                )
              </Typography>
            </div>

            <div className={classes.item__commentsStars}>
              {Array.from({ length: getComment?.attributes?.rating || 0 }).map((_, i) => (
                <ShadedStartBlueIconTwoTone key={i} style={{ fontSize: 14 }} />
              ))}

              {Array.from({ length: 5 - (getComment?.attributes?.rating || 0) }).map((_, i) => (
                <UnshadedStarIconTwoTone key={i} style={{ fontSize: 14 }} />
              ))}
            </div>
          </div>

          <div className={classes.items__commentsActions}>
            <IconButton size="small" onClick={handleBackComment} disabled={isDisabledBackButton}>
              <BackIcon fontSize="small" htmlColor={isDisabledBackButton ? undefined : '#3E8BFF'} />
            </IconButton>
            <IconButton size="small" onClick={handleNextComment} disabled={isDisabledNextButton}>
              <NextIcon fontSize="small" htmlColor={isDisabledNextButton ? undefined : '#3E8BFF'} />
            </IconButton>
          </div>
        </div>

        <div className={classes.item__commentContainer}>
          {getComment?.attributes?.comment ? (
            <StyledTooltip title={getComment?.attributes?.comment || ''}>
              <div>
                <Typography className={classes.item__comment}>
                  {getComment?.attributes?.comment ? ellipseText(getComment?.attributes?.comment, 40) : 'N/A'}
                </Typography>
              </div>
            </StyledTooltip>
          ) : (
            <Typography className={classes.item__comment}>{'N/A'}</Typography>
          )}
        </div>
      </div> */}
    </div>
  );
};

export default AuditionSingle;
