import { gql } from 'graphql-request';

export const GET_CLIENT_AUDITION_SCHEDULE = gql`
  query ($project_id: ID!, $casting_session_id: ID, $casting_role_id: ID, $choice_group_ids: [ID!]) {
    client_view_audition_schedules(
      projectId: $project_id
      castingSessionId: $casting_session_id
      castingRoleId: $casting_role_id
      choiceGroupIds: $choice_group_ids
    ) {
      id
      phase
      status
      checked_in_at
      grouping_list
      audition_schedule_groups {
        id
        group_name
        group_number
        viewed
        role_names
        medium_attachments {
          id
          attachment
          modified_attachment
          sort
          tag_list
          primary
        }
      }
      medium_attachments {
        id
        attachment
        modified_attachment
        sort
        tag_list
        primary
      }
      audition {
        id
        sequence_tag
        priority
        submission_note
        mediumable_count
        internal_note_count
        choice_group {
          id
          name
          sort_order
        }
        profile {
          id
          talent {
            email
            first_name
            last_name
            gender
            contact_no
            country
            country_code
            state_region
            adult_minor
            birth_date
            primary_type
            statistics
          }
          primary_medium_attachment {
            primary
            medium_id
            mediumable_id
            mediumable_type
            attachment
            modified_attachment
          }
        }
        medium_attachments {
          id
          attachment
          modified_attachment
          sort
          tag_list
          medium {
            file_type
            file_name
            file_size
            file_width
            file_height
          }
        }
        casting_role {
          id
          name
        }
      }
    }
  }
`;
