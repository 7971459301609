import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    root: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
    container: {
      marginLeft: '-9px',
      marginTop: '-9px',

      '& .MuiTypography-root': {
        fontSize: '13px',
        letterSpacing: '0.35px',
      },

      '& .MuiIconButton-label': {
        marginTop: '-1px',
      },
    },
    icon: {
      borderRadius: 3,
      width: 16,
      height: 16,
      border: '1px solid #48505c',
    },
    checkedIcon: {
      width: 16,
      borderRadius: 3,
      height: 16,
      border: '1px solid #3e8bff',
    },
  }),
);
