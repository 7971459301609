import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Inbox(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 18" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M17.056 0c.248 0 .449.2.449.449v.618h.64a1.58 1.58 0 0 1 1.416.871l3.363 6.65c.137.24.22.519.22.816v7.025c0 .866-.704 1.571-1.57 1.571H1.57C.705 18 0 17.295 0 16.43V9.403c0-.297.083-.575.227-.813L.22 8.588l3.363-6.65A1.58 1.58 0 0 1 5 1.067h.64V.449C5.64.2 5.84 0 6.088 0zm-10.2 8.83H1.57a.575.575 0 0 0-.574.574v7.025c0 .317.257.574.574.574h20.002a.575.575 0 0 0 .575-.574V9.404a.575.575 0 0 0-.575-.574H16.29a.038.038 0 0 0-.034.022l-.647 1.434c-.166.37-.537.609-.943.609H8.48c-.406 0-.776-.24-.943-.61L6.89 8.853a.038.038 0 0 0-.035-.022zm7.268 3.639c.385 0 .698.313.698.697v1.566a.698.698 0 0 1-.698.698H9.02a.698.698 0 0 1-.698-.698v-1.566c0-.384.313-.697.698-.697zm-.199.897H9.22v1.167h4.706v-1.167zm4.731-6.76H4.488v1.227h2.367c.406 0 .776.24.943.61l.647 1.433c.006.014.02.022.034.022h6.186a.038.038 0 0 0 .035-.022l.646-1.433c.167-.37.537-.61.943-.61h2.367V6.606zm-.512-4.543h-.64v.792h.597c.248 0 .449.2.449.448V5.71h.555c.247 0 .448.201.448.449v1.675h1.873l-2.754-5.445a.589.589 0 0 0-.528-.325zm-12.504 0H5a.588.588 0 0 0-.527.325L1.718 7.833h1.873V6.158c0-.248.201-.449.449-.449h.555V3.303c0-.248.2-.448.448-.448h.597v-.792zm12.013 1.689H5.492v1.957h12.16V3.752zM16.608.897H6.537v1.958h10.07V.897z"
          fill="#838D9C"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default Inbox;
