import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const StarGradientIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="147.119%" y1="4.895%" x2="-228.617%" y2="140.643%" id="96iv0cz6ra">
          <stop stop-color="#D51BFF" offset="0%" />
          <stop stop-color="#00D4FF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M16.652 6.046a.886.886 0 0 0-.764-.61l-4.818-.438L9.164.538A.888.888 0 0 0 7.532.54L5.626 5l-4.82.437a.887.887 0 0 0-.503 1.553l3.643 3.194-1.074 4.732a.887.887 0 0 0 1.32.958l4.156-2.484 4.155 2.484a.888.888 0 0 0 1.32-.959l-1.073-4.73 3.642-3.195a.888.888 0 0 0 .26-.943z"
        fill="url(#96iv0cz6ra)"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default StarGradientIcon;
