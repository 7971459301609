import { Box } from '@material-ui/core';
import { getImageWidth } from '@room-match/shared-utils';
import clsx from 'clsx';
import { WithStore } from 'pure-react-carousel';
import React, { useState } from 'react';
import { useStyles } from './ImageCarousel.styles';
interface UpdateCheckCarouselState {
  readonly currentSlide: number;
}
interface Props {
  images: {
    src: string;
    width: number;
    height: number;
  }[];
}
const BOX_HEIGHT = 100;
const DecoratedComponent = ({ images, currentSlide, ...props }: Props & UpdateCheckCarouselState) => {
  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState<{
    src: string;
    width: number;
    height: number;
  }>(images[currentSlide]);

  const onClickImage = (index: number) => {
    const prop = props as any;

    prop.carouselStore.setStoreState({ currentSlide: index });
  };

  return (
    <Box className={classes.imageListContainer}>
      {images.map((item, i) => (
        <Box
          onClick={() => setSelectedImage(item)}
          className={classes.imageItemContainer}
          style={{
            maxWidth: getImageWidth({ width: item.width, height: item.height }, BOX_HEIGHT),
            height: BOX_HEIGHT,
          }}
          key={i}
        >
          <Box
            onClick={() => onClickImage(i)}
            style={{ width: getImageWidth({ width: item.width, height: item.height }, BOX_HEIGHT) / 2 }}
          >
            <img
              src={item.src}
              className={clsx(classes.imageItem, {
                [classes.imageItem__active]: currentSlide === i,
              })}
              style={{ height: BOX_HEIGHT, width: '100%' }}
            />
          </Box>
        </Box>
      ))}
    </Box>
  );
};

export default WithStore<Props, UpdateCheckCarouselState>(DecoratedComponent, (state) => ({
  currentSlide: state.currentSlide,
}));
