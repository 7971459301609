import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const CommentIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.71.006a7 7 0 0 1 7.277 6.562v4.518A2.917 2.917 0 0 1 11.073 14h-4.07A7 7 0 0 1 6.712.006zm.296 1.174a6.16 6.16 0 0 0-.758.047 5.853 5.853 0 0 0-5.044 5.106 5.78 5.78 0 0 0 1.443 4.553 5.851 5.851 0 0 0 4.357 1.949h4.069c.965 0 1.748-.783 1.748-1.749V6.624a5.851 5.851 0 0 0-2.098-4.104 5.782 5.782 0 0 0-3.717-1.34zm2.319 7.575a.583.583 0 1 1 0 1.166H4.663a.583.583 0 1 1 0-1.166zm0-2.33a.583.583 0 1 1 0 1.165H4.663a.583.583 0 1 1 0-1.166zM6.994 4.092a.583.583 0 1 1 0 1.166H4.663a.583.583 0 1 1 0-1.166z"
        fill="#D7A300"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default CommentIcon;
