import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const HeartIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="19" height="16" viewBox="0 0 19 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.157 1.566A4.836 4.836 0 0 0 13.56 0a4.524 4.524 0 0 0-2.826.975A5.781 5.781 0 0 0 9.59 2.168 5.778 5.778 0 0 0 8.45.975 4.524 4.524 0 0 0 5.623 0a4.836 4.836 0 0 0-3.597 1.566C1.106 2.564.599 3.927.599 5.405c0 1.52.567 2.913 1.784 4.381 1.089 1.314 2.653 2.647 4.465 4.191.619.527 1.32 1.125 2.048 1.762a1.055 1.055 0 0 0 1.39 0c.729-.637 1.43-1.235 2.05-1.762 1.81-1.544 3.375-2.877 4.464-4.19 1.217-1.47 1.783-2.861 1.783-4.382 0-1.478-.506-2.841-1.426-3.84z"
        fill="#677482"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default HeartIcon;
