import { Dialog, Theme } from '@material-ui/core';
import { withStyles, makeStyles, createStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      '&.paper': {
        minWidth: '85%',
        minHeight: '90%',
      },
      '& .MuiDialog-paperWidthSm': {
        maxWidth: '605px !important',
        minHeight: '620px',
      },
    },
    closeIcon: {
      position: 'absolute',
      top: '20px',
      right: '20px',
      cursor: 'pointer',
    },
    notifBody: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      textAlign: 'center',
      padding: '30px 20px',
      minHeight: '590px',
      '& .Mui-focused .MuiOutlinedInput-notchedOutline': {
        borderColor: 'rgba(255, 255, 255, 0.23) !important',
      },
    },
    inputAutoComplete: {
      '& .MuiAutocomplete-inputRoot': {
        height: '54px !important',
      },
      '& input': {
        padding: '9.5px 8px !important',
      },
    },
    listItem: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      background: '#21262d',
      width: '100%',
      padding: '10px',
      borderRadius: '5px',
      '& > *:last-child': {
        marginLeft: 'auto',
      },
    },
    dpContainer: {
      height: '40px',
      width: '40px',
      background: '#808080',
      marginRight: '10px',
      borderRadius: '5px',
    },
    darkText: {
      color: '#6e767f',
    },
    dropdownContainer: {
      background: '#0e1319',
      position: 'absolute',
      top: '-5px',
      right: '-1px',
      width: '100%',
      border: 'solid 2px rgba(255, 255, 255, 0.23)',
      borderTop: 'none !important',
      borderTopLeftRadius: '0px',
      borderTopRightRadius: '0px',
      '& li:hover, & li': {
        padding: '5px 10px',
        background: 'unset !important',
      },
    },
    profileContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      flexWrap: 'wrap',
      marginBottom: '25px',
    },
    profileImage: {
      position: 'relative',
      background: '#FFFFFF',
      borderRadius: '10px',
      width: '108px',
      height: '108px',
    },
    editContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      background: 'rgba(0, 0, 0, 0.5)',
      borderRadius: '8px',
      width: '31px',
      height: '31px',
      position: 'absolute',
      bottom: '-9%',
      right: '42%',
      cursor: 'pointer',
      '& svg': {
        width: '20px',
      },
    },
    uploadProfileImage: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      borderRadius: '10px',
      border: 'solid 1px #3e8bff',
      width: '108px',
      height: '108px',
      cursor: 'pointer',
      '& svg': {
        width: '40px',
      },
    },
    addTalentBtn: {
      height: '50px',
      marginTop: 'auto',
    },
    formCls: {
      textAlign: 'left',
      width: '100%',
      '& .MuiFormControl-root': {
        margin: '10px 0px',
      },
    },
    contactInputContainer: {
      '& .react-tel-input .flag-dropdown': {
        border: 'solid 1px rgba(255, 255, 255, 0.23) !important',
        borderRight: 'none !important',
      },
      '& .react-tel-input input': {
        border: 'solid 1px rgba(255, 255, 255, 0.23) !important',
      },
    },
  }),
);
