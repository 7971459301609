import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    addTalentModal__paper: {
      maxWidth: '100%',
    },
    addTalentModal__dialogContent: {
      // maxWidth: '100%',
      padding: '0px 20px 0px 20px',
      paddingBottom: '27px',
    },
    addTalentModal__container: {
      minWidth: 796,
      // minHeight: 100,
    },
    addTalentModal__title: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      marginBottom: '36px',
      marginTop: '7px',
    },
    addTalentModal__content: {
      padding: '10px 0',
      marginTop: theme.spacing(2),
    },
    addTalentModal__addTalentContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '0px',
    },
    addTalentModal__imageContainer: {
      width: '217px',
      height: '325px',
      overflow: 'hidden',
      borderRadius: 4,
      marginLeft: '7px',
      marginTop: '7px',
      marginBottom: '15px',
    },
    addTalent__image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    addTalentModal__rightContent: {
      // width: '600px',
      padding: '0px 13px 0px 34px',
      marginLeft: '18px',
    },
    addTalentModal__detailContainer: {
      gap: 8,
    },
    addTalentModal__bottomTalentName: {
      fontSize: '15px',
      fontWeight: 500,
      fontFamily: 'Inter',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.4px',
      color: '#fff',
      marginLeft: '7px',
    },
    addTalentModal__bottomTatentStats: {
      fontSize: '13px',
      fontWeight: 500,
      fontFamily: 'Inter',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.35px',
      color: '#6e767f',
      marginTop: '5px',
      marginLeft: '7px',
    },
    addTalentModal__selectProject: {
      marginBottom: '-5px',
      marginTop: '10px',
      fontSize: '13px',
      fontWeight: 500,
      fontFamily: 'Inter',
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.35px',
      color: '#c2ccd8',
    },

    addTalentModal__selectedProjectContainer: {
      marginTop: theme.spacing(2),
      padding: theme.spacing(1.5, 2),
      border: '1px solid rgb(118, 118, 118)',
      borderRadius: 4,
    },
    addTalentModal__addToProjectBtn: {
      // width: '485px',
      height: '50px',
      borderRadius: '5px',
      fontSize: '14px',
    },
    addTalentModal__selectionContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: '17px 0px 32px 0px',
      gap: 20,
    },
    addTalentModal__selectionBtn: {
      fontSize: 13,
      fontFamily: 'Inter',
      fontweight: 500,
      fontStretch: 'normal',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.35px',
      textAlign: 'center',
      minWidth: '10px',
      minHeight: '10px',
      margin: '0px',
      padding: '2px 5px 2px 6px',
      border: '1px solid rgba(255, 255, 255, 0.23)',
      backgroundColor: '#293441',
      borderRadius: 8,
    },
  }),
);
