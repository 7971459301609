import { Grid, IconButton, Button, InputLabel } from '@material-ui/core';

import React from 'react';
import { FormikProps } from 'formik';
import clsx from 'clsx';

import { TextInput, Typography } from 'shared/elements';
import { useStyles } from './GeneralProjectInformation.styles';
import { useWorksheetSectionStyle } from 'shared/theme/styles/worksheetSectionStyle';
import { ArrowUpIcon, SelectArrowDown } from 'components/Icons';
import { ArrowDownIconTwoTone, CameraIconTwoTone, Select } from '@room-match/shared-ui-components';
import { IProjectCreatePayload } from 'shared/interfaces/IProject';
import { getErrorMessage } from 'shared/utils/getErrorMessage';
import { CONTRACT_TYPES } from 'shared/constants/CONTRACT_TYPES';
import { PROJECT_TYPES } from 'shared/constants/PROJECT_TYPES';
import { PROJECT_UNIONS } from 'shared/constants/PROJECT_UNIONS';

type Props = {
  form: FormikProps<IProjectCreatePayload>;
  isCollapse: boolean;
  toggleCollapse: (accordionItem: string) => void;
};
const GeneralProjectInformation: React.FC<Props> = ({ isCollapse, form, toggleCollapse }) => {
  const classes = useStyles();
  const sectionClasses = useWorksheetSectionStyle();

  return (
    <Grid container spacing={0} className={sectionClasses.root}>
      <Grid item className={sectionClasses.header}>
        <Grid item>
          <Typography>1. General Project Information</Typography>
        </Grid>
        <Grid item style={{ display: 'flex', gap: '2em' }}>
          <Button
            className={sectionClasses.collapseButton}
            startIcon={isCollapse ? <ArrowUpIcon /> : <ArrowDownIconTwoTone />}
            onClick={() => toggleCollapse('GPI')}
          >
            Collapse
          </Button>
        </Grid>
      </Grid>

      <Grid
        item
        className={clsx(sectionClasses.content, {
          [sectionClasses.content__isNotCollapsed]: !isCollapse,
        })}
      >
        <Grid item className={classes.image__container}>
          <Grid item>
            <IconButton className={classes.image__button}>
              <CameraIconTwoTone />
            </IconButton>
          </Grid>
          <Grid item>
            <Typography fontSize={14}>Project Image (Optional)</Typography>
            <Typography fontSize={14} color="lightGrey">
              Aspect Ratio: 1:1 | Image Formats: PNG/JPG
            </Typography>
          </Grid>
        </Grid>
        <Grid item>
          <TextInput
            label="Project Name"
            labelId="projectName"
            tooltip={true}
            tooltipTitle="Project Name"
            name="name"
            placeholder="Example Project Name"
            value={form.values.name}
            onBlur={form.handleBlur}
            onChange={(e) => {
              if (form.errors.name && !form.touched.name) {
                form.setFieldTouched('name');
                form.validateField('name');
              }
              return form.handleChange(e);
            }}
            errorMessage={getErrorMessage(form.touched.name, form.errors.name)}
          />
        </Grid>
        <Grid item className={classes.twoColumn}>
          <Grid item>
            <InputLabel id="typeOfAudition" className={sectionClasses.selectLabelFix}>
              Project Type
            </InputLabel>
            <Select
              variant="outlined"
              fullWidth
              data={PROJECT_TYPES}
              value={form.values.project_type}
              onChange={(e) => form.setFieldValue('project_type', e.target.value)}
              IconComponent={SelectArrowDown}
            />
          </Grid>
          <Grid item>
            <InputLabel id="typeOfAudition" className={sectionClasses.selectLabelFix}>
              Project Union*
            </InputLabel>
            <Select
              variant="outlined"
              fullWidth
              data={PROJECT_UNIONS}
              value={form.values.union}
              onChange={(e) => form.setFieldValue('union', e.target.value)}
              IconComponent={SelectArrowDown}
            />
          </Grid>
        </Grid>
        <Grid item className={classes.twoColumn}>
          <Grid item>
            <InputLabel id="typeOfAudition" className={sectionClasses.selectLabelFix}>
              SAG/AFTRA Contract Type
            </InputLabel>
            <Select
              variant="outlined"
              fullWidth
              data={CONTRACT_TYPES}
              value={form.values.sag_aftra_contract_type}
              onChange={(e) => form.setFieldValue('sag_aftra_contract_type', e.target.value)}
              IconComponent={SelectArrowDown}
            />
          </Grid>
          <Grid item>
            <InputLabel id="projectRate">Project Rate / Payment Summary</InputLabel>
            <Select
              name="projectRate"
              variant="outlined"
              fullWidth
              data={[]}
              value={form.values.rate}
              onChange={(e) => form.setFieldValue('rate', e.target.value)}
              IconComponent={SelectArrowDown}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default GeneralProjectInformation;
