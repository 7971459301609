export enum SelfTapesTab {
  NEW = 'new',
  HIDDEN = 'hidden',
  CD_APPROVAL = 'cd-approval',
  APPROVED = 'approved',
  REQUESTED = 'requested',
  CONFIRMED = 'confirmed',
  DECLINED = 'declined',
  NO_RESPONSe = 'no-response',
}
