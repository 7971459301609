import { FormikProps, useFormik } from 'formik';
import { number, object, SchemaOf, string } from 'yup';

export interface ICreateNoteTextArea {
  note: string;
  stars: number;
}

type NoteTextFormProps = {
  onSubmit: (values: ICreateNoteTextArea) => void;
};
export const useNoteTextAreaForm = ({ onSubmit }: NoteTextFormProps) => {
  const validationSchema: SchemaOf<ICreateNoteTextArea> = object().shape({
    note: string().required('Note is required'),
    stars: number().required().min(1),
  });

  const initialValues: ICreateNoteTextArea = {
    stars: 0,
    note: '',
  };
  const form: FormikProps<ICreateNoteTextArea> = useFormik({
    initialValues,
    validationSchema,
    onSubmit: (values, helper) => {
      onSubmit(values);
      helper.resetForm();
    },
  });

  return {
    form,
  };
};
