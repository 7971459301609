import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    request__paper: {
      // padding: '20px 40px',
      maxWidth: 'auto',
    },
    request__dialogContent: {
      padding: '20px 20px',
      paddingBottom: 40,
    },
    request__container: {
      minWidth: 470,
    },
    request__content: {
      padding: '12px 24px',
      paddingTop: 20,
    },
    request__closeButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    request__title: {
      textAlign: 'center',
    },
    request__action: {
      display: 'flex',
      gap: 10,
      // justifyContent: 'space-between',
      marginTop: theme.spacing(4),
    },
    request__actionButton: {
      fontSize: 18,
      minWidth: 'auto',
      minHeight: 'auto',
      padding: '14px 18px',
      lineHeight: 1,
      border: '2px solid rgba(255, 255, 255, 0.23)',
      borderRadius: 8,
    },
    request__actionIconButton: {
      fontSize: 18,
      minWidth: 'auto',
      minHeight: 'auto',
      padding: '14px 14.9px',
      lineHeight: 1,
      border: '2px solid rgba(255, 255, 255, 0.23)',
      borderRadius: 8,
    },
    request__actionButton__active: {
      borderColor: theme.palette.primary.main,
    },
    request__agencyContainer: {
      marginTop: theme.spacing(3),
    },
    request__agency: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginTop: theme.spacing(0.5),
    },
    request__form: {
      marginTop: theme.spacing(2),
    },
    request__textArea: {
      padding: '10px 14px',
      marginTop: '8px !important',
    },
    request__sendContainer: {
      marginTop: theme.spacing(2),
    },
    request__sendButton: {
      padding: '12px 16px',
    },
  }),
);
