import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function ArrowDownIconTwoTone(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 13 14" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <title>expand</title>
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="linearGradient-1">
            <stop stop-color="#3E8BFF" offset="0%"></stop>
            <stop stop-color="#3E8BFF" offset="100%"></stop>
          </linearGradient>
        </defs>
        <g id="Page-1" stroke="none" stroke-width="1" fill="none" fillRule="evenodd">
          <g
            id="CreateNewProject1"
            transform="translate(-1721.000000, -645.000000)"
            fill="url(#linearGradient-1)"
            fill-rule="nonzero"
          >
            <g id="Group-34" transform="translate(141.000000, 612.000000)">
              <g id="Group-23" transform="translate(1580.000000, 31.000000)">
                <g id="expand" transform="translate(0.000000, 2.000000)">
                  <path
                    d="M11.719313,8.121 C11.3288131,7.73061806 10.6958128,7.73061806 10.305313,8.121 L7.01231299,11.413 L7.00531299,1 C7.00531299,0.44771525 6.55759774,0 6.00531299,0 C5.45302824,0 5.00531299,0.44771525 5.00531299,1 L5.01231299,11.414 L1.71931299,8.121 C1.46823852,7.86104342 1.0964351,7.7567874 0.746805669,7.84830284 C0.397176238,7.93981828 0.124131277,8.21286324 0.0326158368,8.56249268 C-0.0588996039,8.91212211 0.0453564089,9.28392553 0.305312993,9.535 L3.89031299,13.121 C4.45293673,13.683719 5.21607548,13.9998593 6.01181299,13.9998593 C6.80755051,13.9998593 7.57068926,13.683719 8.13331299,13.121 L11.719313,9.535 C12.1096949,9.14450015 12.1096949,8.51149985 11.719313,8.121 Z"
                    id="Path"
                  ></path>
                </g>
              </g>
            </g>
          </g>
        </g>
      </svg>
    </SvgIcon>
  );
}

export default ArrowDownIconTwoTone;
