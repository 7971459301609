import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    requests__history__notFW: {
      maxWidth: 400,
      [theme.breakpoints.down('lg')]: {
        maxWidth: 300,
      },
    },
    requests__history__detail: {
      width: '100%',
    },
    history__detail: {
      display: 'flex',
      alignItems: 'flex-start',
      gap: 8,
      marginTop: theme.spacing(1),
      [theme.breakpoints.down('lg')]: {
        '& p': {
          fontSize: '13px !important',
        },
      },
    },
  }),
);
