import { styled, Theme } from '@material-ui/core';

export const LineBorderParent = styled('div')<Theme, { $percent: number }>(({ $percent }) => ({
  display: 'flex',
  flexDirection: 'row',
  flexWrap: 'nowrap',
  '& > *:first-child': {
    background: '#3e8bff',
    width: `${$percent}%`,
    border: 'solid 1px #3e8bff',
  },
  '& > *:last-child': {
    flexGrow: 1,
    border: 'solid 1px #2d343e',
    background: '#2d343e',
  },
}));
