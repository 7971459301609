import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { clientAuditionScheduleDao } from 'shared/dao/clientAuditionScheduleDao';
import {
  IClientAuditionRequestPayload,
  IClientAuditionResponsePayload,
} from 'shared/interfaces/IClientAuditionSchedule';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';

const { getClientAuditionSchedules: getClientAuditionSchedulesDao } = clientAuditionScheduleDao();
export const clientAuditionSchedule = () => {
  const getClientAuditionSchedules = (
    payload: IClientAuditionRequestPayload,
    options?: Omit<
      UseQueryOptions<IClientAuditionResponsePayload, IAxiosErrorResponse, IClientAuditionResponsePayload, QueryKey>,
      'queryKey' | 'queryFn'
    >,
  ) => {
    return useQuery<IClientAuditionResponsePayload, IAxiosErrorResponse>(
      ['clientAuditionSchedule', { ...payload }],
      () => getClientAuditionSchedulesDao(payload),
      options,
    );
  };

  return {
    getClientAuditionSchedules,
  };
};
