import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function NotificationAlertColored(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 28 31" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="207.311%" y1="-34.558%" x2="-108.409%" y2="113.255%" id="fdsd8nvfca">
            <stop stop-color="#D51BFF" offset="0%" />
            <stop stop-color="#00D4FF" offset="100%" />
          </linearGradient>
        </defs>
        <path
          d="M13.75 0c.518 0 .938.42.938.938V3.8c4.385.469 7.812 4.192 7.812 8.7v3.485a8.375 8.375 0 0 0 2.972 6.406 2.188 2.188 0 0 1-1.41 3.859h-5.719A4.695 4.695 0 0 1 13.75 30a4.695 4.695 0 0 1-4.593-3.75h-5.72a2.19 2.19 0 0 1-1.422-3.85A8.377 8.377 0 0 0 5 15.985V12.5c0-4.508 3.426-8.23 7.812-8.7V.938c0-.518.42-.938.938-.938zm2.652 26.25h-5.304a2.817 2.817 0 0 0 2.652 1.875 2.817 2.817 0 0 0 2.652-1.874zM13.75 5.626A6.882 6.882 0 0 0 6.875 12.5v3.485c0 3.026-1.326 5.884-3.637 7.837a.312.312 0 0 0 .2.553h20.625c.17 0 .312-.143.312-.312a.308.308 0 0 0-.11-.238 10.248 10.248 0 0 1-3.64-7.84V12.5a6.882 6.882 0 0 0-6.875-6.875zm9.266-3.954A15.218 15.218 0 0 1 27.5 12.5a.938.938 0 0 1-1.875 0c0-3.59-1.397-6.964-3.935-9.503a.938.938 0 1 1 1.326-1.326zm-17.205.001a.938.938 0 0 1 0 1.327 13.343 13.343 0 0 0-3.936 9.5.938.938 0 0 1-1.875 0A15.21 15.21 0 0 1 4.485 1.673a.938.938 0 0 1 1.326 0z"
          transform="translate(0 .5)"
          fill="url(#fdsd8nvfca)"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default NotificationAlertColored;
