import { Button, DialogContent, Grid, IconButton, Link, Snackbar, Typography as Typography2 } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import { exportComponentAsJPEG } from 'react-component-export-image';
import { CloseIcon, CopyIconTwoTone } from '@room-match/shared-ui-components';
import clsx from 'clsx';
import React, { useRef } from 'react';
import { QRCode } from 'react-qrcode-logo';

import useCopyClipboard from 'react-use-clipboard';
import { StyledDialog, Typography } from 'shared/elements';
import { CONFIG } from 'shared/config';
import { useStyles } from './CheckInQRModal.styles';

type Props = {
  open: boolean;
  onClose: () => void;
  project: any | null;
};

const CheckInQRModal: React.FC<Props> = ({ open, onClose, project }) => {
  const qrRef = useRef<any>(null);
  const classes = useStyles();

  const URL = `${CONFIG.TALENT_APP_URL}/go/check-in/${project ? project.attributes.id : ''}`;
  const [isCopiedLinkQR, setIsCopiedLinkQR] = useCopyClipboard(URL, { successDuration: 1000 });
  // const [isCopiedSelfTapeLink, setIsCopiedSelfTapeLink] = useCopyClipboard(
  //   'https://AHC.SUBMITYOURSELFTAPE.com/target/23rdt',
  //   { successDuration: 1000 },
  // );

  const exportQr = () => {
    if (qrRef.current) {
      exportComponentAsJPEG(qrRef, {
        fileName: `${project ? project.attributes.name : 'project'}-check-in-qrcode`,
      });
    }
  };

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="md" fullWidth>
      <DialogContent classes={{ root: classes.qrModal__dialogContent }}>
        <div className={classes.qrModal__container}>
          {/* Header  */}
          <div className={classes.qrModal__header}>
            <Typography fontSize={25}>Checkin Link / QR Code for sharing</Typography>
            <IconButton size="small" onClick={() => onClose()}>
              <CloseIcon style={{ fontSize: 14 }} />
            </IconButton>
          </div>
          {/* Content */}
          <div className={classes.qrModal__content}>
            {/* Qr */}
            <Grid container spacing={2}>
              <Grid item xl={3} lg={3} md={4} sm={6}>
                <div className={classes.qrModal__qrContainer}>
                  <div className={classes.whiteContainer} ref={qrRef}>
                    <QRCode value={URL} logoImage="/casting-logo.png" removeQrCodeBehindLogo ecLevel="H" size={170} />
                  </div>
                </div>
              </Grid>
              <Grid item xl={2} lg={2} md={4} sm={6}>
                <div className={classes.qrModal__qrContainer}>
                  {/* <div className={classes.qrModal__imageContainer}>
                    <QRCode
                      value="https://shortened-proj-link.com"
                      logoImage="/casting-logo.png"
                      removeQrCodeBehindLogo
                      ecLevel="H"
                    />
                  </div> */}
                  <div className={classes.whiteContainerSmall}>
                    <QRCode value={URL} logoImage="/casting-logo.png" removeQrCodeBehindLogo ecLevel="H" />
                    <div className={classes.posterCls}>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vivamus accumsan mi et massa finibus,
                      quis vulputate leo dapibus. In hac habitasse platea dictumst. Fusce semper in ante sit amet
                      malesuada. Class aptent taciti sociosqu ad litora torquent per conubia nostra, per inceptos
                      himenaeos. Etiam mollis lacinia lacus, sed feugiat risus ultricies ac. Quisque gravida elit massa,
                      nec hendrerit nibh pellentesque a. Fusce eget accumsan libero. Proin elementum feugiat lacus id
                      ultricies.
                    </div>
                  </div>
                </div>
              </Grid>
              {/* Right Content */}
              <Grid item xl={7} lg={7} md={4} sm={12} className={classes.detailsContainer}>
                <div className={classes.qrModal__rightContent}>
                  {/* Link  */}
                  <div style={{ marginBottom: '5px' }}>
                    <Typography2 variant="caption">Checkin URL:</Typography2>
                  </div>
                  <div className={clsx(classes.qrModal__linkContainer, classes.qrModal__link)}>
                    <IconButton size="small" onClick={() => setIsCopiedLinkQR()}>
                      <CopyIconTwoTone style={{ fontSize: 14 }} />
                    </IconButton>
                    <Link href={URL}>{URL}</Link>
                  </div>
                </div>
                <Button className={classes.qrButton} disableElevation onClick={() => exportQr()}>
                  Download QR Code
                </Button>
                <Button className={classes.qrButton} disableElevation>
                  Print Poster (US Letter Size)
                </Button>
              </Grid>
            </Grid>
          </div>
        </div>
      </DialogContent>
      <Snackbar
        open={isCopiedLinkQR} // || isCopiedSelfTapeLink
        autoHideDuration={6000}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
      >
        <Alert severity="success">Copied to Clipboard</Alert>
      </Snackbar>
    </StyledDialog>
  );
};

export default CheckInQRModal;
