import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function FormTwoTone(props: SvgIconProps) {
  return (
    <SvgIcon width="16" height="20" viewBox="0 0 16 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="165.576%" y1="-34.558%" x2="-66.383%" y2="113.255%" id="53r4gju4na">
          <stop stop-color="#D51BFF" offset="0%" />
          <stop stop-color="#00D4FF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M12.143 0H.714A.714.714 0 0 0 0 .714V16.43A3.576 3.576 0 0 0 3.571 20H15c.394 0 .714-.32.714-.714V3.57A3.576 3.576 0 0 0 12.143 0zm2.143 18.571H3.57A2.143 2.143 0 0 1 1.43 16.43v-15h10.714c1.183 0 2.143.959 2.143 2.142v15zM12.143 5.714c0 .395-.32.715-.714.715H4.286a.714.714 0 0 1 0-1.429h7.143c.394 0 .714.32.714.714zm0 4.286c0 .394-.32.714-.714.714H4.286a.714.714 0 1 1 0-1.428h7.143c.394 0 .714.32.714.714zm0 4.286c0 .394-.32.714-.714.714H4.286a.714.714 0 1 1 0-1.429h7.143c.394 0 .714.32.714.715z"
        fill="url(#53r4gju4na)"
        fillRule="nonzero"
        opacity="1"
      />
    </SvgIcon>
  );
}

export default FormTwoTone;
