import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Decline(props: SvgIconProps) {
  return (
    <SvgIcon width="20" height="21" viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 .5c5.512 0 10 4.488 10 10s-4.483 10-10 10-10-4.488-10-10S4.488.5 10 .5zm0 1.666c-4.592 0-8.334 3.742-8.334 8.334S5.403 18.834 10 18.834c4.597 0 8.334-3.742 8.334-8.334S14.592 2.166 10 2.166zm3.576 3.58 1.178 1.178-3.577 3.576 3.577 3.576-1.178 1.178L10 11.677l-3.576 3.577-1.178-1.178L8.823 10.5 5.246 6.924l1.178-1.178L10 9.323l3.576-3.577z"
        fill="#E53737"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default Decline;
