import { Box, Grid, IconButton, Popover, Typography as Typography2 } from '@material-ui/core';
import {
  ImageIcon,
  FlagGreenIconTwoTone,
  YoutubeIcon,
  BlindIcon,
  ShareIcon,
  LoadingSpinnerIconTwoTone,
} from '@room-match/shared-ui-components';
import { ellipseText, humanizeString } from '@room-match/shared-utils';
import { AuditionFlagIcon } from 'components/AuditionFlagIcon';
import React from 'react';
import { StyledPopover, Typography } from 'shared/elements';
import { AuditionStatus } from 'shared/enums/AuditionStatus';
import { SelfTapesTab } from 'shared/enums/SelfTapesTab';
import { IAuditionAttributes } from 'shared/interfaces/IAudition';
import { profileService } from 'shared/services/profileService';
import { getFullName } from 'shared/utils/getFullName';
import { getMainAuditionSchedule } from 'shared/utils/getMainAuditionSchedule';
import { navigateToRoute } from 'shared/utils/navigateToRoute';
import { useStyles } from './TalentDescription.styles';

type Props = {
  isShowVideoExpanded: boolean;
  handleOpenShowVideoExpanded: () => void;
  handleCloseShowVideoExpanded: () => void;
  audition: IAuditionAttributes;
  auditionScheduleId?: string;
  handleSingleUpdateStatus: (auditionScheduleId: string, status: AuditionStatus) => void;
  handleOpenTalentProfile?: (instance: any, auditionScheduleId: string) => void;
  disabledUpdateStatus?: boolean;
  currentFlag?: string | null;
  status?: string;
};

const { createProfileLink } = profileService();
const TalentDescription: React.FC<Props> = ({
  isShowVideoExpanded,
  handleCloseShowVideoExpanded,
  handleOpenShowVideoExpanded,
  audition,
  auditionScheduleId,
  handleSingleUpdateStatus,
  handleOpenTalentProfile,
  disabledUpdateStatus,
  currentFlag,
  status,
}) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const { mutate: mutateCreateProfileLink, isLoading: isLoadingCreateProfileLink } = createProfileLink();

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  const mainAuditionSchedule = getMainAuditionSchedule(audition.audition_schedules || []);

  const handleCreateProfileLink = () => {
    if (audition.profile) {
      mutateCreateProfileLink(audition.profile.id, {
        onSuccess: (response) => {
          const token = response.data.attributes.token;
          window.open(navigateToRoute.toProfileLink(token), '_blank');
        },
      });
    }
  };

  return (
    <Box className={classes.requests__description}>
      <Box className={classes.requests__description__item}>
        <IconButton size="small" disabled={true}>
          <ImageIcon
            fontSize="small"
            classes={{ root: classes.requests__description__icon }}
            className={status !== 'Auditioned' && status !== 'Overtime' ? classes.ghosted : ''}
          />
        </IconButton>
        <Box className={classes.detail__description__textContainer}>
          <Typography2
            className={classes.nameWrap}
            style={{ fontSize: 15 }}
            onClick={() =>
              handleOpenTalentProfile ? handleOpenTalentProfile(audition.profile, auditionScheduleId || '') : null
            }
          >
            {getFullName(audition.profile.talent?.first_name, audition.profile.talent?.last_name) || '—'}
          </Typography2>
          <IconButton size="small">
            <AuditionFlagIcon flag={currentFlag || null} fontSize={16} />
          </IconButton>
        </Box>
      </Box>
      <Box className={classes.requests__description__item}>
        <IconButton
          size="small"
          onClick={() => {
            if (!isShowVideoExpanded) {
              handleOpenShowVideoExpanded();
            } else {
              handleCloseShowVideoExpanded();
            }
          }}
        >
          <YoutubeIcon
            fontSize="small"
            classes={{ root: classes.requests__description__icon }}
            className={status !== 'Auditioned' && status !== 'Overtime' ? classes.ghosted : ''}
          />
        </IconButton>
        <Typography fontSize={15} color="mainGrey">
          {humanizeString(audition.profile.talent?.sag_status || '').toUpperCase() || '—'}
        </Typography>
      </Box>
      <Box className={classes.requests__description__item}>
        <IconButton
          size="small"
          onClick={() => handleSingleUpdateStatus(mainAuditionSchedule.id, AuditionStatus.HIDDEN)}
          disabled={disabledUpdateStatus}
        >
          <BlindIcon
            classes={{ root: classes.requests__description__icon }}
            className={status !== 'Auditioned' && status !== 'Overtime' ? classes.ghosted : ''}
          />
        </IconButton>
        <Box>
          <Typography
            fontSize={15}
            color="primary"
            onMouseEnter={(e) => handlePopoverOpen(e)}
            onMouseLeave={() => handlePopoverClose()}
          >
            {ellipseText(audition.profile.agency?.name || '—', 20)}
          </Typography>
          <StyledPopover
            id="mouse-over-popover"
            className={classes.popover}
            open={open}
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'bottom',
              horizontal: 'left',
            }}
            transformOrigin={{
              vertical: 'top',
              horizontal: 'left',
            }}
            onClose={handlePopoverClose}
            disableRestoreFocus
          >
            <Grid container spacing={0} className={classes.popoverContainer}>
              <Grid item>
                <Typography color="primary" className={classes.popoverHeading}>
                  {audition.profile?.agency?.name}
                </Typography>
                <Typography fontSize={14}>{audition.profile?.agency?.company_type}</Typography>
              </Grid>

              <Grid item>
                <Typography color="primary" className={classes.popoverHeading}>
                  Rep:{' '}
                  {audition.profile?.agency?.contact_first_name && audition.profile?.agency?.contact_last_name
                    ? `${audition.profile?.agency?.contact_first_name} ${audition.profile?.agency?.contact_last_name}`
                    : '—'}
                </Typography>
                <Typography fontSize={14}>{audition.profile?.agency?.contact_number}</Typography>
                <Typography fontSize={14}>{audition.profile?.agency?.website}</Typography>
              </Grid>
            </Grid>
          </StyledPopover>
        </Box>
      </Box>
      <Box className={classes.requests__description__item}>
        {!isLoadingCreateProfileLink ? (
          <IconButton size="small" onClick={() => handleCreateProfileLink()} disabled={isLoadingCreateProfileLink}>
            <ShareIcon
              classes={{ root: classes.requests__description__icon }}
              className={status !== 'Auditioned' && status !== 'Overtime' ? classes.ghosted : ''}
            />
          </IconButton>
        ) : (
          <LoadingSpinnerIconTwoTone />
        )}

        <Typography fontSize={15} color="mainGrey">
          {audition.profile?.talent?.contact_no || '—'}
        </Typography>
      </Box>
    </Box>
  );
};

export default TalentDescription;
