import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import {
  IAuditionScheduleGroupRequestPayload,
  IAuditionScheduleGroupResponsePayload,
} from 'shared/interfaces/IAuditionScheduleGroup';
import { ICommonResponsePayload } from 'shared/interfaces/ICommon';
import { GET_AUDITION_SCHEDULE_GROUPS } from 'shared/queries/auditionScheduleGroup.queries';

const { POST, PATCH } = useAxios();
export const auditionScheduleGroupDao = () => {
  const getAuditionScheduleGroups = async (payload: IAuditionScheduleGroupRequestPayload) => {
    const response = await POST<IAuditionScheduleGroupResponsePayload>({
      url: ENDPOINTS.GRAPHQL,
      data: {
        query: GET_AUDITION_SCHEDULE_GROUPS,
        variables: { ...payload },
      },
    });

    return response.data;
  };

  const setAuditionScheduleGroupView = async (auditionScheduleGroupId: string) => {
    const response = await PATCH<ICommonResponsePayload>({
      url: `${ENDPOINTS.AUDITION_SCHEDULE_GROUPS}/${auditionScheduleGroupId}/viewing`,
    });
    return response.data;
  };

  const sortOrderAuditionScheduleGroup = async (auditionScheduleGroupId: string) => {
    const response = await PATCH<ICommonResponsePayload>({
      url: `${ENDPOINTS.AUDITION_SCHEDULE_GROUPS}/${auditionScheduleGroupId}/auditionScheduleGroupId`,
    });
    return response.data;
  };

  return {
    getAuditionScheduleGroups,
    setAuditionScheduleGroupView,
    sortOrderAuditionScheduleGroup,
  };
};
