import { Slider } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const HEIGHT = 4;
const HOVER_HEIGHT = 6;
export const CustomVideoPlayerSlider = withStyles((theme) => ({
  root: {
    color: '#fff',
    height: HEIGHT,
    '& .MuiSlider-valueLabel': {
      left: 'calc(-50% + 2px)',
    },
    '&:hover': {
      '& .MuiSlider-thumb': {
        display: 'block',
        // height: HOVER_HEIGHT * 2,
        height: 16,
        width: 16,
      },
      '& .MuiSlider-track': {
        height: HOVER_HEIGHT,
      },
      '& .MuiSlider-rail': {
        height: HOVER_HEIGHT,
      },
    },
    padding: 0,
  },
  thumb: {
    color: theme.palette.primary.main,
    display: 'none',
    '&.Mui-disabled': {
      height: 20,
      width: 20,
      marginTop: -8,
    },
  },
  track: {
    height: HEIGHT,
    color: theme.palette.primary.main,
  },
  rail: {
    height: HEIGHT,
    color: '#fff',
  },
  valueLabel: {
    zIndex: 9999,
    '& span span': {
      color: '#fff',
    },
  },
}))(Slider);
