import { Snackbar } from '@material-ui/core';
import { Alert } from '@material-ui/lab';
import React from 'react';
import { useDispatch } from 'react-redux';
import { selectUserState, setErrorMessage, setResponseMessage } from 'shared/redux/slicers/user.slicer';
import { useAppSelector } from 'shared/redux/store';

const SnackbarAlertWrapper = () => {
  const dispatch = useDispatch();
  const { errorMessage, responseMessage } = useAppSelector(selectUserState);

  const handleSnackBarClose = () => {
    dispatch(setErrorMessage(null));
    dispatch(setResponseMessage(null));
  };
  return (
    <div>
      <Snackbar open={!!errorMessage} autoHideDuration={2000} onClose={handleSnackBarClose}>
        <Alert severity="error" onClose={handleSnackBarClose}>
          {errorMessage}
        </Alert>
      </Snackbar>
      <Snackbar open={!!responseMessage} autoHideDuration={2000} onClose={handleSnackBarClose}>
        <Alert severity="success" onClose={handleSnackBarClose}>
          {responseMessage}
        </Alert>
      </Snackbar>
    </div>
  );
};

export default SnackbarAlertWrapper;
