import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function UserIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 18 19" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.346 11.031a.818.818 0 0 1 .801.224 8.963 8.963 0 0 1 2.498 4.522 2.23 2.23 0 0 1-.452 1.879 2.259 2.259 0 0 1-1.762.844H2.263A2.259 2.259 0 0 1 .5 17.659a2.23 2.23 0 0 1-.449-1.882 8.963 8.963 0 0 1 2.498-4.522.818.818 0 1 1 1.145 1.168 7.345 7.345 0 0 0-2.045 3.7.593.593 0 0 0 .124.504c.119.15.3.237.49.237h13.17a.628.628 0 0 0 .49-.237.593.593 0 0 0 .124-.505 7.345 7.345 0 0 0-2.045-3.7.818.818 0 0 1 .344-1.391zM8.847.5a5.727 5.727 0 0 1 0 11.455A5.734 5.734 0 0 1 3.12 6.227 5.727 5.727 0 0 1 8.847.5zm0 1.636a4.09 4.09 0 1 0 0 8.182 4.09 4.09 0 0 0 0-8.182z"
          fill={`${props.fill || '#677482'} `}
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default UserIcon;
