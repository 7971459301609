import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { AuditionType } from 'shared/enums/AuditionType';
import { useAxios } from 'shared/hooks/useAxios';
import {
  IRoleMutateResponsePayload,
  IRoleCreatePayload,
  IRoleResponsePayload,
  IRoleSideCreatePayload,
  IRoleSideCreateResponsePayload,
  IRoleUpdateRequestPayload,
  ISingleRoleResponsePayload,
} from 'shared/interfaces/IRole';

const { GET, POST, PATCH } = useAxios();
export const roleDao = () => {
  const getRoles = async (projectId: string, auditionType?: AuditionType) => {
    const response = await GET<IRoleResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/roles`,
      params: {
        audition_type: auditionType,
      },
    });
    return response.data;
  };

  const getSingleRole = async (projectId: string, roleId: string) => {
    const response = await GET<ISingleRoleResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/roles/${roleId}`,
    });
    return response.data;
  };

  const createRole = async (projectId: string, payload: IRoleCreatePayload) => {
    const response = await POST<IRoleMutateResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/roles`,
      data: payload,
    });

    return response.data;
  };

  const updateRole = async (projectId: string, roleId: string, payload: IRoleUpdateRequestPayload) => {
    const response = await PATCH<IRoleMutateResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/roles/${roleId}`,
      data: payload,
    });

    return response.data;
  };

  const createRoleSide = async (projectId: string, roleId: string, payload: IRoleSideCreatePayload) => {
    const response = await POST<IRoleSideCreateResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/roles/${roleId}/sides`,
      data: payload,
    });
    return response.data;
  };

  return {
    getRoles,
    getSingleRole,
    createRole,
    updateRole,
    createRoleSide,
  };
};
