import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function TwoFactor(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="w4nudx3tsa">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M8.245 11.719a.39.39 0 0 1 .078.773l-.078.008H1.953c-.603 0-1.101.458-1.165 1.044l-.007.128v4.375c0 .603.458 1.101 1.045 1.165l.127.007h16.094c.603 0 1.1-.458 1.165-1.045l.006-.127v-4.375c0-.603-.457-1.101-1.044-1.165l-.127-.007H11.76a.39.39 0 0 1-.079-.773l.08-.008h6.286c1.028 0 1.873.798 1.947 1.807l.006.146v4.375a1.956 1.956 0 0 1-1.808 1.948l-.145.005H1.953a1.956 1.956 0 0 1-1.948-1.808L0 18.047v-4.375c0-1.028.798-1.873 1.808-1.948l.145-.005h6.292zM10 13.906a.39.39 0 0 1 .383.312l.007.079v.886l.768-.443a.39.39 0 0 1 .455.63l-.065.046-.767.443.767.443a.39.39 0 0 1-.327.706l-.063-.029-.768-.443v.886a.39.39 0 0 1-.773.079l-.008-.08v-.885l-.767.443a.389.389 0 0 1-.534-.143.39.39 0 0 1 .08-.487l.063-.047.768-.443-.768-.443a.39.39 0 0 1 .319-.709l.072.033.767.443v-.886a.39.39 0 0 1 .39-.39zm-5.352 0a.39.39 0 0 1 .391.391v.886l.767-.443a.39.39 0 1 1 .39.676l-.766.443.767.443a.39.39 0 1 1-.39.677l-.768-.443v.886a.39.39 0 0 1-.781 0v-.886l-.767.443a.389.389 0 0 1-.534-.143.39.39 0 0 1 .143-.534l.767-.443-.767-.443a.39.39 0 1 1 .39-.676l.768.443v-.886a.39.39 0 0 1 .39-.39zm10.703 0a.39.39 0 0 1 .39.391v.886l.768-.443a.39.39 0 1 1 .39.676l-.766.443.767.443a.39.39 0 1 1-.39.677l-.768-.443v.886a.39.39 0 0 1-.781 0v-.886l-.768.443a.389.389 0 0 1-.533-.143.39.39 0 0 1 .143-.534l.767-.443-.767-.443a.39.39 0 1 1 .39-.676l.768.443v-.886a.39.39 0 0 1 .39-.39zm-5.349-2.185a.394.394 0 0 1 .39.391.392.392 0 0 1-.666.276.393.393 0 0 1 0-.552.393.393 0 0 1 .276-.115zM10 0a2.737 2.737 0 0 1 2.734 2.734v.675h.078c.905 0 1.64.736 1.64 1.641v4.09c0 .905-.735 1.641-1.64 1.641H7.187c-.904 0-1.64-.736-1.64-1.64V5.05c0-.905.736-1.64 1.64-1.64h.079v-.676A2.737 2.737 0 0 1 10 0zm2.812 4.19H7.187a.86.86 0 0 0-.859.86v4.09c0 .475.386.86.86.86h5.624a.86.86 0 0 0 .86-.86V5.05a.86.86 0 0 0-.86-.86zM10 5.87a.39.39 0 0 1 .39.391V7.93a.39.39 0 0 1-.78 0V6.26a.39.39 0 0 1 .39-.39zm0-5.089a1.955 1.955 0 0 0-1.953 1.953v.675h3.906v-.675A1.955 1.955 0 0 0 10 .781z"
        fill="url(#w4nudx3tsa)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default TwoFactor;
