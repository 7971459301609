import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Contacts(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="22" height="25" viewBox="0 0 22 25" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M18.274.842A2.853 2.853 0 0 1 21.126 3.7v18.286a2.858 2.858 0 0 1-2.858 2.857H4.766a2.858 2.858 0 0 1-2.858-2.857v-1.234H.571a.571.571 0 1 1 0-1.143h1.337v-3.749H.571a.571.571 0 1 1 0-1.142h1.337v-3.749H.571a.571.571 0 1 1 0-1.143h1.337V6.077H.571a.571.571 0 1 1 0-1.143h1.343V3.699A2.858 2.858 0 0 1 4.771.842zm0 1.143H4.771A1.72 1.72 0 0 0 3.057 3.7v1.235h1.337a.571.571 0 1 1 0 1.143H3.057v3.748h1.337a.571.571 0 1 1 0 1.143H3.057v3.749h1.337a.571.571 0 1 1 0 1.142H3.057v3.749h1.337a.571.571 0 1 1 0 1.143H3.057v1.234A1.72 1.72 0 0 0 4.771 23.7h13.503a1.72 1.72 0 0 0 1.715-1.714V3.7a1.72 1.72 0 0 0-1.715-1.714zM11.52 13.597c2.377 0 4.24 1.925 4.24 4.388 0 .315-.256.57-.571.572H7.85a.573.573 0 0 1-.571-.572c0-2.463 1.863-4.388 4.24-4.388zm0 1.142c-1.577 0-2.806 1.115-3.052 2.675h6.103c-.24-1.56-1.474-2.675-3.051-2.675zm0-7.605a2.771 2.771 0 1 1 0 5.543 2.771 2.771 0 0 1 0-5.543zm0 1.137a1.629 1.629 0 1 0 0 3.257 1.629 1.629 0 0 0 0-3.257z"
          fill="#838D9C"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default Contacts;
