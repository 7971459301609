import React from 'react';
import { LineBorderParent } from './LineBorder.styles';

type Props = {
  percent?: number;
};
const LineBorder: React.FC<Props> = ({ percent = 10 }) => {
  return (
    <LineBorderParent $percent={percent}>
      <div />
      <div />
    </LineBorderParent>
  );
};

export default LineBorder;
