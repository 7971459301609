import clsx from 'clsx';
import React, { useEffect, useState } from 'react';
import { useStyles } from './Image.styles';

const CustomImage: React.FC<React.DetailedHTMLProps<React.ImgHTMLAttributes<HTMLImageElement>, HTMLImageElement>> = ({
  ...props
}) => {
  const classes = useStyles();
  const [imageSrc, setImageSrc] = useState<string | undefined>(props.src);
  const [isImageLoaded, setIsImageLoaded] = useState<boolean>(false);

  useEffect(() => {
    const image = new Image();
    image.src = props.src || '';
    image.onload = () => {
      setImageSrc(props.src);
      setIsImageLoaded(true);
    };
  }, [props.src]);

  return (
    <img
      {...props}
      src={imageSrc}
      key={Math.random()}
      className={clsx(
        classes.img,
        {
          [classes.loaded]: isImageLoaded,
        },
        props.className,
      )}
    />
  );
};

export default React.memo(CustomImage);
