import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import {
  ICreateContactPayload,
  ICreateContactResponsePayload,
  IContactResponsePayload,
} from 'shared/interfaces/IContact';

const { GET, POST, PATCH } = useAxios();
export const contactDao = () => {
  const getContacts = async () => {
    const response = await GET<IContactResponsePayload>({
      url: ENDPOINTS.CONTACT,
    });
    return response.data;
  };

  const createContact = async (payload: ICreateContactPayload) => {
    const response = await POST<ICreateContactResponsePayload>({
      url: ENDPOINTS.CONTACT,
      data: payload,
    });

    return response.data;
  };

  const updateContact = async (contactId: string, payload: ICreateContactPayload) => {
    const response = await PATCH<ICreateContactResponsePayload>({
      url: `${ENDPOINTS.CONTACT}/${contactId}`,
      data: payload,
    });
    return response.data;
  };

  const sendContactInvite = async (contactId: string, projectId: string) => {
    const response = await POST<ICreateContactResponsePayload>({
      url: `${ENDPOINTS.CONTACT}/${contactId}/invites`,
      params: {
        project_id: projectId,
      },
    });

    return response.data;
  };

  return {
    getContacts,
    createContact,
    updateContact,
    sendContactInvite,
  };
};
