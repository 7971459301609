import { gql } from 'graphql-request';

export const GET_SESSION_MEDIUM_ATTACHMENTS = gql`
  query ($sessionId: ID!, $search: String!) {
    session_medium_attachments(sessionId: $sessionId, search: $search) {
      id
      primary
      medium_id
      mediumable_type
      medium {
        id
        file_name
        file_size
        file_width
        file_height
        visible
      }
    }
  }
`;
