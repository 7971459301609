import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function DocumentUpload(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="21" viewBox="0 0 16 21" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="3y2mnahaja">
            <stop stopColor="#3E8BFF" offset="0%" />
            <stop stopColor="#3E8BFF" offset="100%" />
          </linearGradient>
        </defs>
        <path
          d="M8.78 0a2.024 2.024 0 0 1 1.42.589L15.411 5.8c.369.382.579.89.589 1.42V18a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2zm-.113 1.333H2A.667.667 0 0 0 1.333 2v16c0 .368.299.667.667.667h12a.667.667 0 0 0 .667-.667V7.333h-4a2 2 0 0 1-2-2v-4zM8 8.667c.368 0 .667.298.667.666v2h2a.667.667 0 0 1 0 1.334h-2v2a.667.667 0 0 1-1.334 0v-2h-2a.667.667 0 0 1 0-1.334h2v-2c0-.368.299-.666.667-.666zm2-6.391v3.057c0 .369.298.667.667.667h3.057L10 2.276z"
          transform="translate(0 .5)"
          fill="url(#3y2mnahaja)"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default DocumentUpload;
