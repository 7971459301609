import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      backgroundImage: 'linear-gradient(to bottom, #0D0F14, #0D0F14), linear-gradient(253deg, #d51bff, #00d4ff)',
    },
    dialogContent: {
      padding: '32px 64px',
    },
    titleContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(4),
    },
    datePicker: {
      backgroundColor: '#101318 !important',
      '&.mbsc-material.mbsc-calendar-controls': {
        backgroundColor: 'transparent !important',
      },
      '&.mbsc-material-dark.mbsc-datepicker-inline .mbsc-calendar-slide': {
        backgroundColor: '#101318 !important',
      },
      '& .mbsc-range-day:after': {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      '& .mbsc-material-dark.mbsc-selected .mbsc-calendar-cell-text': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        borderColor: `${theme.palette.primary.main} !important`,
        color: '#fff',
      },
      '& .mbsc-material.mbsc-icon-button .mbsc-icon': {
        color: `${theme.palette.primary.main}`,
      },
    },
    buttonContainer: {
      marginTop: theme.spacing(6),
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);
