import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { DialogContent, Box, Button, Grid } from '@material-ui/core';
import { CloseIcon, SuccessIcon, ErrorIcon, Avatar, Typography } from '@room-match/shared-ui-components';
import { useStyles, DefaultDialog } from './ProjectPermission.styles';
import { Checkbox } from 'shared/elements';
import { permissionService } from 'shared/services/permissionService';
import { staffService } from 'shared/services/staffService';

type Props = {
  id: string;
  currentPermissions: string[];
  name?: string;
  position?: string;
  open: boolean;
  onClose: (instance?: any) => void;
};

const ProjectPermission: React.FC<Props> = ({ id: staffId, currentPermissions, name, position, open, onClose }) => {
  const classes = useStyles();
  const [permissions, setPermissions] = useState<string[]>([]);
  const permissionList = [
    'Adding Staff',
    'Adding Internal Notes',
    'Archiving Projects',
    'Creating New Projects',
    'Editing Company Information/Settings',
  ];

  const reload = () => {
    window.location.reload();
  };

  useEffect(() => {
    if (currentPermissions) {
      setPermissions(currentPermissions);
    }
  }, [currentPermissions]);

  const handleSelectAllPermission = (isChecked: boolean) => {
    if (!isChecked) {
      setPermissions([]);
    } else {
      setPermissions(permissionList.map((i: any) => i));
    }
  };

  const handleCheckChange = (value: string, isChecked: boolean) => {
    if (!isChecked) {
      setPermissions(
        permissions.filter(function (k) {
          return k !== value;
        }),
      );
    } else {
      setPermissions((v) => [...v, value]);
    }
  };

  const handleSubmitPermissions = async () => {
    onClose(permissions);
  };

  return (
    <DefaultDialog
      open={open}
      onClose={() => onClose()}
      maxWidth="sm"
      fullWidth
      className={classes.dialog}
      aria-labelledby="simple-dialog-title"
    >
      <DialogContent>
        <Grid className={classes.closeIcon}>
          <Box component="div" onClick={() => onClose()}>
            <CloseIcon style={{ width: '14px' }} />
          </Box>
        </Grid>
        <Grid container className={classes.body}>
          <Grid item className={classes.header}>
            <Grid item>
              <Avatar title={name} className={classes.avatar} />
            </Grid>
            <Grid item>
              <Typography fontSize={25}>Edit Staff Permission</Typography>
              <Typography fontSize={13} color="mainGrey">
                {name} - {position}
              </Typography>
            </Grid>
          </Grid>
          <Grid item className={classes.content}>
            <Box component="div" className={classes.allPermission}>
              <Checkbox
                value={permissions.length === permissionList.length}
                label="All Admin Permissions"
                labelPlacement="end"
                onChange={(e) => handleSelectAllPermission(e.target.checked)}
              />
            </Box>
            <Box component="div" className={classes.permissionCheckboxesContainer}>
              {permissionList.map((i: string) => (
                <Checkbox
                  value={permissions.includes(i)}
                  label={i}
                  labelPlacement="end"
                  onChange={(e) => handleCheckChange(i, e.target.checked)}
                />
              ))}
            </Box>
          </Grid>
          <Grid item>
            <Button
              fullWidth
              className={classes.btn}
              onClick={() => {
                handleSubmitPermissions();
              }}
            >
              Save Staff Permissions
            </Button>
          </Grid>
        </Grid>
      </DialogContent>
    </DefaultDialog>
  );
};

export default ProjectPermission;
