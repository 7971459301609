import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const ResetIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="gwpfb31pza">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M13.467 7.376A6.85 6.85 0 1 1 5.08 2.532l.416-.11-1.421-.823A.857.857 0 0 1 4.93.115l3.46 1.998a.57.57 0 0 1 .208.779L6.6 6.354a.856.856 0 1 1-1.481-.856l.819-1.424-.283.077c-2.734.73-4.478 3.508-3.785 6.254a5.138 5.138 0 1 0 9.057-4.387.895.895 0 0 1-.191-.549.853.853 0 0 1 1.521-.528c.564.722.976 1.55 1.21 2.435z"
        fill="url(#gwpfb31pza)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default ResetIcon;
