import { QueryKey, useMutation, useQuery, UseQueryOptions } from 'react-query';
import { projectSideDao } from 'shared/dao/projectSideDao';
import { IProjectSideResponsePayload } from 'shared/interfaces/IProjectSide';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';

const { getProjectSides: getProjectSidesDao, attachSides: attachSidesDao } = projectSideDao();
export const projectSideService = () => {
  const getProjectSides = (
    projectId: string,
    options?: Omit<
      UseQueryOptions<IProjectSideResponsePayload, IAxiosErrorResponse, IProjectSideResponsePayload, QueryKey>,
      'queryKey' | 'queryFn'
    >,
  ) => {
    return useQuery<IProjectSideResponsePayload, IAxiosErrorResponse>(
      ['projectSides', projectId],
      () => getProjectSidesDao(projectId),
      options,
    );
  };

  const attachSides = () => {
    return useMutation<IProjectSideResponsePayload, IAxiosErrorResponse, { projectId: string; mediumIds: string[] }>(
      ({ projectId, mediumIds }) => attachSidesDao(projectId, mediumIds),
    );
  };

  return {
    getProjectSides,
    attachSides,
  };
};
