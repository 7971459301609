import {
  ShadedStarIconTwoTone,
  ShadedStartBlueIconTwoTone,
  UnshadedStarIconTwoTone,
} from '@room-match/shared-ui-components';
import { getTimeAgo } from '@room-match/shared-utils';
import React from 'react';
import { NO_IMAGE_PLACEHOLDER_URL } from 'shared/constants/ASSETS_URL';
import { Typography } from 'shared/elements';
import { IComment } from 'shared/interfaces/utils/IComment';
import { getFullName } from 'shared/utils/getFullName';
import { useStyles } from './CommentTab.styles';

type Props = {
  comments: IComment[];
  isYellowStar?: boolean;
};
const CommentTab: React.FC<Props> = ({ comments, isYellowStar }) => {
  const classes = useStyles();
  return (
    <div className={classes.commentTab}>
      {comments.map((comment, i) => (
        <div className={classes.commentTab__item} key={i}>
          <div className={classes.commentTab__personContainer}>
            <div className={classes.commentTab__imageContainer}>
              <img
                src={comment.user?.medium_attachment?.data?.attributes?.attachment || NO_IMAGE_PLACEHOLDER_URL}
                className={classes.commentTab__image}
              />
            </div>
            <div className={classes.commentTab__detailContainer}>
              <div className={classes.commentTab__personDetail}>
                <Typography fontSize={14} fontWeight={500}>
                  {getFullName(comment.user?.first_name, comment.user?.last_name)}
                </Typography>
                <Typography fontSize={14} fontWeight={500} color="mainGrey">
                  {getTimeAgo(new Date(comment.created_at))}
                </Typography>
              </div>
              <div className={classes.commentTab__startIconContainer}>
                {!comment.rating ? (
                  <>
                    {Array.from({ length: 5 }).map((_, i) => (
                      <UnshadedStarIconTwoTone key={i} classes={{ root: classes.commentTab__startIcon }} />
                    ))}
                  </>
                ) : (
                  <>
                    {Array.from({ length: comment.rating }).map((_, i) => (
                      <>
                        {isYellowStar ? (
                          <ShadedStarIconTwoTone key={i} classes={{ root: classes.commentTab__startIcon }} />
                        ) : (
                          <ShadedStartBlueIconTwoTone key={i} classes={{ root: classes.commentTab__startIcon }} />
                        )}
                      </>
                    ))}
                    {Array.from({ length: 5 - comment.rating }).map((_, i) => (
                      <UnshadedStarIconTwoTone key={i} classes={{ root: classes.commentTab__startIcon }} />
                    ))}
                  </>
                )}
              </div>
            </div>
          </div>

          <div className={classes.commentTab__commentContainer}>
            <Typography fontSize={14} fontWeight={500} style={{ lineHeight: 1.2 }}>
              {comment.comment}
            </Typography>
          </div>
        </div>
      ))}
    </div>
  );
};

export default CommentTab;
