import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function HorizontalBarsIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="ydjy394pka">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M180 9h16a1 1 0 1 1 0 2h-16a1 1 0 1 1 0-2zm0 7h16a1 1 0 1 1 0 2h-16a1 1 0 1 1 0-2zm0 7h16a1 1 0 1 1 0 2h-16a1 1 0 1 1 0-2z"
        transform="translate(-179 -9)"
        fill="url(#ydjy394pka)"
        fillRule="evenodd"
      />
    </SvgIcon>
  );
}

export default HorizontalBarsIcon;
