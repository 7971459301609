import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const DownloadIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="16" viewBox="0 0 18 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.455 13.818V10.91a.727.727 0 0 0-1.455 0v2.91a.727.727 0 0 1-.727.726H2.182a.727.727 0 0 1-.727-.727V10.91a.727.727 0 0 0-1.455 0v2.91C0 15.022.977 16 2.182 16h13.09a2.182 2.182 0 0 0 2.183-2.182zm-4.64-3.796L9.178 12.93a.727.727 0 0 1-.902 0L4.64 10.02a.727.727 0 0 1 .902-1.134L8 10.851V.727a.727.727 0 0 1 1.455 0v10.124l2.458-1.964a.727.727 0 1 1 .902 1.135z"
        fill={props.htmlColor || '#646C75'}
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default DownloadIcon;
