import { Box, FormControlLabel, CheckboxProps, Checkbox } from '@material-ui/core';
import { CheckboxCheckIconTwoTone } from 'components';
import React from 'react';

import { useStyles } from './Checkbox.styles';

type Props = {
  errorMessage?: string;
  label?: string;
  value?: boolean | string;
  name?: string;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  checked?: boolean;
  labelPlacement?: 'top' | 'start' | 'bottom' | 'end';
  formControlLabelStyle?: React.CSSProperties;
} & CheckboxProps;
const FormInput: React.FC<Props> = ({
  errorMessage,
  label,
  value,
  name,
  onChange,
  checked,
  labelPlacement,
  formControlLabelStyle,
  ...props
}) => {
  const classes = useStyles();

  return (
    <FormControlLabel
      value={value}
      className={classes.container}
      control={
        <Checkbox
          icon={<span className={classes.icon} />}
          checked={checked ? checked : typeof value === 'boolean' ? value : !value}
          onChange={onChange}
          name={name}
          checkedIcon={
            <span className={classes.checkedIcon}>
              <CheckboxCheckIconTwoTone
                style={{ height: '8px', width: '10px', position: 'absolute', left: '12px', top: '12px' }}
              />
            </span>
          }
          {...props}
        />
      }
      label={label}
      labelPlacement={labelPlacement}
      style={formControlLabelStyle}
    />
  );
};

export default FormInput;
