import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    rightContainer: {},
    expandedDiv__talentContainer: {
      display: 'flex',
      gap: 16,
      alignItems: 'center',
    },
    expandedDiv__imageContainer: {
      width: 150,
      height: 80,
      overflow: 'hidden',
      borderRadius: 4,
      position: 'relative',
    },
    expandedDiv__image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    expandedDiv__detailContainer: {
      flex: '1',
      display: 'flex',
      gap: 4,
      justifyContent: 'space-between',
    },
    expandedDiv__details: {
      display: 'flex',
      flexDirection: 'column',
      gap: 4,
    },
    expandedDiv__iconsContainer: {
      display: 'flex',
      gap: 12,
    },
    expandedDiv__iconItem: {
      display: 'flex',
      gap: 6,
      alignItems: 'center',
    },
    expandedDiv__iconItem__hidden: {
      opacity: 0.4,
    },
    expandedDiv__icon: {
      fontSize: 16,
    },
    noteTextAreaContainer: {
      margin: theme.spacing(2, 0),
    },
    notePaper: {
      marginTop: theme.spacing(2),
    },
    noteLabel: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
      paddingBottom: theme.spacing(1),
      borderBottom: `1px solid ${theme.palette.primary.main}`,
    },
    expandedDiv__commentSectionContainer: {
      marginTop: theme.spacing(4),
    },
    expandedDiv__commentSection__content: {},
    buttonIcon: {
      fontSize: '16px !important',
    },
    actionButton: {
      backgroundColor: '#1b232d',
      color: '#fff',
      '&:hover': {
        backgroundColor: theme.palette.customBlack.dark,
      },
    },
  }),
);
