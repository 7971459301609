import { Tooltip, Typography } from '@material-ui/core';
import { TrashErrorIconTwoTone, WarningCircleFilledIcon } from 'components';
import React, { useEffect, useState } from 'react';
import { DeleteCont, EditItemCont, ImageThumb, WarningCont } from './EditItem.styles';

export enum ColorGauge {
  PASS = '#1fa00f',
  WARNING = '#daa13e',
  FAIL = '#e34343',
}

enum ImageQuanlityLevel {
  None,
  Reject,
  Bad,
  Accept,
}

const rejectWidthQuanlity = 720;
const rejectHeightQuanlity = 720;
const acceptWidthQuanlity = 1080;
const acceptHeightQuanlity = 1080;
interface Props {
  image: File;
  onSelect: (file: File) => void;
  active: boolean;
  edited: boolean;
  delete: (filename: string) => void;
  lastItem: boolean;
}

function EditItem({ image, onSelect, active, edited, delete: remove, lastItem }: Props) {
  const source = URL.createObjectURL(image);
  const [qualityLevel, setQualityLevel] = useState<ImageQuanlityLevel>(ImageQuanlityLevel.None);

  useEffect(() => {
    const imageData: HTMLImageElement = new Image();

    imageData.onload = function () {
      if (imageData.naturalWidth < rejectWidthQuanlity || imageData.naturalHeight < rejectHeightQuanlity) {
        setQualityLevel(ImageQuanlityLevel.Reject);
        // setErrorSnackbarOpen(true);
      } else if (imageData.naturalWidth < acceptWidthQuanlity || imageData.naturalHeight < acceptHeightQuanlity) {
        setQualityLevel(ImageQuanlityLevel.Bad);
        // setWarningSnackbarOpen(true);
      } else {
        setQualityLevel(ImageQuanlityLevel.Accept);
      }
    };

    imageData.src = source;
  }, [image, source]);

  return (
    <EditItemCont
      onClick={() => onSelect(image)}
      style={{
        padding: 1,
        border: `2px solid ${
          qualityLevel === ImageQuanlityLevel.Accept
            ? ColorGauge.PASS
            : qualityLevel === ImageQuanlityLevel.Bad
            ? ColorGauge.WARNING
            : ColorGauge.FAIL
        }`,
        backgroundImage: active
          ? `linear-gradient(to bottom, #151a21, #151a21), linear-gradient(253deg, #d51bff, #00d4ff)`
          : '',

        overflow: 'hidden',
      }}
    >
      {edited && (
        <Tooltip title="This image needs edit" placement="top-end">
          <WarningCont>
            <WarningCircleFilledIcon />
          </WarningCont>
        </Tooltip>
      )}
      {!lastItem && (
        <DeleteCont
          className="delete-img"
          onClick={(e) => {
            e.stopPropagation();
            remove(image.name);
          }}
        >
          <TrashErrorIconTwoTone style={{ width: 15, height: 15 }} />
        </DeleteCont>
      )}
      <ImageThumb src={source} />
      <Typography noWrap style={{ fontSize: 12, padding: '0 5px' }}>
        {image.name}
      </Typography>
    </EditItemCont>
  );
}

export default EditItem;
