import { combineReducers } from '@reduxjs/toolkit';
import themeReducer from './slicers/theme.slicer';
import userReducer from './slicers/user.slicer';
import talentProfileReducer from './slicers/talentProfile.slicer';
import auditionSlicer from './slicers/audition.slicer';
import appSlicer from './slicers/app.slicer';
import clientPermissionSlicer from './slicers/clientPermission.slicer';

const rootReducer = combineReducers({
  app: appSlicer,
  theme: themeReducer,
  user: userReducer,
  talentProfile: talentProfileReducer,
  audition: auditionSlicer,
  clientPermission: clientPermissionSlicer,
});

export default rootReducer;
