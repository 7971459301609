import { InputLabel, TextareaAutosizeProps, TextareaAutosize as MUITextareaAutosize, Tooltip } from '@material-ui/core';
import clsx from 'clsx';
import React from 'react';
import { useStyles } from './TextareaAutosize.styles';

type Props = {
  label?: string;
  tooltip?: string;
} & TextareaAutosizeProps;
const TextareaAutosize: React.FC<Props> = ({ label, tooltip, ...props }) => {
  const classes = useStyles();
  return (
    <div className={classes.container}>
      <InputLabel>
        {label}
        {!!tooltip && (
          <Tooltip title={tooltip}>
            <span>?</span>
          </Tooltip>
        )}
      </InputLabel>
      <MUITextareaAutosize
        {...props}
        className={clsx(classes.textAreaCls, {
          [classes.withLabel]: !!label,
        })}
      />
    </div>
  );
};
export default React.memo(TextareaAutosize);
