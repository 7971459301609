import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    talentProfileMediaContainer: {},
    primaryImage: {
      borderRadius: 4,
      height: 300,
      width: '100%',

      overflow: 'hidden',
    },
    primaryImage__img: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: 4,
    },
    imageListContainer: {
      marginTop: 16,
    },
    imageList: {
      // height: 420,
      overflow: 'overlay',
    },
    imageListItem: {
      borderRadius: 4,
      cursor: 'pointer',
    },
    videoListContainer: {
      marginTop: theme.spacing(4),
    },
    videoPlayerContainer: {
      width: '100%',
      height: 90,
      overflow: 'hidden',
      borderRadius: 4,
      position: 'relative',
      backgroundColor: 'black',
    },
    videoPlayerThumbnail: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    videoPlayerButton: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      zIndex: 999,
    },
    audioListContainer: {
      marginTop: theme.spacing(4),
    },
    audioPlayer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.customBlack.light,
      padding: theme.spacing(0.5),
      borderRadius: 4,
    },
    audioPlayer__description: {},
  }),
);
