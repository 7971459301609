import { Button, DialogActions, DialogContent, IconButton } from '@material-ui/core';
import { Avatar, CloseIcon, Typography } from '@room-match/shared-ui-components';
import React, { useMemo } from 'react';
import { StyledDialog } from 'shared/elements';
import { IAuditionScheduleGroupAttributes } from 'shared/interfaces/IAuditionScheduleGroup';
import { IProjectAttributes } from 'shared/interfaces/IProject';
import { IRoleAttributes } from 'shared/interfaces/IRole';
import { ISessionAttributes } from 'shared/interfaces/ISession';

import AuditionGroupItem from './AuditionGroupItem/AuditionGroupItem';
import { useStyles } from './DownloadAuditionModal.styles';

type Props = {
  open: boolean;
  onClose: () => void;
  auditionScheduleGroups: IAuditionScheduleGroupAttributes[];
  session: ISessionAttributes;
  role: IRoleAttributes;
  project: IProjectAttributes;
  type?: 'sessions' | 'roles';
};
const DownloadAuditionModal: React.FC<Props> = ({
  open,
  onClose,
  auditionScheduleGroups,
  session,
  role,
  project,
  type = 'sessions',
}) => {
  const classes = useStyles();

  const filteredAuditionSchedules = useMemo(() => {
    return type === 'sessions' ? auditionScheduleGroups : auditionScheduleGroups;
  }, [type, auditionScheduleGroups]);

  return (
    <StyledDialog
      open={open}
      onClose={() => onClose()}
      maxWidth="lg"
      fullWidth
      className={classes.dialog}
      aria-labelledby="simple-dialog-title"
    >
      <DialogContent>
        <div className={classes.heading}>
          <div className={classes.heading__title}>
            <Avatar title={project.name} style={{ height: '50px', width: '50px' }} />
            <Typography>{project.name}</Typography>
          </div>
          <IconButton size="small" onClick={() => onClose()}>
            <CloseIcon fontSize="small" />
          </IconButton>
        </div>

        <div className={classes.content}>
          {type === 'sessions' ? <Typography>{session.name}</Typography> : <Typography>{role.name}</Typography>}

          <div className={classes.listContainer}>
            {filteredAuditionSchedules.map((group) => (
              <AuditionGroupItem key={group.id} auditionScheduleGroup={group} />
            ))}
          </div>
        </div>
      </DialogContent>
      <DialogActions className={classes.dialog__action}>
        <Button fullWidth color="primary" variant="contained" style={{ height: '50px' }}>
          Download All Filtered Audition
        </Button>
      </DialogActions>
    </StyledDialog>
  );
};

export default DownloadAuditionModal;
