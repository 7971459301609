import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const FacebookOutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="13" height="20" viewBox="0 0 13 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.889 20H4.686a.971.971 0 0 1-.97-.97v-7.224H1.847a.971.971 0 0 1-.97-.97V7.74c0-.535.435-.97.97-.97h1.869V5.22c0-1.537.483-2.845 1.396-3.782C6.029.498 7.31 0 8.818 0l2.442.004c.534 0 .969.436.969.97v2.874c0 .535-.435.97-.97.97l-1.645.001c-.501 0-.629.1-.656.131-.045.052-.099.196-.099.595V6.77h2.276a.974.974 0 0 1 .98.97l-.002 3.096c0 .534-.435.97-.97.97H8.86v7.224c0 .535-.435.97-.97.97zm-3-1.172h2.798V11.28c0-.357.29-.648.647-.648h2.607l.001-2.69H8.334a.648.648 0 0 1-.647-.648v-1.75c0-.458.046-.979.392-1.37.418-.474 1.076-.528 1.535-.528l1.442-.001v-2.47l-2.24-.004c-2.422 0-3.928 1.551-3.928 4.048v2.075c0 .357-.29.647-.647.647H2.05v2.691h2.19c.358 0 .648.29.648.648v7.547z"
        fill="#FFF"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default FacebookOutlinedIcon;
