import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function TikTokIconTwoTone(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M7.27 4h17.46A3.27 3.27 0 0 1 28 7.27v17.46A3.27 3.27 0 0 1 24.73 28H7.27A3.27 3.27 0 0 1 4 24.73V7.27A3.27 3.27 0 0 1 7.27 4z"
        fill="#212121"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.472 13.738A6.392 6.392 0 0 0 23.2 14.93v-2.674c-.263 0-.525-.027-.783-.082v2.105a6.397 6.397 0 0 1-3.728-1.192v5.456a4.943 4.943 0 0 1-7.697 4.106 4.944 4.944 0 0 0 8.48-3.455v-5.456zm.966-2.697a3.73 3.73 0 0 1-.966-2.182v-.344h-.742a3.744 3.744 0 0 0 1.708 2.526zm-7.719 9.514a2.261 2.261 0 0 1 2.487-3.523v-2.734a5.03 5.03 0 0 0-.783-.045v2.127a2.261 2.261 0 0 0-1.704 4.175z"
        fill="#EC407A"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.69 13.087a6.391 6.391 0 0 0 3.727 1.192v-2.105a3.746 3.746 0 0 1-1.979-1.134 3.742 3.742 0 0 1-1.707-2.525h-1.949v10.678a2.262 2.262 0 0 1-4.063 1.361 2.26 2.26 0 0 1 1.018-4.28c.24 0 .47.037.687.106v-2.127a4.943 4.943 0 0 0-3.43 8.396 4.943 4.943 0 0 0 7.697-4.106l-.002-5.456z"
        fill="#fff"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.417 12.175v-.57a3.725 3.725 0 0 1-1.979-.565 3.737 3.737 0 0 0 1.98 1.135zm-3.687-3.66a3.642 3.642 0 0 1-.04-.308v-.344h-2.691v10.68a2.261 2.261 0 0 1-3.28 2.011 2.262 2.262 0 0 0 4.063-1.36V8.513h1.948zm-4.307 5.738v-.606a4.943 4.943 0 0 0-3.431 9.002 4.943 4.943 0 0 1 3.431-8.396z"
        fill="#81D4FA"
      />
    </SvgIcon>
  );
}

export default TikTokIconTwoTone;
