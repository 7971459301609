import { Box } from '@material-ui/core';
import { getImageWidth } from '@room-match/shared-utils';
import React, { useState } from 'react';
import { ImageCarousel } from 'components/ImageCarousel';
import { useStyles } from './ImageGallery.styles';
import { IMediumAttributes } from 'shared/interfaces/IMedium';
import { EmptyList } from 'components/EmptyList';
const images = [
  {
    src: '/sample_person2.jpg',
    width: 1920,
    height: 2401,
  },
  {
    src: '/landscape.jpg',
    width: 1980,
    height: 1280,
  },
];
const items = Array.from({ length: 20 }).map((_, i) => {
  return images[i % 2 === 0 ? 0 : 1];
});

const BOX_HEIGHT = 100;

type Props = {
  images?: IMediumAttributes[];
};

const ImageGallery: React.FC<Props> = ({ images }) => {
  const classes = useStyles();
  const [selectedImage, setSelectedImage] = useState<{
    src: string;
    width: number;
    height: number;
  }>(items[0]);
  return (
    <Box className={classes.imageGalleryContainer}>
      {images && images.length > 0 ? (
        <ImageCarousel images={images || []} fit={'contain'} />
      ) : (
        <EmptyList text="No Images Data" />
      )}
    </Box>
  );
};

export default ImageGallery;
