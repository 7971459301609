import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const GradientLogIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="100%" y1="0%" x2="0%" y2="100%" id="eka8u352qa">
          <stop stop-color="#D51BFF" offset="0%" />
          <stop stop-color="#00D4FF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M14.063 0A3.937 3.937 0 0 1 18 3.938v10.124A3.937 3.937 0 0 1 14.062 18H3.938A3.937 3.937 0 0 1 0 14.062V3.938A3.937 3.937 0 0 1 3.938 0zm0 1.125H3.937a2.812 2.812 0 0 0-2.812 2.813v10.124a2.812 2.812 0 0 0 2.813 2.813h10.124a2.812 2.812 0 0 0 2.813-2.813V3.938a2.812 2.812 0 0 0-2.813-2.813zM5.624 11.813c.31 0 .563.251.563.562V13.5c0 .31-.252.563-.563.563H4.5a.562.562 0 0 1-.563-.563v-1.125c0-.31.252-.563.563-.563zm8.438.562V13.5h-6.75v-1.125h6.75zm-8.438-4.5c.31 0 .563.252.563.563v1.124c0 .311-.252.563-.563.563H4.5a.562.562 0 0 1-.563-.563V8.438c0-.311.252-.563.563-.563zm8.438.563v1.124h-6.75V8.438h6.75zm-8.438-4.5c.31 0 .563.251.563.562v1.125c0 .31-.252.563-.563.563H4.5a.562.562 0 0 1-.563-.563V4.5c0-.31.252-.563.563-.563zm8.438.562v1.125h-6.75V4.5h6.75z"
        fill="url(#eka8u352qa)"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default GradientLogIconTwoTone;
