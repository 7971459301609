import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Moon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="147.119%" y1="1.228%" x2="-228.617%" y2="148.011%" id="0n4hwnmy8a">
            <stop stopColor="#D51BFF" offset="0%" />
            <stop stopColor="#00D4FF" offset="100%" />
          </linearGradient>
        </defs>
        <path
          d="M15.676 9.106a.564.564 0 0 0-.471.102 6.02 6.02 0 0 1-1.827 1.067 5.719 5.719 0 0 1-2.072.37A6.034 6.034 0 0 1 7.038 8.88a6.034 6.034 0 0 1-1.765-4.268c0-.698.123-1.375.329-1.99A5.51 5.51 0 0 1 6.587.857a.524.524 0 0 0-.082-.739.564.564 0 0 0-.472-.102 8.28 8.28 0 0 0-4.35 2.913A8.126 8.126 0 0 0 0 7.874c0 2.237.903 4.268 2.38 5.746A8.096 8.096 0 0 0 8.126 16a8.16 8.16 0 0 0 5.027-1.744 8.09 8.09 0 0 0 2.872-4.494c.103-.308-.061-.595-.349-.656zm-3.139 4.288a7.041 7.041 0 0 1-9.357-.554 7.041 7.041 0 0 1-2.072-4.986c0-1.621.534-3.098 1.457-4.288a7.054 7.054 0 0 1 2.36-1.97 7.088 7.088 0 0 0-.288.697 6.828 6.828 0 0 0-.39 2.34c0 1.949.8 3.734 2.073 5.006a7.09 7.09 0 0 0 5.006 2.073 6.87 6.87 0 0 0 2.442-.431c.267-.103.534-.206.78-.329a7.465 7.465 0 0 1-2.01 2.442z"
          fill="url(#0n4hwnmy8a)"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default Moon;
