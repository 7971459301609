import { TextField, OutlinedInputProps, Chip, Slider, Button, CircularProgress, Box } from '@material-ui/core';

import { CloseIcon, useConfirmationModal, useNotificationModal } from 'components';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import {
  Buttons,
  Controls,
  CursorHint,
  EditorMain,
  EditorPreview,
  Footer,
  Header,
  ImageQualityCont,
  ImagesList,
  MainCont,
  MultipleEditorCont,
  Quality,
  SaveButton,
  Scale,
  SwitchButton,
  TagCont,
  TagLabel,
  TagMainCont,
  Title,
  ZoomCropBox,
} from './PhotoEditor.styles';
import { Cropper } from 'react-cropper';
import EditItem from './EditItem';
import { base64ToBlob, Base64Url } from 'base64-blob';
import { StyledDialog, Typography } from 'shared/elements';
// const tagOptions = ['Mime', 'Hot', 'Funny'];
const tagOptions: string[] = [];

const standardHeightPixel = 1500;
const standardWidthPixel = 1125;
const rejectWidthQuanlity = 720;
const rejectHeightQuanlity = 720;
const acceptWidthQuanlity = 1080;
const acceptHeightQuanlity = 1080;
const CROP_BOX_HEIGHT = 440;
const portraitAR = 472 / 648; //1200
const landscapeAR = 1154 / 648;

enum ImageQuanlityLevel {
  None,
  Reject,
  Bad,
  Accept,
}

enum ColorGauge {
  PASS = '#1fa00f',
  WARNING = '#daa13e',
  FAIL = '#e34343',
}

interface IOutput {
  attachment: Blob;
  file_name: string;
  file_size: string;
  file_type: string;
  file_width: string;
  file_height: string;
  tag_list: string;
}

interface Props {
  show: boolean;
  images: File[];
  close: () => void;
  output: (images: IOutput[]) => void;
  isPrimary?: boolean;
  orientationLock?: 'landscape' | 'portrait';
}

interface ITags {
  id: number;
  value: string;
}

function MultipleImageEditor({ show, images, close, output, isPrimary, orientationLock }: Props) {
  const inputRef: any = useRef(null);
  const [sliderDefaultValue, setSliderDefaultValue] = useState<number>(0.2);
  const [minZoomRatio, setMinZoomRatio] = useState(0);
  const [maxZoomRatio, setMaxZoomRatio] = useState(1);
  const [imageData, setImageData] = useState<HTMLImageElement>();
  const [qualityLevel, setQualityLevel] = useState<ImageQuanlityLevel>(ImageQuanlityLevel.None);
  const [image, setImage] = useState<string>('');
  const [portrait, setportrait] = useState(orientationLock === 'portrait' ? true : isPrimary);
  const [cropper, setCropper] = useState<any>(null);
  const [filename, setfilename] = useState('');
  const [loading, setloading] = useState(false);
  const [selectedFile, setselectedFile] = useState<File>(images[0]);
  const [tagvalue, settagvalue] = useState('');
  const [initial, setinitial] = useState(true);

  const [modifiedImage, setModifiedImages] = useState(images);
  const [finalImages, setFinalImages] = useState<any>(images);
  const [tags, settags] = useState<ITags[]>([]);

  const { handleOpen, isOpen, NotificationModal, handleSetMessage, handleChangeModalType } = useNotificationModal({
    type: 'warning',
  });

  const {
    ConfirmationModal: ConfirmationModal,
    handleOpen: handleOpenConfirm,
    handleSetMessage: handleConfirmSetMessage,
    handleChangeModalType: handleChangeConfirmModalType,
    isOpen: isConfirmModalOpen,
  } = useConfirmationModal({
    type: 'warning',
  });

  const handleSliderChange = (event: any, newValue: number | number[]) => {
    const valueNumber = newValue as number;
    setSliderDefaultValue(valueNumber);
    if (valueNumber > 0) {
      cropper.zoomTo(valueNumber);
    } else {
      cropper.zoomTo(0);
    }

    cropper.setCropBoxData({ height: CROP_BOX_HEIGHT });
  };

  const handleOnSelect = (file: File) => {
    settagvalue('');
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as string);
      setfilename(file.name);
    };

    setSliderDefaultValue(0.2);
    reader.readAsDataURL(file);
    if (cropper) cropper.reset();

    const selectedFileTags = finalImages.filter((f: IOutput) => f.file_name === file.name)[0]?.tag_list;
    const _tags = selectedFileTags?.split(',');

    if (_tags !== undefined) {
      if (!_tags[0].length) return settags([]);
      settags(
        _tags.map((t: string, i: number) => {
          return { value: t, id: i };
        }),
      );
    } else {
      settags([]);
    }
    setinitial(false);
  };

  const updateImageData = (cropper: any) => {
    const cropData = cropper?.getCroppedCanvas()?.toDataURL('image/jpeg', 0.9);

    const i: HTMLImageElement = new Image();

    i.onload = function () {
      setImageData(i);
    };

    i.src = cropData;
  };

  const handleImageReady = (e: Cropper.ReadyEvent<HTMLImageElement>) => {
    const imageData = e.currentTarget.cropper.getImageData();

    updateImageData(e.currentTarget.cropper);

    const minSliderZoom = imageData.width / imageData.naturalWidth;
    const maxSliderZoom =
      Math.min.apply([imageData.naturalWidth / standardWidthPixel, imageData.naturalHeight / standardHeightPixel]) || 1;

    setMaxZoomRatio(maxSliderZoom);
    setMinZoomRatio(minSliderZoom);

    if (imageData.naturalWidth < rejectWidthQuanlity || imageData.naturalHeight < rejectHeightQuanlity) {
      setQualityLevel(ImageQuanlityLevel.Reject);
    } else if (imageData.naturalWidth < acceptWidthQuanlity || imageData.naturalHeight < acceptHeightQuanlity) {
      setQualityLevel(ImageQuanlityLevel.Bad);
    } else {
      setQualityLevel(ImageQuanlityLevel.Accept);
    }
  };

  useEffect(() => {
    if (!cropper) return;
    cropper.reset();
    setSliderDefaultValue(0.2);
    if (portrait) {
      cropper.setAspectRatio(portraitAR);
    } else {
      cropper.setAspectRatio(landscapeAR);
    }

    updateImageData(cropper);
  }, [portrait, cropper, selectedFile]);

  const handleSave = async () => {
    let done = 0;

    finalImages.map((img: any) => img.lastModified && done++);

    if (done > 0) {
      handleChangeModalType('error');
      handleSetMessage(
        'Unfinish Edits',
        `You have ${done} out of ${finalImages.length} images that are not yet done editing.`,
        'OK',
      );
      handleOpen();
    } else {
      let containsReject = 0;
      finalImages.map(
        (img: IOutput) =>
          (parseInt(img.file_width) < rejectWidthQuanlity || parseInt(img.file_height) < rejectHeightQuanlity) &&
          containsReject++,
      );

      if (containsReject > 0) {
        handleChangeModalType('error');
        handleSetMessage(
          'Image Rejected',
          `You have ${containsReject} rejected ${
            containsReject > 1 ? 'images' : 'image'
          }. Please fix by editing or delete the rejected ${containsReject > 1 ? 'images' : 'image'} before saving.`,
          'OK',
        );
        handleOpen();
      } else {
        output(finalImages);
        close();
      }
    }
  };

  const toBase64 = (file: File) =>
    new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result);
      reader.onerror = (error) => reject(error);
    });

  const dataUrlToFile = async (dataUrl: string, fileName: string): Promise<File> => {
    const res: Response = await fetch(dataUrl);
    const blob: Blob = await res.blob();
    return new File([blob], fileName, { type: 'image/png' });
  };

  interface ISavedFile {
    filename: string;
    file: string;
  }

  const modifyFinalImage = (convertedFile: Blob, width: number, height: number) => {
    const fileSize = convertedFile.size;

    const finalFile = {
      attachment: convertedFile,
      file_name: filename,
      file_width: width.toString(),
      file_height: height.toString(),
      file_type: 'image',
      file_size: fileSize.toString(),
      tag_list: tags.map((tag) => tag.value).join(','),
    };

    const files = finalImages.map((img: File) => {
      if (img.name === filename) {
        return finalFile;
      }
      return img;
    });

    setFinalImages(files);
  };

  const modifyImages = (blob: Blob) => {
    const file = new File([blob], filename, { type: 'image/jpeg' });

    const newImage: File[] = modifiedImage.map((img: File) => {
      if (img.name === filename) {
        return file;
      }
      return img;
    });

    const convertedFile = newImage.map(async (img) => {
      return { filename: img.name, file: await toBase64(img) };
    });

    Promise.all(convertedFile).then((res) => {
      localStorage.setItem('modifiedImages', JSON.stringify(res));
    });

    setModifiedImages(newImage);
    handleOnSelect(file);
  };

  const handleCropEnd = async (e: any) => {
    updateImageData(e.target.cropper);
  };

  const handleCrop = async () => {
    const cropData = cropper.getCroppedCanvas().toDataURL('image/jpeg', 0.9);
    const convertedFile = await base64ToBlob(cropData);

    const i: HTMLImageElement = new Image();

    i.onload = function () {
      modifyFinalImage(convertedFile, i.width, i.height);
    };

    i.src = cropData;

    modifyImages(convertedFile);
  };

  const handleReset = () => {
    const file: File = images.filter((f) => f.name === filename)[0];

    const newImages: File[] = modifiedImage.map((img: File) => {
      if (img.name === filename) {
        return file;
      }
      return img;
    });

    const finalFiles: File[] = finalImages.map((img: any) => {
      if (img.file_name === filename) {
        return file;
      }
      return img;
    });

    setFinalImages(finalFiles);
    handleOnSelect(file);
    setSliderDefaultValue(0.2);
    setModifiedImages(newImages);
    cropper.reset();
  };

  const handleClose = () => {
    handleOpenConfirm();
    handleConfirmSetMessage(
      'Exit Confirmation',
      'You have unsaved work in progress, this will discard all the changes you made. Do you wish to continue?',
      'Yes',
    );
  };

  const updateImageTag = (_tags: ITags[]) => {
    const files = finalImages.map((img: any) => {
      if (img.file_name === filename) {
        return { ...img, tag_list: _tags.map((tag) => tag.value).join(',') };
      }
      return img;
    });

    setFinalImages(files);
  };

  useEffect(() => {
    if (!imageData) return;

    if (imageData.naturalWidth < rejectWidthQuanlity || imageData.naturalHeight < rejectHeightQuanlity) {
      setQualityLevel(ImageQuanlityLevel.Reject);
    } else if (imageData.naturalWidth < acceptWidthQuanlity || imageData.naturalHeight < acceptHeightQuanlity) {
      setQualityLevel(ImageQuanlityLevel.Bad);
    } else {
      setQualityLevel(ImageQuanlityLevel.Accept);
    }
  }, [imageData]);

  if (initial) handleOnSelect(images[0]);
  let timer: any = null;

  const handleDelete = (name: string) => {
    const newMImg = modifiedImage.filter((img) => img.name !== name);
    setModifiedImages(newMImg);

    const fImg = finalImages.filter((img: any) => (img.name || img.file_name) !== name);
    setFinalImages(fImg);

    if (name === filename) {
      const file = newMImg.filter((f) => f.name === newMImg[0].name)[0];
      handleOnSelect(file);
    }
  };

  return (
    <StyledDialog
      style={{ zIndex: 9999 }}
      open={show}
      onClose={close}
      maxWidth={false}
      aria-labelledby="simple-dialog-title"
    >
      <MultipleEditorCont>
        <Header>
          <Title>Edit Image</Title>
          {!orientationLock && (
            <SwitchButton onClick={() => setportrait(!portrait)}>
              {portrait ? 'Switch to Landscape' : 'Switch to Portrait'}
            </SwitchButton>
          )}
          <Button style={{ width: 20, height: 38, marginLeft: !orientationLock ? 0 : 'auto' }} onClick={handleClose}>
            <CloseIcon style={{ height: 16, width: 16 }} />
          </Button>
        </Header>
        <EditorMain>
          <ImagesList>
            {modifiedImage.map((img: File) => (
              <EditItem
                lastItem={finalImages.length === 1}
                delete={handleDelete}
                edited={finalImages.filter((f: File) => f.name === img.name)[0]?.lastModified}
                key={img.name}
                image={img}
                onSelect={handleOnSelect}
                active={img.name === filename}
              />
            ))}
          </ImagesList>
          <EditorPreview>
            <Cropper
              style={{ height: '100%', width: '100%' }}
              // zoomTo={0}
              preview=".img-preview"
              src={image}
              // minCropBoxHeight={800}
              aspectRatio={portrait ? portraitAR : landscapeAR}
              viewMode={1}
              guides={false}
              // center={true}
              // autoCrop={true}
              minCropBoxHeight={800}
              minCropBoxWidth={1000}
              responsive={true}
              background={false}
              // wheelZoomRatio={0.1}
              modal={true}
              // cropBoxResizable={true}
              ready={handleImageReady}
              // zoomable={true}
              dragMode="move"
              zoomOnWheel={false}
              checkCrossOrigin={true}
              // zoomOnWheel={true}
              zoom={(e) => {
                clearTimeout(timer);
                timer = setTimeout(() => {
                  handleCropEnd(e);
                }, 100);
              }}
              cropend={handleCropEnd}
              toggleDragModeOnDblclick={false}
              cropBoxMovable={false}
              cropBoxResizable={false}
              onInitialized={(instance) => {
                setCropper(instance);
              }}
            />
          </EditorPreview>
        </EditorMain>
        <Footer>
          <Controls>
            <Scale>
              Scale:
              <Slider
                style={{ marginLeft: 22 }}
                onChange={handleSliderChange}
                value={sliderDefaultValue}
                step={0.0001}
                min={minZoomRatio}
                max={1}
              />
            </Scale>
            <Quality>
              <Buttons onClick={handleCrop}>Crop</Buttons>
              <Buttons onClick={handleReset}>Reset</Buttons>
            </Quality>
          </Controls>
          <ZoomCropBox>
            <Typography style={{ fontSize: 14 }}> Current Quality Level: </Typography>
            <Typography
              style={{
                margin: '10px 0',
                fontSize: 16,
                color: `${
                  qualityLevel === ImageQuanlityLevel.Accept
                    ? ColorGauge.PASS
                    : qualityLevel === ImageQuanlityLevel.Bad
                    ? ColorGauge.WARNING
                    : ColorGauge.FAIL
                }`,
                textAlign: 'center',
                fontWeight: 'bold',
                border: `1px ${
                  qualityLevel === ImageQuanlityLevel.Accept
                    ? ColorGauge.PASS
                    : qualityLevel === ImageQuanlityLevel.Bad
                    ? ColorGauge.WARNING
                    : ColorGauge.FAIL
                } dashed`,
                borderRadius: 8,
                padding: 10,
              }}
            >
              {qualityLevel === ImageQuanlityLevel.Accept
                ? 'Good'
                : qualityLevel === ImageQuanlityLevel.Bad
                ? 'Warning'
                : 'Reject'}
            </Typography>
            <Box style={{ display: 'flex', justifyContent: 'flex-end' }}>
              <Typography style={{ fontSize: 13, color: '#93a1b6', textAlign: 'right', marginTop: 5 }}>
                {Math.round(imageData?.width || 0)}x{Math.round(imageData?.height || 0)} pixels,
              </Typography>
              <Typography
                style={{ textTransform: 'uppercase', fontSize: 13, color: '#93a1b6', margin: '5px 0px 0px 5px' }}
              >
                {filename.split('.')[filename.split('.').length - 1]}
              </Typography>
            </Box>

            {/* <Typography style={{ textAlign: 'center' }}>Zoom and crop further will compromise quality</Typography> */}
          </ZoomCropBox>
          <TagMainCont>
            <TagLabel>
              <Typography style={{ fontSize: 15, color: '#c2ccd8' }}>Photo tags</Typography>
              {tags.length !== 3 && (
                <Typography style={{ fontSize: 13, marginLeft: 'auto' }}>
                  {3 - tags.length} {tags.length > 1 ? 'tag' : 'tags'} left
                </Typography>
              )}
            </TagLabel>

            <TextField
              disabled={tags.length === 3 || finalImages.filter((f: File) => f.name === filename)[0]?.lastModified}
              ref={inputRef}
              size="small"
              fullWidth
              style={{ backgroundColor: '#0f1621', marginBottom: 8 }}
              value={tagvalue}
              placeholder="Enter your desired tags here..."
              variant="outlined"
              InputLabelProps={{ shrink: true }}
              onChange={(e) => settagvalue(e.target.value)}
              onKeyDown={(e) => {
                if (e.key === 'Enter') {
                  const regValue = tagvalue
                    .replace(/"/g, '')
                    .replace(/Add\s/g, '')
                    .replace(/[!@#$%^&*()`~]/g, '');
                  if (!tags.some((tag) => tag.value === regValue)) {
                    const newTags = [...tags, { id: tags.length, value: regValue }];
                    settags(newTags);
                    updateImageTag(newTags);
                    settagvalue('');
                  }
                }
              }}
            />
            {finalImages.filter((f: File) => f.name === filename)[0]?.lastModified && (
              <Typography style={{ fontSize: 14, color: ColorGauge.FAIL }}>
                Cropping is required before adding tag
              </Typography>
            )}

            <TagCont>
              {tags &&
                tags.map((v, idx) => (
                  <Chip
                    style={{
                      width: 105,
                      textAlign: 'left',
                      display: 'flex',
                      justifyContent: 'flex-start',
                      backgroundColor: '#151b24',
                      border: 'none',
                      marginRight: 15,
                    }}
                    key={idx}
                    deleteIcon={
                      <CloseIcon
                        htmlColor="red"
                        style={{ width: 10, marginLeft: 'auto', marginRight: 10, color: 'red' }}
                      />
                    }
                    onDelete={() => {
                      const newTag = tags.filter((chip) => chip.id !== v.id);
                      settags(newTag);
                      updateImageTag(newTag);
                    }}
                    variant="outlined"
                    label={v.value}
                  />
                ))}
            </TagCont>
          </TagMainCont>
        </Footer>
        <SaveButton onClick={handleSave} disabled={loading}>
          {loading ? <CircularProgress style={{ color: 'white', width: 20, height: 20 }} /> : 'Save Changes'}
        </SaveButton>
      </MultipleEditorCont>
      {isOpen && <NotificationModal onClose={() => {}} />}
      {isConfirmModalOpen && <ConfirmationModal onClose={() => {}} onConfirm={() => close()} />}
    </StyledDialog>
  );
}

export default MultipleImageEditor;
