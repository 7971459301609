import React from 'react';
import { Grid } from '@material-ui/core';
import { MiniAudioPlayer } from 'components/MiniAudioPlayer';
// import { IMediumTrim } from 'shared/interfaces/IMedium';
import { IMediumAttributes } from 'shared/interfaces/IMedium';
import { IProfileMediumAttachment } from 'shared/interfaces/IProfile';
import { EmptyList } from 'components/EmptyList';

type Props = {
  audios?: IMediumAttributes[];
};

const AudioContent: React.FC<Props> = ({ audios }) => {
  return (
    <div>
      <Grid container style={{ marginTop: 12 }} spacing={2}>
        {/* Audio List Item */}
        {audios && audios.length > 0 ? (
          <React.Fragment>
            {audios.map((audio, i) => (
              <Grid item lg={4} md={6} sm={6} xs={12} key={i}>
                <MiniAudioPlayer audio={audio} textLength={50} />
              </Grid>
            ))}
          </React.Fragment>
        ) : (
          <EmptyList text="No Audio Data" />
        )}
      </Grid>
    </div>
  );
};

export default AudioContent;
