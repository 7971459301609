import { Tab, Tabs, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

export const StyledTabs = withStyles((theme: Theme) => ({
  root: {
    backgroundColor: '#0b0f14',
    borderTopLeftRadius: 4,
    borderTopRightRadius: 4,
  },
  indicator: {
    backgroundColor: theme.palette.primary.main,
  },
}))(Tabs);

export const StyledTab = withStyles((theme: Theme) => ({
  root: {
    padding: 0,
    color: theme.palette.customGrey.main,
    textTransform: 'none',
  },
  selected: {
    color: '#fff',
  },
}))(Tab);
