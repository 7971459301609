import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

export interface AppState {
  headerTitle: string;
  companyName: string;
  bookedProjectAccordionsShow: string[];
}

const initialState: AppState = {
  headerTitle: '',
  companyName: '',
  bookedProjectAccordionsShow: [],
};

export const appSlicer = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setHeaderTitle: (state: AppState, action: PayloadAction<{ headerTitle: string }>) => {
      const { headerTitle } = action.payload;
      const cleanedHeaderTitle = headerTitle.replace('null ', '');
      state.headerTitle = cleanedHeaderTitle;
    },
    setCompanyName: (state: AppState, action: PayloadAction<{ companyName: string }>) => {
      const { companyName } = action.payload;
      state.companyName = companyName;
    },
    toggleBookedProjectAccordion: (state: AppState, action: PayloadAction<{ accordionItem: string }>) => {
      const { accordionItem } = action.payload;
      if (!state.bookedProjectAccordionsShow.includes(accordionItem)) {
        state.bookedProjectAccordionsShow = [...state.bookedProjectAccordionsShow, accordionItem];
      } else {
        const filteredBookProjectAccordionsShow = state.bookedProjectAccordionsShow.filter(
          (val) => val !== accordionItem,
        );
        state.bookedProjectAccordionsShow = filteredBookProjectAccordionsShow;
      }
    },
    collapseAllBookedProjectAccordion: (state: AppState) => {
      state.bookedProjectAccordionsShow = ['GPI', 'CAU', 'CCI', 'CT', 'AT', ''];
    },
    expandAllBookedProjectAccordion: (state: AppState) => {
      state.bookedProjectAccordionsShow = [];
    },
  },
});

export const selectAppState = (state: RootState) => state.app;
export const {
  setHeaderTitle,
  setCompanyName,
  toggleBookedProjectAccordion,
  collapseAllBookedProjectAccordion,
  expandAllBookedProjectAccordion,
} = appSlicer.actions;

export default appSlicer.reducer;
