import { DialogContent, Grid, IconButton } from '@material-ui/core';
import {
  Checkbox,
  CloseIcon,
  Input,
  InputSearchTwoTone,
  StyledDialog,
  TrashErrorIconTwoTone,
  Typography,
} from '@room-match/shared-ui-components';
import { LineBorder } from 'components/LineBorder';
import SKILL_LIST from 'data/Skills.json';

import React, { useMemo, useState } from 'react';
import { COLORS } from 'shared/constants/COLORS';
import {
  CategoriesButton,
  CategoriesButtonList,
  ModalContainer,
  ModalContent,
  ModalHeader,
  ModalSidebar,
  SelectedCategoryTitleContainer,
  SelectedSkillContainer,
  SkillCategoriesContainer,
  SkillListContainer,
  SkillListGrid,
  SubGroupItem,
  SubGroupList,
  SubGroupTitleContainer,
} from './SkillModal.styles';

type Props = {
  open: boolean;
  onClose: () => void;
  selectedSkills: string[];
  handleSelectSkill: (skill: string, isChecked: boolean) => void;
  handleClearSelectedSkill: () => void;
};
const SkillModal: React.FC<Props> = ({
  open,
  onClose,
  selectedSkills,
  handleSelectSkill,
  handleClearSelectedSkill,
}) => {
  const [selectedCategory, setSelectedCategory] = useState<string>('Accents');
  const [inputQuery, setInputQuery] = useState<string>('');

  const handleSelectedCategory = (category: string) => {
    if (selectedCategory !== category) {
      setSelectedCategory(category);
    }
  };

  const getCategories = [...new Set(SKILL_LIST.map((value) => value.category))];
  const filteredSkills = SKILL_LIST.filter((list) => list.category === selectedCategory);
  const resultSearchedSkills = useMemo(() => {
    return filteredSkills
      .map((v) => v.skills.map((v) => v.value))
      .flat()
      .filter((v) => v.toLocaleLowerCase().includes(inputQuery.toLocaleLowerCase()));
  }, [filteredSkills, inputQuery]);

  return (
    <StyledDialog open={open} onClose={onClose} maxWidth="lg" fullWidth>
      <DialogContent style={{ padding: 0 }}>
        <ModalContainer>
          <ModalHeader>
            <Input
              variant="outlined"
              placeholder="Search skill"
              fullWidth
              size="small"
              onChange={(e) => setInputQuery(e.target.value)}
              InputProps={{
                startAdornment: <InputSearchTwoTone viewBox="0 0 18 18" style={{ marginRight: 10, fontSize: 14 }} />,
              }}
            />
            <IconButton size="small" onClick={() => onClose()}>
              <CloseIcon style={{ fontSize: 14 }} />
            </IconButton>
          </ModalHeader>
          <ModalContent>
            <Grid container style={{ height: '80vh ' }}>
              <Grid item xl={3} lg={3}>
                <ModalSidebar>
                  <SelectedSkillContainer>
                    <Typography fontSize={14}>
                      Selected Skill: <span style={{ color: COLORS.PRIMARY }}>{selectedSkills.length}</span>
                    </Typography>
                    {selectedSkills.length > 0 && (
                      <IconButton size="small" onClick={() => handleClearSelectedSkill()}>
                        <TrashErrorIconTwoTone style={{ fontSize: 14 }} />
                      </IconButton>
                    )}
                  </SelectedSkillContainer>
                  <SkillCategoriesContainer>
                    <Typography fontSize={20} fontWeight={700}>
                      Skill Categories
                    </Typography>
                    <CategoriesButtonList>
                      {getCategories.map((category, index) => (
                        <CategoriesButton
                          key={index}
                          onClick={() => handleSelectedCategory(category)}
                          variant="contained"
                          fullWidth
                          $active={selectedCategory === category}
                        >
                          {category}
                        </CategoriesButton>
                      ))}
                    </CategoriesButtonList>
                  </SkillCategoriesContainer>
                </ModalSidebar>
              </Grid>
              <Grid item xl={9} lg={9}>
                <SkillListContainer>
                  <SelectedCategoryTitleContainer>
                    <Typography fontSize={20} fontWeight={700}>
                      Select {selectedCategory}
                    </Typography>
                    {!inputQuery ? (
                      <>
                        {filteredSkills.map((list) => (
                          <SubGroupList key={list.subgroup}>
                            <SubGroupItem>
                              <SubGroupTitleContainer>
                                <Typography color="primary">{list.subgroup}</Typography>
                              </SubGroupTitleContainer>
                              <LineBorder percent={7} />
                              <SkillListGrid container spacing={3}>
                                {list.skills.map((skill) => (
                                  <Grid item xl={4} key={skill.value}>
                                    <Checkbox
                                      label={skill.value}
                                      value={selectedSkills.includes(skill.value)}
                                      onChange={(e) => {
                                        handleSelectSkill(skill.value, e.target.checked);
                                      }}
                                    />
                                  </Grid>
                                ))}
                              </SkillListGrid>
                            </SubGroupItem>
                          </SubGroupList>
                        ))}
                      </>
                    ) : (
                      <>
                        {resultSearchedSkills.length > 0 && (
                          <SkillListGrid container spacing={3}>
                            {resultSearchedSkills.map((skill) => (
                              <Grid item xl={4} key={skill}>
                                <Checkbox
                                  label={skill}
                                  value={selectedSkills.includes(skill)}
                                  onChange={(e) => {
                                    handleSelectSkill(skill, e.target.checked);
                                  }}
                                />
                              </Grid>
                            ))}
                          </SkillListGrid>
                        )}
                      </>
                    )}
                  </SelectedCategoryTitleContainer>
                </SkillListContainer>
              </Grid>
            </Grid>
          </ModalContent>
        </ModalContainer>
      </DialogContent>
    </StyledDialog>
  );
};

export default SkillModal;
