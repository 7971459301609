import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Comment(props: SvgIconProps) {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="wnhwneufoa">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M16.922 14.493A5.455 5.455 0 0 0 18 11.233c0-1.887-.979-3.638-2.63-4.732-.152-1.68-.937-3.24-2.25-4.441C11.67.732 9.745 0 7.7 0 5.653 0 3.728.732 2.277 2.06.808 3.404 0 5.196 0 7.106c0 1.47.474 2.862 1.374 4.054L.05 14.432a.703.703 0 0 0 .936.907l3.772-1.663c.474.18.962.316 1.46.406a6.053 6.053 0 0 0 2.128 2.071c.99.579 2.132.888 3.308.897.017.001.034.003.052.003.813 0 1.6-.14 2.345-.418l2.961 1.305a.702.702 0 0 0 .936-.907l-1.027-2.54zm-1.457.148.531 1.314-1.647-.726a.703.703 0 0 0-.56-.003 5.273 5.273 0 0 1-2.053.42l-.029-.001c-1.386 0-2.695-.542-3.61-1.443a8.198 8.198 0 0 0 3.31-.866.703.703 0 1 0-.637-1.254 6.81 6.81 0 0 1-3.07.724 6.78 6.78 0 0 1-2.677-.54.703.703 0 0 0-.56.003l-2.459 1.084.828-2.048a.703.703 0 0 0-.117-.72C1.86 9.581 1.406 8.378 1.406 7.106c0-3.143 2.823-5.7 6.293-5.7s6.293 2.557 6.293 5.7a5.288 5.288 0 0 1-.863 2.882.703.703 0 1 0 1.18.766 6.745 6.745 0 0 0 .99-2.511c.824.807 1.295 1.87 1.295 2.99 0 .982-.35 1.912-1.012 2.688a.703.703 0 0 0-.117.72zm-7.098-3.918a.703.703 0 1 1-.703-.703c.388 0 .703.314.703.703zm1.968-5.268.001.029a2.634 2.634 0 0 1-1.602 2.427.557.557 0 0 0-.332.516v.046a.703.703 0 1 1-1.406 0v-.046a1.96 1.96 0 0 1 1.186-1.81 1.23 1.23 0 1 0-1.713-1.133.703.703 0 1 1-1.406 0 2.64 2.64 0 0 1 2.636-2.636 2.64 2.64 0 0 1 2.636 2.607z"
        fill="url(#wnhwneufoa)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default Comment;
