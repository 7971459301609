import { Box, IconButton } from '@material-ui/core';
import { ellipseText } from '@room-match/shared-utils';
import clsx from 'clsx';
import { PlayIcon } from 'components/Icons';
import React, { useRef, useState, useEffect } from 'react';
import { Typography } from 'shared/elements';
import { useStyles } from './VideoGallery.styles';
import { IMediumAttributes } from 'shared/interfaces/IMedium';
import { intervalToDuration } from 'date-fns';
import { useGetThumbnail } from 'shared/hooks/useGetThumbnail';
import { NO_IMAGE_PLACEHOLDER_URL } from 'shared/constants/ASSETS_URL';
import { Image } from 'components/Image';

type Props = {
  video: IMediumAttributes;
  index: number;
  selectedIndex: number;
  videoURL: string;
  handleSetSelectedVideoTime: (instance: string) => void;
};

const Video: React.FC<Props> = ({ video, index, selectedIndex, videoURL, handleSetSelectedVideoTime }) => {
  const classes = useStyles();
  const videoEl = useRef(null);
  const [videoTime, setVideoTime] = useState<string>('00:00');

  const { thumbnail, handleChangeVideoUrl } = useGetThumbnail();

  useEffect(() => {
    if (videoURL) {
      handleChangeVideoUrl(videoURL);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [videoURL]);

  const secondsToHms = (seconds: any) => {
    const duration = intervalToDuration({ start: 0, end: seconds * 1000 });
    if (duration.minutes) {
      const formatted = `${duration.minutes < 10 ? `0${duration.minutes}` : duration.minutes}:${duration.seconds}`;
      return formatted;
    }
  };

  const handleLoadedMetadata = () => {
    const vid: any = videoEl.current;
    if (!vid) return;
    const duration = secondsToHms(vid.duration);
    if (duration) {
      setVideoTime(duration);
      handleSetSelectedVideoTime(duration);
    }
  };

  return (
    <Box className={classes.videoList__item}>
      <video
        ref={videoEl}
        src={video.modified_attachment || video.attachment || video.attachment_url}
        onLoadedMetadata={handleLoadedMetadata}
        style={{ height: 0, width: 0, visibility: 'hidden', position: 'absolute' }}
      />
      <Image
        src={thumbnail}
        className={clsx(classes.videoList__img, {
          [classes.videoList__img__selected]: index === selectedIndex,
        })}
      />

      <IconButton className={classes.videoList__playButton}>
        <PlayIcon fontSize="large" />
      </IconButton>
      <Box>
        <Typography fontSize={13} fontWeight={500}>
          {ellipseText(video.file_name || '—', 30)}
        </Typography>
        <Typography fontSize={12} color="mainGrey">
          {videoTime}
        </Typography>
      </Box>
    </Box>
  );
};

export default Video;
