import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    leftArrowIcon: {
      position: 'absolute',
      left: 0,
      top: '50%',
      zIndex: 9000,
    },
    rightArrowIcon: {
      position: 'absolute',
      right: 0,
      top: '50%',
      zIndex: 9000,
    },
    imageHidden: {
      // display: 'flex',
      // justifyContent: 'flex-end',
      // backgroundColor: 'red',
      width: '100%',
      '& + img': {
        objectFit: 'contain',
      },
    },
    container: {
      position: 'relative',
    },
    blurRight: {
      background: '#000',
      height: 650,
      width: 50,
      position: 'absolute',
      left: -20,
      top: -60,
      zIndex: 1,
      filter: 'blur(35px)',
    },
    blurLeft: {
      background: '#000',
      height: 650,
      width: 50,
      position: 'absolute',
      right: -20,
      top: -60,
      zIndex: 1,
      filter: 'blur(35px)',
    },
    button: {
      background: 'none',
      border: 'none',
    },
    buttonBack: {
      position: 'absolute',
      top: '50%',
      left: 10,
      zIndex: 2,
      transform: 'translateY(-50%)',
    },
    buttonNext: {
      position: 'absolute',
      top: '50%',
      right: 10,
      zIndex: 2,
      transform: 'translateY(-50%)',
    },
    largeImage: {
      display: 'block',
      position: 'absolute',
      left: '50%',
      top: '50%',
      height: '100%',
      minWidth: '100%',
      minHeight: '100%',
      transform: 'translate(-50%,-50%)',
      objectFit: 'cover',
    },
    imageListContainer: {
      display: 'flex',
      width: '100%',
      gap: 8,
      overflow: 'auto',
      paddingBottom: theme.spacing(3),
      marginTop: theme.spacing(2),
      '&::-webkit-scrollbar': {
        width: 6,
        height: 6,
      },
    },
    imageItemContainer: {
      cursor: 'pointer',
    },
    imageItem: {
      width: '100%',

      objectFit: 'cover',
    },
    imageItem__active: {
      border: `1px solid ${theme.palette.primary.main}`,
    },

    portrait: {
      '& .carousel__slide': {
        width: '5%!important',
      },
    },
  }),
);
