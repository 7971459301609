export const swapArrayElement = <T>(array: T[], sourceIndex: number, destinationIndex: number) => {
  if (destinationIndex >= 0) {
    const arr = array;
    const temp = array[sourceIndex];
    arr[sourceIndex] = arr[destinationIndex];
    arr[destinationIndex] = temp;
    return arr;
  }
  return array;
};
