import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function CheckedCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 .5c5.52.007 9.993 4.48 10 10 0 5.523-4.477 10-10 10s-10-4.477-10-10S4.477.5 10 .5zm0 1.818a8.182 8.182 0 1 0 0 16.364 8.19 8.19 0 0 0 8.182-8.182A8.182 8.182 0 0 0 10 2.318zm3.93 5.24a.91.91 0 1 1 1.23 1.34l-5.455 5a.91.91 0 0 1-1.257-.028l-2.727-2.727a.91.91 0 1 1 1.285-1.286l2.112 2.112z"
        fill={props.htmlColor || '#677482'}
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default CheckedCircleIcon;
