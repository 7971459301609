import React, { useEffect, useMemo, useState } from 'react';
import {
  Grid,
  InputLabel,
  Tooltip,
  Box,
  TextField,
  OutlinedInputProps,
  Chip,
  Card,
  CardHeader,
  CardContent,
  Button,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  ListItemSecondaryAction,
  IconButton,
  ImageList,
  ImageListItem,
  ImageListItemBar,
  RadioGroup,
  Radio,
  FormControlLabel,
  AccordionSummary,
  AccordionDetails,
} from '@material-ui/core';
import { Autocomplete, createFilterOptions } from '@material-ui/lab';
import { ButtonSkill, useStyles } from './AddNewRole.styles';
import { Typography, TextInput, Checkbox, FileUpload, StyledAccordion, TextareaAutosize } from 'shared/elements';
import {
  Backdrop,
  IKeyValue,
  PlusIconTwoTone,
  RemoveIconTwoTone,
  Select,
  useNotificationModal,
  DatePicker,
  MinusCircleIcon,
} from '@room-match/shared-ui-components';
import talentList from 'data/TalentList.json';
import gender from 'data/Gender.json';
import ETHNIC_APPEARANCES from 'data/EthnicAppearances.json';

import { SelectArrowDown, RemoveIcon, PlusIcon, EditIcon, DocumentIcon } from 'components/Icons';

import auditionType from 'data/AuditionType.json';
import { IRoleCreatePayload, IRoleMutateResponsePayload } from 'shared/interfaces/IRole';
import { useAddNewRoleForm } from './AddNewRoleForm';
import { roleService } from 'shared/services/roleService';
import { useHistory, useParams } from 'react-router-dom';
import { NotificationModal, SkillModal } from 'components';
import { useQueryClient } from 'react-query';
import { ROUTES } from 'shared/constants/ROUTES';
import { projectSideService } from 'shared/services/projectSideService';
import { ISelectValue } from 'shared/interfaces/utils/ISelectValue';
import { uploadService } from 'shared/services/uploadService';
import { IUpload } from 'shared/interfaces/IUpload';
import { format } from 'date-fns';
import { getErrorMessage } from 'shared/utils/getErrorMessage';
import PRINCIPAL_ROLE_TYPE_LIST from 'data/PrincipalRoleTypeList.json';
import BACKGROUND_ROLE_TYPE_LIST from 'data/BackgroundRoleTypeList.json';
import SEXUAL_SITUATIONS_LIST from 'data/SexualSitutationsList.json';
import WHO_CAN_SEE_SIDES_LIST from 'data/WhoCanSeeSidesList.json';
import SKILL_LIST from 'data/Skills.json';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import clsx from 'clsx';
import { errorResponseToArray } from '@room-match/shared-utils';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';
import { COLORS } from 'shared/constants/COLORS';
import { isArray, isEqual, sortBy } from 'lodash';
import { navigateToRoute } from 'shared/utils/navigateToRoute';
import { IMedium } from 'shared/interfaces/IMedium';

const { createRole, createRoleSide, getRoles, getSingleRole, updateRole } = roleService();
const { uploadMedia } = uploadService();
const { getProjectSides, attachSides } = projectSideService();

type Props = {
  defaultAuditionType?: 'studio' | 'self_tapes';
};
const AddNewRole: React.FC<Props> = ({ defaultAuditionType }) => {
  const classes = useStyles();

  const history = useHistory();
  const { projectId, roleId } = useParams() as {
    projectId: string;
    roleId: string | undefined;
  };
  const { mutateAsync, isLoading: roleMutateLoading } = createRole();
  const { mutateAsync: mutateUpdateRole, isLoading: isMutateUpdateRole } = updateRole();
  const { mutateAsync: createRoleSideMutateAsync, isLoading: createRoleSideMutateLoading } = createRoleSide();
  const { mutateAsync: uploadMediaMutateAsync, isLoading: uploadMediaMutateLoading } = uploadMedia();
  const { mutate: attachSidesMutate, isLoading: attachSideMutateLoading } = attachSides();
  const { data: projectSideData, isLoading: isProjectSideLoading } = getProjectSides(projectId);
  const { data: rolesData, isLoading: isRolesLoading } = getRoles(projectId);
  const { data: roleData, isLoading: isRoleLoading } = getSingleRole(projectId, roleId || '');

  const [auditionRoleLabel, setAuditionRoleLabel] = useState<string>('principal');
  const [uploadedSides, setUploadedSides] = useState<Array<{ name: string; type: string; url: string; file: File }>>(
    [],
  );

  const [getRolePreviousSides, setGetRolePreviousSides] = useState<string[]>([]);
  const [getRolePhotos, setGetRolePhotos] = useState<IMedium[]>([]);
  const [previousSides, setPreviousSides] = useState<string[]>([]);
  // const [uploadedMedia, setUploadedMedia] = useState<IUpload[]>([]);
  const [uploadedPhotos, setUploadedPhotos] = useState<
    Array<{ name: string; type: string; url: string; file: File; imageAttr: { width: number; height: number } }>
  >([]);

  const [isSaving, setIsSaving] = useState<boolean>(false);
  const [isShowSkillModal, setIsShowSkillModal] = useState<boolean>(false);
  const queryClient = useQueryClient();

  const {
    handleOpen: handleNotificationModalOpen,
    isOpen: isOpenNotificationModal,
    NotificationModal,
    handleSetMessage: handleSetNotificationModalMessage,
  } = useNotificationModal({
    type: 'error',
  });

  // Form Submit
  const handleSubmit = async (values: IRoleCreatePayload) => {
    try {
      setIsSaving(true);

      const finalValues = {
        ...values,
      };

      let getUploadedDocuments: IUpload[] = [];
      let getUploadedPhotos: IUpload[] = [];
      // Upload sides to medium service
      if (uploadedSides.length > 0) {
        for (const side of uploadedSides) {
          const formData = new FormData();
          formData.append('attachment', side.file);
          formData.append('file_name', side.name);
          formData.append('file_type', 'document');
          formData.append('file_size', side.file.size.toString());

          const uploadedMediaSide = await uploadMediaMutateAsync({ formData });
          if (uploadedMediaSide) {
            getUploadedDocuments = [...getUploadedDocuments, uploadedMediaSide.data];
          }
        }
      }

      // Upload Photos on Medium Service
      if (uploadedPhotos.length > 0) {
        for (const photo of uploadedPhotos) {
          const formData = new FormData();
          formData.append('attachment', photo.file);
          formData.append('file_name', photo.name);
          formData.append('file_width', photo.imageAttr.width.toString());
          formData.append('file_height', photo.imageAttr.height.toString());
          formData.append('file_type', 'image');
          formData.append('file_size', photo.file.size.toString());

          const uploadedPhoto = await uploadMediaMutateAsync({ formData });
          if (uploadedPhoto) {
            getUploadedPhotos = [...getUploadedPhotos, uploadedPhoto.data];
          }
        }
      }

      // Added Sides to project
      if (getUploadedDocuments.length > 0) {
        attachSidesMutate({ projectId, mediumIds: getUploadedDocuments.map((m) => m.id) });
      }

      // Create New Role
      let mutateRoleData: IRoleMutateResponsePayload;
      if (roleId) {
        mutateRoleData = await mutateUpdateRole({ projectId, roleId, payload: finalValues });
      } else {
        mutateRoleData = await mutateAsync({ projectId, payload: finalValues });
      }

      // Add photos and sides on role created.
      if (mutateRoleData) {
        const getRoleId = mutateRoleData.data.id;
        let uploadedMediaIds: string[] = [...previousSides, ...getRolePhotos.map((photo) => photo.id)];

        if (getUploadedDocuments.length > 0) {
          uploadedMediaIds = [...uploadedMediaIds, ...getUploadedDocuments.map((v) => v.id)];
        }
        if (getUploadedPhotos.length > 0) {
          uploadedMediaIds = [...uploadedMediaIds, ...getUploadedPhotos.map((v) => v.id)];
        }

        if (uploadedMediaIds.length > 0) {
          await createRoleSideMutateAsync({
            projectId,
            roleId: getRoleId,
            payload: {
              medium_ids: uploadedMediaIds,
            },
          });
        }

        queryClient.invalidateQueries(['role', projectId], {
          refetchInactive: true,
        });

        if (finalValues.audition_type === 'self_tapes') {
          queryClient.invalidateQueries(['projects'], {
            refetchInactive: true,
          });
        }

        history.push(navigateToRoute.toProjectTab(projectId, 'roles'));
      }
    } catch (error: any) {
      if (error) {
        if (error.response) {
          const errorMessage = errorResponseToArray(error.response.data.errors);
          handleSetNotificationModalMessage('Error', errorMessage, 'Close');
          handleNotificationModalOpen();
        }
      }
    } finally {
      setIsSaving(false);
    }
  };

  const roles = useMemo(() => {
    return rolesData ? rolesData.data : [];
  }, [rolesData]);

  const role = useMemo(() => {
    return roleData ? (!isArray(roleData.data) ? roleData.data : null) : null;
  }, [roleData]);

  // Added Role side to previous side states

  useEffect(() => {
    if (role) {
      const sidesMediumIds = role.attributes.sides.data.map((side) => side.attributes.medium.data.id);
      const photos = role.attributes.photos.data;
      setGetRolePreviousSides(sidesMediumIds);
      setPreviousSides(sidesMediumIds);
      setGetRolePhotos(photos);
    }
  }, [role]);

  useEffect(() => {
    if (role) {
      if (role.attributes.principal_role_type) {
        setAuditionRoleLabel('principal');
      } else {
        setAuditionRoleLabel('background');
      }
    }
  }, [role]);

  const { form, isFormDirty: dirtyForm } = useAddNewRoleForm({
    onSubmit: handleSubmit,
    roles,
    role,
  });

  useEffect(() => {
    if (!roleId) {
      form.setFieldValue('audition_type', defaultAuditionType);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultAuditionType]);

  const handleUploadSide = async (name: string, type: string, url: string, file?: File) => {
    if (file) {
      if (!uploadedSides.some((side) => side.name === name && side.type === type)) {
        setUploadedSides([...uploadedSides, { name, type, url, file }]);
      }
    }
  };

  const handleRemoveSide = (index: number) => {
    const filteredUploadedSide = uploadedSides.filter((_, i) => i !== index);
    setUploadedSides(filteredUploadedSide);
  };

  const handleUploadPhotos = async (
    name: string,
    type: string,
    url: string,
    file?: File,
    imageAttr?: { width: number; height: number },
  ) => {
    if (file && imageAttr) {
      setUploadedPhotos([...uploadedPhotos, { name, type, url, file, imageAttr }]);
    }
  };

  const handleRemovePhoto = (index: number) => {
    const filteredUploadedPhotos = uploadedPhotos.filter((_, i) => i !== index);
    setUploadedPhotos(filteredUploadedPhotos);
  };

  const handleRemoveRolePhotos = (id: string) => {
    const filteredRolePhotos = getRolePhotos.filter((photo) => photo.attributes.id !== id);
    setGetRolePhotos(filteredRolePhotos);
  };

  const filter = createFilterOptions<string>();

  const onSelectAdditionalEthnicAppearance = (newEthnicAppearance: string) => {
    const getEthnicAppearance = form.values.ethnic_appearance;
    if (getEthnicAppearance) {
      if (!getEthnicAppearance.includes(newEthnicAppearance)) {
        form.setFieldValue('ethnic_appearance', [...getEthnicAppearance, newEthnicAppearance]);
      }
    }
  };

  const onDeleteAdditionalEthnicAppearance = (ethnicAppearance: string) => {
    const getEthnicAppearance = form.values.ethnic_appearance;
    if (getEthnicAppearance) {
      const filteredEthnicAppearances = getEthnicAppearance.filter((v) => v !== ethnicAppearance);
      form.setFieldValue('ethnic_appearance', filteredEthnicAppearances);
    }
  };

  const handleRoleForCheck = (value: string, isChecked: boolean) => {
    const getRoleIsForValues = form.values.role_is_for;
    if (isChecked) {
      if (!getRoleIsForValues.includes(value)) {
        form.setFieldValue('role_is_for', [...getRoleIsForValues, value]);
      }
    } else {
      const filteredRoleValues = getRoleIsForValues.filter((v) => v !== value);
      form.setFieldValue('role_is_for', filteredRoleValues);
    }
  };

  const handleSelectAllTalent = (isChecked: boolean) => {
    if (isChecked) {
      form.setFieldValue(
        'role_is_for',
        talentList.map((t) => t.id),
      );
    } else {
      form.setFieldValue('role_is_for', []);
    }
  };

  const handleEthnicAppearanceCheck = (value: string, isChecked: boolean) => {
    const getEthnicAppearanceValues = form.values.ethnic_appearance || [];
    if (isChecked) {
      if (!getEthnicAppearanceValues?.includes(value)) {
        form.setFieldValue('ethnic_appearance', [...getEthnicAppearanceValues, value]);
      }
    } else {
      const filteredEthnicAppearanceValues = getEthnicAppearanceValues.filter((v) => v !== value);
      form.setFieldValue('ethnic_appearance', filteredEthnicAppearanceValues);
    }
  };

  const handleRoleEthnicAppearanceSelectAll = (isChecked: boolean) => {
    if (isChecked) {
      form.setFieldValue(
        'ethnic_appearance',
        ETHNIC_APPEARANCES.map((v) => v.id),
      );
    } else {
      form.setFieldValue('ethnic_appearance', []);
    }
  };

  const handleGenderCheck = (value: string, isChecked: boolean) => {
    const getGenderValues = form.values.genders || [];
    if (isChecked) {
      if (!getGenderValues.includes(value)) {
        form.setFieldValue('genders', [...getGenderValues, value]);
      }
    } else {
      const filteredGenderValues = getGenderValues.filter((v) => v !== value);
      form.setFieldValue('genders', filteredGenderValues);
    }
  };

  const handleGenderSelectAll = (isChecked: boolean) => {
    if (isChecked) {
      form.setFieldValue(
        'genders',
        gender.map((v) => v.value),
      );
    } else {
      form.setFieldValue('genders', []);
    }
  };

  const handleSituationsCheck = (value: string, isChecked: boolean) => {
    const getSituationsValues = form.values.nudity_sexual_situations || [];
    if (isChecked) {
      if (!getSituationsValues.includes(value)) {
        form.setFieldValue('nudity_sexual_situations', [...getSituationsValues, value]);
      }
    } else {
      const filteredSituationsValues = getSituationsValues.filter((v) => v !== value);
      form.setFieldValue('nudity_sexual_situations', filteredSituationsValues);
    }
  };

  const handleSituationsSelectAll = (isChecked: boolean) => {
    if (isChecked) {
      form.setFieldValue(
        'nudity_sexual_situations',
        SEXUAL_SITUATIONS_LIST.map((v) => v.value),
      );
    } else {
      form.setFieldValue('nudity_sexual_situations', []);
    }
  };

  const generateAge = (start = 0, end = 110) => {
    return Array.from({ length: end - start }).map((_, i) => {
      return {
        key: String(i + start + 1),
        value: i + start + 1,
      };
    });
  };

  const handleSelectSkill = (value: string, isChecked: boolean) => {
    const getRecommendedSkills = form.values.recommended_skills || [];
    if (isChecked) {
      if (!getRecommendedSkills.includes(value)) {
        form.setFieldValue('recommended_skills', [...getRecommendedSkills, value]);
      }
    } else {
      const filteredRecommendedSkills = getRecommendedSkills.filter((v) => v !== value);
      form.setFieldValue('recommended_skills', filteredRecommendedSkills);
    }
  };

  const handleClearSelectedSkill = () => {
    form.setFieldValue('recommended_skills', []);
  };

  const projectSideSelectData = useMemo(() => {
    const results = projectSideData ? projectSideData.data : [];
    return results.map((result) => {
      return {
        key: result.attributes.medium.data.attributes.file_name,
        value: result.attributes.medium.data.attributes.id,
      };
    });
  }, [projectSideData]) as ISelectValue[];

  const handleRemoveDate = (index: number, fieldName: 'audition' | 'callback' | 'fitting' | 'working') => {
    if (form.values[fieldName].dates) {
      const filteredDate = form.values[fieldName].dates!.filter((_, i) => i !== index);

      form.setFieldValue(`${fieldName}.dates`, filteredDate);
    }
  };

  const getSkillsLabel = (skills: string[]) => {
    const skillsLength = skills.length;
    if (skillsLength <= 0) {
      return 'Select Skill';
    }
    return skillsLength <= 3 ? skills.join(', ') : `${skills.slice(0, 3).join(', ')}, +${skillsLength - 3}`;
  };

  const getEthnicAppearancesCheckboxValues = form.values.ethnic_appearance?.filter(
    (ea) => ETHNIC_APPEARANCES.filter((v) => v.value.toLocaleLowerCase() === ea.toLocaleLowerCase()).length > 0,
  );

  const newEthnicAppearancesValues = form.values.ethnic_appearance?.filter(
    (ea) => ETHNIC_APPEARANCES.filter((v) => v.value.toLocaleLowerCase() === ea.toLocaleLowerCase()).length <= 0,
  );

  const isFormDirty =
    !dirtyForm &&
    uploadedSides.length <= 0 &&
    uploadedPhotos.length <= 0 &&
    isEqual(sortBy(getRolePreviousSides), sortBy(previousSides)) &&
    isEqual(roleData?.data.attributes.photos.data, getRolePhotos);

  const isLoading = useMemo(
    () =>
      isRolesLoading ||
      isProjectSideLoading ||
      roleMutateLoading ||
      createRoleSideMutateLoading ||
      uploadMediaMutateLoading ||
      attachSideMutateLoading ||
      isRoleLoading,
    [
      isRolesLoading,
      isProjectSideLoading,
      roleMutateLoading,
      createRoleSideMutateLoading,
      uploadMediaMutateLoading,
      attachSideMutateLoading,
      isRoleLoading,
    ],
  );

  return (
    <div>
      {!isLoading && (
        <Grid container spacing={0} className={classes.container}>
          {/* <Grid item className={`${classes.sectionContainer} description ${classes.fullWidth}`}>
            <Typography variant="text" fontSize={14}>
              Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum lectus ex, ultricies ut sodales et,
              vestibulum nec neque. Vestibulum magna eros, egestas nec feugiat at, imperdiet sed nibh.
            </Typography>
          </Grid> */}
          <Grid item className={classes.twoColumn}>
            <Grid item className={`${classes.sectionContainer}`}>
              <Typography className="heading">General Role Information</Typography>
              <Grid item lg={12} md={12} xs={12}>
                <TextInput
                  label="Role Name"
                  labelId="roleName"
                  tooltip={true}
                  tooltipTitle="Role Name"
                  name="name"
                  value={form.values.name}
                  onChange={form.handleChange}
                  placeholder="Type here..."
                  errorMessage={getErrorMessage(form.touched.name, form.errors.name)}
                  onBlur={form.handleBlur}
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12} style={{ marginTop: '0' }}>
                <Checkbox
                  value={form.values.use_internal_name}
                  onChange={(e) => form.setFieldValue('use_internal_name', e.target.checked)}
                  label="Use a different internal role name"
                  name="use_internal_name"
                  labelPlacement="end"
                  onBlur={form.handleBlur}
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12} className={classes.twoColumn}>
                <Grid item>
                  <InputLabel id="typeOfAudition">Type of audition</InputLabel>
                  <Select
                    variant="outlined"
                    fullWidth
                    data={auditionType}
                    value={form.values.audition_type}
                    IconComponent={SelectArrowDown}
                    onChange={(e) => form.setFieldValue('audition_type', e.target.value)}
                    name="audition_type"
                    onBlur={form.handleBlur}
                  />
                </Grid>
                <Grid item>
                  <InputLabel id="principalOrBackground">Is This a Principal or Background Role?</InputLabel>
                  <Select
                    variant="outlined"
                    fullWidth
                    value={auditionRoleLabel}
                    data={[
                      {
                        key: 'Principal',
                        value: 'principal',
                      },
                      {
                        key: 'Background',
                        value: 'background',
                      },
                    ]}
                    onChange={(e) => {
                      setAuditionRoleLabel(e.target.value as string);
                      form.setFieldValue('principal_role_type', undefined);
                      form.setFieldValue('background_role_type', undefined);
                    }}
                    IconComponent={SelectArrowDown}
                  />
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <InputLabel id="principalRoleType">
                  {auditionRoleLabel === 'principal' ? 'Principal Role Type' : 'Background Role Type'}
                </InputLabel>
                <Select
                  variant="outlined"
                  value={
                    auditionRoleLabel === 'principal'
                      ? form.values.principal_role_type
                      : form.values.background_role_type
                  }
                  onChange={(e) =>
                    auditionRoleLabel === 'principal'
                      ? form.setFieldValue('principal_role_type', e.target.value)
                      : form.setFieldValue('background_role_type', e.target.value)
                  }
                  fullWidth
                  data={auditionRoleLabel === 'principal' ? PRINCIPAL_ROLE_TYPE_LIST : BACKGROUND_ROLE_TYPE_LIST}
                  IconComponent={SelectArrowDown}
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <TextInput
                  label="Spot Name"
                  labelId="spotName"
                  tooltip={true}
                  tooltipTitle="Spot Name"
                  name="spot_name"
                  placeholder="Type here..."
                  value={form.values.spot_name}
                  onChange={form.handleChange}
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <InputLabel id="roleIsFor" style={{ marginBottom: '20px' }}>
                  Role is For:
                </InputLabel>

                <Checkbox
                  value={form.values.role_is_for?.length === talentList.length}
                  label="All Talent"
                  name="role_is_for"
                  labelPlacement="end"
                  onChange={(e) => {
                    handleSelectAllTalent(e.target.checked);
                  }}
                  formControlLabelStyle={{ marginRight: 24 }}
                />
                {talentList.map((talent) => (
                  <Checkbox
                    key={talent.id}
                    value={form.values.role_is_for?.includes(talent.id)}
                    label={talent.value}
                    name="role_is_for"
                    labelPlacement="end"
                    onChange={(e) => {
                      handleRoleForCheck(talent.id, e.target.checked);
                    }}
                    formControlLabelStyle={{ marginRight: 24 }}
                  />
                ))}
              </Grid>
            </Grid>
            <Grid item className={`${classes.sectionContainer}`}>
              <Typography className="heading">Role Rate</Typography>
              <Grid item lg={12} md={12} xs={12}>
                <InputLabel id="payingRole">
                  Is this a paying role?
                  <Tooltip title="Is this a paying role?">
                    <Box component="span">?</Box>
                  </Tooltip>
                </InputLabel>
                <Select
                  variant="outlined"
                  fullWidth
                  value={form.values.paying_role ? 'Yes' : 'No'}
                  onChange={(e) => {
                    const { value } = e.target as { value: string };
                    if (value === 'Yes') {
                      form.setFieldValue('paying_role', true);
                    } else {
                      form.setFieldValue('paying_role', false);
                    }
                  }}
                  data={[
                    { key: 'Yes', value: 'Yes' },
                    { key: 'No', value: 'No' },
                  ]}
                  IconComponent={SelectArrowDown}
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <TextInput
                  label="Role Rate Summary"
                  labelId="roleRateSummary"
                  tooltip={true}
                  tooltipTitle="Role Rate Summary"
                  name="rate_summary"
                  placeholder="Type here..."
                  value={form.values.rate_summary}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <TextareaAutosize
                  minRows={11}
                  placeholder="Type here..."
                  className={classes.textAreaCls}
                  label="Additional Role Rate Information"
                  name="rate_information"
                  value={form.values.rate_information}
                  onChange={form.handleChange}
                  onBlur={form.handleBlur}
                />
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={`${classes.sectionContainer} ${classes.fullWidth} ${classes.notFullElements}`}>
            <Typography className="heading">Demographic Features</Typography>
            {/* <Grid
              item
              className={`${classes.sectionContainer} description ${classes.fullWidth}`}
              style={{ background: '#151b22' }}
            >
              <Typography variant="text" fontSize={14}>
                Lorem ipsum dolor sit amet, consectetur adipiscing elit. Vestibulum lectus ex, ultricies ut sodales et,
                vestibulum nec neque. Vestibulum magna eros, egestas nec feugiat at, imperdiet sed nibh.
              </Typography>
            </Grid> */}
            <Grid item lg={12} md={12} xs={12} className={classes.twoColumn}>
              <Grid item lg={5} md={12} xs={12}>
                <Grid item lg={12} md={12} xs={12} className={classes.twoColumnInline}>
                  <Grid item>
                    <InputLabel id="roleAgeRange">Role Age Range</InputLabel>
                    <Select
                      variant="outlined"
                      fullWidth
                      data={form.values.age_range_type === 'Year' ? generateAge() : generateAge(0, 24)}
                      IconComponent={SelectArrowDown}
                      value={form.values.age_range_from}
                      onChange={(e) => {
                        const value = e.target.value as number;
                        form.setFieldValue('age_range_from', value);
                        if (form.values.age_range_type === 'Year') {
                          if (value >= 110) {
                            form.setFieldValue('age_range_to', 110);
                          } else {
                            if (value > form.values.age_range_to) {
                              form.setFieldValue('age_range_to', value + 1);
                            }
                          }
                        } else {
                          if (value >= 24) {
                            form.setFieldValue('age_range_to', 24);
                          } else {
                            if (value > form.values.age_range_to) {
                              form.setFieldValue('age_range_to', value + 1);
                            }
                          }
                        }
                      }}
                      name="age_range_from"
                    />
                  </Grid>
                  <Box component="span">to</Box>
                  <Grid item>
                    <InputLabel id="roleAgeRange">&nbsp;</InputLabel>
                    <Select
                      variant="outlined"
                      fullWidth
                      data={
                        form.values.age_range_type === 'Year'
                          ? form.values.age_range_from > 0
                            ? generateAge(form.values.age_range_from === 110 ? 109 : form.values.age_range_from)
                            : []
                          : form.values.age_range_from > 0
                          ? generateAge(form.values.age_range_from === 24 ? 23 : form.values.age_range_from, 24)
                          : []
                      }
                      IconComponent={SelectArrowDown}
                      value={form.values.age_range_to}
                      name="age_range_to"
                      onChange={form.handleChange}
                    />
                  </Grid>
                  <div>
                    <RadioGroup
                      row={true}
                      aria-label="position"
                      name="position"
                      value={form.values.age_range_type}
                      onChange={(e) => {
                        const { value } = e.target;
                        if (value === 'Year') {
                          form.setFieldValue('age_range_from', 1);
                          form.setFieldValue('age_range_to', 100);
                        } else {
                          form.setFieldValue('age_range_from', 1);
                          form.setFieldValue('age_range_to', 24);
                        }

                        form.setFieldValue('age_range_type', value);
                      }}
                    >
                      <FormControlLabel
                        value="Year"
                        control={<Radio color="primary" size="small" />}
                        label="Year"
                        classes={{ label: classes.radioFormLabel, root: classes.radioFormRoot }}
                      />
                      <FormControlLabel
                        value="Month"
                        control={<Radio color="primary" size="small" />}
                        label="Month"
                        classes={{ label: classes.radioFormLabel, root: classes.radioFormRoot }}
                      />
                    </RadioGroup>
                  </div>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <Grid item>
                    <InputLabel id="roleAgeRange" style={{ marginBottom: 10 }}>
                      Role Gender
                    </InputLabel>
                    <StyledAccordion
                      defaultExpanded
                      style={{ marginBottom: 16 }}
                      classes={{
                        root: clsx({
                          [classes.genderAccordionError]: form.touched.genders && form.errors.genders,
                        }),
                      }}
                    >
                      <AccordionSummary
                        expandIcon={<ExpandMoreIcon />}
                        aria-controls="panel1a-content"
                        id="panel1a-header"
                      >
                        <Typography
                          fontSize={14}
                          className={clsx({
                            [classes.genderTextError]: form.touched.genders && form.errors.genders,
                          })}
                        >
                          {form.touched.genders && form.errors.genders
                            ? `Gender is required`
                            : `Selected: ${form.values.genders.length > 0 ? form.values.genders.join(', ') : '—'}`}
                        </Typography>
                      </AccordionSummary>
                      <AccordionDetails>
                        <div className={classes.genderContainer}>
                          <Checkbox
                            value={form.values.genders.length === gender.length}
                            label="Select All"
                            name="gender"
                            labelPlacement="end"
                            onChange={(e) => handleGenderSelectAll(e.target.checked)}
                          />
                          {gender.map((value) => (
                            <Checkbox
                              value={form.values.genders.includes(value.value)}
                              label={value.key}
                              name="gender"
                              labelPlacement="end"
                              onChange={(e) => handleGenderCheck(value.value, e.target.checked)}
                            />
                          ))}
                        </div>
                      </AccordionDetails>
                    </StyledAccordion>

                    {/* <Select
                  multiple
                  errorMessage={getErrorMessage(form.touched.genders, form.errors.genders as string)}
                  variant="outlined"
                  fullWidth
                  data={gender}
                  IconComponent={SelectArrowDown}
                  label="Select Genders"
                  value={form.values.genders}
                  onChange={(e) => {
                    form.setFieldValue('genders', e.target.value);
                  }}
                /> */}
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} xs={12} className={classes.roleEthnicAppearance}>
                  <InputLabel id="roleEthnicAppearance" style={{ marginBottom: '20px' }}>
                    Role Ethnic Appearance
                  </InputLabel>
                  <Grid item>
                    <Checkbox
                      value={getEthnicAppearancesCheckboxValues?.length === ETHNIC_APPEARANCES.length}
                      label="Select All"
                      name="roleEthnicAppearance"
                      labelPlacement="end"
                      onChange={(e) => handleRoleEthnicAppearanceSelectAll(e.target.checked)}
                    />
                  </Grid>

                  <Grid item className={classes.twoColumn} style={{ gap: '10px' }}>
                    <Grid item>
                      {ETHNIC_APPEARANCES.slice(0, Math.floor(ETHNIC_APPEARANCES.length / 2)).map(
                        (ethnicAppearance) => (
                          <Grid item>
                            <Checkbox
                              key={ethnicAppearance.id}
                              value={form.values.ethnic_appearance?.includes(ethnicAppearance.id)}
                              label={ethnicAppearance.value}
                              name="roleEthnicAppearance"
                              labelPlacement="end"
                              onChange={(e) => {
                                handleEthnicAppearanceCheck(ethnicAppearance.id, e.target.checked);
                              }}
                            />
                          </Grid>
                        ),
                      )}
                    </Grid>
                    <Grid item>
                      {ETHNIC_APPEARANCES.slice(Math.floor(ETHNIC_APPEARANCES.length / 2)).map((ethnicAppearance) => (
                        <Grid item>
                          <Checkbox
                            key={ethnicAppearance.id}
                            value={form.values.ethnic_appearance?.includes(ethnicAppearance.id)}
                            label={ethnicAppearance.value}
                            name="roleEthnicAppearance"
                            labelPlacement="end"
                            onChange={(e) => {
                              handleEthnicAppearanceCheck(ethnicAppearance.id, e.target.checked);
                            }}
                          />
                        </Grid>
                      ))}
                    </Grid>
                  </Grid>
                </Grid>
                <Grid item lg={12} md={12} xs={12} className={classes.otherRoleEthnicAppearance}>
                  <Autocomplete
                    options={newEthnicAppearancesValues || []}
                    freeSolo={true}
                    clearOnBlur={true}
                    value={null}
                    renderOption={(option: any) => option}
                    filterOptions={(options: any, params: any) => {
                      const filtered = filter(options, params);
                      if (params.inputValue !== '') {
                        filtered.push(`Add "${params.inputValue}"`);
                      }

                      return filtered;
                    }}
                    onChange={(_, newValue) => {
                      if (newValue) {
                        const regValue = newValue
                          .replace(/"/g, '')
                          .replace(/Add\s/g, '')
                          .replace(/[!@#$%^&*()`~]/g, '');
                        if (!ETHNIC_APPEARANCES.some((eh) => eh.value === regValue)) {
                          onSelectAdditionalEthnicAppearance(regValue);
                        }
                      }
                    }}
                    renderInput={(params) => {
                      return (
                        <TextField
                          {...params}
                          placeholder="Add Other Role Ethnic Appearance"
                          variant="outlined"
                          InputProps={
                            {
                              ...params.InputProps,
                            } as Partial<OutlinedInputProps>
                          }
                          InputLabelProps={{ shrink: true }}
                          inputProps={{
                            ...params.inputProps,
                            autoComplete: 'off',
                          }}
                        />
                      );
                    }}
                  />
                  <Box className={classes.twoColumnChipContainer}>
                    {newEthnicAppearancesValues &&
                      newEthnicAppearancesValues.map((v) => (
                        <Chip
                          className={classes.customChip}
                          key={v}
                          deleteIcon={<RemoveIcon width="10" height="8" viewBox="0 0 10 8" fill="none" />}
                          onDelete={() => onDeleteAdditionalEthnicAppearance(v)}
                          variant="outlined"
                          label={v}
                        />
                      ))}
                  </Box>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={`${classes.sectionContainer} ${classes.fullWidth} ${classes.notFullElements}`}>
            <Typography className="heading">Role Description</Typography>
            <Grid item lg={11} md={12} xs={12} className={classes.twoColumn}>
              <Grid item lg={12} md={12} xs={12}>
                <Grid item lg={12} md={12} xs={12}>
                  <TextareaAutosize
                    minRows={5}
                    placeholder="Please do not submit here. For office use only."
                    className={classes.textAreaCls}
                    value={form.values.agents_clients_description}
                    name="agents_clients_description"
                    onChange={form.handleChange}
                    label="Role Description (Agents, Clients)"
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <TextareaAutosize
                    minRows={5}
                    placeholder="Please do not submit here. For office use only."
                    className={classes.textAreaCls}
                    value={form.values.talent_description}
                    name="talent_description"
                    onChange={form.handleChange}
                    label="Role Description (Talent)"
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <InputLabel id="skillsRecommended">Skills Recommended For This Role</InputLabel>
                  <ButtonSkill onClick={() => setIsShowSkillModal(true)} fullWidth variant="outlined">
                    {getSkillsLabel(form.values.recommended_skills || [])}
                  </ButtonSkill>

                  {/* <Select
                    multiple
                    variant="outlined"
                    fullWidth
                    data={mappedSkills || []}
                    value={form.values.recommended_skills || []}
                    IconComponent={SelectArrowDown}
                    onChange={(e) => {
                      form.setFieldValue('recommended_skills', e.target.value as string[]);
                    }}
                  /> */}
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <InputLabel id="roleAgeRange" style={{ marginBottom: 10 }}>
                    Nudity / Sexual Situations
                  </InputLabel>
                  <StyledAccordion
                    defaultExpanded
                    style={{ marginBottom: 16 }}
                    classes={{
                      root: clsx({
                        [classes.genderAccordionError]: form.touched.genders && form.errors.genders,
                      }),
                    }}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      aria-controls="panel1a-content"
                      id="panel1a-header"
                    >
                      <Typography fontSize={14}>
                        {`Selected: ${
                          form.values?.nudity_sexual_situations && form.values?.nudity_sexual_situations?.length > 0
                            ? form.values?.nudity_sexual_situations?.join(', ')
                            : '—'
                        }`}
                      </Typography>
                    </AccordionSummary>
                    <AccordionDetails>
                      <div className={classes.genderContainer}>
                        <Checkbox
                          value={form.values?.nudity_sexual_situations?.length === SEXUAL_SITUATIONS_LIST.length}
                          label="Select All"
                          name="nudity_sexual_situations"
                          labelPlacement="end"
                          onChange={(e) => handleSituationsSelectAll(e.target.checked)}
                        />
                        {SEXUAL_SITUATIONS_LIST.map((value) => (
                          <Checkbox
                            value={form.values?.nudity_sexual_situations?.includes(value.value)}
                            label={value.key}
                            name="nudity_sexual_situations"
                            labelPlacement="end"
                            onChange={(e) => handleSituationsCheck(value.value, e.target.checked)}
                          />
                        ))}
                      </div>
                    </AccordionDetails>
                  </StyledAccordion>
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <InputLabel id="uploadPhotoReferences">Add Photo References</InputLabel>
                  <FileUpload
                    onFileSelected={handleUploadPhotos}
                    icon="image"
                    heading="Add Photo"
                    acceptedFiles="image/png, image/jpeg, image/jpg, .webp, .heif"
                    multiple
                    bodyText="JPEG, PNG or GIF"
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <ImageList className={classes.imageList}>
                    {getRolePhotos.length > 0 &&
                      getRolePhotos.map((photo) => (
                        <ImageListItem key={photo.id}>
                          <img src={photo.attributes.attachment} alt="landscape" />
                          <ImageListItemBar
                            actionIcon={
                              <IconButton aria-label="delete" onClick={() => handleRemoveRolePhotos(photo.id)}>
                                <RemoveIcon width="10" height="8" viewBox="0 0 10 8" />
                              </IconButton>
                            }
                            position="top"
                          />
                        </ImageListItem>
                      ))}
                    {uploadedPhotos.length > 0 &&
                      uploadedPhotos.map((uploadedPhoto, index) => (
                        <ImageListItem key={index}>
                          <img src={uploadedPhoto.url} alt="landscape" />
                          <ImageListItemBar
                            actionIcon={
                              <IconButton aria-label="delete" onClick={() => handleRemovePhoto(index)}>
                                <RemoveIcon width="10" height="8" viewBox="0 0 10 8" />
                              </IconButton>
                            }
                            position="top"
                          />
                        </ImageListItem>
                      ))}
                  </ImageList>
                </Grid>
              </Grid>
              <Grid item lg={12} md={12} xs={12}>
                <Grid item lg={12} md={12} xs={12}>
                  <InputLabel id="uploadSides">Upload Sides</InputLabel>
                  <FileUpload
                    onFileSelected={handleUploadSide}
                    icon="document"
                    heading="Add a Document"
                    acceptedFiles="application/pdf"
                    bodyText="PDF"
                  />
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <List dense={true} className={classes.representativeList}>
                    {uploadedSides.length > 0 &&
                      uploadedSides.map((uploadedSide, index) => (
                        <ListItem key={index}>
                          <ListItemIcon>
                            <DocumentIcon className="document" viewBox="0 0 16 21" />
                          </ListItemIcon>
                          <ListItemText primary={uploadedSide.name} />
                          <ListItemSecondaryAction>
                            <IconButton
                              edge="end"
                              size="small"
                              aria-label="plusminus"
                              onClick={() => handleRemoveSide(index)}
                            >
                              <MinusCircleIcon htmlColor={COLORS.DANGER} />
                            </IconButton>
                          </ListItemSecondaryAction>
                        </ListItem>
                      ))}
                  </List>
                </Grid>
                <Grid item lg={12} md={12} xs={12} style={{ marginTop: '20px' }}>
                  {projectSideSelectData.length > 0 && (
                    <>
                      <InputLabel id="nudity" style={{ marginBottom: 10 }}>
                        Use Previously Uploaded Sides
                      </InputLabel>

                      <StyledAccordion defaultExpanded>
                        <AccordionSummary
                          expandIcon={<ExpandMoreIcon />}
                          aria-controls="panel1a-content"
                          id="panel1a-header"
                        >
                          <Typography fontSize={14}>Select Sides</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                          <div className={classes.prevsideContainer}>
                            {projectSideSelectData.map((value, index) => (
                              <div className={classes.prevSideItemContainer} key={index}>
                                <div className={classes.prevSideItem__detailContainer}>
                                  <DocumentIcon className="document" viewBox="0 0 16 21" fontSize="small" />
                                  <Typography fontSize={14}>{value.key}</Typography>
                                </div>
                                {previousSides.includes(value.value as string) ? (
                                  <IconButton
                                    size="small"
                                    onClick={() => {
                                      const filteredPreviousSides = previousSides.filter((v) => v !== value.value);
                                      setPreviousSides(filteredPreviousSides);
                                    }}
                                  >
                                    <MinusCircleIcon
                                      fontSize="small"
                                      style={{ fontSize: 19 }}
                                      htmlColor={COLORS.DANGER}
                                    />
                                  </IconButton>
                                ) : (
                                  <IconButton
                                    size="small"
                                    onClick={() => setPreviousSides([...previousSides, value.value as string])}
                                  >
                                    <PlusIconTwoTone fontSize="small" style={{ fontSize: 19 }} />
                                  </IconButton>
                                )}
                              </div>
                            ))}
                          </div>
                        </AccordionDetails>
                      </StyledAccordion>
                    </>

                    // <>
                    //   <InputLabel id="nudity">Use Previously Uploaded Sides</InputLabel>
                    //   <Select
                    //     multiple
                    //     variant="outlined"
                    //     fullWidth
                    //     data={projectSideSelectData}
                    //     placeHolder="Select"
                    //     placeholder="Select"
                    //     IconComponent={SelectArrowDown}
                    //     value={previousSides}
                    //     onChange={(e) => {
                    //       const value = e.target.value as string[];
                    //       setPreviousSides(value);
                    //     }}
                    //   />
                    // </>
                  )}
                </Grid>
                <Grid item lg={12} md={12} xs={12}>
                  <InputLabel id="nudity">Who can see these sides?</InputLabel>
                  <Select
                    multiple
                    variant="outlined"
                    fullWidth
                    data={WHO_CAN_SEE_SIDES_LIST}
                    value={form.values.allowed_sides_viewers || []}
                    onChange={(e) => {
                      const { value } = e.target as { value: string[] };
                      if (value.includes('None')) {
                        if (value.indexOf('None') === 0) {
                          const filteredValues = value.filter((v) => v !== 'None');
                          form.setFieldValue('allowed_sides_viewers', filteredValues);
                        } else {
                          form.setFieldValue('allowed_sides_viewers', ['None']);
                        }
                      } else {
                        form.setFieldValue('allowed_sides_viewers', value);
                      }
                    }}
                    IconComponent={SelectArrowDown}
                  />
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item className={`${classes.sectionContainer} ${classes.fullWidth} ${classes.notFullElements}`}>
            <Typography className="heading">Audition Information</Typography>
            <Grid item lg={11} md={12} xs={12} className={classes.twoColumn}>
              <Card variant="outlined" className={classes.customCard}>
                <CardHeader title="Audition Location and Date" />
                <CardContent>
                  <Grid item lg={12} md={12} xs={12}>
                    <TextInput
                      label="Audition Location"
                      labelId="auditionLocation"
                      value={form.values.audition.location}
                      name="audition.location"
                      onChange={form.handleChange}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} className={classes.showToContainer}>
                    <Box component="span">Show to:</Box>
                    <Checkbox
                      value={form.values?.audition?.show_to?.includes('agents')}
                      label="Agents"
                      name="audition_location_show"
                      labelPlacement="end"
                      onChange={(e) => {
                        if (e.target.checked) {
                          form.setFieldValue('audition.show_to', [...(form.values?.audition?.show_to || []), 'agents']);
                        } else {
                          const filteredAuditionShowTo = form.values?.audition?.show_to?.filter((v) => v !== 'agents');
                          form.setFieldValue('audition.show_to', filteredAuditionShowTo);
                        }
                      }}
                    />
                    <Checkbox
                      value={form.values?.audition?.show_to?.includes('casting_app_notice_board')}
                      label="CastingApp Notice Board"
                      name="audition_location_show"
                      labelPlacement="end"
                      onChange={(e) => {
                        if (e.target.checked) {
                          form.setFieldValue('audition.show_to', [
                            ...(form.values?.audition?.show_to || []),
                            'casting_app_notice_board',
                          ]);
                        } else {
                          const filteredAuditionShowTo = form.values?.audition?.show_to?.filter(
                            (v) => v !== 'casting_app_notice_board',
                          );
                          form.setFieldValue('audition.show_to', filteredAuditionShowTo);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} className={classes.scheduleActions}>
                    <Grid item>
                      <DatePicker
                        label="Add Date Range"
                        startIcon={<PlusIcon />}
                        type="range"
                        onChooseDate={(valueDate, valueText) => {
                          const [startDate, endDate] = valueDate as Date[];
                          const formattedStartDate = format(startDate as Date, 'MMM d');
                          const formattedEndDate = format(endDate as Date, 'MMM d');
                          const newDateRange = `${formattedStartDate} - ${formattedEndDate}`;
                          form.setFieldValue('audition.dates', [...(form.values?.audition?.dates || []), newDateRange]);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <DatePicker
                        label="Add Individual Date"
                        startIcon={<PlusIcon />}
                        onChooseDate={(valueDate) => {
                          const formattedDate = format(valueDate as Date, 'MMM d');
                          form.setFieldValue('audition.dates', [
                            ...(form.values?.audition?.dates || []),
                            formattedDate,
                          ]);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Button variant="contained">TBD</Button>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} className={classes.scheduleChips}>
                    {form.values.audition.dates &&
                      form.values.audition.dates.map((item, index) => (
                        <Chip
                          key={index}
                          className={classes.customChip}
                          deleteIcon={<RemoveIcon width="10" height="8" viewBox="0 0 10 8" fill="none" />}
                          onDelete={() => handleRemoveDate(index, 'audition')}
                          variant="outlined"
                          label={item}
                          style={{ width: 'auto' }}
                        />
                      ))}
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} style={{ marginTop: '30px' }}>
                    <TextareaAutosize
                      minRows={4}
                      className={classes.textAreaCls}
                      value={form.values.audition.note}
                      name="audition.note"
                      onChange={form.handleChange}
                      label="Wardrobe / Notes"
                    />
                  </Grid>
                </CardContent>
              </Card>
              <Card variant="outlined" className={classes.customCard}>
                <CardHeader title="Callback Location and Date" />
                <CardContent>
                  <Grid item lg={12} md={12} xs={12}>
                    <TextInput
                      label="Callback Location"
                      labelId="auditionLocation"
                      value={form.values.callback.location}
                      name="callback.location"
                      onChange={form.handleChange}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} className={classes.showToContainer}>
                    <Box component="span">Show to:</Box>
                    <Checkbox
                      value={form.values?.callback?.show_to?.includes('agents')}
                      label="Agents"
                      name="audition_location_show"
                      labelPlacement="end"
                      onChange={(e) => {
                        if (e.target.checked) {
                          form.setFieldValue('callback.show_to', [...(form.values?.callback?.show_to || []), 'agents']);
                        } else {
                          const filteredShowTo = form.values?.callback?.show_to?.filter((v) => v !== 'agents');
                          form.setFieldValue('callback.show_to', filteredShowTo);
                        }
                      }}
                    />
                    <Checkbox
                      value={form.values?.callback?.show_to?.includes('casting_app_notice_board')}
                      label="CastingApp Notice Board"
                      name="audition_location_show"
                      labelPlacement="end"
                      onChange={(e) => {
                        if (e.target.checked) {
                          form.setFieldValue('callback.show_to', [
                            ...(form.values?.callback?.show_to || []),
                            'casting_app_notice_board',
                          ]);
                        } else {
                          const filteredShowTo = form.values?.callback?.show_to?.filter(
                            (v) => v !== 'casting_app_notice_board',
                          );
                          form.setFieldValue('callback.show_to', filteredShowTo);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} className={classes.scheduleActions}>
                    <Grid item>
                      <DatePicker
                        label="Add Date Range"
                        startIcon={<PlusIcon />}
                        type="range"
                        onChooseDate={(valueDate, valueText) => {
                          const [startDate, endDate] = valueDate as Date[];
                          const formattedStartDate = format(startDate as Date, 'MMM d');
                          const formattedEndDate = format(endDate as Date, 'MMM d');
                          const newDateRange = `${formattedStartDate} - ${formattedEndDate}`;
                          form.setFieldValue('callback.dates', [...(form.values?.callback?.dates || []), newDateRange]);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <DatePicker
                        label="Add Individual Date"
                        startIcon={<PlusIcon />}
                        onChooseDate={(valueDate) => {
                          const formattedDate = format(valueDate as Date, 'MMM d');
                          form.setFieldValue('callback.dates', [
                            ...(form.values?.callback?.dates || []),
                            formattedDate,
                          ]);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Button variant="contained">TBD</Button>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} className={classes.scheduleChips}>
                    {form.values.callback.dates &&
                      form.values.callback.dates.map((item, index) => (
                        <Chip
                          key={index}
                          className={classes.customChip}
                          deleteIcon={<RemoveIcon width="10" height="8" viewBox="0 0 10 8" fill="none" />}
                          onDelete={() => handleRemoveDate(index, 'callback')}
                          variant="outlined"
                          label={item}
                          style={{ width: 'auto' }}
                        />
                      ))}
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} style={{ marginTop: '30px' }}>
                    <TextareaAutosize
                      minRows={4}
                      className={classes.textAreaCls}
                      value={form.values.callback.note}
                      name="callback.note"
                      onChange={form.handleChange}
                      label="Notes"
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item className={`${classes.sectionContainer} ${classes.fullWidth} ${classes.notFullElements}`}>
            <Typography className="heading">Work Information</Typography>
            <Grid item lg={11} md={12} xs={12} className={classes.twoColumn}>
              <Card variant="outlined" className={classes.customCard}>
                <CardHeader title="Fitting Location and Date" />
                <CardContent>
                  <Grid item lg={12} md={12} xs={12}>
                    <TextInput
                      label="Fitting Location"
                      labelId="auditionLocation"
                      value={form.values.fitting.location}
                      name="fitting.location"
                      onChange={form.handleChange}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} className={classes.showToContainer}>
                    <Box component="span">Show to:</Box>
                    <Checkbox
                      value={form.values?.fitting?.show_to?.includes('agents')}
                      label="Agents"
                      name="audition_location_show"
                      labelPlacement="end"
                      onChange={(e) => {
                        if (e.target.checked) {
                          form.setFieldValue('fitting.show_to', [...(form.values?.fitting?.show_to || []), 'agents']);
                        } else {
                          const filteredShowTo = form.values?.fitting?.show_to?.filter((v) => v !== 'agents');
                          form.setFieldValue('fitting.show_to', filteredShowTo);
                        }
                      }}
                    />
                    <Checkbox
                      value={form.values?.fitting?.show_to?.includes('casting_app_notice_board')}
                      label="CastingApp Notice Board"
                      name="audition_location_show"
                      labelPlacement="end"
                      onChange={(e) => {
                        if (e.target.checked) {
                          form.setFieldValue('fitting.show_to', [
                            ...(form.values?.fitting?.show_to || []),
                            'casting_app_notice_board',
                          ]);
                        } else {
                          const filteredShowTo = form.values?.fitting?.show_to?.filter(
                            (v) => v !== 'casting_app_notice_board',
                          );
                          form.setFieldValue('fitting.show_to', filteredShowTo);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} className={classes.scheduleActions}>
                    <Grid item>
                      <DatePicker
                        label="Add Date Range"
                        startIcon={<PlusIcon />}
                        type="range"
                        onChooseDate={(valueDate, valueText) => {
                          const [startDate, endDate] = valueDate as Date[];
                          const formattedStartDate = format(startDate as Date, 'MMM d');
                          const formattedEndDate = format(endDate as Date, 'MMM d');
                          const newDateRange = `${formattedStartDate} - ${formattedEndDate}`;
                          form.setFieldValue('fitting.dates', [...(form.values?.fitting?.dates || []), newDateRange]);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <DatePicker
                        label="Add Individual Date"
                        startIcon={<PlusIcon />}
                        onChooseDate={(valueDate) => {
                          const formattedDate = format(valueDate as Date, 'MMM d');
                          form.setFieldValue('fitting.dates', [...(form.values?.fitting?.dates || []), formattedDate]);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Button variant="contained">TBD</Button>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} className={classes.scheduleChips}>
                    {form.values.fitting.dates &&
                      form.values.fitting.dates.map((item, index) => (
                        <Chip
                          key={index}
                          className={classes.customChip}
                          deleteIcon={<RemoveIcon width="10" height="8" viewBox="0 0 10 8" fill="none" />}
                          onDelete={() => handleRemoveDate(index, 'fitting')}
                          variant="outlined"
                          label={item}
                          style={{ width: 'auto' }}
                        />
                      ))}
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} style={{ marginTop: '30px' }}>
                    <TextareaAutosize
                      minRows={4}
                      className={classes.textAreaCls}
                      value={form.values.fitting.note}
                      name="fitting.note"
                      onChange={form.handleChange}
                      label="Fitting Notes"
                    />
                  </Grid>
                </CardContent>
              </Card>
              <Card variant="outlined" className={classes.customCard}>
                <CardHeader title="Working Location and Date" />
                <CardContent>
                  <Grid item lg={12} md={12} xs={12}>
                    <TextInput
                      value={form.values.working.location}
                      label="Working Location"
                      labelId="auditionLocation"
                      name="working.location"
                      onChange={form.handleChange}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} className={classes.showToContainer}>
                    <Box component="span">Show to:</Box>
                    <Checkbox
                      value={form.values?.working?.show_to?.includes('agents')}
                      label="Agents"
                      name="audition_location_show"
                      labelPlacement="end"
                      onChange={(e) => {
                        if (e.target.checked) {
                          form.setFieldValue('working.show_to', [...(form.values?.working?.show_to || []), 'agents']);
                        } else {
                          const filteredAuditionShowTo = form.values?.working?.show_to?.filter((v) => v !== 'agents');
                          form.setFieldValue('working.show_to', filteredAuditionShowTo);
                        }
                      }}
                    />
                    <Checkbox
                      value={form.values?.working?.show_to?.includes('casting_app_notice_board')}
                      label="CastingApp Notice Board"
                      name="audition_location_show"
                      labelPlacement="end"
                      onChange={(e) => {
                        if (e.target.checked) {
                          form.setFieldValue('working.show_to', [
                            ...(form.values?.working?.show_to || []),
                            'casting_app_notice_board',
                          ]);
                        } else {
                          const filteredAuditionShowTo = form.values?.working?.show_to?.filter(
                            (v) => v !== 'casting_app_notice_board',
                          );
                          form.setFieldValue('working.show_to', filteredAuditionShowTo);
                        }
                      }}
                    />
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} className={classes.scheduleActions}>
                    <Grid item>
                      <DatePicker
                        label="Add Date Range"
                        startIcon={<PlusIcon />}
                        type="range"
                        onChooseDate={(valueDate, valueText) => {
                          const [startDate, endDate] = valueDate as Date[];
                          const formattedStartDate = format(startDate as Date, 'MMM d');
                          const formattedEndDate = format(endDate as Date, 'MMM d');
                          const newDateRange = `${formattedStartDate} - ${formattedEndDate}`;
                          form.setFieldValue('working.dates', [...(form.values?.working?.dates || []), newDateRange]);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <DatePicker
                        label="Add Individual Date"
                        startIcon={<PlusIcon />}
                        onChooseDate={(valueDate) => {
                          const formattedDate = format(valueDate as Date, 'MMM d');
                          form.setFieldValue('working.dates', [...(form.values?.working?.dates || []), formattedDate]);
                        }}
                      />
                    </Grid>
                    <Grid item>
                      <Button variant="contained">TBD</Button>
                    </Grid>
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} className={classes.scheduleChips}>
                    {form.values.working.dates &&
                      form.values.working.dates.map((item, index) => (
                        <Chip
                          key={index}
                          className={classes.customChip}
                          deleteIcon={<RemoveIcon width="10" height="8" viewBox="0 0 10 8" fill="none" />}
                          onDelete={() => handleRemoveDate(index, 'working')}
                          variant="outlined"
                          label={item}
                          style={{ width: 'auto' }}
                        />
                      ))}
                  </Grid>
                  <Grid item lg={12} md={12} xs={12} style={{ marginTop: '30px' }}>
                    <InputLabel id="fittingNotes"></InputLabel>
                    <TextareaAutosize
                      minRows={4}
                      className={classes.textAreaCls}
                      value={form.values.working.note}
                      name="working.note"
                      onChange={form.handleChange}
                      label="Work Notes"
                    />
                  </Grid>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
          <Grid item className={`${classes.sectionContainer} ${classes.fullWidth} ${classes.notFullElements}`}>
            <Typography className="heading">Submission Requirements</Typography>
            {/* <Grid item lg={11} md={12} xs={12} className={classes.twoColumn}>
          <Grid item lg={6} md={12} xs={12}>
            <Grid item lg={12} md={12} xs={12} style={{ display: 'flex', flexDirection: 'column' }}>
              <InputLabel id="submissionDueBy">Submissions Due By</InputLabel>
              <TextField
                type="datetime-local"
                variant="outlined"
                inputProps={{
                  class: 'MuiInputBase-input MuiOutlinedInput-input',
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">
                      <CalendarIcon
                        width="16"
                        height="16"
                        viewBox="0 0 16 16"
                        style={{ height: '16px', width: '16px' }}
                      />
                    </InputAdornment>
                  ),
                }}
              />
            </Grid>
            <Grid item lg={12} md={12} xs={12}>
              <InputLabel id="timeZone">Time Zone</InputLabel>
              <Select variant="outlined" fullWidth data={[]} IconComponent={SelectArrowDown} />
            </Grid>
          </Grid>
          <Grid item lg={6} md={12} xs={12}>
            <Grid item lg={12} md={12} xs={12}>
              <InputLabel id="instructionsForSubmissionNote">Instructions for Submission Note</InputLabel>
              <TextareaAutosize minRows={5} placeholder="Type instructions here…" className={classes.textAreaCls} />
            </Grid>
          </Grid>
        </Grid> */}
            Coming Soon
          </Grid>
          <Grid item className={`${classes.sectionContainer} ${classes.fullWidth} ${classes.notFullElements}`}>
            <Typography className="heading">Publication Settings</Typography>
            Coming Soon
            {/* <Grid item lg={11} md={12} xs={12} className={classes.publicationCheckBox}>
          <Grid item>
            <Checkbox
              value="Representatives (Agencies/Managers)"
              label="Representatives (Agencies/Managers)"
              name="publicationCheckBox"
              labelPlacement="end"
            />
          </Grid>
          <Grid item>
            <Checkbox
              value="Talent on the CastingApp Notice Board"
              label="Talent on the CastingApp Notice Board"
              name="publicationCheckBox"
              labelPlacement="end"
            />
          </Grid>
          <Grid item>
            <Checkbox
              value="Freelance Talent"
              label="Freelance Talent"
              name="publicationCheckBox"
              labelPlacement="end"
            />
          </Grid>
          <Grid item>
            <Checkbox value="My Talent" label="My Talent" name="publicationCheckBox" labelPlacement="end" />
          </Grid>
        </Grid> */}
            {/* <Grid item lg={11} md={12} xs={12} className={classes.twoColumn}>
          <Grid item lg={6} md={12} xs={12}>
            <Grid item>
              <InputLabel id="myRepresentativeShortlists">My Representative Shortlists</InputLabel>
              <List dense={true} className={classes.representativeList}>
                {mockRepresentativeData.map((i) => (
                  <ListItem key={i.key}>
                    <ListItemIcon>
                      <ListViewIcon viewBox="0 0 15 12" />
                    </ListItemIcon>
                    <ListItemText primary={i.value} secondary={`(${i.representatives} Representatives)`} />
                    <ListItemSecondaryAction>
                      <IconButton edge="end" aria-label="edit">
                        <EditIcon />
                      </IconButton>
                      <IconButton edge="end" aria-label="plusminus">
                        <PlusIcon />
                      </IconButton>
                    </ListItemSecondaryAction>
                  </ListItem>
                ))}
              </List>
            </Grid>
            <Grid item style={{ marginTop: '0' }}>
              <Select variant="outlined" fullWidth data={[]} IconComponent={SelectArrowDown} />
            </Grid>
            <Grid item>
              <InputLabel id="manuallySelectRepresentatives">Manually Select Representatives</InputLabel>
              <Button variant="contained" className={classes.fullButton}>
                Add Representatives
              </Button>
            </Grid>
            <Grid item>
              <List dense={true} className={classes.representativeList}>
                <ListItem key={0}>
                  <ListItemIcon>
                    <ListViewIcon viewBox="0 0 15 12" />
                  </ListItemIcon>
                  <ListItemText primary="Manual List" secondary={`(150 Representatives)`} />
                  <ListItemSecondaryAction>
                    <IconButton edge="end" aria-label="edit">
                      <EditIcon />
                    </IconButton>
                    <IconButton edge="end" aria-label="plusminus">
                      <PlusIcon />
                    </IconButton>
                  </ListItemSecondaryAction>
                </ListItem>
              </List>
            </Grid>
          </Grid>
          <Grid item lg={6} md={12} xs={12}>
            <Card className={classes.publicationCard}>
              <CardContent>
                <Grid item>
                  <Typography variant="subHeading" color="primary" fontSize={20}>
                    Talent
                  </Typography>
                  <Typography variant="subHeading" color="primary" fontSize={20}>
                    4000
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="text" fontSize={15}>
                    Talent on the CastingApp Notice Board
                  </Typography>
                  <Typography variant="text" fontSize={15}>
                    —
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="text" fontSize={15}>
                    Freelance Talent
                  </Typography>
                  <Typography variant="text" fontSize={15}>
                    1500
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="text" fontSize={15}>
                    My Talent
                  </Typography>
                  <Typography variant="text" fontSize={15}>
                    2500
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="subHeading" color="primary" fontSize={20}>
                    Representatives:
                  </Typography>
                  <Typography variant="subHeading" color="primary" fontSize={20}>
                    6
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="text" fontSize={15}>
                    Agencies
                  </Typography>
                  <Typography variant="text" fontSize={15}>
                    6
                  </Typography>
                </Grid>
                <Grid item>
                  <Typography variant="text" fontSize={15}>
                    Managers
                  </Typography>
                  <Typography variant="text" fontSize={15}>
                    0
                  </Typography>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid> */}
          </Grid>
          <Grid item className={classes.sectionActionButtons}>
            <Button variant="contained" className="back" onClick={() => history.goBack()}>
              Go Back
            </Button>
            <Button
              variant="contained"
              disabled={isSaving || isMutateUpdateRole || isFormDirty}
              color="primary"
              className="save"
              onClick={() => form.handleSubmit()}
            >
              {isSaving ? 'Saving...' : 'Save'}
            </Button>
          </Grid>
        </Grid>
      )}
      {isOpenNotificationModal && <NotificationModal />}
      <SkillModal
        open={isShowSkillModal}
        onClose={() => setIsShowSkillModal(false)}
        selectedSkills={form.values.recommended_skills || []}
        handleSelectSkill={handleSelectSkill}
        handleClearSelectedSkill={handleClearSelectedSkill}
      />
      <Backdrop isLoading={isLoading} />
    </div>
  );
};

export default AddNewRole;
AddNewRole.defaultProps = {
  defaultAuditionType: 'studio',
};
