import { useMutation, useQuery } from 'react-query';
import { profileDao } from 'shared/dao/profileDao';
import { ICreateProfileLinkResponsePayload, IProfileResponsePayload } from 'shared/interfaces/IProfile';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';

const { getProfile: getProfileDao, createProfileLink: createProfileLinkDao } = profileDao();

export const profileService = () => {
  const getProfile = (profileId: string) => {
    return useQuery<IProfileResponsePayload, IAxiosErrorResponse>(['profile', profileId], () =>
      getProfileDao(profileId),
    );
  };

  const createProfileLink = () => {
    return useMutation<ICreateProfileLinkResponsePayload, IAxiosErrorResponse, string>((profileId) =>
      createProfileLinkDao(profileId),
    );
  };

  return {
    getProfile,
    createProfileLink,
  };
};
