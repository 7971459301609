import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function PaperPlane(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 21 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="7otduaqbaa">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="m7.609 15.315 3.536 1.208-2.359 3.21a.652.652 0 0 1-1.177-.385v-4.033zM19.916.074a.652.652 0 0 1 .947.675L18.47 16.836a.656.656 0 0 1-.855.52L8.5 14.242l8.963-10.8L5.88 13.347.44 11.487a.653.653 0 0 1-.09-1.196z"
        fill="url(#7otduaqbaa)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default PaperPlane;
