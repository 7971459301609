import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    miniAudioPlayer: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
      backgroundColor: theme.palette.customBlack.light,
      padding: theme.spacing(0.5),
      borderRadius: 4,
    },
    miniAudioPlayer__description: {},
    miniAudioPlayer__trackerPlaying: {
      color: theme.palette.customGrey.dark,
    },
    miniAudioPlayer__pauseContainer: {
      position: 'relative',
    },
    miniAudioPlayer__pauseIconContainer: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 5,
      right: 0,
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      zIndex: 3,
    },
    circular__background: {
      position: 'absolute',
      zIndex: 1,
      right: 0,
      color: '#353f4c',
    },
    circular__foreground: {
      position: 'relative',
      zIndex: 2,
    },
  }),
);
