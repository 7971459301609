import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { ICreateProfileLinkResponsePayload, IProfileResponsePayload } from 'shared/interfaces/IProfile';

const { GET, POST } = useAxios();
export const profileDao = () => {
  const getProfile = async (profileId: string) => {
    const response = await GET<IProfileResponsePayload>({
      url: `${ENDPOINTS.PROFILE}/${profileId}`,
    });
    return response.data;
  };

  const createProfileLink = async (profileId: string) => {
    const response = await POST<ICreateProfileLinkResponsePayload>({
      url: `${ENDPOINTS.PROFILE}/${profileId}/links`,
    });

    return response.data;
  };

  return {
    getProfile,
    createProfileLink,
  };
};
