import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const ShareIcon = (props: SvgIconProps, className: string) => {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9 0a.818.818 0 1 1 0 1.636H2.898c-.697 0-1.261.565-1.262 1.262v12.204c0 .697.565 1.261 1.262 1.262h12.204c.697 0 1.261-.565 1.262-1.262V9A.818.818 0 1 1 18 9v6.102A2.901 2.901 0 0 1 15.102 18H2.898A2.901 2.901 0 0 1 0 15.102V2.898A2.901 2.901 0 0 1 2.898 0zm8.182 0c.452 0 .818.366.818.818v4.91a.818.818 0 0 1-1.636 0V2.792L9.578 9.578a.818.818 0 1 1-1.156-1.156l6.785-6.786h-2.934a.818.818 0 1 1 0-1.636z"
        fill="#677482"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default ShareIcon;
