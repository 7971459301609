import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const DeclineIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M10 0c5.512 0 10 4.488 10 10s-4.483 10-10 10S0 15.512 0 10 4.488 0 10 0zm0 1.666c-4.592 0-8.334 3.742-8.334 8.334S5.403 18.334 10 18.334c4.597 0 8.334-3.742 8.334-8.334S14.592 1.666 10 1.666zm3.576 3.58 1.178 1.178L11.177 10l3.577 3.576-1.178 1.178L10 11.177l-3.576 3.577-1.178-1.178L8.823 10 5.246 6.424l1.178-1.178L10 8.823l3.576-3.577z"
        fill="#E53737"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default DeclineIconTwoTone;
