import { Dialog, Theme } from '@material-ui/core';
import { withStyles, makeStyles, createStyles } from '@material-ui/styles';

export const DefaultDialog = withStyles((theme: Theme) => ({
  paper: {
    backgroundColor: theme.palette.customBlack.main,
    borderStyle: 'solid',
    borderWidth: '1px',
    borderImageSource: 'linear-gradient(253deg, #d51bff, #00d4ff)',
    borderImageSlice: '1',
    backgroundImage: 'linear-gradient(to bottom, #0e1319, #0e1319), linear-gradient(253deg, #d51bff, #00d4ff)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    borderRadius: '8px !important',
  },
}))(Dialog);

export const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      '&.paper': {
        minWidth: '85%',
        minHeight: '90%',
      },
      '& .MuiDialog-paperWidthSm': {
        maxWidth: '585px !important',
      },
    },
    closeIcon: {
      position: 'absolute',
      top: '20px',
      right: '20px',
      cursor: 'pointer',
    },
    body: {
      padding: '5px 5px 20px',
      display: 'flex',
      flexDirection: 'column',
    },
    header: {
      display: 'flex',
      gap: 15,
      marginBottom: 25,

      '& > div:last-child p:first-child': {
        lineHeight: '1.3',
      },
    },
    avatar: {
      width: 50,
      height: 50,
      borderRadius: 8,
    },
    content: {
      background: '#181f27',
      borderRadius: 8,
      padding: '15px 15px 20px',
      marginBottom: 20,

      '& .MuiTypography-root': {
        fontSize: 15,
        margin: '2px 0 0 10px',
      },

      '& .MuiButtonBase-root': {
        '& span span': {
          width: 20,
          height: 20,
          padding: '8px 9px',
        },

        '& .MuiSvgIcon-root': {
          width: '13px!important',
          height: '10px!important',
          left: '12.5px!important',
          top: '13px!important',
        },
      },
    },
    allPermission: {
      marginBottom: 15,
    },
    permissionCheckboxesContainer: {
      marginLeft: 16,
      display: 'flex',
      flexDirection: 'column',
      gap: 13,
    },
    btn: {
      background: '#3e8bff',
      height: 50,
    },
  }),
);
