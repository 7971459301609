import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    root: {
      margin: theme.spacing(1, 0),
      padding: theme.spacing(2),
      display: 'flex',
      backgroundColor: '#0e1319',
      flexWrap: 'wrap',
      flexGrow: 1,
      border: '1px solid #2d343e',

      gap: 24,
    },
    inputContainer: {
      flex: 1,
    },
    textArea: {
      backgroundColor: 'transparent',
      border: 'none',
      outline: 'none',
      width: '100%',
      resize: 'none',
      color: '#fff',
    },
    actionContainer: {
      display: 'flex',

      flexDirection: 'column',
      justifyContent: 'space-between',
    },
    checkMarkContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    starList: {
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    starIcon: {
      fontSize: 16,
    },
  }),
);
