import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const LikeIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M17.075 6.78H13.08l.785-2.356A3.545 3.545 0 0 0 12.472.366a2.225 2.225 0 0 0-3.037.573l-4.267 6.09a.903.903 0 0 0-.623-.248H.91A.91.91 0 0 0 0 7.69v10.909c0 .502.407.909.91.909h3.635a.91.91 0 0 0 .91-.91v-.12l1.313.877c.15.1.325.153.505.153h8.182a.91.91 0 0 0 .813-.503l3.326-6.652A3.876 3.876 0 0 0 20 10.631v-.925a2.93 2.93 0 0 0-2.925-2.925zM3.636 17.69H1.818V8.599h1.818v9.09zm14.546-7.059c0 .316-.074.627-.215.91l-3.074 6.149H7.545l-2.09-1.396v-6.5l5.47-7.813a.39.39 0 0 1 .539-.102c.642.43.92 1.236.676 1.97l-1.182 3.553a.91.91 0 0 0 .86 1.197h5.257c.61 0 1.106.496 1.107 1.107v.925z"
        fill="#43BA01"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default LikeIconTwoTone;
