import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const UnshadedStarIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="13" viewBox="0 0 14 13" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m7.062 1.232 2.162 3.264 3.408.52-2.316 3.172.582 3.57-3.581-1.335-3.018 1.668.076-3.977-2.48-2.544 3.659-1.122 1.508-3.216z"
        fill-rule="nonzero"
        stroke="#768CA4"
        fill="none"
      />
    </SvgIcon>
  );
};

export default UnshadedStarIconTwoTone;
