import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Date(props: SvgIconProps) {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="6bus3ulz1a">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M15 0c.394 0 .714.32.714.714v1.429h2.143c1.184 0 2.143.96 2.143 2.143v13.571C20 19.041 19.04 20 17.857 20H2.143A2.143 2.143 0 0 1 0 17.857V4.286c0-1.184.96-2.143 2.143-2.143h2.143V.714a.714.714 0 0 1 1.428 0v1.429h8.572V.714c0-.394.32-.714.714-.714zm3.571 8.571H1.43v9.286c0 .395.32.714.714.714h15.714c.395 0 .714-.32.714-.714V8.571zm-4.79 1.638a.714.714 0 0 1 1.01 1.01l-5.715 5.715a.714.714 0 0 1-1.01 0L5.21 14.076a.714.714 0 0 1 1.01-1.01l2.352 2.353zM4.286 3.571H2.143a.714.714 0 0 0-.714.715v2.857H18.57V4.286a.714.714 0 0 0-.714-.715h-2.143V5a.714.714 0 1 1-1.428 0V3.571H5.714V5a.714.714 0 1 1-1.428 0V3.571z"
        fill="url(#6bus3ulz1a)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default Date;
