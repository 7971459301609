import { createStyles, makeStyles } from '@material-ui/core';
import { relative } from 'path';

export const useStyles = makeStyles((theme) =>
  createStyles({
    profileDetail: {
      marginTop: theme.spacing(2),
    },
    profileDetailTabContainer: {
      position: 'relative',
      backgroundColor: theme.palette.customBlack.dark,
      // padding: theme.spacing(4, 3),
      padding: '5px 20px 20px 20px',
      borderBottomLeftRadius: 4,
      borderBottomRightRadius: 4,
      minHeight: 75,
    },
  }),
);
