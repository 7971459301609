import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const RequestedIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="25" height="30" viewBox="0 0 25 30" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="0cryzqobza">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M21.563 0A3.44 3.44 0 0 1 25 3.438v23.124A3.44 3.44 0 0 1 21.562 30H3.439A3.44 3.44 0 0 1 0 26.562V3.438A3.44 3.44 0 0 1 3.438 0h18.124zm1.562 22.5H1.875v4.062c0 .862.701 1.563 1.563 1.563h18.124c.862 0 1.563-.701 1.563-1.563V22.5zM21.562 1.875H3.439c-.862 0-1.563.701-1.563 1.563v17.187H6.25v-1.563C6.25 16.71 8.88 15 12.5 15s6.25 1.709 6.25 4.062v1.563h4.375V3.438c0-.862-.701-1.563-1.563-1.563zm-9.062 15c-2.702 0-4.375 1.135-4.375 2.187v1.563h8.75v-1.563c0-1.052-1.672-2.187-4.375-2.187zm0-10.625A3.755 3.755 0 0 1 16.25 10a3.755 3.755 0 0 1-3.75 3.75A3.755 3.755 0 0 1 8.75 10a3.755 3.755 0 0 1 3.75-3.75zm0 1.875A1.877 1.877 0 0 0 10.625 10c0 1.034.841 1.875 1.875 1.875A1.877 1.877 0 0 0 14.375 10 1.877 1.877 0 0 0 12.5 8.125z"
        fill="url(#0cryzqobza)"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default RequestedIconTwoTone;
