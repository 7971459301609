import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import {
  ICompanyAddressCreatePayload,
  ICompanyLogoUpdatePayload,
  ICompanyMetalDetailsResponsePayload,
  ICompanyResponsePayload,
  ICompanyUpdatePayload,
} from 'shared/interfaces/ICompany';

const { GET, PATCH, POST, DELETE } = useAxios();
export const companyDao = () => {
  const getCompany = async () => {
    const response = await GET<ICompanyResponsePayload>({
      url: ENDPOINTS.COMPANY,
    });
    return response.data;
  };

  const updateCompany = async (payload: ICompanyUpdatePayload) => {
    const response = await PATCH<ICompanyResponsePayload>({
      url: ENDPOINTS.COMPANY,
      data: payload,
    });
    return response.data;
  };

  const updateCompanyLogo = async (formData: FormData) => {
    const response = await PATCH<ICompanyLogoUpdatePayload>({
      url: `${ENDPOINTS.COMPANY}/logo`,
      data: formData,
    });
    return response.data;
  };
  const updateCompanyIcon = async (formData: FormData) => {
    const response = await PATCH<ICompanyLogoUpdatePayload>({
      url: `${ENDPOINTS.COMPANY}/icon`,
      data: formData,
    });
    return response.data;
  };

  const createCompanyAddress = async (payload: ICompanyAddressCreatePayload) => {
    const response = await POST<ICompanyResponsePayload>({
      url: `${ENDPOINTS.COMPANY}/addresses`,
      data: payload,
    });
    return response.data;
  };

  const updateCompanyAddress = async (payload: ICompanyAddressCreatePayload, addressId: string) => {
    const response = await PATCH<ICompanyResponsePayload>({
      url: `${ENDPOINTS.COMPANY}/addresses/${addressId}`,
      data: payload,
    });
    return response.data;
  };

  const deleteCompanyAddress = async (addressId: string) => {
    const response = await DELETE<ICompanyResponsePayload>({
      url: `${ENDPOINTS.COMPANY}/addresses/${addressId}`,
    });
    return response.data;
  };

  const detachCompanyLogo = async (strType: string) => {
    const response = await DELETE<ICompanyResponsePayload>({
      url: `${ENDPOINTS.COMPANY}/${strType}`,
    });
    return response.data;
  };

  const detachCompanyIcon = async (strType: string) => {
    const response = await DELETE<ICompanyResponsePayload>({
      url: `${ENDPOINTS.COMPANY}/${strType}`,
    });
    return response.data;
  };

  const getCompanyMetaDetails = async (companyId: string) => {
    const response = await GET<ICompanyMetalDetailsResponsePayload>({
      url: `${ENDPOINTS.COMPANY_MATCHES}/${companyId}`,
    });
    return response.data;
  };

  return {
    getCompany,
    updateCompany,
    updateCompanyLogo,
    updateCompanyIcon,
    createCompanyAddress,
    updateCompanyAddress,
    deleteCompanyAddress,
    detachCompanyLogo,
    detachCompanyIcon,
    getCompanyMetaDetails,
  };
};
