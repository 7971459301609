import { Grid, IconButton, ImageList, ImageListItem, Typography as Typography2 } from '@material-ui/core';

import { PlayIcon } from 'components/Icons';
import React, { useMemo, useState, useEffect } from 'react';
import { IProfileMediumAttachment } from 'shared/interfaces/IProfile';
import { useStyles } from './TalentProfileMedia.styles';
import { getMediaId } from 'shared/utils/auditionMediaAttachments';
import { uploadService } from 'shared/services/uploadService';
import { useGetThumbnail } from 'shared/hooks/useGetThumbnail';
import { NO_IMAGE_PLACEHOLDER_URL } from 'shared/constants/ASSETS_URL';
import { Image } from 'components/Image';

type Props = {
  video: IProfileMediumAttachment;
  onClick: () => void;
};

const { getSingleMedia } = uploadService();
const VideoItem: React.FC<Props> = ({ video, onClick }) => {
  const classes = useStyles();

  const { thumbnail, handleChangeVideoUrl } = useGetThumbnail();

  useEffect(() => {
    if (video) {
      handleChangeVideoUrl(video.attributes.modified_attachment || video.attributes.attachment || '');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [video]);

  return (
    <Grid item lg={6}>
      <div className={classes.videoPlayerContainer}>
        <Image src={thumbnail} className={classes.videoPlayerThumbnail} />
        <IconButton className={classes.videoPlayerButton} onClick={onClick}>
          <PlayIcon style={{ fontSize: 30 }} />
        </IconButton>
      </div>
    </Grid>
  );
};

export default VideoItem;
