import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const FilmIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="mb342ox47a">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M.81 11.664v6.252c0 1.15.936 2.084 2.085 2.084H17.9c1.15 0 2.084-.935 2.084-2.084v-6.252H.811zm7.472-4.168L6.948 10.83h-3.27l1.334-3.334h3.27zm4.168 0-1.334 3.334h-3.27L9.18 7.496h3.27zM1.654 3.856l2.58 3.801-.203.046L2.78 10.83H.81V8.594L.039 5.54c-.082-.33-.03-.67.148-.959.152-.247.38-.43.65-.525l.138-.04.68-.16zm17.914 3.64c.205 0 .375.147.41.342l.006.075v2.918h-3.802l1.334-3.335h2.052zm-2.95 0-1.334 3.334h-3.27l1.334-3.334h3.27zM5.883 2.852 8.49 6.69l-3.383.77-2.586-3.81 3.362-.797zm3.986-.946 2.873 3.817-3.379.769L6.75 2.646l3.12-.74zM14.1.902l2.652 3.91-3.119.71-2.878-3.826 3.344-.794zm5.126-.075.037.12.71 2.69a.42.42 0 0 1-.232.487l-.08.027-2.036.463L14.966.696l2.801-.665a1.26 1.26 0 0 1 1.458.796z"
        fill="url(#mb342ox47a)"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default FilmIconTwoTone;
