import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    closeButtonContainer: {
      display: 'flex',
      justifyContent: 'flex-end',
    },
    deadline: {
      marginTop: 24,
    },
    dialogContent: {
      padding: '20px 24px',
    },
    datePickerButton: {
      background: '#0e1319',
      color: '#fff',
      width: '100%',
      padding: '15px',
      height: '50px',
      border: `1px solid ${theme.palette.primary.main}`,

      '&:hover': {
        background: '#3e8bff',

        '& svg path': {
          transition: 'background-color 250ms',
          fill: '#fff',
        },
      },
    },
    deadline__buttonContainer: {
      display: 'flex',
      gap: 16,
      justifyContent: 'space-between',
      marginTop: 12,
      '& > div': {
        flex: '100%',
      },
    },

    requirements: {
      marginTop: 24,
    },
    requirements__list: {
      marginTop: 12,
      display: 'flex',
      flexDirection: 'column',
      gap: 10,
    },
    requirements__item: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
      border: '1px solid #2d343e',
      padding: '6px 16px',
      borderRadius: 4,
      '& .switchContainer': {
        margin: 0,
      },
    },
    requirements__itemExpandable: {
      border: '1px solid #2d343e',
      padding: '6px 16px',
      borderRadius: 4,
      '& .switchContainer': {
        margin: 0,
      },
    },
    requirements__itemExpandable__expanded: {
      borderBottom: '1px solid #2d343e',
      paddingBottom: 16,
    },
    requirements__item__leftContainer: {
      display: 'flex',
      gap: 12,
      alignItems: 'center',
    },
    requirements__item__rightContainer: {
      display: 'flex',
      gap: 12,
    },
    requirements__item__valueContainer: {
      display: 'flex',
      gap: 8,
      alignItems: 'center',
    },
    requirements__item__value: {
      border: '1px solid #2d343e',
      padding: '4px 10px',
      borderRadius: 4,
    },
    requirements__item__labelContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    requirements__item__actionButton: {
      fontSize: 16,
      minWidth: 0,
      padding: '2px 10px',
      border: `1px solid ${theme.palette.primary.main}`,
    },
    requirements__item__expandContainer: {
      padding: '4px 8px',
    },
    requirements__item__input: {
      '& .MuiInputBase-input': {
        padding: '11.5px 14px',
      },
    },
    requirements__container: {
      marginTop: 24,
    },
  }),
);
