import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    iconNote: {
      '&:hover': {
        backgroundColor: 'transparent',
      },
    },
  }),
);
