import { IconButton } from '@material-ui/core';
import { PlayIconTwoTone } from '@room-match/shared-ui-components';
import React from 'react';
import { IAuditionAttributes } from 'shared/interfaces/IAudition';
import { IMediumAttributes } from 'shared/interfaces/IMedium';
import { useStyles } from './TalentMedia.styles';
import { NO_IMAGE_PLACEHOLDER_URL } from 'shared/constants/ASSETS_URL';
import { getPrimaryImage } from 'shared/utils/auditionMediaAttachments';

type Props = {
  isVideo?: boolean;
  audition: IAuditionAttributes;
  medium_attachments?: IMediumAttributes[];
  status?: string;
  primaryImage?: string;
};
const TalentMedia: React.FC<Props> = ({ isVideo, audition, medium_attachments, status, primaryImage }) => {
  const classes = useStyles();

  const handleIsAuditioned = (status: string) => {
    const auditionedStatuses = ['Auditioned', 'Late Auditioned', 'Late Overtime Auditioned', 'Overtime Auditioned'];
    return auditionedStatuses.includes(status) ? true : false;
  };

  return (
    <div>
      {isVideo ? (
        <div className={classes.request__videoPlayerContainer}>
          <img src={primaryImage} className={classes.request__videoPlayerThumbnail} />
          <IconButton className={classes.request__videoPlayerButton} size="small">
            <PlayIconTwoTone style={{ fontSize: 30 }} />
          </IconButton>
        </div>
      ) : handleIsAuditioned(status || '') ? (
        <div className={classes.request__videoPlayerContainer}>
          <img src={primaryImage} className={classes.request__videoPlayerThumbnail} />
        </div>
      ) : (
        <div className={classes.request__imageContainer}>
          <img src={primaryImage} className={classes.request__image} />
        </div>
      )}
    </div>
  );
};

export default TalentMedia;
