import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function InstagramIconTwoTone(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M27.78 8.987c-.267.173-.647.333-.807.513-.06.06-.093.127-.08.193v.687l-.2.673-.2.667-.22.733-.453 1.5-.44 1.48-.333 1.114c0 .173-.007.346-.02.513a6.608 6.608 0 0 1-.394 1.827c-.126.353-.28.693-.466 1.013a4.286 4.286 0 0 1-.227.373c-.267.42-.587.807-.94 1.147a5.653 5.653 0 0 1-.9.74h-.007a.843.843 0 0 1-.18.12 4.82 4.82 0 0 1-.653.367c-.153.08-.307.146-.467.206-.033.02-.073.034-.106.047-.38.18-.82.3-1.26.36-.027.007-.047.007-.074.013a8.072 8.072 0 0 1-.793.107c-.12.013-.247.02-.367.02l-.473-.027-2.28-.12c0-.006-.007 0-.007 0l-1.146-.06c-.087 0-.18 0-.267-.006a6.156 6.156 0 0 1-1.053-.127h-.007a6.817 6.817 0 0 1-1.807-.62c-.313-.16-.62-.34-.906-.547a4.522 4.522 0 0 1-.347-.28 8.77 8.77 0 0 1-.873-.846c-.04-.04-.074-.08-.107-.12a1.94 1.94 0 0 1-.113-.127 5.715 5.715 0 0 1-.64-.993 5.046 5.046 0 0 1-.387-.994 3.52 3.52 0 0 1-.1-.38.05.05 0 0 1-.013-.033 7.032 7.032 0 0 1-.16-.847 3.7 3.7 0 0 1-.06-.666c-.014-.087-.014-.18-.014-.267l1.274-1.967 1.246-1.92.567-.873.513-.787.174-.273v-.687c.013-.153.02-.313.013-.46-.007-.466-.1-.92-.253-1.353a7.826 7.826 0 0 0-.567-1.293c-.253-.46-.52-.874-.74-1.247-.327-.547-.553-.993-.493-1.307a6.55 6.55 0 0 1 1.5-.173h10.666c2.787 0 5.187 1.733 6.18 4.173.107.26.2.534.267.814z"
        fill="#304FFE"
      />
      <path
        d="M28 10.667v.18l-.92.533-.587.34-.646.373-1.294.754-1.266.733-1.294.747-.513.3c0 .32-.08.613-.227.88-.206.386-.553.706-.993.98-.447.273-.993.493-1.607.653 0 0 0-.007-.006 0-2.374.613-5.614.333-7.187-.84-.44-.327-.747-.727-.88-1.187-.04-.153-.06-.32-.06-.486V9.833c.007-.1-.06-.2-.18-.3-.36-.286-1.207-.56-2.153-.833-.74-.207-1.534-.413-2.2-.613-.527-.16-.974-.32-1.24-.474.12-.233.26-.466.406-.686A6.696 6.696 0 0 1 10.667 4h7.2c3.58.627 6.88 2.087 9.646 4.173.107.26.2.534.267.814.12.44.193.893.213 1.366.007.1.007.207.007.314z"
        fill="#4928F4"
      />
      <path
        d="M28 10.667v2.94l-.147.453-.5 1.553-.52 1.6-.273.854-.253.793-.247.753-.24.747-.127.393-.166.52c0 .507-.014.954-.047 1.334-.007.04-.013.08-.013.12-.04.353-.094.653-.18.906a.566.566 0 0 1-.034.114c-.173.48-.433.786-.82.986-.093.054-.2.094-.313.134-.353.12-.8.18-1.347.213-.4.027-.86.033-1.38.033h-.46l-.793-.033-.14-.007-1.447-.06-1.466-.06-4.834-.2L11 24.7h-.173c-.52-.007-.967-.04-1.354-.093-.56-.087-.993-.234-1.32-.454-.466-.3-.74-.74-.9-1.353a3.21 3.21 0 0 1-.093-.467A10.52 10.52 0 0 1 7.04 21c0-.1 0-.2-.007-.307-.006-.006 0-.006 0-.006V20.3c-.006-.193-.006-.393-.006-.6l.033-1.073.02-.767.027-.893v-.127l.046-1.64.047-1.64.047-1.54.04-1.513.013-.4c0-.207-.7-.327-1.48-.427-.62-.08-1.3-.153-1.707-.247.034-.153.067-.306.107-.453.12-.48.3-.933.527-1.367.12-.233.26-.466.406-.686A22.218 22.218 0 0 1 9.66 5.48a20.958 20.958 0 0 1 4.287-.44c4.906 0 9.44 1.66 13.026 4.46.347.267.687.553 1.02.853.007.1.007.207.007.314z"
        fill="#6200EA"
      />
      <path
        d="M28 12.247v3.026l-.367.707-.7 1.367-.373.72-.34.66-.147.286c0 .207 0 .407-.013.6 0 .287-.013.56-.033.814-.027.3-.067.573-.107.826-.1.527-.24.98-.44 1.354-.027.046-.053.093-.08.133-.073.12-.16.233-.253.34-.12.147-.254.273-.407.38a2.84 2.84 0 0 1-.8.42c-.38.14-.82.233-1.34.287-.34.033-.713.053-1.12.053l-.28.013-1.387.08h-.006l-1.474.087-1.5.087-2.066.12-2.514.146-.366.02c-.34 0-.66-.02-.967-.06-.033-.006-.06-.013-.093-.013a4.978 4.978 0 0 1-1.24-.36 4.058 4.058 0 0 1-1.18-.787 4.36 4.36 0 0 1-.754-.98c-.046-.086-.093-.166-.133-.26a6.23 6.23 0 0 1-.533-1.566 3.595 3.595 0 0 1-.094-.48c-.073-.427-.113-.88-.133-1.354v-.006c-.007-.194-.013-.38-.013-.58l-.327-.78-.047-.12-.04-.1-.5-1.194-.08-.193-.48-1.153L4.74 13.5h-.007l-.54-1.3L4 11.727v-1.06c0-.42.04-.834.113-1.234.034-.153.067-.306.107-.453.567-.327 1.16-.627 1.767-.893a19.278 19.278 0 0 1 4.413-1.36 20.05 20.05 0 0 1 3.547-.32c4.873 0 9.293 1.766 12.746 4.646A15.97 15.97 0 0 1 28 12.246z"
        fill="#673AB7"
      />
      <path
        d="M28 14.233v3.427l-.38.793-.72 1.5-.007.02c0 .287-.013.547-.033.78-.067.767-.253 1.254-.56 1.554-.22.226-.493.353-.833.42-.02.006-.047.006-.067.013-.107.02-.22.033-.34.033-.413.04-.9.014-1.46-.026-.06 0-.127-.007-.193-.014-.407-.026-.84-.053-1.32-.073-.26-.007-.534-.013-.814-.013h-.013l-.673.053h-.007l-1.513.107-1.727.133-.253.02-2.02.147-1.047.08-1.033.073c-.18 0-.354 0-.527-.007 0 0-.007-.006-.007 0-.753-.013-1.426-.06-2.026-.173-.554-.093-1.04-.24-1.454-.46-.426-.207-.78-.5-1.066-.873-.274-.367-.474-.827-.6-1.38v-.007a7.638 7.638 0 0 1-.147-1.553l-.1-.18-.593-1.06-.087-.147-.047-.093-.62-1.1-.306-.554-.387-.686-.667-1.194L4 13.167v-2.454A16.359 16.359 0 0 1 5.82 9.68c.76-.387 1.547-.713 2.367-.98a18.198 18.198 0 0 1 2.78-.68c.966-.16 1.96-.24 2.98-.24 4.533 0 8.693 1.62 11.9 4.313.146.114.286.24.426.36.56.5 1.094 1.04 1.58 1.607.054.06.107.113.147.173z"
        fill="#8E24AA"
      />
      <path
        d="M28 16.473v4.82a6.553 6.553 0 0 0-.653-.326 5.627 5.627 0 0 0-.487-.214 10.42 10.42 0 0 0-1.04-.393c-.12-.047-.247-.087-.373-.127a16.92 16.92 0 0 0-1.28-.333 12.633 12.633 0 0 0-.534-.093c-.58-.1-1.2-.16-1.846-.167-.054-.007-.114-.007-.167-.007l-1.713.014-2.334.013h-.006l-4.994.04c-1.586 0-2.56.38-3.146.533 0 0-.007 0-.007.007-.62.16-.813.06-.867-1.027-.013-.3-.02-.686-.02-1.16l-.373-.286-.653-.494-.4-.306-.08-.06-1.107-.84-.167-.127-.346-.267-.64-.48L4 14.607V12.34c.067-.053.127-.1.193-.14a17.523 17.523 0 0 1 6.147-2.667c.287-.06.58-.12.88-.16.887-.153 1.8-.226 2.727-.226 4.006 0 7.686 1.393 10.606 3.7.44.346.867.713 1.267 1.106.547.52 1.06 1.074 1.533 1.66.094.12.187.24.28.367.127.16.247.327.367.493z"
        fill="#C2185B"
      />
      <path
        d="M28 19.147v2.186c0 .434-.04.86-.12 1.274a6.62 6.62 0 0 1-.607 1.746 1.384 1.384 0 0 1-.5.007c-.42-.073-.913-.293-1.446-.58a.246.246 0 0 1-.074-.033 8.117 8.117 0 0 1-.513-.287c-.353-.193-.727-.407-1.1-.607-.08-.04-.16-.08-.233-.12-.427-.22-.867-.42-1.307-.573h-.007a3.796 3.796 0 0 0-.293-.093 4.26 4.26 0 0 0-1.14-.174l-.733-.213-3.247-.94h-.007l-1.993-.58h-.007l-.866-.253c-2.507 0-4.047 1.066-4.794.66-.293-.154-.466-.54-.526-1.3a7.976 7.976 0 0 1-.027-.734l-.78-.38-.033-.013H7.64l-.56-.28-.613-.293-.047-.02-.113-.06L5 16.853l-1-.486V14.08c.113-.1.233-.193.353-.287.127-.1.254-.193.38-.293h.007a15.49 15.49 0 0 1 2.507-1.48 14.945 14.945 0 0 1 3.273-1.12c.167-.04.34-.08.513-.107.947-.18 1.92-.273 2.914-.273a15.658 15.658 0 0 1 11.433 4.913 15.518 15.518 0 0 1 1.453 1.78c.034.047.067.094.1.134.247.36.474.726.687 1.106.14.227.26.46.38.694z"
        fill="#D81B60"
      />
      <path
        d="M27.88 22.607a6.62 6.62 0 0 1-1.32 2.853c-.24-.093-.593-.227-1.027-.373v-.007c-.326-.113-.7-.233-1.1-.347a12.148 12.148 0 0 0-1.793-.407 7.145 7.145 0 0 0-1.16-.106l-.307-.087h-.006l-1.614-.466-.993-.287-1.107-.32h-.006l-.36-.1-4.354-1.253-1.253-.36-.933-.22-1.52-.36-.187-.047h-.007l-1.526-.353v-.007l-.274-.06-.14-.033-1.113-.26-.127-.034-.946-.78L4 18.6v-2.72c.247-.24.5-.467.767-.687.08-.073.166-.14.253-.206A13.473 13.473 0 0 1 7.2 13.56c.867-.467 1.787-.84 2.753-1.107.187-.06.374-.113.567-.153a14.106 14.106 0 0 1 3.427-.413 14.396 14.396 0 0 1 11.08 5.173c.44.527.84 1.08 1.193 1.667.033.046.06.086.087.133.213.353.413.72.593 1.093.167.334.313.667.447 1.014.213.533.386 1.08.533 1.64z"
        fill="#F50057"
      />
      <path
        d="M26.853 25.06a6.53 6.53 0 0 1-1.267 1.393c-.313.26-.646.494-1 .694a3.552 3.552 0 0 1-.406-.127c-.44-.153-.9-.407-1.327-.673a15.94 15.94 0 0 1-1.44-1.047l-.14-.12-1.133-.1-.14-.013-1.467-.127-1.52-.133-2.246-.2-3.56-.314-.014-.006-1.253-.607-1.267-.613-1.02-.494-.22-.106-.273-.134-.947-.46-1.26-.606-.393-.194L4 20.9v-2.98c.313-.373.647-.727 1-1.067.227-.213.467-.426.713-.626.04-.034.08-.067.12-.094a.252.252 0 0 1 .087-.066c.393-.32.807-.607 1.233-.867a11.18 11.18 0 0 1 1.554-.827c.58-.26 1.186-.48 1.813-.646a12.618 12.618 0 0 1 3.427-.467c2.72 0 5.233.827 7.306 2.247a12.931 12.931 0 0 1 3.38 3.38c.3.426.567.88.814 1.346.086.174.173.347.246.52.08.167.154.334.227.5.14.347.267.694.38 1.054a12.416 12.416 0 0 1 .553 2.753z"
        fill="#FF1744"
      />
      <path
        d="M25.593 26.28c0 .053 0 .113-.006.173-.314.26-.647.494-1 .694-.147.08-.294.16-.447.226-.153.074-.307.14-.467.2-.226-.12-.533-.266-.86-.406-.46-.207-.96-.394-1.346-.454a1.23 1.23 0 0 0-.26-.026l-1.094-.14H20.1l-1.36-.18-1.373-.18-.64-.08-5.04-.654c-.327 0-.674-.02-1.034-.066-.44-.04-.9-.107-1.36-.2-.453-.08-.913-.187-1.353-.3-.46-.107-.913-.234-1.333-.354a49.68 49.68 0 0 1-2.107-.68 5.837 5.837 0 0 1-.293-.893A6.253 6.253 0 0 1 4 21.333V20.22c.213-.353.447-.7.707-1.027.473-.626 1.013-1.2 1.6-1.706a.27.27 0 0 1 .066-.06l.007-.007c.207-.187.42-.353.647-.513.026-.027.053-.047.08-.067.106-.08.22-.16.34-.233a11.493 11.493 0 0 1 3.133-1.494 11.68 11.68 0 0 1 3.367-.486c2.326 0 4.5.686 6.313 1.86 1.34.86 2.493 1.993 3.373 3.32.107.153.207.306.307.466.46.78.84 1.62 1.12 2.5.033.1.06.2.087.307.053.18.1.367.14.553l.04.147c.093.42.16.86.206 1.3v.007c.04.393.06.793.06 1.193z"
        fill="#FF5722"
      />
      <path
        d="M24.22 26.28c0 .233-.014.487-.04.74l-.04.353a6.333 6.333 0 0 1-2.04.58h-.007c-.206-.126-.447-.28-.693-.453a38.389 38.389 0 0 1-1.287-.953l-.013-.014c-.394-.3-.674-.526-.674-.526l-.706.026-1.36.047-.633.027-2.547.093-2.153.08c-.14.007-.307.007-.514 0h-.006c-.28-.007-.614-.027-.98-.06a67.696 67.696 0 0 1-1.367-.12c-.46-.053-.927-.107-1.373-.16-.494-.053-.96-.113-1.36-.167-.314-.04-.587-.073-.807-.1A6.396 6.396 0 0 1 5.107 25c-.24-.36-.447-.74-.607-1.147a5.833 5.833 0 0 1-.293-.893 9.608 9.608 0 0 1 .746-1.693c.24-.44.52-.86.827-1.26.3-.394.627-.76.98-1.094v-.006c.1-.1.2-.194.3-.28.187-.174.38-.334.58-.487h.007c.006-.013.013-.013.02-.02.16-.127.326-.24.493-.353.987-.674 2.1-1.174 3.3-1.467.793-.193 1.626-.3 2.486-.3 1.694 0 3.294.407 4.7 1.14h.007a10.127 10.127 0 0 1 3.134 2.5c.473.547.88 1.147 1.213 1.78a9.872 9.872 0 0 1 1.087 3.213c.013.08.026.154.033.234.066.46.1.933.1 1.413z"
        fill="#FF6F00"
      />
      <path
        d="M22.853 26.28v.067c0 .226-.02.513-.04.82l-.06.68a5.335 5.335 0 0 1-.653.106h-.007a5.75 5.75 0 0 1-.76.047h-1.166l-.254-.073-1.313-.4-1.333-.4-3.087-.927-1.333-.4s-.554.22-1.334.48h-.006c-.3.1-.627.207-.974.313-.433.127-.893.254-1.346.354a9.636 9.636 0 0 1-1.34.22c-.127.013-.254.02-.367.02a6.779 6.779 0 0 1-1.86-1.514A6.398 6.398 0 0 1 5.107 25c.046-.3.1-.593.18-.88.2-.793.513-1.553.926-2.247.227-.393.48-.773.774-1.126a.118.118 0 0 1 .046-.054c-.006-.006 0-.006 0-.006.087-.114.18-.22.274-.32v-.007c.273-.293.553-.573.86-.833.106-.087.206-.174.32-.26.02-.02.04-.034.066-.054a8.87 8.87 0 0 1 5.394-1.84c2.293 0 4.38.86 5.96 2.274a8.932 8.932 0 0 1 1.893 2.42c.04.066.08.14.113.213.06.12.12.247.174.38.22.48.393.987.513 1.507l.04.16a8.926 8.926 0 0 1 .213 1.953z"
        fill="#FF9800"
      />
      <path
        d="M21.48 26.28c0 .133-.007.28-.013.433-.014.247-.034.514-.067.787-.013.167-.04.333-.067.5H17.68l-.707-.113-2.76-.44-.393-.06-.9-.147c-.393 0-1.247.173-2.147.34-.473.087-.953.18-1.386.24l-.154.02h-.006c-.467-.1-.92-.253-1.347-.453a5.608 5.608 0 0 1-.4-.2 6.786 6.786 0 0 1-1.06-.727c-.007-.08-.013-.147-.013-.18 0-.173.006-.34.02-.507.026-.426.086-.84.18-1.24.146-.606.36-1.193.646-1.733.054-.113.114-.227.18-.333.027-.054.06-.1.087-.154.12-.193.253-.38.387-.566.28-.367.593-.714.926-1.027h.007a.796.796 0 0 1 .08-.073c.033-.027.06-.06.093-.08.134-.12.267-.227.407-.327 0-.007.007-.007.007-.007a7.52 7.52 0 0 1 4.52-1.493c1.32 0 2.546.333 3.62.92h.006a7.544 7.544 0 0 1 2.354 2.02c.246.32.466.66.653 1.02h.007l.1.2c.2.393.36.807.48 1.233h.006c.007.034.02.067.027.1.08.287.147.58.193.88l.02.187c.047.32.067.647.067.98z"
        fill="#FFC107"
      />
      <path
        d="M20.113 26.207c0 .106 0 .22-.013.326v.014c-.013.48-.08.953-.187 1.38a.153.153 0 0 1-.02.073H16.78l-2.567-.553-.34-.074-.053.014-2.847.586-.126.027h-.18c-.427 0-.847-.04-1.254-.12l-.18-.04h-.006c-.467-.1-.92-.253-1.347-.453-.013-.074-.027-.147-.033-.22a5.55 5.55 0 0 1-.067-.887c0-.113 0-.227.007-.34.02-.36.073-.713.153-1.053a5.986 5.986 0 0 1 .467-1.333c.08-.167.173-.327.266-.487.094-.154.194-.3.3-.447a6.018 6.018 0 0 1 1.574-1.493 6.163 6.163 0 0 1 3.4-1.014c.246 0 .486.014.72.047h.013c.713.08 1.38.28 1.993.58h.007a6.17 6.17 0 0 1 2.673 2.533c.074.127.14.26.2.394.094.206.18.426.254.646h.006a6.9 6.9 0 0 1 .187.754v.006c.073.367.113.747.113 1.134z"
        fill="#FFD54F"
      />
      <path
        d="M18.74 26.347v.02c0 .393-.047.78-.14 1.16-.033.16-.08.32-.14.473h-2.987l-1.526-.42-1.527.42h-1.753c-.427 0-.847-.04-1.254-.12a.237.237 0 0 1-.026-.06 4.365 4.365 0 0 1-.2-.873 4.387 4.387 0 0 1-.04-.6c0-.08 0-.167.013-.247.007-.313.053-.62.133-.913.04-.2.1-.394.18-.58.027-.094.067-.18.114-.267.1-.227.22-.447.353-.66.147-.213.307-.413.487-.6.213-.24.453-.46.726-.64.467-.34 1-.593 1.58-.733a4.791 4.791 0 0 1 4.607 1.233.965.965 0 0 1 .107.12h.006c.094.1.18.2.267.313.247.314.453.654.613 1.027.08.173.147.353.2.54.007.027.014.047.02.073.094.327.154.667.167 1.02.013.1.02.207.02.314z"
        fill="#FFE082"
      />
      <path
        d="M17.373 26.347c0 .273-.033.533-.106.78a3.39 3.39 0 0 1-.294.76c-.02.04-.066.113-.066.113h-5.92a.05.05 0 0 1-.014-.027 2.182 2.182 0 0 1-.2-.393 3.056 3.056 0 0 1-.24-.987c-.013-.08-.013-.166-.013-.246 0-.04 0-.087.007-.127a3.07 3.07 0 0 1 .126-.833c.067-.24.154-.46.267-.674 0 0 .007-.006.007-.013.08-.14.166-.28.266-.413.327-.44.76-.8 1.26-1.034h.007c.16-.08.327-.146.5-.193h.007c.306-.093.64-.14.98-.14.393 0 .773.06 1.12.187.126.033.246.086.366.146h.007c.573.274 1.06.7 1.393 1.234.067.1.127.206.18.32.027.046.054.1.074.146.153.347.246.727.273 1.127a.537.537 0 0 1 .007.107c.006.053.006.106.006.16z"
        fill="#FFECB3"
      />
      <path
        d="M20 7.333h-8A4.638 4.638 0 0 0 7.333 12v8c0 2.6 2.067 4.667 4.667 4.667h8c2.6 0 4.667-2.067 4.667-4.667v-8c0-2.6-2.067-4.667-4.667-4.667z"
        stroke="#fff"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path d="M20.667 11.333a.667.667 0 1 0 0-1.333.667.667 0 0 0 0 1.333z" fill="#fff" />
      <path
        d="M16 20a4 4 0 1 0 0-8 4 4 0 0 0 0 8z"
        stroke="#fff"
        strokeWidth="2"
        strokeMiterlimit="10"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </SvgIcon>
  );
}

export default InstagramIconTwoTone;
