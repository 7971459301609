import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    requests__action: {
      borderRight: '1px solid #2d343e',

      display: 'flex',
      width: '86px',
      [theme.breakpoints.down('lg')]: {
        width: 'auto',
      },
      // alignItems: 'center',
    },
    requests__action__approvedAction: {
      [theme.breakpoints.down('lg')]: {
        width: 'auto',
      },
    },
    requests__action__new: {
      display: 'flex',
      alignItems: 'center',
      // padding: '14px 24px',
      justifyContent: 'center',
      flex: 1,
      // [theme.breakpoints.down('lg')]: {
      //   padding: '14px 16px',
      // },
    },
    requests__action__review: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      // padding: '14px 24px',
      flex: 1,
      // [theme.breakpoints.down('lg')]: {
      //   padding: '14px 16px',
      // },
    },
    requests__action__approved: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },
    requests__action__approved__dragIcon: {
      padding: '0 8px',
      [theme.breakpoints.down('lg')]: {
        padding: '0 4px',
      },
    },
    requests__action__approved__likeIcon: {
      backgroundColor: '#0b0f14',
      display: 'flex',
      alignItems: 'center',
      height: '100%',
      borderLeft: '1px solid #2d343e',
      padding: '0 8px',
      [theme.breakpoints.down('lg')]: {
        padding: '0 4px',
      },
      // alignContent: 'center',
      // justifyContent: 'center',
      // justifySelf: 'center',
    },
    requests__action__rejected: {
      display: 'flex',
      flex: 1,
      alignItems: 'center',
      padding: '14px 16px',
      justifyContent: 'center',
      [theme.breakpoints.down('lg')]: {
        padding: '14px 14px',
      },
    },
    requests__action__item: {
      display: 'flex',
      alignItems: 'center',
      flexDirection: 'column',
      // padding: '14px 24px',
      justifyContent: 'center',
      gap: 12,
      flex: 1,
    },
  }),
);
