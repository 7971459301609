export interface IErrorResponse {
  errors: {
    [key: string]: string[];
  };
}

type ErrorValues = {
  [key: string]: string[];
};

const humanizeString = (str: string) => {
  return str
    .replace(/^[\s_]+|[\s_]+$/g, '')
    .replace(/[_\s]+/g, ' ')
    .replace(/^[a-z]/, function (m) {
      return m.toUpperCase();
    });
};

const errorResponseToArray = (error: ErrorValues): string => {
  return Object.entries(error)
    .reduce((acc: string[], curr) => {
      const [key, value] = curr;
      const text = humanizeString(`${key} ${value.join(',')}`);
      return [...acc, text];
    }, [])
    .join('. ');
};

export default errorResponseToArray;
