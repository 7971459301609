import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    twoColumn: {
      display: 'flex',
      gap: '2em',
    },
    pNameHeader: {
      '& p': {
        color: '#c2ccd8',
        fontSize: '12px',
        letterSpacing: 1,
        marginBottom: '10px',
      },
    },
    customChip: {
      width: '100%',
      borderRadius: '5px',
      background: '#2e3540',
      border: 'none',
      justifyContent: 'space-between',

      '& .MuiChip-deleteIcon': {
        height: '15px',
        width: '20px',
      },
    },
  }),
);
