import { useMutation, useQuery } from 'react-query';
import { uploadDao } from 'shared/dao/uploadDao';
import { ICommonResponsePayload } from 'shared/interfaces/ICommon';
import { IUploadCreateResponsePayload } from 'shared/interfaces/IUpload';
import { IAxiosErrorResponse, IOnProgress } from 'shared/interfaces/utils/IAxios';

const {
  getSingleMedia: getSingleMediaDao,
  getSingleMediaAsBlob: getSingleMediaAsBlobDao,
  uploadMedia: uploadMediaDao,
  updateMedia: updateMediaDao,
  deleteMedia: deleteMediaDao,
} = uploadDao();
export const uploadService = () => {
  const getSingleMedia = (mediaId: string) => {
    return useQuery<string, IAxiosErrorResponse, string>(['media', mediaId], () => getSingleMediaDao(mediaId), {
      enabled: !!mediaId,
    });
  };
  const getSingleMediaAsBlob = (mediaId: string) => {
    return useQuery<Blob, IAxiosErrorResponse, Blob>(['media', mediaId], () => getSingleMediaAsBlobDao(mediaId), {
      enabled: !!mediaId,
    });
  };
  const uploadMedia = () => {
    return useMutation<
      IUploadCreateResponsePayload,
      IAxiosErrorResponse,
      { formData: FormData; onProgres?: IOnProgress }
    >(({ formData, onProgres }) => uploadMediaDao(formData, onProgres));
  };

  const updateMedia = () => {
    return useMutation<
      IUploadCreateResponsePayload,
      IAxiosErrorResponse,
      { formData: FormData; mediumId: string; onProgres?: IOnProgress }
    >(({ formData, mediumId, onProgres }) => updateMediaDao(formData, mediumId, onProgres));
  };

  const deleteMedia = () => {
    return useMutation<ICommonResponsePayload, IAxiosErrorResponse, string[]>((mediaIds) => deleteMediaDao(mediaIds));
  };

  return {
    getSingleMedia,
    getSingleMediaAsBlob,
    uploadMedia,
    updateMedia,
    deleteMedia,
  };
};
