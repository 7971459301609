import { useMediaQuery } from '@material-ui/core';
import { Sidebar } from 'components/Sidebar';
import { Header } from 'components/Header';
import React, { Suspense, useState } from 'react';
import theme from 'theme';
import { Backdrop } from '@room-match/shared-ui-components';

import { useStyles } from './PDFLayout.style';

type Props = {
  children: React.ReactNode;
};
const PDFLayout: React.FC<Props> = ({ children }) => {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <main className={classes.content}>
        <Suspense fallback={<Backdrop isLoading={true} />}>
          <>{children}</>
        </Suspense>
      </main>
    </div>
  );
};

export default PDFLayout;
