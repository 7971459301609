import { ClientLayout } from 'components';
import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Redirect, Route } from 'react-router';
import { getUserProfile, selectUserState, userLogout } from 'shared/redux/slicers/user.slicer';
import { tokenService } from 'shared/services/tokenService';
import { navigateToClientRoute } from 'shared/utils/navigateToRoute';

type Props = {
  component: React.FC;
  exact?: boolean;
  path: string;
};
const { getAuthToken, isAuthenticated } = tokenService();
const ClientRoute: React.FC<Props> = ({ component: Component, exact, path }) => {
  // isAuthenticated() ? <Component /> : <Redirect to={ROUTES.LOGIN} />

  const authToken = getAuthToken();
  const dispatch = useDispatch();
  const { isLoggedIn, user } = useSelector(selectUserState);
  useEffect(() => {
    if (authToken && !isLoggedIn) {
      dispatch(getUserProfile());
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [authToken, isLoggedIn]);

  useEffect(() => {
    if (user) {
      if (user.attributes.user_types.includes('casting')) {
        dispatch(userLogout());
        window.location.href = navigateToClientRoute.toLogin('na');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  return (
    <ClientLayout>
      <Route
        component={() => {
          if (isAuthenticated()) {
            return <Component />;
          } else {
            return <Redirect to={navigateToClientRoute.toLogin('na')} />;
          }
        }}
        exact={exact}
        path={path}
      />
    </ClientLayout>
  );
};

export default ClientRoute;
