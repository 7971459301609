import { createStyles, makeStyles } from '@material-ui/core';

export const useAutocompleteStyle = makeStyles(() =>
  createStyles({
    root: {
      '& .MuiInputBase-root': {
        padding: '7px 20px',
      },

      '& .MuiFormControl-root': {
        margin: '0',
      },

      '& .MuiInputBase-input': {
        height: '1em',
      },

      '& .MuiSvgIcon-root': {
        height: '18px',
        width: '18px',
        marginRight: '10px',
      },

      '& .MuiOutlinedInput-root': {
        borderRadius: '5px',
        height: '54px!important',
      },
      '& .MuiAutocomplete-clearIndicator': {
        // padding: '0px !important',
      },
    },
  }),
);
