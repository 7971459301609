import React, { useState } from 'react';

import { DialogContent, Box, Typography, Button } from '@material-ui/core';
import { CloseIcon, SuccessIcon, ErrorIcon, WarningCircleFilledIcon } from '@room-match/shared-ui-components';
import { useStyles, DefaultDialog } from './NotificationModal.styles';

type Props = {
  notification: {
    title: string;
    message: string;
    cta_text?: string;
  };
  notifType?: 'success' | 'error' | 'warning';
  open: boolean;
  onClose: () => void;
};
const NotificationModal: React.FC<Props> = ({ notification, notifType = '', open, onClose }) => {
  const classes = useStyles();

  const renderIcon = (type: string) => {
    switch (type) {
      case 'success':
        return <SuccessIcon />;
      case 'error':
        return <ErrorIcon />;
      case 'warning':
        return <WarningCircleFilledIcon />;
      default:
        return <SuccessIcon />;
    }
  };

  const renderBorder = (type: string) => {
    return type === 'error' ? classes.errorDialog : type === 'warning' ? classes.warningDialog : '';
  };

  const renderButton = (type: string) => {
    return type === 'success'
      ? classes.primaryBtn
      : type === 'error'
      ? classes.errorBtn
      : type === 'warning'
      ? classes.warningBtn
      : '';
  };

  return (
    <DefaultDialog
      open={open}
      onClose={onClose}
      maxWidth="sm"
      fullWidth
      className={`${classes.dialog} ${renderBorder(notifType)}`}
      aria-labelledby="simple-dialog-title"
    >
      <DialogContent>
        <Box className={classes.closeIcon}>
          <Box onClick={onClose}>
            <CloseIcon style={{ width: '14px' }} />
          </Box>
        </Box>
        <Box className={classes.notifBody}>
          {renderIcon(notifType)}
          <Typography variant="h5">{notification?.title}</Typography>
          <Typography variant="body2">{notification?.message}</Typography>
          <Button fullWidth className={`${classes.btnCls} ${renderButton(notifType)}`} onClick={() => onClose()}>
            Close
          </Button>
        </Box>
      </DialogContent>
    </DefaultDialog>
  );
};

export default NotificationModal;
