import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const RoleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.657 2.343A7.948 7.948 0 0 0 8 0a7.948 7.948 0 0 0-5.657 2.343A7.948 7.948 0 0 0 0 8c0 2.137.832 4.146 2.343 5.657A7.948 7.948 0 0 0 8 16a7.948 7.948 0 0 0 5.657-2.343A7.948 7.948 0 0 0 16 8a7.948 7.948 0 0 0-2.343-5.657zM3.472 13.416A4.577 4.577 0 0 1 8 9.388a4.53 4.53 0 0 1 3.224 1.335 4.555 4.555 0 0 1 1.304 2.692A7.03 7.03 0 0 1 8 15.063a7.03 7.03 0 0 1-4.528-1.647zM8 8.422c-1.34 0-2.43-1.09-2.43-2.43S6.66 3.562 8 3.562s2.43 1.09 2.43 2.43S9.34 8.422 8 8.422zm5.335 4.2a5.497 5.497 0 0 0-1.449-2.562 5.48 5.48 0 0 0-2-1.279 3.367 3.367 0 1 0-3.77 0 5.483 5.483 0 0 0-1.861 1.143 5.479 5.479 0 0 0-1.59 2.698A7.033 7.033 0 0 1 .936 8 7.07 7.07 0 0 1 8 .937 7.07 7.07 0 0 1 15.063 8a7.032 7.032 0 0 1-1.728 4.623z"
        fill={props.htmlColor || '#ffffff'}
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default RoleIcon;
