import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {
      width: '100%',
      marginTop: '10px',
      padding: '20px',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      flexDirection: 'column',
      borderRadius: '4px',
      border: theme.palette.type === 'dark' ? 'dashed 1px #2d343e' : 'dashed 1px #FFFFFF',
      background: theme.palette.type === 'dark' ? '#0e1319' : '#FFFFFF',
    },
    uploaderContainer: {
      width: 'auto',
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      color: '#6e767f',
      '& h6': {
        color: '#fff',
        fontSize: '14px',
      },
      '& p': {
        fontSize: '13px',
      },
    },
    uploaderHeader: {
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      marginBottom: '5px',
    },
    uploadProcessed: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      alignItems: 'center',
      justifyContent: 'space-between',
      marginBottom: '5px',
      '& > svg:last-child path': {
        fill: '#6e767f',
      },
    },
    fileContainer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      alignItems: 'flex-start',
      padding: '15px',
      flexGrow: 1,
      '& > *:first-child': {
        color: '#FFFFFF',
      },
      '& > *:last-child': {
        color: '#6e767f',
      },
    },
    headingTop: {
      marginBottom: '0!important',
    },
    heightLg: {
      height: 100,
    },
  }),
);
