import React, { useEffect, useMemo, useState } from 'react';
import { InputAdornment, Box, Button, Grid } from '@material-ui/core';
import { InputPassword, PasswordStrength, Backdrop } from '@room-match/shared-ui-components';
import { FormikProps, useFormik } from 'formik';
import { Link, useHistory, useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import * as yup from 'yup';
import { FrontLayout } from 'components';
import { PasswordIcon } from 'components/Icons';
import { IResetPasswordRequestPayload } from 'shared/interfaces/IUser';
import { clientResetPassword, selectUserState, userLogin, userResetPassword } from 'shared/redux/slicers/user.slicer';
import { getErrorMessage } from 'shared/utils/getErrorMessage';
import { PasswordPrinciple, validatePassword } from 'shared/utils/passwordUtil';
import { ROUTES } from 'shared/constants/ROUTES';

import { useStyles } from './ClientResetPassword.styles';
import { companyService } from 'shared/services/companyService';
import { navigateToClientRoute } from 'shared/utils/navigateToRoute';

interface LoginState {
  userName: string;
  password: string;
  rememberMe: boolean;
}

const { getCompanyMetaDetails } = companyService();
const ResetPassword = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const token = urlParams.get('reset_token');
  const clientName = urlParams.get('name');
  const projectId = urlParams.get('project_id');
  const { companyId } = useParams() as { companyId: string };
  const classes = useStyles();
  const dispatch = useDispatch();
  const history = useHistory();
  const [passwordValidationResult, setPasswordValidationResult] = useState<PasswordPrinciple | null>(null);
  const [password_str, setPasswordStr] = useState<string>('');

  const { data: companyMetaDetailsData, isLoading: isCompanyDetailsMetaLoading } = getCompanyMetaDetails(companyId);

  const { isLoading, isLoggedIn } = useSelector(selectUserState);

  useEffect(() => {
    if (isLoggedIn) {
      history.push(ROUTES.CASTING.PROJECTS.PROJECT_LIST);
    }
  }, [isLoggedIn, history]);

  // Forms
  const initialValues: IResetPasswordRequestPayload = {
    reset_password_token: token || '',
    password: '',
    password_confirmation: '',
  };

  const resetPasswordValidationSchema: yup.SchemaOf<IResetPasswordRequestPayload> = yup.object({
    reset_password_token: yup.string().required('Token is required'),
    password: yup
      .string()
      .required('Password is required')
      .min(8, 'Password must be atleast 8 characters')
      .test('passwordValidate', 'Invalid password', (value: any) => {
        if (value) {
          const validatePasswordResult = validatePassword(value);
          setPasswordValidationResult(validatePasswordResult);
          return validatePasswordResult.IsValid;
        }
        return false;
      }),
    password_confirmation: yup
      .string()
      .required('Password confirmation is required')
      .oneOf([yup.ref('password')], 'Your passwords do not match.'),
  });

  const asyncHandleResetPassword = (values: IResetPasswordRequestPayload): Promise<any> =>
    Promise.resolve(dispatch(clientResetPassword(values)));
  const handleResetPassword = async (values: IResetPasswordRequestPayload) => {
    const response = await asyncHandleResetPassword(values);
    if (response) {
      if (projectId) {
        history.push(navigateToClientRoute.toClientViewPreview(projectId));
      } else {
        history.push(ROUTES.CLIENT.PROJECTS.PROJECT_LIST);
      }
    }
  };

  const form: FormikProps<IResetPasswordRequestPayload> = useFormik({
    initialValues,
    validationSchema: resetPasswordValidationSchema,
    onSubmit: (values) => handleResetPassword(values),
  });

  const companyMetaDetails = useMemo(() => {
    return companyMetaDetailsData ? companyMetaDetailsData.data : null;
  }, [companyMetaDetailsData]);

  return (
    <FrontLayout
      containerWidth={800}
      bannerImage={companyMetaDetails?.attributes.icon ? companyMetaDetails.attributes.icon : ''}
      companyLogo={companyMetaDetails?.attributes.logo ? companyMetaDetails.attributes.logo : ''}
      isClient={true}
      clientName={clientName || ''}
    >
      <Box>
        <Grid container spacing={2}>
          <Grid item>
            <Box className={classes.boxContainer}>
              <InputPassword
                variant={'outlined'}
                margin={'normal'}
                name="password"
                placeholder="Enter 8 Digit Password"
                onChange={(e) => {
                  if (form.errors.password && !form.touched.password) {
                    form.setFieldTouched('password');
                    form.validateField('password');
                  }
                  setPasswordStr(e.target.value || '');
                  return form.handleChange(e);
                }}
                errorMessage={getErrorMessage(form.touched.password, form.errors.password)}
                value={form.values.password}
                fullWidth
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon style={{ marginTop: '5px' }} />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
                inputProps={{ tabIndex: 8 }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
              <InputPassword
                variant={'outlined'}
                margin={'normal'}
                name="password_confirmation"
                placeholder="Repeat 8 Digit Password"
                fullWidth
                onChange={(e) => {
                  if (form.errors.password_confirmation && !form.touched.password_confirmation) {
                    form.setFieldTouched('password_confirmation');
                    form.validateField('password_confirmation');
                  }
                  return form.handleChange(e);
                }}
                errorMessage={getErrorMessage(form.touched.password_confirmation, form.errors.password_confirmation)}
                value={form.values.password_confirmation}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">
                      <PasswordIcon style={{ marginTop: '5px' }} />
                    </InputAdornment>
                  ),
                }}
                InputLabelProps={{ shrink: true }}
                inputProps={{ tabIndex: 8 }}
                onCopy={(e) => {
                  e.preventDefault();
                  return false;
                }}
                onPaste={(e) => {
                  e.preventDefault();
                  return false;
                }}
              />
              <PasswordStrength password={password_str} />
            </Box>
            <Box className={classes.boxContainer}>
              <Button
                color="primary"
                variant="contained"
                disableElevation
                fullWidth
                tabIndex={10}
                onClick={() => form.handleSubmit()}
                className={classes.buttonCls}
              >
                Save and Access Auditions
              </Button>
            </Box>
          </Grid>
          <Grid>
            <Backdrop isLoading={isLoading} />
          </Grid>
        </Grid>
      </Box>
    </FrontLayout>
  );
};

export default ResetPassword;
