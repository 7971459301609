import { Grid, Button, IconButton, Box, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import React, { useState } from 'react';
import { Typography } from 'shared/elements';
import { useParams } from 'react-router';

import { CurrentTimeIcon, InputSearchIcon, FilterIcon, SelectArrowDown, PlusIcon } from 'components/Icons';
import { Select } from '@room-match/shared-ui-components';

import MoreVertIcon from '@material-ui/icons/MoreVert';
import { useStyles } from './StudioSessionActions.styles';
import { ROUTES } from 'shared/constants/ROUTES';

import placeholderOptions from 'data/Placeholder.json';
import { useAutocompleteStyle } from 'shared/theme/styles/autocompleteStyle';
import ActiveIndicator from 'components/Icons/ActiveIndicator';

import { AddNewSession } from 'components/AddNewSession';
import { useClockTimer } from 'shared/hooks/useClockTimer';

type Props = {
  currentTab?: string;
};
const StudioSessionActions: React.FC<Props> = ({ currentTab }) => {
  const classes = useStyles();
  const { currentTime } = useClockTimer();
  const autocompleteStyle = useAutocompleteStyle();
  const { childTab } = useParams() as { childTab: string };

  return (
    <Grid item container className={classes.container}>
      <Grid item>
        <Button variant="contained" startIcon={<CurrentTimeIcon style={{ height: '15px' }} />}>
          <Typography fontSize={14} style={{ marginRight: '5px' }}>
            Current Time:{' '}
          </Typography>
          <Typography fontSize={14} color="primary">
            {currentTime}
          </Typography>
        </Button>
      </Grid>
      <Grid item style={{ width: '340px' }} className={classes.sel_container}>
        <Autocomplete
          disableClearable
          freeSolo
          className={autocompleteStyle.root}
          options={placeholderOptions.map((option) => option.title)}
          fullWidth
          renderInput={(params) => (
            <TextField
              {...params}
              placeholder={'Type Name, Agency, Role...'}
              variant="outlined"
              margin="normal"
              InputProps={{
                ...params.InputProps,
                startAdornment: <InputSearchIcon viewBox="0 0 18 18" />,
              }}
            />
          )}
        />
      </Grid>
      <Grid item>
        <Button variant="contained">
          <FilterIcon style={{ height: '15px' }} />
        </Button>
      </Grid>
      <Grid item style={{ width: '236px' }} className={classes.sel_container}>
        <Select variant="outlined" fullWidth data={[]} IconComponent={SelectArrowDown} />
      </Grid>
      {childTab === 'waiting' ? (
        <Grid item className={classes.legend}>
          <Grid item className={classes.greenIndicator}>
            Arrived Before / On-Time
          </Grid>
          <Grid item className={classes.redIndicator}>
            Arrived Late
          </Grid>
        </Grid>
      ) : (
        ''
      )}
    </Grid>
  );
};

export default StudioSessionActions;
