import { gql } from 'graphql-request';

export const GET_AUDITION_SCHEDULE_GROUPS = gql`
  query ($session_id: ID!) {
    audition_schedule_groups(sessionId: $session_id) {
      id
      group_name
      group_number
      auditioned
      viewed
      role_names
      medium_attachments {
        id
        attachment
        modified_attachment
        sort
        tag_list
        primary
      }
      audition_schedules {
        id
        phase
        status
        checked_in_at
        grouping_list
        medium_attachments {
          id
          attachment
          modified_attachment
          sort
          tag_list
          primary
        }
        schedule {
          time_in
          time_out
        }
        audition {
          id
          sequence_tag
          priority
          submission_note
          mediumable_count
          internal_note_count
          public_note_count
          internal_rating
          public_rating
          profile {
            id
            primary_medium_attachment {
              primary
              medium_id
              mediumable_id
              mediumable_type
              attachment
              modified_attachment
            }
            talent {
              id
              email
              first_name
              last_name
              sag_status
              contact_no
            }
            agency {
              name
              email
              company_type
              contact_first_name
              contact_last_name
              contact_number
              country
              state
              website
            }
          }
          medium_attachments {
            id
            attachment
            modified_attachment
            sort
            tag_list
            medium {
              file_type
              file_name
              file_size
              file_width
              file_height
            }
          }
          casting_role {
            id
            name
          }
        }
      }
    }
  }
`;
