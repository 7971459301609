import { createStyles, makeStyles, withStyles, Slider } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    container: {},
  }),
);

export const SliderStyled = withStyles({
  root: {
    color: '#52af77',
    height: 0,
    padding: '0px !important',
    marginBottom: '15px',
    '& .MuiSlider-rail': {
      background: '#343c47 !important',
    },
  },
  thumb: {
    height: 28,
    width: 28,
    backgroundColor: '#fff',
    border: '1px solid #ffffff',
    marginTop: -6,
    marginLeft: -12,
    '&:focus, &:hover, &$active': {
      boxShadow: 'inherit',
    },
  },
  active: {},
  valueLabel: {
    left: 'calc(-50% + 4px)',
  },
  track: {
    height: 16,
    borderRadius: 8,
  },
  rail: {
    height: 16,
    borderRadius: 8,
  },
})(Slider);
