import { Button, styled } from '@material-ui/core';

export const BoxContainer = styled('div')(() => ({
  '& .MuiOutlinedInput-root': {
    height: '60px',
    borderRadius: '6px',
  },

  '& .MuiTypography-caption.MuiTypography-colorError': {
    margin: '3px 14px 0 14px',
  },

  '& input': {
    fontSize: '14px',
  },
}));

export const ExtraContainer = styled('div')(() => ({
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
  marginTop: '5px',
  alignItems: 'center',
  '& a, & span': {
    fontSize: '14px',
  },
  '& a': {
    color: '#3e8bff',
    textDecoration: 'none',
  },
}));

export const LoginFooter = styled('div')(() => ({
  marginTop: '15px',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'center',
  '& *': {
    fontSize: '14px',
  },
  '& span': {
    color: '#6e767f',
  },
  '& p': {
    color: '#3e8bff',
    textDecoration: 'none',
    opacity: 0.5,
    cursor: 'default',
  },
}));

export const CustomButton = styled(Button)(() => ({
  height: '60px !important',
  borderRadius: '6px',
  textTransform: 'unset',
}));
