import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const YoutubeOutlinedIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="21" height="15" viewBox="0 0 21 15" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M11.156 0c1.281.005 5.866.049 7.23.416a2.952 2.952 0 0 1 2.078 2.09c.366 1.375.408 3.967.413 4.582v.258c-.005.614-.047 3.206-.413 4.582a2.952 2.952 0 0 1-2.079 2.09c-1.363.367-5.948.41-7.229.415h-.557c-1.282-.005-5.867-.048-7.23-.416a2.952 2.952 0 0 1-2.079-2.09C.924 10.552.883 7.96.878 7.346v-.258c.005-.615.046-3.207.412-4.582A2.952 2.952 0 0 1 3.37.416c1.363-.368 5.948-.41 7.23-.415zm-.279 1.172c-1.616 0-6.06.067-7.203.376a1.778 1.778 0 0 0-1.251 1.26c-.37 1.387-.374 4.379-.374 4.409v.013c0 .221.017 3.058.374 4.396a1.778 1.778 0 0 0 1.25 1.26c1.144.308 5.588.376 7.204.376h.199c1.754-.006 5.906-.08 7.005-.376a1.778 1.778 0 0 0 1.25-1.26c.37-1.387.374-4.38.374-4.41v-.013c0-.22-.017-3.057-.373-4.395a1.778 1.778 0 0 0-1.25-1.26c-1.144-.309-5.589-.376-7.205-.376zM8.365 3.406l6.692 3.81-6.692 3.812V3.406zm1.172 2.016v3.59l3.152-1.795-3.152-1.795z"
        fill="#FFF"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default YoutubeOutlinedIcon;
