import React, { useMemo } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useStyles } from './Avatar.styles';
import clsx from 'clsx';

type Props = {
  title?: string;
  style?: object;
  className?: string;
};
const backgroundColors = [
  '#c05334',
  '#8757db',
  '#bd0f14',
  '#933aa9',
  '#0e386f',
  '#647c8e',
  '#343cc0',
  '#34c0a1',
  '#238944',
  '#7d91f1',
  '#006daf',
  '#fe8300',
  '#00b68a',
  '#ffb11d',
  '#de2390',
];

const Avatar: React.FC<Props> = ({ title = 'No Company', style, className }) => {
  const classes = useStyles();

  const firstChar = title.charAt(0);

  const randomizeColors = useMemo(() => {
    const randomIndex = Math.floor(Math.random() * backgroundColors.length);
    const item = backgroundColors[randomIndex];
    return item;
  }, []);

  return (
    <Box className={clsx(classes.container, className)} style={{ background: randomizeColors, ...style }}>
      <Typography variant="h5">{firstChar}</Typography>
    </Box>
  );
};

export default React.memo(Avatar, (prev, next) => prev.title === next.title);
