import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    boxContainer: {
      '& .MuiOutlinedInput-root': {
        height: '60px',
        borderRadius: '6px',
      },

      '& input': {
        fontSize: '14px',
      },
    },
    extraContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      marginTop: '5px',
      alignItems: 'center',
      '& a, & span': {
        fontSize: '14px',
      },
      '& a': {
        color: '#3e8bff',
      },
    },
    link: {
      color: '#3e8bff',
      textDecoration: 'none',
    },
    buttonCls: {
      height: '60px !important',
      borderRadius: '6px',
      textTransform: 'unset',
    },
    loginFooter: {
      marginTop: '15px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'center',
      '& *': {
        fontSize: '14px',
      },
      '& span': {
        color: '#6e767f',
      },
    },
  }),
);
