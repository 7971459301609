import { IAuditionAttributes } from 'shared/interfaces/IAudition';
import { IMediumAttributes } from 'shared/interfaces/IMedium';
import { NO_IMAGE_PLACEHOLDER_URL } from '../constants/ASSETS_URL';

export const getMediaSlates = (mediums: IMediumAttributes[] | null) => {
  return mediums
    ? mediums
        .filter((medium) => medium.tag_list.length > 0 && medium.tag_list[0].startsWith('Slate'))
        .sort((a, b) => a.tag_list[0].localeCompare(b.tag_list[0]))
    : [];
};

export const getMediaScenes = (mediums: IMediumAttributes[] | null) => {
  return mediums
    ? mediums
        .filter((medium) => medium.tag_list.length > 0 && medium.tag_list[0].startsWith('Scene'))
        .sort((a, b) => a.tag_list[0].localeCompare(b.tag_list[0]))
    : [];
};

export const getMediaImages = (mediums: IMediumAttributes[] | null) => {
  return mediums
    ? mediums.filter((medium) => medium.tag_list.length > 0 && medium.tag_list[0].startsWith('Image'))
    : [];
};

export const getMediaId = (url: string) => {
  const splitted = url.split('/');
  if (splitted.length > 0) {
    const splitted2 = splitted[splitted.length - 1].split('?');
    const mediaId = splitted2[0];
    return mediaId || '';
  }
  return url;
};

export const getScenesPerGroup = (mediums: IMediumAttributes[] | null, groupId: string) => {
  return mediums
    ? mediums
        .filter(
          (medium) =>
            medium.tag_list.length >= 2 && medium.tag_list[0].startsWith('Scene') && medium.tag_list[1] === groupId,
        )
        .sort((a, b) => a.tag_list[0].localeCompare(b.tag_list[0]))
    : [];
};

export const getSlatesPerGroup = (auditionScheduleMediums: IMediumAttributes[] | null, groupId: string) => {
  return auditionScheduleMediums && auditionScheduleMediums.length > 0
    ? auditionScheduleMediums
        .filter(
          (medium) =>
            medium.tag_list.length >= 2 && medium.tag_list[0].startsWith('Slate') && medium.tag_list[1] === groupId,
        )
        .sort((a, b) => a.tag_list[0].localeCompare(b.tag_list[0]))
    : [];
};

export const getMediaImagesPerGroup = (auditionScheduleMediums: IMediumAttributes[] | null, groupId: string) => {
  return auditionScheduleMediums && auditionScheduleMediums.length > 0
    ? auditionScheduleMediums.filter(
        (medium) =>
          medium.tag_list.length <= 3 && medium.tag_list[0].startsWith('Image') && medium.tag_list[1] === groupId,
      )
    : [];
};

export const getViewMediaImagePerGroup = (auditionScheduleMediums: IMediumAttributes[] | null, groupId: string) => {
  return auditionScheduleMediums && auditionScheduleMediums.length > 0
    ? auditionScheduleMediums.filter(
        (medium) =>
          medium.tag_list.length <= 2 && medium.tag_list[0].startsWith('Image') && medium.tag_list[1] === groupId,
      )
    : [];
};

export const getPrimaryImage = (audition: IAuditionAttributes, auditionScheduleMediumPerGroup: IMediumAttributes[]) => {
  const filtered = auditionScheduleMediumPerGroup.filter((medium) => {
    if (medium.tag_list.length <= 3) {
      return medium.tag_list[0].startsWith('Image') && medium.primary;
    }
    return null;
  });
  if (filtered.length > 0) {
    return filtered[0].attachment || filtered[0].modified_attachment;
  }

  if (audition.profile.primary_medium_attachment) {
    return (
      audition.profile.primary_medium_attachment.attachment ||
      audition.profile.primary_medium_attachment.modified_attachment
    );
  }
  return NO_IMAGE_PLACEHOLDER_URL;
};

export const getGroupPrimaryImage = (auditionScheduleGroupMediums: IMediumAttributes[], groupId: string) => {
  if (auditionScheduleGroupMediums.length <= 0) {
    return NO_IMAGE_PLACEHOLDER_URL;
  }
  const filtered = auditionScheduleGroupMediums.filter((medium) => {
    if (medium.tag_list.length <= 2) {
      return null;
    }
    return (
      medium.tag_list[0].startsWith('Image') &&
      medium.primary &&
      medium.tag_list[1] === groupId &&
      medium.tag_list[2].startsWith('Group')
    );
  });
  if (filtered.length > 0) {
    return filtered[0].attachment || filtered[0].modified_attachment;
  }
  return NO_IMAGE_PLACEHOLDER_URL;
};
