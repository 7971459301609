import { Accordion, AccordionDetails, AccordionSummary, IconButton } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import { Checkbox, DownloadIcon } from '@room-match/shared-ui-components';
import React, { useEffect, useState } from 'react';
import { IAuditionScheduleAttributes } from 'shared/interfaces/IAuditionSchedule';
import { IAuditionScheduleGroupAttributes } from 'shared/interfaces/IAuditionScheduleGroup';
import { getFullName } from 'shared/utils/getFullName';
import { useStyles } from './AuditionGroupItem.styles';

type Props = {
  auditionScheduleGroup: IAuditionScheduleGroupAttributes;
};
const AuditionGroupItem: React.FC<Props> = ({ auditionScheduleGroup }) => {
  const classes = useStyles();
  const [selectedAudition, setSelectedAudition] = useState<string[]>([]);

  useEffect(() => {
    if (auditionScheduleGroup.audition_schedules.length > 0) {
      setSelectedAudition(auditionScheduleGroup.audition_schedules.map((v) => v.id));
    }
  }, [auditionScheduleGroup]);

  const [accordionOpened, setAccordionOpened] = useState<boolean>(false);
  const handleAccordionChange = () => {
    setAccordionOpened(!accordionOpened);
  };

  const handleSelectAll = (isChecked: boolean) => {
    if (isChecked) {
      setSelectedAudition(auditionScheduleGroup.audition_schedules.map((v) => v.id));
    } else {
      setSelectedAudition([]);
    }
  };

  const handleCheckAudition = (value: string, isChecked: boolean) => {
    if (isChecked) {
      if (!selectedAudition.includes(value)) {
        setSelectedAudition((curr) => [...curr, value]);
      }
    } else {
      const filteredAudition = selectedAudition.filter((v) => v !== value);
      setSelectedAudition(filteredAudition);
    }
  };

  return (
    <Accordion className={classes.accordion} expanded={accordionOpened}>
      <AccordionSummary className={classes.accordionHeader} aria-controls="panel4bh-content" id="panel4bh-header">
        <div>
          <Checkbox
            value={selectedAudition.length === auditionScheduleGroup.audition_schedules.length}
            checked={selectedAudition.length === auditionScheduleGroup.audition_schedules.length}
            label={auditionScheduleGroup.group_name}
            name="auto_share"
            labelPlacement="end"
            onChange={(e) => handleSelectAll(e.target.checked)}
            onClick={(e) => {
              e.stopPropagation();
            }}
            id="testcheckboxid"
          />
        </div>
        <div className={classes.accordionHeader__right}>
          <IconButton
            onClick={(e) => {
              e.stopPropagation();
            }}
            size="small"
            disabled={selectedAudition.length <= 0}
            className={classes.downloadButton}
          >
            <DownloadIcon />
          </IconButton>
          {!accordionOpened && (
            <KeyboardArrowDown className={classes.arrowDown} onClick={() => handleAccordionChange()} />
          )}
          {accordionOpened && <KeyboardArrowUp className={classes.arrowUp} onClick={() => handleAccordionChange()} />}
        </div>
      </AccordionSummary>
      <AccordionDetails>
        <div className={classes.auditionContainer}>
          {auditionScheduleGroup.audition_schedules.map((schedule) => (
            <Checkbox
              key={schedule.id}
              label={getFullName(
                schedule.audition.profile.talent?.first_name,
                schedule.audition.profile.talent?.last_name,
              )}
              value={selectedAudition.includes(schedule.id)}
              labelPlacement="end"
              onChange={(e) => {
                return handleCheckAudition(schedule.id, e.target.checked);
              }}
              onClick={(e) => {
                e.stopPropagation();
              }}
            />
          ))}
        </div>
      </AccordionDetails>
    </Accordion>
  );
};

export default AuditionGroupItem;
