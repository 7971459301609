import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const DoubleDownIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="14" height="14" viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <clipPath id="71bt0ggura">
          <path d="M1920 0v1176H0V0h1920z" />
        </clipPath>
        <clipPath id="cf4dmdqfbb">
          <path d="m7.412 6.588 6.417 6.416a.583.583 0 1 1-.825.825L7 7.825.996 13.829a.583.583 0 1 1-.825-.825l6.417-6.416a.583.583 0 0 1 .824 0zm0-6.417 6.417 6.417a.583.583 0 1 1-.825.824L7 1.408.996 7.412a.583.583 0 1 1-.825-.824L6.588.17a.583.583 0 0 1 .824 0z" />
        </clipPath>
        <linearGradient id="azxvl4dyyc" x1="7" y1="0" x2="7" y2="14" gradientUnits="userSpaceOnUse">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <g clip-path="url(#71bt0ggura)" transform="translate(-222 -179)">
        <g clip-path="url(#cf4dmdqfbb)" transform="matrix(1 0 0 -1 222 193)">
          <path fill="url(#azxvl4dyyc)" d="M0 0h14v14H0V0z" />
        </g>
      </g>
    </SvgIcon>
  );
};

export default DoubleDownIconTwoTone;
