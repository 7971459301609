import { createStyles, makeStyles, styled, Theme } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    emptyList: {
      width: '100%',
      background: '#0b0f14',
      border: '1px solid #2d343e',
      borderRadius: '5px',
      padding: '10px 25px',
      textAlign: 'center',
      height: 'max-content',
    },
  }),
);

export const EmptyListContainer = styled('div')<Theme, { type: 'light' | 'dark' }>(({ theme, type }) => ({
  width: '100%',
  background: '#0b0f14',
  border: `1px solid ${type === 'dark' ? '#2d343e' : theme.palette.primary.main}`,
  borderRadius: '5px',
  padding: '10px 25px',
  textAlign: 'center',
  height: 'max-content',
}));
