import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function UploadIconTwoTone(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 23 20" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="sxzjmeayua">
            <stop stopColor="#3E8BFF" offset="0%" />
            <stop stopColor="#3E8BFF" offset="100%" />
          </linearGradient>
        </defs>
        <path
          d="M15.539 0c.84 0 1.595.323 2.155.884.494.513.825 1.19.876 1.947l.008.208v7.888c1.013.129 1.94.582 2.672 1.271a4.56 4.56 0 0 1 1.336 3.233A4.56 4.56 0 0 1 18.017 20c-1.014 0-1.937-.349-2.7-.911l-.188-.145H3.04c-.84 0-1.595-.323-2.155-.884a3.115 3.115 0 0 1-.877-1.947L0 15.905V3.04c0-.84.323-1.595.884-2.155A3.115 3.115 0 0 1 2.83.007L3.039 0h12.5zm2.457 12.047c-.949 0-1.81.367-2.414.992a3.46 3.46 0 0 0-.963 2.881l.036.222.086.28c.022.13.065.259.108.367.043.086.086.15.13.237.064.13.129.258.215.366.065.065.13.151.194.216.086.086.172.194.259.28a3.4 3.4 0 0 0 2.349.948c.948 0 1.788-.366 2.413-.991.604-.625.992-1.466.992-2.392 0-.949-.367-1.79-.992-2.414a3.46 3.46 0 0 0-2.413-.992zm.021.906a.63.63 0 0 1 .194.043c.086.043.151.086.194.129l1.336 1.336c.216.237.216.604 0 .82-.237.215-.603.215-.819 0l-.344-.346v2.371a.58.58 0 0 1-.582.582.58.58 0 0 1-.582-.582v-2.37l-.345.344c-.237.216-.603.216-.819 0-.216-.237-.216-.603 0-.819l1.336-1.336a.509.509 0 0 1 .194-.13.63.63 0 0 1 .194-.042zm-4.18-5.13L9.03 12.651a.607.607 0 0 1-.742.064l-.077-.064-3.254-3.254-3.815 3.836v2.672c0 .517.216.992.539 1.336.307.307.715.511 1.166.553l.17.008v.043h11.056c-.072-.1-.134-.22-.193-.34l-.087-.177v-.022a7.576 7.576 0 0 1-.215-.647c-.022-.086-.044-.15-.065-.237a4.738 4.738 0 0 1-.108-.948 4.56 4.56 0 0 1 1.336-3.233c.101-.115.211-.22.33-.316l.188-.136.107-.065.173-.108.323-.194c.065-.043.13-.064.216-.107a.704.704 0 0 1 .113-.064l.059-.023.259-.086c.107-.043.194-.064.28-.086l.097-.032.097-.033-3.147-3.168zm1.702-6.66h-12.5c-.517 0-.992.216-1.336.561-.307.287-.511.694-.553 1.144l-.008.17v8.557l3.405-3.448a.578.578 0 0 1 .742-.065l.077.065 3.255 3.275 4.806-4.827a.578.578 0 0 1 .742-.065l.077.065 3.19 3.19V3.06c0-.517-.216-.991-.561-1.336a1.891 1.891 0 0 0-1.336-.56zM8.513 4.936a1.552 1.552 0 1 1 0 3.104 1.552 1.552 0 0 1 0-3.104z"
          fill="url(#sxzjmeayua)"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default UploadIconTwoTone;
