import { gql } from 'graphql-request';

// export const GET_WORKSHEET_AUDITIONS = gql`
//   query (
//     $project_id: ID!
//     $status: String
//     $search: String
//     $agency_id: ID
//     $role_id: ID
//     $session_id: ID
//     $sort_by: String
//   ) {
//     worksheet_auditions(
//       projectId: $project_id
//       status: $status
//       search: $search
//       agencyId: $agency_id
//       roleId: $role_id
//       sessionId: $session_id
//       sortBy: $sort_by
//     ) {
//       id
//       sequence_tag
//       priority
//       submission_note
//       mediumable_count
//       internal_note_count
//       public_note_count
//       internal_rating
//       public_rating
//       audition_schedules {
//         main
//         phase
//         status
//         id
//         checked_in_at
//         schedule {
//           time_in
//           time_out
//         }
//         audition_schedule_groups {
//           id
//           group_name
//           group_number
//         }
//         medium_attachments {
//           attachment
//           modified_attachment
//           sort
//           tag_list
//           primary
//         }
//         schedule {
//           casting_session {
//             id
//             session_date
//           }
//         }
//       }
//       profile {
//         id
//         talent {
//           first_name
//           last_name
//           country
//           country_code
//           contact_no
//           id
//           sag_status
//           sag_id
//           birth_date
//         }
//         agency {
//           name
//           email
//           company_type
//           contact_first_name
//           contact_last_name
//           contact_number
//           country
//           state
//           website
//         }
//         primary_medium_attachment {
//           primary
//           medium_id
//           mediumable_id
//           mediumable_type
//           attachment
//           modified_attachment
//         }
//       }
//       medium_attachments {
//         attachment
//         primary
//         mediumable_type
//         modified_attachment
//         tag_list
//       }
//       casting_role {
//         id
//         name
//         audition_type
//       }
//     }
//   }
// `;
export const GET_WORKSHEET_AUDITIONS = gql`
  query (
    $first: Int
    $after: String
    $project_id: ID!
    $status: String
    $search: String
    $agency_id: ID
    $role_id: ID
    $session_id: ID
    $sort_by: String
  ) {
    worksheet_connection(
      first: $first
      after: $after
      projectId: $project_id
      status: $status
      search: $search
      agencyId: $agency_id
      roleId: $role_id
      sessionId: $session_id
      sortBy: $sort_by
    ) {
      pageInfo {
        startCursor
        endCursor
        hasNextPage
      }
      nodes {
        id
        sequence_tag
        priority
        submission_note
        mediumable_count
        internal_note_count
        public_note_count
        internal_rating
        public_rating
        audition_schedules {
          main
          phase
          status
          id
          checked_in_at
          schedule {
            time_in
            time_out
          }
          audition_schedule_groups {
            id
            group_name
            group_number
          }
          medium_attachments {
            attachment
            modified_attachment
            sort
            tag_list
            primary
          }
          schedule {
            casting_session {
              id
              session_date
            }
          }
        }
        profile {
          id
          talent {
            first_name
            last_name
            country
            country_code
            contact_no
            id
            sag_status
            sag_id
            birth_date
          }
          agency {
            name
            email
            company_type
            contact_first_name
            contact_last_name
            contact_number
            country
            state
            website
          }
          primary_medium_attachment {
            primary
            medium_id
            mediumable_id
            mediumable_type
            attachment
            modified_attachment
          }
        }
        medium_attachments {
          attachment
          primary
          mediumable_type
          modified_attachment
          tag_list
        }
        casting_role {
          id
          name
          audition_type
        }
      }
    }
  }
`;
