import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const FullCalendarIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="24" height="25" viewBox="0 0 24 25" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="7uvhhpyb2a">
          <stop stopColor="#838D9C" offset="0%" />
          <stop stopColor="#838D9C" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M5.813 0v1.875h12.375V0h1.875v1.875h1.125A2.816 2.816 0 0 1 24 4.688v16.5A2.816 2.816 0 0 1 21.187 24H2.813A2.816 2.816 0 0 1 0 21.187v-16.5a2.816 2.816 0 0 1 2.813-2.812h1.124V0h1.876zm16.312 8.813H1.875v12.374c0 .517.42.938.938.938h18.375c.516 0 .937-.42.937-.938V8.813zM5.437 18.28v1.875H3.563v-1.875h1.876zm3.75 0v1.875H7.313v-1.875h1.875zm3.75 0v1.875h-1.874v-1.875h1.874zm3.75 0v1.875h-1.875v-1.875h1.876zm-11.25-3.75v1.875H3.563v-1.875h1.876zm3.75 0v1.875H7.313v-1.875h1.875zm3.75 0v1.875h-1.874v-1.875h1.874zm3.75 0v1.875h-1.875v-1.875h1.876zm3.75 0v1.875h-1.875v-1.875h1.875zm-15-3.75v1.875H3.563v-1.875h1.876zm3.75 0v1.875H7.313v-1.875h1.875zm3.75 0v1.875h-1.874v-1.875h1.874zm3.75 0v1.875h-1.875v-1.875h1.876zm3.75 0v1.875h-1.875v-1.875h1.875zM3.938 3.75H2.813a.939.939 0 0 0-.938.938v2.25h20.25v-2.25a.939.939 0 0 0-.938-.938h-1.125v1.875h-1.875V3.75H5.813v1.875H3.938V3.75z"
        transform="translate(0 .692)"
        fill={props.htmlColor || '#838D9C'}
        fillRule="nonzero"
        opacity="1"
      />
    </SvgIcon>
  );
};

export default FullCalendarIcon;
