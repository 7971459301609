import { HorizontalBarsIcon } from 'components/Icons';
import React from 'react';
import { SliderStyled } from './PrettoSlider.styles';

type Props = {
  value?: number;
  color?: string;
  min?: number;
  max?: number;
  step?: number;
  disabled?: boolean;
  onChange?: (instance: any, newValue: number | number[]) => void;
};

const PrettoSlider: React.FC<Props> = ({
  value = 0,
  color = '#3e8bff',
  min = 0,
  max = 1,
  step = 0,
  disabled = false,
  onChange,
}) => {
  const Icon = (props: any) => {
    return (
      <span {...props}>
        <HorizontalBarsIcon style={{ width: '15px' }} />
      </span>
    );
  };

  return (
    <SliderStyled
      ThumbComponent={Icon}
      min={min}
      max={max}
      step={step}
      valueLabelDisplay="off"
      aria-label="pretto slider"
      value={value}
      disabled={disabled}
      style={{
        color: color ? color : '#52af77',
      }}
      onChange={onChange}
    />
  );
};

export default PrettoSlider;
