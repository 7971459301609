import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function CurrentTime(props: SvgIconProps) {
  return (
    <SvgIcon width="15" height="16" viewBox="0 0 15 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="ehkmyrt01a">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M7.5 0C11.63 0 15 3.37 15 7.5S11.63 15 7.5 15 0 11.63 0 7.5 3.37 0 7.5 0zm0 .879A6.629 6.629 0 0 0 .879 7.5c0 3.65 2.97 6.621 6.621 6.621 3.65 0 6.621-2.97 6.621-6.621 0-3.65-2.97-6.621-6.621-6.621zm0 10.576a.44.44 0 0 1 .44.44v.878a.44.44 0 0 1-.88 0v-.878a.44.44 0 0 1 .44-.44zm-3.729-.666a.44.44 0 1 1 0 .88.44.44 0 0 1 0-.88zm7.147.13a.44.44 0 1 1 .622.62.44.44 0 0 1-.622-.62zM7.5 4.423a.44.44 0 0 1 .44.44v2.454l2.507 2.508a.44.44 0 1 1-.621.621L7.189 7.811a.44.44 0 0 1-.128-.311V4.863a.44.44 0 0 1 .439-.44zM3.105 7.06a.44.44 0 0 1 0 .878h-.878a.44.44 0 0 1 0-.878zm9.668 0a.44.44 0 0 1 0 .878h-.878a.44.44 0 0 1 0-.878zM11.23 3.33a.44.44 0 1 1 0 .88.44.44 0 0 1 0-.88zm-7.769.13a.44.44 0 1 1 .622.62.44.44 0 0 1-.622-.62zM7.5 1.786a.44.44 0 0 1 .44.44v.878a.44.44 0 0 1-.88 0v-.878a.44.44 0 0 1 .44-.44z"
        transform="translate(0 .5)"
        fill="url(#ehkmyrt01a)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default CurrentTime;
