import { SwitchProps, Box, FormControlLabel } from '@material-ui/core';
import React from 'react';

import { useStyles, CustomSwitch } from './Switch.styles';

type Props = {
  customVariant?: 'customSwitch';
  disabled: boolean;
  toggle: (instance: any) => void;
} & SwitchProps;

const Button: React.FC<Props> = ({ toggle, disabled, checked, ...props }) => {
  const classes = useStyles();
  const renderSwitch = () => {
    return (
      <Box className={classes.switchComp}>
        <Box />
        <Box />
        <Box />
      </Box>
    );
  };

  return (
    <Box className={classes.switchContent}>
      <FormControlLabel
        control={
          <CustomSwitch
            className={!disabled ? '' : classes.disabled}
            icon={renderSwitch()}
            checkedIcon={renderSwitch()}
          />
        }
        label=""
        onChange={toggle}
        disabled={!disabled ? false : true}
        className={props.className}
        checked={!checked}
      />
    </Box>
  );
};

export default Button;
