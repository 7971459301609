export enum AuditionStatus {
  AUDITIONED = 'Auditioned',
  SCHEDULES = 'Scheduled',
  NEW = 'New',
  NEED_EDIT = 'Need Edit',
  CD_APPROVAL = 'CD Approval',
  APPROVED = 'Approved',
  HIDDEN = 'Hidden',
  CALLBACK = 'Callback',
  RELEASED = 'Released',
  REQUESTED = 'Requested',
  DECLINED = 'Declined',
  CONFIRMED = 'Confirmed',
  NO_SHOW = 'No Show',
  LATE = 'Late',
  WAITING = 'Waiting',
  CHECKED_IN = 'Checked In',
  RESCHEDULED = 'Rescheduled',
  OVERTIME = 'Overtime',
}
