import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function YoutubeIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.693 4h1.416l.914 3.664h.088l.872-3.662h1.432l-1.639 5.357v3.974h-1.408V9.531L9.693 4zm4.657 3.949c0-.523.17-.941.506-1.25.336-.31.788-.466 1.357-.466.518 0 .942.163 1.272.491.33.325.495.747.495 1.263v3.49c0 .577-.161 1.032-.485 1.362-.324.33-.768.494-1.338.494-.549 0-.985-.172-1.316-.511-.329-.339-.492-.796-.492-1.37V7.948h.001zm1.287 3.63c0 .183.044.329.136.43.088.1.215.151.381.151.17 0 .303-.051.403-.156.099-.1.15-.244.15-.425V7.897a.46.46 0 0 0-.154-.357.58.58 0 0 0-.399-.137.527.527 0 0 0-.374.137.473.473 0 0 0-.143.357v3.682zm6.975-5.176v6.93h-1.25v-.844c-.23.276-.47.486-.72.628a1.45 1.45 0 0 1-.725.216c-.29 0-.506-.099-.654-.301-.147-.2-.219-.5-.219-.905V6.403h1.25v5.258c0 .157.026.273.08.346.05.069.137.108.253.108.094 0 .21-.048.348-.144.142-.094.27-.216.387-.362V6.403h1.25z"
        fill="#455A64"
      />
      <path
        d="M17.738 20.386a.649.649 0 0 1 .172-.129c.2-.1.375-.053.465.066.066.088.101.231.101.416v3.337c0 .214-.041.313-.08.36-.015.021-.063.082-.217.082a.508.508 0 0 1-.23-.053.858.858 0 0 1-.211-.162v-3.917zM22.262 20.35c.06-.08.16-.12.305-.12.14 0 .236.038.293.117.045.068.1.208.1.505v.516h-.795v-.516c0-.302.054-.44.097-.501z"
        fill="#F44336"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M22.98 14.8c1.593 0 2.886 1.265 2.886 2.826.095 1.235.14 2.47.134 3.708a45.889 45.889 0 0 1-.134 3.707c0 1.564-1.293 2.829-2.886 2.829-2.275.093-4.604.135-6.98.13-2.375.003-4.704-.037-6.98-.13-1.592 0-2.884-1.265-2.884-2.829A46.252 46.252 0 0 1 6 21.334c-.003-1.237.04-2.473.135-3.708 0-1.561 1.294-2.827 2.885-2.827 2.276-.094 4.605-.135 6.98-.132a161.31 161.31 0 0 1 6.98.132zM10.33 25.723a.137.137 0 0 0 .137-.136v-7.353h1.278a.136.136 0 0 0 .137-.136V16.83a.137.137 0 0 0-.137-.136H7.55a.137.137 0 0 0-.137.136v1.27c0 .073.061.135.137.135h1.276v7.353c0 .074.06.136.137.136h1.366zm4.863 0a.137.137 0 0 0 .137-.136h-.002v-6.462a.137.137 0 0 0-.137-.136h-1.214a.137.137 0 0 0-.138.136v4.855c-.093.111-.198.207-.316.284-.144.098-.224.112-.26.112-.102 0-.127-.032-.136-.044-.018-.026-.048-.092-.048-.255v-4.952a.137.137 0 0 0-.137-.136h-1.214a.137.137 0 0 0-.138.136v5.4c0 .412.082.731.24.947.17.228.42.345.745.345.256 0 .509-.073.774-.224.168-.095.33-.218.492-.373v.367c0 .074.06.136.137.136h1.215zm4.492-.337c.209-.265.315-.648.315-1.138l-.001-3.583c0-.555-.112-.989-.33-1.289-.228-.312-.562-.47-.99-.47-.21 0-.421.057-.627.17-.11.062-.216.138-.316.229v-2.478a.137.137 0 0 0-.137-.135h-1.225a.137.137 0 0 0-.138.135v8.76c0 .076.062.136.138.136h1.225c.075 0 .137-.06.137-.136v-.155c.101.089.209.161.32.216.208.104.441.156.697.156.4 0 .714-.139.932-.418zm4.663-2.74a.138.138 0 0 0 .138-.137v-1.649c0-.644-.163-1.15-.483-1.5-.324-.355-.788-.536-1.382-.536-.58 0-1.062.193-1.43.572-.367.377-.552.869-.552 1.464v2.901c0 .653.17 1.175.503 1.555.337.384.805.581 1.386.581.645 0 1.139-.185 1.465-.547.326-.36.492-.893.492-1.589v-.486a.135.135 0 0 0-.137-.136H23.1a.137.137 0 0 0-.138.136v.45c0 .472-.068.62-.093.659-.054.072-.16.109-.312.109-.191 0-.257-.074-.292-.133-.044-.07-.1-.239-.1-.634v-1.08h2.183z"
        fill="#F44336"
      />
    </SvgIcon>
  );
}

export default YoutubeIcon;
