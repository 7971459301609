import React, { Fragment, useCallback, useLayoutEffect } from 'react';
import { Box, Typography } from '@material-ui/core';
import { useDropzone } from 'react-dropzone';
import { UploadIcon, DocumentUploadIcon } from 'components/Icons';

import { useStyles } from './FileUpload.styles';

type Props = {
  onFileSelected: (
    name: string,
    type: string,
    image: string,
    file?: File,
    imageAttr?: { width: number; height: number },
    rawFiles?: File[],
  ) => void;
  onFileRemoved?: () => void;
  heading?: string;
  sizeText?: string;
  bodyText?: string;
  acceptedFiles?: string;
  icon?: string;
  headingTop?: string;
  height?: number;
  autoUpload?: boolean;
  multiple?: boolean;
};
const FileUpload: React.FC<Props> = ({
  onFileSelected,
  heading,
  sizeText,
  bodyText,
  acceptedFiles,
  icon,
  headingTop,
  height,
  autoUpload = false,
  multiple = false,
}) => {
  const classes = useStyles();

  const onDrop = useCallback(
    (acceptedFiles: File[]) => {
      acceptedFiles.forEach((file) => {
        const reader = new FileReader();
        if (file.type.startsWith('image')) {
          const image = new Image();
          reader.onload = () => {
            image.onload = function () {
              onFileSelected(file.name, file.type, reader.result as string, file, {
                width: image.width,
                height: image.height,
              });
            };

            image.src = reader.result as string;
          };
        } else {
          reader.onload = () => {
            onFileSelected(file.name, file.type, reader.result as string, file, { width: 0, height: 0 }, acceptedFiles);
          };
        }

        reader.readAsDataURL(file);
      });
    },
    [onFileSelected],
  );

  const { getRootProps, getInputProps, inputRef } = useDropzone({
    onDrop,
    accept: acceptedFiles?.split(','),
  });

  useLayoutEffect(() => {
    if (autoUpload) {
      if (inputRef.current) {
        inputRef.current.click();
      }
    }
  }, [autoUpload, inputRef]);

  return (
    <Box className={classes.container} style={{ height: height ? height : 'auto' }}>
      <Box {...getRootProps()} style={{ width: '100%', cursor: 'pointer' }}>
        <input {...getInputProps()} type="file" id="contained-input-file" multiple={multiple} />
        <Box className={classes.uploaderContainer}>
          {/* {!hasFile && ( */}
          <Fragment>
            <Box className={`${classes.uploaderHeader} ${headingTop ? classes.headingTop : ''}`}>
              {icon === 'image' ? <UploadIcon /> : <DocumentUploadIcon width="16" height="21" viewBox="0 0 16 21" />}
              {headingTop ? (
                <Typography variant="h6" style={{ marginLeft: 7 }}>
                  {headingTop}
                </Typography>
              ) : (
                ''
              )}
            </Box>
            {heading ? <Typography variant="h6">{heading}</Typography> : ''}
            {sizeText ? <Typography variant="body1">{sizeText}</Typography> : ''}
            {bodyText ? <Typography variant="body1">{bodyText}</Typography> : ''}
          </Fragment>
          {/* )} */}
          {/* {hasFile && (
            <Box className={classes.uploadProcessed}>
              <SuccessIcon />
              <div className={classes.fileContainer}>
                <Typography variant="body1">File(s) Processed Successfully</Typography>
                {fileLength === 1 && <Typography variant="body2">{fileName}</Typography>}
                {fileLength > 1 && <Typography variant="body2">{`${fileLength} files selected`}</Typography>}
              </div>
              <TrashErrorIconTwoTone onClick={(e) => handleRemoveFile(e)} />
            </Box>
          )} */}
        </Box>
      </Box>
    </Box>
  );
};

export default FileUpload;
