import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    talentDetailsContainer: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    talentDetail: {},
    talentDetail__name: {
      fontWeight: 500,
      fontSize: 25,
    },
    talentDetail__location: {
      fontSize: 16,
      color: '#6e767f',
      lineHeight: 1,
    },
    talentDetail__title: {
      fontSize: 16,
      color: '#6e767f',
    },
    talentDetail__content: {
      fontSize: 16,
      color: '#c3ccd7',
    },
    talentDetail__statistics: {
      marginTop: 4,
      display: 'flex',
      alignItems: 'center',
      gap: 8,
    },
    talentDetail__statistics__value: {
      display: 'flex',
      gap: 4,
    },
    talentDetail__statistics__separator: {
      fontSize: 18,
      color: '#6e767f',
    },
    talentLogo: {},
  }),
);
