import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    request__comment: {
      maxWidth: 250,
      [theme.breakpoints.down('lg')]: {
        maxWidth: 210,
      },
    },
    request__comment_flagContent: {
      display: 'flex',
      gap: 4,
      alignItems: 'center',
      [theme.breakpoints.down('lg')]: {
        '& p': {
          fontSize: '13px !important',
        },
      },
    },

    request__comment__item: {
      display: 'flex',
    },
    request__comment__dotButton: {
      display: 'flex',
      alignItems: 'center',
    },
    paperSmall: {
      backgroundImage: 'linear-gradient(180deg, #1d2834, #161e27)',
      border: '1px solid #222c39',
      borderRadius: '8px',
    },

    popoverContainer: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 15px',
      gap: 10,

      '& > button': {
        padding: 5,
      },
    },
    flagRed: {
      fontSize: 16,
      '& path': {
        fill: '#e53737',
      },
    },

    flagGreen: {
      fontSize: 16,
      '& path': {
        fill: '#43ba01',
      },
    },

    flagYellow: {
      fontSize: 16,
      '& path': {
        fill: '#e58f37',
      },
    },
  }),
);
