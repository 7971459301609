import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function CallBackIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 23 21" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M12.908.5C8.062.5 4.012 3.967 3.102 8.55l-1.9-2.377L0 7.135l3.077 3.846a.77.77 0 0 0 .945.207l4.615-2.307-.689-1.376L4.557 9.2c.63-4.05 4.128-7.162 8.351-7.162 4.666 0 8.462 3.797 8.462 8.462 0 4.665-3.796 8.462-8.462 8.462V20.5c5.514 0 10-4.486 10-10s-4.486-10-10-10z"
        fill={props.htmlColor || '#677482'}
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default CallBackIcon;
