import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    paper: {
      backgroundImage: 'linear-gradient(to bottom, #0D0F14, #0D0F14), linear-gradient(253deg, #d51bff, #00d4ff)',
    },
    dialogContent: {},
    titleContainer: {
      display: 'flex',
      justifyContent: 'center',
      marginBottom: theme.spacing(2),
    },
    timezoneContainer: {
      margin: '16px 0',
      display: 'flex',
      justifyContent: 'center',
    },
    timezoneWrapper: {
      width: 250,
    },
    datePicker: {
      backgroundColor: '#101318 !important',
      '&.mbsc-material.mbsc-calendar-controls': {
        backgroundColor: 'transparent !important',
      },
      '& .mbsc-scroller-wheel-group': {
        gap: 16,
      },
      '& .mbsc-material.mbsc-scroller-pointer .mbsc-scroller-wheel-group': {
        backgroundColor: '#0F1014',
      },
      '&.mbsc-material-dark.mbsc-datepicker-inline .mbsc-calendar-slide': {
        backgroundColor: '#101318 !important',
      },
      '& .mbsc-range-day:after': {
        backgroundColor: `${theme.palette.primary.main} !important`,
      },
      '& .mbsc-material-dark.mbsc-selected .mbsc-calendar-cell-text': {
        backgroundColor: `${theme.palette.primary.main} !important`,
        borderColor: `${theme.palette.primary.main} !important`,
        color: '#fff',
      },
      '& .mbsc-material.mbsc-icon-button .mbsc-icon': {
        color: `${theme.palette.primary.main}`,
      },
      '& .mbsc-scroller-bar-cont.mbsc-ltr': {
        backgroundColor: '#545659',
      },
      '& .mbsc-scroller-bar': {
        backgroundColor: theme.palette.primary.main,
        '&::after': {
          backgroundColor: theme.palette.primary.main,
        },
      },
    },
    buttonContainer: {
      marginTop: theme.spacing(2),
      display: 'flex',
      justifyContent: 'center',
    },
  }),
);
