import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const ReportIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="13" height="16" viewBox="0 0 13 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="cf6tmqexda">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M9.844 0c.259 0 .469.21.469.469v1.437h1.406c.259 0 .469.21.469.469v13.156c0 .26-.21.469-.47.469H2.345a.469.469 0 0 1-.469-.469v-1.406H.469A.469.469 0 0 1 0 13.656V.47C0 .209.21 0 .469 0zm1.406 2.844h-.938v10.812c0 .26-.21.469-.468.469H2.813v.938h8.437V2.842zM9.375.938H.937v12.25h8.438V.938zm-1.406 9.406a.469.469 0 0 1 0 .937H6.094a.469.469 0 0 1 0-.937zm0-1.875a.469.469 0 0 1 0 .937H2.344a.469.469 0 0 1 0-.937zm0-1.875a.469.469 0 0 1 0 .937H2.344a.469.469 0 0 1 0-.937zm0-1.875a.469.469 0 0 1 0 .937H2.344a.469.469 0 0 1 0-.937zM5.156 2.844a.469.469 0 0 1 0 .937H2.344a.469.469 0 0 1 0-.937z"
        transform="translate(.5)"
        fill="url(#cf6tmqexda)"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default ReportIconTwoTone;
