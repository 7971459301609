import { Dialog, Theme } from '@material-ui/core';
import { withStyles, makeStyles, createStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      '&.paper': {
        minWidth: '85%',
        minHeight: '90%',
      },
      '& .MuiDialog-paperWidthSm': {
        maxWidth: '585px !important',
      },
      '& .MuiFormControl-root': {
        margin: '0px !important',
      },
    },
    closeIcon: {
      position: 'absolute',
      top: '20px',
      right: '20px',
      cursor: 'pointer',
    },
    tagsBody: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: '20px 20px 30px 20px',
      '& > *:not(:last-child)': {
        marginBottom: '30px !important',
      },
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'nowrap',
    },
    headerDp: {
      background: '#808080',
      height: '60px',
      width: '45px',
      borderRadius: '5px',
      marginRight: '20px',
    },
    headerText: {
      '& > *:last-child': {
        marginTop: '3px',
        color: '#6e767f',
      },
    },
    btn: {
      padding: '4px 8px 4px 10px',
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      justifyContent: 'space-between',
      '& svg': {
        width: '16px',
      },
    },
    primaryBtn: {
      background: '#3e8bff',
      '& svg path': {
        fill: '#FFFFFF',
      },
    },
    defaultBtn: {
      background: '#2c3745',
    },
    tagHeader: {
      marginBottom: '10px',
    },
  }),
);
