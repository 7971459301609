import { Theme, createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    container: {
      display: 'flex',
      marginTop: '24px',
      gap: '15px',
      flexWrap: 'wrap',

      '& .MuiFormControl-root': {
        margin: '0',
      },

      '& .MuiSelect-iconOutlined': {
        right: '0',
        top: '22px',
      },

      '& .MuiSelect-iconOpen': {
        top: '2px',
        right: '13px',
      },

      '& .MuiButton-root': {
        fontSize: '14px',
        background: '#0e1319',
        color: '#fff',
        width: '100%',
        padding: '15px',
        height: '50px',
        border: '1px solid #2d343e',

        '&:hover': {
          background: '#151a21',
          borderColor: '#3e8bff',

          '& svg path': {
            transition: 'background-color 250ms',
          },
        },
      },

      '& .MuiOutlinedInput-root': {
        height: '50px!important',
      },
    },

    legend: {
      background: '#0e1319',
      display: 'flex',
      alignItems: 'center',
      padding: '10px 20px',
      borderRadius: '5px',
      position: 'relative',
      gap: 20,
      height: '50px',
      border: '1px solid #2d343e',

      [theme.breakpoints.up(1435)]: {
        marginLeft: 'auto',
      },

      '& > div': {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
      },

      '& > div::before': {
        height: '10px',
        width: '10px',
        borderRadius: '100%',
        content: '""',
        display: 'block',
      },
    },

    greenIndicator: {
      '&::before': {
        background: '#6edc11',
      },
    },

    redIndicator: {
      '&::before': {
        background: '#ff4747',
      },
    },
    sel_container: {
      '& .MuiFormControl-root': {
        marginTop: '0px !important',
      },
    },
  }),
);
