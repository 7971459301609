import { Box, DialogContent, IconButton } from '@material-ui/core';
import { CustomVideoPlayer } from '@room-match/shared-ui-components';
import { CloseIcon, PlayIcon } from 'components/Icons';
import React, { useMemo, useState, useEffect } from 'react';
import { StyledDialog, Typography } from 'shared/elements';
import { useStyles } from './VideoGallery.styles';
import { IProfileMediumAttachment } from 'shared/interfaces/IProfile';
import Video from './Video';
import { useGetThumbnail } from 'shared/hooks/useGetThumbnail';

type Props = {
  open: boolean;
  data: IProfileMediumAttachment[];
  view?: string;
  onClose: () => void;
};

const VideoGallery: React.FC<Props> = ({ open, data, view = 'group', onClose }) => {
  const classes = useStyles();
  const [selectedVideoIndex, setSelectedVideoIndex] = useState<number>(0);
  const [selectedVideoTime, setSelectedVideoTime] = useState<string>('00:00');

  const { thumbnail, handleChangeVideoUrl } = useGetThumbnail();

  const handleSetSelectedVideoTime = (time: string) => {
    setSelectedVideoTime(time);
  };

  const handleSetSelectedVideoUrl = (url: string) => {
    handleChangeVideoUrl(url);
  };

  const videos = useMemo(() => {
    return data.length > 0
      ? data.map((item) => ({
          ...item.attributes,
          file_name: item.attributes.file_name
            ? item.attributes.file_name
            : item.attributes.medium.data.attributes.file_name,
        }))
      : [];
  }, [data]);

  const selectedVideo = useMemo(() => {
    return selectedVideoIndex ? videos[selectedVideoIndex] : videos[0];
  }, [selectedVideoIndex, videos]);

  // const videoURL = useMemo(() => {
  //   return selectedVideoUrl
  //     ? selectedVideoUrl
  //     : (selectedVideo || {}).attachment || (selectedVideo || {}).modified_attachment || '';
  // }, [selectedVideo, selectedVideoUrl]);

  useEffect(() => {
    if (selectedVideo) {
      handleChangeVideoUrl(selectedVideo.modified_attachment || selectedVideo.attachment);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedVideo]);

  return (
    <StyledDialog open={open} maxWidth="lg" fullWidth style={{ maxHeight: '95%', top: 20 }}>
      <DialogContent>
        {/* Header */}
        <Box className={classes.header}>
          {/* Title */}
          <Box>
            {selectedVideo && <Typography fontSize={13}>{selectedVideo.medium.data.attributes.file_name}</Typography>}
            <Typography color="mainGrey" fontSize={12}>
              {selectedVideoTime}
            </Typography>
          </Box>
          {/* Artist */}
          <Box className={classes.header__artist}>
            {/* <Typography fontSize={13}>Leonardo DiCaprio</Typography> */}
            <Typography color="mainGrey" fontSize={12}>
              {`Video ${selectedVideoIndex + 1} of ${videos.length}`}
            </Typography>
          </Box>
          <IconButton onClick={onClose}>
            <CloseIcon style={{ fontSize: 12 }} />
          </IconButton>
        </Box>

        {/* Video */}
        <Box className={classes.videoContainer}>
          <CustomVideoPlayer
            url={selectedVideo?.modified_attachment || selectedVideo?.attachment}
            thumbnail={thumbnail}
            playIcon={<PlayIcon style={{ fontSize: 48 }} />}
          />
        </Box>

        {/* Video List */}
        {view === 'group' && (
          <Box className={classes.videoList}>
            {videos.map((video, i) => (
              <div
                key={i}
                onClick={() => {
                  setSelectedVideoIndex(i);
                  handleSetSelectedVideoUrl(video.modified_attachment || video.attachment);
                }}
              >
                <Video
                  video={video}
                  index={i}
                  selectedIndex={selectedVideoIndex}
                  videoURL={video.modified_attachment || video.attachment}
                  handleSetSelectedVideoTime={handleSetSelectedVideoTime}
                />
              </div>
            ))}
          </Box>
        )}
      </DialogContent>
    </StyledDialog>
  );
};

export default VideoGallery;
