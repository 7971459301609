import { FormControl, FormHelperText, InputLabel, Select, MenuItem, SelectProps, Input, Chip } from '@material-ui/core';
import clsx from 'clsx';
import React, { Fragment } from 'react';
import { makeStyles } from '@material-ui/core/styles';

interface IKeyValue {
  key: string;
  value: string | number;
}

type Props = {
  fullWidth?: boolean;
  label?: string;
  placeHolder?: string;
  errorMessage?: string;
  data: Array<{
    key: string;
    value: string | number;
  }>;
  hasMargin?: boolean;
  rootFormStyle?: React.CSSProperties;
} & SelectProps;

const useFormStyles = makeStyles((theme) => ({
  selectContainer: {
    '& .MuiInputBase-formControl, & .MuiInput-root, & .MuiInput-formControl': {
      border: 'solid 1px #2d343e !important',
      borderRadius: '4px !important',
      minHeight: '50px !important',
      height: 'unset !important',
    },
  },
  chip: {
    margin: 2,
    background: '#3e8bff',
  },
}));

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 0;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
    },
  },
};

const SelectChip: React.FC<Props> = ({
  fullWidth,
  label,
  errorMessage,
  data = [],
  hasMargin = true,
  placeHolder = 'Select One',
  rootFormStyle,
  ...props
}) => {
  const formClasses = useFormStyles();

  const handleValueKey = (value: string) => {
    const filtered = data.filter((item: any) => item.value === value);
    return filtered.length > 0 ? filtered[0].key : '';
  };

  return (
    <Fragment>
      <FormControl fullWidth margin={'normal'} className={formClasses.selectContainer}>
        <Select
          disableUnderline
          displayEmpty
          multiple
          input={<Input />}
          renderValue={(selected: any) => (
            <div style={{ display: 'flex', flexWrap: 'wrap' }}>
              {selected.map((value: any) => (
                <Chip key={value} label={handleValueKey(value)} className={formClasses.chip} />
              ))}
              {selected.length === 0 && <span style={{ paddingLeft: '15px' }}>{placeHolder}</span>}
            </div>
          )}
          MenuProps={MenuProps}
          {...props}
        >
          {data.map((item: any) => (
            <MenuItem key={item.value} value={item.value}>
              {item.key}
            </MenuItem>
          ))}
        </Select>
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </Fragment>
  );
};

export default SelectChip;

SelectChip.defaultProps = {
  fullWidth: false,
};
