import { FormikProps, useFormik } from 'formik';
import { AuditionType } from 'shared/enums/AuditionType';
import { IRole, IRoleCreatePayload } from 'shared/interfaces/IRole';
import { array, boolean, date, mixed, number, object, SchemaOf, string } from 'yup';
import GENDERS from 'data/Gender.json';
import { isEqual } from 'lodash';

type RoleCreateFormProps = {
  onSubmit: (values: IRoleCreatePayload) => void;
  roles: IRole[];
  role: IRole | null | undefined;
};
export const useAddNewRoleForm = ({ onSubmit, roles, role }: RoleCreateFormProps) => {
  const validationSchema: SchemaOf<IRoleCreatePayload> = object().shape({
    name: string()
      .required('Name is required')
      .test('isUnique', 'Name must be unique per type of audition', (value, context) => {
        if (role) {
          const filteredRoles = roles.filter((roleValue) => roleValue.attributes.name !== role.attributes.name);
          return !filteredRoles.some(
            (role) => role.attributes.name === value && role.attributes.audition_type === context.parent.audition_type,
          );
        } else {
          return !roles.some(
            (role) => role.attributes.name === value && role.attributes.audition_type === context.parent.audition_type,
          );
        }
      }),
    role_type: string(),
    rate_summary: string(),
    requesting_submissions_from: string(),
    internal_name: string(),
    audition_type: string(),
    principal_role_type: string(),
    background_role_type: string(),
    spot_name: string(),
    submission_date_timezone: string(),
    role_is_for: array(),
    genders: array().min(1, 'Genders must have at least 1 item').required('Required'),
    ethnic_appearance: array(),
    age_range_from: number().default(0),
    age_range_to: number().default(100),
    age_range_type: mixed().oneOf(['Year', 'Month']).defined(),
    description: string(),
    rate_information: string(),
    agents_clients_description: string(),
    talent_description: string(),
    submission_notes: string(),
    use_internal_name: boolean().default(false),
    paying_role: boolean().default(false),
    submission_date_due: date(),
    recommended_skills: array(),
    nudity_sexual_situations: array(),
    allowed_sides_viewers: array(),
    publish_to: array(),
    audition: object({
      location: string(),
      show_to: array(),
      dates: array(),
      note: string(),
    }),
    callback: object({
      location: string(),
      show_to: array(),
      dates: array(),
      note: string(),
    }),
    fitting: object({
      location: string(),
      show_to: array(),
      dates: array(),
      note: string(),
    }),
    working: object({
      location: string(),
      show_to: array(),
      dates: array(),
      note: string(),
    }),
  });

  const initialValues: IRoleCreatePayload = {
    name: role && role.attributes.name ? role.attributes.name : '',
    role_type: role && role.attributes.role_type ? role.attributes.role_type || '' : '',
    rate_summary: role && role.attributes.rate_summary ? role.attributes.rate_summary || '' : '',
    requesting_submissions_from:
      role && role.attributes.requesting_submissions_from ? role.attributes.requesting_submissions_from || '' : '',
    internal_name: role && role.attributes.internal_name ? role.attributes.internal_name || '' : '',
    audition_type: role && role.attributes.audition_type ? role.attributes.audition_type : AuditionType.StudioSession,
    principal_role_type:
      role && role.attributes.principal_role_type ? role.attributes.principal_role_type || undefined : undefined,
    background_role_type:
      role && role.attributes.background_role_type ? role.attributes.background_role_type || undefined : undefined,
    spot_name: role && role.attributes.spot_name ? role.attributes.spot_name : '',
    submission_date_timezone:
      role && role.attributes.submission_date_timezone ? role.attributes.submission_date_timezone : '',
    role_is_for: role && role.attributes.role_is_for ? role.attributes.role_is_for : [],
    genders: role && role.attributes.genders ? role.attributes.genders : GENDERS.map((gender) => gender.value),
    ethnic_appearance: role && role.attributes.ethnic_appearance ? role.attributes.ethnic_appearance : [],
    age_range_from: role && role.attributes.age_range_from ? role.attributes.age_range_from : 1,
    age_range_to: role && role.attributes.age_range_to ? role.attributes.age_range_to : 100,
    age_range_type: role && role.attributes.age_range_type ? role.attributes.age_range_type : 'Year',
    nudity_sexual_situations:
      role && role.attributes.nudity_sexual_situations ? role.attributes.nudity_sexual_situations : [],
    allowed_sides_viewers: role && role.attributes.allowed_sides_viewers ? role.attributes.allowed_sides_viewers : [],
    recommended_skills: role && role.attributes.recommended_skills ? role.attributes.recommended_skills : [],
    description: role && role.attributes.description ? role.attributes.description : '',
    rate_information: role && role.attributes.rate_information ? role.attributes.rate_information : '',
    agents_clients_description:
      role && role.attributes.agents_clients_description ? role.attributes.agents_clients_description : '',
    talent_description: role && role.attributes.talent_description ? role.attributes.talent_description : '',
    submission_notes: role && role.attributes.submission_notes ? role.attributes.submission_notes : '',
    use_internal_name: role && role.attributes.use_internal_name ? role.attributes.use_internal_name : false,
    paying_role: role && role.attributes.paying_role ? role.attributes.paying_role : false,
    submission_date_due: role && role.attributes.submission_date_due ? role.attributes.submission_date_due : undefined,
    audition:
      role && role.attributes.audition
        ? {
            location: role.attributes.audition.location,
            show_to: role.attributes.audition.show_to,
            dates: role.attributes.audition.dates,
            note: role.attributes.audition.note,
          }
        : {
            location: '',
            show_to: [],
            dates: [],
            note: '',
          },
    callback:
      role && role.attributes.callback
        ? {
            location: role.attributes.callback.location,
            show_to: role.attributes.callback.show_to,
            dates: role.attributes.callback.dates,
            note: role.attributes.callback.note,
          }
        : {
            location: '',
            show_to: [],
            dates: [],
            note: '',
          },
    fitting:
      role && role.attributes.fitting
        ? {
            location: role.attributes.fitting.location,
            show_to: role.attributes.fitting.show_to,
            dates: role.attributes.fitting.dates,
            note: role.attributes.fitting.note,
          }
        : {
            location: '',
            show_to: [],
            dates: [],
            note: '',
          },
    working:
      role && role.attributes.working
        ? {
            location: role.attributes.working.location,
            show_to: role.attributes.working.show_to,
            dates: role.attributes.working.dates,
            note: role.attributes.working.note,
          }
        : {
            location: '',
            show_to: [],
            dates: [],
            note: '',
          },
  };
  const form: FormikProps<IRoleCreatePayload> = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,

    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  const isFormDirty = !isEqual(initialValues, form.values);

  return {
    form,
    isFormDirty,
  };
};
