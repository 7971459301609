import {
  Box,
  DialogContent,
  Grid,
  Button,
  TextField,
  InputLabel,
  Chip,
  Typography as Typography2,
} from '@material-ui/core';
import React, { Fragment, useState, useEffect, useMemo } from 'react';
import { FormikProps, useFormik } from 'formik';
import * as yup from 'yup';
import { useQueryClient } from 'react-query';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { ProjectPermission } from 'components/ProjectPermission';
import { ContactInput } from 'shared/elements/ContactInput';
import { StyledDialog } from 'shared/elements/StyledDialog';
import { getErrorMessage } from 'shared/utils/getErrorMessage';
import { useStyles } from './AddNewSession.styles';
import {
  Backdrop,
  Input,
  LoadingSpinnerIconTwoTone,
  CloseIcon as EmailCloseIcon,
  CheckboxCheckIconTwoTone,
  useNotificationModal,
} from '@room-match/shared-ui-components';
import { Typography, TextInput } from 'shared/elements';
import { ICreateStaffPayload, ISessionDirectorDropDown } from 'shared/interfaces/IStaff';
import { CloseIcon, EditIcon } from 'components/Icons';
import { staffService } from 'shared/services/staffService';
import { StaffType } from 'shared/enums/StaffType';
import { useEmailValidation } from 'shared/hooks/useEmailValidation';
import { COLORS } from 'shared/constants/COLORS';
import { errorResponseToArray } from '@room-match/shared-utils';

type Props = {
  open: boolean;
  currentDirectors?: any[];
  onClose: () => void;
  onSelect: (instance: any[]) => void;
};
const { createStaff, getStaff } = staffService();
const AddNewSession: React.FC<Props> = ({ open, currentDirectors, onClose, onSelect }) => {
  const queryClient = useQueryClient();
  const {
    handleOpen: handleOpenModal,
    NotificationModal,
    handleSetMessage: handleSetMessageModal,
    isOpen: isOpenModal,
  } = useNotificationModal({
    type: 'error',
  });
  const { isEmailValidationLoading, isValidEmail, handleEmailChange, emailValue } = useEmailValidation();
  const { mutateAsync: mutateAsyncCreateStaff, isLoading } = createStaff();
  const { data: staffData } = getStaff(StaffType.SessionDirector);
  const defaultValues = {
    first_name: '',
    last_name: '',
    email: '',
    position_name: 'Session Director',
    password: '',
    contact_country_code: 'us',
    contact_number: '',
    permissions: [],
    user: {},
    archived_at: false,
    encrypted_password: '',
  };
  const classes = useStyles();
  const [selected, setSelected] = useState<ISessionDirectorDropDown[]>([]);
  const [data, setData] = useState<ISessionDirectorDropDown[]>([]);
  const [newPermissions, setNewPermissions] = useState<any>([]);
  const [initialValues, setInitialValues] = useState<ICreateStaffPayload>(defaultValues);
  const [permissionModalOpen, setPermissionModalOpen] = useState<boolean>(false);
  const [createNew, setCreateNew] = useState(true);

  const addSessionDirectorValidationSchema: yup.SchemaOf<ICreateStaffPayload> = yup.object().shape(
    {
      first_name: yup.string().when(['last_name', 'email', 'password'], {
        is: (last_name: string, email: string, password: string) => last_name || email || password,
        then: yup.string().required('First name is required'),
        otherwise: yup.string().notRequired(),
      }),
      last_name: yup.string().when(['first_name', 'email', 'password'], {
        is: (first_name: string, email: string, password: string) => first_name || email || password,
        then: yup.string().required('Last name is required'),
        otherwise: yup.string().notRequired(),
      }),
      email: yup.string().when(['first_name', 'last_name', 'password'], {
        is: (first_name: string, last_name: string, password: string) => first_name || last_name || password,
        then: yup.string().email('Wrong email format').required('Email is required'),
        otherwise: yup.string().notRequired(),
      }),
      position_name: yup.string().required('Position is required'),
      password: yup.string().when(['first_name', 'last_name', 'email'], {
        is: (first_name: string, last_name: string, email: string) => first_name || last_name || email,
        then: yup.string().required('Password is required'),
        otherwise: yup.string().notRequired(),
      }),
      contact_country_code: yup.string().notRequired(),
      contact_number: yup.string().notRequired(),
      permissions: yup.array().notRequired(),
      archived_at: yup.boolean().notRequired(),
      user: yup.object({}).notRequired(),
      encrypted_password: yup.string().notRequired(),
    },
    [
      ['first_name', 'last_name'],
      ['first_name', 'email'],
      ['first_name', 'password'],
      ['last_name', 'email'],
      ['last_name', 'password'],
      ['email', 'password'],
    ],
  );

  const handleMutateAsyncCreateStaff = async (values: ICreateStaffPayload, form: any) => {
    const { data: staffData } = await mutateAsyncCreateStaff(
      { payload: { ...values, permissions: newPermissions } },
      {
        onError(errors) {
          if (errors?.response?.data?.errors) {
            const errorResponseArray = errorResponseToArray(errors.response.data.errors);
            handleSetMessageModal('Warning', errorResponseArray, 'Close');
            handleOpenModal();
          }
        },
      },
    );
    form.resetForm();
    queryClient.invalidateQueries('staff');

    onSelect([
      ...selected,
      {
        id: staffData.attributes.id,
        key: `${staffData.attributes.user.data.attributes.full_name}`,
        value: staffData.attributes.id,
        name: `${staffData.attributes.user.data.attributes.full_name}`,
        permissions: staffData.attributes.permissions,
        position: 'Session Director',
      },
    ]);
    setSelected([]);
  };

  const handleAttachSessionDirectors = (staff: any[]) => {
    onSelect(staff);
    setSelected([]);
  };

  const form: FormikProps<ICreateStaffPayload> = useFormik({
    initialValues,
    validationSchema: addSessionDirectorValidationSchema,
    enableReinitialize: true,
    onSubmit: async (values, helpers) => {
      if (!isValidEmail) {
        helpers.setFieldError('email', 'Invalid Email');
      } else {
        if (!values.first_name) {
          handleAttachSessionDirectors(selected);
        } else {
          handleMutateAsyncCreateStaff(values, form);
        }
      }
    },
  });

  const handleSetSelected = (staff: ISessionDirectorDropDown[]) => {
    if (staff.length === 0) {
      setSelected([]);
      return;
    }
    setSelected(staff);
  };

  const cDirectors = useMemo(() => {
    return currentDirectors ? currentDirectors : [];
    // ? currentDirectors.map((staff) => ({
    //     id: staff.attributes.staff.data.id,
    //     name: staff.attributes.staff.data.attributes.user.data.attributes.full_name,
    //     key: staff.attributes.staff.data.attributes.user.data.attributes.full_name,
    //     value: staff.attributes.staff.data.id,
    //     permissions: staff.attributes.staff.data.attributes.permissions || [],
    //     position: staff.attributes.staff.data.attributes.position_name || 'Session Director',
    //   }))
    // : [];
  }, [currentDirectors]);

  useEffect(() => {
    if (staffData) {
      const formatttedData = staffData.data.map((item) => ({
        id: item.attributes.id,
        name: item.attributes.user.data.attributes.full_name,
        key: `${item.attributes.user.data.attributes.full_name}`,
        value: item.id,
        permissions: item.attributes.permissions || [],
        position: item.attributes.position_name || 'Session Director',
      }));
      setData(formatttedData);
    }
    if (cDirectors.length > 0) {
      setSelected(cDirectors);
    }
  }, [staffData, cDirectors]);

  return (
    <StyledDialog
      open={open}
      onClose={() => {
        setSelected([]);
        onClose();
      }}
      maxWidth="sm"
      className={classes.profileDialog}
      aria-labelledby="simple-dialog-title"
    >
      <DialogContent>
        <Box className={classes.closeIcon}>
          <Box
            onClick={() => {
              setSelected([]);
              onClose();
            }}
          >
            <CloseIcon style={{ width: '14px' }} />
          </Box>
        </Box>
        <Grid container spacing={2} className={classes.container}>
          <Grid item className={classes.header}>
            <Typography fontSize={24}>Assign Session Director</Typography>
            <Typography fontSize={14}>You can select a Session Director from the list or create a new one</Typography>
          </Grid>
          <Grid item className={classes.form}>
            <Grid item>
              <Autocomplete
                multiple
                options={data}
                getOptionLabel={(option: ISessionDirectorDropDown) => option.key}
                getOptionSelected={(options, values) => options.value === values.value}
                fullWidth
                disableCloseOnSelect={true}
                onChange={(e, arr) => {
                  const newArr = arr ? arr : [];
                  handleSetSelected(newArr);
                }}
                value={selected}
                filterSelectedOptions
                renderInput={(params) => (
                  <TextField
                    {...params}
                    variant="outlined"
                    name="id"
                    placeholder="Select Session Director"
                    fullWidth
                    margin={'normal'}
                  />
                )}
              />
              {/* {selected.map((staff: any) => (
                <Chip
                  key={staff.id}
                  label={staff.name}
                  onDelete={() => handleDeleteSelected(staff.id)}
                  style={{ margin: '5px' }}
                />
              ))} */}
            </Grid>
            {!createNew && (
              <Grid item className={classes.textGrid}>
                <Typography2
                  variant="body2"
                  color="primary"
                  className={classes.anchorState}
                  onClick={() => setCreateNew(true)}
                >
                  Create a new Session Director
                </Typography2>
              </Grid>
            )}
            {createNew && (
              <Fragment>
                <Grid item className={classes.textGrid}>
                  <Typography fontSize={14} fontWeight={500} color="mainGrey">
                    or Create a new Session Director
                  </Typography>
                  {createNew && (
                    <Typography2
                      variant="body2"
                      color="primary"
                      className={classes.anchorState}
                      onClick={() => setCreateNew(false)}
                    >
                      Hide
                    </Typography2>
                  )}
                </Grid>
                <Grid item className={classes.twoColumn} style={{ marginTop: '10px' }}>
                  <TextInput
                    label="First Name"
                    labelId="firstName"
                    name="first_name"
                    placeholder="Type here..."
                    onBlur={form.handleBlur}
                    onChange={(e) => {
                      if (form.errors.first_name && !form.touched.first_name) {
                        form.setFieldTouched('first_name');
                        form.validateField('first_name');
                      }
                      return form.handleChange(e);
                    }}
                    errorMessage={getErrorMessage(form.touched.first_name, form.errors.first_name)}
                    value={form.values.first_name}
                  />
                  <TextInput
                    label="Last Name"
                    labelId="firstName"
                    name="last_name"
                    placeholder="Type here..."
                    onBlur={form.handleBlur}
                    onChange={(e) => {
                      if (form.errors.last_name && !form.touched.last_name) {
                        form.setFieldTouched('last_name');
                        form.validateField('last_name');
                      }
                      return form.handleChange(e);
                    }}
                    errorMessage={getErrorMessage(form.touched.last_name, form.errors.last_name)}
                    value={form.values.last_name}
                  />
                </Grid>
                <Grid item>
                  {/* <TextInput
                    label="Email Address (Username)"
                    labelId="email"
                    name="email"
                    placeholder="Type here..."
                    onBlur={form.handleBlur}
                    onChange={(e) => {
                      if (form.errors.email && !form.touched.email) {
                        form.setFieldTouched('email');
                        form.validateField('email');
                      }
                      return form.handleChange(e);
                    }}
                    errorMessage={getErrorMessage(form.touched.email, form.errors.email)}
                    value={form.values.email}
                  /> */}
                  <InputLabel
                    id="emailAddress"
                    shrink
                    style={{ marginBottom: '10px', fontSize: '16px', marginTop: '-5px' }}
                  >
                    Email Address (Username)
                  </InputLabel>
                  <Input
                    name="email"
                    placeholder="Type here..."
                    type="email"
                    variant="outlined"
                    fullWidth
                    InputProps={{
                      tabIndex: 1,
                      endAdornment: (
                        <>
                          {emailValue && (
                            <>
                              {isEmailValidationLoading ? (
                                <LoadingSpinnerIconTwoTone style={{ fontSize: 40 }} />
                              ) : (
                                <>
                                  {isValidEmail ? (
                                    <CheckboxCheckIconTwoTone style={{ fontSize: 12 }} />
                                  ) : (
                                    <EmailCloseIcon style={{ fontSize: 12 }} htmlColor={COLORS.DANGER} />
                                  )}
                                </>
                              )}
                            </>
                          )}
                        </>
                      ),
                    }}
                    value={form.values.email}
                    onChange={(e) => {
                      return form.handleChange(e);
                    }}
                    onBlur={(e) => {
                      handleEmailChange(e.target.value);
                      return form.handleBlur(e);
                    }}
                    errorMessage={getErrorMessage(
                      form.touched.email,
                      form.errors.email
                        ? form.errors.email
                        : !isValidEmail && !isEmailValidationLoading
                        ? 'Invalid Email'
                        : '',
                    )}
                  />
                </Grid>
                <Grid item>
                  <ContactInput
                    name="contact_number"
                    variant="outlined"
                    dropdownStyle={{ height: '50px', marginBottom: '0px' }}
                    showLabel={true}
                    placeholder="Type here..."
                    handleCodeChange={(val: any) => {
                      form.setFieldValue('contact_country_code', val.countryCode);
                    }}
                    country={(form.values.contact_country_code || 'us').toLowerCase()}
                    onBlur={form.handleBlur}
                    onChange={(e) => {
                      if (form.errors.contact_number && !form.touched.contact_number) {
                        form.setFieldTouched('contact_number');
                        form.validateField('contact_number');
                      }
                      return form.handleChange(e);
                    }}
                    errorMessage={getErrorMessage(form.touched.contact_number, form.errors.contact_number)}
                    value={form.values.contact_number}
                  />
                </Grid>
                <Grid item>
                  <TextInput
                    type="password"
                    label="Password"
                    labelId="password"
                    name="password"
                    placeholder="Type here..."
                    onBlur={form.handleBlur}
                    onChange={(e) => {
                      if (form.errors.password && !form.touched.password) {
                        form.setFieldTouched('password');
                        form.validateField('password');
                      }
                      return form.handleChange(e);
                    }}
                    errorMessage={getErrorMessage(form.touched.password, form.errors.password)}
                    value={form.values.password}
                  />
                </Grid>
                <Grid item>
                  <Button
                    variant="contained"
                    className={classes.editButton}
                    startIcon={<EditIcon style={{ height: '17px' }} />}
                    onClick={() => setPermissionModalOpen(true)}
                  >
                    Edit Staff Permissions
                  </Button>
                </Grid>
              </Fragment>
            )}
            <Grid item>
              <Button
                variant="contained"
                className={classes.saveButton}
                onClick={() => {
                  if (!createNew) {
                    handleAttachSessionDirectors(selected);
                  } else {
                    form.handleSubmit();
                  }
                }}
              >
                Save & Finish
              </Button>
            </Grid>
          </Grid>
        </Grid>
        <ProjectPermission
          open={permissionModalOpen}
          id={''}
          currentPermissions={[]}
          name={`${form.values.first_name} ${form.values.last_name}`}
          position={'Session Director'}
          onClose={(data: any) => {
            setPermissionModalOpen(false);
            setNewPermissions(data);
          }}
        />
        {isOpenModal && <NotificationModal />}
        <Backdrop isLoading={isLoading} />
      </DialogContent>
    </StyledDialog>
  );
};

export default AddNewSession;
