import { Theme, createStyles, makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      display: 'flex',
    },
    content: {
      flexGrow: 1,
      padding: theme.spacing(3, 4),
      backgroundColor: theme.palette.type === 'dark' ? '#151a21' : '#edf1f6',
      minHeight: '100vh',
      overflow: 'hidden',
      [theme.breakpoints.down('lg')]: {
        padding: theme.spacing(2, 2.5),
      },
      [theme.breakpoints.down('sm')]: {
        padding: theme.spacing(2),
      },
      '& .MuiInputBase-formControl': {
        height: '50px !important',
        fontSize: '14px !important',
        overflow: 'hidden',
      },
      '& .MuiFormControl-root': {
        marginTop: '10px',
        marginBottom: '10px',
      },
      '& .MuiInputBase-root': {
        background: theme.palette.type === 'dark' ? '#0e1319' : '#FFFFFF',
      },
      '& fieldset': {
        borderColor: theme.palette.type === 'dark' ? '#2d343e' : '#d5dbe3',
      },
      '& .MuiButton-label': {
        whiteSpace: 'nowrap !important',
      },
    },
    toolbar: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'flex-end',
      padding: theme.spacing(2, 0),

      // necessary for content to be below app bar
      ...theme.mixins.toolbar,
    },
  }),
);
