import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const ImportIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 40 50" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="h7jycwktua">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M26.075 0c.326 0 .64.145.87.386L39.262 13.31c.205.23.35.531.35.845v29.288c0 3.599-2.97 6.558-6.57 6.558H6.57C2.97 50 0 47.04 0 43.442V6.558C0 2.959 2.97 0 6.57 0zm-1.196 2.415H6.57c-2.27 0-4.155 1.885-4.155 4.143v36.884c0 2.27 1.897 4.143 4.155 4.143h26.473c2.27 0 4.155-1.872 4.155-4.143V15.338H30.18a5.283 5.283 0 0 1-5.302-5.29V2.415zm4.867 36.836c.677 0 1.22.544 1.22 1.208 0 .664-.543 1.208-1.208 1.208H9.868a1.211 1.211 0 0 1-1.209-1.208c0-.664.544-1.208 1.208-1.208zm-9.951-22.62c.338 0 .652.145.881.386l7.078 7.597a1.204 1.204 0 0 1-.06 1.702c-.23.218-.532.327-.822.327-.326 0-.64-.134-.882-.387l-4.976-5.35v13.188c0 .664-.543 1.208-1.207 1.208a1.211 1.211 0 0 1-1.208-1.208V20.906l-4.976 5.35c-.459.483-1.22.52-1.727.06a1.204 1.204 0 0 1-.06-1.702l7.077-7.597c.23-.241.543-.386.882-.386zm7.5-12.368v5.785a2.876 2.876 0 0 0 2.886 2.875h5.362l-8.248-8.66z"
        fill="url(#h7jycwktua)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default ImportIcon;
