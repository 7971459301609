import React, { Fragment } from 'react';
import {
  FormControl,
  FormHelperText,
  InputLabel,
  Select as MUISelect,
  MenuItem,
  SelectProps,
  Box,
} from '@material-ui/core';
import {
  FacebookIconTwoTone,
  TwitterIconTwoTone,
  YoutubeIconTwoTone,
  TikTokIconTwoTone,
  LinkedInIconTwoTone,
  SoundCloudIconTwoTone,
  InstagramIconTwoTone,
} from 'components';
import { useStyles } from './SocialMedia.styles';

interface IKeyValue {
  key: string;
  value: string;
}

type Props = {
  fullWidth?: boolean;
  label?: string;
  errorMessage?: string;
  data: IKeyValue[];
  hasMargin?: boolean;
} & SelectProps;
const Select: React.FC<Props> = ({ fullWidth, label, errorMessage, data, hasMargin = true, ...props }) => {
  const classes = useStyles();
  const renderIcon = (acc: any) => {
    const l_acc = acc.toLowerCase();
    switch (l_acc) {
      case 'facebook':
        return <FacebookIconTwoTone />;
      case 'twitter':
        return <TwitterIconTwoTone />;
      case 'youtube':
        return <YoutubeIconTwoTone />;
      case 'instagram':
        return <InstagramIconTwoTone />;
      case 'tiktok':
        return <TikTokIconTwoTone />;
      case 'linkedin':
        return <LinkedInIconTwoTone />;
      case 'soundcloud':
        return <SoundCloudIconTwoTone />;
      default:
        return '';
    }
  };
  return (
    <Fragment>
      <FormControl margin={hasMargin ? 'normal' : undefined} fullWidth={fullWidth} error={!!errorMessage}>
        {!!label && <InputLabel shrink>{label}</InputLabel>}

        <MUISelect
          disableUnderline
          {...props}
          error={!!errorMessage}
          renderValue={(value) => {
            return (
              <Box className={classes.boxSelectFlex}>
                {renderIcon(value)}
                {value}
              </Box>
            );
          }}
        >
          {data.map((item) => (
            <MenuItem value={item.value} key={item.key}>
              {item.key}
            </MenuItem>
          ))}
        </MUISelect>
        {errorMessage && <FormHelperText>{errorMessage}</FormHelperText>}
      </FormControl>
    </Fragment>
  );
};

export default Select;

Select.defaultProps = {
  fullWidth: false,
};
