import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import {
  IAuditionMutateResponsePayload,
  IAuditionPriorityResponsePayload,
  IAuditionPriorityUpdatePayload,
  IAuditionRequestPayload,
  IAuditionResponsePayload,
  IAuditionUpdatePayload,
  IWorksheetAuditionRequestPayload,
  IWorksheetAuditionResponsePayload,
  IAuditionActivitiesResponsePayload,
} from 'shared/interfaces/IAudition';
import { GET_AUDITIONS } from 'shared/queries/audition.queries';
import { GET_WORKSHEET_AUDITIONS } from 'shared/queries/worksheet.queries';

const { GET, POST, PATCH } = useAxios();
export const auditionDao = () => {
  const getAuditions = async (payload: IAuditionRequestPayload) => {
    const response = await POST<IAuditionResponsePayload>({
      url: ENDPOINTS.GRAPHQL,
      data: {
        query: GET_AUDITIONS,
        variables: { ...payload },
      },
    });
    return response.data;
  };

  const getWorksheetAuditions = async (payload: IWorksheetAuditionRequestPayload) => {
    const response = await POST<IWorksheetAuditionResponsePayload>({
      url: ENDPOINTS.GRAPHQL,
      data: {
        query: GET_WORKSHEET_AUDITIONS,
        variables: { ...payload },
      },
    });
    return response.data;
  };

  const getAuditionActivities = async (auditionId: string) => {
    const response = await GET<IAuditionActivitiesResponsePayload>({
      url: `${ENDPOINTS.AUDITIONS}/${auditionId}/activities`,
    });
    return response.data;
  };

  const updateAudition = async (auditionId: string, payload: IAuditionUpdatePayload) => {
    const response = await PATCH<IAuditionMutateResponsePayload>({
      url: `${ENDPOINTS.AUDITIONS}/${auditionId}`,
      data: payload,
    });

    return response.data;
  };

  const setAuditionPriority = async (auditionId: string, payload: IAuditionPriorityUpdatePayload) => {
    const response = await PATCH<IAuditionPriorityResponsePayload>({
      url: `${ENDPOINTS.AUDITIONS}/${auditionId}/priorities`,
      data: payload,
    });

    return response.data;
  };

  return {
    getAuditions,
    getWorksheetAuditions,
    getAuditionActivities,
    updateAudition,

    setAuditionPriority,
  };
};
