import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    commentTab: {
      maxHeight: 300,
      overflow: 'auto',
      paddingRight: theme.spacing(1),
      [theme.breakpoints.down('lg')]: {
        maxHeight: 350,
      },
    },
    commentTab__item: {
      borderBottom: `1px solid ${theme.palette.customBlack.light}`,
      padding: theme.spacing(3, 0),
    },
    commentTab__personContainer: {
      display: 'flex',
      // justifyContent: 'space-between',
      alignItems: 'flex-start',
      gap: 16,
    },
    commentTab__imageContainer: {
      width: 40,
      height: 40,
    },
    commentTab__image: {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
      borderRadius: 10,
    },
    commentTab__detailContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 2,
    },
    commentTab__personDetail: {
      display: 'flex',
      gap: 8,
    },
    commentTab__startIconContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
    },
    commentTab__startIcon: {
      fontSize: 14,
    },
    commentTab__commentContainer: {
      marginTop: theme.spacing(1),
      paddingLeft: theme.spacing(7.3),
    },
  }),
);
