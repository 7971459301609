import { useSortable } from '@dnd-kit/sortable';
import { IconButton } from '@material-ui/core';
import {
  DragIcon,
  Input,
  ShadedStartBlueIconTwoTone,
  StickyNoteBlueIconTwoTone,
  StyledPopover,
  TextInput,
} from '@room-match/shared-ui-components';
import { ellipseText } from '@room-match/shared-utils';
import clsx from 'clsx';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import { COLORS } from 'shared/constants/COLORS';
import { Typography } from 'shared/elements';
import { IAuditionFlagResponsePayload } from 'shared/interfaces/IAuditionFlag';
import { IAuditionScheduleAttributes } from 'shared/interfaces/IAuditionSchedule';
import { IAuditionScheduleGroupAttributes } from 'shared/interfaces/IAuditionScheduleGroup';
import { getPublicNotesData } from 'shared/utils/auditionFlagParser';
import { getGroupPrimaryImage, getMediaImagesPerGroup, getPrimaryImage } from 'shared/utils/auditionMediaAttachments';
import { getFullName } from 'shared/utils/getFullName';
import { useStyles } from './AuditionGroup.styles';
import { CSS } from '@dnd-kit/utilities';

import { auditionScheduleFlagService } from 'shared/services/auditionScheduleFlagService';
import { combineAuditionScheduleMedium } from 'shared/utils/combineAuditionScheduleMedium';

type Props = {
  index: number;
  isViewed?: boolean;
  isSelected?: boolean;
  handleSelectItem: (index: number) => void;
  isEditMode?: boolean;
  hasShownExpanded?: boolean;
  auditionSchedules: IAuditionScheduleAttributes[];
  auditionScheduleGroup: IAuditionScheduleGroupAttributes;
  handleEditGroupNumber: (auditionScheduleGroupId: string, newGroupNumber: string, oldGroupNumber: string) => void;
};

const { multipleGetAuditionScheduleFlag } = auditionScheduleFlagService();
const AuditionGroup: React.FC<Props> = ({
  index,
  isViewed,
  isSelected,
  handleSelectItem,
  isEditMode,
  hasShownExpanded,
  auditionSchedules,
  auditionScheduleGroup,
  handleEditGroupNumber,
}) => {
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null);
  const [notesCount, setNotesCount] = useState<number>(0);
  const [starsCount, setStarsCount] = useState<number>(0);
  const sortable = useSortable({ id: auditionScheduleGroup.id });
  const { attributes, listeners, isDragging, setNodeRef, transform, transition } = sortable;
  const [groupNumber, setGroupNumber] = useState<string>(auditionScheduleGroup.group_number);
  const numInputRef = useRef<null | HTMLInputElement>(null);

  useEffect(() => {
    setGroupNumber(auditionScheduleGroup.group_number);
  }, [auditionScheduleGroup.group_number]);

  const handleClick = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };

  const auditionFlagQueries = multipleGetAuditionScheduleFlag(auditionSchedules.map((schedule) => schedule.id));

  useEffect(() => {
    if (auditionFlagQueries.length > 0) {
      const data = auditionFlagQueries.map((queries) => queries.data) as IAuditionFlagResponsePayload[];
      if (data.length > 0) {
        const notesData = data.map((value) => {
          if (value) {
            return getPublicNotesData(value.data);
          }
        });
        const totalNotes = notesData.reduce((acc, curr) => acc + (curr?.length || 0), 0);
        const totalStars = notesData.reduce((acc, curr) => {
          if (curr) {
            const totalStarsPerNotes = curr.reduce((acc, curr) => acc + (curr?.attributes?.rating || 0), 0);
            return acc + totalStarsPerNotes;
          }
          return acc;
        }, 0);
        setNotesCount(totalNotes);
        setStarsCount(totalStars);
      }
    }
  }, [auditionFlagQueries]);

  const handleClose = () => {
    setAnchorEl(null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const getAuditionScheduleTalentsFullName = useMemo(() => {
    return auditionScheduleGroup.audition_schedules.map((auditionSchedule) => {
      if (auditionSchedule.audition.profile.talent) {
        return ellipseText(
          getFullName(
            auditionSchedule.audition.profile.talent.first_name,
            auditionSchedule.audition.profile.talent.last_name,
          ),
          12,
        );
      }
      return [];
    });
  }, [auditionScheduleGroup]);

  const handleKeyUp = (event: React.KeyboardEvent<HTMLDivElement>) => {
    const { key } = event;
    if (key === 'Escape') {
      setGroupNumber(auditionScheduleGroup.group_number);
    } else if (key === 'Enter') {
      if (auditionScheduleGroup.group_number !== groupNumber) {
        // TODO UPDATE GROUP NUMBER

        if (numInputRef.current) {
          numInputRef.current.blur();
        }
        handleEditGroupNumber(auditionScheduleGroup.id, groupNumber, auditionScheduleGroup.group_number);
        setGroupNumber(auditionScheduleGroup.group_number);
      }
    }
  };

  const style: React.CSSProperties = {
    transform: CSS.Transform.toString(transform),
    transition,
    opacity: isDragging ? 0.3 : 1,
  };

  return (
    <div
      onMouseLeave={() => handleClose()}
      ref={setNodeRef}
      style={style}
      className={clsx(classes.item, {
        [classes.item__selected]: isSelected,
        [classes.item__notActive]: hasShownExpanded && !isSelected,
      })}
    >
      {/* Thumbnail */}
      <div className={classes.item__thumbnailContainer} onClick={() => handleSelectItem(index)}>
        <img
          src={getGroupPrimaryImage(auditionScheduleGroup.medium_attachments, auditionScheduleGroup.id)}
          className={classes.item__thumbnail}
        />
        {isViewed && (
          <div className={classes.item__isViewed}>
            <Typography className={classes.item__viewedText}>Viewed</Typography>
          </div>
        )}
      </div>
      {/* Details */}
      <div className={classes.item__details}>
        {/* Drag */}
        {isEditMode && (
          <div
            className={classes.item__dragContainer}
            {...attributes}
            {...listeners}
            onClick={(e) => e.stopPropagation()}
          >
            <IconButton size="small">
              <DragIcon fontSize="small" />
            </IconButton>
          </div>
        )}

        {isEditMode ? (
          <div className={classes.item__inputIdContainer}>
            <TextInput
              margin="none"
              value={groupNumber}
              inputRef={numInputRef}
              onKeyUp={handleKeyUp}
              onChange={(e) => {
                const re = /^[0-9\b]+$/;
                if (e.target.value === '' || re.test(e.target.value)) {
                  setGroupNumber(e.target.value);
                }
              }}
            />
          </div>
        ) : (
          <div className={classes.item__idContainer}>
            <Typography>{groupNumber || '—'}</Typography>
          </div>
        )}

        <div className={classes.item__detailDescriptionContainer}>
          <div>
            <div className={classes.item__groupNameContainer} onClick={(e) => handleClick(e)}>
              <Typography className={classes.item__groupName}>
                {getAuditionScheduleTalentsFullName.slice(0, 2).join(', ')}
                <span style={{ color: COLORS.PRIMARY }}>
                  {getAuditionScheduleTalentsFullName.slice(2).length > 0
                    ? ` +${getAuditionScheduleTalentsFullName.slice(2).length}`
                    : ''}
                </span>
              </Typography>
            </div>

            <StyledPopover
              id={id}
              open={open}
              anchorEl={anchorEl}
              onClose={handleClose}
              anchorOrigin={{
                vertical: 'bottom',
                horizontal: 'center',
              }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'center',
              }}
            >
              <div className={classes.popover}>
                <div className={classes.popover__talentList}>
                  {auditionSchedules.map((auditionSchedule) => (
                    <div className={classes.popover__talentItem} key={auditionSchedule.id}>
                      <img
                        src={getPrimaryImage(
                          auditionSchedule.audition,
                          getMediaImagesPerGroup(auditionSchedule.medium_attachments, auditionScheduleGroup.id),
                        )}
                        className={classes.popover__talentImg}
                      />
                      <div className={classes.popover__talentDetailsContainer}>
                        <Typography fontSize={16} fontWeight={500}>
                          {getFullName(
                            auditionSchedule.audition.profile.talent?.first_name,
                            auditionSchedule.audition.profile.talent?.last_name,
                          )}
                          ({auditionSchedule.audition.sequence_tag})
                        </Typography>
                        <Typography fontSize={14} color="primary">
                          {auditionSchedule.audition.casting_role.name}
                        </Typography>
                      </div>
                    </div>
                  ))}
                </div>
                <div className={classes.popover__closeWindow} onClick={() => handleClose()}>
                  <Typography fontSize={13} style={{ color: '#91a5bc' }}>
                    Close Window
                  </Typography>
                </div>
              </div>
            </StyledPopover>
          </div>

          <div className={classes.item__bottomContainer}>
            <div className={classes.item__bottomFlex}>
              <StickyNoteBlueIconTwoTone style={{ fontSize: 12 }} />
              <Typography fontSize={12}>{notesCount} Comments</Typography>
            </div>
            <div className={classes.item__bottomFlex}>
              <ShadedStartBlueIconTwoTone style={{ fontSize: 12 }} />
              <Typography fontSize={12}>{starsCount} Ratings</Typography>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default AuditionGroup;
