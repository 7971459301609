import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const ArrowDownIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 14 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M13.394 9.281a1.143 1.143 0 0 0-1.616 0l-3.764 3.763-.008-11.901a1.143 1.143 0 1 0-2.286 0l.008 11.902L1.965 9.28a1.143 1.143 0 1 0-1.616 1.616l4.097 4.099a3.429 3.429 0 0 0 4.85 0l4.098-4.099a1.143 1.143 0 0 0 0-1.616z"
        fill="#677482"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default ArrowDownIcon;
