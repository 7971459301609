import { gql } from 'graphql-request';

export const GET_AUDITIONS = gql`
  query ($casting_role_id: ID!, $phase: String!, $status: String!) {
    auditions(castingRoleId: $casting_role_id, phase: $phase, status: $status) {
      id
      sequence_tag
      priority
      submission_note
      mediumable_count
      internal_note_count
      public_note_count
      internal_rating
      public_rating
      casting_role {
        name
      }
      audition_schedules {
        main
        phase
        status
        id
        medium_attachments {
          id
          attachment
          modified_attachment
          sort
          tag_list
          primary
        }
      }
      profile {
        talent {
          id
          email
          first_name
          last_name
          sag_status
          contact_no
        }
        primary_medium_attachment {
          primary
          medium_id
          mediumable_id
          mediumable_type
          attachment
          modified_attachment
        }
        agency {
          name
          email
          company_type
          contact_first_name
          contact_last_name
          contact_number
          country
          state
          website
        }
      }
    }
  }
`;

export const GET_AUDITIONS_PER_SESSION = gql`
  query ($session_id: ID!, $status: String!) {
    audition_schedules(sessionId: $session_id, status: $status) {
      id
      phase
      status
      checked_in_at
      grouping_list
      medium_attachments {
        attachment
        modified_attachment
        sort
        tag_list
        primary
      }
      schedule {
        time_in
        time_out
      }
      audition {
        id
        sequence_tag
        priority
        submission_note
        mediumable_count
        internal_note_count
        public_note_count
        internal_rating
        public_rating
        profile {
          id
          primary_medium_attachment {
            primary
            medium_id
            mediumable_id
            mediumable_type
            attachment
            modified_attachment
          }
          talent {
            id
            email
            first_name
            last_name
            sag_status
            contact_no
          }
          agency {
            name
            email
            company_type
            contact_first_name
            contact_last_name
            contact_number
            country
            state
            website
          }
        }
        medium_attachments {
          id
          attachment
          modified_attachment
          sort
          medium {
            file_type
            file_name
            file_size
            file_width
            file_height
          }
        }
        casting_role {
          id
          name
        }
      }
    }
  }
`;

export const GET_GROUPED_AUDITIONS_PER_SESSION = gql`
  query ($session_id: ID!) {
    audition_schedule_groups(sessionId: $session_id) {
      id
      group_name
      group_number
      auditioned
      role_names
      viewed
      medium_attachments {
        attachment
        modified_attachment
        sort
        tag_list
        primary
      }
      audition_schedules {
        id
        phase
        status
        checked_in_at
        grouping_list
        medium_attachments {
          attachment
          modified_attachment
          sort
          tag_list
          primary
        }
        schedule {
          time_in
          time_out
        }
        audition {
          id
          sequence_tag
          priority
          submission_note
          mediumable_count
          internal_note_count
          public_note_count
          internal_rating
          public_rating
          casting_role {
            id
            name
          }
          profile {
            id
            primary_medium_attachment {
              primary
              medium_id
              mediumable_id
              mediumable_type
              attachment
              modified_attachment
            }
            talent {
              id
              email
              first_name
              last_name
              sag_status
              contact_no
            }
            agency {
              name
              email
              company_type
              contact_first_name
              contact_last_name
              contact_number
              country
              state
              website
            }
          }
          medium_attachments {
            id
            attachment
            modified_attachment
            sort
            tag_list
            medium {
              file_type
              file_name
              file_size
              file_width
              file_height
            }
          }
          casting_role {
            id
            name
          }
        }
      }
    }
  }
`;
