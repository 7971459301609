import { Box, Button, Grid } from '@material-ui/core';
import {
  CustomVideoPlayer,
  DeclineIconTwoTone,
  LikeIconTwoTone,
  MagnifyingGlassIconTwoTone,
  PlayIconTwoTone,
  ShadedStarIconTwoTone,
  ShadedStartBlueIconTwoTone,
  StickyNoteBlueIconTwoTone,
  StickyNoteIconTwoTone,
  UploadIconTwoTone,
} from '@room-match/shared-ui-components';
import clsx from 'clsx';
import { useQueryClient } from 'react-query';

import React, { useEffect, useMemo, useState } from 'react';
import { StyledTab, StyledTabs, Typography } from 'shared/elements';
import { CommentTab } from './CommentTab';

import { useStyles } from './AuditionGroupExpandingContent.styles';

import { getFullName } from 'shared/utils/getFullName';
import { IComment } from 'shared/interfaces/utils/IComment';
import { NoteTextArea } from 'components/NoteTextArea';
import { IMediumAttributes } from 'shared/interfaces/IMedium';
import { getInternalNotesData, getPublicNotesData } from 'shared/utils/auditionFlagParser';
import { ISelectMediaButtonProps } from 'shared/interfaces/utils/ISelectMediaButtonProps';
import {
  getMediaImagesPerGroup,
  getPrimaryImage,
  getSlatesPerGroup,
  getViewMediaImagePerGroup,
} from 'shared/utils/auditionMediaAttachments';
import { auditionScheduleFlagService } from 'shared/services/auditionScheduleFlagService';
import { sumArrayOfNumbers } from '@room-match/shared-utils';
import { IAuditionScheduleAttributes } from 'shared/interfaces/IAuditionSchedule';

type Props = {
  audition_schedule: IAuditionScheduleAttributes;
  handleSelectedMediumTab: (instance: IMediumAttributes[], audition_schedule: IAuditionScheduleAttributes) => void;
  handleSelectedMediaButton: (mediaButton: ISelectMediaButtonProps) => void;
  selectedMediaButton: ISelectMediaButtonProps | null;
  groupId: string;
};

const { getAuditionScheduleFlags, createAuditionScheduleFlag } = auditionScheduleFlagService();
const AuditionScheduleCommentTab: React.FC<Props> = ({
  audition_schedule,
  handleSelectedMediumTab,
  groupId,
  handleSelectedMediaButton,
  selectedMediaButton,
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const [tab, setTab] = useState<string>('internal_notes');

  const { mutate } = createAuditionScheduleFlag();
  const { data: auditionFlagData } = getAuditionScheduleFlags(audition_schedule.id, {
    enabled: !!audition_schedule.id,
  });

  const getInternalNotes = useMemo(() => {
    return auditionFlagData ? getInternalNotesData(auditionFlagData.data) : [];
  }, [auditionFlagData]);

  const getPublicNotes = useMemo(() => {
    return auditionFlagData ? getPublicNotesData(auditionFlagData.data) : [];
  }, [auditionFlagData]);

  const tabs = useMemo(() => {
    return [
      {
        name: 'internal_notes',
        header: (
          <Box sx={{ display: 'flex', alignItems: 'center', gridGap: 6 }}>
            <StickyNoteIconTwoTone style={{ fontSize: 16 }} />
            <Typography>Internal Notes</Typography>
          </Box>
        ),
      },
      {
        name: 'public_notes',
        header: (
          <Box sx={{ display: 'flex', alignItems: 'center', gridGap: 6 }}>
            <StickyNoteBlueIconTwoTone style={{ fontSize: 16 }} />
            <Typography>Public Notes</Typography>
          </Box>
        ),
      },
    ];
  }, []);

  useEffect(() => {
    if (audition_schedule && audition_schedule.medium_attachments && audition_schedule.medium_attachments.length > 0) {
      const getFirstMedium = audition_schedule.medium_attachments[0];
      if (getFirstMedium.tag_list.length > 0) {
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (tab !== newValue) {
      setTab(newValue);
    }
  };

  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

  const onCreateNote = (type: 'Internal Note' | 'Public Note', rating: number, note: string) => {
    mutate(
      { auditionScheduleId: audition_schedule.id, payload: { flag_type: type, comment: note, rating: rating } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['audition_schedule_flag', audition_schedule.id], {
            refetchInactive: true,
          });
          queryClient.invalidateQueries(['audition'], {
            refetchInactive: true,
          });
        },
      },
    );
  };

  const handleSubmitComment = (rating: number, note: string) => {
    if (tab === 'internal_notes') {
      onCreateNote('Internal Note', rating, note);
    } else {
      onCreateNote('Public Note', rating, note);
    }
  };

  const internalNotesComments = useMemo(() => {
    return getInternalNotes.map((internalNote) => {
      return {
        comment: internalNote.attributes.comment,
        rating: internalNote.attributes.rating,
        user: internalNote.attributes.user.data.attributes,
        created_at: internalNote.attributes.created_at,
      };
    });
  }, [getInternalNotes]) as IComment[];

  const publicNotesComments = useMemo(() => {
    return getPublicNotes.map((publicNote) => {
      return {
        comment: publicNote.attributes.comment,
        rating: publicNote.attributes.rating,
        user: publicNote.attributes.user.data.attributes,
        created_at: publicNote.attributes.created_at,
      };
    });
  }, [getPublicNotes]) as IComment[];

  const isMediaButtonSelected = (type: string, id: string) => {
    if (selectedMediaButton) {
      return selectedMediaButton.type === type && selectedMediaButton.id === id;
    }
    return false;
  };

  const getAuditionSlates = useMemo(() => {
    return getSlatesPerGroup(audition_schedule.medium_attachments, groupId);
  }, [audition_schedule.medium_attachments, groupId]);

  return (
    <div className={classes.rightContainer}>
      {/* Talent Profie */}
      <div className={classes.expandedDiv__talentContainer}>
        <div className={classes.expandedDiv__imageContainer}>
          <img
            src={getPrimaryImage(
              audition_schedule.audition,
              getMediaImagesPerGroup(audition_schedule.medium_attachments, groupId),
            )}
            className={classes.expandedDiv__image}
          />
        </div>
        {/* Talent Profile Detail  */}
        <div className={classes.expandedDiv__detailContainer}>
          <Typography fontWeight={500}>
            {getFullName(
              audition_schedule.audition.profile?.talent?.first_name,
              audition_schedule.audition.profile?.talent?.last_name,
            )}
          </Typography>
          {/* Talent Profile Icons */}
          <div className={classes.expandedDiv__iconsContainer}>
            <div
              className={clsx(classes.expandedDiv__iconItem, {
                [classes.expandedDiv__iconItem__hidden]: tab !== 'internal_notes',
              })}
            >
              <StickyNoteIconTwoTone classes={{ root: classes.expandedDiv__icon }} />
              <Typography fontSize={16}>{internalNotesComments.length}</Typography>
            </div>
            <div
              className={clsx(classes.expandedDiv__iconItem, {
                [classes.expandedDiv__iconItem__hidden]: tab !== 'internal_notes',
              })}
            >
              <ShadedStarIconTwoTone classes={{ root: classes.expandedDiv__icon }} />
              <Typography fontSize={16}>
                {sumArrayOfNumbers(internalNotesComments.map((v) => v.rating || 0)) || 0}
              </Typography>
            </div>
            <div
              className={clsx(classes.expandedDiv__iconItem, {
                [classes.expandedDiv__iconItem__hidden]: tab !== 'public_notes',
              })}
            >
              <StickyNoteBlueIconTwoTone classes={{ root: classes.expandedDiv__icon }} />
              <Typography fontSize={16}>{publicNotesComments.length}</Typography>
            </div>
            <div
              className={clsx(classes.expandedDiv__iconItem, {
                [classes.expandedDiv__iconItem__hidden]: tab !== 'public_notes',
              })}
            >
              <ShadedStartBlueIconTwoTone classes={{ root: classes.expandedDiv__icon }} />
              <Typography fontSize={16}>
                {sumArrayOfNumbers(publicNotesComments.map((v) => v.rating || 0)) || 0}
              </Typography>
            </div>
          </div>
        </div>
        <div className={classes.slateImages_container}>
          {getAuditionSlates.length > 0 && (
            <Button
              variant={isMediaButtonSelected('video', audition_schedule.audition.id) ? 'outlined' : undefined}
              color="primary"
              onClick={() =>
                handleSelectedMediaButton({
                  type: 'video',
                  attachments: getAuditionSlates,
                  isGroup: false,
                  label: 'Slate',
                  id: audition_schedule.audition.id,
                })
              }
              startIcon={<PlayIconTwoTone classes={{ root: classes.buttonIcon }} />}
            >
              Slate
            </Button>
          )}

          <Button
            variant={isMediaButtonSelected('image', audition_schedule.audition.id) ? 'outlined' : undefined}
            color="primary"
            onClick={() =>
              handleSelectedMediaButton({
                type: 'image',
                attachments: getViewMediaImagePerGroup(audition_schedule.medium_attachments, groupId),
                isGroup: false,
                label: 'Image',
                id: audition_schedule.audition.id,
              })
            }
            startIcon={<UploadIconTwoTone classes={{ root: classes.buttonIcon }} />}
          >
            Images
          </Button>
        </div>
      </div>

      {/* Comment Section */}
      <div className={classes.expandedDiv__commentSectionContainer}>
        <StyledTabs value={tab} onChange={handleTabChange} aria-label="simple tabs example" variant="fullWidth">
          {tabs.map((tab, index) => (
            <StyledTab key={index} label={tab.header} value={tab.name} {...a11yProps(index)} />
          ))}
        </StyledTabs>
        <div className={classes.noteTextAreaContainer}>
          <NoteTextArea isYellowStar={tab === 'internal_notes'} onSubmitForm={handleSubmitComment} minRows={1} />
        </div>
        <div className={classes.expandedDiv__commentSection__content}>
          <TabPanel value={tab} index="internal_notes">
            <CommentTab comments={internalNotesComments} isYellowStar />
          </TabPanel>
          <TabPanel value={tab} index="public_notes">
            <CommentTab comments={publicNotesComments} />
          </TabPanel>
        </div>
      </div>
    </div>
  );
};

export default AuditionScheduleCommentTab;
