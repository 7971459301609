import { PayloadAction, createSlice } from '@reduxjs/toolkit';
import { authDao } from 'shared/dao/authDao';
import {
  IForgotPasswordRequestPayload,
  IResetPasswordRequestPayload,
  ISignInRequestPayload,
  ISignUpRequestPayload,
  IUser,
} from 'shared/interfaces/IUser';
import { ls } from 'shared/utils/ls';

import { AppThunk, RootState } from '../store';
import { ROUTES } from 'shared/constants/ROUTES';
import { errorResponseToArray } from '@room-match/shared-utils';
import { CONFIG } from 'shared/config';

const { logout, getUserProfile: getUserProfileService, login, signup, sendEmail, resetPassword } = authDao();

const { setLS, removeLS } = ls();

export interface UserState {
  isLoading: boolean;
  user: IUser | null;
  errorMessage: string | null;
  responseMessage: string | null;
  isLoggedIn: boolean;
}
const initialState: UserState = {
  isLoading: false,
  user: null,
  errorMessage: null,
  responseMessage: null,
  isLoggedIn: false,
};
export const userSlicer = createSlice({
  name: 'user',
  initialState,
  reducers: {
    setUser(state: UserState, action: PayloadAction<{ user: IUser }>) {
      state.user = action.payload.user;
    },
    setIsLoading(state: UserState, action: PayloadAction<boolean>) {
      state.isLoading = action.payload;
    },
    setErrorMessage(state: UserState, action: PayloadAction<string | null>) {
      state.errorMessage = action.payload;
    },
    setResponseMessage(state: UserState, action: PayloadAction<string | null>) {
      state.responseMessage = action.payload;
    },
    setIsLoggedIn(state: UserState, action: PayloadAction<boolean>) {
      state.isLoggedIn = action.payload;
    },
    handleCompleteProfileSuccess(state: UserState, action: PayloadAction<{ user: IUser }>) {
      const { user } = action.payload;
      setLS('is_completed_primary_details', true);
      state.isLoggedIn = true;
      state.user = user;
    },
    handleAuthExpired(state: UserState) {
      removeLS('auth_token');
      state.errorMessage = null;
      state.isLoggedIn = false;
    },
  },
});

export const selectUserState = (state: RootState) => state.user;
export const selectUser = (state: RootState) => state.user.user;

export const {
  setUser,
  setIsLoading,
  setErrorMessage,
  setResponseMessage,
  setIsLoggedIn,
  handleAuthExpired,
  handleCompleteProfileSuccess,
} = userSlicer.actions;
export default userSlicer.reducer;

export const userLogin =
  (payload: ISignInRequestPayload, isRememberMe?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));

      const {
        data: { data: user, token, refresh_token },
      } = await login(payload);
      if (user.attributes.user_types.some((userType) => userType === 'casting')) {
        if (user.attributes.user_types.some((userType) => userType === 'contact')) {
          dispatch(setErrorMessage('Invalid email or password'));
        } else {
          setLS('auth_token', token);
          setLS('user', JSON.stringify(user));
          setLS('is_completed_primary_details', true);
          if (isRememberMe) {
            setLS('refresh_token', refresh_token);
          }
          dispatch(setUser({ user }));
          dispatch(setIsLoggedIn(true));
          dispatch(setErrorMessage(null));
          return user;
        }
      } else if (user.attributes.user_types.includes('talent')) {
        window.location.href = `${CONFIG.TALENT_APP_URL}/login?token=${token}`;
      } else {
        dispatch(setErrorMessage('Invalid email or password'));
        return null;
      }
    } catch (err: any) {
      dispatch(setErrorMessage(err.response.data.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
export const clientLogin =
  (payload: ISignInRequestPayload, isRememberMe?: boolean): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));

      const {
        data: { data: user, token, refresh_token },
      } = await login(payload);
      if (user.attributes.user_types.some((userType) => userType === 'contact')) {
        if (user.attributes.user_types.some((userType) => userType === 'casting')) {
          dispatch(setErrorMessage('Invalid email or password'));
        } else {
          setLS('auth_token', token);
          setLS('user', JSON.stringify(user));
          setLS('is_completed_primary_details', true);
          if (isRememberMe) {
            setLS('refresh_token', refresh_token);
          }
          dispatch(setUser({ user }));
          dispatch(setIsLoggedIn(true));
          dispatch(setErrorMessage(null));
          return user;
        }
      } else if (user.attributes.user_types.includes('talent')) {
        window.location.href = `${CONFIG.TALENT_APP_URL}/login?token=${token}`;
      } else {
        dispatch(setErrorMessage('Invalid email or password'));
        return null;
      }
    } catch (err: any) {
      dispatch(setErrorMessage(err.response.data.message));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
export const userSignup =
  (payload: ISignUpRequestPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));

      const {
        data: { data: user, token },
      } = await signup(payload);

      setLS('auth_token', token);
      setLS('user', JSON.stringify(user));
      setLS('is_completed_primary_details', user.attributes.completed_primary_details);

      dispatch(setUser({ user }));
      dispatch(setIsLoggedIn(true));
      dispatch(setErrorMessage(null));
      return user;
    } catch (err: any) {
      const { errors = {} } = err.response.data;
      const errrMessage = errorResponseToArray(errors);

      dispatch(setErrorMessage(errrMessage));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
export const userSendEmail =
  (payload: IForgotPasswordRequestPayload, history: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));

      const {
        data: { message = 'Email successfully sent' },
      } = await sendEmail(payload);

      dispatch(setResponseMessage(message));
      history.push(ROUTES.LOGIN);
      return message;
    } catch (err: any) {
      const { errors = '' } = err.response.data;
      dispatch(setErrorMessage(errors));
    } finally {
      dispatch(setIsLoading(false));
    }
  };
export const userResetPassword =
  (payload: IResetPasswordRequestPayload, history: any): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));

      const { message } = await resetPassword(payload);

      dispatch(setResponseMessage(message));
      history.push(ROUTES.LOGIN);
      return message;
    } catch (err: any) {
      const { errors = {} } = err.response.data;
      const errrMessage = errorResponseToArray(errors);

      dispatch(setErrorMessage(errrMessage));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const clientResetPassword =
  (payload: IResetPasswordRequestPayload): AppThunk =>
  async (dispatch) => {
    try {
      dispatch(setIsLoading(true));
      const { message, data, token, refresh_token } = await resetPassword(payload);
      setLS('auth_token', token);
      setLS('user', JSON.stringify(data));
      setLS('refresh_token', refresh_token);
      dispatch(setResponseMessage(message));

      return message;
    } catch (err: any) {
      const { errors = {} } = err.response.data;
      const errrMessage = errorResponseToArray(errors);
      dispatch(setErrorMessage(errrMessage));
    } finally {
      dispatch(setIsLoading(false));
    }
  };

export const getUserProfile = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    const { data } = await getUserProfileService();
    setLS('is_completed_primary_details', data.attributes.completed_primary_details);
    dispatch(setUser({ user: data }));
    dispatch(setIsLoggedIn(true));
  } catch (err: any) {
    dispatch(setErrorMessage(err.response?.data?.error));
  } finally {
    dispatch(setIsLoading(false));
  }
};

export const autoLogin =
  (payload: string): AppThunk =>
  async (dispatch) => {
    try {
      // dispatch(setIsLoading(true));
      removeLS('auth_token');
      removeLS('user');
      removeLS('is_completed_primary_details');
      dispatch(setIsLoggedIn(false));
      const { data } = await getUserProfileService(payload);

      setLS('auth_token', payload);
      setLS('user', JSON.stringify({ user: data }));
      setLS('is_completed_primary_details', true);
      dispatch(setUser({ user: data }));
      dispatch(setIsLoggedIn(true));
    } catch (err: any) {
      // dispatch(setErrorMessage(err.response.data.error));
      // dispatch(setIsLoading(false));
      // return;
      window.location.href = '/login';
    }
  };

export const userLogout = (): AppThunk => async (dispatch) => {
  try {
    dispatch(setIsLoading(true));
    removeLS('auth_token');
    removeLS('user');
    removeLS('is_completed_primary_details');
    removeLS('refresh_token');
    dispatch(setIsLoggedIn(false));
    await logout();
  } catch (err: any) {
    dispatch(setErrorMessage(err.response.data.message));
  } finally {
    dispatch(setIsLoading(false));
  }
};
