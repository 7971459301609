import { createSlice } from '@reduxjs/toolkit';

export interface ThemeState {
  mode: 'dark' | 'light';
  time: number;
}

const initialState: ThemeState = {
  mode: 'dark',
  time: 0,
};

export const themeSlicer = createSlice({
  name: 'theme',
  initialState,
  reducers: {
    toggleTheme(state: ThemeState) {
      if (state.mode === 'dark') {
        state.mode = 'light';
      } else {
        state.mode = 'dark';
      }
    },
  },
});

export const selectTheme = (state: ThemeState) => state;

export const { toggleTheme } = themeSlicer.actions;

export default themeSlicer.reducer;
