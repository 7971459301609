import { QueryKey, useMutation, useQuery, UseQueryOptions } from 'react-query';
import { projectDao } from 'shared/dao/projectDao';
import { ICommonResponsePayload } from 'shared/interfaces/ICommon';
import {
  IProjectContactAttachmentRequestPayload,
  IProjectContactAttachmentResponsePayload,
  IProjectCreatePayload,
  IProjectCreateResponsePayload,
  IProjectGetCastingSessionRequestPayload,
  IProjectGetCastingSessionResponsePayload,
  IProjectImportResponsePayload,
  IProjectRequestPayload,
  IProjectResponsePayload,
  IProjectSearchCastingRoleRequestPayload,
  IProjectSearchCastingRoleResponsePayload,
  IProjectSearchResponsePayload,
  ISingleProjectResponsePayload,
  IProjectUpdateAttachedContactRequestPayload,
  IProjectUpdateAttachedContactResponsePayload,
  IProjectQuestionAtttachmentResponsePayload,
  IProjectNdaPayload,
  IProjectQuestionSortUpdatePayload,
  IProjectQuestionUpdatePayload,
  IProjectQuestionsAttachPayload,
  IProjectContactsResponsePayload,
} from 'shared/interfaces/IProject';
import { IQuestionRequestPayload, IQuestionsAttachResponsePayload } from 'shared/interfaces/IQuestion';
import { IStaffAttachPayload, IStaffAttachResponsePayload, IStaffAttachSidesPayload } from 'shared/interfaces/IStaff';
import { IAxiosErrorResponse, IOnProgress } from 'shared/interfaces/utils/IAxios';

const {
  getProjects: getProjectsDao,
  getSingleProject: getSingleProjectDao,
  createProject: createProjectDao,
  updateProject: updateProjectDao,
  importProject: importProjectDao,
  searchProjects: searchProjectsDao,
  searchCastingRolesByProject: searchCastingRolesByProjectDao,
  getCastingSessionsByProject: getCastingSessionsByProjectDao,
  attachProjectStaff: attachProjectStaffDao,
  attachProjectQuestions: attachProjectQuestionsDao,
  getProjectQuestions: getProjectQuestionsDao,
  detachProjectStaff: detachProjectStaffDao,
  attachDetachSessionDirectors: attachDetachSessionDirectorsDao,
  attachProjectSides: attachProjectSidesDao,
  attachProjectContacts: attachProjectContactsDao,
  detachProjectContacts: detachProjectContactsDao,
  updateProjectContactAttachments: updateProjectContactAttachmentsDao,
  getProjectContacts: getProjectContactsDao,
  sortProjectQuestions: sortProjectQuestionsDao,
  updateProjectQuestion: updateProjectQuestionDao,
} = projectDao();
export const projectService = () => {
  const getProjects = (payload?: IProjectRequestPayload) => {
    return useQuery<IProjectResponsePayload, IAxiosErrorResponse>(['projects', payload], () => getProjectsDao(payload));
  };

  const getSingleProject = (projectId: string) => {
    return useQuery<ISingleProjectResponsePayload, IAxiosErrorResponse>(
      ['projects', projectId, 'single'],
      () => getSingleProjectDao(projectId),
      {
        enabled: !!projectId,
      },
    );
  };

  const createProject = () => {
    return useMutation<IProjectCreateResponsePayload, IAxiosErrorResponse, IProjectCreatePayload>(
      (payload: IProjectCreatePayload) => createProjectDao(payload),
    );
  };

  const updateProject = () => {
    return useMutation<
      IProjectCreateResponsePayload,
      IAxiosErrorResponse,
      { projectId: string; payload: IProjectCreatePayload | IProjectNdaPayload }
    >(({ projectId, payload }) => updateProjectDao(projectId, payload));
  };

  const importProject = () => {
    return useMutation<
      IProjectImportResponsePayload,
      IAxiosErrorResponse,
      { formData: FormData; onProgress?: IOnProgress }
    >(({ formData, onProgress }) => importProjectDao(formData, onProgress));
  };

  const searchProjects = (query: string) => {
    return useQuery<IProjectSearchResponsePayload, IAxiosErrorResponse>(['projects', query], () =>
      searchProjectsDao(query),
    );
  };

  const searchCastingRolesByProject = (
    payload: IProjectSearchCastingRoleRequestPayload,
    options?: Omit<
      UseQueryOptions<
        IProjectSearchCastingRoleResponsePayload,
        IAxiosErrorResponse,
        IProjectSearchCastingRoleResponsePayload,
        QueryKey
      >,
      'queryKey' | 'queryFn'
    >,
  ) => {
    return useQuery<
      IProjectSearchCastingRoleResponsePayload,
      IAxiosErrorResponse,
      IProjectSearchCastingRoleResponsePayload
    >(['projects/casting_role', payload], () => searchCastingRolesByProjectDao(payload), options);
  };

  const getCastingSessionsByProject = (
    payload: IProjectGetCastingSessionRequestPayload,
    options?: Omit<
      UseQueryOptions<
        IProjectGetCastingSessionResponsePayload,
        IAxiosErrorResponse,
        IProjectGetCastingSessionResponsePayload,
        QueryKey
      >,
      'queryKey' | 'queryFn'
    >,
  ) => {
    return useQuery<IProjectGetCastingSessionResponsePayload, IAxiosErrorResponse>(
      ['projects/casting_session', payload],
      () => getCastingSessionsByProjectDao(payload),
      options,
    );
  };

  const attachProjectStaff = () => {
    return useMutation<
      IStaffAttachResponsePayload,
      IAxiosErrorResponse,
      { projectId: string; payload: IStaffAttachPayload }
    >(({ projectId, payload }) => attachProjectStaffDao(projectId, payload));
  };

  const attachDetachSessionDirectors = () => {
    return useMutation<
      IStaffAttachResponsePayload,
      IAxiosErrorResponse,
      { projectId: string; payload: { staff_ids: string[] } }
    >(({ projectId, payload }) => attachDetachSessionDirectorsDao(projectId, payload));
  };

  const attachProjectQuestions = () => {
    return useMutation<
      IQuestionsAttachResponsePayload,
      IAxiosErrorResponse,
      { projectId: string; payload: IProjectQuestionsAttachPayload }
    >(({ projectId, payload }) => attachProjectQuestionsDao(projectId, payload));
  };

  const getProjectQuestions = (projectId: string, payload: IQuestionRequestPayload) => {
    return useQuery<IProjectQuestionAtttachmentResponsePayload, IAxiosErrorResponse>(
      ['project_questions', projectId, payload],
      () => getProjectQuestionsDao(projectId, payload),
      {
        enabled: !!projectId,
      },
    );
  };

  const detachProjectStaff = () => {
    return useMutation<IStaffAttachResponsePayload, IAxiosErrorResponse, { projectId: string; attachmentId: string }>(
      ({ projectId, attachmentId }) => detachProjectStaffDao(projectId, attachmentId),
    );
  };

  const attachProjectSides = () => {
    return useMutation<
      IStaffAttachResponsePayload,
      IAxiosErrorResponse,
      { projectId: string; payload: IStaffAttachSidesPayload }
    >(({ projectId, payload }) => attachProjectSidesDao(projectId, payload));
  };

  const attachProjectContacts = () => {
    return useMutation<
      IProjectContactAttachmentResponsePayload,
      IAxiosErrorResponse,
      { projectId: string; payload: IProjectContactAttachmentRequestPayload }
    >(({ projectId, payload }) => attachProjectContactsDao(projectId, payload));
  };
  const detachProjectContacts = () => {
    return useMutation<ICommonResponsePayload, IAxiosErrorResponse, { projectId: string; attachmentId: string }>(
      ({ projectId, attachmentId }) => detachProjectContactsDao(projectId, attachmentId),
    );
  };

  const updateProjectContactAttachments = () => {
    return useMutation<
      IProjectUpdateAttachedContactResponsePayload,
      IAxiosErrorResponse,
      { projectId: string; contactId: string; payload: IProjectUpdateAttachedContactRequestPayload }
    >(({ projectId, contactId, payload }) => updateProjectContactAttachmentsDao(projectId, contactId, payload));
  };

  const getProjectContacts = (projectId: string) => {
    return useQuery<IProjectContactsResponsePayload, IAxiosErrorResponse>(
      ['project_contact_attachment', projectId],
      () => getProjectContactsDao(projectId),
      {
        enabled: !!projectId,
      },
    );
  };

  const sortProjectQuestions = () => {
    return useMutation<
      { message: string },
      IAxiosErrorResponse,
      {
        projectId: string;
        payload: IProjectQuestionSortUpdatePayload;
      }
    >(({ projectId, payload }) => sortProjectQuestionsDao(projectId, payload));
  };

  const updateProjectQuestion = () => {
    return useMutation<
      { message: string },
      IAxiosErrorResponse,
      { projectId: string; attachmentId: string; payload: IProjectQuestionUpdatePayload }
    >(({ projectId, attachmentId, payload }) => updateProjectQuestionDao(projectId, attachmentId, payload));
  };

  return {
    getProjects,
    getSingleProject,
    createProject,
    updateProject,
    importProject,
    searchProjects,
    searchCastingRolesByProject,
    getCastingSessionsByProject,
    attachProjectStaff,
    attachProjectQuestions,
    getProjectQuestions,
    detachProjectStaff,
    attachDetachSessionDirectors,
    attachProjectSides,
    attachProjectContacts,
    detachProjectContacts,
    updateProjectContactAttachments,
    getProjectContacts,
    sortProjectQuestions,
    updateProjectQuestion,
  };
};
