import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const CastingAppLogoPlain = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="eturj5bzwa">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M8.543 0a8.016 8.016 0 0 1 7.484 7.744l.004.254V16h-1.055v-4.021a8.015 8.015 0 0 1-6.146 3.997L8.543 16v-1.057c3.593-.273 6.433-3.28 6.433-6.943a6.971 6.971 0 0 0-6.194-6.92l-.239-.023V0zM7.49 0v1.057c-3.594.27-6.435 3.28-6.435 6.94 0 3.58 2.716 6.538 6.195 6.92l.238.022v1.057A8.016 8.016 0 0 1 7.49 0zM5.88 9.41a13.023 13.023 0 0 0 1.621 1.28 12.624 12.624 0 0 0 4.034 1.767c-.047.037-.094.075-.146.107a5.655 5.655 0 0 1-3.887 1.1l-.217-.023-.118-.016a5.69 5.69 0 0 1-.783-.172 12.258 12.258 0 0 1-.504-3.026c-.002-.076-.007-.152-.007-.228a12.95 12.95 0 0 1 .008-.789zm-.144-2.682a12.707 12.707 0 0 0-.363 2.185 12.98 12.98 0 0 0-.036 1.299l.009.262a12.788 12.788 0 0 0 .419 2.758 5.66 5.66 0 0 1-2.62-2.267 5.687 5.687 0 0 1-.635-1.46l-.075-.306.028-.03a12.38 12.38 0 0 1 2.256-1.857c.33-.211.67-.406 1.017-.584zm7.903.48a5.715 5.715 0 0 1-1.61 4.811 12.098 12.098 0 0 1-3.823-1.517 12.26 12.26 0 0 0 1.837-.712 12.676 12.676 0 0 0 3.596-2.582zm-3.307-4.405a5.657 5.657 0 0 1 2.473 2.143 5.627 5.627 0 0 1 .706 1.62l-.151.16a12.398 12.398 0 0 1-2.19 1.817c-.299.195-.605.375-.917.54a12.695 12.695 0 0 0 .367-2.04c.05-.513.07-1.029.058-1.544 0-.029-.003-.058-.003-.088a12.874 12.874 0 0 0-.343-2.609zM4.104 3.85a12.105 12.105 0 0 1 3.634 1.51 12.344 12.344 0 0 0-1.798.672 12.652 12.652 0 0 0-1.975 1.141c-.58.41-1.125.867-1.63 1.366-.043-.458-.03-.92.037-1.374A5.649 5.649 0 0 1 4.104 3.85zm3.978-1.544a5.67 5.67 0 0 1 .753.06c.3.045.597.113.887.205.207.766.339 1.55.393 2.342l.016.268.01.268v.063c.008.345 0 .687-.02 1.027a12.785 12.785 0 0 0-2.948-2.117 12.605 12.605 0 0 0-2.56-.997A5.65 5.65 0 0 1 7.566 2.32l.292-.014h.224z"
        fill="url(#eturj5bzwa)"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default CastingAppLogoPlain;
