import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function FacebookIconTwoTone(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7.043 7.043a12.667 12.667 0 1 1 8.997 21.624c.57-.003 1.126-.055 1.675-.13v-9.18h3.278l.514-3.33h-3.793v-1.82c0-1.383.452-2.61 1.746-2.61h2.08V8.691l-.02-.002c-.373-.05-1.144-.155-2.579-.155-3.048 0-4.836 1.61-4.836 5.278v2.215h-3.134v3.33h3.134v9.153c.607.091 1.222.154 1.853.157A12.667 12.667 0 0 1 7.043 7.043z"
        fill="#039BE5"
      />
    </SvgIcon>
  );
}

export default FacebookIconTwoTone;
