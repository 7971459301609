export const CONTRACT_TYPES = [
  { key: 'Commercial', value: 'Commercial' },
  { key: 'Corporate / Educational & Non-Broadcast', value: 'Corporate / Educational & Non-Broadcast' },
  { key: 'Low Budget Film', value: 'Low Budget Film' },
  { key: 'Modified Low Budget Film', value: 'Modified Low Budget Film' },
  { key: 'Music Video', value: 'Music Video' },
  { key: 'New Media', value: 'New Media' },
  { key: 'Short Film', value: 'Short Film' },
  { key: 'Sound Recordings', value: 'Sound Recordings' },
  { key: 'Student Film', value: 'Student Film' },
  { key: 'Television', value: 'Television' },
  { key: 'Theatrical Film', value: 'Theatrical Film' },
  { key: 'Ultra Low Budget Film', value: 'Ultra Low Budget Film' },
];
