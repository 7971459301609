import { computeProgressEvent, IProgressEvent } from '@room-match/shared-utils';
import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { ICommonResponsePayload } from 'shared/interfaces/ICommon';
import {
  ISessionAttachQuestionPayload,
  ISessionAttachQuestionResponsePayload,
  ISessionCreatePayload,
  ISessionCreateResponsePayload,
  ISessionImportResponsePayload,
  ISessionMediumAttachmentRequestPayload,
  ISessionMediumAttachmentResponsePayload,
  ISessionQuestionsResponsePayload,
  ISessionResponsePayload,
  ISessionSortAuditionScheduleGroupPayload,
} from 'shared/interfaces/ISession';

import { IOnProgress } from 'shared/interfaces/utils/IAxios';
import { GET_SESSION_MEDIUM_ATTACHMENTS } from 'shared/queries/studioSession.queries';

const { POST, PUT, GET, PATCH, DELETE } = useAxios();
export const studioSessionDao = () => {
  const getStudioSessions = async (projectId: string) => {
    const response = await GET<ISessionResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/sessions`,
    });
    return response.data;
  };

  const getOneStudioSession = async (projectId: string, sessionId: string) => {
    const response = await GET<ISessionResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/sessions/${sessionId}`,
    });
    return response.data;
  };

  const createStudioSession = async (projectId: string, payload: ISessionCreatePayload) => {
    const response = await POST<ISessionCreateResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/sessions`,
      data: payload,
    });
    return response.data;
  };

  const updateStudioSession = async (projectId: string, sessionId: string, payload: ISessionCreatePayload) => {
    const response = await PATCH<ISessionCreateResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/sessions/${sessionId}`,
      data: payload,
    });
    return response.data;
  };

  const importStudioSession = async (formData: FormData, onProgress?: IOnProgress) => {
    const response = await PUT<ISessionImportResponsePayload>({
      url: ENDPOINTS.PROJECT,
      data: formData,
      onUploadProgress: (progressEvent: IProgressEvent) => {
        if (onProgress) {
          onProgress(computeProgressEvent(progressEvent));
        }
      },
    });

    return response.data;
  };

  const getSessionMediumAttachments = async (payload: ISessionMediumAttachmentRequestPayload) => {
    const response = await POST<ISessionMediumAttachmentResponsePayload>({
      url: ENDPOINTS.GRAPHQL,
      data: {
        query: GET_SESSION_MEDIUM_ATTACHMENTS,
        variables: { ...payload },
      },
    });

    return response.data;
  };

  const attachStudioSessionQuestion = async (
    projectId: string,
    sessionId: string,
    payload: ISessionAttachQuestionPayload,
  ) => {
    const response = await POST<ISessionAttachQuestionResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/sessions/${sessionId}/question_attachments`,
      data: payload,
    });
    return response.data;
  };

  const getStudioSessionQuestions = async (projectId: string, sessionId: string) => {
    const response = await GET<ISessionQuestionsResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/sessions/${sessionId}/question_attachments`,
    });
    return response.data;
  };

  const studioSessionSortAuditionScheduleGroup = async (
    sessionId: string,
    payload: ISessionSortAuditionScheduleGroupPayload,
  ) => {
    const response = await PATCH<ICommonResponsePayload>({
      url: `${ENDPOINTS.SESSION}/${sessionId}/sort_audition_schedule_groups`,
      data: payload,
    });
    return response.data;
  };

  const deleteSession = async (projectId: string, sessionId: string) => {
    const response = await DELETE<ICommonResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/sessions/${sessionId}`,
    });
    return response.data;
  };

  const archiveSession = async (projectId: string, sessionId: string) => {
    const response = await PATCH<ICommonResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/sessions/${sessionId}/archived`,
    });
    return response.data;
  };

  return {
    getStudioSessions,
    getOneStudioSession,
    createStudioSession,
    updateStudioSession,
    importStudioSession,
    getSessionMediumAttachments,
    attachStudioSessionQuestion,
    getStudioSessionQuestions,
    studioSessionSortAuditionScheduleGroup,
    deleteSession,
    archiveSession,
  };
};
