import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    videoWrapper: {
      overflow: 'hidden',
      position: 'relative',
      height: '100%',
      backgroundColor: '#000',
    },
    videoWrapperFullScreen: {
      height: '100vh',
      width: '100vw',
      position: 'fixed',
      left: 0,
      top: 0,
      zIndex: 9998,
    },
    playAction: {
      position: 'absolute',
      left: '50%',
      top: '50%',
      transform: 'translate(-50%,-50%)',
      zIndex: 9000,
    },
    progressAction: {
      position: 'absolute',
      bottom: '0',
      left: 0,
      zIndex: 9001,
      width: '100%',
    },
    controlsContainer: {},
    bottomActions: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    bar1Indeterminate: {
      width: 'auto',
      animation: '$indeterminate1 60s linear forwards',
    },
    bar2Indeterminate: {
      display: 'none',
    },
    '@keyframes indeterminate1': {
      '0%': {
        left: '-35%',
        right: '100%',
      },
      '100%': {
        left: '0%',
        right: '0%',
      },
    },
  }),
);
