import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const ArrowExpand = (props: SvgIconProps) => {
  return (
    <SvgIcon width="30" height="14" viewBox="0 0 30 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M30 2.125H15.938v8.05l4.024-4.025 1.326 1.325-5.625 5.625a.936.936 0 0 1-1.326 0L8.712 7.475l1.326-1.325 4.025 4.024v-8.05H0V.25h30v1.875z"
        fill={props.htmlColor || '#fff'}
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default ArrowExpand;
