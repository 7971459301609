import React, { Fragment } from 'react';
import { Typography, Popover } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { InfoOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: theme.spacing(1),
  },
}));

export type Props = {
  password: string;
};

const PasswordStrength: React.FC<Props> = ({ password }) => {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const successColor = '#31ab37';
  const initColor = 'unset';

  const handlePopoverOpen = (event: any) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };

  const hasLowerCase = (str: string) => {
    const hasLC = /[a-z]/.test(str) ? successColor : initColor;
    return hasLC;
  };

  const hasUpperCase = (str: string) => {
    const hasUC = /[A-Z]/.test(str) ? successColor : initColor;
    return hasUC;
  };

  const hasNumber = (str: string) => {
    const hasNum = /[0-9]/.test(str) ? successColor : initColor;
    return hasNum;
  };

  const hasSpecialCharacter = (str: string) => {
    const hasSC = /[`!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?~]/.test(str) ? successColor : initColor;
    return hasSC;
  };

  return (
    <Fragment>
      <Typography variant="subtitle2" style={{ marginTop: '10px', marginBottom: '5px' }}>
        Your password must be at least 8 characters in length and include:
      </Typography>
      <Typography variant="body2" style={{ color: hasLowerCase(password) }}>
        • &nbsp;a minimum of 1 lower case letter [a-z]
      </Typography>
      <Typography variant="body2" style={{ color: hasUpperCase(password) }}>
        • &nbsp;a minimum of 1 upper case letter [A-Z]
      </Typography>
      <Typography variant="body2" style={{ color: hasNumber(password) }}>
        • &nbsp;a minimum of 1 numeric character [0-9]
      </Typography>
      <Typography variant="body2" style={{ color: hasSpecialCharacter(password) }}>
        • &nbsp;a minimum of 1 special character
        <span
          aria-owns={open ? 'mouse-over-popover' : undefined}
          aria-haspopup="true"
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
        >
          <InfoOutlined
            color="primary"
            style={{ width: '20px', height: '20px', marginBottom: '-6px', marginLeft: '3px' }}
          />
        </span>
      </Typography>
      <Popover
        id="mouse-over-popover"
        className={classes.popover}
        classes={{
          paper: classes.paper,
        }}
        open={open}
        anchorEl={anchorEl}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'left',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'left',
        }}
        onClose={handlePopoverClose}
        disableRestoreFocus
      >
        <div>
          <Typography variant="body2">Special Characters:</Typography>
          <Typography variant="body2">{'~`!@#$%^&*()-_+={}[]|;:"<>,./?'}</Typography>
        </div>
      </Popover>
    </Fragment>
  );
};

export default PasswordStrength;
