import { QueryKey, useQuery, UseQueryOptions } from 'react-query';
import { REACT_QUERIES_KEYS } from 'shared/constants/REACT_QUERIES_KEYS';
import { profileLinkDao } from 'shared/dao/profileLinkDao';
import { IProfileResponsePayload } from 'shared/interfaces/IProfile';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';

const { getProfileLink: getProfileLinkDao } = profileLinkDao();
export const profileLinkService = () => {
  const getProfileLink = (
    token: string,
    options?: Omit<
      UseQueryOptions<IProfileResponsePayload, IAxiosErrorResponse, IProfileResponsePayload, QueryKey>,
      'queryKey' | 'queryFn'
    >,
  ) => {
    return useQuery<IProfileResponsePayload, IAxiosErrorResponse>(
      [REACT_QUERIES_KEYS.PROFILE_LINK, token],
      () => getProfileLinkDao(token),
      options,
    );
  };

  return {
    getProfileLink,
  };
};
