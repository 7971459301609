import { Button, ButtonProps } from '@material-ui/core';
import React from 'react';
import { useStyles } from './CallBackButton.styles';

const CallBackButton: React.FC<ButtonProps> = ({ ...props }) => {
  const classes = useStyles();
  return (
    <Button classes={{ root: classes.root, ...props.classes }} {...props}>
      CB
    </Button>
  );
};

export default CallBackButton;
