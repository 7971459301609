import { Dialog, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const StyledDialog = withStyles((theme: Theme) => ({
  paper: {
    backgroundColor: '#0B1017',
    borderStyle: 'solid',
    borderWidth: '1px',
    borderImageSource: 'linear-gradient(253deg, #d51bff, #00d4ff)',
    borderImageSlice: '1',
    backgroundImage: 'linear-gradient(to bottom, #0e1319, #0e1319), linear-gradient(253deg, #d51bff, #00d4ff)',
    backgroundOrigin: 'border-box',
    backgroundClip: 'content-box, border-box',
    borderRadius: '8px !important',
  },
}))(Dialog);

export default StyledDialog;
