import {
  QueryKey,
  useInfiniteQuery,
  UseInfiniteQueryOptions,
  useMutation,
  useQuery,
  UseQueryOptions,
} from 'react-query';
import { REACT_QUERIES_KEYS } from 'shared/constants/REACT_QUERIES_KEYS';
import { auditionDao } from 'shared/dao/auditionDao';
import {
  IAuditionMutateResponsePayload,
  IAuditionPriorityResponsePayload,
  IAuditionPriorityUpdatePayload,
  IAuditionRequestPayload,
  IAuditionResponsePayload,
  IAuditionUpdatePayload,
  IWorksheetAuditionRequestPayload,
  IWorksheetAuditionResponsePayload,
  IAuditionActivitiesResponsePayload,
} from 'shared/interfaces/IAudition';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';

const {
  getAuditions: getAuditionsDao,
  getWorksheetAuditions: getWorksheetAuditionsDao,
  getAuditionActivities: getAuditionActivitiesDao,
  updateAudition: updateAuditionDao,
  setAuditionPriority: setAuditionPriorityDao,
} = auditionDao();
export const auditionService = () => {
  const getAuditions = (
    payload: IAuditionRequestPayload,
    options?: Omit<
      UseQueryOptions<IAuditionResponsePayload, IAxiosErrorResponse, IAuditionResponsePayload, QueryKey>,
      'queryKey' | 'queryFn'
    >,
  ) => {
    return useQuery<IAuditionResponsePayload, IAxiosErrorResponse>(
      [REACT_QUERIES_KEYS.AUDITION, payload],
      () => getAuditionsDao(payload),
      options,
    );
  };

  const getWorksheetAuditions = (
    payload: IWorksheetAuditionRequestPayload,
    options?: Omit<
      UseInfiniteQueryOptions<
        IWorksheetAuditionResponsePayload,
        IAxiosErrorResponse,
        IWorksheetAuditionResponsePayload,
        QueryKey
      >,
      'queryKey' | 'queryFn'
    >,
  ) => {
    return useInfiniteQuery<IWorksheetAuditionResponsePayload, IAxiosErrorResponse>(
      [REACT_QUERIES_KEYS.AUDITION, payload],
      ({ pageParam = '' }) => getWorksheetAuditionsDao({ ...payload, after: pageParam }),
      {
        getNextPageParam: (lastPage) => {
          return lastPage.data.worksheet_connection.pageInfo.hasNextPage
            ? lastPage.data.worksheet_connection.pageInfo.endCursor
            : undefined;
        },
        enabled: options?.enabled,
      },
    );
  };

  const getAuditionAtivities = (auditionId: string) => {
    return useQuery<IAuditionActivitiesResponsePayload, IAxiosErrorResponse>(['auditionActivities', auditionId], () =>
      getAuditionActivitiesDao(auditionId),
    );
  };

  const updateAudition = () => {
    return useMutation<
      IAuditionMutateResponsePayload,
      IAxiosErrorResponse,
      { auditionId: string; payload: IAuditionUpdatePayload }
    >(({ auditionId, payload }) => updateAuditionDao(auditionId, payload));
  };

  const setAuditionPriority = () => {
    return useMutation<
      IAuditionPriorityResponsePayload,
      IAxiosErrorResponse,
      { auditionId: string; payload: IAuditionPriorityUpdatePayload }
    >(({ auditionId, payload }) => setAuditionPriorityDao(auditionId, payload));
  };

  return {
    getAuditions,
    getWorksheetAuditions,
    getAuditionAtivities,
    updateAudition,
    setAuditionPriority,
  };
};
