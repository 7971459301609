import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function ProjectDetails(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 17 10" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M15.833 1.111H.556A.557.557 0 0 1 0 .556C0 .25.25 0 .556 0h15.277c.306 0 .556.25.556.556 0 .305-.25.555-.556.555zM8.056 5.556h-7.5A.557.557 0 0 1 0 5c0-.306.25-.556.556-.556h7.5c.305 0 .555.25.555.556 0 .306-.25.556-.555.556zm0 4.444h-7.5A.557.557 0 0 1 0 9.444c0-.305.25-.555.556-.555h7.5c.305 0 .555.25.555.555 0 .306-.25.556-.555.556z"
          fill="#FFF"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default ProjectDetails;
