import { Button, Grid, styled, Theme } from '@material-ui/core';

export const ModalContainer = styled('div')(() => ({}));

export const ModalHeader = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 2),
  borderBottom: `1px solid ${theme.palette.primary.main}`,
  display: 'flex',
  justifyContent: 'space-between',
  gap: theme.spacing(2),
  alignItems: 'center',
}));

export const ModalContent = styled('div')(({ theme }) => ({
  [theme.breakpoints.down('sm')]: {
    backgroundColor: 'red',
  },
}));

export const ModalSidebar = styled('div')(({ theme }) => ({
  borderRight: `1px solid ${theme.palette.primary.main}`,
  padding: theme.spacing(3),
  height: '100%',
}));

export const SkillListContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(3, 2),
}));

export const SelectedSkillContainer = styled('div')(({ theme }) => ({
  backgroundColor: '#18212e',
  padding: theme.spacing(1, 2),
  borderRadius: 4,
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
}));

export const SkillCategoriesContainer = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(3),
}));

export const CategoriesButtonList = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  flexDirection: 'column',
  gap: 12,
}));

export const CategoriesButton = styled(Button)<Theme, { $active?: boolean }>(({ theme, $active }) => ({
  backgroundColor: $active ? '#101620' : '#18212e',
  color: '#fff',
  border: `1px solid ${$active ? theme.palette.primary.main : 'transparent'}`,
  textAlign: 'left',
  display: 'block',
  '&:hover': {
    backgroundColor: '#18212e',
    border: `1px solid ${theme.palette.primary.main}`,
  },
}));

export const SelectedCategoryTitleContainer = styled('div')(() => ({}));

export const SubGroupList = styled('div')(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

export const SubGroupItem = styled('div')(() => ({}));

export const SubGroupTitleContainer = styled('div')(({ theme }) => ({
  padding: theme.spacing(2, 0),
}));

export const SkillListGrid = styled(Grid)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));
