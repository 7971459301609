import { Button, DialogContent } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import { Datepicker as MobiScrollDatePicker } from '@mobiscroll/react';
import { useStyles } from './TimerPickerComp.styles';
import '@mobiscroll/react/dist/css/mobiscroll.min.css';
import { StyledDialog, Typography } from 'shared/elements';
import { TimezoneSelect } from 'components/TimezoneSelect';

type Props = {
  open: boolean;
  onClose: () => void;
  onChooseTime?: (value: string, timezone?: string) => void;
  isShowTimezone?: boolean;
  handleSetInputText: (value: string) => void;
  defaultValue?: string | undefined;
  timezoneValue?: string;
};
const DatePickerComp: React.FC<Props> = ({
  open,
  onClose,
  onChooseTime,
  handleSetInputText,
  defaultValue,
  isShowTimezone,
  timezoneValue,
}) => {
  const classes = useStyles();

  const [valueTime, setValueTime] = useState<string | undefined>(undefined);
  const [timezone, setTimeZone] = useState<string>(Intl.DateTimeFormat().resolvedOptions().timeZone);

  useEffect(() => {
    if (defaultValue) {
      setValueTime(defaultValue);
    }
  }, [defaultValue]);

  useEffect(() => {
    if (timezoneValue) {
      setTimeZone(timezoneValue);
    }
  }, [timezoneValue]);

  const handleChooseDate = () => {
    if (valueTime) {
      if (onChooseTime) {
        onChooseTime(valueTime, timezone);
        handleSetInputText(valueTime);
        onClose();
      }
    }
  };

  const handleChangeTimezone = (tz: string) => {
    setTimeZone(tz);
  };

  return (
    <StyledDialog classes={{ paper: classes.paper }} open={open} onClose={onClose} maxWidth="sm" fullWidth>
      <DialogContent className={classes.dialogContent}>
        <div className={classes.titleContainer}>
          <Typography fontSize={20}>Select Time</Typography>
        </div>

        <MobiScrollDatePicker
          theme="material"
          controls={['time']}
          defaultSelection={valueTime}
          display="inline"
          onChange={(e) => {
            const { valueText } = e as { valueText: string };

            setValueTime(valueText);
          }}
          inputProps={{
            placeholder: 'Please Select...',
          }}
          themeVariant="dark"
          className={classes.datePicker}
        />
        {isShowTimezone && (
          <div className={classes.timezoneContainer}>
            <div className={classes.timezoneWrapper}>
              <TimezoneSelect value={timezone} onChange={handleChangeTimezone} fullWidth />
            </div>
          </div>
        )}

        <div className={classes.buttonContainer}>
          <Button color="primary" variant="contained" onClick={() => handleChooseDate()}>
            Choose Time
          </Button>
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

export default React.memo(DatePickerComp, (prevProps, nextProps) => prevProps.open === nextProps.open);
