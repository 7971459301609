import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IAuditionAttributes } from 'shared/interfaces/IAudition';
import { RootState } from '../store';

export interface AuditionState {
  selectedAudition: Array<IAuditionAttributes>;
}

export const initialState: AuditionState = {
  selectedAudition: [],
};

export const auditionSlicer = createSlice({
  name: 'audition',
  initialState,
  reducers: {
    addNewSelectedAudition: (
      state: AuditionState,
      action: PayloadAction<{ audition: IAuditionAttributes; isChecked: boolean }>,
    ) => {
      const { audition, isChecked } = action.payload;
      if (isChecked) {
        state.selectedAudition = [...state.selectedAudition, audition];
      } else {
        const filteredSelectedAudition = state.selectedAudition.filter((sa) => sa.id !== audition.id);
        state.selectedAudition = filteredSelectedAudition;
      }
    },

    clearSelectedAudition: (state: AuditionState) => {
      state.selectedAudition = [];
    },
  },
});
export const selectAuditionState = (state: RootState) => state.audition;
export const { addNewSelectedAudition, clearSelectedAudition } = auditionSlicer.actions;

export default auditionSlicer.reducer;
