import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Company(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg width="28" height="25" viewBox="0 0 28 25" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m14.813 1.627.006.106V4.92h-1.782V2.896l-9.366 2.52v17.643h9.366V7.6c0-.455.34-.83.779-.885l.111-.007 11.094-.013c.448 0 .829.345.885.78l.007.111v10.722H24.13v-9.83l-9.312.012v14.57h12.983v1.783H0V23.06h1.89V4.732c0-.363.218-.685.546-.822l.113-.039L13.697.872a.902.902 0 0 1 1.116.755zm11.1 18.285v1.661H24.13v-1.66h1.782zm-15.435-1.01v1.783H6.23v-1.783h4.247zm11.093 0v1.783h-4.247v-1.783h4.247zm-11.093-4.055v1.783H6.23v-1.783h4.247zm11.093 0v1.783h-4.247v-1.783h4.247zm-11.093-4.055v1.783H6.23v-1.783h4.247zm11.093 0v1.783h-4.247v-1.783h4.247zM10.478 6.737V8.52H6.23V6.737h4.247z"
          fill="#838D9C"
          fillRule="evenodd"
        />
      </svg>
    </SvgIcon>
  );
}

export default Company;
