import { FormikProps, useFormik } from 'formik';
import moment from 'moment-timezone';
import { useEffect } from 'react';
import { IRoleRequestSelfTapesRequirement } from 'shared/interfaces/IRole';
import { addZeroInTime } from 'shared/utils/addZeroInTime';
import { convertTime24to12 } from 'shared/utils/convertTime12to24';
import { array, boolean, date, object, SchemaOf, string } from 'yup';

type SelfTapesRequirementProps = {
  onSubmit: (values: IRoleRequestSelfTapesRequirement) => void;
  selfTapesRequirement: IRoleRequestSelfTapesRequirement | null;
};
export const useSelfTapesRequirementForm = ({ onSubmit, selfTapesRequirement }: SelfTapesRequirementProps) => {
  const validationSchema: SchemaOf<IRoleRequestSelfTapesRequirement> = object().shape({
    date: date().required('Date is required'),
    time: string().required('Time is required'),
    audition_selfie: boolean().default(false),
    full_body: boolean().default(false),
    slate: boolean().default(false),
    other_images: array().min(0),
    video: array().min(0),
    audio: array().min(0),
    files: array().min(0),
    selections: string().default(''),
    timezone: string(),
  });

  const initialValues: IRoleRequestSelfTapesRequirement = {
    date: selfTapesRequirement && selfTapesRequirement.date ? selfTapesRequirement.date : null,
    time: selfTapesRequirement && selfTapesRequirement.time ? selfTapesRequirement.time : null,
    audition_selfie:
      selfTapesRequirement && selfTapesRequirement.audition_selfie ? selfTapesRequirement.audition_selfie : false,
    full_body: selfTapesRequirement && selfTapesRequirement.full_body ? selfTapesRequirement.full_body : false,
    slate: selfTapesRequirement && selfTapesRequirement.slate ? selfTapesRequirement.audition_selfie : false,
    other_images: selfTapesRequirement && selfTapesRequirement.other_images ? selfTapesRequirement.other_images : [],
    video: selfTapesRequirement && selfTapesRequirement.video ? selfTapesRequirement.video : [],
    audio: selfTapesRequirement && selfTapesRequirement.audio ? selfTapesRequirement.audio : [],
    files: selfTapesRequirement && selfTapesRequirement.files ? selfTapesRequirement.files : [],
    selections: selfTapesRequirement && selfTapesRequirement.selections ? selfTapesRequirement.selections : '',
    timezone:
      selfTapesRequirement && selfTapesRequirement.timezone
        ? selfTapesRequirement.timezone
        : Intl.DateTimeFormat().resolvedOptions().timeZone,
  };

  const form: FormikProps<IRoleRequestSelfTapesRequirement> = useFormik({
    initialValues,
    validationSchema,
    enableReinitialize: true,
    onSubmit: (values) => {
      onSubmit(values);
    },
  });

  useEffect(() => {
    if (selfTapesRequirement && selfTapesRequirement.date) {
      // const parsedDateString = moment
      //   .tz(selfTapesRequirement.date, Intl.DateTimeFormat().resolvedOptions().timeZone)
      //   .format('YYYY-MM-DD HH:mm:ss');
      const parsedDateString = selfTapesRequirement.date;
      const convertedDate = new Date(parsedDateString);
      const hours = addZeroInTime(convertedDate.getHours());
      const minutes = addZeroInTime(convertedDate.getMinutes());
      const converted12Hrs = convertTime24to12(`${hours}:${minutes}`);
      form.setFieldValue('date', new Date(convertedDate.toDateString()));
      form.setFieldValue('time', converted12Hrs);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selfTapesRequirement]);

  return {
    form,
  };
};
