import { Button, Grid, IconButton } from '@material-ui/core';
import { CloseIcon, CustomVideoPlayer, PlayIconTwoTone, UploadIconTwoTone } from '@room-match/shared-ui-components';
import { PlayIcon } from 'components/Icons';
import { ImageCarousel } from 'components/ImageCarousel';
import React, { useEffect, useState, useMemo } from 'react';
import { useStyles } from './AuditionGroupExpandingContent.styles';
import { MediumTabAction } from 'shared/enums/MediumTabAction';
import { IMediumAttributes } from 'shared/interfaces/IMedium';
import AuditionScheduleCommentTab from './AuditionScheduleCommentTab';
import { ISelectMediaButtonProps } from 'shared/interfaces/utils/ISelectMediaButtonProps';
import { COLORS } from 'shared/constants/COLORS';
import { IAuditionScheduleAttributes } from 'shared/interfaces/IAuditionSchedule';
import { getGroupPrimaryImage, getMediaImagesPerGroup, getScenesPerGroup } from 'shared/utils/auditionMediaAttachments';
import { uploadService } from 'shared/services/uploadService';
import { Image } from 'components/Image';
import { IAuditionScheduleGroupAttributes } from 'shared/interfaces/IAuditionScheduleGroup';

import { useGetThumbnail } from 'shared/hooks/useGetThumbnail';

type Props = {
  isShowActionButtons?: boolean;
  isImageSlider?: boolean;
  auditionGroup: IAuditionScheduleGroupAttributes;
  mediums?: IMediumAttributes[];
};

const AuditionGroupExpandingContent: React.FC<Props> = ({ auditionGroup, mediums }) => {
  const classes = useStyles();
  const [selectedAttachments, setSelectedAttachments] = useState<{
    type: string;
    attachments: IMediumAttributes[];
  } | null>(null);
  const [scenes, setScenes] = useState<IMediumAttributes[]>([]);
  const [images, setImages] = useState<IMediumAttributes[]>([]);

  const [selectedMediaButton, setSelectedMediaButton] = useState<ISelectMediaButtonProps | null>(null);
  const { thumbnail, handleChangeVideoUrl, isErrorThumbnail } = useGetThumbnail();

  const handleSelectedMediumTab = (mediums: IMediumAttributes[], audition_schedule: IAuditionScheduleAttributes) => {
    if (mediums && mediums.length > 0) {
      const getSceneMediums = getScenesPerGroup(mediums, auditionGroup.id);
      const getImagesMediums = getMediaImagesPerGroup(mediums, auditionGroup.id);
      setScenes(getSceneMediums);
      setImages(getImagesMediums);
      const getFirstSceneMediums = getSceneMediums[0];
      if (getFirstSceneMediums && getFirstSceneMediums.tag_list.length > 0) {
        setSelectedAttachments({ type: getFirstSceneMediums.tag_list[0], attachments: [getFirstSceneMediums] });
        handleChangeVideoUrl(getFirstSceneMediums.attachment);
        return;
      }
    } else if (
      audition_schedule &&
      audition_schedule.medium_attachments &&
      audition_schedule.medium_attachments.length > 0
    ) {
      const getFirstMedium = audition_schedule.medium_attachments[0];
      if (getFirstMedium.tag_list.length > 0) {
        setSelectedAttachments({ type: getFirstMedium.tag_list[0], attachments: [getFirstMedium] });
        handleChangeVideoUrl(getFirstMedium.attachment);
        return;
      }
    }
  };

  useEffect(() => {
    if (mediums && mediums.length > 0) {
      const getSceneMediums = getScenesPerGroup(mediums, auditionGroup.id);
      const getImagesMediums = getMediaImagesPerGroup(mediums, auditionGroup.id);
      setScenes(getSceneMediums);
      setImages(getImagesMediums);
      const getFirstSceneMediums = getSceneMediums[0];
      if (getFirstSceneMediums && getFirstSceneMediums.tag_list.length > 0) {
        setSelectedAttachments({ type: getFirstSceneMediums.tag_list[0], attachments: [getFirstSceneMediums] });
        handleChangeVideoUrl(getFirstSceneMediums.attachment);
        return;
      }
    } else if (auditionGroup && auditionGroup.medium_attachments && auditionGroup.medium_attachments.length > 0) {
      const getFirstMedium = auditionGroup.medium_attachments[0];
      if (getFirstMedium.tag_list.length > 0) {
        setSelectedAttachments({ type: getFirstMedium.tag_list[0], attachments: [getFirstMedium] });
        handleChangeVideoUrl(getFirstMedium.attachment);
        return;
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleSelectedMediaButton = (mediaButton: ISelectMediaButtonProps) => {
    setSelectedMediaButton(mediaButton);

    if (mediaButton.type === 'image') {
      setSelectedAttachments({
        type: 'Images',
        attachments: mediaButton.attachments.length > 0 ? [...mediaButton.attachments] : [],
      });
      handleChangeVideoUrl('');
    } else {
      if (mediaButton.attachments.length > 0) {
        setSelectedAttachments({
          type: mediaButton.attachments[0].tag_list[0],
          attachments: [mediaButton.attachments[0]],
        });
        handleChangeVideoUrl(mediaButton.attachments[0].attachment);
      } else {
        setSelectedAttachments(null);
        handleChangeVideoUrl('');
      }
    }
  };

  const handleDeselectMediaButton = () => {
    setSelectedMediaButton(null);
    if (scenes.length > 0) {
      setSelectedAttachments({ type: scenes[0].tag_list[0], attachments: [scenes[0]] });
      handleChangeVideoUrl(scenes[0].attachment);
    } else {
      setSelectedAttachments({ type: 'Images', attachments: images });
      handleChangeVideoUrl('');
    }
  };

  const handleMediumTabActionChange = (mediumTabAction: string, attachments: IMediumAttributes[]) => {
    if (selectedAttachments?.type !== mediumTabAction) {
      setSelectedAttachments({ type: mediumTabAction, attachments });
      handleChangeVideoUrl(attachments[0].attachment);
    }
  };

  return (
    <div className={classes.expandedDiv}>
      {/* Content */}
      <Grid container spacing={4}>
        {/* Left Player */}
        <Grid item lg={7}>
          <div className={classes.expandedDiv__leftContainer}>
            <div className={classes.expandedDiv__mediaContainer}>
              {selectedMediaButton && (
                <div className={classes.expandedDiv__closeSelectedMediaButton}>
                  <IconButton onClick={() => handleDeselectMediaButton()}>
                    <CloseIcon htmlColor={COLORS.DANGER} />
                  </IconButton>
                </div>
              )}
              {selectedAttachments && (
                <React.Fragment>
                  {selectedAttachments.type.toLocaleLowerCase().startsWith('image') ? (
                    <React.Fragment>
                      {selectedAttachments.attachments.length > 0 && (
                        <React.Fragment>
                          {selectedMediaButton ? (
                            <div className={classes.singleImageContainer}>
                              <Image
                                src={selectedAttachments.attachments[0].attachment}
                                className={classes.singleImage}
                              />
                            </div>
                          ) : (
                            <ImageCarousel images={selectedAttachments.attachments} />
                          )}
                        </React.Fragment>
                      )}
                    </React.Fragment>
                  ) : (
                    <div className={classes.expandedDiv__videoPlayerContainer}>
                      <CustomVideoPlayer
                        url={selectedAttachments?.attachments[0].attachment || ''}
                        thumbnail={
                          !isErrorThumbnail ? thumbnail : getGroupPrimaryImage(mediums || [], auditionGroup.id || '')
                        }
                        playIcon={
                          <PlayIcon style={{ fontSize: 48, position: 'absolute', right: '2%', bottom: '3%' }} />
                        }
                        key={Math.random().toString()}
                      />
                    </div>
                  )}
                </React.Fragment>
              )}
            </div>

            {!selectedMediaButton ? (
              <div className={classes.expandedDiv__sceneActionContainer}>
                {scenes.map((scene, index) => (
                  <Button
                    key={scene.id}
                    variant={selectedAttachments?.type !== scene.tag_list[0] ? 'outlined' : 'contained'}
                    color="primary"
                    startIcon={<PlayIconTwoTone classes={{ root: classes.buttonIcon }} />}
                    onClick={() => handleMediumTabActionChange(scene.tag_list[0], [scene])}
                  >
                    {`Video ${index + 1}`}
                  </Button>
                ))}
                {images.length > 0 && (
                  <Button
                    variant={selectedAttachments?.type !== MediumTabAction.IMAGES ? 'outlined' : 'contained'}
                    color="primary"
                    style={{ color: '#fff' }}
                    startIcon={<UploadIconTwoTone classes={{ root: classes.buttonIcon }} />}
                    onClick={() => handleMediumTabActionChange(MediumTabAction.IMAGES, images)}
                  >
                    Images
                  </Button>
                )}
              </div>
            ) : (
              <div className={classes.expandedDiv__sceneActionContainer}>
                {selectedMediaButton.type === 'image' ? (
                  <Button
                    variant={selectedAttachments?.type !== MediumTabAction.IMAGES ? 'outlined' : 'contained'}
                    color="primary"
                    style={{ color: '#fff' }}
                    startIcon={<UploadIconTwoTone classes={{ root: classes.buttonIcon }} />}
                    onClick={() => handleMediumTabActionChange(MediumTabAction.IMAGES, selectedMediaButton.attachments)}
                  >
                    Images
                  </Button>
                ) : (
                  <React.Fragment>
                    {selectedMediaButton.attachments.map((attachment) => (
                      <Button
                        variant={selectedAttachments?.type !== attachment.tag_list[0] ? 'outlined' : 'contained'}
                        color="primary"
                        startIcon={<PlayIconTwoTone classes={{ root: classes.buttonIcon }} />}
                        onClick={() => handleMediumTabActionChange(attachment.tag_list[0], [attachment])}
                      >
                        {attachment.tag_list[0]}
                      </Button>
                    ))}
                  </React.Fragment>
                )}
              </div>
            )}
            {/* Scene Action */}
          </div>
        </Grid>
        {/* Comments Section */}
        <Grid item lg={5}>
          <div className={classes.groupedComments__container}>
            {auditionGroup.audition_schedules.map((audition_schedule) => (
              <AuditionScheduleCommentTab
                key={audition_schedule.id}
                audition_schedule={audition_schedule}
                handleSelectedMediumTab={handleSelectedMediumTab}
                handleSelectedMediaButton={handleSelectedMediaButton}
                selectedMediaButton={selectedMediaButton}
                groupId={auditionGroup.id}
              />
            ))}
          </div>
        </Grid>
      </Grid>
    </div>
  );
};

export default AuditionGroupExpandingContent;
