import { Box, IconButton } from '@material-ui/core';
import {
  StickyNoteBlueIconTwoTone,
  ShadedStartBlueIconTwoTone,
  FilledStarIcon,
  ITab,
  StickyNoteIconTwoTone,
  DotsIcon,
  ResumeIcon,
} from '@room-match/shared-ui-components';
import { CallBackButton } from 'components/CallBackButton';
import { NoteTextArea } from 'components/NoteTextArea';
import React, { useMemo, useState } from 'react';
import { NO_IMAGE_PLACEHOLDER_URL } from 'shared/constants/ASSETS_URL';
import { StyledTab, StyledTabs, Typography } from 'shared/elements';
import { IAuditionAttributes } from 'shared/interfaces/IAudition';
import { IComment } from 'shared/interfaces/utils/IComment';
import { getFullName } from 'shared/utils/getFullName';
import { CommentTab } from './CommentTab';

import { useStyles } from './AuditionSingleComment.styles';
import { IMediumAttributes } from 'shared/interfaces/IMedium';
import { getMediaImagesPerGroup, getPrimaryImage } from 'shared/utils/auditionMediaAttachments';
import {
  IAuditionScheduleAttributes,
  IAuditionScheduleUpdatePhaseAndStatusPayload,
} from 'shared/interfaces/IAuditionSchedule';
import { AuditionStatus } from 'shared/enums/AuditionStatus';
import { AuditionPhase } from 'shared/enums/AuditionPhase';
import { useQueryClient } from 'react-query';
import { auditionScheduleService } from 'shared/services/auditionScheduleService';
import { a11yProps } from 'shared/utils/a11yProps';
type Props = {
  isEditMode?: boolean;
  publicNotesComments: IComment[];
  internalNotesComments: IComment[];
  onCreateNote: (rating: number, note: string, flagType?: 'Public Note' | 'Internal Note') => void;
  audition: IAuditionAttributes;
  mediums: IMediumAttributes[];
  handleOpenTalentProfile?: (instance: any, auditionId: string) => void;
  auditionSchedule: IAuditionScheduleAttributes;
  selectedGroupId: string;
  isDisabledComment?: boolean;
  isDisabledCallback?: boolean;
  sessionId: string;
};

const { updateAuditionScheduleStatusAndPhase } = auditionScheduleService();
const AuditionSingleComment: React.FC<Props> = ({
  isEditMode,
  publicNotesComments,
  internalNotesComments,
  onCreateNote,
  audition,
  mediums,
  handleOpenTalentProfile,
  auditionSchedule,
  selectedGroupId,
  isDisabledComment,
  isDisabledCallback,
  sessionId,
}) => {
  const classes = useStyles();

  const [tab, setTab] = useState<string>('public_notes');

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (tab !== newValue) {
      setTab(newValue);
    }
  };

  const queryClient = useQueryClient();
  const { mutate: updateAuditionSchedulePhaseAndStatusMutate } = updateAuditionScheduleStatusAndPhase();

  const onUpdateAudition = (auditionScheduleId: string, status: AuditionStatus, phase: AuditionPhase) => {
    const payload: IAuditionScheduleUpdatePhaseAndStatusPayload = {
      audition_schedules: [
        {
          id: auditionScheduleId,
          status,
          phase,
        },
      ],
    };
    updateAuditionSchedulePhaseAndStatusMutate(
      { payload },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['auditionScheduleGroup'], {
            refetchInactive: true,
          });
          queryClient.invalidateQueries(['clientAuditionSchedule'], {
            refetchInactive: true,
          });
        },
      },
    );
  };

  const tabs = useMemo(() => {
    return [
      {
        name: 'internal_notes',
        header: (
          <Box sx={{ display: 'flex', alignItems: 'center', gridGap: 6 }}>
            <StickyNoteIconTwoTone style={{ fontSize: 16 }} />
            <Typography>Internal Notes</Typography>
          </Box>
        ),
      },
      {
        name: 'public_notes',
        header: (
          <Box sx={{ display: 'flex', alignItems: 'center', gridGap: 6 }}>
            <StickyNoteBlueIconTwoTone style={{ fontSize: 16 }} />
            <Typography>Public Notes</Typography>
          </Box>
        ),
      },
    ];
  }, []);

  function TabPanel(props: any) {
    const { children, value, index, ...other } = props;

    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`wrapped-tabpanel-${index}`}
        aria-labelledby={`wrapped-tab-${index}`}
        {...other}
      >
        {value === index && <>{children}</>}
      </div>
    );
  }

  return (
    <div className={classes.rightContainer}>
      {/* Talent Profie */}
      <div className={classes.expandedDiv__talentContainer}>
        <div className={classes.expandedDiv__imageContainer}>
          <img
            src={getPrimaryImage(
              audition,
              getMediaImagesPerGroup(auditionSchedule.medium_attachments, selectedGroupId),
            )}
            className={classes.expandedDiv__image}
          />
        </div>
        {/* Talent Profile Detail  */}
        <div className={classes.expandedDiv__detailContainer}>
          <div className={classes.expandedDiv__details}>
            <Typography fontWeight={500}>
              {getFullName(audition.profile?.talent?.first_name, audition.profile?.talent?.last_name)}
              <ResumeIcon
                style={{ marginLeft: '5px', fontSize: 16 }}
                onClick={() =>
                  handleOpenTalentProfile ? handleOpenTalentProfile(audition?.profile, audition?.id) : null
                }
              />
            </Typography>
            {/* Talent Profile Icons */}
            <div className={classes.expandedDiv__iconsContainer}>
              <div className={classes.expandedDiv__iconItem}>
                <StickyNoteBlueIconTwoTone classes={{ root: classes.expandedDiv__icon }} />
                <Typography fontSize={16}>{publicNotesComments.length > 0 ? publicNotesComments.length : 0}</Typography>
              </div>
              <div className={classes.expandedDiv__iconItem}>
                <ShadedStartBlueIconTwoTone classes={{ root: classes.expandedDiv__icon }} />
                <Typography fontSize={16}>
                  {publicNotesComments.length > 0 ? publicNotesComments[0].rating : 0}
                </Typography>
              </div>
              {!isDisabledCallback && (
                <CallBackButton
                  disabled={auditionSchedule.status === AuditionStatus.CALLBACK}
                  onClick={() => onUpdateAudition(auditionSchedule.id, AuditionStatus.CALLBACK, AuditionPhase.DECISION)}
                />
              )}
              <IconButton size="small">
                <FilledStarIcon fontSize="small" />
              </IconButton>
            </div>
          </div>

          <div style={{ alignSelf: 'center' }}>
            <IconButton size="small">
              <DotsIcon fontSize="small" />
            </IconButton>
          </div>
        </div>
      </div>
      {!isEditMode && (
        <div className={classes.noteTextAreaContainer}>
          <div className={classes.noteLabel}>
            <StickyNoteBlueIconTwoTone style={{ fontSize: 14 }} />
            <Typography fontSize={14}>Notes</Typography>
          </div>
          {!isDisabledComment && (
            <NoteTextArea
              classes={{ paper: classes.notePaper }}
              onSubmitForm={(rating, note) => onCreateNote(rating, note)}
            />
          )}
        </div>
      )}

      {/* Comment Section */}
      {isEditMode ? (
        <div className={classes.expandedDiv__commentSectionContainer}>
          <StyledTabs value={tab} onChange={handleTabChange} aria-label="simple tabs example" variant="fullWidth">
            {tabs.map((tab, index) => (
              <StyledTab key={index} label={tab.header} value={tab.name} {...a11yProps(index)} />
            ))}
          </StyledTabs>
          <div className={classes.noteTextAreaContainer}>
            <NoteTextArea
              isYellowStar={tab === 'internal_notes'}
              onSubmitForm={(rating, note) =>
                onCreateNote(rating, note, tab === 'internal_notes' ? 'Internal Note' : 'Public Note')
              }
            />
          </div>
          <div className={classes.expandedDiv__commentSection__content}>
            <TabPanel value={tab} index="internal_notes">
              <CommentTab comments={internalNotesComments} isYellowStar />
            </TabPanel>
            <TabPanel value={tab} index="public_notes">
              <CommentTab comments={publicNotesComments} />
            </TabPanel>
          </div>
        </div>
      ) : (
        <CommentTab comments={publicNotesComments} />
      )}
    </div>
  );
};

export default AuditionSingleComment;
