import { Box, IconButton, useMediaQuery, useTheme } from '@material-ui/core';
import {
  CheckBox,
  CheckMarkIconTwoTone,
  DeclineIconTwoTone,
  DragIcon,
  InformationIconTwoTone,
  LikeIconTwoTone,
  RequestedIconTwoTone,
} from '@room-match/shared-ui-components';
import clsx from 'clsx';
import React from 'react';
import { Typography } from 'shared/elements';
import { AuditionStatus } from 'shared/enums/AuditionStatus';
import { SelfTapesTab } from 'shared/enums/SelfTapesTab';
import { IAuditionAttributes } from 'shared/interfaces/IAudition';
import { getMainAuditionSchedule } from 'shared/utils/getMainAuditionSchedule';
import { useStyles } from './SelfTapesTabsAction.styles';

type Props = {
  tab: SelfTapesTab;
  handleAddSelectedTalentAudition: (audition: IAuditionAttributes, isChecked: boolean) => void;
  audition: IAuditionAttributes;
  handleSingleUpdateStatus: (auditionScheduleId: string, status: AuditionStatus) => void;
};
const SelfTapesTabsActions: React.FC<Props> = ({
  tab,
  handleAddSelectedTalentAudition,
  audition,
  handleSingleUpdateStatus,
}) => {
  const classes = useStyles();
  const theme = useTheme();
  const isLargeScreen = useMediaQuery(theme.breakpoints.up('xl'));

  const mainAuditionSchedule = getMainAuditionSchedule(audition.audition_schedules);

  return (
    <Box
      className={clsx(classes.requests__action, {
        [classes.requests__action__approvedAction]: true,
      })}
    >
      {tab === SelfTapesTab.NEW && (
        <Box className={classes.requests__action__new}>
          <CheckBox
            size="small"
            onChange={(e) => {
              handleAddSelectedTalentAudition(audition, e.target.checked);
            }}
          ></CheckBox>
        </Box>
      )}

      {tab === SelfTapesTab.CD_APPROVAL && (
        <Box className={classes.requests__action__review}>
          <CheckBox
            size="small"
            onChange={(e) => {
              handleAddSelectedTalentAudition(audition, e.target.checked);
            }}
          ></CheckBox>
        </Box>
      )}

      {tab === SelfTapesTab.HIDDEN && (
        <Box className={classes.requests__action__rejected}>
          <IconButton
            size="small"
            style={{ border: '1px solid #43ba01', padding: '8px ', borderRadius: 4 }}
            onClick={() => handleSingleUpdateStatus(mainAuditionSchedule.id, AuditionStatus.APPROVED)}
          >
            <LikeIconTwoTone style={{ fontSize: isLargeScreen ? 18 : 14 }} />
          </IconButton>
        </Box>
      )}

      {tab === SelfTapesTab.APPROVED && (
        <Box className={classes.requests__action__approved}>
          <Box className={classes.requests__action__approved__dragIcon}>
            <IconButton size="small">
              <DragIcon fontSize="small" />
            </IconButton>
          </Box>
          <Box className={classes.requests__action__approved__likeIcon}>
            <IconButton size="small" disabled>
              <LikeIconTwoTone fontSize="small" />
            </IconButton>
          </Box>
        </Box>
      )}

      {tab === SelfTapesTab.REQUESTED && (
        <Box className={classes.requests__action__item}>
          <RequestedIconTwoTone fontSize="small" />
          <Typography fontSize={14}>4h 23m</Typography>
        </Box>
      )}

      {tab === SelfTapesTab.CONFIRMED && (
        <Box className={classes.requests__action__item}>
          <CheckMarkIconTwoTone fontSize="small" />
          <Typography fontSize={14}>15m</Typography>
        </Box>
      )}

      {tab === SelfTapesTab.DECLINED && (
        <Box className={classes.requests__action__item}>
          <DeclineIconTwoTone fontSize="small" />
          <Typography fontSize={14}>16m</Typography>
        </Box>
      )}

      {tab === SelfTapesTab.NO_RESPONSe && (
        <Box className={classes.requests__action__item}>
          <InformationIconTwoTone fontSize="small" />
          <Typography fontSize={14}>4h 23m</Typography>
        </Box>
      )}
    </Box>
  );
};

export default SelfTapesTabsActions;
