export const generateGridForExpanded = <T = any>(
  data: T[],
  gridSize = 4,
): Array<T & { grid: string; show: boolean }> => {
  const mappedGrid = data.reduce((acc: any, curr, index) => {
    let result = acc;
    if (index === data.length - 1) {
      // Last Item

      result = [...result, { ...curr, grid: 'item' }];
      result = [...result, { ...curr, grid: 'expanded', show: false }];
    } else if (index % gridSize !== 0) {
      result = [...result, { ...curr, grid: 'item' }];
    } else {
      result = [...result, { ...curr, grid: 'expanded', show: false }];
      result = [...result, { ...curr, grid: 'item' }];
    }
    return result;
  }, []);

  return mappedGrid;
};
