import { useMutation, useQuery } from 'react-query';
import { staffDao } from 'shared/dao/staffDao';
import { StaffType } from 'shared/enums/StaffType';
import {
  IStaffResponsePayload,
  ICreateStaffPayload,
  ICreateStaffResponsePayload,
  IStaffArchiveResponsePayload,
} from 'shared/interfaces/IStaff';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';

const {
  getStaff: getStaffDao,
  createStaff: createStaffDao,
  updateStaff: updateStaffDao,
  archiveStaff: archiveStaffDao,
  unarchiveStaff: unarchiveStaffDao,
  updateStaffPermissions: updateStaffPermissionsDao,
} = staffDao();
export const staffService = () => {
  const getStaff = (type?: StaffType) => {
    return useQuery<IStaffResponsePayload, IAxiosErrorResponse>(['staff', type], () => getStaffDao(type));
  };

  const createStaff = () => {
    return useMutation<ICreateStaffResponsePayload, IAxiosErrorResponse, { payload: ICreateStaffPayload }>(
      ({ payload }) => createStaffDao(payload),
    );
  };

  const updateStaff = () => {
    return useMutation<
      ICreateStaffResponsePayload,
      IAxiosErrorResponse,
      { staffId: string; payload: ICreateStaffPayload }
    >(({ staffId, payload }) => updateStaffDao(staffId, payload));
  };

  const archiveStaff = () => {
    return useMutation<IStaffArchiveResponsePayload, IAxiosErrorResponse, { staffId: string }>(({ staffId }) =>
      archiveStaffDao(staffId),
    );
  };

  const unarchiveStaff = () => {
    return useMutation<IStaffArchiveResponsePayload, IAxiosErrorResponse, { staffId: string }>(({ staffId }) =>
      unarchiveStaffDao(staffId),
    );
  };

  const updateStaffPermissions = () => {
    return useMutation<ICreateStaffResponsePayload, IAxiosErrorResponse, { staffId: string; payload: string[] }>(
      ({ staffId, payload }) => updateStaffPermissionsDao(staffId, payload),
    );
  };

  return {
    getStaff,
    createStaff,
    updateStaff,
    archiveStaff,
    unarchiveStaff,
    updateStaffPermissions,
  };
};
