export const getTimeAgo = (date: Date) => {
  const timeInSeconds = Date.parse(date.toISOString()) / 1000;
  const timeNow = Date.now() / 1000;
  const timeDiff = timeNow - timeInSeconds;

  const secondsEquivalents: { [key: string]: number } = {
    year: 31536000,
    month: 2630000,
    week: 604800,
    day: 86400,
    hour: 3600,
    minute: 60,
    second: 1,
  };

  if (timeDiff <= 1) {
    return `Just now`;
  }

  for (const key in secondsEquivalents) {
    if (timeDiff >= secondsEquivalents[key]) {
      const value = Math.floor(timeDiff / secondsEquivalents[key]);
      if (value === 1) {
        return `${value} ${key} ago`;
      }

      return `${value} ${key}s ago`;
    }
  }
};
