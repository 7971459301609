import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function TrashErrorIconTwoTone(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M9.946 0c.596 0 1.081.485 1.081 1.081l-.001 1.405h3.46c.326 0 .596.24.642.553l.007.096c0 .358-.29.649-.649.649l-.919-.001-.986 11.236c-.051.514-.463.92-.962.975l-.116.006h-7.87c-.55 0-1.024-.428-1.078-.973L1.568 3.783h-.92a.649.649 0 0 1-.641-.552L0 3.135c0-.358.29-.648.649-.648l3.459-.001V1.081c0-.556.423-1.016.964-1.075L5.189 0zm2.318 3.783H2.87l.958 10.92h7.479l.957-10.92zM9.73 1.297H5.405v1.189h4.324V1.297z"
          fill="#D33535"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default TrashErrorIconTwoTone;
