import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function UsersList(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 25 25" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M11.976.842a5.991 5.991 0 0 0-5.988 5.989 5.99 5.99 0 0 0 5.988 5.988 5.991 5.991 0 0 0 5.989-5.988A5.992 5.992 0 0 0 11.976.842zm0 1.711a4.28 4.28 0 0 1 4.278 4.278 4.28 4.28 0 0 1-4.278 4.277A4.279 4.279 0 0 1 7.7 6.83a4.28 4.28 0 0 1 4.277-4.278zM1.711 23.084h10.632a.856.856 0 0 1 0 1.711H.855A.855.855 0 0 1 0 23.94v-1.711a7.7 7.7 0 0 1 7.7-7.7h4.643a.856.856 0 0 1 0 1.712H7.699a5.988 5.988 0 0 0-5.988 5.988v.855zm14.909-6.782h7.115a.856.856 0 0 0 0-1.71H16.62a.856.856 0 0 0 0 1.71zm0 4.27h7.115a.856.856 0 0 0 0-1.71H16.62a.856.856 0 0 0 0 1.71zm0 4.27h7.115a.856.856 0 0 0 0-1.71H16.62a.856.856 0 0 0 0 1.71z"
          fill="#838D9C"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default UsersList;
