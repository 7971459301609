import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    paper: {
      backgroundColor: '#0b0f14',
      border: '1px solid #222c39',
      borderRadius: 8,
    },
  }),
);
