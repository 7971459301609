import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const BlindIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="19" height="14" viewBox="0 0 19 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m15.234 0 1.1 1.1-9.143 9.142L3.433 14l-1.1-1.1 1.46-1.459C2.228 10.353.918 8.827 0 7 1.867 3.284 5.343.778 9.333.778c1.475 0 2.878.347 4.156.967L15.234 0zm.859 3.54A12.22 12.22 0 0 1 18.667 7c-1.867 3.717-5.343 6.222-9.333 6.222-.885 0-1.74-.129-2.562-.36l2.026-2.027c.177.024.352.054.536.054A3.889 3.889 0 0 0 13.222 7c0-.183-.03-.358-.053-.536zm-6.76-.429A3.889 3.889 0 0 0 5.446 7c0 .793.243 1.525.65 2.139L7.233 8A2.315 2.315 0 0 1 7 7a2.333 2.333 0 0 1 2.334-2.333c.36 0 .695.088 1 .233l1.138-1.139a3.849 3.849 0 0 0-2.138-.65z"
        fill={props.htmlColor || '#FFF'}
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default BlindIcon;
