import { QueryClientProvider } from 'react-query';
import { ReactQueryDevtools } from 'react-query/devtools';
import { ConfirmProvider } from 'material-ui-confirm';
import { SnackbarProvider } from 'notistack';
import { Provider } from 'react-redux';
import { store } from 'shared/redux/store';
import SwitchThemeProvider from 'shared/theme/SwitchThemeProvider';
import { Routes } from 'shared/navigation';
import { BrowserRouter as Router } from 'react-router-dom';
import { queryClient } from 'shared/config/queryClient';
import { SnackbarAlertWrapper } from 'components';

const App = () => {
  return (
    <QueryClientProvider client={queryClient}>
      <ReactQueryDevtools initialIsOpen={false} />
      <Provider store={store}>
        <Router>
          <SwitchThemeProvider>
            <SnackbarProvider maxSnack={3}>
              <ConfirmProvider>
                <Routes />
                <SnackbarAlertWrapper />
              </ConfirmProvider>
            </SnackbarProvider>
          </SwitchThemeProvider>
        </Router>
      </Provider>
    </QueryClientProvider>
  );
};

export default App;
