import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    request__rating__title: {
      fontSize: 14,
      [theme.breakpoints.down('lg')]: {
        fontSize: 13,
      },
    },
    request__favorite: {
      display: 'flex',
      flexDirection: 'column',
      gap: 6,
    },
    request__favorite__item: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
      [theme.breakpoints.down('lg')]: {
        '& p': {
          fontSize: '13px !important',
        },
      },
    },
    paperSmall: {
      backgroundImage: 'linear-gradient(180deg, #1d2834, #161e27)',
      border: '1px solid #222c39',
      borderRadius: '8px',
    },
    clientSelectionPopover: {
      display: 'flex',
      flexDirection: 'column',
      padding: '10px 15px',
      gap: 10,

      '& > div': {
        display: 'flex',
        alignItems: 'center',
        gap: 10,
      },

      '& button': {
        padding: 0,
        background: 'none',
        '&:hover': {
          background: 'none',
        },
      },
    },
  }),
);
