import { useMutation, useQuery } from 'react-query';
import { auditionScheduleDao } from 'shared/dao/auditionScheduleDao';
import {
  IAuditionScheduleRequestPayload,
  IAuditionScheduleResponsePayload,
  IAuditionScheduleUpdatePhaseAndStatusPayload,
} from 'shared/interfaces/IAuditionSchedule';
import { ICommonUpdateResponsePayload } from 'shared/interfaces/ICommon';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';

const {
  getAuditionSchedules: getAuditionSchedulesDao,
  updateAuditionScheduleStatusAndPhase: updateAuditionScheduleStatusAndPhaseDao,
} = auditionScheduleDao();
export const auditionScheduleService = () => {
  const getAuditionSchedules = (payload: IAuditionScheduleRequestPayload) => {
    return useQuery<IAuditionScheduleResponsePayload, IAxiosErrorResponse, IAuditionScheduleResponsePayload>(
      ['auditionSchedule', payload],
      () => getAuditionSchedulesDao(payload),
    );
  };

  const updateAuditionScheduleStatusAndPhase = () => {
    return useMutation<
      ICommonUpdateResponsePayload,
      IAxiosErrorResponse,
      { payload: IAuditionScheduleUpdatePhaseAndStatusPayload; updateType?: 'worksheet' }
    >(({ payload }) => updateAuditionScheduleStatusAndPhaseDao(payload), {
      onMutate: () => {},
    });
  };

  return {
    getAuditionSchedules,
    updateAuditionScheduleStatusAndPhase,
  };
};
