import React from 'react';
import { CloseIcon, ErrorIcon, SuccessIcon, WarningCircleFilledIcon } from 'components/Icons';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { DefaultDialog, useStyles } from './ConfirmationModal.styles';
import { Button, DialogContent, Grid, Typography } from '@material-ui/core';
import clsx from 'clsx';

type NotificationType = 'success' | 'error' | 'warning';
type Props = {
  type: NotificationType;
  open?: boolean;
};

type NotificationModalProps = {
  onClose?: () => void;
  onConfirm: () => void;
};

export const useConfirmationModal = ({ type = 'success', open: openProps }: Props) => {
  const classes = useStyles();
  const [open, setOpen] = useState<boolean>(false);
  const [modalType, setModalType] = useState<NotificationType>(type);
  const [notification, setNotification] = useState<{ title: string; message: string; ctaText?: string }>({
    title: '',
    message: '',
  });

  const handleSetMessage = (title: string, message: string, ctaText?: string) => {
    setNotification({ title, message, ctaText });
  };

  const handleOpen = () => setOpen(true);
  const handleToggle = () => setOpen((curr) => !curr);
  const handleChangeModalType = (type: NotificationType) => setModalType(type);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (openProps) {
      setOpen(openProps);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setModalType(type);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const renderIcon = useMemo(() => {
    switch (modalType) {
      case 'success':
        return <SuccessIcon />;
      case 'error':
        return <ErrorIcon />;
      case 'warning':
        return <WarningCircleFilledIcon />;
    }
  }, [modalType]);

  return useMemo(() => {
    return {
      handleSetMessage: (title: string, message: string, ctaText?: string) => handleSetMessage(title, message, ctaText),
      handleOpen: () => handleOpen(),
      handleClose: () => handleClose(),
      handleToggle: () => handleToggle(),
      handleChangeModalType: (modalType: NotificationType) => handleChangeModalType(modalType),
      isOpen: open,
      ConfirmationModal: ({ onClose, onConfirm }: NotificationModalProps) => (
        <DefaultDialog
          style={{ zIndex: 9999 }}
          open={open}
          onClose={() => {
            if (onClose) {
              onClose();
            }
            handleClose();
          }}
          maxWidth="sm"
          fullWidth
          className={clsx(classes.dialog, {
            [classes.errorDialog]: modalType === 'error',
            [classes.warningDialog]: modalType === 'warning',
          })}
          aria-labelledby="simple-dialog-title"
        >
          <DialogContent>
            <div className={classes.closeIcon}>
              <div
                onClick={() => {
                  if (onClose) {
                    onClose();
                  }
                  handleClose();
                }}
              >
                <CloseIcon style={{ width: '14px' }} />
              </div>
            </div>
            <div className={classes.notifBody}>
              {renderIcon}
              <Typography variant="h5">{notification.title || 'No Title'}</Typography>
              <Typography variant="body2">{notification.message || 'No Message'}</Typography>
              <Grid container spacing={2}>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    className={clsx(classes.baseBtn, {
                      [classes.primaryBtn]: modalType === 'success',
                      [classes.errorBtn]: modalType === 'error',
                      [classes.warningBtn]: modalType === 'warning',
                    })}
                    onClick={() => {
                      onConfirm();
                      if (onClose) {
                        onClose();
                      }
                      handleClose();
                    }}
                  >
                    Confirm
                  </Button>
                </Grid>
                <Grid item xs={6}>
                  <Button
                    fullWidth
                    className={classes.defaultBtn}
                    onClick={() => {
                      if (onClose) {
                        onClose();
                      }
                      handleClose();
                    }}
                  >
                    Cancel
                  </Button>
                </Grid>
              </Grid>
            </div>
          </DialogContent>
        </DefaultDialog>
      ),
    };
  }, [classes, modalType, open, renderIcon, notification, handleClose]);
};
