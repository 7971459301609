import {
  FlagGreenIconTwoTone,
  FlagIcon,
  FlagOrangeIconTwoTone,
  FlagRedIconTwoTone,
} from '@room-match/shared-ui-components';
import React from 'react';

type Props = {
  flag: string | null;
  fontSize?: number;
};
const AuditionFlagIcon: React.FC<Props> = ({ flag, fontSize = 18 }) => {
  const renderFlagIcon = () => {
    switch (flag) {
      case 'green_flag':
        return <FlagGreenIconTwoTone style={{ fontSize: fontSize }} />;
      case 'red_flag':
        return <FlagRedIconTwoTone style={{ fontSize: fontSize }} />;
      case 'orange_flag':
        return <FlagOrangeIconTwoTone style={{ fontSize: fontSize }} />;
      default:
        return <FlagIcon style={{ fontSize: fontSize }} />;
    }
  };
  return <>{renderFlagIcon()}</>;
};

export default React.memo(AuditionFlagIcon, (prev, current) => prev.flag === current.flag);
