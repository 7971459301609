import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function FullBodyIcon(props: SvgIconProps) {
  return (
    <SvgIcon width="17" height="30" viewBox="0 0 17 30" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="df3uvz4maa">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M8.544 3.323a2.688 2.688 0 0 1 2.685 2.685 2.688 2.688 0 0 1-2.685 2.685 2.688 2.688 0 0 1-2.685-2.685 2.688 2.688 0 0 1 2.685-2.685zm0 .935c-.965 0-1.75.785-1.75 1.75s.785 1.75 1.75 1.75 1.75-.785 1.75-1.75-.785-1.75-1.75-1.75zM14.205 0a2.685 2.685 0 0 1 2.682 2.682v2.53a.467.467 0 1 1-.935 0v-2.53A1.75 1.75 0 0 0 14.205.935h-2.53a.467.467 0 1 1 0-.935zM5.213 0H2.682A2.685 2.685 0 0 0 0 2.682v2.53a.467.467 0 1 0 .935 0v-2.53A1.75 1.75 0 0 1 2.682.935h2.53a.467.467 0 0 0 0-.935zM16.42 24.32c.23 0 .42.165.46.383l.007.084v2.531a2.685 2.685 0 0 1-2.512 2.677l-.17.005h-2.53a.467.467 0 0 1-.085-.927l.084-.008h2.531a1.75 1.75 0 0 0 1.742-1.604l.005-.143v-2.53c0-.259.21-.468.468-.468zm-15.953 0c.258 0 .468.21.468.467v2.531a1.75 1.75 0 0 0 1.747 1.747h2.53a.467.467 0 1 1 0 .935h-2.53A2.685 2.685 0 0 1 0 27.318v-2.53c0-.259.21-.468.467-.468zM11.24 12.307c.23 0 .42.166.46.384l.007.084v4.903h1.164v-3.923a.467.467 0 0 1 .927-.084l.007.084v4.093a.766.766 0 0 1-.66.758l-.104.007h-1.326v7.597c0 .23-.165.42-.383.46l-.084.007H5.64a.467.467 0 0 1-.46-.383l-.007-.084v-5.32a.467.467 0 0 1 .927-.083l.008.084v4.851h4.671v-7.515l-.005-.04-.002-.042v-5.37c0-.258.21-.468.467-.468zm-.551-3.402a3.115 3.115 0 0 1 3.116 3.117.467.467 0 1 1-.934 0 2.184 2.184 0 0 0-1.087-1.888 2.18 2.18 0 0 0-1.095-.294H6.199a2.184 2.184 0 0 0-2.182 2.182v5.656h1.148v-4.904a.467.467 0 1 1 .935 0v5.289a.468.468 0 0 1 .008.082v.913a.467.467 0 1 1-.935 0v-.445H3.847a.765.765 0 0 1-.764-.765v-5.826a3.12 3.12 0 0 1 3.116-3.117z"
        fill="url(#df3uvz4maa)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default FullBodyIcon;
