import { Typography } from '@room-match/shared-ui-components';
import React from 'react';
import { EmptyListContainer, useStyles } from './EmptyList.styles';

type Props = {
  text: string;
  type?: 'light' | 'dark';
  style?: React.CSSProperties;
};
const EmptyList: React.FC<Props> = ({ text, type = 'dark', style }) => {
  return (
    <EmptyListContainer type={type} style={style}>
      <Typography color={type === 'dark' ? 'mainGrey' : 'primary'}>{text}</Typography>
    </EmptyListContainer>
  );
};

export default EmptyList;
