import { QueryKey, useMutation, useQuery, UseQueryOptions } from 'react-query';
import { auditionScheduleGroupDao } from 'shared/dao/auditionScheduleGroupDao';
import {
  IAuditionScheduleGroupRequestPayload,
  IAuditionScheduleGroupResponsePayload,
} from 'shared/interfaces/IAuditionScheduleGroup';
import { ICommonResponsePayload } from 'shared/interfaces/ICommon';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';

const {
  getAuditionScheduleGroups: getAuditionScheduleGroupsDao,
  setAuditionScheduleGroupView: setAuditionScheduleGroupViewDao,
  sortOrderAuditionScheduleGroup: sortOrderAuditionScheduleGroupDao,
} = auditionScheduleGroupDao();
export const auditionScheduleGroupService = () => {
  const getAuditionScheduleGroups = (
    payload: IAuditionScheduleGroupRequestPayload,
    options?: Omit<
      UseQueryOptions<
        IAuditionScheduleGroupResponsePayload,
        IAxiosErrorResponse,
        IAuditionScheduleGroupResponsePayload,
        QueryKey
      >,
      'queryKey' | 'queryFn'
    >,
  ) => {
    return useQuery<IAuditionScheduleGroupResponsePayload, IAxiosErrorResponse>(
      ['auditionScheduleGroup', payload],
      () => getAuditionScheduleGroupsDao(payload),
      options,
    );
  };

  const setAuditionScheduleGroupView = () => {
    return useMutation<ICommonResponsePayload, IAxiosErrorResponse, string>((auditionScheduleGroupId) =>
      setAuditionScheduleGroupViewDao(auditionScheduleGroupId),
    );
  };

  const sortOrderAuditionScheduleGroup = () => {
    return useMutation<ICommonResponsePayload, IAxiosErrorResponse, string>((auditionScheduleGroupId) =>
      sortOrderAuditionScheduleGroupDao(auditionScheduleGroupId),
    );
  };

  return {
    getAuditionScheduleGroups,
    setAuditionScheduleGroupView,
    sortOrderAuditionScheduleGroup,
  };
};
