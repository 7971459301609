import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function ListView(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 24 18" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M22.72 17.683H6.73a1.193 1.193 0 1 1 0-2.386h15.99a1.193 1.193 0 0 1 0 2.386zm0-7.49H6.73a1.193 1.193 0 1 1 0-2.386h15.99a1.193 1.193 0 0 1 0 2.386zm0-7.49H6.73a1.193 1.193 0 1 1 0-2.386h15.99a1.193 1.193 0 0 1 0 2.386zm-21.118.502a1.602 1.602 0 1 0 0-3.205 1.602 1.602 0 0 0 0 3.205zm0 7.397a1.602 1.602 0 1 0 0-3.204 1.602 1.602 0 0 0 0 3.204zm0 7.398a1.602 1.602 0 1 0 0-3.205 1.602 1.602 0 0 0 0 3.205z"
          fill="#838D9C"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default ListView;
