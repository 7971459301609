export const COLORS = {
  PRIMARY: '#3e8bff',
  DANGER: '#d33535',
  DARK: '#0e1319',
  MAIN_GRAY: '#6E767F',
  DARK_BORDER: '#2d343e',
  DARK_GRAY: '#212933',
  DARKER: '#06090c',
  CARD_DARK_GRAY: '#151b23',
  CARD_LIGHT_GRAY: '#192029',
  GRAY: '#6e767f',
  BUTTON_GRAY: '#1a202b',
  GREEN: '#6edc11',
  BUTTON_LIGHT_GRAY: '#27303d',
  WARNING: '#d7a300',
  SUCCESS: '#43ba01',
  LATE_SHORT_COLOR: '#d7a300',
  LATE_LONG_COLOR: '#e53737',
  ON_TIME_COLOR: '#41bb00',
};
