import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function SoundCloudIconTwoTone(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 32 32" fill="none" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M6.667 22.012H8v-9.318c-.74.667-1.133 1.475-1.333 2.08v7.238zM14 12.07a6.275 6.275 0 0 0-2-.558v10.5h2V12.07zm-4.667 9.942h2V11.493c-.782.02-1.447.154-2 .372v10.147zm-6.666-6.043a3.38 3.38 0 0 0-1.334 2.692c0 1.058.53 1.992 1.334 2.604v-5.296zm24.663-1.292c-.963 0-1.522.348-1.522.348s-.56-5.659-6.18-5.659c-3.271 0-4.554 1.803-4.961 2.598v10.047h12.198s3.912-.062 3.912-3.635c0-2.75-2.484-3.699-3.447-3.699zM5.333 15.4a6.94 6.94 0 0 0-.894-.059c-.148 0-.294.025-.44.049v6.511c.283.07.575.111.875.111h.46V15.4z"
        fill="#FF5722"
      />
    </SvgIcon>
  );
}

export default SoundCloudIconTwoTone;
