import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    card: {
      backgroundColor: theme.palette.customBlack.dark,
    },

    cardContent: {
      padding: theme.spacing(2, 4),
    },
    contentContainer: {
      marginTop: 12,
    },
    cardFullHeight: {
      minHeight: 200,
    },
    flexBetween: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    textPrimary: {
      color: theme.palette.primary.main,
    },
    socialIconContainer: {
      display: 'flex',
      alignItems: 'center',
      gap: 16,
    },
    socialIcon: {
      fontSize: 18,
    },
    socialList: {
      paddingTop: theme.spacing(2),
      display: 'grid',
      gridTemplateColumns: 'repeat(3,1fr)',
      rowGap: 32,
      columnGap: 48,
    },

    socialItem: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'space-between',
    },
    socialItem__hidden: {
      '& svg path': {
        fill: theme.palette.customGrey.main,
      },
      '& p': {
        color: theme.palette.customGrey.main,
      },
    },
    socialLabel: {
      display: 'flex',
      alignItems: 'center',
      gap: 10,
    },
    agencyExpanding: {
      paddingTop: theme.spacing(2),
    },
    cardsContainer: {
      marginTop: '1.25rem',
    },
    resumeContainer: {
      marginTop: 8,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'center',
    },
    resumeItemContainer: {
      padding: theme.spacing(0.9, 0),
      display: 'flex',
      alignItems: 'center',
      gap: 4,
    },
    skillsContainer: {
      marginTop: 8,
      display: 'grid',
      // justifyContent: 'space-around',
      gap: 16,
      gridTemplateColumns: 'repeat(3,1fr)',
    },
    skillsItemContainer: {
      // width: '100%',
      padding: theme.spacing(0.5, 1),
      borderRadius: 4,
      backgroundColor: theme.palette.customBlack.light,
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
      gap: 24,
    },
  }),
);
