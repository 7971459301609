import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function MagnifyIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 20 21" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M1.667 15.5v3.333H5V20.5H0v-5h1.667zM8.333 2.167A6.666 6.666 0 0 1 13.6 12.92l4.733 4.735-1.18 1.177-4.732-4.733A6.666 6.666 0 0 1 1.667 8.833a6.666 6.666 0 0 1 6.666-6.666zm0 1.666c-2.757 0-5 2.243-5 5s2.243 5 5 5 5-2.243 5-5-2.243-5-5-5zm0 1.667v1.667c-.92 0-1.666.747-1.666 1.666H5A3.336 3.336 0 0 1 8.333 5.5zM20 .5v5h-1.667V2.167H15V.5h5z"
          fill="#677482"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default MagnifyIcon;
