import { TextField, TextFieldProps } from '@material-ui/core';
import React, { Fragment } from 'react';

export type InputProps = {
  errorMessage?: string;
} & TextFieldProps;
const Input: React.FC<InputProps> = ({ errorMessage, ...props }) => {
  return (
    <Fragment>
      <TextField
        error={!!errorMessage}
        type={props.type || 'text'}
        {...props}
        inputProps={{
          ...props.inputProps,
        }}
        helperText={!!errorMessage && errorMessage}
      />
    </Fragment>
  );
};

export default Input;
