import {
  Grid,
  IconButton,
  Button,
  InputLabel,
  TextareaAutosize,
  Box,
  Typography as Typography2,
  Chip,
} from '@material-ui/core';
import { ellipseText } from '@room-match/shared-utils';
import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router';
import { FormikProps } from 'formik';
import { FileUpload, TextInput, Typography } from 'shared/elements';
import { useTextAreaStyle } from 'shared/theme/styles/textAreaStyle';

import { useStyles } from './AdditionalDetails.styles';
import { useWorksheetSectionStyle } from 'shared/theme/styles/worksheetSectionStyle';
import { ArrowUpIcon, DocumentAddIcon, QuestionCircleIcon } from 'components/Icons';
import { ArrowDownIconTwoTone, RemoveIconTwoTone } from '@room-match/shared-ui-components';
import clsx from 'clsx';
import { IProjectCreatePayload } from 'shared/interfaces/IProject';
import { IProjectSide } from 'shared/interfaces/IProjectSide';

type Props = {
  form: FormikProps<IProjectCreatePayload>;
  files: any;
  sides: Array<IProjectSide>;
  handleSetSideFiles: (instance: any) => void;
  handleSetProjectSides: (instance: Array<IProjectSide>) => void;
  isCollapse: boolean;
  toggleCollapse: (accordionItem: string) => void;
};

const AdditionalDetails: React.FC<Props> = ({
  form,
  files,
  sides,
  handleSetSideFiles,
  handleSetProjectSides,
  isCollapse,
  toggleCollapse,
}) => {
  const classes = useStyles();
  const textarea_classes = useTextAreaStyle();
  const sectionClasses = useWorksheetSectionStyle();

  const [sideFiles, setSideFiles] = useState<any>([]);

  const [projectSides, setProjectSides] = useState<Array<IProjectSide>>([]);

  const handleUpload = async (name: string, type: string, url: string, file: any, imgAtt: any, rawFiles: any) => {
    const combinedFiles = [...sideFiles, ...rawFiles];
    const newFiles = Array.from(new Set(combinedFiles.map((a: any) => a.name))).map((name) => {
      return combinedFiles.find((a: any) => a.name === name);
    });
    handleSetSideFiles(newFiles);
  };

  const handleRemoveSideProject = (index: number) => {
    const newSideProjects = [...sideFiles];
    newSideProjects.splice(index, 1);

    handleSetSideFiles(newSideProjects);
  };

  const handleRemoveCurrentSideProject = (index: number) => {
    const newCurrentSides = [...projectSides];

    newCurrentSides.splice(index, 1);
    handleSetProjectSides(newCurrentSides);
  };

  useEffect(() => {
    if (sides) {
      setProjectSides(sides);
    }
    if (files) {
      setSideFiles(files);
    }
  }, [sides, files]);

  return (
    <Grid container spacing={0} className={sectionClasses.root}>
      <Grid item className={sectionClasses.header}>
        <Grid item>
          <Typography>5. Additional Details</Typography>
        </Grid>
        <Grid item>
          <Button
            className={sectionClasses.collapseButton}
            startIcon={isCollapse ? <ArrowUpIcon /> : <ArrowDownIconTwoTone />}
            onClick={() => toggleCollapse('AT')}
          >
            Collapse
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        className={clsx(sectionClasses.content, {
          [sectionClasses.content__isNotCollapsed]: !isCollapse,
        })}
      >
        <Grid item>
          <Box className={classes.pNameHeader}>
            <InputLabel>Additional Project Details</InputLabel>
            <QuestionCircleIcon style={{ width: '18px' }} />
          </Box>
          <TextareaAutosize
            minRows={7}
            placeholder="Type here..."
            className={textarea_classes.textAreaCls}
            style={{ marginTop: '5px' }}
            value={form.values.additional_details || ''}
            onChange={(e) => form.setFieldValue('additional_details', e.target.value)}
          />
        </Grid>
        <Grid item style={{ marginTop: 10 }}>
          <InputLabel id="uploadPhotoReferences">Add Scripts, Storyboards or Contracts</InputLabel>
          <FileUpload
            onFileSelected={handleUpload}
            headingTop="Click To Upload PDF Document(s)"
            acceptedFiles=".pdf"
            height={100}
          />
        </Grid>
        <Grid container spacing={2}>
          {sideFiles.map((item: any, index: number) => (
            <Grid item xs={12} sm={12} md={6} lg={6} key={item.name}>
              <Chip
                className={classes.customChip}
                key={item.name}
                deleteIcon={<RemoveIconTwoTone width="10" height="8" viewBox="0 0 10 8" fill="none" />}
                onDelete={() => handleRemoveSideProject(index)}
                variant="outlined"
                label={ellipseText(item.name, 20)}
              />
            </Grid>
          ))}
        </Grid>
        {projectSides.length > 0 && (
          <Box className={classes.pNameHeader} style={{ marginTop: '15px' }}>
            <Typography2 variant="body2">Current files</Typography2>
            <Grid container spacing={2}>
              {projectSides.map((item: IProjectSide, index: number) => (
                <Grid item xs={12} sm={12} md={6} lg={6} key={item.attributes.medium.data.attributes.id}>
                  <Chip
                    className={classes.customChip}
                    key={item.attributes.medium.data.attributes.file_name}
                    deleteIcon={<RemoveIconTwoTone width="10" height="8" viewBox="0 0 10 8" fill="none" />}
                    onDelete={() => handleRemoveCurrentSideProject(index)}
                    variant="outlined"
                    label={ellipseText(item.attributes.medium.data.attributes.file_name, 20)}
                  />
                </Grid>
              ))}
            </Grid>
          </Box>
        )}
      </Grid>
    </Grid>
  );
};

export default AdditionalDetails;
