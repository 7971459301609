import { Grid } from '@material-ui/core';
import clsx from 'clsx';
import { JobLogModal } from 'components/JobLogModal';
import DetailComment from 'components/ProjectItemDetail/DetailComment/DetailComment';
import DetailFavorite from 'components/ProjectItemDetail/DetailFavorite/DetailFavorite';
import DotIcon from 'components/ProjectItemDetail/DotIcon/DotIcon';
import MessageInput from 'components/ProjectItemDetail/MessageInput/MessageInput';
import NoteIcon from 'components/ProjectItemDetail/NoteIcon/NoteIcon';
import Rating from 'components/ProjectItemDetail/Rating/Rating';
import TalentDescription from 'components/ProjectItemDetail/TalentDescription/TalentDescription';
import TalentHistory from 'components/ProjectItemDetail/TalentHistory/TalentHistory';
import TalentMedia from 'components/ProjectItemDetail/TalentMedia/TalentMedia';
import React, { useMemo, useState } from 'react';
import { ACTION_TAB_LIST, REQUEST_TAB_LIST } from 'shared/constants/SELF_TAPES_TABS';
import { AuditionStatus } from 'shared/enums/AuditionStatus';
import { SelfTapesTab } from 'shared/enums/SelfTapesTab';
import { IAuditionAttributes } from 'shared/interfaces/IAudition';
import { useStyles } from './SelfTapesTabs.styles';
import SelfTapesTabsActions from './SelfTapesTabsActions/SelfTapesTabsActions';
import { auditionFlagService } from 'shared/services/auditionFlagService';
import { auditionScheduleFlagService } from 'shared/services/auditionScheduleFlagService';
import { useQueryClient } from 'react-query';
import {
  getCastingFavoriteData,
  getClientFavoriteData,
  getCurrentFlagData,
  getCurrentFlagTypeData,
  getInternalNotesData,
  getPublicNotesData,
} from 'shared/utils/auditionFlagParser';
import { IComment } from 'shared/interfaces/utils/IComment';
import { AuditionFlag } from 'shared/enums/AuditionFlag';
import { AuditionExpandingContent } from 'components/AuditionExpandedDiv';
import { getMainAuditionSchedule } from 'shared/utils/getMainAuditionSchedule';
import { JOB_LOG_DATA } from 'shared/constants/JOB_LOG_DATA';

type Props = {
  tab: SelfTapesTab;
  isShowVideoItem?: boolean;
  audition: IAuditionAttributes;
  handleAddSelectedTalentAudition: (audition: IAuditionAttributes, isChecked: boolean) => void;
  handleSingleUpdateStatus: (auditionScheduleId: string, status: AuditionStatus) => void;
  handleOpenTalentProfile: (data: any, auditionScheduleId: string) => void;
};

const { createAuditionScheduleFlag, getAuditionScheduleFlags, updateAuditionScheduleFlag, deleteAuditionScheduleFlag } =
  auditionScheduleFlagService();
const SelfTapesTabs: React.FC<Props> = ({
  tab,
  isShowVideoItem,
  audition,
  handleAddSelectedTalentAudition,
  handleSingleUpdateStatus,
  handleOpenTalentProfile,
}) => {
  const classes = useStyles();
  const [isShowVideoExpanded, setIsShowVideoExpanded] = useState<boolean>(false);
  const [isShowJobLog, setIsShowJobLog] = useState<boolean>(false);

  const [messageAgencyInput, setMessageAgencyInput] = useState<string>('');

  const handleOpenShowVideoExpanded = () => setIsShowVideoExpanded(true);
  const handleCloseShowVideoExpanded = () => setIsShowVideoExpanded(false);

  const handleOpenJobLog = () => setIsShowJobLog(true);
  const handleCloseJobLog = () => setIsShowJobLog(false);

  const getAuditionScheduleId = (audition: IAuditionAttributes) => {
    const auditionSchedule = getMainAuditionSchedule(audition.audition_schedules);
    return auditionSchedule.id;
  };
  const auditionSchedule_Id = getAuditionScheduleId(audition);

  const IS_REQUEST_TAB = REQUEST_TAB_LIST.includes(tab);
  const IS_ACTION_TAB = ACTION_TAB_LIST.includes(tab);

  const queryClient = useQueryClient();
  const { data: auditionFlagData, isLoading: isAuditionFlagLoading } = getAuditionScheduleFlags(auditionSchedule_Id);
  const { mutate } = createAuditionScheduleFlag();
  const { mutate: mutateUpdateAuditionScheduleFlag } = updateAuditionScheduleFlag();
  const { mutate: mutateDeleteAuditionScheduleFlag } = deleteAuditionScheduleFlag();

  const handleMessageAgencySubmit = () => {
    if (messageAgencyInput) {
      alert(messageAgencyInput);
    }
  };

  const getCurrentFlag = useMemo(() => {
    return auditionFlagData ? getCurrentFlagData(auditionFlagData.data) : null;
  }, [auditionFlagData]);

  const getCurrentFlagType = useMemo(() => {
    return getCurrentFlagTypeData(getCurrentFlag?.attributes?.flag_type || '');
  }, [getCurrentFlag?.attributes.flag_type]) as AuditionFlag | undefined;

  const onCreateAuditionFlag = (flag: string, comment: string) => {
    if (!getCurrentFlag) {
      mutate(
        { auditionScheduleId: auditionSchedule_Id, payload: { flag_type: flag, comment } },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['audition_schedule_flag', auditionSchedule_Id], {
              refetchInactive: true,
            });
          },
        },
      );
    } else {
      mutateUpdateAuditionScheduleFlag(
        {
          auditionScheduleId: auditionSchedule_Id,
          flagId: getCurrentFlag.id,
          payload: { flag_type: flag, comment },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['audition_schedule_flag', auditionSchedule_Id], {
              refetchInactive: true,
            });
          },
        },
      );
    }
  };

  const getCastingFavorite = useMemo(() => {
    return auditionFlagData ? getCastingFavoriteData(auditionFlagData.data) : null;
  }, [auditionFlagData]);

  const onCreateCastingFavorite = () => {
    if (!getCastingFavorite) {
      mutate(
        { auditionScheduleId: auditionSchedule_Id, payload: { flag_type: 'Casting Favourite' } },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['audition_schedule_flag', auditionSchedule_Id], {
              refetchInactive: true,
            });
          },
        },
      );
    } else {
      mutateDeleteAuditionScheduleFlag(
        { auditionScheduleId: auditionSchedule_Id, flagId: getCastingFavorite.id },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['audition_schedule_flag', auditionSchedule_Id], {
              refetchInactive: true,
            });
          },
        },
      );
    }
  };

  const getClientFavorite = useMemo(() => {
    return auditionFlagData ? getClientFavoriteData(auditionFlagData.data) : null;
  }, [auditionFlagData]);

  const onCreateClientFavorite = (type: string) => {
    if (!getClientFavorite) {
      mutate(
        { auditionScheduleId: auditionSchedule_Id, payload: { flag_type: type } },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['audition_schedule_flag', auditionSchedule_Id], {
              refetchInactive: true,
            });
          },
        },
      );
    } else {
      mutateUpdateAuditionScheduleFlag(
        {
          auditionScheduleId: auditionSchedule_Id,
          flagId: getClientFavorite.id,
          payload: { flag_type: type },
        },
        {
          onSuccess: () => {
            queryClient.invalidateQueries(['audition_schedule_flag', auditionSchedule_Id], {
              refetchInactive: true,
            });
          },
        },
      );
    }
  };

  const getInternalNotes = useMemo(() => {
    return auditionFlagData ? getInternalNotesData(auditionFlagData.data) : [];
  }, [auditionFlagData]);

  const internalNotesComments = useMemo(() => {
    return getInternalNotes.map((internalNote) => {
      return {
        comment: internalNote.attributes?.comment,
        rating: internalNote.attributes?.rating,
        user: internalNote.attributes?.user.data.attributes,
      };
    });
  }, [getInternalNotes]) as IComment[];

  const getPublicNotes = useMemo(() => {
    return auditionFlagData ? getPublicNotesData(auditionFlagData.data) : [];
  }, [auditionFlagData]);

  const onCreateNote = (type: 'Internal Note' | 'Public Note', rating: number, note: string) => {
    mutate(
      { auditionScheduleId: auditionSchedule_Id, payload: { flag_type: type, comment: note, rating: rating } },
      {
        onSuccess: () => {
          queryClient.invalidateQueries(['audition_schedule_flag', auditionSchedule_Id], {
            refetchInactive: true,
          });
          queryClient.invalidateQueries(['audition'], {
            refetchInactive: true,
          });
        },
      },
    );
  };

  return (
    <>
      {!isAuditionFlagLoading && (
        <div className={classes.tab}>
          <div
            className={clsx(classes.tab__contentWrapper, {
              [classes.tab__contentWrapper__showExpandedDiv]: isShowVideoExpanded,
            })}
          >
            {/* Card */}
            <div className={classes.tab__card}>
              {/* Card Content */}
              <div className={classes.tab__cardContent}>
                <Grid
                  container
                  className={clsx({
                    [classes.tab__contentContainer]: isShowVideoExpanded,
                  })}
                >
                  {/* Left Content */}
                  <Grid
                    item
                    xl={isShowVideoItem ? 6 : 8}
                    lg={isShowVideoItem ? 6 : 7}
                    md={6}
                    className={classes.tab__left}
                  >
                    {/* Action */}
                    <SelfTapesTabsActions
                      tab={tab}
                      handleAddSelectedTalentAudition={handleAddSelectedTalentAudition}
                      audition={audition}
                      handleSingleUpdateStatus={handleSingleUpdateStatus}
                    />
                    {/* Details */}
                    <div className={classes.tab__detail}>
                      {/* Video Player */}
                      <TalentMedia isVideo={isShowVideoItem} audition={audition} />
                      {/* Information */}
                      <div className={classes.tab__information}>
                        {/* Description */}
                        <TalentDescription
                          isShowVideoExpanded={isShowVideoExpanded}
                          handleCloseShowVideoExpanded={handleCloseShowVideoExpanded}
                          handleOpenShowVideoExpanded={handleOpenShowVideoExpanded}
                          handleSingleUpdateStatus={handleSingleUpdateStatus}
                          audition={audition}
                          auditionScheduleId={auditionSchedule_Id}
                          disabledUpdateStatus={tab === SelfTapesTab.HIDDEN}
                          currentFlag={getCurrentFlag?.attributes?.flag_type || null}
                          handleOpenTalentProfile={handleOpenTalentProfile}
                          status={''}
                        />
                        {/* History */}
                        <div
                          className={clsx(classes.tab__history, {
                            [classes.tab__history__unFW]: IS_ACTION_TAB,
                          })}
                        >
                          <MessageInput
                            value={messageAgencyInput}
                            onChange={(e) => setMessageAgencyInput(e.target.value as string)}
                            handleSubmit={handleMessageAgencySubmit}
                          />
                          <TalentHistory audition={audition} />
                        </div>
                        <NoteIcon handleOpenJobLog={handleOpenJobLog} />
                      </div>
                    </div>
                  </Grid>
                  {/* Right Content */}
                  <Grid
                    item
                    xl={isShowVideoItem ? 6 : 4}
                    lg={isShowVideoItem ? 6 : 5}
                    md={6}
                    className={classes.tab__right}
                  >
                    {/* Rating */}
                    {IS_REQUEST_TAB && <Rating audition={audition} comment={internalNotesComments[0]} />}
                    {/* Favorite */}
                    <DetailFavorite
                      isCastingFavorite={!!getCastingFavorite}
                      onCreateCastingFavorite={onCreateCastingFavorite}
                      onCreateClientFavorite={onCreateClientFavorite}
                      clientRate={getClientFavorite?.attributes?.flag_type}
                    />
                    {/* Comment */}
                    <DetailComment auditionFlag={getCurrentFlag} onCreateAuditionFlag={onCreateAuditionFlag} />
                    {/* Dot BUtton */}
                    <DotIcon />
                  </Grid>
                </Grid>
                {/* Expanded Div Container */}
              </div>
            </div>
            {isShowVideoExpanded && (
              <div className={classes.tab__expandedDivContainer}>
                {/* Expanded Div */}
                <AuditionExpandingContent
                  isShowActionButtons={false}
                  audition={audition}
                  internalNotes={getInternalNotes}
                  publicNotes={getPublicNotes}
                  onSubmitComment={onCreateNote}
                />
              </div>
            )}
          </div>

          {isShowJobLog && <JobLogModal open={isShowJobLog} onClose={handleCloseJobLog} audition={audition} />}
        </div>
      )}
    </>
  );
};

export default SelfTapesTabs;
