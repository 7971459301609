import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function ActiveIndicator(props: SvgIconProps) {
  return (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <svg width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <g fill="#6EDC11" fillRule="evenodd">
          <circle opacity=".2" cx="9" cy="9" r="9" />
          <circle cx="9" cy="9" r="6.3" />
        </g>
      </svg>
    </SvgIcon>
  );
}

export default ActiveIndicator;
