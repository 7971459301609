import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function MinusCircleIcon(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 14 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M7 0C3.14 0 0 3.14 0 7s3.14 7 7 7 7-3.14 7-7-3.14-7-7-7zm0 13.125A6.132 6.132 0 0 1 .875 7 6.132 6.132 0 0 1 7 .875 6.132 6.132 0 0 1 13.125 7 6.132 6.132 0 0 1 7 13.125zM3.85 6.65h6.3a.35.35 0 1 1 0 .7h-6.3a.35.35 0 0 1 0-.7z"
        fill={props.htmlColor || '#ffffff'}
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
}

export default MinusCircleIcon;
