import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function NoShow(props: SvgIconProps) {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M9.184 0c-3.167 0-5.75 2.592-5.75 5.758 0 1.936.968 3.65 2.443 4.694C2.439 11.829 0 15.19 0 19.113a.907.907 0 0 0 1.814 0 7.503 7.503 0 0 1 7.517-7.517c1.453 0 2.801.414 3.95 1.125a.91.91 0 1 0 .958-1.548 9.308 9.308 0 0 0-1.658-.787 5.742 5.742 0 0 0 2.36-4.628C14.942 2.592 12.352 0 9.185 0zm0 1.815a3.932 3.932 0 0 1 3.942 3.943 3.926 3.926 0 0 1-3.942 3.936 3.92 3.92 0 0 1-3.936-3.936 3.926 3.926 0 0 1 3.936-3.943zm4.653 11.791a.907.907 0 0 0-.63 1.56l1.623 1.625-1.624 1.622a.907.907 0 1 0 1.279 1.286l1.628-1.626 1.626 1.626a.91.91 0 0 0 1.286-1.286l-1.626-1.622 1.626-1.624a.907.907 0 0 0-.663-1.56.907.907 0 0 0-.623.274l-1.626 1.626-1.628-1.626a.907.907 0 0 0-.648-.275z"
        fill="#E53737"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default NoShow;
