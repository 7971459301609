import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const ArrowCollapse = (props: SvgIconProps) => {
  return (
    <SvgIcon width="31" height="14" viewBox="0 0 31 14" xmlns="http://www.w3.org/2000/SvgIcon" {...props}>
      <path
        d="M30.563 11.688H16.5v-8.05l4.025 4.025 1.325-1.326L16.225.712a.936.936 0 0 0-1.325 0L9.275 6.337 10.6 7.663l4.025-4.025v8.05H.563v1.875h30v-1.875z"
        fill={props.htmlColor || '#fff'}
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default ArrowCollapse;
