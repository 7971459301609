import { Grid, IconButton, Button, InputLabel, Chip, Typography as Typography2 } from '@material-ui/core';

import React, { useState, useMemo, useEffect } from 'react';

import { Checkbox, TextInput, Typography } from 'shared/elements';
import { useParams } from 'react-router';
import { useStyles } from './CreativeTeam.styles';
import { useWorksheetSectionStyle } from 'shared/theme/styles/worksheetSectionStyle';
import { ArrowUpIcon, ContactsIcon, DocumentAddIcon } from 'components/Icons';
import ArrowUp from 'components/Icons/ArrowUp';
import { FormikProps } from 'formik';
import { IBookedCreatePayload } from 'shared/interfaces/IAudition';
import { ArrowDownIconTwoTone, RemoveIconTwoTone, Select } from '@room-match/shared-ui-components';
import clsx from 'clsx';
import { IProjectCreatePayload } from 'shared/interfaces/IProject';
import { ShowToType } from 'shared/enums/ShowToType';
import { useQueryClient } from 'react-query';
import { projectService } from 'shared/services/projectService';
import { IProject } from 'shared/interfaces/IProject';
import { contactService } from 'shared/services/contactService';
import { ellipseText } from '@room-match/shared-utils';

interface IShowToAttributes {
  contact_id: string;
  visible_to_agents: boolean;
  visible_to_notice_board: boolean;
}

type Props = {
  tab?: string;
  form: FormikProps<IProjectCreatePayload>;
  project: IProject;
  director: any;
  producer: any;
  assignedContacts: any;
  selProducerShowToList: IShowToAttributes[];
  selDirectorShowToList: IShowToAttributes[];
  handleSetContact: (instance: any, str: string) => void;
  handleSetSelectedProducerShowToList: (instance: any) => void;
  handleSetSelectedDirectorShowToList: (instance: any) => void;
  handleRemoveContactProducer: (instance: string) => void;
  handleRemoveContactDirector: (instance: string) => void;
  checkUncheckProducerVisibleToAgents: (instance: any, instance2: string, instance3: ShowToType) => void;
  checkUncheckDirectorVisibleToAgents: (instance: any, instance2: string, instance3: ShowToType) => void;
  isCollapse: boolean;
  toggleCollapse: (accordionItem: string) => void;
};

const { getContacts } = contactService();
const { detachProjectContacts, updateProjectContactAttachments } = projectService();
const CreativeTeam: React.FC<Props> = ({
  tab,
  form,
  project,
  director,
  producer,
  assignedContacts,
  handleSetContact,
  selProducerShowToList,
  selDirectorShowToList,
  handleSetSelectedProducerShowToList,
  handleSetSelectedDirectorShowToList,
  handleRemoveContactProducer,
  handleRemoveContactDirector,
  checkUncheckProducerVisibleToAgents,
  checkUncheckDirectorVisibleToAgents,
  isCollapse,
  toggleCollapse,
}) => {
  const classes = useStyles();
  const queryClient = useQueryClient();
  const sectionClasses = useWorksheetSectionStyle();
  const { data: contactsData } = getContacts();

  const [contactProducers, setContactProducers] = useState<any>([]);
  const [contactDirectors, setContactDirectors] = useState<any>([]);
  const [contactProducer, setContactProducer] = useState<any>([]);
  const [contactDirector, setContactDirector] = useState<any>([]);
  const [selectedProducerShowToList, setSelectedProducerShowToList] = useState<IShowToAttributes[]>([]);
  const [selectedDirectorShowToList, setSelectedDirectorShowToList] = useState<IShowToAttributes[]>([]);
  const [currentAssignedContacts, setCurrentAssignedContacts] = useState<string[]>([]);

  const contacts = useMemo(() => {
    return contactsData?.data ? contactsData.data : [];
  }, [contactsData]);

  const producers = useMemo(() => {
    return contacts.length > 0 ? contacts.filter((item) => item.attributes.position === 'Producer') : [];
  }, [contacts]);

  const directors = useMemo(() => {
    return contacts.length > 0 ? contacts.filter((item) => item.attributes.position === 'Director') : [];
  }, [contacts]);

  const dShowToList = useMemo(() => {
    return selDirectorShowToList.length > 0 ? selDirectorShowToList : [];
  }, [selDirectorShowToList]);

  const pShowToList = useMemo(() => {
    return selProducerShowToList.length > 0 ? selProducerShowToList : [];
  }, [selProducerShowToList]);

  const handleContactLabel = (itemId: string, data: any) => {
    const filtered = data.filter((item: any) => item.value === itemId);
    return filtered.length > 0 ? ellipseText(filtered[0].key, 40) : '';
  };

  const getProducerShowToData = (itemId: string) => {
    const filtered = selectedProducerShowToList.filter((item) => item.contact_id === itemId);

    if (filtered.length > 0) {
      return filtered[0];
    }
  };

  const getDirectorShowToData = (itemId: string) => {
    const filtered = selectedDirectorShowToList.filter((item) => item.contact_id === itemId);

    if (filtered.length > 0) {
      return filtered[0];
    }
  };

  const handleCreativeTeamItemName = (fieldName: string, val: string) => {
    form.setFieldValue(fieldName, val);
  };

  const productionCompanyShowTo = (str: string) => {
    const arr = form.values.production_company?.show_to || [];
    if (arr.includes(str)) {
      arr.splice(arr.indexOf(str, 1));
    } else {
      arr.push(str);
    }
    form.setFieldValue('production_company.show_to', arr);
  };

  const adAgencyShowTo = (str: string) => {
    const arr = form.values.ad_agency?.show_to || [];
    if (arr.includes(str)) {
      arr.splice(arr.indexOf(str, 1));
    } else {
      arr.push(str);
    }
    form.setFieldValue('ad_agency.show_to', arr);
  };

  useEffect(() => {
    if (directors.length > 0) {
      const newDirectorData = directors.map((item: any) => ({
        key: `${item.attributes.user.data.attributes.full_name}`,
        value: item.id,
      }));
      setContactDirectors(newDirectorData);
    }
    if (producers.length > 0) {
      const newProducerData = producers.map((item: any) => ({
        key: `${item.attributes.user.data.attributes.full_name}`,
        value: item.id,
      }));
      setContactProducers(newProducerData);
    }
    if (director) {
      setContactDirector(director);
    }
    if (producer) {
      setContactProducer(producer);
    }
    if (assignedContacts) {
      setCurrentAssignedContacts(assignedContacts);
    }
    if (dShowToList) {
      setSelectedDirectorShowToList(dShowToList);
    }
    if (pShowToList) {
      setSelectedProducerShowToList(pShowToList);
    }
  }, [directors, producers, director, producer, assignedContacts, dShowToList, pShowToList]);

  return (
    <Grid container spacing={0} className={sectionClasses.root}>
      <Grid item className={sectionClasses.header}>
        <Grid item>
          <Typography>4. Creative Team</Typography>
        </Grid>
        <Grid item>
          <Button
            className={sectionClasses.collapseButton}
            startIcon={isCollapse ? <ArrowUpIcon /> : <ArrowDownIconTwoTone />}
            onClick={() => toggleCollapse('CT')}
          >
            Collapse
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        className={clsx(sectionClasses.content, {
          [sectionClasses.content__isNotCollapsed]: !isCollapse,
        })}
      >
        <Grid item className={classes.ciContainer}>
          <div className={classes.inputIcon}>
            <InputLabel className={classes.marginTopSm} id="producer">
              Producer
            </InputLabel>
            <Select
              name="producer"
              variant="outlined"
              fullWidth
              data={contactProducers}
              value={contactProducer}
              placeHolder="Select Producer"
              onChange={(e) => {
                handleSetContact(e.target.value, 'producer');
                handleSetSelectedProducerShowToList(e.target.value);
              }}
              multiple
              IconComponent={ContactsIcon}
              classes={{
                icon: classes.customIcon,
              }}
            />
            <Grid container style={{ marginTop: 5 }} spacing={2}>
              {contactProducer.map((item_id: any, v: number) => {
                const showTo = getProducerShowToData(item_id);
                return (
                  <Grid item key={item_id} lg={6} md={12} sm={12} xs={12}>
                    <Chip
                      className={classes.customChip}
                      deleteIcon={<RemoveIconTwoTone width="10" height="8" viewBox="0 0 10 8" fill="none" />}
                      onDelete={() => handleRemoveContactProducer(item_id)}
                      variant="outlined"
                      label={handleContactLabel(item_id, contactProducers)}
                    />
                    <div className={classes.checkboxes}>
                      <Typography2 variant="body2">Show to: </Typography2>
                      <div className={classes.bypassCheckBoxCls}>
                        <Checkbox
                          label="Agents"
                          name="visible_to_agents"
                          labelPlacement="end"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                          onChange={(e) =>
                            checkUncheckProducerVisibleToAgents(e.target, item_id, ShowToType.VisibleToAgents)
                          }
                          value={showTo?.visible_to_agents}
                          id="visible_to_agents"
                        />
                      </div>
                      <div className={classes.bypassCheckBoxCls}>
                        <Checkbox
                          label="CastingApp Notice Board"
                          name="visible_to_notice_board"
                          labelPlacement="end"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                          onChange={(e) =>
                            checkUncheckProducerVisibleToAgents(e.target, item_id, ShowToType.VisibleToNoticeBoard)
                          }
                          value={showTo?.visible_to_notice_board}
                          id="visible_to_notice_board"
                        />
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
          <div className={classes.inputIcon}>
            <InputLabel className={classes.marginTopSm} id="director">
              Director
            </InputLabel>
            <Select
              name="director"
              variant="outlined"
              fullWidth
              data={contactDirectors}
              value={contactDirector}
              placeHolder="Select Director"
              onChange={(e) => {
                handleSetContact(e.target.value, 'director');
                handleSetSelectedDirectorShowToList(e.target.value);
              }}
              multiple
              IconComponent={ContactsIcon}
              classes={{
                icon: classes.customIcon,
              }}
            />
            <Grid container style={{ marginTop: 5 }} spacing={2}>
              {contactDirector.map((item_id: any, v: number) => {
                const showTo = getDirectorShowToData(item_id);
                return (
                  <Grid item key={item_id} lg={6} md={12} sm={12} xs={12}>
                    <Chip
                      className={classes.customChip}
                      key={item_id}
                      deleteIcon={<RemoveIconTwoTone width="10" height="8" viewBox="0 0 10 8" fill="none" />}
                      onDelete={() => handleRemoveContactDirector(item_id)}
                      variant="outlined"
                      label={handleContactLabel(item_id, contactDirectors)}
                    />
                    <div className={classes.checkboxes}>
                      <Typography2 variant="body2">Show to: </Typography2>
                      <div className={classes.bypassCheckBoxCls}>
                        <Checkbox
                          label="Agents"
                          name="visible_to_agents"
                          labelPlacement="end"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                          onChange={(e) =>
                            checkUncheckDirectorVisibleToAgents(e.target, item_id, ShowToType.VisibleToAgents)
                          }
                          value={showTo?.visible_to_agents}
                          id="visible_to_agents"
                        />
                      </div>
                      <div className={classes.bypassCheckBoxCls}>
                        <Checkbox
                          label="CastingApp Notice Board"
                          name="visible_to_notice_board"
                          labelPlacement="end"
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                          onChange={(e) =>
                            checkUncheckDirectorVisibleToAgents(e.target, item_id, ShowToType.VisibleToNoticeBoard)
                          }
                          value={showTo?.visible_to_notice_board}
                          id="visible_to_notice_board"
                        />
                      </div>
                    </div>
                  </Grid>
                );
              })}
            </Grid>
          </div>
        </Grid>
        <Grid item style={{ width: '88.05%' }}>
          <TextInput
            label="Production Company"
            labelId="production_company"
            placeholder="Type here..."
            value={form.values.production_company?.name}
            onChange={(e) => handleCreativeTeamItemName('production_company.name', e.target.value)}
          />
          <Grid item className={classes.checkbox}>
            <InputLabel id="showTo" style={{ marginBottom: '20px' }}>
              Show To:
            </InputLabel>
            <Grid item>
              <Checkbox
                label="Agents"
                labelPlacement="end"
                name="agent"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onChange={() => productionCompanyShowTo('agents')}
                value={(form.values.production_company?.show_to || []).includes('agents') ? true : false}
                id="agent"
              />
              <Checkbox
                label="CastingApp Notice Board"
                labelPlacement="end"
                name="castingapp_notice_board"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onChange={() => productionCompanyShowTo('castingapp_notice_board')}
                value={
                  (form.values.production_company?.show_to || []).includes('castingapp_notice_board') ? true : false
                }
                id="castingapp_notice_board"
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item style={{ width: '88.05%' }}>
          <TextInput
            label="Ad Agency"
            labelId="ad_agency"
            placeholder="Type here..."
            value={form.values.ad_agency?.name}
            onChange={(e) => handleCreativeTeamItemName('ad_agency.name', e.target.value)}
          />
          <Grid item className={classes.checkbox}>
            <InputLabel id="showTo" style={{ marginBottom: '20px' }}>
              Show To:
            </InputLabel>
            <Grid item>
              <Checkbox
                label="Agents"
                labelPlacement="end"
                name="agent"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onChange={() => adAgencyShowTo('agents')}
                value={(form.values.ad_agency?.show_to || []).includes('agents') ? true : false}
                id="agent"
              />
              <Checkbox
                label="CastingApp Notice Board"
                labelPlacement="end"
                name="castingapp_notice_board"
                onClick={(e) => {
                  e.stopPropagation();
                  e.preventDefault();
                }}
                onChange={() => adAgencyShowTo('castingapp_notice_board')}
                value={(form.values.ad_agency?.show_to || []).includes('castingapp_notice_board') ? true : false}
                id="castingapp_notice_board"
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default CreativeTeam;
