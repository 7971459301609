import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const UserNewIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="5fv8yrfjqa">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M14.346 10.531a.818.818 0 0 1 .801.224 8.963 8.963 0 0 1 2.498 4.522 2.23 2.23 0 0 1-.452 1.879A2.259 2.259 0 0 1 15.43 18H2.263A2.259 2.259 0 0 1 .5 17.159a2.23 2.23 0 0 1-.449-1.882 8.963 8.963 0 0 1 2.498-4.522.818.818 0 1 1 1.145 1.168 7.345 7.345 0 0 0-2.045 3.7.593.593 0 0 0 .124.504c.119.15.3.237.49.237h13.17a.628.628 0 0 0 .49-.237.593.593 0 0 0 .124-.505 7.345 7.345 0 0 0-2.045-3.7.818.818 0 0 1 .344-1.391zM8.847 0a5.727 5.727 0 0 1 0 11.455A5.734 5.734 0 0 1 3.12 5.727 5.727 5.727 0 0 1 8.847 0zm0 1.636a4.09 4.09 0 1 0 0 8.182 4.09 4.09 0 0 0 0-8.182z"
        fill="url(#5fv8yrfjqa)"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default UserNewIconTwoTone;
