import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function OnAvailIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 25 21" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.147 9.26c1.374 0 2.566.789 3.15 1.937.222-.06.453-.1.69-.116l.239-.008h2.36a3.538 3.538 0 0 1 3.529 3.34l.005.193v3.774c0 1.118-.87 2.037-1.969 2.115l-.15.005H2.12a2.123 2.123 0 0 1-2.115-1.969L0 18.381v-3.775a3.538 3.538 0 0 1 3.34-3.528l.193-.005h2.361c.322 0 .633.043.929.124a3.538 3.538 0 0 1 2.935-1.93l.215-.007h4.174zm-8.253 3.226h-2.36c-1.119 0-2.038.87-2.115 1.969l-.006.15v3.775c0 .357.267.653.611.7l.096.007h4.32v-6.294c0-.078.004-.156.009-.233a2.113 2.113 0 0 0-.555-.074zm8.253-1.813H9.973c-1.118 0-2.036.87-2.114 1.97l-.006.15v6.294h8.414v-6.294c0-1.169-.951-2.12-2.12-2.12zm6.44 1.813h-2.361c-.192 0-.378.026-.555.074l.007.116.002.117v6.294H22a.708.708 0 0 0 .7-.611l.007-.096v-3.774c0-1.17-.951-2.12-2.12-2.12zM4.714 4.4a3.143 3.143 0 0 1 3.14 3.14 3.143 3.143 0 0 1-3.14 3.139 3.143 3.143 0 0 1-3.14-3.14 3.143 3.143 0 0 1 3.14-3.14zm14.692 0a3.143 3.143 0 0 1 3.14 3.14 3.143 3.143 0 0 1-3.14 3.139 3.143 3.143 0 0 1-3.14-3.14 3.143 3.143 0 0 1 3.14-3.14zM4.714 5.813c-.952 0-1.727.774-1.727 1.726s.775 1.727 1.727 1.727c.952 0 1.726-.775 1.726-1.727 0-.952-.774-1.726-1.726-1.726zm14.692 0c-.952 0-1.726.774-1.726 1.726s.774 1.727 1.726 1.727 1.727-.775 1.727-1.727c0-.952-.775-1.726-1.727-1.726zM12.06.5a4.2 4.2 0 0 1 4.195 4.195 4.2 4.2 0 0 1-4.195 4.194 4.2 4.2 0 0 1-4.194-4.194A4.2 4.2 0 0 1 12.06.5zm0 1.413A2.784 2.784 0 0 0 9.28 4.695a2.784 2.784 0 0 0 2.781 2.78 2.784 2.784 0 0 0 2.781-2.78 2.784 2.784 0 0 0-2.78-2.782z"
          fill={props.htmlColor || '#677482'}
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default OnAvailIcon;
