import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    accordion: {
      background: '#1d232c',
      paddingLeft: '10px',
      paddingRight: '10px',
      borderRadius: '8px !important',
      marginBottom: '15px',
      '& .MuiFormLabel-root': {
        color: '$c2ccd8',
      },
      '&::before': {
        height: 'unset !important',
      },
      '& .Mui-expanded': {
        margin: '0px',
      },
      '& .MuiIconButton-label span': {
        height: '20px',
        width: '20px',
      },
      '& .MuiIconButton-label svg': {
        height: '10px !important',
        width: '14px !important',
        top: '13px !important',
      },
      '& .MuiFormControlLabel-label': {
        marginLeft: '5px',
      },
    },

    accordionHeader: {
      padding: 0,
      '& .MuiAccordionSummary-content': {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'flex-start',
        alignItems: 'center',
        flexWrap: 'wrap',
        margin: '0px',
        '& > *, & > *:first-child > *': {
          margin: '5px 0px',
        },
        '& > *:not(:last-child)': {
          marginRight: '20px',
        },
        '& > *:nth-child(2)': {
          marginLeft: 'auto',
        },
      },
      '& svg': {
        width: '14px',
      },
      '& svg path': {
        fill: '#3e8bff',
      },
    },
    accordionHeader__right: {
      display: 'flex',
      alignItems: 'center',
      gap: 4,
    },
    arrowDown: {
      width: '24px !important',
      '& path': {
        fill: '#FFFFFF !important',
      },
    },
    downloadButton: {
      '&.Mui-disabled': {
        '& .MuiSvgIcon-root': {
          '& path': {
            fill: `${theme.palette.customGrey.dark} !important`,
          },
        },
      },
    },
    arrowUp: {
      width: '24px !important',
    },
    auditionContainer: {
      padding: '0px 10px',
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'flex-start',
      '& > *:not(:last-child)': {
        marginBottom: '5px',
      },
    },
  }),
);
