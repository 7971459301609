export const convertTime12to24 = (time12h: string) => {
  const [time, modifier] = time12h.split(' ');

  let hours = Number(time.split(':')[0]);
  const minutes = Number(time.split(':')[1]);

  if (hours === 12) {
    hours = 0;
  }

  if (modifier === 'PM') {
    hours = hours + 12;
  }

  return {
    hours,
    minutes,
  };
};

export const convertTime24to12 = (time24: string) => {
  const [hourString, minute] = time24.split(':');
  const hour = +hourString % 24;
  return (hour % 12 || 12) + ':' + minute + ' ' + (hour < 12 ? 'AM' : 'PM');
};
