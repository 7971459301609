import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Archive(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M7.031 12.93v1.758c0 .29.213.532.491.578l.095.007h4.766a.586.586 0 0 0 .578-.49l.008-.095V12.93H20v6.484a.586.586 0 0 1-.49.578l-.096.008H.586a.586.586 0 0 1-.578-.49L0 19.413V12.93h7.031zm8.867-9.375c.292 0 .533.212.579.49l.007.096v7.617h-4.101a.586.586 0 0 0-.578.49l-.008.096v1.758H8.203v-1.758a.587.587 0 0 0-.49-.579l-.096-.008H3.516V4.141c0-.292.212-.533.49-.579l.096-.007h11.796zm1.758 5.096 2.072 3.107h-2.072V8.65zm-15.312 0v3.107H.272L2.344 8.65zm8.867.763H6.445a.586.586 0 0 0-.095 1.164l.095.008h4.766a.586.586 0 1 0 0-1.172zm2.344-2.344h-7.11a.586.586 0 0 0-.095 1.165l.095.007h7.11a.586.586 0 1 0 0-1.172zm0-2.343h-7.11A.586.586 0 0 0 6.35 5.89l.095.007h7.11a.586.586 0 1 0 0-1.171zM14.727 0H5.273a.586.586 0 0 0-.585.586v1.797h10.625V.586A.586.586 0 0 0 14.726 0z"
          fill="#C2CCD8"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default Archive;
