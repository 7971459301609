import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import {
  ICreateStaffPayload,
  ICreateStaffResponsePayload,
  IStaffArchiveResponsePayload,
  IStaffResponsePayload,
} from 'shared/interfaces/IStaff';
import { StaffType } from 'shared/enums/StaffType';

const { GET, POST, PATCH, DELETE } = useAxios();
export const staffDao = () => {
  const getStaff = async (type?: StaffType) => {
    const payload = type ? { position_name: type } : {};
    const response = await GET<IStaffResponsePayload>({
      url: ENDPOINTS.STAFF,
      params: payload,
    });
    return response.data;
  };

  const createStaff = async (payload: ICreateStaffPayload) => {
    const response = await POST<ICreateStaffResponsePayload>({
      url: ENDPOINTS.STAFF,
      data: payload,
    });

    return response.data;
  };

  const updateStaff = async (staffId: string, payload: ICreateStaffPayload) => {
    const response = await PATCH<ICreateStaffResponsePayload>({
      url: `${ENDPOINTS.STAFF}/${staffId}`,
      data: payload,
    });
    return response.data;
  };

  const archiveStaff = async (staffId: string) => {
    const response = await PATCH<IStaffArchiveResponsePayload>({
      url: `${ENDPOINTS.STAFF}/${staffId}/archived`,
    });

    return response.data;
  };

  const unarchiveStaff = async (staffId: string) => {
    const response = await DELETE<IStaffArchiveResponsePayload>({
      url: `${ENDPOINTS.STAFF}/${staffId}/archived`,
    });

    return response.data;
  };

  const updateStaffPermissions = async (staffId: string, payload: string[]) => {
    const response = await PATCH<ICreateStaffResponsePayload>({
      url: `${ENDPOINTS.STAFF}/${staffId}/permissions`,
      data: { permission_ids: payload },
    });

    return response.data;
  };

  return {
    getStaff,
    createStaff,
    updateStaff,
    archiveStaff,
    unarchiveStaff,
    updateStaffPermissions,
  };
};
