import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles(() =>
  createStyles({
    bodyContainer: {
      display: 'flex',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '15px',
      minHeight: '100vh',
      position: 'relative',
    },
    bgSvg: {
      position: 'absolute',
      display: 'flex',
      flexDirection: 'row',
      flexWrap: 'nowrap',
      justifyContent: 'center',
      alignItems: 'center',
      width: '100%',
      overflow: 'hidden',
      zIndex: -1,
      '& > *:nth-child(2)': {
        color: '#10131b',
        fontSize: '18vw',
        margin: '0px 100px',
      },
      '& img': {
        height: 'calc(100vh - 300px)',
      },
    },
    errorContainer: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      width: '100%',
      maxWidth: '425px',
      textAlign: 'center',
      '& > *:not(:last-child)': {
        marginBottom: '30px',
      },
    },
  }),
);
