// import { div } from '@material-ui/core';
import React, { useMemo, useState } from 'react';
import { StyledTab, StyledTabs } from 'shared/elements';
import { useStyles } from './TalentProfileDetail.styles';
import { useParams } from 'react-router';
import { ITab } from '@room-match/shared-ui-components';
import ResumeContent from './TabContent/ResumeContent/ResumeContent';
import AudioContent from './TabContent/AudioContent';
import CompositeCardContent from './TabContent/CompositeCardContent/CompositeCardContent';
import SocialFeedContent from './TabContent/SocialFeedContent/SocialFeedContent';
import { IProfile } from 'shared/interfaces/IProfile';
import { IMediumAttributes } from 'shared/interfaces/IMedium';

type Props = {
  profile: IProfile;
  handleSetResumeMedia: (instance: IMediumAttributes[]) => void;
};
const TalentProfileDetails: React.FC<Props> = ({ profile, handleSetResumeMedia }) => {
  const classes = useStyles();
  const [tab, setTab] = useState<string>('resume');

  const audios = useMemo(() => {
    return profile.attributes.audios.data
      ? profile.attributes.audios.data.map((audio) => ({
          ...audio.attributes,
          file_name: audio.attributes.medium.data.attributes.file_name,
        }))
      : [];
  }, [profile]);

  const tabs: ITab[] = useMemo(() => {
    return [
      {
        name: 'resume',
        header: 'Resume',
        component: <ResumeContent />,
      },
      {
        name: 'audio',
        header: `Audio (${audios.length})`,
        component: <AudioContent />,
      },

      {
        name: 'composite_card',
        header: 'Composite Card',
        component: <CompositeCardContent />,
        disabled: true,
      },
      {
        name: 'social_feed',
        header: 'Social Feed',
        component: <SocialFeedContent />,
        disabled: true,
      },
    ];
  }, [audios]);

  const getActiveTab = useMemo(() => {
    if (!tab) {
      tabs[0].component = (
        <ResumeContent
          resumeData={profile.attributes?.resume?.categories || []}
          handleSetResumeMedia={handleSetResumeMedia}
        />
      );
      return tabs[0].component;
    }
    const currentTab = tabs.find((_tab) => _tab.name === tab);
    if (currentTab?.name === 'resume') {
      currentTab.component = (
        <ResumeContent
          resumeData={profile.attributes?.resume?.categories || []}
          handleSetResumeMedia={handleSetResumeMedia}
        />
      );
      return currentTab?.component;
    }
    if (currentTab?.name === 'audio') {
      currentTab.component = <AudioContent audios={audios} />;
      return currentTab?.component;
    }
    return tabs.find((_tab) => _tab.name === tab)?.component;
  }, [tab, tabs, profile, audios, handleSetResumeMedia]);

  const handleTabChange = (event: React.ChangeEvent<{}>, newValue: string) => {
    if (tab !== newValue) {
      setTab(newValue);
    }
  };
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    };
  }
  return (
    <div className={classes.profileDetail}>
      <StyledTabs value={!tab ? 'resume' : tab} onChange={handleTabChange} aria-label="simple tabs example">
        {tabs.map((tab, index) => (
          <StyledTab key={index} label={tab.header} value={tab.name} disabled={tab.disabled} {...a11yProps(index)} />
        ))}
      </StyledTabs>
      <div className={classes.profileDetailTabContainer}>{getActiveTab}</div>
    </div>
  );
};

export default TalentProfileDetails;
