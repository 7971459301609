import { Grid, IconButton, Button, InputLabel, Chip } from '@material-ui/core';

import React, { useState, useEffect, useMemo } from 'react';
import { useParams } from 'react-router';
import { useQueryClient } from 'react-query';
import { TextInput, Typography } from 'shared/elements';
import { useStyles } from './CastingContactInformation.styles';
import { useWorksheetSectionStyle } from 'shared/theme/styles/worksheetSectionStyle';
import { IProject } from 'shared/interfaces/IProject';
import { ellipseText } from '@room-match/shared-utils';
import { ContactInput } from 'shared/elements/ContactInput';
import { projectService } from 'shared/services/projectService';
import { staffService } from 'shared/services/staffService';
import { StaffType } from 'shared/enums/StaffType';
import { IProjectCreatePayload } from 'shared/interfaces/IProject';
import { useEmailValidation } from 'shared/hooks/useEmailValidation';
import { getErrorMessage } from 'shared/utils/getErrorMessage';
import { COLORS } from 'shared/constants/COLORS';
import { CONTACT_INFO_TO } from 'shared/constants/CONTACT_INFO_TO';
import { ArrowUpIcon, DocumentAddIcon, SelectArrowDown } from 'components/Icons';
import {
  ArrowDownIconTwoTone,
  Select,
  RemoveIconTwoTone,
  Input,
  LoadingSpinnerIconTwoTone,
  CloseIcon,
  CheckboxCheckIconTwoTone,
} from '@room-match/shared-ui-components';
import clsx from 'clsx';
import { FormikProps } from 'formik';

type Props = {
  tab?: string;
  form: FormikProps<IProjectCreatePayload>;
  project: IProject;
  director: any;
  associate: any;
  assistant: any;
  assignedStaff: any;
  handleSetStaff: (instance: any, str: string) => void;
  handleIsValidEmailAddress: (instance: boolean) => void;
  isCollapse: boolean;
  toggleCollapse: (accordionItem: string) => void;
};
const { detachProjectStaff } = projectService();
const { getStaff } = staffService();
const CastingContactInformation: React.FC<Props> = ({
  tab,
  form,
  project,
  director,
  associate,
  assistant,
  assignedStaff,
  handleSetStaff,
  handleIsValidEmailAddress,
  isCollapse,
  toggleCollapse,
}) => {
  const { isEmailValidationLoading, isValidEmail, handleEmailChange, emailValue } = useEmailValidation();
  const { projectId: projectIdFromUrl } = useParams() as {
    projectId: string | undefined;
  };
  const queryClient = useQueryClient();
  const classes = useStyles();
  const sectionClasses = useWorksheetSectionStyle();

  const [castingDirectors, setCastingDirectors] = useState<any>([]);
  const [castingAssociates, setCastingAssociates] = useState<any>([]);
  const [castingAssistants, setCastingAssistants] = useState<any>([]);
  const [castingDirector, setCastingDirector] = useState<any>([]);
  const [castingAssociate, setCastingAssociate] = useState<any>([]);
  const [castingAssistant, setCastingAssistant] = useState<any>([]);
  const [currentAssignedStaffs, setCurrentAssignedStaffs] = useState<string[]>([]);

  const { data: castingDirectorsData } = getStaff(StaffType.CastingDirector);
  const { data: associatesData } = getStaff();
  const { data: assistantsData } = getStaff(StaffType.Assistant);
  const { mutateAsync: mutatedDetatchProjectStaff } = detachProjectStaff();

  const handleDetachStaff = async (itemId: string) => {
    if (project) {
      const { data = [] }: any = project.attributes.staff_attachments;
      const selectedAttachment = data.filter((item: any) => item.attributes.staff.data.id === itemId);
      if (selectedAttachment.length > 0 && projectIdFromUrl) {
        const data = await mutatedDetatchProjectStaff({
          projectId: projectIdFromUrl,
          attachmentId: selectedAttachment[0].id,
        });
        queryClient.invalidateQueries(['projects', projectIdFromUrl], { refetchInactive: true });
      }
    }
  };

  const handleRemoveDirector = (itemId: string) => {
    if (currentAssignedStaffs.includes(itemId)) {
      handleDetachStaff(itemId);
    } else {
      const filtered = castingDirector.filter((item: string) => item !== itemId);
      handleSetStaff(filtered, 'director');
    }
  };

  const handleRemoveAssociate = (itemId: string) => {
    if (currentAssignedStaffs.includes(itemId)) {
      handleDetachStaff(itemId);
    } else {
      const filtered = castingAssociate.filter((item: string) => item !== itemId);
      handleSetStaff(filtered, 'associate');
    }
  };

  const handleRemoveAssistant = (itemId: string) => {
    if (currentAssignedStaffs.includes(itemId)) {
      handleDetachStaff(itemId);
    } else {
      const filtered = castingAssistant.filter((item: string) => item !== itemId);
      handleSetStaff(filtered, 'assistant');
    }
  };

  const handleCastingLabel = (itemId: string, data: any) => {
    const filtered = data.filter((item: any) => item.value === itemId);
    return filtered.length > 0 ? ellipseText(filtered[0].key, 25) : '';
  };

  const isValidEmailAddress = useMemo(() => {
    return isValidEmail ? isValidEmail : false;
  }, [isValidEmail]);

  useEffect(() => {
    handleIsValidEmailAddress(isValidEmailAddress);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isValidEmailAddress]);

  useEffect(() => {
    if (castingDirectorsData) {
      const newCdData = castingDirectorsData.data.map((item: any) => ({
        key: `${item.attributes.user.data.attributes.full_name}`,
        value: item.id,
      }));
      setCastingDirectors(newCdData);
    }
    if (associatesData) {
      const newAssociatesData = associatesData.data.map((item: any) => ({
        key: `${item.attributes.user.data.attributes.full_name}`,
        value: item.id,
      }));
      setCastingAssociates(newAssociatesData);
    }
    if (assistantsData) {
      const newAData = assistantsData.data.map((item: any) => ({
        key: `${item.attributes.user.data.attributes.full_name}`,
        value: item.id,
      }));
      setCastingAssistants(newAData);
    }
    if (director) {
      setCastingDirector(director);
    }
    if (associate) {
      setCastingAssociate(associate);
    }
    if (assistant) {
      setCastingAssistant(assistant);
    }
    if (assignedStaff) {
      setCurrentAssignedStaffs(assignedStaff);
    }
  }, [castingDirectorsData, associatesData, assistantsData, project, director, associate, assistant, assignedStaff]);

  return (
    <Grid container spacing={0} className={sectionClasses.root}>
      <Grid item className={sectionClasses.header}>
        <Grid item>
          <Typography>3. Casting Contact Information</Typography>
        </Grid>
        <Grid item>
          <Button
            className={sectionClasses.collapseButton}
            startIcon={isCollapse ? <ArrowUpIcon /> : <ArrowDownIconTwoTone />}
            onClick={() => toggleCollapse('CCI')}
          >
            Collapse
          </Button>
        </Grid>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ marginTop: '10px', overflow: isCollapse ? 'hidden' : 'unset' }}
        className={clsx(sectionClasses.gapLessColumn, {
          [sectionClasses.content__isNotCollapsed]: !isCollapse,
        })}
      >
        <Grid item xs={12} sm={12} md={6} lg={4}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <InputLabel className={classes.inputLabelCls} id="showContact">
                Show Contact Information to
              </InputLabel>
              <Select
                name="show_contact_info_to"
                variant="outlined"
                fullWidth
                data={CONTACT_INFO_TO}
                value={form.values.show_contact_info_to}
                onChange={(e) => form.setFieldValue('show_contact_info_to', e.target.value)}
                IconComponent={SelectArrowDown}
              />
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <InputLabel className={classes.inputLabelCls} id="castingDirector">
                Casting Director
              </InputLabel>
              <Select
                name="director"
                variant="outlined"
                fullWidth
                data={castingDirectors}
                value={castingDirector}
                placeHolder="Select Director"
                onChange={(e) => handleSetStaff(e.target.value, 'director')}
                IconComponent={SelectArrowDown}
                multiple
              />
              <div className={classes.customChipContainer}>
                {castingDirector.map((item_id: any, v: number) => {
                  return (
                    <Chip
                      className={classes.customChip}
                      key={item_id}
                      deleteIcon={<RemoveIconTwoTone width="10" height="8" viewBox="0 0 10 8" fill="none" />}
                      onDelete={() => handleRemoveDirector(item_id)}
                      variant="outlined"
                      label={handleCastingLabel(item_id, castingDirectors)}
                    />
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <InputLabel className={classes.inputLabelCls} id="castingAssociate">
                Casting Associate
              </InputLabel>
              <Select
                name="associate"
                variant="outlined"
                fullWidth
                data={castingAssociates}
                value={castingAssociate}
                placeHolder="Select Associate"
                onChange={(e) => handleSetStaff(e.target.value, 'associate')}
                IconComponent={SelectArrowDown}
                multiple
              />
              <div className={classes.customChipContainer}>
                {castingAssociate.map((item_id: any, v: number) => {
                  return (
                    <Chip
                      className={classes.customChip}
                      key={item_id}
                      deleteIcon={<RemoveIconTwoTone width="10" height="8" viewBox="0 0 10 8" fill="none" />}
                      onDelete={() => handleRemoveAssociate(item_id)}
                      variant="outlined"
                      label={handleCastingLabel(item_id, castingAssociates)}
                    />
                  );
                })}
              </div>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={6}>
              <InputLabel className={classes.inputLabelCls} id="castingAssistant">
                Casting Assistant
              </InputLabel>
              <Select
                name="assistant"
                variant="outlined"
                fullWidth
                data={castingAssistants}
                value={castingAssistant}
                placeHolder="Select Assistant"
                onChange={(e) => handleSetStaff(e.target.value, 'assistant')}
                IconComponent={SelectArrowDown}
                multiple
              />
              <div className={classes.customChipContainer}>
                {castingAssistant.map((item_id: any, v: number) => {
                  return (
                    <Chip
                      className={classes.customChip}
                      key={item_id}
                      deleteIcon={<RemoveIconTwoTone width="10" height="8" viewBox="0 0 10 8" fill="none" />}
                      onDelete={() => handleRemoveAssistant(item_id)}
                      variant="outlined"
                      label={handleCastingLabel(item_id, castingAssistants)}
                    />
                  );
                })}
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={3}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={12} md={12} lg={12} className={classes.contactBypass}>
              <ContactInput
                name="contact_number"
                variant="outlined"
                dropdownStyle={{ height: '50px', marginBottom: '0px' }}
                showLabel={true}
                placeholder="Type here..."
                handleCodeChange={(val: any) => {
                  form.setFieldValue('country_code', val.countryCode);
                }}
                country={(form.values.country_code || 'us').toLowerCase()}
                onChange={(e) => {
                  if (form.errors.contact_number && !form.touched.contact_number) {
                    form.setFieldTouched('contact_number');
                    form.validateField('contact_number');
                  }
                  return form.handleChange(e);
                }}
                value={form.values.contact_number}
              />
            </Grid>
          </Grid>
        </Grid>
        <Grid item xs={12} sm={12} md={6} lg={2}>
          <Grid container spacing={3}>
            <Grid item xs={12} sm={12} md={12} lg={12}>
              <InputLabel className={classes.inputLabelCls}>Contact Email Address</InputLabel>
              <Input
                name="contact_email"
                placeholder="Type here..."
                type="email"
                variant="outlined"
                fullWidth
                InputProps={{
                  tabIndex: 1,
                  endAdornment: (
                    <>
                      {emailValue && (
                        <>
                          {isEmailValidationLoading ? (
                            <LoadingSpinnerIconTwoTone style={{ fontSize: 40 }} />
                          ) : (
                            <>
                              {isValidEmail ? (
                                <CheckboxCheckIconTwoTone style={{ fontSize: 12 }} />
                              ) : (
                                <CloseIcon style={{ fontSize: 12 }} htmlColor={COLORS.DANGER} />
                              )}
                            </>
                          )}
                        </>
                      )}
                    </>
                  ),
                }}
                value={form.values.contact_email}
                onChange={(e) => {
                  return form.handleChange(e);
                }}
                onBlur={(e) => {
                  handleEmailChange(e.target.value);
                  return form.handleBlur(e);
                }}
                errorMessage={getErrorMessage(
                  form.touched.contact_email,
                  form.errors.contact_email
                    ? form.errors.contact_email
                    : !isValidEmail && !isEmailValidationLoading
                    ? 'Invalid Email'
                    : '',
                )}
              />
            </Grid>
          </Grid>
        </Grid>
      </Grid>
      {/* </Grid> */}
    </Grid>
  );
};

export default CastingContactInformation;
