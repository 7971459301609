import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function UserBoxIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 17 21" xmlns="http://www.w3.org/2000/svg">
        <path
          d="M14.375.5a2.294 2.294 0 0 1 2.292 2.292v15.416a2.294 2.294 0 0 1-2.292 2.292H2.292A2.294 2.294 0 0 1 0 18.208V2.792A2.294 2.294 0 0 1 2.292.5h12.083zm1.041 15H1.25v2.708c0 .574.468 1.042 1.042 1.042h12.083c.574 0 1.042-.468 1.042-1.042l-.001-2.708zM14.375 1.75H2.292c-.574 0-1.042.468-1.042 1.042V14.25h2.916v-1.042c0-1.569 1.753-2.708 4.167-2.708 2.415 0 4.167 1.14 4.167 2.708v1.042h2.916V2.792c0-.574-.467-1.042-1.041-1.042zm-6.042 10c-1.801 0-2.916.757-2.916 1.458v1.042h5.833v-1.042c0-.701-1.115-1.458-2.917-1.458zm0-7.083c1.379 0 2.5 1.121 2.5 2.5 0 1.378-1.121 2.5-2.5 2.5a2.503 2.503 0 0 1-2.5-2.5c0-1.379 1.122-2.5 2.5-2.5zm0 1.25c-.689 0-1.25.56-1.25 1.25 0 .689.561 1.25 1.25 1.25.69 0 1.25-.561 1.25-1.25 0-.69-.56-1.25-1.25-1.25z"
          fill="#677482"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default UserBoxIcon;
