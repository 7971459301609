import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const ImageIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon width="19" height="14" viewBox="0 0 19 14" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.528 0a2.14 2.14 0 0 1 2.139 2.139v9.722A2.14 2.14 0 0 1 16.527 14H2.14A2.14 2.14 0 0 1 0 11.861V2.14A2.14 2.14 0 0 1 2.139 0h14.389zm-4.612 5.242L7.8 9.357a.583.583 0 0 1-.825 0L5.584 7.964c-.1-.098-.18-.098-.28 0l-4.113 4.114a.974.974 0 0 0 .948.755h14.389a.974.974 0 0 0 .972-.972l-.001-1.315-5.304-5.304a.199.199 0 0 0-.28 0zm4.612-4.075H2.138a.974.974 0 0 0-.971.972l-.001 8.314L4.48 7.139a1.365 1.365 0 0 1 1.929 0l.98.98 3.702-3.702a1.365 1.365 0 0 1 1.929 0l4.479 4.479.001-6.757a.974.974 0 0 0-.972-.972zM3.888 2.333a1.557 1.557 0 0 1 0 3.111A1.557 1.557 0 0 1 2.334 3.89c0-.858.698-1.556 1.556-1.556zm0 1.167a.389.389 0 1 0 .001.778.389.389 0 0 0 0-.778z"
        fill={props.htmlColor || '#fff'}
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default ImageIcon;
