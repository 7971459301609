import { QueryKey, useMutation, useQueries, useQuery, UseQueryOptions } from 'react-query';
import { auditionScheduleFlagDao } from 'shared/dao/auditionScheduleFlagDao';
import {
  IAuditionFlagResponsePayload,
  IAuditionFlagCreatePayload,
  IAuditionFlagMutateResponsePayload,
} from 'shared/interfaces/IAuditionFlag';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';

const {
  getAuditionScheduleFlags: getAuditionScheduleFlagsDao,
  createAuditionScheduleFlag: createAuditionScheduleFlagDao,
  updateAuditionScheduleFlag: updateAuditionScheduleFlagDao,
  deleteAuditionScheduleFlag: deleteAuditionScheduleFlagDao,
} = auditionScheduleFlagDao();
export const auditionScheduleFlagService = () => {
  const getAuditionScheduleFlags = (
    auditionScheduleId: string,
    options?: Omit<
      UseQueryOptions<IAuditionFlagResponsePayload, IAxiosErrorResponse, IAuditionFlagResponsePayload, QueryKey>,
      'queryKey' | 'queryFn'
    >,
  ) => {
    return useQuery<IAuditionFlagResponsePayload, IAxiosErrorResponse>(
      ['audition_schedule_flag', auditionScheduleId],
      () => getAuditionScheduleFlagsDao(auditionScheduleId),
      options,
    );
  };

  const createAuditionScheduleFlag = () => {
    return useMutation<
      IAuditionFlagMutateResponsePayload,
      IAxiosErrorResponse,
      { auditionScheduleId: string; payload: IAuditionFlagCreatePayload }
    >(({ auditionScheduleId, payload }) => createAuditionScheduleFlagDao(auditionScheduleId, payload));
  };

  const updateAuditionScheduleFlag = () => {
    return useMutation<
      IAuditionFlagMutateResponsePayload,
      IAxiosErrorResponse,
      { auditionScheduleId: string; payload: IAuditionFlagCreatePayload; flagId: string }
    >(({ auditionScheduleId, payload, flagId }) => updateAuditionScheduleFlagDao(auditionScheduleId, payload, flagId));
  };

  const deleteAuditionScheduleFlag = () => {
    return useMutation<
      IAuditionFlagMutateResponsePayload,
      IAxiosErrorResponse,
      { auditionScheduleId: string; flagId: string }
    >(({ auditionScheduleId, flagId }) => deleteAuditionScheduleFlagDao(auditionScheduleId, flagId));
  };

  const multipleGetAuditionScheduleFlag = (auditionScheduleIds: string[]) => {
    return useQueries<IAuditionFlagResponsePayload[]>(
      auditionScheduleIds.map((id) => {
        return {
          queryKey: ['audition_schedule_flag', id],
          queryFn: () => getAuditionScheduleFlagsDao(id),
        };
      }),
    );
  };

  return {
    getAuditionScheduleFlags,
    createAuditionScheduleFlag,
    updateAuditionScheduleFlag,
    deleteAuditionScheduleFlag,
    multipleGetAuditionScheduleFlag,
  };
};
