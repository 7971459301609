import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const FlagOrangeIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 17 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="m14.25 6 2.55-3.4A1 1 0 0 0 16 1H4a1 1 0 1 0-2 0v17H1a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2H4v-7h12a1 1 0 0 0 .8-1.6L14.25 6z"
        fill="#E58F37"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default FlagOrangeIconTwoTone;
