import { Grid, IconButton, Button, InputLabel } from '@material-ui/core';

import React, { useState } from 'react';

import { TextInput, Typography } from 'shared/elements';

import { useStyles } from './ConflictsAndUsage.styles';
import { useWorksheetSectionStyle } from 'shared/theme/styles/worksheetSectionStyle';
import { ArrowUpIcon, DocumentAddIcon, SelectArrowDown } from 'components/Icons';
import { ArrowDownIconTwoTone, Select } from '@room-match/shared-ui-components';
import { FormikProps } from 'formik';
import { IBookedCreatePayload } from 'shared/interfaces/IAudition';
import { IProjectCreatePayload } from 'shared/interfaces/IProject';
import clsx from 'clsx';

type Props = {
  form: FormikProps<IProjectCreatePayload>;
  isCollapse: boolean;
  toggleCollapse: (accordionItem: string) => void;
};
const ConflictsAndUsage: React.FC<Props> = ({ form, isCollapse, toggleCollapse }) => {
  const classes = useStyles();
  const sectionClasses = useWorksheetSectionStyle();

  return (
    <Grid container spacing={0} className={sectionClasses.root}>
      <Grid item className={sectionClasses.header}>
        <Grid item>
          <Typography>2. Conflicts and Usage</Typography>
        </Grid>
        <Grid item>
          <Button
            className={sectionClasses.collapseButton}
            startIcon={isCollapse ? <ArrowUpIcon /> : <ArrowDownIconTwoTone />}
            onClick={() => toggleCollapse('CAU')}
          >
            Collapse
          </Button>
        </Grid>
      </Grid>
      <Grid
        item
        className={clsx(sectionClasses.twoColumn, {
          [sectionClasses.content__isNotCollapsed]: !isCollapse,
        })}
      >
        <Grid item>
          <Grid item>
            <InputLabel id="typeOfAudition" className={sectionClasses.selectLabelFix}>
              Conflicts
            </InputLabel>
            <Select
              variant="outlined"
              name="conflicts"
              fullWidth
              data={[]}
              value={form.values.conflicts}
              onChange={form.handleChange}
              IconComponent={SelectArrowDown}
            />
          </Grid>
          <Grid item>
            <TextInput
              label="Conflicts Details"
              labelId="conflictsDetails"
              tooltip={true}
              tooltipTitle="Conflicts Details"
              value={form.values.conflicts_details}
              name="conflicts_details"
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              textArea={true}
              textAreaMinRows={5}
            />
          </Grid>
        </Grid>
        <Grid item>
          <Grid item>
            <TextInput
              label="Usage / Run"
              labelId="usageRun"
              tooltip={true}
              tooltipTitle="Usage / Run"
              name="usage"
              value={form.values.usage}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              textArea={true}
              textAreaMinRows={3}
            />
          </Grid>
          <Grid item>
            <TextInput
              label="Additional Provisions"
              labelId="additionalProvisions"
              tooltip={true}
              tooltipTitle="Additional Provisions"
              name="additional_provisions"
              value={form.values.additional_provisions}
              onChange={form.handleChange}
              onBlur={form.handleBlur}
              textArea={true}
              textAreaMinRows={3}
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  );
};

export default ConflictsAndUsage;
