import { computeProgressEvent, IProgressEvent } from '@room-match/shared-utils';
import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { ICommonResponsePayload } from 'shared/interfaces/ICommon';
import {
  IProjectContactAttachmentRequestPayload,
  IProjectContactAttachmentResponsePayload,
  IProjectCreatePayload,
  IProjectCreateResponsePayload,
  IProjectGetCastingSessionRequestPayload,
  IProjectGetCastingSessionResponsePayload,
  IProjectImportResponsePayload,
  IProjectRequestPayload,
  IProjectResponsePayload,
  IProjectSearchCastingRoleRequestPayload,
  IProjectSearchCastingRoleResponsePayload,
  IProjectSearchResponsePayload,
  ISingleProjectResponsePayload,
  IProjectUpdateAttachedContactRequestPayload,
  IProjectUpdateAttachedContactResponsePayload,
  IProjectQuestionAtttachmentResponsePayload,
  IProjectNdaPayload,
  IProjectQuestionSortUpdatePayload,
  IProjectQuestionUpdatePayload,
  IProjectQuestionsAttachPayload,
  IProjectContactsResponsePayload,
} from 'shared/interfaces/IProject';
import { IStaffAttachPayload, IStaffAttachResponsePayload, IStaffAttachSidesPayload } from 'shared/interfaces/IStaff';
import { IQuestionRequestPayload, IQuestionsAttachResponsePayload } from 'shared/interfaces/IQuestion';
import { IOnProgress } from 'shared/interfaces/utils/IAxios';
import {
  GET_CASTING_SESSIONS_BY_PROJECT,
  SEARCH_CASTING_ROLES_BY_PROJECT,
  SEARCH_PROJECTS,
} from 'shared/queries/projects.queries';

const { GET, POST, PUT, PATCH, DELETE } = useAxios();
export const projectDao = () => {
  const getProjects = async (payload?: IProjectRequestPayload) => {
    const response = await GET<IProjectResponsePayload>({
      url: ENDPOINTS.PROJECT,
      params: payload,
    });
    return response.data;
  };

  const getSingleProject = async (projectId: string) => {
    const response = await GET<ISingleProjectResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}`,
    });
    return response.data;
  };

  const createProject = async (payload: IProjectCreatePayload) => {
    const response = await POST<IProjectCreateResponsePayload>({
      url: ENDPOINTS.PROJECT,
      data: payload,
    });
    return response.data;
  };

  const updateProject = async (projectId: string, payload: IProjectCreatePayload | IProjectNdaPayload) => {
    const response = await PATCH<IProjectCreateResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}`,
      data: payload,
    });
    return response.data;
  };

  const importProject = async (formData: FormData, onProgress?: IOnProgress) => {
    const response = await PUT<IProjectImportResponsePayload>({
      url: ENDPOINTS.PROJECT,
      data: formData,
      onUploadProgress: (progressEvent: IProgressEvent) => {
        if (onProgress) {
          onProgress(computeProgressEvent(progressEvent));
        }
      },
    });

    return response.data;
  };

  const searchProjects = async (query: string) => {
    const response = await POST<IProjectSearchResponsePayload>({
      url: ENDPOINTS.GRAPHQL,
      data: {
        query: SEARCH_PROJECTS,
        variables: {
          search: String(query),
        },
      },
    });

    if (response.data.errors) {
      throw new Error('Error');
    }

    return response.data;
  };

  const searchCastingRolesByProject = async (payload: IProjectSearchCastingRoleRequestPayload) => {
    const response = await POST<IProjectSearchCastingRoleResponsePayload>({
      url: ENDPOINTS.GRAPHQL,
      data: {
        query: SEARCH_CASTING_ROLES_BY_PROJECT,
        variables: { ...payload },
      },
    });

    return response.data;
  };

  const getCastingSessionsByProject = async (payload: IProjectGetCastingSessionRequestPayload) => {
    const response = await POST<IProjectGetCastingSessionResponsePayload>({
      url: ENDPOINTS.GRAPHQL,
      data: {
        query: GET_CASTING_SESSIONS_BY_PROJECT,
        variables: { ...payload },
      },
    });
    return response.data;
  };

  const attachProjectStaff = async (projectId: string, payload: IStaffAttachPayload) => {
    const response = await POST<IStaffAttachResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/staff_attachments`,
      data: payload,
    });
    return response.data;
  };

  const attachDetachSessionDirectors = async (projectId: string, payload: { staff_ids: string[] }) => {
    const response = await POST<IStaffAttachResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/session_director_attachments`,
      data: payload,
    });
    return response.data;
  };

  const attachProjectQuestions = async (projectId: string, payload: IProjectQuestionsAttachPayload) => {
    const response = await POST<IQuestionsAttachResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/question_attachments`,
      data: payload,
    });
    return response.data;
  };

  const getProjectQuestions = async (projectId: string, payload: IQuestionRequestPayload) => {
    const response = await GET<IProjectQuestionAtttachmentResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/question_attachments`,
      params: payload,
    });
    return response.data;
  };

  const detachProjectStaff = async (projectId: string, attachmentId: string) => {
    const response = await DELETE<IStaffAttachResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/staff_attachments/${attachmentId}`,
    });
    return response.data;
  };

  const attachProjectSides = async (projectId: string, payload: IStaffAttachSidesPayload) => {
    const response = await POST<IStaffAttachResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/sides`,
      data: payload,
    });
    return response.data;
  };

  const attachProjectContacts = async (projectId: string, payload: IProjectContactAttachmentRequestPayload) => {
    const response = await POST<IProjectContactAttachmentResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/contact_attachments`,
      data: payload,
    });
    return response.data;
  };

  const detachProjectContacts = async (projectId: string, attachmentId: string) => {
    const response = await DELETE<ICommonResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/contact_attachments/${attachmentId}`,
    });
    return response.data;
  };

  const updateProjectContactAttachments = async (
    projectId: string,
    contactId: string,
    payload: IProjectUpdateAttachedContactRequestPayload,
  ) => {
    const response = await PATCH<IProjectUpdateAttachedContactResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/contact_attachments/${contactId}`,
      data: payload,
    });
    return response.data;
  };

  const getProjectContacts = async (projectId: string) => {
    const response = await GET<IProjectContactsResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/contact_attachments`,
    });
    return response.data;
  };

  const sortProjectQuestions = async (projectId: string, payload: IProjectQuestionSortUpdatePayload) => {
    const response = await PATCH<{ message: string }>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/order_question_attachments`,
      data: payload,
    });
    return response.data;
  };

  const updateProjectQuestion = async (
    projectId: string,
    attachmentId: string,
    payload: IProjectQuestionUpdatePayload,
  ) => {
    const response = await PATCH<{ message: string }>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/question_attachments/${attachmentId}`,
      data: payload,
    });
    return response.data;
  };

  return {
    getProjects,
    getSingleProject,
    createProject,
    updateProject,
    importProject,
    searchProjects,
    searchCastingRolesByProject,
    getCastingSessionsByProject,
    attachProjectStaff,
    attachProjectQuestions,
    getProjectQuestions,
    detachProjectStaff,
    attachDetachSessionDirectors,
    attachProjectSides,
    attachProjectContacts,
    detachProjectContacts,
    updateProjectContactAttachments,
    getProjectContacts,
    sortProjectQuestions,
    updateProjectQuestion,
  };
};
