import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const WarningCircleFilledIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 70 70" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M35 0A34.988 34.988 0 0 0 0 35a34.988 34.988 0 0 0 35 35 34.988 34.988 0 0 0 35-35A34.988 34.988 0 0 0 35 0zm0 60.21a5.287 5.287 0 1 1-.024-10.574A5.287 5.287 0 0 1 35 60.21zm6.4-43.567-1.994 26.923a.734.734 0 0 1-.735.686H31.33a.734.734 0 0 1-.735-.686L28.6 16.643a6.413 6.413 0 1 1 12.8 0z"
        fill="#E39604"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default WarningCircleFilledIcon;
