import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const ImportRoleIcon = (props: SvgIconProps) => {
  return (
    <SvgIcon viewBox="0 0 50 50" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="0%" y1=".004%" x2="100%" y2="99.996%" id="356pjd9c2a">
          <stop stop-color="#45EC9C" offset="0%" />
          <stop stop-color="#2FC07A" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M44.143 0c3.23 0 5.86 2.629 5.86 5.86v38.28c0 3.231-2.63 5.86-5.86 5.86H5.86a5.866 5.866 0 0 1-5.859-5.86v-9.374h3.906v9.375c0 1.077.876 1.953 1.953 1.953h38.282a1.955 1.955 0 0 0 1.953-1.953V5.859a1.955 1.955 0 0 0-1.953-1.953H5.86A1.955 1.955 0 0 0 3.908 5.86v9.375H.002V5.86C.002 2.63 2.631 0 5.862 0zM26.38 11.9l13.1 13.1-13.1 13.1-2.762-2.762 8.385-8.385H0v-3.906h32.004l-8.385-8.385L26.38 11.9z"
        fill="url(#356pjd9c2a)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default ImportRoleIcon;
