import { makeStyles, createStyles } from '@material-ui/styles';

export const useStyles = makeStyles((theme) =>
  createStyles({
    dialog: {
      '&.paper': {
        minWidth: '85%',
        minHeight: '90%',
      },
      '& .MuiDialog-paperWidthSm': {
        maxWidth: '585px !important',
      },
      '& .MuiTableBody-root .MuiTableRow-root .MuiTableCell-root': {
        borderBottom: '1px solid #2c3645',
      },
      '& .MuiTableBody-root .MuiTableRow-root:last-child .MuiTableCell-root': {
        borderBottom: 'none',
      },
      '& .MuiTableCell-head': {
        borderTop: '1px solid #2c3645',
        borderBottom: '1px solid #2c3645',
      },
    },
    closeIcon: {
      position: 'absolute',
      top: '20px',
      right: '20px',
      cursor: 'pointer',
    },
    logBody: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'flex-start',
      justifyContent: 'flex-start',
      padding: '20px',
      '& > *': {
        width: '100%',
      },
      '& > *:not(:last-child)': {
        marginBottom: '15px',
      },
    },
    headerContainer: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'flex-start',
      alignItems: 'center',
      flexWrap: 'nowrap',
    },
    headerDp: {
      background: '#808080',
      height: '60px',
      width: '45px',
      borderRadius: '5px',
      marginRight: '20px',
    },
    primaryText: {
      color: '#3e8bff',
    },
    darkText: {
      color: '#6e767f',
    },
    orangeText: {
      color: '#d7a300',
    },
    flexRow: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'nowrap',
    },
    eventCls: {
      display: 'flex',
      flexDirection: 'row',
      alignItems: 'center',
      flexWrap: 'nowrap',
      justifyContent: 'flex-end',
      '& svg': {
        width: '18px',
      },
    },
    svgCls: {
      width: '14px',
      marginRight: '10px',
    },
    onAvailCls: {
      '& svg path': {
        fill: '#de7c30',
      },
    },
    greenSvg: {
      '& path': {
        fill: '#41bb00',
      },
    },
  }),
);
