import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function CastingLogo(props: SvgIconProps) {
  return (
    <SvgIcon viewBox="0 0 35 34" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="40.313%" y1="95.922%" x2="70.395%" y2="5.978%" id="icasid8q5a">
          <stop stopColor="#D51BFF" offset="0%" />
          <stop stopColor="#00D4FF" offset="100%" />
        </linearGradient>
        <linearGradient x1="28.126%" y1="115.422%" x2="58.187%" y2="25.501%" id="p3i469rtlb">
          <stop stopColor="#D51BFF" offset="0%" />
          <stop stopColor="#00D4FF" offset="100%" />
        </linearGradient>
        <linearGradient x1="-59.392%" y1="193.063%" x2="127.904%" y2="-36.855%" id="yr19mnfiec">
          <stop stopColor="#D51BFF" offset="0%" />
          <stop stopColor="#00D4FF" offset="100%" />
        </linearGradient>
        <linearGradient x1="-62.042%" y1="178.684%" x2="127.306%" y2="-50.37%" id="kgdnyamlyd">
          <stop stopColor="#D51BFF" offset="0%" />
          <stop stopColor="#00D4FF" offset="100%" />
        </linearGradient>
        <linearGradient x1="-23.364%" y1="228.84%" x2="61.518%" y2="-.243%" id="kddylu2lde">
          <stop stopColor="#D51BFF" offset="0%" />
          <stop stopColor="#00D4FF" offset="100%" />
        </linearGradient>
        <linearGradient x1="-36.053%" y1="132.875%" x2="145.364%" y2="-58.89%" id="mvn5o4jb1f">
          <stop stopColor="#D51BFF" offset="0%" />
          <stop stopColor="#00D4FF" offset="100%" />
        </linearGradient>
        <linearGradient x1="-81.163%" y1="198.079%" x2="104.634%" y2="-1.046%" id="c9rjmb4lpg">
          <stop stopColor="#D51BFF" offset="0%" />
          <stop stopColor="#00D4FF" offset="100%" />
        </linearGradient>
        <linearGradient x1="24.887%" y1="137.686%" x2="105.795%" y2="-83.537%" id="7asvwf1cmh">
          <stop stopColor="#D51BFF" offset="0%" />
          <stop stopColor="#00D4FF" offset="100%" />
        </linearGradient>
      </defs>
      <g fillRule="nonzero" fill="none">
        <path
          d="M2.243 16.995c0-7.778 6.036-14.174 13.673-14.749V0C7.028.577 0 7.965 0 16.995c0 9.03 7.028 16.424 15.913 16.997v-2.247c-7.634-.574-13.67-6.97-13.67-14.75z"
          fill="url(#icasid8q5a)"
        />
        <path
          d="M34.067 16.995C34.067 7.965 27.039.577 18.154 0v2.246C25.79 2.821 31.825 9.216 31.825 17c0 7.783-6.036 14.175-13.67 14.754V34a17.032 17.032 0 0 0 13.67-8.545V34h2.242V16.995z"
          fill="url(#p3i469rtlb)"
        />
        <path
          d="M19.906 21.444c-.804.33-1.627.622-2.469.873a26.244 26.244 0 0 0 2.728 1.45 25.71 25.71 0 0 0 5.396 1.773 12.143 12.143 0 0 0 3.422-10.223 27.301 27.301 0 0 1-4.595 3.773 26.936 26.936 0 0 1-4.482 2.354z"
          fill="url(#yr19mnfiec)"
        />
        <path
          d="M14.024 12.216c.791-.31 1.597-.584 2.418-.824a26.48 26.48 0 0 0-2.71-1.474 25.722 25.722 0 0 0-5.01-1.738 12.003 12.003 0 0 0-3.682 7.045c-.142.967-.169 1.948-.079 2.921a27.355 27.355 0 0 1 3.465-2.903 26.885 26.885 0 0 1 5.598-3.027z"
          fill="url(#kgdnyamlyd)"
        />
        <path
          d="M22.38 16.464a26.978 26.978 0 0 1-.593 2.838 26.573 26.573 0 0 0 2.891-1.789 26.347 26.347 0 0 0 3.712-3.22c.108-.114.214-.229.32-.343a11.958 11.958 0 0 0-1.5-3.44 12.02 12.02 0 0 0-5.254-4.553 27.357 27.357 0 0 1 .73 5.542c0 .063.004.126.006.186a27.559 27.559 0 0 1-.311 4.779z"
          fill="url(#kddylu2lde)"
        />
        <path
          d="M24.511 26.471a26.723 26.723 0 0 1-5.88-2.178 26.826 26.826 0 0 1-3.949-2.468 27.674 27.674 0 0 1-2.185-1.829 27.518 27.518 0 0 0-.018 1.677c0 .161.011.324.017.484a26.049 26.049 0 0 0 1.07 6.43 12.091 12.091 0 0 0 1.914.4 12.016 12.016 0 0 0 8.722-2.287c.11-.07.21-.15.31-.229z"
          fill="url(#mvn5o4jb1f)"
        />
        <path
          d="M14.222 8.89c.343.16.685.33 1.02.506a26.56 26.56 0 0 1 3.95 2.514 27.168 27.168 0 0 1 2.316 1.985c.043-.723.06-1.45.042-2.181v-.134a25.969 25.969 0 0 0-.89-6.118 11.85 11.85 0 0 0-2.558-.511c-.463-.044-.928-.06-1.392-.05h-.012a12.008 12.008 0 0 0-6.895 2.376c1.518.407 2.996.946 4.419 1.612z"
          fill="url(#c9rjmb4lpg)"
        />
        <path
          d="M11.578 17.437c.142-1.058.345-2.106.61-3.14a26.116 26.116 0 0 0-3.11 1.878 26.309 26.309 0 0 0-3.845 3.31l-.059.064A12.085 12.085 0 0 0 7.29 24.2a12.026 12.026 0 0 0 4.96 3.916 27.173 27.173 0 0 1-.89-5.86c-.007-.186-.015-.372-.018-.557-.021-.921.004-1.842.076-2.76.044-.505.094-1.005.16-1.503z"
          fill="url(#7asvwf1cmh)"
        />
        <circle fill="#E30613" cx="32.611" cy="1.456" r="1.456" />
      </g>
    </SvgIcon>
  );
}

export default CastingLogo;
