import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { IProfileResponsePayload } from 'shared/interfaces/IProfile';

const { GET } = useAxios();
export const profileLinkDao = () => {
  const getProfileLink = async (token: string) => {
    const response = await GET<IProfileResponsePayload>({
      url: `${ENDPOINTS.PROFILE_LINK}/${token}`,
    });

    return response.data;
  };

  return {
    getProfileLink,
  };
};
