import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Flag(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 17 20" xmlns="http://www.w3.org/2000/svg">
        <path
          d="m14.25 6 2.55-3.4A1 1 0 0 0 16 1H4a1 1 0 1 0-2 0v17H1a1 1 0 0 0 0 2h4a1 1 0 0 0 0-2H4v-7h12a1 1 0 0 0 .8-1.6L14.25 6z"
          fill="#677482"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default Flag;
