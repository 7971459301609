import { Theme } from '@material-ui/core';
import { PaginationItem } from '@material-ui/lab';
import { withStyles } from '@material-ui/styles';

export const StyledPaginationItem = withStyles((theme: Theme) => ({
  root: {
    height: 24,
    minWidth: 24,
    borderRadius: '6px',
    backgroundColor: theme.palette.customBlack.main,
  },
  rounded: {
    borderRadius: '9px',
  },
}))(PaginationItem);
