import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Copy(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <defs>
          <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="rr7trvh17a">
            <stop stop-color="#3E8BFF" offset="0%" />
            <stop stop-color="#3E8BFF" offset="100%" />
          </linearGradient>
        </defs>
        <path
          d="M15.875 0H4.902a2.124 2.124 0 0 0-2.119 2.125v.658H2.12A2.124 2.124 0 0 0 0 4.908v10.967C0 17.047.95 18 2.119 18h10.973a2.127 2.127 0 0 0 2.125-2.125v-.658h.658a2.128 2.128 0 0 0 2.126-2.125V2.125A2.128 2.128 0 0 0 15.875 0zm-1.958 15.875c0 .455-.37.824-.825.824H2.119a.822.822 0 0 1-.818-.824V4.908c0-.454.367-.825.818-.825H13.092c.455 0 .825.37.825.825v10.967zm2.783-2.783c0 .454-.37.825-.825.825h-.658V4.908a2.128 2.128 0 0 0-2.125-2.125H4.084v-.658c0-.455.367-.824.818-.824h10.973c.455 0 .825.37.825.824v10.967z"
          fill="url(#rr7trvh17a)"
          fillRule="nonzero"
        />
      </svg>
    </SvgIcon>
  );
}

export default Copy;
