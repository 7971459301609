import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Calendar2(props: SvgIconProps) {
  return (
    <SvgIcon width="20" height="20" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg" {...props}>
      <path
        d="M16.636 11.813a.729.729 0 0 1 1.03 0l1.8 1.8a.725.725 0 0 1-.004 1.03l-5.143 5.142a.715.715 0 0 1-.513.215c-.018 0-.036-.004-.054-.004l-1.673-.127a.729.729 0 0 1-.67-.67l-.127-1.672a.739.739 0 0 1 .211-.571l3.779-3.779zM14.548 0c.4 0 .728.327.727.727v.728h1.033a2.606 2.606 0 0 1 2.604 2.604v6.067a.73.73 0 0 1-.727.727.73.73 0 0 1-.727-.727V4.059c0-.633-.517-1.15-1.15-1.15h-1.032v.728a.73.73 0 0 1-.728.727.73.73 0 0 1-.727-.727V2.91H5.09v.727a.73.73 0 0 1-.727.727.73.73 0 0 1-.727-.727V2.91H2.604c-.633 0-1.15.516-1.15 1.149v12.977c0 .633.517 1.15 1.15 1.15h6.794c.4 0 .728.327.728.727a.73.73 0 0 1-.728.727H2.604A2.606 2.606 0 0 1 0 17.036V4.059a2.606 2.606 0 0 1 2.604-2.604h1.033V.727c0-.4.327-.727.727-.727.4 0 .728.327.728.727v.728h8.729V.727c0-.4.327-.727.727-.727zm1.237 14.723-3.026 3.026.054.716.717.055 3.026-3.026-.771-.771zM5.274 13.82a.91.91 0 1 1 0 1.818.91.91 0 0 1 0-1.818zm4 0a.91.91 0 1 1 0 1.818.91.91 0 0 1 0-1.818zm7.878-.466-.338.338.771.771.338-.338-.77-.77zM5.274 10.184a.91.91 0 1 1 0 1.818.91.91 0 0 1 0-1.818zm4 0a.91.91 0 1 1 0 1.818.91.91 0 0 1 0-1.818zm4.001 0a.91.91 0 1 1 0 1.818.91.91 0 0 1 0-1.818zM5.274 6.547a.91.91 0 1 1 0 1.818.91.91 0 0 1 0-1.818zm4 0a.91.91 0 1 1 0 1.818.91.91 0 0 1 0-1.818zm4.001 0a.91.91 0 1 1 0 1.818.91.91 0 0 1 0-1.818z"
        fill="#D7A300"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default Calendar2;
