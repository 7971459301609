import { Accordion, Theme } from '@material-ui/core';
import { withStyles } from '@material-ui/styles';

const StyledAccordion = withStyles((theme: Theme) => ({
  root: {
    border: `1px solid ${theme.palette.primary.main}`,
    backgroundColor: '#0e1319',
    // borderRadius: 8,
  },
  rounded: {
    borderRadius: 8,
    '&:last-child': {
      borderRadius: 8,
    },
  },
}))(Accordion);

export default StyledAccordion;
