import { ENDPOINTS } from 'shared/constants/ENDPOINTS';
import { useAxios } from 'shared/hooks/useAxios';
import { IProjectSideResponsePayload } from 'shared/interfaces/IProjectSide';

const { GET, POST } = useAxios();
export const projectSideDao = () => {
  const getProjectSides = async (projectId: string) => {
    const response = await GET<IProjectSideResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/sides`,
    });

    return response.data;
  };

  const attachSides = async (projectId: string, mediumIds: string[]) => {
    const response = await POST<IProjectSideResponsePayload>({
      url: `${ENDPOINTS.PROJECT}/${projectId}/sides`,
      data: {
        medium_ids: mediumIds,
      },
    });
    return response.data;
  };

  return {
    getProjectSides,
    attachSides,
  };
};
