import { createStyles, makeStyles } from '@material-ui/core';

export const useStyles = makeStyles((theme) =>
  createStyles({
    qrModal__dialogContent: {
      padding: '20px 30px',
    },
    qrModal__container: {},
    qrModal__header: {
      display: 'flex',
      justifyContent: 'space-between',
      alignItems: 'flex-start',
    },
    qrModal__content: {
      padding: '10px 0',
      marginTop: theme.spacing(2),
    },
    qrModal__qrContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 18,
    },
    qrModal__imageContainer: {
      width: '100%',
      height: 200,
      overflow: 'hidden',
      borderRadius: 4,

      backgroundColor: '#fff',
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
    },

    whiteContainer: {
      height: '215px',
      width: '100%',
      borderRadius: '8px',
      background: '#FFFFFF',
      overflow: 'hidden',
      display: 'flex',
      alignItems: 'center',
      '& canvas': {
        height: '100% !important',
        width: '100% !important',
        objectFit: 'contain !important',
        padding: '5px !important',
      },
    },
    whiteContainerSmall: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'space-around',
      alignItems: 'center',
      height: '215px',
      width: '100%',
      borderRadius: '8px',
      background: '#FFFFFF',
      marginRight: '20px',
      overflow: 'hidden',
      padding: '15px',
      '& canvas': {
        height: '70px !important',
        width: '70px !important',
        margin: '10px',
      },
    },
    posterCls: {
      color: '#000000',
      fontSize: '10px',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      display: '-webkit-box !important',
      '-webkit-line-clamp': 5,
      '-webkit-box-orient': 'vertical',
      whiteSpace: 'normal',
    },
    qrButton: {
      border: 'solid 1px #3e8bff',
      textTransform: 'capitalize',
      height: '40px',
      fontSize: '14px',
      width: '100%',
      maxWidth: '280px',
      '& svg': {
        width: '18px',
      },
    },
    qrModal__rightContent: {},
    qrModal__linkContainer: {
      gap: 8,
      border: '1px solid #2d343e',
      padding: theme.spacing(1.5),
      borderRadius: 8,
    },
    qrModal__link: {
      display: 'flex',
      alignItems: 'center',
    },
    qrModal__centerTextContainer: {
      marginTop: theme.spacing(1.5),
    },
    qrModal__detailContainer: {
      display: 'flex',
      alignItems: 'flex-start',
      marginTop: theme.spacing(1),
    },
    qrModal__detailTextContainer: {
      display: 'flex',
      flexDirection: 'column',
      gap: 8,
    },
    detailsContainer: {
      '& > *:not(:last-child)': {
        marginBottom: '20px',
      },
    },
  }),
);
