import SvgIcon, { SvgIconProps } from '@material-ui/core/SvgIcon';
import React from 'react';

function Comment(props: SvgIconProps) {
  return (
    <SvgIcon width="22" height="21" viewBox="0 0 22 21" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="r9udcdx71a">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M19.286 0c1.181 0 2.143.961 2.143 2.143V13.57a2.145 2.145 0 0 1-2.143 2.143h-8.678l-5.162 4.13a.713.713 0 0 1-1.16-.558v-3.572H2.143A2.145 2.145 0 0 1 0 13.571V2.143C0 .96.961 0 2.143 0h17.143zm0 1.429H2.143a.715.715 0 0 0-.714.714V13.57c0 .394.32.715.714.715H5c.395 0 .714.319.714.714v2.8l4.197-3.358a.714.714 0 0 1 .446-.156h8.929c.394 0 .714-.321.714-.715V2.143a.715.715 0 0 0-.714-.714zM10.714 8.57a.714.714 0 1 1 0 1.429H5a.714.714 0 1 1 0-1.429h5.714zm5.715-2.857a.714.714 0 1 1 0 1.429H5a.714.714 0 1 1 0-1.429h11.429z"
        transform="translate(0 .5)"
        fill="url(#r9udcdx71a)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
}

export default Comment;
