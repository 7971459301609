import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const StickyNoteBlueIconTwoTone = (props: SvgIconProps) => {
  return (
    <SvgIcon width="17" height="16" viewBox="0 0 17 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="fcczu6ak8a">
          <stop stop-color="#3E8BFF" offset="0%" />
          <stop stop-color="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M16 10.458V.625A.625.625 0 0 0 15.375 0H.625A.625.625 0 0 0 0 .625v14.75c0 .345.28.625.625.625h9.833a.629.629 0 0 0 .442-.183l4.917-4.917a.628.628 0 0 0 .183-.442zM1.25 1.25h13.5v8.583h-4.292a.625.625 0 0 0-.625.625v4.292H1.25V1.25zm12.616 9.833-2.783 2.783v-2.783h2.783zM3.688 5.542c0-.346.28-.625.624-.625h7.375a.625.625 0 1 1 0 1.25H4.314a.625.625 0 0 1-.625-.625zm.624 3.083a.625.625 0 1 1 0-1.25h7.375a.625.625 0 1 1 0 1.25H4.314z"
        transform="translate(.696)"
        fill="url(#fcczu6ak8a)"
        fill-rule="nonzero"
      />
    </SvgIcon>
  );
};

export default StickyNoteBlueIconTwoTone;
