import { styled, Box } from '@material-ui/core';

export const EditItemCont = styled(Box)(({ theme }) => ({
  //   border: 'solid #2d343e 1px',
  //   borderRadius: 8,
  overflow: 'hidden',
  margin: '5px 10px',
  cursor: 'pointer',
  backgroundOrigin: 'border-box',
  backgroundClip: 'content-box, border-box',
  position: 'relative',
  borderRadius: '8px !important',

  '&:hover': {
    '& .delete-img': {
      opacity: 1,
    },
  },
}));

export const ImageThumb = styled('img')(({ theme }) => ({
  width: '100%',
  objectFit: 'contain',
  overflow: 'hidden',
  borderRadius: '7px 7px 0px 0px',
}));

export const WarningCont = styled(Box)(({ theme }) => ({
  position: 'absolute',
  right: 5,
  top: 5,
}));

export const DeleteCont = styled(Box)(({ theme }) => ({
  backgroundColor: theme.palette.type === 'dark' ? '#151a21' : '#edf1f6',
  padding: 5,
  borderRadius: 999,
  position: 'absolute',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  left: 5,
  top: 5,

  transition: '200ms',
  opacity: 0,
  '&:hover': {
    filter: 'brightness(1.5)',
  },
}));
