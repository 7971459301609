import { SvgIcon, SvgIconProps } from '@material-ui/core';
import React from 'react';

const Filter = (props: SvgIconProps) => {
  return (
    <SvgIcon width="16" height="16" viewBox="0 0 16 16" xmlns="http://www.w3.org/2000/svg" {...props}>
      <defs>
        <linearGradient x1="50%" y1="0%" x2="50%" y2="100%" id="fbwczaq67a">
          <stop stopColor="#3E8BFF" offset="0%" />
          <stop stopColor="#3E8BFF" offset="100%" />
        </linearGradient>
      </defs>
      <path
        d="M.381 2.286h9.493v.38c0 .632.512 1.144 1.143 1.144h1.524c.631 0 1.143-.512 1.143-1.143v-.381h1.775a.381.381 0 1 0 0-.762h-1.775v-.381C13.684.512 13.172 0 12.54 0h-1.524c-.631 0-1.143.512-1.143 1.143v.38H.381a.381.381 0 1 0 0 .763zm15.131 11.428H12.16v-.38c0-.632-.512-1.144-1.143-1.144H9.493c-.63 0-1.143.512-1.143 1.143v.381H.39a.381.381 0 0 0 0 .762H8.35v.381c0 .631.512 1.143 1.143 1.143h1.524c.631 0 1.143-.512 1.143-1.143v-.38h3.352a.381.381 0 0 0 0-.763zm-10.59-3.81h1.524c.63 0 1.143-.51 1.143-1.142V8.38h7.961a.381.381 0 1 0 0-.762H7.59v-.38c0-.632-.512-1.144-1.143-1.144H4.922c-.631 0-1.143.512-1.143 1.143v.381H.427a.381.381 0 1 0 0 .762h3.352v.38c0 .632.512 1.144 1.143 1.144z"
        fill="url(#fbwczaq67a)"
        fillRule="nonzero"
      />
    </SvgIcon>
  );
};

export default Filter;
