import { Button, DialogContent, Grid, IconButton, Link, InputLabel } from '@material-ui/core';
import { CloseIcon, Select } from '@room-match/shared-ui-components';
import React from 'react';
import { StyledDialog, Typography } from 'shared/elements';
import { useStyles } from './MoveTalentToModal.styles';

type Props = {
  open: boolean;
  onClose: () => void;
};
const MoveTalentToModal: React.FC<Props> = ({ open, onClose }) => {
  const classes = useStyles();

  return (
    <StyledDialog open={open} onClose={onClose} classes={{ paper: classes.addTalentModal__paper }}>
      <DialogContent classes={{ root: classes.addTalentModal__dialogContent }}>
        <div className={classes.addTalentModal__container}>
          {/* Content */}
          {/* <div className={classes.addTalentModal__content}> */}
          {/* addTalent */}
          <Grid container spacing={2}>
            <Grid item xs={3}>
              <div className={classes.addTalentModal__addTalentContainer}>
                <div className={classes.addTalentModal__imageContainer}>
                  <img src="/sample_person3.jpg" className={classes.addTalent__image} />
                </div>
                <Typography className={classes.addTalentModal__bottomTalentName}>Leonardo di Carpio</Typography>
                <Typography className={classes.addTalentModal__bottomTatentStats}>5’8” / 154 lbs / SAG+</Typography>
              </div>
            </Grid>
            {/* Right Content */}

            <Grid item md={9}>
              <div className={classes.addTalentModal__rightContent}>
                {/* Header  */}
                <div className={classes.addTalentModal__title}>
                  <Typography fontSize={24}>Move Talent To</Typography>
                  <IconButton size="small" onClick={() => onClose()}>
                    <CloseIcon style={{ fontSize: 16 }} />
                  </IconButton>
                </div>

                {/* Content Detail */}
                <div className={classes.addTalentModal__detailContainer}>
                  <Typography className={classes.addTalentModal__selectProject}>Select a Project</Typography>
                  <div className={classes.addTalentModal__selectedProjectContainer}>
                    <Typography>Select a Project</Typography>
                  </div>

                  <Typography className={classes.addTalentModal__selectProject}>Select a Role</Typography>
                  <Select
                    className={classes.addTalentModal__addToProjectBtn}
                    variant="outlined"
                    data={[]}
                    placeHolder="Farmer"
                    fullWidth
                  />
                  <Typography className={classes.addTalentModal__selectProject}>Make A Selection</Typography>
                  <div className={classes.addTalentModal__selectionContainer}>
                    <Button className={classes.addTalentModal__selectionBtn}>1</Button>
                    <Button className={classes.addTalentModal__selectionBtn} style={{ backgroundColor: 'dodgerblue' }}>
                      2
                    </Button>
                    <Button className={classes.addTalentModal__selectionBtn}>3</Button>
                    <Button className={classes.addTalentModal__selectionBtn}>4</Button>
                    <Button className={classes.addTalentModal__selectionBtn}>5</Button>
                    <Button
                      className={classes.addTalentModal__selectionBtn}
                      style={{ fontSize: 11, padding: '2px 3px' }}
                    >
                      CB
                    </Button>
                  </div>
                </div>
                <Button
                  className={classes.addTalentModal__addToProjectBtn}
                  variant="contained"
                  color="primary"
                  fullWidth
                >
                  Move Talent to Role
                </Button>
              </div>
            </Grid>
          </Grid>
          {/* </div> */}
        </div>
      </DialogContent>
    </StyledDialog>
  );
};

export default MoveTalentToModal;
