import { createStyles, makeStyles, styled } from '@material-ui/core';

export const useStyles = makeStyles(() => createStyles({}));

export const Container = styled('div')(({}) => ({
  display: 'flex',

  gap: '1.5em',
  flexDirection: 'column',
  width: '100%',
}));
