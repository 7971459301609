import { useMutation, useQuery } from 'react-query';
import { contactDao } from 'shared/dao/contactDao';
import {
  IContactResponsePayload,
  ICreateContactPayload,
  ICreateContactResponsePayload,
} from 'shared/interfaces/IContact';
import { IAxiosErrorResponse } from 'shared/interfaces/utils/IAxios';

const {
  getContacts: getContactDao,
  createContact: createContactDao,
  updateContact: updateContactDao,
  sendContactInvite: sendContactInviteDao,
} = contactDao();
export const contactService = () => {
  const getContacts = () => {
    return useQuery<IContactResponsePayload, IAxiosErrorResponse>(['contacts'], () => getContactDao());
  };

  const createContact = () => {
    return useMutation<ICreateContactResponsePayload, IAxiosErrorResponse, { payload: ICreateContactPayload }>(
      ({ payload }) => createContactDao(payload),
    );
  };

  const updateContact = () => {
    return useMutation<
      ICreateContactResponsePayload,
      IAxiosErrorResponse,
      { contactId: string; payload: ICreateContactPayload }
    >(({ contactId, payload }) => updateContactDao(contactId, payload));
  };

  const sendContactInvite = () => {
    return useMutation<ICreateContactResponsePayload, IAxiosErrorResponse, { contactId: string; projectId: string }>(
      ({ contactId, projectId }) => sendContactInviteDao(contactId, projectId),
    );
  };

  return {
    getContacts,
    createContact,
    updateContact,
    sendContactInvite,
  };
};
